import { FieldHookConfig, useField } from "formik";
import React, { useEffect } from "react";
import { FormField } from "@hopper/form_field";
import { Input } from "@hopper/input";
import { Spinner } from "@hopper/loading";

type TextInputProps = {
  isLoading?: boolean;
  label?: string;
  labelClass?: string;
  errorClass?: string;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  "data-testid"?: string;
  setInputValue?: (fn: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

function TextInput({
  label,
  isLoading,
  required,
  onBlur = () => {},
  validate,
  labelClass,
  errorClass,
  setInputValue = () => {},
  ...props
}: TextInputProps & FieldHookConfig<string>): JSX.Element {
  const [field, meta] = useField({ validate, ...props });
  const id = props.id ?? props.name;

  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  return (
    <FormField label={label} isOptional={!required} errorMessage={meta.touched && meta.error}>
      <div className="relative">
        <Input id={id} style={{ minWidth: "24rem" }} {...field} {...props} />
        <div className="absolute top-0 right-0">{isLoading && <Spinner size={40} />}</div>
      </div>
    </FormField>
  );
}

export default TextInput;
