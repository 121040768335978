import queryString from "query-string";
import { useLocation } from "react-router-dom";

type UseOpenChatMessageResult = {
  shouldOpenChannel: boolean;
  messageToJumpIntoExternalId: string | null;
};

export function useOpenChatFromQueryParams(externalChannelId: string | undefined): UseOpenChatMessageResult {
  const location = useLocation();
  const queryStringParams = queryString.parse(location.search);
  const channelToOpenExternalId = queryStringParams.channel_id;
  const messageToJumpToExternalId = queryStringParams.message_id;

  if (!channelToOpenExternalId) {
    return {
      shouldOpenChannel: false,
      messageToJumpIntoExternalId: null,
    };
  }

  return {
    shouldOpenChannel: channelToOpenExternalId === externalChannelId,
    messageToJumpIntoExternalId: messageToJumpToExternalId || null,
  };
}
