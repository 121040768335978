import React, { Component } from "react";
import styles from "./style.module.css";
import { Modal } from "react-bootstrap";
import classnames from "classnames";

type ModalComponentProps = {
  show: boolean;
  closeModal: () => void;
  closeButton: boolean;
  title: string;
  visibleOverflowY: boolean;
  children?: JSX.Element;
  body?: string;
  contentClassName?: string;
};
export default class ModalComponent extends Component<ModalComponentProps> {
  static defaultProps = {
    closeButton: true,
  };

  render(): JSX.Element {
    const { show, closeModal, closeButton, title, visibleOverflowY, children, body, contentClassName } = this.props;

    return (
      <Modal show={show} onHide={closeModal} size="lg" contentClassName={contentClassName}>
        <Modal.Header closeButton={closeButton} data-testid="modal-header">
          <div className={styles.modalHeader}>
            <h4 className={classnames(styles.modalTitle, "mt-xs font-bold mb-sm")}>{title}</h4>
          </div>
        </Modal.Header>
        <Modal.Body className={`${styles.modalBody} ${!visibleOverflowY ? "overflow-y-auto" : ""}`}>
          {children || body}
        </Modal.Body>
      </Modal>
    );
  }
}
