import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { FlexibleTestingIssueTag } from "types/graphql";

type LimitedActiveTagsListProps = {
  tags: FlexibleTestingIssueTag[];
  onClick: () => void;
  classNames: string;
};

const LimitedActiveTagsList = ({ tags, onClick, classNames }: LimitedActiveTagsListProps) => {
  const maximumNumberOfLetters = 21;
  const tagsLetterCount = tags.reduce((count: number, tag) => count + tag.name.length, 0);
  const reducedNumberOfTags = (tags: FlexibleTestingIssueTag[]) => {
    const newTags = new Array<FlexibleTestingIssueTag>();

    for (const tag of tags) {
      const lettersCount = newTags.reduce((count: number, tag) => count + tag.name.length, 0);
      lettersCount + tag.name.length <= maximumNumberOfLetters - newTags.length && newTags.push(tag);
    }

    return newTags;
  };
  const [labelsToDisplay, setLabelsToDisplay] = useState(reducedNumberOfTags(tags));
  const shouldDisplayNumericTag = () =>
    tagsLetterCount > maximumNumberOfLetters - labelsToDisplay.length && tags.length > labelsToDisplay.length;

  useEffect(() => {
    setLabelsToDisplay(reducedNumberOfTags(tags));
  }, [tags]);

  return (
    <>
      <div className="cursor-pointer truncate inline-block" style={{ width: "260px" }} onClick={onClick}>
        {labelsToDisplay.map(tag => (
          <span key={tag.id} className={classnames(classNames, "mr-xs inline-block")} data-testid="current-issue-tag">
            {tag?.name}
          </span>
        ))}
        {shouldDisplayNumericTag() ? (
          <span className={classNames}>+{tags.length - labelsToDisplay.length}</span>
        ) : (
          <i className={classnames(classNames, "fa fa-tag")} />
        )}
      </div>
    </>
  );
};

export default LimitedActiveTagsList;
