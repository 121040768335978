import React, { useRef } from "react";

import { FlagIcon } from "components/flexible_testing/shared/flag";
import { BodyCell, Row } from "components/flexible_testing/shared/table";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import formatDistanceShortLocale from "tools/format_distance_short_locale";
import { FlexibleTestingTestBlocker } from "types/graphql";
import classnames from "classnames";
import resolvedIcon from "assets/images/blocker_status_icons/resolved.svg";
import openIcon from "assets/images/blocker_status_icons/open.svg";
import { useResolveTestBlocker } from "./use_resolve_test_blocker";
import { useRequestTestBlockerAssistance } from "./use_request_test_blocker_assistance";
import { Button } from "@hopper/button";
import {
  OpenPrivateTestBlockerChatButton,
  ButtonType,
  OpenPrivateTestBlockerChatButtonHandle,
} from "components/flexible_testing/shared/chat/open_private_test_blocker_chat_button";

type BlockerRowProps = {
  testBlocker: FlexibleTestingTestBlocker;
};

const statusIcon: { [key: FlexibleTestingTestBlocker["status"]]: string } = {
  resolved: resolvedIcon,
  open: openIcon,
};

const BlockerRow = ({ testBlocker }: BlockerRowProps) => {
  const tester = testBlocker.tester;
  const chatButtonRef = useRef<OpenPrivateTestBlockerChatButtonHandle>(null);
  const resolvedTextClass = testBlocker.status === "resolved" ? "text-muted" : "";
  const reportedAtIso = parseISO(testBlocker.createdAt);
  const reportedAtTimeDistance = formatDistanceToNowStrict(reportedAtIso, {
    locale: formatDistanceShortLocale,
    roundingMethod: "floor",
  });
  const { resolveTestBlocker, isLoading: resolveTestBlockerLoading } = useResolveTestBlocker();
  const { requestTestBlockerAssistance, isLoading: requestAssistanceLoading } = useRequestTestBlockerAssistance();

  return (
    <Row
      testId="blocker-row"
      className="cursor-pointer"
      onClick={() => chatButtonRef.current && chatButtonRef.current.openChatPanel()}
    >
      <BodyCell data-testid="tester-personal-info" className={classnames("truncate max-w-[1px]", resolvedTextClass)}>
        <FlagIcon className="mr-md" code={tester.country?.code.toLowerCase()} title={tester.country?.name} />
        {testBlocker.tester.fullName}
      </BodyCell>
      <BodyCell data-testid="blocker-name" className={classnames("truncate max-w-[1px]", resolvedTextClass)}>
        <span onClick={event => event.stopPropagation()} className="cursor-default">
          <OpenPrivateTestBlockerChatButton
            chatTitle={testBlocker.name}
            testBlockerId={testBlocker.id}
            testCode="Test"
            type="test_blocker_private_tms_tester"
            tester={tester}
            variant={ButtonType.Inline}
            ref={chatButtonRef}
          />
          {testBlocker.name}
        </span>
      </BodyCell>
      <BodyCell data-testid="blocker-status">
        <span className="flex items-center text-sm capitalize">
          <img src={statusIcon[testBlocker.status]} className="mr-sm" alt="blocker-status-icon" />
          {testBlocker.status}
        </span>
      </BodyCell>
      <BodyCell data-testid="blocker-reported-time">{reportedAtTimeDistance}</BodyCell>
      <BodyCell data-testid="blocker-action" className="flex justify-between items-center">
        {testBlocker.status === "open" && (
          <>
            <div className="flex justify-center flex-1 text-center">
              {testBlocker.assistanceRequested ? (
                <span className="text-secondary mr-sm px-lg">Help requested</span>
              ) : (
                <span onClick={event => event.stopPropagation()} className="cursor-default mr-sm">
                  <Button
                    variant="primary"
                    appearance="minimal"
                    onClick={() => requestTestBlockerAssistance(testBlocker.id)}
                    isLoading={requestAssistanceLoading}
                    className="font-normal"
                  >
                    Get help
                  </Button>
                </span>
              )}
            </div>

            <span onClick={event => event.stopPropagation()} className="cursor-default flex-1">
              <Button
                variant="primary"
                appearance="minimal"
                onClick={() => resolveTestBlocker(testBlocker.id)}
                isLoading={resolveTestBlockerLoading}
                className="font-normal"
              >
                Mark as resolved
              </Button>
            </span>
          </>
        )}
      </BodyCell>
    </Row>
  );
};

export default BlockerRow;
