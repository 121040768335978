import React from "react";
import * as yup from "yup";
import ExportIntegrationForm from "../export_integration_form";
import { Select, TextInput } from "components/shared/form";
import styles from "./index.module.css";

const validations = yup.object({
  repository: yup.string().required("Repository can't be blank"),
  labels: yup.string().max(255),
});

const initialValues = {
  repository: "",
  labels: "",
};

const GithubExportForm = ({
  integration: {
    runs_export_attributes: { repositories },
  },
  ...props
}) => (
  <ExportIntegrationForm initialValues={initialValues} validations={validations} {...props}>
    <div className={styles.exportFormContent}>
      <div className="mb-lg" data-test-role="form-group">
        <Select
          name="repository"
          label="Repository"
          options={repositories?.map(({ full_name }) => ({
            value: full_name,
            label: full_name,
          }))}
        />
      </div>
      <div className="mb-lg" data-test-role="form-group">
        <TextInput
          id="labels"
          name="labels"
          label="Labels (comma separated values)"
          placeholder="bug, critical"
          className="form-control"
        />
      </div>
    </div>
  </ExportIntegrationForm>
);

export default GithubExportForm;
