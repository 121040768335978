import React from "react";
import { Button } from "@hopper/button";

type ActionButtonProps = {
  onChatButtonClick: () => void;
  unreadMessagesCount: number;
};

export const ActionButton = ({ onChatButtonClick, unreadMessagesCount }: ActionButtonProps) => (
  <Button
    data-testid="open-private-chat-button"
    variant="secondary"
    className="m-xs"
    onClick={() => onChatButtonClick()}
  >
    <i className="fa fa-comment-o mr-sm" aria-hidden="true" />
    Chat
    {unreadMessagesCount > 0 && (
      <span className="h-[10px] w-[10px] absolute top-[-3px] right-[-3px] rounded-full bg-danger" />
    )}
  </Button>
);
