import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { withCurrentUser } from "../../../app/current_user/with_current_user";
import { IntegrationsQuery } from "./pages/integrations_page";
import { IntegrationQuery } from "./pages/integration_page";
import OrganizationUsersPage from "./pages/organization_users_page";
import ApiSettingsPage from "../../api_settings/api_settings_page";
import { compose } from "lodash/fp";

class SettingsSection extends React.Component {
  render() {
    const {
      match: { path },
    } = this.props;
    const childrenProps = {
      ...this.props,
    };

    return (
      <Switch>
        <Route exact path={path + "/integrations"}>
          <IntegrationsQuery {...childrenProps} />
        </Route>
        <Route
          path={path + "/integrations/:integrationCode"}
          render={routeProps => <IntegrationQuery {...childrenProps} {...routeProps} />}
        />
        <Route path={path + "/api-settings"}>
          <ApiSettingsPage />
        </Route>
        <Redirect from={path + "/customer-api-key"} to={path + "/api-settings"} />
        <Route exact path={path + "/users"}>
          <OrganizationUsersPage />
        </Route>
      </Switch>
    );
  }
}

export default compose(withCurrentUser)(SettingsSection);
