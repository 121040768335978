import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Loader } from "@hopper/loading";
import { groupBy } from "lodash";

import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { GET_AVAILABLE_INTEGRATIONS } from "components/shared/integration/integration_queries";
import { removePrefixFromGraphqlErrorMessage } from "tools/remove_prefix_from_graphql_error_message";
import { websiteContentPaths } from "components/configuration";
import ExportIntegrationCard, { IntegrationType } from "./export_integration_card";
//@ts-ignore
import ExportDetails from "./export_details";
import styles from "./style.module.css";
import { useCsvExport } from "./use_csv_export";
import { Integration, IntegrationConnection } from "types/graphql";
import { GET_ISSUES_TAB_DATA } from "../issues/issues_tab_data_query";

export type IntegrationTypes = "jira" | "github" | "azure_devops" | "jira_connect" | "csv";

type ExportDestinationsProps = {
  issuesToExport: Array<string>;
  onSuccessfulExport: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchQuery?: any;
};

type QueryData = {
  availableIntegrations: IntegrationConnection;
};

type ErrorType = {
  message: string;
};

function ExportDestinations({
  issuesToExport,
  onSuccessfulExport,
  refetchQuery,
}: ExportDestinationsProps): JSX.Element {
  const { downloadCsvFile, isLoading: isCSVLoading } = useCsvExport(refetchQuery);
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationTypes | null>(null);
  const { loading: areIntegrationsLoading, data } = useQuery<QueryData>(GET_AVAILABLE_INTEGRATIONS);
  const integrations =
    data?.availableIntegrations?.edges
      ?.map(integration => integration?.node)
      ?.filter((integration): integration is Integration => !!integration) || [];
  const exportIntegrations = integrations.filter(
    integration => integration?.features && integration.features.indexOf("runs_manual_export") > -1,
  );
  const groupedIntegrations = groupBy(exportIntegrations, integration =>
    integration?.clientAuthenticationValid ? "active" : "inactive",
  );
  const history = useHistory();
  const isLoading = areIntegrationsLoading || isCSVLoading;
  function handleIntegrationSelect(integrationCode: IntegrationTypes) {
    const integration = integrations.find(integration => integration.code === integrationCode);

    if (integrationCode === "csv") {
      downloadCsvFile(issuesToExport).then(() => {
        closeAndReset();
      });
    } else if (integration?.clientAuthenticationValid) {
      setSelectedIntegration(integrationCode);
    } else {
      history.push(websiteContentPaths.integrationSetting.replace(":code", integrationCode));
    }
  }

  // @ts-ignore
  function handleExportSubmit(values, exportMutation) {
    return exportMutation({
      variables: {
        input: {
          issueIds: issuesToExport,
          exportParams: values,
        },
      },
      refetchQueries: [
        { query: GET_ISSUES_TAB_DATA, variables: { testId: "g3juBSu3QEYAec4Dp-z38xhXDGGsDMB4GItCpRWgEBo=" } },
      ],
    })
      .then(() => {
        closeAndReset();
      })
      .catch((error: ErrorType) => {
        throw new Error(removePrefixFromGraphqlErrorMessage(error.message));
      });
  }

  function handleExportReset() {
    setSelectedIntegration(null);
  }

  function closeAndReset() {
    onSuccessfulExport();

    notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
      type: "success",
      message: "Results have been successfully exported.",
    });
  }

  function currentIntegration() {
    return integrations.find(integration => integration.code === selectedIntegration);
  }

  return (
    <>
      {selectedIntegration ? (
        <ExportDetails
          itemsToExport={issuesToExport}
          integration={currentIntegration()}
          onExportSubmit={handleExportSubmit}
          onExportReset={handleExportReset}
        />
      ) : (
        <div className="pt-xl">
          <div className="mb-xl pl-lg text-xl">Where would you like to export the selected issue(s) to?</div>

          {!isLoading ? (
            <div className="flex justify-around flex-wrap lg:flex-nowrap mx-sm mb-2xl">
              {groupedIntegrations.active?.map(integration => (
                <ExportIntegrationCard
                  integration={integration as IntegrationType}
                  inactive={false}
                  onIntegrationSelect={handleIntegrationSelect}
                  key={integration.code}
                />
              ))}
              <ExportIntegrationCard
                integration={{ code: "csv" }}
                inactive={false}
                onIntegrationSelect={handleIntegrationSelect}
                key="csv"
              />
              {groupedIntegrations.inactive?.map(integration => (
                <ExportIntegrationCard
                  integration={integration as IntegrationType}
                  inactive={true}
                  onIntegrationSelect={handleIntegrationSelect}
                  key={integration.code}
                />
              ))}
            </div>
          ) : (
            <div className={styles.loadingBox}>
              <Loader isLoaded={!isLoading} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ExportDestinations;
