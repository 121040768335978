import { gql, useMutation } from "@apollo/client";
import { PageViewTrackInput } from "types/graphql";

export const PAGE_VIEW_TRACK = gql`
  mutation PageViewTrack($input: PageViewTrackInput!) {
    pageViewTrack(input: $input) {
      successful
    }
  }
`;

type usePageViewTrackInterface = {
  trackPageView: (pageName: string) => Promise<void>;
};

export const usePageViewTrack = (): usePageViewTrackInterface => {
  const [trackPageView] = useMutation<{ input: PageViewTrackInput }>(PAGE_VIEW_TRACK);

  return {
    trackPageView: async pageName => {
      await trackPageView({
        variables: {
          input: {
            pageName,
          },
        },
      });
    },
  };
};
