import React, { useState } from "react";

import { ParticipantRemovalReason } from "types/graphql";
import { Participant } from "components/flexible_testing/shared/chat";
import { Button } from "@hopper/button";
import { useRemoveTester } from "./use_remove_tester";
import CustomTesterRemovalModal from "./custom_tester_removal_modal";
import StrictTesterRemovalModal from "./strict_tester_removal_modal";

type RemoveTesterButtonProps = {
  participants: Participant[];
  testId: string;
  disabled: boolean;
  hasStrictTesterRemoval: boolean;
  participantRemovalReasons?: ParticipantRemovalReason[];
};

export type ModalStatus = "init" | "success" | "error";

const RemoveTesterButton = ({
  participants,
  testId,
  disabled,
  hasStrictTesterRemoval,
  participantRemovalReasons,
}: RemoveTesterButtonProps) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<ModalStatus>("init");
  const isStrictTesterRemoval = hasStrictTesterRemoval && !!participantRemovalReasons;
  const { removeTester } = useRemoveTester();
  const participantIds = participants.map(participant => participant.id);

  const onCloseModal = () => {
    setModalOpen(false);
    setStatusModal("init");
  };

  const handleRemoveTester = async (reasons: string[]) => {
    const success = await removeTester(testId, participantIds, reasons);

    setStatusModal(success ? "success" : "error");
  };

  const ModalComponent = isStrictTesterRemoval ? (
    <StrictTesterRemovalModal
      onClose={onCloseModal}
      onSubmit={handleRemoveTester}
      isOpen={isModalOpen}
      status={statusModal}
      participantRemovalReasons={participantRemovalReasons}
    />
  ) : (
    <CustomTesterRemovalModal
      participants={participants}
      onClose={onCloseModal}
      onSubmit={handleRemoveTester}
      isOpen={isModalOpen}
      status={statusModal}
    />
  );

  return (
    <>
      <Button
        data-testid="remove-tester-button"
        variant="secondary"
        disabled={disabled}
        onClick={() => setModalOpen(true)}
      >
        {isStrictTesterRemoval ? "Remove" : "Remove tester"}
      </Button>

      {isModalOpen && ModalComponent}
    </>
  );
};

export default RemoveTesterButton;
