import React from "react";
import { CaseResultModerationLog } from "types/graphql";
// @ts-ignore
import { datetimeFormat } from "components/organization/shared_functions/date_format";

type ModerationLogProps = {
  moderationLog: CaseResultModerationLog;
};

const formatDateTime = (datetime: string): string =>
  datetimeFormat(datetime, { format: "dd' 'LLL' 'yyyy' at 'h':'mma' (UTC)" });

const ModerationLog = ({ moderationLog }: ModerationLogProps) => {
  const userRole = moderationLog.organizationUserRole === "community_test_manager" ? "Community test manager" : "";
  const userName = <span className="font-bold">{moderationLog.organizationUserFullName}</span>;

  return (
    <div>
      <div className="mb-xs font-bold">{formatDateTime(moderationLog.eventCreatedAt)}</div>
      <div className="mb-lg">
        {userRole} {userName} {moderationLog.eventType} this test case result
      </div>
    </div>
  );
};

export default ModerationLog;
