import { gql, useMutation } from "@apollo/client";
import { GET_TEST_DETAILS } from "./test_details_query";

export const TEST_CYCLE_TO_TEST_LINK = gql`
  mutation ($input: TestCycleToTestLinkInput!) {
    advancedTestingTestCycleToTestLink(input: $input) {
      result
      errors {
        message
        code
        path
      }
      successful
    }
  }
`;

export const useTestCycleToTestLink = () => {
  const [testCycleToTestLink] = useMutation(TEST_CYCLE_TO_TEST_LINK);

  return {
    testCycleToTestLink: async (testId: string, testCycleCode: string) => {
      const { data } = await testCycleToTestLink({
        variables: { input: { testId, testCycleCode } },
        refetchQueries: () => [
          {
            query: GET_TEST_DETAILS,
            variables: { testId },
          },
        ],
      });

      return data.advancedTestingTestCycleToTestLink;
    },
  };
};
