import React from "react";
import { Modal } from "@hopper/legacy_modal";
import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";

type RelatedIssueDeleteConfirmationProps = {
  onClose: () => void;
  onMarkAsPassed: () => void;
  onRequestEdit: () => void;
  isButtonLoading?: boolean;
};

export function RelatedIssueDeleteConfirmation({
  onClose,
  onMarkAsPassed,
  onRequestEdit,
  isButtonLoading,
}: RelatedIssueDeleteConfirmationProps) {
  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={true}
      position="center"
      size="lg"
      onOverlayClicked={onClose}
    >
      <div className="flex flex-col max-w-xs sm:max-w-full ml-xl" data-testId="related-issue-removal-confirmation">
        <Heading size={2}>You are about to delete a related issue, how would you like to proceed?</Heading>
        <div className="mt-lg">
          <div>You have two options:</div>
          <ol className="list-decimal ml-2xl">
            <li>Delete the related issue and request tester edit</li>
            <li>Delete the related issue and mark the test case as “Passed”</li>
          </ol>
          <div className="mt-lg">What would you like to do?</div>
        </div>

        <div className="flex mt-4xl items-center justify-center">
          <Button variant="secondary" className="mr-xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="secondary"
            className="mr-xl"
            type="submit"
            onClick={onRequestEdit}
            isLoading={isButtonLoading}
          >
            Request tester edit
          </Button>
          <Button variant="secondary" type="submit" onClick={onMarkAsPassed} isLoading={isButtonLoading}>
            Mark as passed
          </Button>
        </div>
      </div>
    </Modal>
  );
}
