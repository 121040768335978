import {
  FlexibleTestingIssueFieldConnection,
  FlexibleTestingIssueTagConnection,
  FlexibleTestingIssueField,
  FlexibleTestingIssueTag,
} from "types/graphql";

export function extractField(fieldName: string, fields: FlexibleTestingIssueFieldConnection): string {
  const field = findByIssueFieldDefinitionCode(fieldName, fields);

  return field?.value || "";
}

export function extractTags(tags: FlexibleTestingIssueTagConnection): FlexibleTestingIssueTag[] {
  const issueTags = tags?.edges?.map(tag => tag?.node) || [];

  return issueTags;
}

const buildInFieldDefinitionCodes = [
  "title",
  "severity",
  "expected_results",
  "actual_results",
  "reproducibility",
  "reproducibility_express",
  "reproduction_steps",
  "mobile_device",
  "browser_and_operating_system",
  "operating_system_version",
  "operating_system_and_version",
  "browser_or_device",
];

export function extractAdditionalDetails(fields: FlexibleTestingIssueFieldConnection): FlexibleTestingIssueField[] {
  return (fields?.edges || [])
    .map(field => field?.node)
    .filter(issueField => !buildInFieldDefinitionCodes.includes(issueField.issueFieldDefinition?.code));
}

function findByIssueFieldDefinitionCode(
  fieldName: string,
  fields: FlexibleTestingIssueFieldConnection,
): FlexibleTestingIssueField | undefined {
  const field = fields?.edges
    ?.map(field => field?.node)
    ?.find(issueField => issueField?.issueFieldDefinition?.code === fieldName);

  return field;
}
