import React, { useEffect, useState } from "react";
import { Checkbox } from "@hopper/checkbox";
import { Button } from "@hopper/button";
import { useLocation, useHistory } from "react-router-dom";

import { FlexibleTestingIssue } from "types/graphql";
import { IssueRow } from "./issue_row";
import { Table, Header, HeaderCell, Body } from "components/flexible_testing/shared/table";
import pluralize from "pluralize";
import IssueFilters from "../issue_filters";
import { ReportedIssuesInfo } from "../reported_issues_info";
import queryString from "query-string";
import { isPossibleDuplicate } from "../../../shared/is_possible_duplicate";
import { severityScores } from "../../../shared/sort_by_severity";
import { createNavigationUrl } from "app/helpers/url_helper";

type IssuesListProps = {
  issues: Array<FlexibleTestingIssue>;
  handleSelectSingle: (id: string) => void;
  handleExportClick: () => void;
  selectedIssues: Array<string>;
  testId: string;
  testStatus: string;
  setSelectedIssuesIds: React.Dispatch<React.SetStateAction<Array<string>>>;
};

export type IssueProps = {
  isNew: boolean;
} & FlexibleTestingIssue;

type TriageStatusToIssuesMapProps = {
  [key: string]: Array<FlexibleTestingIssue>;
};

type HeaderProps = {
  [key: string]: string;
};

const MAX_NUMBER_OF_EXPORTABLE_ISSUES = 50;

function IssuesList({
  issues,
  handleSelectSingle,
  selectedIssues,
  handleExportClick,
  testId,
  testStatus,
  setSelectedIssuesIds,
}: IssuesListProps): JSX.Element {
  const [currentIssueIds, setCurrentIssueIds] = useState<string[]>([]);
  const [issuesToDisplay, setIssuesToDisplay] = useState<IssueProps[]>([]);

  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const [tagsFilterMode, setTagsFilterMode] = useState<string>("any");
  const filterIssuesByTag = (issue: FlexibleTestingIssue) =>
    selectedTags.length === 0 ||
    (tagsFilterMode === "all"
      ? selectedTags.every(selTag => issue.issueTags.edges.map(x => x.node.name).includes(selTag))
      : selectedTags.some(selTag => issue.issueTags.edges.map(x => x.node.name).includes(selTag)));

  const newIssues = issuesToDisplay.filter(issue => issue.triageStatus === "untriaged").filter(filterIssuesByTag);
  const acceptedIssues = issuesToDisplay.filter(issue => issue.triageStatus === "accepted").filter(filterIssuesByTag);
  const rejectedIssues = issuesToDisplay.filter(issue => issue.triageStatus === "rejected").filter(filterIssuesByTag);
  const [currentFilteredIssues, setCurrentFilteredIssues] = useState<IssueProps[]>(newIssues);
  const [currentIssuesFilter, setCurrentIssuesFilter] = useState("untriaged");

  const taggedIssues = issuesToDisplay.filter(filterIssuesByTag).filter(issue => {
    switch (currentIssuesFilter) {
      case "accepted":
        return issue.triageStatus === "accepted";
      case "rejected":
        return issue.triageStatus === "rejected";
      case "untriaged":
        return issue.triageStatus === "untriaged";
      default:
        return true;
    }
  });

  const isTestFinished = testStatus === "closed";
  const areAllSelected = currentFilteredIssues.length > 0 && currentFilteredIssues.length === selectedIssues.length;
  const isTriageCompleted = isTestFinished && newIssues.length === 0 && issuesToDisplay.length > 0;
  const location = useLocation<{ previousPage: string; currentFilter: string }>();
  const history = useHistory();
  const paramsFilter = queryString.parse(location.search).filter;
  const shouldDisplayRejectionReason = currentIssuesFilter === "rejected";

  const triageStatusToIssuesMap: TriageStatusToIssuesMapProps = {
    tags: taggedIssues,
    untriaged: newIssues,
    accepted: acceptedIssues,
    rejected: rejectedIssues,
  };

  const listHeader: HeaderProps = {
    tags: "ISSUES FILTERED BY TAG",
    untriaged: "NEW ISSUES",
    accepted: "ACCEPTED ISSUES",
    rejected: "REJECTED ISSUES",
  };

  const setFilter = (triageStatus: string) => {
    setCurrentIssuesFilter(triageStatus);
    setCurrentFilteredIssues(triageStatusToIssuesMap[triageStatus] as IssueProps[]);
    clearSelectedIssues();
  };

  const updateSelectedTags = (tags: string[], mode?: string) => {
    setTagsFilterMode(mode || "any");
    clearSelectedIssues();
    setSelectedTags(tags);
    setCurrentFilteredIssues(triageStatusToIssuesMap.tags as IssueProps[]);
    clearSelectedIssues();
  };

  const clearSelectedIssues = () => {
    if (selectedIssues.length > 0) {
      setSelectedIssuesIds([]);
    }
  };

  // This will support future sorting stacks without making sorting exponentially more expensive.
  const rankedIssues = [...currentFilteredIssues].map(issue => {
    const POSSIBLE_DUPLICATE_PRIORITY = -10;

    // Disabling prettier here so the sorting stack actually looks like a stack
    // eslint-disable-next-line prettier/prettier
    const sortingStack = [
      isPossibleDuplicate(issue.issueTags) ? POSSIBLE_DUPLICATE_PRIORITY : 0,
      severityScores[issue.severity] || 0,
    ];

    return {
      rank: sortingStack.reduce((a, b) => a - b),
      issue,
    };
  });

  const orderedIssues = () =>
    [...rankedIssues].sort((firstIssue, secondIssue) => secondIssue.rank - firstIssue.rank).map(r => r.issue);

  useEffect(() => {
    const issuesToDisplay = issues.map(issue => {
      const isNew = currentIssueIds.length > 0 && !currentIssueIds.includes(issue.id);

      return { ...issue, isNew };
    });

    setIssuesToDisplay(issuesToDisplay);
    setCurrentIssueIds(issues.map(issue => issue.id));
  }, [issues]);

  useEffect(() => {
    if (paramsFilter && paramsFilter in triageStatusToIssuesMap) {
      setFilter(paramsFilter);
    } else if (location.state?.previousPage === "resultsPage" && isTriageCompleted) {
      setFilter("accepted");
      history.replace(location.pathname, { ...location.state, ...{ previousPage: "" } });
    } else if (location.state?.previousPage === "issuePage") {
      setFilter(location.state?.currentFilter || "untriaged");
      history.replace(location.pathname, { ...location.state, previousPage: "", currentFilter: "" });
    } else {
      setCurrentFilteredIssues(triageStatusToIssuesMap[currentIssuesFilter] as IssueProps[]);
    }
  }, [issuesToDisplay, selectedTags]);

  const toggleSelectAllForFilter = () => {
    if (selectedIssues.length === currentFilteredIssues.length) {
      setSelectedIssuesIds([]);
    } else {
      setSelectedIssuesIds(currentFilteredIssues.map(issue => issue.id));
    }
  };

  const exportButtonText = () => {
    if (selectedIssues.length === 0) {
      return "Export issues";
    } else {
      return `Export ${selectedIssues.length} ${pluralize("issue", selectedIssues.length)}`;
    }
  };

  const exportButton = (
    <span className="text-center">
      <Button
        variant="primary"
        disabled={selectedIssues.length === 0 || selectedIssues.length > 50}
        onClick={handleExportClick}
      >
        {exportButtonText()}
      </Button>
      <p className="text-sm text-secondary">Max 50 issues at once</p>
    </span>
  );

  return (
    <div className="mt-2xl sm:mt-4xl xl:mt-5xl mb-4xl">
      <div className="flex justify-between">
        <div className="mb-3xl" data-testid="issues-export-button">
          {orderedIssues().length > 0 && exportButton}
        </div>
        <IssueFilters
          currentIssuesFilter={currentIssuesFilter}
          setFilter={setFilter}
          issues={issues}
          newIssues={newIssues}
          acceptedIssues={acceptedIssues}
          rejectedIssues={rejectedIssues}
          taggedIssues={taggedIssues}
          updateSelectedTags={updateSelectedTags}
          filterMode={tagsFilterMode}
        />
      </div>
      <ReportedIssuesInfo
        currentIssuesFilter={currentIssuesFilter}
        testStatus={testStatus}
        issuesToDisplayCount={issuesToDisplay.length}
        newIssuesCount={newIssues.length}
        acceptedIssuesCount={acceptedIssues.length}
        rejectedIssuesCount={rejectedIssues.length}
      />
      {orderedIssues().length > 0 && (
        <Table>
          <Header data-testid="issues-list-header">
            <HeaderCell style={{ width: "30px" }}>
              <div className="ml-lg mr-sm" data-testid="issues-list-checkbox">
                <Checkbox onChange={toggleSelectAllForFilter} isChecked={areAllSelected} />
              </div>
            </HeaderCell>
            <HeaderCell>{listHeader[currentIssuesFilter]}</HeaderCell>

            <HeaderCell style={{ width: "170px" }}>TAGS</HeaderCell>

            {shouldDisplayRejectionReason && <HeaderCell style={{ width: "200px" }}>REJECTION REASON</HeaderCell>}

            <HeaderCell style={{ width: "120px" }}>SEVERITY</HeaderCell>

            <HeaderCell style={{ width: "38px" }} />
          </Header>
          <Body>
            {orderedIssues().map(issue => (
              <IssueRow
                key={issue.id}
                issue={issue}
                isSelected={selectedIssues.includes(issue.id)}
                handleSelectSingle={handleSelectSingle}
                disableSelection={selectedIssues.length >= MAX_NUMBER_OF_EXPORTABLE_ISSUES}
                currentIssuesFilter={currentIssuesFilter}
                shouldDisplayRejectionReason={shouldDisplayRejectionReason}
                destinationPath={createNavigationUrl("flexcaseIssueFromResults", { id: issue.id, testId })}
                showReportedAt={false}
              />
            ))}
          </Body>
        </Table>
      )}
    </div>
  );
}

export default IssuesList;
