import { Heading } from "@hopper/heading";
import { Label } from "@hopper/legacy_label";
import React from "react";
import { FlexibleTestingTestCaseResult } from "types/graphql";
import { Attachments } from "../../shared/attachments";
import statusColorMap from "./shared/statusColorMap";
import { CaseResultStatus } from "./case_result_status";
import classnames from "classnames";

const STATUS_LABEL_MAP: Record<CaseResultStatus, string> = {
  pass: "Could reproduce",
  fail: "Could not reproduce",
  blocked: "Could not reproduce (blocked)",
  not_tested: "Not tested",
  none: "Not tested",
} as const;

function ReproTestResult({ caseResult }: { caseResult: FlexibleTestingTestCaseResult }): JSX.Element {
  // TODO: Using legacy Label component due to the newer one not supporting colors since the default styles
  // take precedence over the styles added via className. Plus the fact that moste Hopper components in CP aren't up
  // to date. An investigation is being made on whether the design system has changed to deliberate remove support for
  // colours. In the meantime Legacy will be used to match the design, which makes functional use of coloured labels.

  const status = (caseResult.status || "none") as CaseResultStatus;
  const statusColor = `bg-${statusColorMap[status]}`;
  const deviceUsed = caseResult.testerEnvironment?.browserOrDevice;
  const osUsed = caseResult.testerEnvironment?.operatingSystemAndVersion;
  const attachments = caseResult.caseResultAttachments.edges.map(({ node }) => ({
    type: "video",
    url: node?.attachment?.publicUrl || "",
  }));

  return (
    <div className="gap-sm py-2" data-testid="repro-test-result">
      <div className="flex flex-wrap items-center" data-testid="repro-status">
        <Label color={statusColor} className={classnames("px-2", "text-white")}>
          {STATUS_LABEL_MAP[status]}
        </Label>
        <div className="text-muted mx-2 text-xs" data-testid="repro-environment">
          {deviceUsed}, {osUsed}
        </div>
      </div>
      <div className="pt-lg" data-testid="repro-tester-notes">
        {caseResult?.actualResult && (
          <>
            <Heading size={5}>Note from tester</Heading>
            <p>{caseResult?.actualResult}</p>
          </>
        )}
        <div className="md:mt-0 mr-4xl pt-2xl">
          <Attachments {...{ attachments }} />
        </div>
      </div>
    </div>
  );
}

export default ReproTestResult;
