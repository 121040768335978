import React from "react";

import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";
import { LaunchWizardBackButton } from "./launch_wizard_back_button";

export function StageHeader({
  sdlcStageName,
  isBackButtonVisible = true,
  onBackButtonClick,
  additionalLabel,
}: {
  sdlcStageName?: SoftwareDevelopmentLifeCycleStageName | null;
  isBackButtonVisible?: boolean;
  onBackButtonClick: () => void;
  additionalLabel?: string;
}): JSX.Element | null {
  if (!isBackButtonVisible && !sdlcStageName) {
    return null;
  }

  return (
    <div className="mb-xl">
      {isBackButtonVisible && <LaunchWizardBackButton onBackButtonClick={onBackButtonClick} />}
      {sdlcStageName && (
        <div className="uppercase text-darkest text-xl font-bold tracking-extra-wide pt-md">
          {sdlcStageName} test {additionalLabel}
        </div>
      )}
    </div>
  );
}
