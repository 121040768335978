import React from "react";
import classNames from "classnames";

type RowProps = {
  children: React.ReactNode;
  isHidden?: boolean;
  noBorder?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.FormEventHandler;
  testId?: string;
  recordId?: string;
};

function Row({ children, isHidden, noBorder, className, style, onClick, testId, recordId }: RowProps) {
  const classes = classNames({ hidden: isHidden, "border-t border-light": !noBorder }, className);

  return (
    <tr className={classes} style={style} onClick={onClick} data-testid={testId} data-recordid={recordId}>
      {children}
    </tr>
  );
}

export default Row;
