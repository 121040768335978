import React from "react";
import { Card } from "@hopper/card";
import classnames from "classnames";
import {
  TestingServiceCardContent,
  TestingServiceCardHeader,
  TestingServiceCardPricing,
  TestingServiceCardPricingItem,
} from "../testing_service_card";
import boltIcon from "./bolt_icon.svg";

type ExpressTestingServiceCardProps = {
  isDisabled: boolean;
};

export default function ExpressTestingServiceCard({ isDisabled }: ExpressTestingServiceCardProps): JSX.Element {
  const cardClasses = classnames(["p-2xl", { "opacity-50": isDisabled }]);

  return (
    <div className="pb-2xl">
      <Card active={isDisabled}>
        <div className={cardClasses}>
          <TestingServiceCardHeader
            testType="express"
            icon={boltIcon}
            testDescription="Results in 6+ hours"
            isUnavailable={isDisabled}
          />
          <TestingServiceCardContent
            items={["Your test goes directly to testers", "Results are received in real time"]}
          />
          <TestingServiceCardPricing>
            <TestingServiceCardPricingItem itemTitle="Exploratory testing" price="244 credits" />
            <TestingServiceCardPricingItem
              itemTitle="Test case execution"
              price="1 credit"
              note="(3 results per test case)"
            />
          </TestingServiceCardPricing>
        </div>
      </Card>
    </div>
  );
}
