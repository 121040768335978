import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";
import { Loader } from "@hopper/loading";
import { Textarea } from "@hopper/textarea";

import { websiteContentPaths } from "components/configuration";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { useProfessionalServiceRequestCreate } from "./use_professional_service_request_create";
import { useProfessionalService } from "./use_professional_service";

type Params = {
  professionalServiceId?: string;
};

export function ServiceRequestFormPage(): JSX.Element {
  const { professionalServiceRequestCreate, isLoading: isButtonLoading } = useProfessionalServiceRequestCreate(
    onProfessionalServiceRequestCreateSuccess,
  );
  const [content, setContent] = useState("");
  const { professionalServiceId } = useParams<Params>();
  const { professionalService, isLoading: isProfessionalServiceLoading } = useProfessionalService(
    professionalServiceId || "",
  );
  const isButtonDisabled = isEmpty(content);
  const history = useHistory();

  const header = (
    <div data-testid="services-header">
      <Heading size={1}>{professionalService?.title}</Heading>
    </div>
  );

  function onProfessionalServiceRequestCreateSuccess(): void {
    history.push(websiteContentPaths.services);
  }

  return isProfessionalServiceLoading ? (
    <Loader />
  ) : (
    <PageTemplate header={header}>
      <div className="text-darkest text-xl font-bold whitespace-pre-wrap">
        {professionalService?.requestFormInstructions}
      </div>
      <div className="pt-2xl">
        <Textarea
          placeholder="Add request details here ..."
          onChange={event => setContent(event.target.value)}
          style={{ minHeight: "15.5rem", width: "600px" }}
        />
      </div>
      <div className="pt-2xl">
        <Button
          disabled={isButtonDisabled}
          isLoading={isButtonLoading}
          onClick={() => professionalServiceRequestCreate(professionalServiceId || "", content)}
        >
          Submit
        </Button>
      </div>
    </PageTemplate>
  );
}
