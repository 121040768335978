import React from "react";
import classnames from "classnames";

type AttachmentThumbnailProps = {
  onClick: (index: number) => void;
  index: number;
  additionalClassNames: string | null | undefined;
  children: React.ReactNode;
};

function AttachmentThumbnail({
  onClick,
  index,
  additionalClassNames,
  children,
}: AttachmentThumbnailProps): JSX.Element {
  return (
    <div
      className={classnames(
        "flex items-center justify-center",
        "border border-light rounded-base",
        "text-secondary cursor-pointer",
        additionalClassNames,
      )}
      style={{ width: "8rem", height: "5rem" }}
      onClick={() => onClick(index)}
      data-testid="attachment-thumbnail"
    >
      {children}
    </div>
  );
}

export default AttachmentThumbnail;
