import React from "react";
import { Button } from "@hopper/button";

type InlineButtonProps = {
  onChatButtonClick: () => void;
  unreadMessagesCount: number;
};

export const InlineButton = ({ onChatButtonClick, unreadMessagesCount }: InlineButtonProps) => (
  <Button
    size="sm"
    variant="secondary"
    className="px-sm mr-md"
    onClick={() => onChatButtonClick()}
    data-testid="open-private-chat-button"
  >
    <i className="fa fa-comment-o" aria-hidden="true" />
    {unreadMessagesCount > 0 && (
      <span className="h-[10px] w-[10px] absolute top-[-3px] right-[-3px] rounded-full bg-danger" />
    )}
  </Button>
);
