import React from "react";
import { useLocation } from "react-router-dom";
import { StandardTestContent, StandardTestInfoSection } from "../standard_test_content";

import queryString from "query-string";
import { AdvancedTestContent } from "../advanced_test_content/advanced_test_content";
import { TestRequestTabs } from "./test_request_tabs";
import { LaunchWizardContent, launchWizardSteps } from "../launch_wizard_content";
import {
  advancedTest,
  PreviewPageAdvancedTestRequest,
  PreviewPageStandardTestRequest,
  standardTest,
} from "../../preview_page/preview_page";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import HeaderSection from "components/flexible_testing/test_request_page/header_section";
import { AdvancedTestInfoSection } from "../advanced_test_content/advanced_test_info_section";

type ContentProps = {
  testRequest?: PreviewPageStandardTestRequest | PreviewPageAdvancedTestRequest;
  isServiceTypeSelectionEnabled: boolean;
};

const allowedSdlcStages = ["design", "development", "release", "live"];

function TestRequestContent({ testRequest, isServiceTypeSelectionEnabled }: ContentProps): JSX.Element {
  const location = useLocation();
  const selectedTab = queryString.parse(location.search).tab;
  const sdlcStageParam = queryString.parse(location.search).sdlc_stage;
  const preselectedSdlcStage = sdlcStageParam && allowedSdlcStages.includes(sdlcStageParam) ? sdlcStageParam : null;
  const initStepWizardByTab =
    (selectedTab === advancedTest && launchWizardSteps.AdvancedTestForm) ||
    (selectedTab === standardTest && launchWizardSteps.StandardTestForm) ||
    null;
  const initStepWizardBySdlc = preselectedSdlcStage && launchWizardSteps.DesiredOutcomesSelection;
  const initWithStep = (testRequest && initStepWizardByTab) || initStepWizardBySdlc || null;

  return (
    <>
      {isServiceTypeSelectionEnabled ? (
        <LaunchWizardContent
          testRequest={testRequest}
          initWithStep={initWithStep}
          initWithSdlcStage={preselectedSdlcStage}
        />
      ) : (
        <PageTemplate header={<HeaderSection shouldShowCloneButton={false} />}>
          <TestRequestTabs selectedTab={selectedTab || standardTest} />
          {selectedTab === advancedTest ? (
            <AdvancedTestContent
              testRequest={testRequest as PreviewPageAdvancedTestRequest}
              infoSection={<AdvancedTestInfoSection />}
            />
          ) : (
            <StandardTestContent
              testRequest={testRequest as PreviewPageStandardTestRequest}
              infoSection={<StandardTestInfoSection />}
            />
          )}
        </PageTemplate>
      )}
    </>
  );
}

export default TestRequestContent;
