import { useField } from "formik";
import React from "react";
import FormLabel from "../form_label";
import { FieldError } from "../field_error";
import classNames from "classnames";

type RadioGroupProps<T extends string> = {
  name: string;
  label: string;
  options: { id?: string; label: string; value: T }[];
  horizontal?: boolean;
  required?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const RadioGroup = <T extends string>({
  label,
  options,
  required,
  horizontal = false,
  name,
  ...props
}: RadioGroupProps<T>) => {
  const [field, meta, helpers] = useField({ name, ...props });

  return (
    <div className="mb-sm" role="radiogroup">
      <FormLabel
        label={label}
        required={required}
        labelProps={{ htmlFor: props.id || name, className: "text-sm mb-sm" }}
      />
      <div className={classNames("flex items-start gap-xs", { "flex-col": horizontal })}>
        {options.map(option => (
          <label
            className="flex cursor-pointer mr-2xl mb-0 text-sm"
            htmlFor={option.id || option.label}
            key={option.value}
          >
            <input
              {...field}
              type="radio"
              id={option.id || option.label}
              onChange={() => helpers.setValue(option.value)}
              checked={field.value === option.value}
              name={name}
              value={option.value}
              data-testid={option.id}
              // @ts-ignore override bootstrap
              style={{ marginTop: 0, marginRight: "0.5rem", accentColor: "#373A40", cursor: "pointer" }}
            />
            {option.label}
          </label>
        ))}
      </div>
      {meta.touched && meta.error && (
        <FieldError fieldName={name} className="text-xs">
          {meta.error}
        </FieldError>
      )}
    </div>
  );
};

export default RadioGroup;
