import React, { useEffect, useRef } from "react";
import { RadioGroup, TextArea, TextInput } from "components/flexible_testing/shared/form";
import { useFormikContext } from "formik";
import TestCaseFields from "./test_case_fields";
import ScopeFields from "./scope_fields";
import { Activity } from "../activity_step_content";
import { RequestedTest } from "../requested_test_form";
import { useEnvironments } from "../targeting_step_content";
import RequestedTestWizardNavigation from "../requested_test_wizard_navigation";

export const ACTIVITIES_REQUIRING_SCOPE = [Activity.Exploratory, Activity.Translation, Activity.Localization];
export const ACTIVITIES_REQUIRING_TEST_CASE_FIELDS = [Activity.TestCaseRun];

const DEFAULT_LABELS = {
  requiresFinancialTransactions: "Do testers need to conduct financial transactions?",
  requiresKycIdentityCheck: "Do testers need to go through a KYC/identity check?",
  requiresPersonalIdentifyingInformation: "Do testers need to provide personal identifying information?",
  requiresMovingToDifferentLocation: "Do testers need to move to a different location during the test?",
} as const;

const LABELS_BY_ACTIVITY = {
  [Activity.Exploratory]: DEFAULT_LABELS,
  [Activity.Localization]: DEFAULT_LABELS,
  [Activity.Translation]: DEFAULT_LABELS,
  [Activity.TestCaseRun]: {
    requiresFinancialTransactions: "Do your test cases require financial transactions?",
    requiresKycIdentityCheck: "Do your test cases involve a KYC/identity check?",
    requiresPersonalIdentifyingInformation: "Do your test cases require personal identifying information?",
    requiresMovingToDifferentLocation: "Do your test cases require moving to a different location?",
  },
} as const;

const ScopeStepContent = ({ nextStep }: { nextStep: () => unknown }) => {
  const { isValid, values, validateForm } = useFormikContext<RequestedTest>();
  const activity = values.activity;
  const initialRender = useRef<boolean>(true);

  useEffect(() => {
    initialRender.current = false;
    validateForm();
  }, []);

  useEnvironments({ platform: values.applicationType, skip: initialRender.current || !isValid });

  return (
    <div data-testid="scope-step-content">
      <div className="grid grid-cols-2 gap-4xl">
        <div className="flex flex-col gap-lg" style={{ maxWidth: "430px" }}>
          {ACTIVITIES_REQUIRING_TEST_CASE_FIELDS.includes(activity) && <TestCaseFields />}
          {ACTIVITIES_REQUIRING_SCOPE.includes(activity) && <ScopeFields />}
          <RadioGroup
            name="requiresPredefinedUserAccounts"
            label="Do testers need to test with existing user accounts that you have created?"
            options={[
              { id: "requiresPredefinedUserAccountsYes", label: "Yes", value: "true" },
              { id: "requiresPredefinedUserAccountsNo", label: "No", value: "false" },
            ]}
            required
          />
          {values.requiresPredefinedUserAccounts === "true" && (
            <div>
              <TextArea
                name="predefinedUserAccountsInstructions"
                placeholder="e.g., Admin - username & password"
                label="What are the account types and credentials?"
                description="This information will be shown directly to testers"
                useHopperInput={true}
                required
              />
            </div>
          )}
          <RadioGroup
            name="hasKnownIssuesList"
            label="Do you have a known issues list?"
            options={[
              { id: "hasKnownIssuesListYes", label: "Yes", value: "true" },
              { id: "hasKnownIssuesListNo", label: "No", value: "false" },
            ]}
            required
          />
          {values.hasKnownIssuesList === "true" && (
            <div>
              <TextInput
                name="knownIssuesListUrl"
                placeholder="https://known-issues-list.com"
                label="What is the URL where we can find the known issues list?"
                required
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-lg">
          <RadioGroup
            name="requiresFinancialTransactions"
            label={LABELS_BY_ACTIVITY[activity]["requiresFinancialTransactions"]}
            options={[
              { id: "requiresFinancialTransactionsYes", label: "Yes", value: "true" },
              { id: "requiresFinancialTransactionsNo", label: "No", value: "false" },
            ]}
            required
          />
          {values.requiresFinancialTransactions === "true" && (
            <div>
              <TextArea
                name="financialTransactionsInstructions"
                placeholder="e.g., Google Pay"
                label="What payment method(s) do the testers need to use?"
                useHopperInput={true}
                required
              />
            </div>
          )}
          <RadioGroup
            name="requiresKycIdentityCheck"
            label={LABELS_BY_ACTIVITY[activity]["requiresKycIdentityCheck"]}
            options={[
              { id: "requiresKycIdentityCheckYes", label: "Yes", value: "true" },
              { id: "requiresKycIdentityCheckNo", label: "No", value: "false" },
            ]}
            required
          />
          {values.requiresKycIdentityCheck === "true" && (
            <div>
              <TextArea
                name="kycIdentityCheckInstructions"
                placeholder="e.g., A valid passport"
                label="What KYC/Identity documents and data are needed?"
                useHopperInput={true}
                required
              />
            </div>
          )}
          <RadioGroup
            name="requiresPersonalIdentifyingInformation"
            label={LABELS_BY_ACTIVITY[activity]["requiresPersonalIdentifyingInformation"]}
            options={[
              { id: "requiresPersonalIdentifyingInformationYes", label: "Yes", value: "true" },
              { id: "requiresPersonalIdentifyingInformationNo", label: "No", value: "false" },
            ]}
            required
          />
          {values.requiresPersonalIdentifyingInformation === "true" && (
            <div>
              <TextArea
                name="personalIdentifyingInformationInstructions"
                placeholder="e.g., Passport number"
                label="What information do the testers need to provide?"
                useHopperInput={true}
                required
              />
            </div>
          )}
          <RadioGroup
            name="requiresMovingToDifferentLocation"
            label={LABELS_BY_ACTIVITY[activity]["requiresMovingToDifferentLocation"]}
            options={[
              { id: "requiresMovingToDifferentLocationYes", label: "Yes", value: "true" },
              { id: "requiresMovingToDifferentLocationNo", label: "No", value: "false" },
            ]}
            required
          />
          {values.requiresMovingToDifferentLocation === "true" && (
            <div>
              <TextArea
                name="movingToDifferentLocationInstructions"
                placeholder="e.g., Travel to a nearby retail location"
                label="What do the testers need to do?"
                useHopperInput={true}
                required
              />
            </div>
          )}
        </div>
      </div>
      <RequestedTestWizardNavigation onSubmit={nextStep} submitDisabled={!isValid} />
    </div>
  );
};

export default ScopeStepContent;
