import React, { useState } from "react";
import { Form, TextInput } from "components/flexible_testing/shared/form";
import { useTestCycleToTestLink } from "./use_test_cycle_to_test_link";
import { Button } from "@hopper/button";

type LinkTestCycleFormProps = {
  flexibleTestingTestId: string;
};

const LinkTestCycleForm = ({ flexibleTestingTestId }: LinkTestCycleFormProps) => {
  const { testCycleToTestLink } = useTestCycleToTestLink();
  const [error, setError] = useState(null);

  return (
    <div className="mt-md">
      <Form
        initialValues={{
          testCycleCode: "",
        }}
        onSubmit={async (values: { [field: string]: unknown }) => {
          const result = await testCycleToTestLink(flexibleTestingTestId, values.testCycleCode as string);

          if (!result.successful) {
            setError(result["errors"][0]["message"]);
          }
        }}
        className="px-lg sm:px-0 mb-md"
      >
        <TextInput
          name="testCycleCode"
          label="Link an existing test cycle with code"
          placeholder="Enter the test cycle code"
          labelClass="font-normal"
        />
        <p className="text-danger mb-sm">{error}</p>
        <div>
          <Button type="submit" variant="primary" size="sm">
            Link test cycle
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default LinkTestCycleForm;
