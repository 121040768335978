import { gql } from "@apollo/client";

export const GET_FEEDBACK_TAB_DATA = gql`
  query getPlatformTestFeedback($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        testerSessions {
          edges {
            node {
              id
              feedback
            }
          }
        }
      }
      ... on FlexibleTestingTest {
        id
        testerSessions {
          edges {
            node {
              id
              feedback
            }
          }
        }
      }
    }
  }
`;
