import React from "react";
import { FormikErrors } from "formik";
import { Loader } from "@hopper/loading";

import { TesterGroupParams } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import { useCurrentUser } from "app/current_user/use_current_user";
import AutomaticSelectionSwitch from "./automatic_selection_switch";
import ManualSelectionFeedbackForm from "./manual_selection_feedback_form";
import { TesterGroupData } from "./tester_group_list";

type AutomaticSelectionSwitchSectionProps = {
  fieldName: string;
  testerGroup: TesterGroupData;
  errors: FormikErrors<TesterGroupParams>;
};

const AutomaticSelectionSwitchSection = ({ fieldName, testerGroup, errors }: AutomaticSelectionSwitchSectionProps) => {
  const currentUser = useCurrentUser();
  const requiresFeedback =
    !testerGroup.automaticTesterSelectionEnabled && !testerGroup.automaticTesterSelectionNotEnoughTesters;

  if (!currentUser) return <Loader />;

  return (
    <div data-testid={`automatic-selection-switch-section-${testerGroup.name}`}>
      <AutomaticSelectionSwitch fieldName={fieldName} testerGroup={testerGroup} />
      {requiresFeedback && (
        <div className="my-md">
          <span className="text-link">{currentUser.email}</span> has disabled automatic tester management for this test.
          Testers need to be manually selected and managed throughout this test.
          <ManualSelectionFeedbackForm fieldName={fieldName} groupName={testerGroup.name} errors={errors} />
        </div>
      )}
    </div>
  );
};

export default AutomaticSelectionSwitchSection;
