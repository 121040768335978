import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { Form, SubmitButton, TextArea, TextInput, ClearButton } from "../../shared/form";
import { TestRequestErrorModal } from "../../shared/test_request_error_modal";
import { TestCases } from "./test_cases";
import { TestCasesFileError } from "components/flexible_testing/shared/test_cases_file_error";
import { TestDetails } from "./test_details";
import { ScheduleTestSection } from "../../shared/schedule_test_section";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { PreviewPageStandardTestRequest, standardTest } from "../../preview_page/preview_page";
import { launchTestFormCommonValidations } from "components/flexible_testing/shared/validations";
import { websiteContentPaths } from "components/configuration";
import { DesiredOutcomeCode, SoftwareDevelopmentLifeCycleStageName } from "types/graphql";

type StandardTestContentProps = {
  testRequest?: PreviewPageStandardTestRequest;
  infoSection?: JSX.Element;
  desiredOutcomes?: Array<DesiredOutcomeCode>;
  sdlcStage?: SoftwareDevelopmentLifeCycleStageName;
};

function ActionButtons(): JSX.Element {
  return (
    <>
      <SubmitButton className="mt-3xl mr-xl" useHopperButton={true} style={{ pointerEvents: "auto" }}>
        Preview and launch test
      </SubmitButton>
      <ClearButton>Clear All</ClearButton>
    </>
  );
}

function StandardTestContent({
  testRequest,
  infoSection,
  desiredOutcomes,
  sdlcStage,
}: StandardTestContentProps): JSX.Element {
  const initialTestCaseSectionNumber = 4;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const history = useHistory();

  const handleSubmit = (values: { [field: string]: unknown }): Promise<void> => {
    const {
      applicationUrl,
      name,
      description,
      includeTestCases,
      testCases,
      scheduleAt,
      isTestScheduled,
      credentialsAndAccessInstructions,
      additionalUrlsAndResources,
      testEnvironments,
      desiredOutcomes,
      sdlcStage,
    } = values;
    const testRequest = {
      applicationUrl,
      name,
      description,
      credentialsAndAccessInstructions,
      additionalUrlsAndResources,
      testCases: includeTestCases ? testCases : [],
      testEnvironments,
      scheduleAt,
      isTestScheduled,
      desiredOutcomes,
      sdlcStage,
    };

    history.push(websiteContentPaths.testPreview, {
      ...testRequest,
      testCasesFile: null,
      testType: standardTest,
    } as PreviewPageStandardTestRequest);

    return new Promise(resolve => resolve());
  };

  return (
    <div data-testid="standard-test-content">
      {infoSection}
      <Form
        initialValues={{
          applicationUrl: testRequest?.applicationUrl || "",
          name: testRequest?.name || "",
          credentialsAndAccessInstructions: testRequest?.credentialsAndAccessInstructions || "",
          additionalUrlsAndResources: testRequest?.additionalUrlsAndResources || "",
          description: testRequest?.description || "",
          includeTestCases:
            testRequest?.testCasesFile || (testRequest?.testCases && testRequest?.testCases.length > 0) ? true : false,
          testCasesFile: testRequest?.testCasesFile ? testRequest?.testCasesFile : null,
          testCases: testRequest?.testCases ? testRequest?.testCases : null,
          testEnvironments: testRequest?.testEnvironments || [],
          isTestScheduled: testRequest?.isTestScheduled === true,
          sdlcStage: testRequest?.sdlcStage || sdlcStage,
          desiredOutcomes: testRequest?.desiredOutcomes || desiredOutcomes,
        }}
        validations={yup.object({
          ...launchTestFormCommonValidations,
          credentialsAndAccessInstructions: yup
            .string()
            .trim()
            .required("Credentials or access instructions are required")
            .max(5000, "Cannot be longer than 5000 characters."),
        })}
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <TestDetails />
            <PageSection title="Give your test run a name" className="mb-2xl">
              <div data-test-role="form-group" className="text-xs">
                <TextInput
                  name="name"
                  placeholder="e.g. Account sign-up flow"
                  label="Test run name"
                  required={true}
                  labelClass="font-normal"
                />
              </div>
            </PageSection>
            <ScheduleTestSection initScheduleAt={testRequest?.scheduleAt} />
            <ActionButtons />
          </div>

          <div>
            <div data-test-role="form-group">
              <PageSection title="What would you like to include in this test run?">
                <TextArea
                  name="description"
                  placeholder="What is the goal or objective of this test run?"
                  label="Describe the goal of this test run"
                  useHopperInput={true}
                  required={false}
                />
              </PageSection>
            </div>

            <TestCases initialTestCaseSectionNumber={initialTestCaseSectionNumber} />
            <TestCasesFileError />
            <TestRequestErrorModal isOpen={showErrorModal} onCloseModalClicked={() => setShowErrorModal(false)} />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default StandardTestContent;
