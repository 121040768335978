import React from "react";
import { Base64 } from "js-base64";
import { useFormikContext } from "formik";
import { Button } from "@hopper/button";

import { TextInput } from "../../shared/form";
import { azureDevopsRedirectUrl } from "components/configuration";
import { PageSection } from "../../shared/page_section";
import warningIcon from "assets/images/icon--warning.svg";

type AuthenticationFieldsProps = {
  isHostedOnAdo: boolean;
  credentialsCreateError?: string;
  credentialsKey?: string;
  isEditable: boolean;
};

function AuthenticationFields({
  isHostedOnAdo,
  credentialsCreateError,
  isEditable,
  credentialsKey,
}: AuthenticationFieldsProps) {
  const { values } = useFormikContext();

  function redirectUrl() {
    const encodedFormState = Base64.encodeURI(
      JSON.stringify({
        values,
        isEditable,
      }),
    );

    return `${azureDevopsRedirectUrl}&state=${encodedFormState}`;
  }

  function renderOauthButton() {
    return (
      <div className="mb-4xl">
        {credentialsCreateError && (
          <div className="mb-lg text-xs text-dark">
            <img
              src={warningIcon}
              data-testid="credentials-warning-icon"
              className="mr-sm inline-block"
              alt="Warning icon"
              style={{ height: "16px", width: "16px" }}
            />
            We were unable to authenticate to Azure DevOps
          </div>
        )}

        {(!isEditable || credentialsKey) && (
          <div className="mb-lg text-xs text-dark">
            <i data-testid="credentials-success-icon" className="fa fa-check text-success mr-sm" />
            Authenticated via OAuth 2
          </div>
        )}

        {isEditable && !credentialsKey && (
          <Button variant="secondary" href={redirectUrl()}>
            Grant access via OAuth 2
          </Button>
        )}
      </div>
    );
  }

  function renderAccessTokenFields() {
    return (
      <div className="mb-4xl">
        <div data-test-role="form-group" className="mb-lg">
          <TextInput
            id="username"
            name="username"
            label="What is your username?"
            labelClass="text-sm font-normal"
            className="form-control max-w-xs mb-sm"
            placeholder="eg. john.smith"
            disabled={!isEditable}
            data-testid="username-input"
            required={true}
          />
        </div>
        <div data-test-role="form-group">
          <TextInput
            id="accessToken"
            name="accessToken"
            type="password"
            label="What is your access token?"
            labelClass="text-sm font-normal"
            className="form-control max-w-xs mb-sm"
            placeholder="eg. 5ywb4m6fwttgv6..."
            disabled={!isEditable}
            required={true}
          />
        </div>
      </div>
    );
  }

  return (
    <PageSection title="Authentication" orderNumber={2}>
      {isHostedOnAdo ? renderOauthButton() : renderAccessTokenFields()}
    </PageSection>
  );
}

export default AuthenticationFields;
