import { TestCase } from "types/test_cases";
import { DesiredOutcomeCode, FlexibleTestingTest, SoftwareDevelopmentLifeCycleStageName } from "types/graphql";

export type StandardTest = {
  applicationUrl: string;
  name: string;
  description: string;
  testCases: Array<TestCase>;
  credentialsAndAccessInstructions: string;
  additionalUrlsAndResources: string;
  testEnvironments: string[];
  capabilities?: string[];
  sdlcStage?: SoftwareDevelopmentLifeCycleStageName;
  desiredOutcomes?: DesiredOutcomeCode[];
};

export type AdvancedTest = {
  accessToApplication: string;
  testObjective: string;
  name: string;
  notes: string;
  platformsToTestOn: string;
  deviceRequirements: string;
  testLocalisation: string;
  testCasesUrl: string;
  knownIssuesUrl: string;
  capabilities?: string[];
  sdlcStage?: SoftwareDevelopmentLifeCycleStageName;
  desiredOutcomes?: DesiredOutcomeCode[];
  customDesiredOutcomes?: string;
};

export function getPathForCloning(test: FlexibleTestingTest) {
  if (test.type === "Supervised Test") {
    return `/test_managers/advanced_tests/${test.parentTestId}/launch_child`;
  } else if (test.type === "Advanced Test") {
    return `/launch?tab=advanced_test`;
  } else if (test.type === "Standard Test") {
    return `/launch?tab=standard_test`;
  } else {
    return "";
  }
}
