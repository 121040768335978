import React from "react";
import { Test } from "types/graphql";
import sortBySeverity from "components/flexible_testing/shared/sort_by_severity/sort_by_severity";
import { IssuesSummary } from "components/flexible_testing/shared/issues_summary";
import IssuesList from "components/flexible_testing/result_details_page/summary/issues_list";
import { Button } from "@hopper/button";

type IssuesSectionProps = {
  test?: Test;
  onAllIssuesClick: () => void;
};

function IssuesSection({ test, onAllIssuesClick }: IssuesSectionProps): JSX.Element {
  const issueEdges = test?.issues?.edges || [];
  const issuesTotalCount = test?.testStats.issuesTotalCount || 0;
  const issues = [...issueEdges.map(x => x.node)]
    .sort((issue1, issue2) => sortBySeverity(issue1.severity, issue2.severity))
    .slice(0, 5);

  return (
    <section className="flex flex-col gap-2xl">
      <IssuesSummary
        issuesStats={{
          issuesTotalCount,
          issuesCriticalCount: test?.testStats.issuesCriticalSeverityCount || 0,
          issuesHighCount: test?.testStats.issuesHighSeverityCount || 0,
          issuesMediumCount: test?.testStats.issuesMediumSeverityCount || 0,
          issuesLowCount: test?.testStats.issuesLowSeverityCount || 0,
        }}
      />
      <IssuesList issues={issues} testId={test?.id} />
      {issuesTotalCount > 0 && (
        <Button variant="primary" appearance="minimal" onClick={onAllIssuesClick}>
          View all issues
        </Button>
      )}
    </section>
  );
}

export default IssuesSection;
