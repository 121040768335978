import React from "react";

type TextTruncateProps = {
  text: string;
  maxLength: number;
};

const TextTruncate = ({ text, maxLength }: TextTruncateProps) => {
  const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return <>{truncatedText}</>;
};

export default TextTruncate;
