import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingUpdateIssueTagInput, FlexibleTestingUpdateIssueTagPayload } from "types/graphql";
import { ISSUE_TAGS_QUERY } from "./use_issue_tags";
import { GET_ISSUES_TAB_DATA } from "components/flexible_testing/result_details_page/issues";

export const UPDATE_TAG = gql`
  mutation FlexibleTestingUpdateIssueTag($input: FlexibleTestingUpdateIssueTagInput!) {
    flexibleTestingUpdateIssueTag(input: $input) {
      successful
      result {
        id
        name
      }
    }
  }
`;

type useUpdateIssueTagResult = {
  updateIssueTag: (tagId: string, tagName: string) => Promise<void>;
};

export const useUpdateIssueTag = (testId: string | undefined): useUpdateIssueTagResult => {
  const [updateIssueTagMutation] = useMutation<
    { flexibleTestingUpdateIssueTag: FlexibleTestingUpdateIssueTagPayload },
    { input: FlexibleTestingUpdateIssueTagInput }
  >(UPDATE_TAG, {
    refetchQueries: () => [{ query: ISSUE_TAGS_QUERY }, { query: GET_ISSUES_TAB_DATA, variables: { testId } }],
  });

  return {
    updateIssueTag: async (tagId: string, tagName: string): Promise<void> => {
      await updateIssueTagMutation({
        variables: {
          input: {
            issueTagId: tagId,
            name: tagName,
          },
        },
      });
    },
  };
};
