import React, { useState } from "react";
import { Button } from "@hopper/button";
import { Modal, ModalHeader } from "@hopper/legacy_modal";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

import { TextArea } from "components/flexible_testing/shared/form";
import { useDisableAutomaticTesterManagement } from "./use_disable_automatic_tester_management";
import { Test } from "types/graphql";

type ConfirmationModalProps = {
  value: string;
  onClose: () => void;
  isOpen: boolean;
};

const reasonValidator = yup.string().trim().required();

const ConfirmationModal = ({ value, onClose, isOpen }: ConfirmationModalProps) => (
  <Modal
    isShowingCloseButton={true}
    onCloseButtonClicked={onClose}
    isOpen={isOpen}
    position="center"
    size="lg"
    onOverlayClicked={onClose}
  >
    <div className="pl-2xl">
      <ModalHeader>Help us improve this feature</ModalHeader>
      <p className="mb-2xl mt-xs">
        The automated system that manages backup testers is designed to reduce test manager overhead. Providing a reason
        why you are unable to use the automated system will help us identify ways to improve it soon and save you time &
        effort
      </p>
      <Form>
        <TextArea
          name="reason"
          label="Please provide a reason"
          required={true}
          placeholder="Reason for not using automatic management of backup testers"
          className="w-full p-sm"
        />
        <div className="flex items-beginning justify-end mt-2xl">
          <Button variant="danger" className="mr-xl" type="submit" disabled={!reasonValidator.isValidSync(value)}>
            Disable automatic tester management
          </Button>
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  </Modal>
);

const DisableAutomaticTesterManagementButton = ({ testId }: { testId: Test["id"] }) => {
  const [showModal, setShowModal] = useState(false);
  const [disableAutomaticTesterManagement] = useDisableAutomaticTesterManagement();

  return (
    <>
      <Button
        className="text-sm my-sm px-0"
        variant="primary"
        appearance="minimal"
        onClick={() => setShowModal(true)}
        type="button"
      >
        I want to manage backup testers manually
      </Button>

      <Formik
        initialValues={{ reason: "" }}
        validationSchema={yup.object({ reason: reasonValidator })}
        onSubmit={async ({ reason }) => {
          const { successful } = await disableAutomaticTesterManagement(testId, reason);

          if (successful) {
            notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
              type: "success",
              message: "Automatic testers selection has been disabled.",
            });
            setShowModal(false);
          } else
            notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
              type: "error",
              message: "Something went wrong. Please contact with support.",
            });
        }}
      >
        {({ values }) => (
          <ConfirmationModal onClose={() => setShowModal(false)} isOpen={showModal} value={values.reason} />
        )}
      </Formik>
    </>
  );
};

export default DisableAutomaticTesterManagementButton;
