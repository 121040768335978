import React from "react";
import TransactionRow from "../transaction_row";
import { Table, Header, HeaderCell, Body } from "components/flexible_testing/shared/table";
import { Transaction } from "types/graphql";

export type TransactionsListProps = {
  transactions: Transaction[];
};

const TransactionsList = ({ transactions }: TransactionsListProps): JSX.Element => (
  <div className="max-w-xl">
    {transactions.length > 0 ? (
      <Table>
        <Header data-testid="transactions-list-header">
          <HeaderCell className="pl-xs">Transactions details</HeaderCell>
          <HeaderCell className="text-center" style={{ width: "150px" }}>
            Amount
          </HeaderCell>
          <HeaderCell style={{ width: "60px" }}>Balance</HeaderCell>
        </Header>
        <Body>
          {transactions.map(transaction => (
            <TransactionRow key={transaction.createdAt} transaction={transaction} />
          ))}
        </Body>
      </Table>
    ) : (
      <div>
        Your credit usage history is empty. Please contact customer support to add credits to your account and start
        testing
      </div>
    )}
  </div>
);

export default TransactionsList;
