import { Events } from "components/configuration";
import { useTrackingModeration } from "./use_tracking_moderation";

export function useTrackingMarkAsPassed() {
  const eventName = Events.TEST_CASE_RESULT_MARKED_AS_PASSED;
  const { tracking, organizationUserEmail, trackingCaseResultId, trackingTestId } = useTrackingModeration();

  const trackMarkAsPassed = () => {
    tracking.trackEvent({
      action: "track",
      name: eventName,
      eventProperties: {
        test_id: trackingTestId,
        case_result_id: trackingCaseResultId,
        organization_user_email: organizationUserEmail,
      },
    });
  };

  return trackMarkAsPassed;
}
