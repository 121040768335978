import React from "react";

import { FlexibleTestingCaseResultRelatedIssue } from "types/graphql";
import {
  FlexibleTestingTestCaseResult,
  TestCaseFields,
} from "components/flexible_testing/test_case_result_page/use_test_case_result";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { statuses } from "components/flexible_testing/shared/status_indicator";
import TestCaseInstructions from "components/flexible_testing/test_case_result_page/test_case_instructions";
import { Attachments } from "components/flexible_testing/shared/attachments";
// @ts-ignore
import { TestCaseResponseRejectionReasonLabel } from "../test_case_response_rejection_reason_label";
import { TestCaseResponseRejectionButton } from "../test_case_response_rejection_button";
import { CaseResultStatusSection } from "components/flexible_testing/shared/case_result/case_result_status_section";
import { OverallResult } from "components/flexible_testing/shared/case_result/overall_result";
import { CaseResultTimeAndCountry } from "components/flexible_testing/shared/case_result/case_result_time_and_country";
import { generateCaseInstructionsWithResponses } from "components/flexible_testing/shared/case_result/generate_step_instructions_with_responses";
import TextBasedAdditionalInformation from "./text_based_additional_information";
import { Heading } from "@hopper/heading";
import { DecoratedText } from "@hopper/decorated_text";
import { Link } from "react-router-dom";

type TestCaseResultProps = {
  caseResult: FlexibleTestingTestCaseResult;
  tabIndex: number;
  selectedTab: number;
  caseFields: TestCaseFields;
  prerequisites?: string | null;
};

const CaseResult = ({
  caseResult,
  tabIndex,
  selectedTab,
  caseFields,
  prerequisites,
}: TestCaseResultProps): JSX.Element => {
  const testerEnvironment = caseResult.testerEnvironment;
  const caseResultAttachments =
    caseResult.caseResultAttachments?.edges
      ?.filter(edge => edge?.node?.archived === false)
      ?.map(edge => edge?.node?.attachment?.url || {}) || [];
  const caseFieldResponsetAttachments =
    caseResult.caseFieldAttachmentResponses?.edges
      ?.filter(edge => edge?.node?.archived === false)
      ?.map(edge => edge?.node?.attachment?.url || {}) || [];
  const attachments = [...caseResultAttachments, ...caseFieldResponsetAttachments];
  const relatedIssues = caseResult.relatedIssues;
  const status = caseResult?.status as typeof statuses[number];
  const additionalInformationResultFieldResponses = caseResult?.additionalInformationResultFieldResponses || [];

  const statusLabelPrefix =
    {
      pass: "Passed by",
      fail: "Failed by",
      blocked: "Blocked by",
      not_tested: "Not tested",
      live: "Live",
      missing: "Missing",
      skipped: "Skipped by",
    }[status] || "Tested by";

  const shouldShowRelatedIssues = relatedIssues?.length > 0;

  const renderRelatedIssueLink = (relatedIssue: FlexibleTestingCaseResultRelatedIssue) => (
    <Link to={new URL(relatedIssue.url).pathname} className="pl-0 text-link">
      {relatedIssue.label}
    </Link>
  );

  const stepResponses = generateCaseInstructionsWithResponses(
    caseResult.stepCaseResultFieldResponses,
    caseFields.stepCaseFields || [],
  );
  const testerName = caseResult?.tester?.fullName;

  const shouldShowTextBasedAdditionalInformation = caseFields.additionalInformationCaseFields.some(
    field => field.id !== "",
  );

  return (
    <>
      {selectedTab === tabIndex && (
        <div className="grid grid-cols-1 md:grid-cols-2 mt-3xl">
          <div className="mr-4xl">
            <CaseResultStatusSection
              statusLabel={`${statusLabelPrefix} ${testerName}`}
              statusCode={status}
              testerEnvironment={testerEnvironment}
            />
            <Attachments attachments={attachments} />
            <div className="border-t border-b border-light mt-4xl">
              <OverallResult
                status={status}
                actualResult={caseResult.actualResult}
                additionalInformation={caseResult.additionalInformation}
              />
              {shouldShowTextBasedAdditionalInformation && (
                <TextBasedAdditionalInformation
                  additionalInformationCaseFields={caseFields.additionalInformationCaseFields}
                  additionalInformationResultFieldResponses={additionalInformationResultFieldResponses}
                />
              )}
              <div className="grid grid-cols-1 my-xl text-dark text-sm md:grid-cols-2">
                <CaseResultTimeAndCountry
                  testerCountry={caseResult.testerCountry}
                  completedAt={caseResult.completedAt}
                />
              </div>
              {shouldShowRelatedIssues && (
                <DetailsField label="Related issues" testId="related-issues" paddingClass="pt-md">
                  {relatedIssues.map(renderRelatedIssueLink)}
                </DetailsField>
              )}
            </div>
          </div>
          <div>
            <div className="pb-2xl pull-right">
              {caseResult.isRejected ? (
                <TestCaseResponseRejectionReasonLabel caseResult={caseResult} />
              ) : (
                <TestCaseResponseRejectionButton caseResult={caseResult} />
              )}
            </div>
            <></>
            {prerequisites && (
              <div className="my-3xl" style={{ clear: "both" }} data-testid="test-case-prerequisites">
                <Heading size={5}>Prerequisites</Heading>
                <DecoratedText text={prerequisites} />
              </div>
            )}
            <TestCaseInstructions resultFieldResponses={stepResponses} />
          </div>
        </div>
      )}
    </>
  );
};

export default CaseResult;
