import { useQuery } from "@apollo/client";

import { FlexibleTestingTestBlocker, FlexibleTestingTest, OrganizationUser } from "types/graphql";
import { GET_TEST_BLOCKERS } from "./test_blockers_tab_data_query";

function useTestBlockers(testId?: string, user?: OrganizationUser) {
  const { loading, data } = useQuery(GET_TEST_BLOCKERS, {
    variables: { testId },
    skip: !testId || !(user?.testManager || user?.communityTestManager),
  });
  const testBlockers = extractBlockers(data?.platformTest);
  const openTestBlockersCount = testBlockers.filter(blocker => blocker.status === "open").length;

  return {
    isLoading: loading,
    testBlockers,
    openTestBlockersCount,
  };
}

function extractBlockers(platformTest?: FlexibleTestingTest): FlexibleTestingTestBlocker[] {
  return platformTest?.blockers?.edges?.map(blocker => blocker?.node).filter(blocker => !!blocker) || [];
}

export { useTestBlockers };
