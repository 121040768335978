import React from "react";
import { DecoratedText } from "@hopper/decorated_text";
import { Loader } from "@hopper/loading";

import { Table, Header, HeaderCell, Body, Row, BodyCell } from "components/flexible_testing/shared/table";
import NoTesterFeedbackMessage from "../no_tester_feedback_message";
import { FlexibleTestingTestWindow, FlexibleTestingTesterSession } from "types/graphql";

type TesterFeedbackListProps = {
  testWindow: FlexibleTestingTestWindow;
  isLoading: boolean;
  testerFeedback: FlexibleTestingTesterSession[];
};

function TesterFeedbackList({ testWindow, isLoading, testerFeedback }: TesterFeedbackListProps): JSX.Element {
  if (isLoading) {
    return <Loader />;
  }

  return testerFeedback.length > 0 ? (
    <div className="mt-2xl sm:mt-4xl xl:mt-5xl mb-4xl">
      <Table>
        <Header data-testid="tester-feedback-list-header">
          <HeaderCell>FEEDBACK FROM TESTERS</HeaderCell>
        </Header>
        <Body>
          {testerFeedback.map(oneTesterFeedback => (
            <Row testId="tester-feedback-row">
              <BodyCell>
                <div className="flex items-center flex-grow text-base text-darker leading-xl">
                  <DecoratedText text={oneTesterFeedback.feedback || ""} />
                </div>
              </BodyCell>
            </Row>
          ))}
        </Body>
      </Table>
    </div>
  ) : (
    <NoTesterFeedbackMessage isTestOpen={testWindow?.status === "open"} isTestClosed={!!testWindow?.closedAt} />
  );
}

export default TesterFeedbackList;
