import React from "react";
import classnames from "classnames";
import { Spinner } from "@hopper/loading";

type ButtonLinkProps = {
  children: React.ReactNode;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "success" | "danger" | "info";
  size?: "sm" | "md";
  isLoading?: boolean;
  onClick: () => void;
};

function ButtonLink({ children, variant, isLoading, size, disabled, onClick }: ButtonLinkProps) {
  const DEFAULT_FONT_SIZE = "sm";
  const FONT_SIZE_STYLES = {
    sm: "text-sm",
    md: "text-md",
  };
  const DEFAULT_TEXT_COLOR = "primary";
  const VARIANTS_TEXT_COLORS = {
    primary: "text-link",
    secondary: "text-secondary",
    success: "text-success",
    danger: "text-danger",
    info: "text-info",
  };
  const SPINNER_SIZES = {
    sm: 30,
    md: 40,
  };

  const disabledStyle = `${disabled ? " text-muted cursor-not-allowed" : ""}`;

  const buttonLinkClasses = classnames([
    FONT_SIZE_STYLES[size || DEFAULT_FONT_SIZE],
    VARIANTS_TEXT_COLORS[variant || DEFAULT_TEXT_COLOR],
    disabledStyle,
  ]);

  if (isLoading) {
    return <Spinner size={SPINNER_SIZES[size || DEFAULT_FONT_SIZE]} />;
  } else {
    return (
      <button className={buttonLinkClasses} onClick={onClick} disabled={disabled}>
        {children}
      </button>
    );
  }
}

export default ButtonLink;
