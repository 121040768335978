import React from "react";
import { Button } from "@hopper/button";
import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingTestCaseResult } from "components/flexible_testing/tester_overview/use_flexible_testing_test_case_result";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

export const TOGGLE_TEST_CASE_RESULT_APPROVAL_STATUS = gql`
  mutation toggleTestCaseResultApprovalStatus($input: ToggleTestCaseResultApprovalStatusInput!) {
    toggleTestCaseResultApprovalStatus(input: $input) {
      successful
      result {
        id
        moderationStatus
        testParticipant {
          id
          approvedTestCasesCount
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

const ModerationStatus = ({ caseResult }: { caseResult: FlexibleTestingTestCaseResult }) => {
  const [toggleTestCaseResultApprovalStatus, { loading }] = useMutation(TOGGLE_TEST_CASE_RESULT_APPROVAL_STATUS);

  const toggleApprovalStatus = () => {
    toggleTestCaseResultApprovalStatus({
      variables: {
        input: {
          testCaseResultId: caseResult.id,
        },
      },
      onCompleted({ toggleTestCaseResultApprovalStatus: { errors } }) {
        if (errors.length) {
          notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
            type: "error",
            message: errors[0].message,
          });
        }
      },
    });
  };

  if (caseResult.moderationStatus === "discarded") {
    return null;
  } else if (caseResult.moderationStatus === "approved") {
    return (
      <div className="flex justify-between items-center">
        <span role="status">✅ Approved</span>
        <Button variant="secondary" appearance="ghost" onClick={toggleApprovalStatus} isLoading={loading}>
          Remove approval
        </Button>
      </div>
    );
  } else {
    return (
      <div className="flex justify-end items-center">
        <Button onClick={toggleApprovalStatus} isLoading={loading}>
          Approve
        </Button>
      </div>
    );
  }
};

export default ModerationStatus;
