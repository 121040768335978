import { useMutation } from "@apollo/client";

import { INTEGRATION_UPDATE } from "components/shared/integration/integration_queries";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { IntegrationUpdateInput } from "types/graphql";

type UseIntegrationUpdateResult = {
  updateIntegration: (params: IntegrationUpdateInput) => Promise<void>;
};

export const useIntegrationUpdate = (): UseIntegrationUpdateResult => {
  const [updateIntegration] = useMutation(INTEGRATION_UPDATE, {
    onCompleted: ({ integrationUpdate: { successful } }) => {
      if (successful) {
        notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
          type: "success",
          message: "Changes saved successfully.",
        });
      }
    },
    onError: () => {
      notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
        type: "danger",
        message: "Configuration change unsuccessful, please try again later.",
      });
    },
  });

  return {
    updateIntegration: async (params: IntegrationUpdateInput): Promise<void> => {
      await updateIntegration({
        variables: {
          input: params,
        },
      });
    },
  };
};
