import React, { Component } from "react";
import * as yup from "yup";

import ExportIntegrationForm from "../export_integration_form";
import JiraConnectExportFields from "./jira_connect_export_fields";

const validations = yup.object({
  project_key: yup.string().required("Project can't be blank"),
  issuetype_name: yup.string().required("Issue Type can't be blank"),
  labels: yup.string().nullable().max(255),
});

class JiraConnectExportForm extends Component {
  render() {
    const clientAuthentication = this.props.integration.clientAuthentication || {};
    const { projectKey, issuetypeName, labels } = clientAuthentication;
    const {
      runs_export_attributes: { projects },
      id: integrationId,
    } = this.props.integration;

    const initialValues = {
      project_key: projectKey,
      issuetype_name: issuetypeName,
      labels,
    };

    return (
      <ExportIntegrationForm initialValues={initialValues} validations={validations} {...this.props}>
        <JiraConnectExportFields
          projects={projects}
          integrationId={integrationId}
          projectKey={projectKey}
          issuetypeName={issuetypeName}
        />
      </ExportIntegrationForm>
    );
  }
}

export default JiraConnectExportForm;
