import { gql, useMutation } from "@apollo/client";
import {
  FlexibleTestingCaseResultEditRequestCreateInput,
  FlexibleTestingCaseResultEditRequestCreatePayload,
} from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

export const CASE_RESULT_EDIT_REQUEST_CREATE_MUTATION = gql`
  mutation FlexibleTestingCaseResultEditRequestCreate($input: FlexibleTestingCaseResultEditRequestCreateInput!) {
    flexibleTestingCaseResultEditRequestCreate(input: $input) {
      successful
      result {
        id
        hasOpenCaseResultEditRequest
        latestCaseResultEditRequest {
          status
          createdAt
        }
        canRemoveAttachment
        caseResultEditLogs {
          description
          timestamp
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

const displayError = (message: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message,
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Your edit request has been successfully submitted.",
  });
};

type useCreateCaseResultEditRequestResult = {
  flexibleTestingCaseResultEditRequestCreate: (caseResultId: string, content: string) => Promise<void>;
  isLoading: boolean;
};

export const useCreateCaseResultEditRequest = (onSuccess: () => void): useCreateCaseResultEditRequestResult => {
  const [flexibleTestingCaseResultEditRequestCreateMutation, { loading }] = useMutation<
    { flexibleTestingCaseResultEditRequestCreate: FlexibleTestingCaseResultEditRequestCreatePayload },
    { input: FlexibleTestingCaseResultEditRequestCreateInput }
  >(CASE_RESULT_EDIT_REQUEST_CREATE_MUTATION, {
    onCompleted: ({ flexibleTestingCaseResultEditRequestCreate }) => {
      if (flexibleTestingCaseResultEditRequestCreate?.successful) {
        displaySuccess();
        onSuccess();
      } else {
        const errorMessage = flexibleTestingCaseResultEditRequestCreate.errors.map(({ message }) => message).join(",");
        displayError(errorMessage);
      }
    },
    onError: () => displayError("There was an issue with submitting your edit request. Please try again later."),
  });

  return {
    flexibleTestingCaseResultEditRequestCreate: async (caseResultId: string, content: string): Promise<void> => {
      await flexibleTestingCaseResultEditRequestCreateMutation({ variables: { input: { caseResultId, content } } });
    },
    isLoading: loading,
  };
};
