import React, { forwardRef, useEffect } from "react";
import ReactAutosuggest from "react-autosuggest";
import { useField } from "formik";
import FormLabel from "../form_label";
import { FieldError } from "../field_error";

const Autosuggest = forwardRef(({ label, labelStyles, required, ...props }, ref) => {
  const [field, meta, helpers] = useField({ name: props.inputProps.name, value: props.inputProps.value });

  useEffect(() => {
    helpers.setValue(props.inputProps.value);
  }, [props.inputProps.value]);

  return (
    <>
      <FormLabel
        label={label}
        required={required}
        labelProps={{ className: labelStyles, htmlFor: props.inputProps.name }}
      />
      <ReactAutosuggest
        {...field}
        onBlur={() => {
          helpers.setTouched(true);
        }}
        {...props}
        ref={ref}
      />
      {meta.touched && meta.error ? <FieldError fieldName={props.inputProps.name}>{meta.error}</FieldError> : null}
    </>
  );
});

export default Autosuggest;
