import { Label } from "@hopper/label";
import React from "react";
import { Body, BodyCell, Row } from "components/flexible_testing/shared/table";
import { TestCasesGroup } from "../../utils/group_test_cases_by_section";
import pluralize from "pluralize";

type TestCasesSectionHeaderProps = {
  isCollapsed: boolean;
  group: TestCasesGroup;
  onClick: () => void;
};

function TestCasesGroupHeader({ group, isCollapsed, onClick }: TestCasesSectionHeaderProps) {
  const sectionName = group.section || "Other test cases";

  return (
    <Body key={sectionName}>
      <Row className="border-t-0">
        <BodyCell data-testid="test-case-section" onClick={onClick} colSpan={5}>
          <div className="flex items-center cursor-pointer">
            {isCollapsed ? (
              <i className="fa fa-chevron-down text-sm" aria-hidden="true" />
            ) : (
              <i className="fa fa-chevron-up text-sm" aria-hidden="true" />
            )}
            <span className="uppercase ml-md mr-lg font-bold">{sectionName}</span>
            <Label className="mr-sm">
              {`${group.testCases.length} ${pluralize("test case", group.testCases.length)}`}
            </Label>
          </div>
        </BodyCell>
      </Row>
    </Body>
  );
}

export default TestCasesGroupHeader;
