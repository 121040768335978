import React from "react";
import { Button } from "@hopper/button";

type LogAttachmentThumbnailProps = {
  attachmentUrl: string;
  index?: number;
  totalNumber?: number;
};

function LogAttachmentThumbnail({ attachmentUrl, index, totalNumber }: LogAttachmentThumbnailProps): JSX.Element {
  return (
    <Button
      appearance="minimal"
      href={attachmentUrl}
      isLoading={false}
      size="md"
      variant="secondary"
      data-testid="log-attachment-thumbnail"
    >
      <div className="text-center">
        <i className="text-xl text-secondary fa fa-download" />
        <div className="text-2xs text-secondary">Download log file</div>
        <div className="mt-xs text-2xs text-dark">
          {index} OF {totalNumber}
        </div>
      </div>
    </Button>
  );
}

export default LogAttachmentThumbnail;
