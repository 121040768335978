import pluralize from "pluralize";
import { Item } from "./targeting_field";
import type { TargetingGroup } from "./targeting_row";
import { RequestedTest } from "../requested_test_form";

const labelMappings: Record<keyof TargetingGroup, Record<RequestedTest["applicationType"], string>> = {
  devices: {
    android: "Android device",
    ios: "iOS device",
    web: "device",
  },
  operatingSystemVersions: {
    android: "Android version",
    ios: "iOS version",
    web: "version",
  },
  countries: {
    android: "country",
    ios: "country",
    web: "country",
  },
  languages: {
    android: "language",
    ios: "language",
    web: "language",
  },
  operatingSystemDeviceTypes: {
    android: "device type",
    ios: "device type",
    web: "device type",
  },
  internetBrowsers: {
    android: "browser",
    ios: "browser",
    web: "browser",
  },
} as const;

const getBaseLabel = (fieldName: keyof TargetingGroup, applicationType: RequestedTest["applicationType"]) => {
  const mapping = labelMappings[fieldName];

  return mapping[applicationType];
};

const buildLabel = (baseLabel: string, count: number) =>
  count > 0 ? `${count} ${pluralize(baseLabel, count)}` : `Any ${baseLabel}`;

export const buildSelectedItemsLabel = (
  applicationType: RequestedTest["applicationType"],
  fieldName: keyof TargetingGroup,
  value: Item[],
) => {
  const count = value.length;
  const baseLabel = getBaseLabel(fieldName, applicationType);

  if (baseLabel === "language" && count > 0) {
    return count === 1 ? value[0]?.name || "" : `${count} languages selected`;
  } else if (baseLabel === "country" && count > 0) {
    return `${buildLabel(baseLabel, count)} selected`;
  } else {
    return buildLabel(baseLabel, count);
  }
};
