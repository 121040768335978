import React from "react";
import { useHistory } from "react-router-dom";

import { websiteContentPaths } from "components/configuration";
import Form from "components/flexible_testing/shared/form/form";
import { Activity } from "./activity_step_content";
import { TargetingGroup } from "./targeting_step_content";
import { TestEnvironmentPlatform } from "types/graphql";
import { TestCase } from "types/test_cases";
import { useCustomerRequestContext } from "components/flexible_testing/test_request_page/customer_request_context";

export const defaultValues = {
  activity: "" as Activity,
  applicationType: "" as `${TestEnvironmentPlatform}`,
  applicationName: "",
  applicationVersion: "",
  accessToApplicationUrl: "",
  requiresAccessToSecureEnvironment: "",
  accessToSecureEnvironmentInstructions: "",
  testCases: undefined as Array<TestCase> | undefined,
  numberOfResultsPerTestCaseNeeded: "3",
  inScope: "",
  outOfScope: "",
  requiresPredefinedUserAccounts: "",
  requiresFinancialTransactions: "",
  requiresKycIdentityCheck: "",
  requiresPersonalIdentifyingInformation: "",
  requiresMovingToDifferentLocation: "",
  hasKnownIssuesList: "",
  targetingGroups: [] as TargetingGroup[],
  customTargetingRequirements: "",
  name: "",
};

export type RequestedTest = typeof defaultValues & {
  predefinedUserAccountsInstructions?: string;
  financialTransactionsInstructions?: string;
  kycIdentityCheckInstructions?: string;
  personalIdentifyingInformationInstructions?: string;
  movingToDifferentLocationInstructions?: string;
  knownIssuesListUrl?: string;
};

type RequestedTestFormProps = Pick<Parameters<typeof Form>[0], "children"> & {
  validations: unknown;
  initialValues?: RequestedTest;
};

const RequestedTestForm = ({ validations, initialValues, children }: RequestedTestFormProps) => {
  const { tests, addTest, updateTest } = useCustomerRequestContext();
  const history = useHistory();

  const formValues = initialValues || { ...defaultValues, name: `Test #${tests.length + 1}` };

  const handleSubmit = async (values: RequestedTest): Promise<void> => {
    if (initialValues) updateTest(initialValues, values);
    else addTest(values);

    history.push(websiteContentPaths.flexcaseLaunch);
  };

  return (
    <Form<RequestedTest> initialValues={formValues} validations={validations} onSubmit={handleSubmit}>
      {children}
    </Form>
  );
};

export default RequestedTestForm;
