import { Checkbox } from "@hopper/checkbox";
import React from "react";
import { Body, Header, HeaderCell, Table } from "../../shared/table";
import { IssueRow } from "../../result_details_page/issues/issues_list/issue_row";
import { IssueProps } from "../../result_details_page/issues/issues_list/issues_list";
import { createNavigationUrl } from "app/helpers/url_helper";

type IssuesListProps = {
  issues: IssueProps[];
  handleSelectSingle: (id: string) => void;
  selectedIssues: string[];
  setSelectedIssuesIds: React.Dispatch<React.SetStateAction<Array<string>>>;
};

const MAX_NUMBER_OF_EXPORTABLE_ISSUES = 50;

function IssuesList({
  issues,
  handleSelectSingle,
  selectedIssues,
  setSelectedIssuesIds,
}: IssuesListProps): JSX.Element {
  const toggleSelectAllForFilter = () => {
    if (selectedIssues.length === issues.length) {
      setSelectedIssuesIds([]);
    } else {
      setSelectedIssuesIds(issues.map(issue => issue.id));
    }
  };
  const areAllSelected = issues.length > 0 && issues.length === selectedIssues.length;

  return (
    <Table>
      <Header data-testid="issues-list-header">
        <HeaderCell style={{ width: "30px" }}>
          <div className="ml-lg mr-sm" data-testid="issues-list-checkbox">
            <Checkbox onChange={toggleSelectAllForFilter} isChecked={areAllSelected} />
          </div>
        </HeaderCell>
        <HeaderCell>Title</HeaderCell>
        <HeaderCell style={{ width: "100px" }}>REPORTED</HeaderCell>
        <HeaderCell style={{ width: "140px" }}>TAGS</HeaderCell>
        <HeaderCell style={{ width: "60px" }}>SEVERITY</HeaderCell>
      </Header>
      <Body>
        {issues.map(issue => (
          <IssueRow
            key={issue.id}
            issue={issue}
            isSelected={selectedIssues.includes(issue.id)}
            handleSelectSingle={handleSelectSingle}
            disableSelection={selectedIssues.length >= MAX_NUMBER_OF_EXPORTABLE_ISSUES}
            shouldDisplayRejectionReason={false}
            currentIssuesFilter=""
            destinationPath={createNavigationUrl("flexcaseIssue", { id: issue.id })}
            showReportedAt={true}
          />
        ))}
      </Body>
    </Table>
  );
}

export default IssuesList;
