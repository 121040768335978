import React from "react";
import { Heading } from "@hopper/heading";
import { Loader } from "@hopper/loading";

import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { useOrganizationStats } from "./use_organization_stats";
import { useLiveTests } from "./use_live_tests";
import EmptyStats from "./empty_stats";
import TestStatsContent from "./test_stats_content";
import EmptyLiveTests from "./empty_live_tests";
import LiveTestsList from "./live_tests_list";

const OverviewPage = () => {
  const { isLoading: areStatsLoading, organizationStats } = useOrganizationStats();
  const { isLoading: areTestsLoading, liveTestsCount, tests } = useLiveTests();

  const header = (
    <div data-testid="overview-header">
      <Heading size={1}>Overview</Heading>
    </div>
  );

  const renderContent = () => (
    <div className="grid grid-cols-2">
      {organizationStats && organizationStats.testsCount > 0 ? (
        <TestStatsContent organizationStats={organizationStats} />
      ) : (
        <EmptyStats areLiveTestsPresent={liveTestsCount > 0} />
      )}
      {liveTestsCount > 0 ? <LiveTestsList liveTestsCount={liveTestsCount} tests={tests} /> : <EmptyLiveTests />}
    </div>
  );

  const isLoading = areStatsLoading || areTestsLoading;

  return <PageTemplate header={header}>{isLoading ? <Loader /> : renderContent()}</PageTemplate>;
};

export default OverviewPage;
