import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import ProfessionalServicesPage from "./professional_services_page/professional_services_page";
import { ServiceRequestFormPage } from "./service_request_form_page";

function ProfessionalServicesSection({ match: { path } }: RouteComponentProps): JSX.Element {
  return (
    <Switch>
      <Route
        path={path + "/:professionalServiceId/professional-service-requests/new"}
        component={ServiceRequestFormPage}
      />
      <Route path={path} component={ProfessionalServicesPage} />
    </Switch>
  );
}

export default ProfessionalServicesSection;
