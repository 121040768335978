import React from "react";
import { Heading } from "@hopper/heading";
import { Card } from "@hopper/card";
import pluralize from "pluralize";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";

import { TextTruncate } from "components/shared/text_truncate";
import { RequestedTest } from "components/flexible_testing/test_request_page/requested_test_page/requested_test_form";
import {
  ACTIVITIES_REQUIRING_TEST_CASE_FIELDS,
  ACTIVITIES_REQUIRING_SCOPE,
} from "components/flexible_testing/test_request_page/requested_test_page/scope_step_content";
import {
  buildSelectedItemsLabel,
  TARGETING_FIELDS_ORDER,
  TargetingGroup,
} from "components/flexible_testing/test_request_page/requested_test_page/targeting_step_content";
import {
  getActivityTitle,
  getApplicationTypeLabel,
} from "components/flexible_testing/test_request_page/requested_test_page/helpers";
import { useCustomerRequestContext } from "../customer_request_context";
import { Button } from "@hopper/button";
import { websiteContentPaths } from "components/configuration";
import { WizardStepName } from "components/flexible_testing/test_request_page/requested_test_page/stepper";

type RequestedTestCardProps = {
  test: RequestedTest;
  canDuplicate: boolean;
};

type GroupPreviewProps = {
  group: TargetingGroup;
  activity: RequestedTest["activity"];
  applicationType: RequestedTest["applicationType"];
  index: number;
};

const GroupPreview = ({ group, activity, applicationType, index }: GroupPreviewProps) => {
  const fieldOrder = TARGETING_FIELDS_ORDER[applicationType][activity];

  return (
    <div>
      Group #{index + 1} (
      {fieldOrder.map(field => buildSelectedItemsLabel(applicationType, field, group[field])).join(", ")})
    </div>
  );
};

const EditTestLink = ({ test, stepName }: { test: RequestedTest; stepName: WizardStepName }) => (
  <Link
    to={{ pathname: websiteContentPaths.advancedTestRequestTest, state: { currentTest: test, currentStep: stepName } }}
    className="text-link text-sm ml-md"
    data-testid={`edit-${stepName}-link`}
  >
    Edit
  </Link>
);

export const RequestedTestCard = ({ test, canDuplicate }: RequestedTestCardProps) => {
  const {
    name,
    activity,
    accessToApplicationUrl,
    applicationType,
    testCases,
    numberOfResultsPerTestCaseNeeded,
    inScope,
    targetingGroups,
    customTargetingRequirements,
  } = test;
  const { tests, addTest, removeTest } = useCustomerRequestContext();
  const testType = getActivityTitle(activity);
  const applicationTypeLabel = getApplicationTypeLabel(applicationType);
  const testCasesLength = testCases?.length || 0;

  const duplicateTest = () => {
    addTest({ ...cloneDeep(test), name: `${test.name} (copy)` });
  };

  return (
    <Card>
      <div className="flex flex-col mx-3xl mb-3xl mt-2xl">
        <Heading size={2}>
          <span className="text-3xl">{name}</span>
        </Heading>
        <div className="flex flex-col gap-2xl">
          <div>
            <span className="font-bold">{testType}</span>
            <EditTestLink test={test} stepName="activity" />
          </div>
          <div>
            <div>
              <span className="font-bold">Application</span>
              <EditTestLink test={test} stepName="app" />
            </div>
            <div className="text-body">
              {applicationTypeLabel},{" "}
              <a target="_blank" href={accessToApplicationUrl} className="text-sm text-link underline">
                <TextTruncate text={accessToApplicationUrl} maxLength={40} />
              </a>
            </div>
          </div>
          <div>
            <div>
              <span className="font-bold">Scope</span>
              <EditTestLink test={test} stepName="scope" />
            </div>
            <div className="text-body">
              {ACTIVITIES_REQUIRING_TEST_CASE_FIELDS.includes(activity) && (
                <div>
                  {testCasesLength} {pluralize("test case", testCasesLength)}, {numberOfResultsPerTestCaseNeeded}{" "}
                  {pluralize("result", Number(numberOfResultsPerTestCaseNeeded))} for each test case
                </div>
              )}
              {ACTIVITIES_REQUIRING_SCOPE.includes(activity) && (
                <div>
                  <TextTruncate text={inScope} maxLength={400} />
                </div>
              )}
            </div>
          </div>
          <div>
            <div>
              <span className="font-bold">Targeting</span>
              <EditTestLink test={test} stepName="targeting" />
            </div>
            <div className="text-body">
              {customTargetingRequirements ? (
                <div>{customTargetingRequirements}</div>
              ) : (
                <>
                  {targetingGroups.map((group, index) => (
                    <GroupPreview
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      group={group}
                      activity={activity}
                      applicationType={applicationType}
                      index={index}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="flex gap-2xl mt-lg">
            <Button
              variant="secondary"
              appearance="ghost"
              onClick={duplicateTest}
              type="button"
              disabled={!canDuplicate}
            >
              Duplicate this test
            </Button>

            {tests.length > 1 && (
              <Button variant="secondary" appearance="ghost" onClick={() => removeTest(test)} type="button">
                Remove this test
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
