import React, { useState } from "react";
import { FlexibleTestingIssue, IssueSeverity as IssueSeverityType } from "types/graphql";
import { SeverityPopup } from "../severity_popup";
import { SeverityLabel } from "../severity_label";
import { useIssueUpdateSeverity } from "./use_issue_update_severity";

type IssueSeverityProps = {
  issue: FlexibleTestingIssue;
};

const IssueSeverity = ({ issue }: IssueSeverityProps): JSX.Element => {
  const [isSeverityPopupOpen, setIsSeverityPopupOpen] = useState(false);
  const { issueUpdateSeverity, wasSuccessfullyRequested } = useIssueUpdateSeverity({ issueId: issue.id });

  const onSeveritySelected = (issueSeverity: IssueSeverityType) =>
    issueUpdateSeverity(issue.id, issueSeverity, "ada").then(() => {
      if (wasSuccessfullyRequested) {
        setIsSeverityPopupOpen(false);
      }
    });

  return (
    <div data-testid="issue-severity">
      <SeverityPopup
        currentSeverity={issue.severity}
        onSeveritySelected={onSeveritySelected}
        isOpen={isSeverityPopupOpen}
        onExtenalElementClick={() => setIsSeverityPopupOpen(false)}
        onPopupClick={() => setIsSeverityPopupOpen(!isSeverityPopupOpen)}
      >
        <div data-testid="issue-severity-label">
          <SeverityLabel severity={issue.severity} />
        </div>
      </SeverityPopup>
    </div>
  );
};

export default IssueSeverity;
