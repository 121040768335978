import React from "react";
import { useFormikContext } from "formik";
import Button, { ButtonProps } from "components/flexible_testing/shared/form/reset_button/button";
import { isObject } from "lodash";

function ClearButton({ children, disabled, loading, ...props }: ButtonProps): JSX.Element {
  const { resetForm, values, isSubmitting } = useFormikContext();

  return (
    <Button
      disabled={disabled || isSubmitting || areAllValuesEmpty(values)}
      onClick={() =>
        resetForm({
          values: createValuesObjectWithEmptyValues(values),
        })
      }
      {...props}
    >
      {children}
    </Button>
  );
}

function createValuesObjectWithEmptyValues(values: unknown) {
  if (typeof values === "object" && values !== null) {
    return Object.entries(values).reduce(
      (values, [key, value]) => ({ ...values, [key]: Array.isArray(value) ? [] : "" }),
      {},
    );
  }

  return values;
}

function areAllValuesEmpty(values: unknown): boolean {
  if (isObject(values)) {
    return Object.values(values).every(value => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }

      return !value;
    });
  }

  return true;
}

export default ClearButton;
