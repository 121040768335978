import { Events } from "components/configuration";
import { useTrackingModeration } from "./use_tracking_moderation";

export function useTrackingRestoreAttachment() {
  const eventName = Events.ATTACHMENT_RESTORED;
  const { tracking, organizationUserEmail, trackingCaseResultId, trackingTestId } = useTrackingModeration();

  const trackRestoreAttachment = (attachmentId: string, attachmentType: string) => {
    tracking.trackEvent({
      action: "track",
      name: eventName,
      eventProperties: {
        attachment_id: attachmentId,
        attachment_type: attachmentType,
        test_id: trackingTestId,
        case_result_id: trackingCaseResultId,
        organization_user_email: organizationUserEmail,
      },
    });
  };

  return trackRestoreAttachment;
}
