import React from "react";
import { getIn, useFormikContext } from "formik";
import { Button } from "@hopper/button";

import { Country, Language, MobileDevice, InternetBrowser, OperatingSystemDevice } from "types/graphql";
import duplicateIcon from "assets/images/icon--duplicate.svg";
import removeIcon from "assets/images/icon--x.svg";
import { Activity } from "../activity_step_content";
import { RequestedTest } from "../requested_test_form";
import TargetingField from "./targeting_field";
import { useEnvironments, OperatingSystemVersion } from "./use_environments";

type BaseTargetingGroup = {
  countries: Country[];
  languages: Language[];
};

type WebTargeting = {
  internetBrowsers: InternetBrowser[];
  operatingSystemDeviceTypes: OperatingSystemDevice[];
  devices: never[];
  operatingSystemVersions: never[];
};

type MobileTargeting = {
  devices: MobileDevice[];
  operatingSystemVersions: OperatingSystemVersion[];
  internetBrowsers: never[];
  operatingSystemDeviceTypes: never[];
};

export type TargetingGroup = BaseTargetingGroup & (WebTargeting | MobileTargeting);

type TargetingRowProps = {
  index: number;
  targetingGroup: TargetingGroup;
  environments: Omit<ReturnType<typeof useEnvironments>, "loading">;
  duplicate: () => void | null;
  remove: () => void | null;
  canDuplicate: boolean;
  canRemove: boolean;
};

type ActivityFieldOrder = Record<Activity, Array<keyof TargetingGroup>>;

const DEFAULT_FIELDS_ORDER_PER_ACTIVITY: ActivityFieldOrder = {
  [Activity.TestCaseRun]: ["devices", "operatingSystemVersions", "countries", "languages"],
  [Activity.Exploratory]: ["devices", "operatingSystemVersions", "countries", "languages"],
  [Activity.Localization]: ["countries", "languages", "devices", "operatingSystemVersions"],
  [Activity.Translation]: ["languages", "countries", "devices", "operatingSystemVersions"],
};

export const TARGETING_FIELDS_ORDER: Record<RequestedTest["applicationType"], ActivityFieldOrder> = {
  android: DEFAULT_FIELDS_ORDER_PER_ACTIVITY,
  ios: DEFAULT_FIELDS_ORDER_PER_ACTIVITY,
  web: {
    [Activity.TestCaseRun]: ["operatingSystemDeviceTypes", "internetBrowsers", "countries", "languages"],
    [Activity.Exploratory]: ["operatingSystemDeviceTypes", "internetBrowsers", "countries", "languages"],
    [Activity.Localization]: ["countries", "languages", "operatingSystemDeviceTypes", "internetBrowsers"],
    [Activity.Translation]: ["languages", "countries", "operatingSystemDeviceTypes", "internetBrowsers"],
  },
};

const fields = [
  { name: "devices", label: "Device(s)" },
  { name: "operatingSystemVersions", label: "Operating system version(s)" },
  { name: "countries", label: "Countries" },
  { name: "languages", label: "Languages" },
  { name: "operatingSystemDeviceTypes", label: "Device type(s)" },
  { name: "internetBrowsers", label: "Browser(s)" },
] as const;

const TargetingRow = ({
  index,
  targetingGroup,
  environments,
  duplicate,
  remove,
  canDuplicate,
  canRemove,
}: TargetingRowProps) => {
  const {
    values: { activity, applicationType },
    setFieldValue,
    errors: formErrors,
  } = useFormikContext<RequestedTest>();
  const errors = getIn(formErrors, `targetingGroups.${index}`);

  const fieldOrder = TARGETING_FIELDS_ORDER[applicationType];

  const sortedFields = [...fields]
    .filter(({ name }) => fieldOrder[activity].includes(name))
    .sort((a, b) => fieldOrder[activity].indexOf(a.name) - fieldOrder[activity].indexOf(b.name));

  const updateField = <T extends { id: string }[]>(field: string, selectedIds: string[], values: T) => {
    const selectedValues = values.filter(({ id }) => selectedIds.includes(id));

    setFieldValue(`targetingGroups.${index}.${field}`, selectedValues);
  };

  return (
    <div data-test-role="targeting-group">
      <div className="text-secondary mb-lg text-xs uppercase">Group #{index + 1}</div>
      <div className="grid grid-cols-9 gap-2xl flex-1">
        {sortedFields.map(({ name, label }) => (
          <div className="col-span-2" key={name}>
            <TargetingField
              name={name}
              label={label}
              value={targetingGroup[name]}
              availableItems={environments[name]}
              errorMessage={errors?.[name]}
              onChange={updateField}
              singleSelect={activity === Activity.Translation && name === "languages"}
            />
          </div>
        ))}

        <div className="flex items-end pb-sm gap-md">
          {canDuplicate && (
            <Button
              type="button"
              variant="secondary"
              appearance="ghost"
              className="py-[7px] px-md h-auto flex items-center justify-center"
              onClick={duplicate}
            >
              <img src={duplicateIcon} alt="Duplicate icon" style={{ height: "16px", width: "16px" }} />
            </Button>
          )}
          {canRemove && (
            <Button
              type="button"
              variant="secondary"
              appearance="ghost"
              className="p-sm px-md h-auto flex items-center justify-center"
              onClick={remove}
            >
              <img src={removeIcon} alt="Remove icon" style={{ height: "14px", width: "14px" }} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TargetingRow;
