import React, { useState } from "react";
import { Button } from "@hopper/button";
import { FlexibleTestingTestCaseResult } from "components/flexible_testing/test_case_result_page/use_test_case_result";
import TestCaseResponseRejectionPopup from "./test_case_response_rejection_popup";
import { useRejectTestCaseResult } from "./use_reject_test_case_result";
import { useTestCaseResultRejectionReasons } from "./use_test_case_result_rejection_reasons";

type TestCaseResponseRejectionButtonProps = {
  caseResult: FlexibleTestingTestCaseResult;
};

const TestCaseResponseRejectionButton = ({ caseResult }: TestCaseResponseRejectionButtonProps) => {
  const [isUndoCaseResultRejectionPopupOpen, setUndoCaseResultRejectionPopupOpen] = useState(false);
  const { rejectTestCaseResult } = useRejectTestCaseResult();
  const testCaseResultRejectionReasons = useTestCaseResultRejectionReasons();

  function onRejectionReasonOptionClick(rejectionReason: string) {
    rejectTestCaseResult(caseResult.testCase.id, caseResult.id, rejectionReason);
    setUndoCaseResultRejectionPopupOpen(false);
  }

  return (
    <TestCaseResponseRejectionPopup
      isOpen={isUndoCaseResultRejectionPopupOpen}
      onExtenalElementClick={() => setUndoCaseResultRejectionPopupOpen(false)}
      onRejectionReasonOptionClick={onRejectionReasonOptionClick}
      onButtonClick={() => setUndoCaseResultRejectionPopupOpen(!isUndoCaseResultRejectionPopupOpen)}
      rejectionReasons={testCaseResultRejectionReasons}
      caseResultId={caseResult.id}
    >
      <Button variant="secondary" appearance="ghost">
        <span className="text-lg">Reject this tester's result</span>
      </Button>
    </TestCaseResponseRejectionPopup>
  );
};

export default TestCaseResponseRejectionButton;
