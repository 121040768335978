import { ParticipantsPanel } from "components/flexible_testing/shared/participants_panel";
import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import React, { useState } from "react";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { useCsvExport } from "./use_csv_export";
import { TestParticipationStatus } from "types/app";

const EXPORTABLE_STATUSES: Readonly<TestParticipationStatus[]> = [
  "testing_complete",
  "under_review",
  "results_approved",
  "publishing_results",
  "results_published",
] as const;

type CsvExportProps = {
  testId?: string;
  isPanelOpen: boolean;
  onClose: () => void;
  selectionRequirementsGroups: SelectionRequirementsGroup[];
};

function publishedGroupsIds(selectionRequirementsGroups: SelectionRequirementsGroup[]): string[] {
  return selectionRequirementsGroups.filter(group => group.status === "published").map(group => group.id);
}

function CsvExport({ testId, isPanelOpen, onClose, selectionRequirementsGroups }: CsvExportProps) {
  const { downloadCsvFiles, isLoading } = useCsvExport();
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState<string[]>([]);

  const handleExportClick = () => {
    testId &&
      downloadCsvFiles(testId, selectedParticipantsIds)
        .then(() => {
          notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
            type: "success",
            message: "Results have been successfully exported.",
          });
        })
        .catch(error => {
          notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
            type: "danger",
            message: error.message,
          });
        });
  };

  return (
    <ParticipantsPanel
      title="Export results"
      show={isPanelOpen}
      onClose={onClose}
      isLoading={isLoading}
      onActionButtonPress={handleExportClick}
      actionButtonLabel="Export as CSV"
      selectionRequirementsGroups={selectionRequirementsGroups}
      collapsedSelectionRequirementsGroupIds={publishedGroupsIds(selectionRequirementsGroups)}
      allowedParticipantStatuses={EXPORTABLE_STATUSES}
      onParticipantSelectionChange={setSelectedParticipantsIds}
      data-testid="tester-csv-export"
    />
  );
}

export default CsvExport;
