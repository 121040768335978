import { gql, useMutation } from "@apollo/client";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { UpdateRequirementsGroupPayload } from "types/graphql";

type UpdateRequirementsGroupInput = {
  requirementsGroupId: string;
  operatingSystemVersionIds: string[];
  deviceTypeIds: string[];
  manufacturerIds: string[];
  mobileDeviceIds: string[];
  operatingSystemIds: string[];
  internetBrowserIds: string[];
  countryIds: string[];
  languageIds: string[];
  numberOfResultsNeeded: number;
  name: string;
  other: string;
};

export const UPDATE_REQUIREMENTS_GROUP = gql`
  mutation ($input: UpdateRequirementsGroupInput!) {
    updateRequirementsGroup(input: $input) {
      successful
      result {
        id
        name
        numberOfResultsNeeded
        operatingSystemVersions {
          edges {
            node {
              id
              fullName
            }
          }
        }
        deviceTypes {
          edges {
            node {
              id
              name
            }
          }
        }
        manufacturers {
          edges {
            node {
              id
              name
            }
          }
        }
        mobileDevices {
          edges {
            node {
              id
              name
            }
          }
        }
        operatingSystems {
          edges {
            node {
              id
              name
            }
          }
        }
        internetBrowsers {
          edges {
            node {
              id
              name
            }
          }
        }
        countries {
          edges {
            node {
              id
              name
            }
          }
        }
        languages {
          edges {
            node {
              id
              name
            }
          }
        }
        other
      }
      errors {
        code
        message
      }
    }
  }
`;

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Requirements group has been successfully updated.",
  });
};

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with updating the requirements group.",
  });
};

const useUpdateRequirementsGroup = () => {
  const [updateRequirementsGroup] = useMutation<{
    updateRequirementsGroup: UpdateRequirementsGroupPayload;
  }>(UPDATE_REQUIREMENTS_GROUP, {
    onCompleted: ({ updateRequirementsGroup: { successful } }) => (successful ? displaySuccess() : displayError()),
    onError: error => {
      displayError();
      console.error(error);
    },
  });

  return {
    updateRequirementsGroup: async ({
      requirementsGroupId,
      operatingSystemVersionIds,
      deviceTypeIds,
      numberOfResultsNeeded,
      manufacturerIds,
      mobileDeviceIds,
      operatingSystemIds,
      internetBrowserIds,
      countryIds,
      languageIds,
      name,
      other,
    }: UpdateRequirementsGroupInput): Promise<void> => {
      await updateRequirementsGroup({
        variables: {
          input: {
            requirementsGroupId,
            numberOfResultsNeeded,
            name,
            operatingSystemVersionIds,
            deviceTypeIds,
            manufacturerIds,
            mobileDeviceIds,
            operatingSystemIds,
            internetBrowserIds,
            countryIds,
            languageIds,
            other,
          },
        },
      });
    },
  };
};

export default useUpdateRequirementsGroup;
