import React from "react";
import { Heading } from "@hopper/heading";
import { Link } from "react-router-dom";
import pluralize from "pluralize";
import { Label } from "@hopper/label";
import Truncate from "react-truncate";

// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import { Test } from "types/graphql";

type LiveTestsListProps = {
  liveTestsCount: number;
  tests: Test[];
};

const LiveTestsList = ({ liveTestsCount, tests: liveTests }: LiveTestsListProps) => {
  const tests = pluralize("test", liveTestsCount);
  const are = pluralize("is", liveTestsCount);

  return (
    <div>
      <br />

      <div className="mt-4xl">
        <Heading size={2}>{`There ${are} ${liveTestsCount} ${tests} in progress`}</Heading>
      </div>

      <div>
        {liveTests.map(test => (
          <LiveTestRow key={test.id} test={test} />
        ))}
      </div>

      <div className="mt-2xl">
        <Link
          to={createNavigationUrl("flexcaseResults")}
          className="text-link font-bold focus:outline-none cursor-pointer"
        >
          View all tests
        </Link>
      </div>
    </div>
  );
};

function LiveTestRow({ test }: { test: Test }) {
  return (
    <div className="first:mt-4xl py-xl border-t-2 border-light last:border-b-2">
      <Link to={createNavigationUrl("flexcaseResult", { id: test.id })}>
        <div className="grid grid-cols-12">
          <div className="col-span-11">
            <Label>{test.code}</Label>
            <Truncate ellipsis="..." lines={1} width={400} className="ml-sm font-bold">
              {test.name}
            </Truncate>
          </div>

          <i className="col-span-1 fa fa-chevron-right text-dark flex items-center" aria-hidden="true" />
        </div>
      </Link>
    </div>
  );
}

export default LiveTestsList;
