import { gql, useMutation } from "@apollo/client";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

type useIssueUpdateSeverityResult = {
  issueUpdateSeverity: (issueId: string, newSeverity: string, source: string) => Promise<void>;
  wasSuccessfullyRequested: boolean;
};

type useIssueUpdateSeverityProps = {
  issueId: string;
};

export const CHANGE_ISSUE_SEVERITY_MUTATION = gql`
  mutation ($input: FlexibleTestingChangeIssueSeverityInput!) {
    flexibleTestingChangeIssueSeverity(input: $input) {
      successful
      result {
        id
        reviewedSeverity
        severity
      }
    }
  }
`;

export const useIssueUpdateSeverity = ({ issueId }: useIssueUpdateSeverityProps): useIssueUpdateSeverityResult => {
  const [issueUpdateSeverity, { error }] = useMutation(CHANGE_ISSUE_SEVERITY_MUTATION, {
    onError: () => {
      notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
        type: "danger",
        message: "Severity update unsuccessful, please try again later.",
      });
    },
  });

  return {
    issueUpdateSeverity: async (issueId, newSeverity, source): Promise<void> => {
      await issueUpdateSeverity({
        variables: {
          input: {
            issueId,
            newSeverity,
            source,
          },
        },
      });
    },
    wasSuccessfullyRequested: !error,
  };
};
