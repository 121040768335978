import React from "react";

import { PageSection } from "components/flexible_testing/shared/page_section";
import { CheckboxGroup, TextArea, TextInput } from "components/flexible_testing/shared/form";
import { CheckboxOption } from "components/flexible_testing/shared/form/checkbox_group/checkbox_group";
import { testEnvironments } from "components/flexible_testing/shared/test_environments";

const title = (
  <div className="inline-flex items-center justify-between flex-grow">
    <span>What application would you like to test?</span>
  </div>
);

const testEnvironmentOptions = testEnvironments as CheckboxOption[];

const TestDetails = () => (
  <PageSection title={title} fullWidthTitle={true} className="mb-2xl">
    <div data-test-role="form-group" className="text-xs mb-xl">
      <TextInput name="applicationUrl" placeholder="http://www.your-app.com" label="Application URL" required={true} />
    </div>
    <div data-test-role="form-group" className="text-xs mb-xl">
      <CheckboxGroup
        label="What type of application is it?"
        required
        name="testEnvironments"
        options={testEnvironmentOptions}
      />
    </div>
    <div data-test-role="form-group" className="text-xs mb-xl">
      <TextArea
        name="credentialsAndAccessInstructions"
        placeholder="Will testers need login credentials for accessing the application? Please add any access instructions here"
        label="Credentials and/or access instructions"
        useHopperInput={true}
      />
    </div>
    <div data-test-role="form-group" className="text-xs mb-xl">
      <TextArea
        name="additionalUrlsAndResources"
        placeholder="Will testers need any additional URLs to complete this test (e.g. admin app, production environments)? Please add them here along with any credentials needed"
        label="Additional URLs and resources"
        required={false}
        useHopperInput={true}
      />
    </div>
  </PageSection>
);

export default TestDetails;
