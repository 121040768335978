import { useQuery } from "@apollo/client";
import { Test } from "types/graphql";
import { GET_TEST_CASES_TAB_DATA } from "./test_cases_tab_query";

function useTestCasesTabData(testId?: string) {
  const { loading, data } = useQuery(GET_TEST_CASES_TAB_DATA, {
    variables: { testId },
    skip: !testId,
  });

  const platformTest = data?.platformTest;

  return {
    isLoading: loading,
    testWindow: platformTest?.testWindow,
    testCases: extractTestCases(platformTest),
  };
}

function extractTestCases(platformTest?: Test) {
  return platformTest?.cases?.edges?.map(testCase => testCase?.node).filter(testCase => !!testCase) || [];
}

export { useTestCasesTabData };
