import { Body, BodyCell, Row } from "components/flexible_testing/shared/table";
import { FlagIcon } from "components/flexible_testing/shared/flag";
import React from "react";
import { Participant } from "components/flexible_testing/shared/chat";
import { useHistory, useRouteMatch } from "react-router-dom";
import { websiteContentPaths } from "components/configuration";
import StatusLabel from "./status_label";
import TesterProgressBar from "./tester_progress_bar";
import classnames from "classnames";
import { Checkbox } from "@hopper/checkbox";
import { TimeSinceLastResult } from "./time_since_last_result";
import OpenPrivateChatButton, { ButtonType } from "components/flexible_testing/shared/chat/open_private_chat_button";
import Authorization from "app/authorization/authorization";
import IssuesModerationStatus from "./issues_moderation_status";
import { PublishTesterResultsButton } from "components/flexible_testing/shared/publish_tester_results_button";
import { useFeatureFlag } from "app/feature_flags";
import { useResultsHeaderData } from "../results_header/use_results_header_data";

export const JOINED_STATUSES: Readonly<Participant["status"][]> = [
  "joined",
  "in_progress",
  "testing_complete",
  "under_review",
  "results_approved",
  "publishing_results",
  "results_published",
] as const;

type TesterGroupParticipantsListProps = {
  participant: Participant;
  testCasesCount: number;
  isSelected: boolean;
  isSelectingDisabled: boolean;
  onSelect: (participantId: string) => void;
  testName: string;
  testCode: string;
  canSelect: boolean;
};

type RouteParams = {
  id?: string;
};

function TesterGroupParticipant({
  participant,
  testCasesCount,
  isSelectingDisabled,
  isSelected,
  onSelect,
  testName,
  testCode,
  canSelect,
}: TesterGroupParticipantsListProps) {
  const history = useHistory();
  const match = useRouteMatch<RouteParams>(websiteContentPaths.flexcaseResult);
  const testId = match?.params.id || "";
  const alreadyPublished = participant.status === "results_published";
  const hasParticipantJoined = JOINED_STATUSES.includes(participant.status);
  const CTMsChatAccessEnabled = useFeatureFlag("ctms-chats-access", false);
  const { platformTest } = useResultsHeaderData(testId);

  const onRowClick = () => {
    history.push(
      websiteContentPaths.flexcaseParticipantResult
        .replace(":testId", testId)
        .replace(":participantId", participant.id),
    );
  };

  return (
    <Body>
      <Row testId="participant-row" recordId={participant.email} className="cursor-pointer" onClick={onRowClick}>
        {canSelect && (
          <BodyCell
            data-testid="participant-checkbox"
            className="cursor-default"
            onClick={event => event.stopPropagation()}
          >
            <Checkbox isChecked={isSelected} disabled={isSelectingDisabled} onChange={() => onSelect(participant.id)} />
          </BodyCell>
        )}
        {/* Setting max-w-[1px] ensures the column width matches the header width, allowing the text to be truncated,
            otherwise, it behaves like width-auto, which resizes the column based on its content */}
        <BodyCell data-testid="participant-personal-info" className="truncate max-w-[1px]">
          <Authorization roles={["testManager"]}>
            <span className="cursor-default" onClick={event => event.stopPropagation()}>
              <OpenPrivateChatButton
                testName={testName}
                testId={testId}
                testCode={testCode}
                participant={participant}
                communityTestManagersEnabled={platformTest?.communityTestManagersEnabled}
                variant={ButtonType.Inline}
              />
            </span>
          </Authorization>
          {CTMsChatAccessEnabled && (
            <Authorization roles={["communityTestManager"]}>
              <span className="cursor-default" onClick={event => event.stopPropagation()}>
                <OpenPrivateChatButton
                  testName={testName}
                  testId={testId}
                  testCode={testCode}
                  participant={participant}
                  communityTestManagersEnabled={true}
                  variant={ButtonType.Inline}
                />
              </span>
            </Authorization>
          )}
          <FlagIcon className="mr-md" code={participant.country.code?.toLowerCase()} title={participant.country.name} />
          <span
            className={classnames({ "text-muted": participant.status === "removed" })}
            data-testid="participant-full-name"
            title={participant.fullName}
          >
            {participant.fullName}
          </span>
        </BodyCell>
        <BodyCell>
          <StatusLabel status={participant.status} invitedAt={participant.invitedAt} />
        </BodyCell>
        <BodyCell data-testid="participant-progress">
          {hasParticipantJoined && (
            <TesterProgressBar
              total={testCasesCount}
              completed={participant.approvedTestCasesCount}
              isPublished={participant.status === "results_published"}
            />
          )}
        </BodyCell>
        <BodyCell>
          <></>
        </BodyCell>
        <BodyCell data-testid="participant-issues" className="text-sm text-secondary">
          {participant.hasReportedIssue && (
            <IssuesModerationStatus
              numberOfPendingIssueModerations={participant.numberOfPendingIssueModerations}
              moderationStatus={participant.moderationStatus}
            />
          )}
        </BodyCell>
        <BodyCell>
          <TimeSinceLastResult participant={participant} />
        </BodyCell>
        <BodyCell className="flex justify-end px-0">
          <Authorization roles={["testManager"]}>
            <>
              {alreadyPublished && <span className="text-secondary text-sm">Already published</span>}
              {!alreadyPublished && participant.resultsPublishable && (
                <span onClick={event => event.stopPropagation()} className="cursor-default">
                  <PublishTesterResultsButton participant={participant} testId={testId} />
                </span>
              )}
            </>
          </Authorization>
        </BodyCell>
      </Row>
    </Body>
  );
}

export default TesterGroupParticipant;
