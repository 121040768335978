import React, { useEffect, useState } from "react";
import CasesStats from "components/flexible_testing/shared/cases_stats/cases_stats";
import IssuesStats from "./issues_stats";
import { usePreviousState } from "components/flexible_testing/shared/use_previous_state";
import { Test } from "types/graphql";
import { TestStatusLabel } from "components/flexible_testing/shared/test_status_label";
import classNames from "classnames";
import { Link } from "react-router-dom";
import TestProgressLabel from "components/flexible_testing/results_page/test_progress_label";

type RowProps = {
  test: Test;
  inProgress?: boolean;
  animate?: boolean;
};

function Row({ test, inProgress, animate }: RowProps) {
  const { url } = test;
  const [animationTrigger, setAnimationTrigger] = useState<boolean>(animate as boolean);
  const previousState = usePreviousState();
  const isTestOpen = test?.testWindow?.status === "open";

  useEffect(() => {
    setAnimationTrigger(false);
  }, []);

  const newTestEffect = `
    @keyframes highlight {
      0%   { background-color: #fffff; }
      50%  { background-color: #f3f4f5; }
      100% { background-color: #fffff; }
    }
  `;

  const styles = isTestOpen ? { animationName: "highlight", animationDuration: "4s" } : {};

  const RowWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
    if (url) {
      return (
        <Link
          className={classNames("text-secondary hover:text-secondary", {
            "opacity-100": !animationTrigger,
            "opacity-0": animationTrigger,
          })}
          to={{
            pathname: url,
            state: { ...previousState, ...{ previousPage: "resultsPage" } },
          }}
          style={{ transition: "opacity 300ms" }}
        >
          {children}
        </Link>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <div style={styles} data-test-role="row">
      <style children={newTestEffect} />
      <RowWrapper>
        <div data-testid="test-item" className="border-b border-light grid grid-cols-12 gap-xl w-full items-end">
          <span className="py-xl col-span-4">
            <div className="uppercase text-secondary tracking-wide text-sm">{test.code}</div>
            <div className="flex">
              <div className="tracking-wide uppercase text-xs text-secondary font-bold" data-testid="test-status">
                <TestProgressLabel test={test} />
              </div>
              <div className="text-lg font-bold text-darkest">
                <span className="pr-sm">{test.name}</span>
                {test.type === "Advanced Test" && (
                  <span className="uppercase text-secondary tracking-wider inline-block text-xs my-xs">Advanced</span>
                )}
              </div>
            </div>
          </span>
          <span className="py-xl col-span-3 truncate">
            <div className="uppercase text-secondary font-bold tracking-wider inline-block text-xs">
              <TestStatusLabel testWindow={test?.testWindow} />
            </div>
          </span>
          <span className="py-xl col-span-1 truncate">
            <div className="my-xs">
              <IssuesStats test={test} />
            </div>
          </span>
          <span className="py-xl col-span-4 truncate">
            <div className="my-xs">
              <CasesStats test={test} inProgress={inProgress} fontSize="md" />
            </div>
          </span>
        </div>
      </RowWrapper>
    </div>
  );
}

export default Row;
