import React, { useState } from "react";
import * as yup from "yup";
import { Card } from "@hopper/card";
import { Button } from "@hopper/button";
import { useHistory } from "react-router-dom";

// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import { TextInput } from "../shared/form";
import { useIntegrationUpdate } from "./use_integration_update";
import { TestrailClientAuthentication } from "types/graphql";
import { PageSection } from "../shared/page_section";
import { GeneralError, Form, SubmitButton } from "components/shared/form";
import { ClientAuthenticationProps } from "./integration_page";

export function Testrail({ integration }: ClientAuthenticationProps) {
  const clientAuthentication = integration?.clientAuthentication as TestrailClientAuthentication;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const cancel = () => history.push(createNavigationUrl("integrationSettings"));
  const { updateIntegration } = useIntegrationUpdate();

  const handleSubmit = (values: { [field: string]: unknown }) => {
    setIsLoading(true);

    const params = {
      code: "testrail",
      testrailAuthenticationParams: {
        site: values.site as string,
        username: values.username as string,
        api_key: values.api_key as string,
      },
    };

    return updateIntegration(params).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Card>
      <div className="m-3xl">
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            site: clientAuthentication?.site,
            username: clientAuthentication?.username,
            api_key: "",
          }}
          validations={yup.object({
            site: yup
              .string()
              .required("can't be blank")
              .matches(/(^https)/, "site URL must start with https"),
            username: yup.string().required("can't be blank"),
            api_key: yup.string().required("can't be blank"),
          })}
        >
          <GeneralError />

          <div className="grid grid-cols-2">
            <PageSection title="Integration settings" orderNumber={1}>
              <div className="mb-2xl flex justify-between">
                <div style={{ width: "75%" }} data-test-role="form-group">
                  <TextInput
                    id="site"
                    name="site"
                    label="What is your TestRail site URL?"
                    className="form-control"
                    labelClass="text-sm font-normal"
                    placeholder="https://gatteam.testrail.io"
                    data-testid="site-input"
                    required={true}
                  />
                </div>
              </div>
            </PageSection>
            <PageSection title="Authentication" orderNumber={2}>
              <div style={{ width: "75%" }} data-test-role="form-group">
                <TextInput
                  id="username"
                  name="username"
                  label="What is your TestRail username?"
                  placeholder="e.g. user@globalapptesting.com"
                  className="form-control"
                  labelClass="text-sm font-normal"
                  data-testid="username-input"
                  required={true}
                />
              </div>
              <div style={{ width: "75%" }} className="mt-xl" data-test-role="form-group">
                <TextInput
                  id="api_key"
                  name="api_key"
                  label="What is your TestRail API key?"
                  placeholder="e.g. 12a34b34df34dfa3435423dfs53dga231sd"
                  className="form-control mb-sm"
                  labelClass="text-sm font-normal"
                  type="password"
                  data-testid="api-key-input"
                  required={true}
                />
                <a
                  className="text-sm text-link underline"
                  href="https://www.gurock.com/testrail/docs/api/getting-started/accessing"
                  target="_blank"
                >
                  Learn how to create an API key
                </a>
              </div>
            </PageSection>
          </div>

          <div className="ml-4xl mb-4xl">
            <SubmitButton useHopperButton={true} className="mr-lg" loading={isLoading}>
              Save
            </SubmitButton>
            <Button variant="secondary" type="reset" onClick={cancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
}
