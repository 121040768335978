import React, { useState } from "react";
import classnames from "classnames";
import { Button } from "@hopper/button";

import { TestCase } from "types/test_cases";

type TestCasesListProps = {
  testCases: Array<TestCase>;
  onTestCasesUpload: (testCases: Array<TestCase> | null) => void;
};

const DEFAULT_NUMBER_OF_TEST_CASES_TO_SHOW = 3;

function TestCasesList({ testCases, onTestCasesUpload }: TestCasesListProps): JSX.Element {
  const [numberOfTestCasesToShow, setNumberOfTestCasesToShow] = useState(DEFAULT_NUMBER_OF_TEST_CASES_TO_SHOW);
  const displayShowAllLink =
    numberOfTestCasesToShow === DEFAULT_NUMBER_OF_TEST_CASES_TO_SHOW && testCases.length > numberOfTestCasesToShow;

  const removeTestCase = (testCaseToRemove: TestCase) => {
    const filteredTestCases = testCases.filter(testCase => testCase !== testCaseToRemove);
    onTestCasesUpload(filteredTestCases);
  };

  const rowClass = (index: number) =>
    classnames("flex justify-between border-b border-light py-sm items-center", index === 0 ? "border-t" : "");

  return (
    <div className="mt-md">
      {testCases.slice(0, numberOfTestCasesToShow).map((testCase, index) => (
        <div className={rowClass(index)} key={`${testCase.code} ${testCase.name}`}>
          <span data-testid="test-case-name">{`${testCase.code} ${testCase.name}`}</span>
          <span>
            <Button type="button" size="sm" variant="secondary" onClick={() => removeTestCase(testCase)}>
              Remove
            </Button>
          </span>
        </div>
      ))}
      {displayShowAllLink && (
        <div
          className="text-link mt-sm cursor-pointer text-xs font-bold"
          onClick={() => setNumberOfTestCasesToShow(testCases.length)}
        >
          Show all
        </div>
      )}
    </div>
  );
}

export default TestCasesList;
