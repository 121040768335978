import { ApolloQueryResult } from "@apollo/client";
import { Loader } from "@hopper/loading";
import React from "react";
import { Test } from "types/graphql";
import { ResultsList } from "./results_list";
import { Button } from "@hopper/button";
import { TestsList } from "./use_test_list";

type ResultsSectionProps = {
  tests: Test[];
  isLoading: boolean;
  emptyListMessage: string;
  dataTestid: string;
  isLoadingNextPage: boolean;
  hasNextPage: boolean | undefined;
  isInProgressSection?: boolean;
  loadNextPage: () => Promise<ApolloQueryResult<TestsList>>;
};

const ResultsSection = ({
  tests,
  isLoading,
  emptyListMessage,
  dataTestid,
  isLoadingNextPage,
  hasNextPage,
  loadNextPage,
  isInProgressSection = false,
}: ResultsSectionProps): JSX.Element => (
  <>
    {isLoading && tests.length === 0 ? (
      <div className="relative pt-3xl">
        <Loader />
      </div>
    ) : tests.length > 0 ? (
      <div data-testid={dataTestid}>
        <ResultsList tests={tests} animate inProgress={isInProgressSection} />
        {hasNextPage && (
          <div className="flex justify-center mt-2xl">
            <Button variant="secondary" appearance="ghost" isLoading={isLoadingNextPage} onClick={loadNextPage}>
              Load more
            </Button>
          </div>
        )}
      </div>
    ) : (
      <div className="text-lg">{emptyListMessage}</div>
    )}
  </>
);

export default ResultsSection;
