import { gql, useMutation } from "@apollo/client";
import {
  FlexibleTestingSaveAutoExportSettingsInput,
  FlexibleTestingSaveAutoExportSettingsPayload,
  AutoExportDestination,
  IssueSeverity,
} from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

export const SAVE_AUTO_EXPORT_SETTINGS = gql`
  mutation ($input: FlexibleTestingSaveAutoExportSettingsInput!) {
    flexibleTestingSaveAutoExportSettings(input: $input) {
      successful
      result {
        id
        enabledDestinations
        severitiesForExport
      }
      errors {
        code
        message
      }
    }
  }
`;

type useSaveAutoExportSettingsInterface = {
  saveAutoExportSettings: (
    enabledDestinations: Array<AutoExportDestination>,
    severitiesForExport: Array<IssueSeverity>,
  ) => Promise<void>;
};

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with saving auto-export settings",
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Auto-export settings has been successfully saved.",
  });
};

export const useSaveAutoExportSettings = (): useSaveAutoExportSettingsInterface => {
  const [saveAutoExportSettings] = useMutation<
    { flexibleTestingSaveAutoExportSettings: FlexibleTestingSaveAutoExportSettingsPayload },
    { input: FlexibleTestingSaveAutoExportSettingsInput }
  >(SAVE_AUTO_EXPORT_SETTINGS, {
    onCompleted: () => displaySuccess(),
    onError: displayError,
  });

  return {
    saveAutoExportSettings: async (
      enabledDestinations: Array<AutoExportDestination>,
      severitiesForExport: Array<IssueSeverity>,
    ): Promise<void> => {
      await saveAutoExportSettings({
        variables: {
          input: {
            enabledDestinations,
            severitiesForExport,
          },
        },
      });
    },
  };
};
