import { NetworkStatus, gql, useQuery } from "@apollo/client";

import {
  FlexibleTestingIssueFilter,
  FlexibleTestingIssue,
  FlexibleTestingIssueConnection,
  FlexibleTestingIssueSort,
} from "types/graphql";
import { IssueProps } from "components/flexible_testing/result_details_page/issues/issues_list/issues_list";
import { useEffect, useState } from "react";

type FlexibleTestingIssuesResponse = {
  flexibleTestingIssues: FlexibleTestingIssueConnection;
};

export const ISSUE_QUERY = gql`
  query FlexibleTestingIssues(
    $filters: FlexibleTestingIssueFilter
    $sort: [FlexibleTestingIssueSort!]
    $cursor: String
  ) {
    flexibleTestingIssues(filters: $filters, sort: $sort, first: 25, after: $cursor) {
      edges {
        node {
          _id
          id
          code
          title
          createdAt
          severity
          test {
            name
          }
          issueTags {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

function useIssues(filters?: FlexibleTestingIssueFilter, sort?: Array<FlexibleTestingIssueSort>) {
  const { data, fetchMore, networkStatus } = useQuery(ISSUE_QUERY, {
    variables: { filters, sort },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [cursor, setCursor] = useState("");

  useEffect(() => {
    setCursor(data?.flexibleTestingIssues?.pageInfo.endCursor || "");
  }, [data]);

  const issues: IssueProps[] =
    data?.flexibleTestingIssues?.edges?.map(({ node }: { node: FlexibleTestingIssue }) => node) || [];

  return {
    issues,
    isLoading: networkStatus === NetworkStatus.loading,
    isLoadingNextPage: networkStatus === NetworkStatus.fetchMore,
    hasNextPage: data?.flexibleTestingIssues?.pageInfo.hasNextPage,
    cursor: data?.flexibleTestingIssues?.pageInfo.endCursor,
    loadNextPage: async () =>
      fetchMore({
        variables: { cursor },
        updateQuery: (previousQueryResult, { fetchMoreResult, variables }): FlexibleTestingIssuesResponse => ({
          flexibleTestingIssues: {
            ...fetchMoreResult?.flexibleTestingIssues,
            nodes: fetchMoreResult?.flexibleTestingIssues.nodes || [],
            edges: previousQueryResult.flexibleTestingIssues.edges.concat(
              fetchMoreResult?.flexibleTestingIssues.edges || [],
            ),
            pageInfo:
              fetchMoreResult?.flexibleTestingIssues.pageInfo || previousQueryResult.flexibleTestingIssues.pageInfo,
          },
        }),
      }),
  };
}

export default useIssues;
