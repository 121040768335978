import React from "react";

const FinishedTestInfo = () => (
  <div>
    <div className="font-bold pb-2xl border-b border-light flex items-center">
      <div className="border-l-4 pl-sm border-muted text-xs text-secondary tracking-wider">NOT TESTED</div>
    </div>
    <div className="py-2xl border-b border-light">
      <div className="font-bold mb-sm">This test case was not tested</div>
      <div>This test case was not finished by our testers within the testing window</div>
    </div>
  </div>
);

export default FinishedTestInfo;
