import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { IntegrationPage as FlexibleTestingIntegrationPage } from "../../../../flexible_testing/integration_page";
import { GET_AVAILABLE_INTEGRATIONS } from "components/shared/integration/integration_queries";

const IntegrationQuery = props => {
  // This should be refactored. We should not load all integrations here, just the specific one
  const { loading, error, data } = useQuery(GET_AVAILABLE_INTEGRATIONS);
  if (error) {
    return <p>{error.Error}</p>;
  }
  const integrations =
    (data && data.availableIntegrations && data.availableIntegrations.edges.map(edge => edge.node)) || [];

  const integration = integrations.find(integration => integration.code === props.match.params.integrationCode);

  return (
    <>
      <FlexibleTestingIntegrationPage loading={loading} integration={integration} />
    </>
  );
};

IntegrationQuery.propTypes = {
  match: PropTypes.object.isRequired,
};

export default IntegrationQuery;
