import React from "react";

import { Activity } from "components/flexible_testing/test_request_page/requested_test_page/activity_step_content";
import { StepSummaryHeading } from "./step_summary_heading";
import { getActivityTitle } from "components/flexible_testing/test_request_page/requested_test_page/helpers";

type ActivityStepSummaryProps = {
  activity: Activity;
};

export const ActivityStepSummary = ({ activity }: ActivityStepSummaryProps) => {
  const testType = getActivityTitle(activity);

  return <StepSummaryHeading heading="Activity" subheading={testType} dataTestId="activity-step-summary" />;
};
