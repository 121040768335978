import { ApiPath } from "../../components/configuration";

function useSignUpWithTrial() {
  const hubspotCookie = (): string => document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, "$1");

  return {
    signUpWithTrial: async (email: string, firstName: string, lastName: string) =>
      await fetch(`${ApiPath}/sign_up`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          hubspotCookie: hubspotCookie(),
        }),
      }).then(response => response.json()),
  };
}

export default useSignUpWithTrial;
