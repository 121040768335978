import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Heading } from "@hopper/heading";
import { useParams } from "react-router";

import {
  Country,
  Device,
  FlexibleTestingTestCaseParams,
  InternetBrowser,
  Language,
  MatchingTester,
  MobileDevice,
  MobileDeviceBrand,
  OperatingSystem,
  OperatingSystemVersion,
} from "types/graphql";
import { websiteContentPaths } from "components/configuration";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { BackToPreviousPageLink } from "components/flexible_testing/shared/back_to_previous_page";
import { SupervisedTestContent } from "./supervised_test_content";

type SupervisedTest = "supervised_test";
export const supervisedTest: SupervisedTest = "supervised_test";

export type TesterGroupParams = {
  id: number;
  name: string;
  operatingSystemVersions: Array<OperatingSystemVersion>;
  selectedTesterEmails: string;
  backupTesterEmails: string;
  numberOfResultsNeeded: number;
  deviceTypes: Device[];
  internetBrowsers: InternetBrowser[];
  manufacturers: MobileDeviceBrand[];
  mobileDevices: MobileDevice[];
  operatingSystems: OperatingSystem[];
  countries: Country[];
  languages: Language[];
  other?: string;
  matchingTesters?: MatchingTester[];
  automaticTesterSelectionEnabled: boolean;
  automaticTesterSelectionDisabledReason: string;
  tags: string[];
};

export type SupervisedTestPreviewPageRequest = {
  applicationUrl: string;
  name: string;
  description: string;
  credentialsAndAccessInstructions: string;
  additionalUrlsAndResources: string;
  testCasesFile: File | null;
  testCases: Array<FlexibleTestingTestCaseParams>;
  testEnvironments: Array<string>;
  isTestScheduled: boolean;
  testType: SupervisedTest;
  testerSpecificationDocument: string;
  testerGroups: Array<TesterGroupParams>;
  payoutBaseAmount: number;
  requiredAttachmentForPass: boolean;
  applyCountryPayoutMultiplier: boolean;
  automaticTesterManagementEnabled: boolean;
  automaticTesterManagementDisabledReason: string;
  requestedTestId?: string;
};

type SupervisedTestPreviewPageProps = {
  testRequest: SupervisedTestPreviewPageRequest;
};

type BackToLaunchSupervisedTestProps = {
  launchPath: string;
};

type Params = {
  id: string;
};

const BackToLaunchSupervisedTest = ({ launchPath }: BackToLaunchSupervisedTestProps) => {
  const location = useLocation();

  return (
    <BackToPreviousPageLink
      to={{
        pathname: launchPath,
        state: location.state,
      }}
    >
      Back to launch
    </BackToPreviousPageLink>
  );
};

export const SupervisedTestPreviewPage = ({ testRequest }: SupervisedTestPreviewPageProps) => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const launchPath = websiteContentPaths.supervisedTestLaunchChild.replace(":id", id);

  const handleEditTest = () => {
    history.push(launchPath + history.location.search, { ...testRequest });
  };

  return (
    <div data-testid="preview-supervised-test-page">
      <PageTemplate
        backButton={<BackToLaunchSupervisedTest launchPath={launchPath} />}
        header={<Heading size={1}>Test preview</Heading>}
      >
        <SupervisedTestContent handleEditTest={handleEditTest} testRequest={testRequest} />
      </PageTemplate>
    </div>
  );
};
