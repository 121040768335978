import { isFunction } from "lodash";

class EventBus {
  handlers = {};

  on = (event, handler) => {
    if (!this.handlers[event]) {
      this.handlers[event] = [];
    }

    this.handlers[event].push(handler);
  };

  off = (event, handler) => {
    if (!isFunction(handler)) {
      throw new Error(`EventBus: Trying to remove event "${event}" without providing handler to be removed`);
    }

    const handlers = this.handlers[event];

    if (!handlers) {
      return;
    }

    this.handlers[event] = handlers.filter(registeredHandler => registeredHandler !== handler);
  };

  dispatch = (event, ...params) => {
    const handlers = this.handlers[event] || [];

    handlers.forEach(handler => handler(...params));
  };
}

export default EventBus;
