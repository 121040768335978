import React from "react";

import { Modal, ModalBody } from "@hopper/legacy_modal";
import Truncate from "react-truncate";
import { ModalFooter } from "react-bootstrap";
import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";

type AddTestersValidationModalProps = {
  onClose: (status?: string) => void;
  onConfirm: (status?: string) => void;
  isOpen: boolean;
  invalidTesters: string[];
};

function AddTestersValidationModal({
  onClose,
  onConfirm,
  isOpen,
  invalidTesters,
}: AddTestersValidationModalProps): JSX.Element {
  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={() => onClose()}
      isOpen={isOpen}
      position="center"
      size="lg"
      onOverlayClicked={() => {}}
    >
      <ModalBody className="flex flex-col h-full overflow-hidden">
        <div className="flex flex-col items-center justify-center max-w-xs sm-max-w-full text-center">
          <div
            className="rounded-full flex items-center justify-center mb-2xl"
            style={{ backgroundColor: "rgba(236, 107, 88, 0.12)", height: "4.5rem", width: "4.5rem" }}
            data-testid="error-icon"
          >
            <i className="fa fa-exclamation text-danger opacity-100 text-3xl" aria-hidden="true" />
          </div>
        </div>

        <Heading size={3}>Some testers do not meet OS requirements</Heading>

        <div className="overflow-y-auto overflow-x-hidden mb-2xl" style={{ maxHeight: "400px" }}>
          {invalidTesters.map((email: string) => (
            <div className="mr-2xl">
              <div className="text-dark text-sm">
                <Truncate ellipsis="..." lines={1} width={500}>
                  {email}
                </Truncate>
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => onConfirm()}>Add tester anyway</Button>
        <Button onClick={() => onClose()} variant="secondary">
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddTestersValidationModal;
