import React, { useState } from "react";
import { Modal } from "@hopper/modal";

import TestLaunchedModal from "components/flexible_testing/preview_page/content/success_modal/test_launched_modal";
import AskForOrganizationName from "components/flexible_testing/preview_page/content/success_modal/ask_for_organization_name";

type SuccessPopupProps = {
  formattedTestLaunchDateTime: string | null;
  isOpen?: boolean;
  onCloseModalClicked?: () => void;
  resultsUrl: string;
  shouldAskForOrganizationChosenName: boolean;
};

function SuccessModal({
  formattedTestLaunchDateTime,
  isOpen = false,
  onCloseModalClicked = () => {},
  resultsUrl,
  shouldAskForOrganizationChosenName,
}: SuccessPopupProps): JSX.Element {
  const [isChosenOrganizationNameMissing, setIsChosenOrganizationNameMissing] = useState(true);

  return (
    <>
      {shouldAskForOrganizationChosenName && isChosenOrganizationNameMissing ? (
        <Modal
          isShowingCloseButton={true}
          onCloseButtonClicked={onCloseModalClicked}
          topOffset={5}
          isOpen={isOpen}
          onOverlayClicked={onCloseModalClicked}
          overlayColor="rgba(255, 255, 255, 0.5)"
        >
          <div data-testid="success-modal">
            <AskForOrganizationName
              onSave={() => {
                setIsChosenOrganizationNameMissing(false);
              }}
            />
          </div>
        </Modal>
      ) : (
        <TestLaunchedModal
          formattedTestLaunchDateTime={formattedTestLaunchDateTime}
          isOpen={isOpen}
          onCloseModalClicked={onCloseModalClicked}
          resultsUrl={resultsUrl}
        />
      )}
    </>
  );
}

export default SuccessModal;
