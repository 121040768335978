import React, { useEffect, useState } from "react";

import { Header, HeaderCell, Table } from "components/flexible_testing/shared/table";
import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import EditRequirementsGroupPanel from "./edit_requirements_group_panel";
import { Checkbox } from "@hopper/checkbox";
import TesterGroup from "components/flexible_testing/result_details_page/testers/tester_group";
import { AutomaticTesterManagementLabel } from "./automatic_tester_management_label";
import { Participant } from "components/flexible_testing/shared/chat";
import Authorization from "app/authorization/authorization";
import { Warning } from "components/flexible_testing/shared/warning";
import { Loader } from "@hopper/loading";
import { FlexibleTestingTest, OrganizationUser } from "types/graphql";
import { RemoveTesterButton } from "../../shared/remove_tester_button";

type TestersProps = {
  currentUser?: OrganizationUser;
  platformTest?: FlexibleTestingTest;
  isLoading: boolean;
  selectionRequirementsGroups: SelectionRequirementsGroup[];
  testersLoading: boolean;
  testStatus: string;
};

const UNREMOVABLE_TESTERS_STATUSES = [
  "removed",
  "results_published",
  "publishing_results",
  "publishing_failed",
  "dropped_out",
  "expired",
];

const Testers = ({
  currentUser,
  platformTest,
  isLoading,
  selectionRequirementsGroups,
  testersLoading,
  testStatus,
}: TestersProps) => {
  const [selectedRequirementsGroupID, setSelectedRequirementsGroupID] = useState<string>();
  const selectedRequirementsGroup = selectionRequirementsGroups.find(({ id }) => id === selectedRequirementsGroupID);
  const [selectedParticipantIds, setSelectedParticipantIds] = useState<Set<string>>(new Set());

  const [filteredSelectionRequirementsGroups, setFilteredSelectionRequirementsGroups] =
    useState<SelectionRequirementsGroup[]>(selectionRequirementsGroups);
  const [hiddenStatuses, setHiddenStatuses] = useState<Array<string>>(["declined", "dropped_out"]);
  const shouldDisplayPublishingFailedWarning = selectionRequirementsGroups.some(group =>
    group.participants.some(participant => participant.status === "publishing_failed"),
  );
  const canModifyNumberOfResultsNeeded = platformTest?.canModifyNumberOfResultsNeeded || false;
  const supportsAutomaticTesterManagement = platformTest?.supportsAutomaticTesterManagement || false;
  const automaticTesterManagementEnabled = platformTest?.automaticTesterManagementEnabled || false;

  useEffect(() => {
    setFilteredSelectionRequirementsGroups(filterParticipantGroupsByStatus(hiddenStatuses));
  }, [hiddenStatuses, selectionRequirementsGroups]);

  useEffect(() => {
    const removedParticipantsIds = flattenAndFilterParticipants(({ status }) => status === "removed").map(
      ({ id }) => id,
    );
    const updatedSelectedParticipantIds = new Set(
      [...selectedParticipantIds].filter(id => !removedParticipantsIds.includes(id)),
    );

    setSelectedParticipantIds(updatedSelectedParticipantIds);
  }, [filteredSelectionRequirementsGroups]);

  if (testersLoading || isLoading) {
    return <Loader />;
  }

  function filterParticipantGroupsByStatus(status: string[]) {
    return selectionRequirementsGroups.map(group => {
      const newGroup = { ...group };
      newGroup.participants = group.participants.filter(participant => !status.includes(participant.status));

      return newGroup;
    });
  }

  function toggleStatusFilter(statuses: string[]) {
    setHiddenStatuses(prev => [
      ...prev.filter(status => !statuses.includes(status)),
      ...statuses.filter(status => !prev.includes(status)),
    ]);
  }

  function flattenAndFilterParticipants(filterFunction: (value: Participant) => boolean) {
    return filteredSelectionRequirementsGroups
      .map(group => group.participants)
      .flat()
      .filter(filterFunction);
  }

  const removableParticipantsIds = new Set(
    flattenAndFilterParticipants(participant => !UNREMOVABLE_TESTERS_STATUSES.includes(participant.status)).map(
      ({ id }) => id,
    ),
  );

  const handleSelectAllParticipants = () => {
    const updatedSelectedIds: Set<string> =
      selectedParticipantIds.size !== removableParticipantsIds.size ? new Set(removableParticipantsIds) : new Set();

    setSelectedParticipantIds(updatedSelectedIds);
  };

  const handleSingleParticipantSelection = (id: string) => {
    const selected = new Set(selectedParticipantIds);

    if (selected.has(id)) {
      selected.delete(id);
    } else {
      selected.add(id);
    }

    setSelectedParticipantIds(selected);
  };

  const selectedParticipants = flattenAndFilterParticipants(({ id }) => selectedParticipantIds.has(id));

  return (
    <>
      <div className="my-4xl flex flex-wrap gap-y-4xl justify-between">
        <div className="flex flex-col mt-2xl gap-y-2xl">
          {shouldDisplayPublishingFailedWarning && (
            <div className="flex">
              <Warning>
                <span className="font-bold">Publishing failed</span> for some results (
                {currentUser?.communityTestManager
                  ? "please contact a test manager"
                  : "please contact #tech-help for support"}
                )
              </Warning>
            </div>
          )}
          <Authorization roles={["testManager"]}>
            <div className="flex flex-wrap gap-y-2xl justify-between">
              <div className="flex gap-xl">
                {!platformTest?.hasStrictTesterRemoval && platformTest && (
                  <RemoveTesterButton
                    participants={selectedParticipants}
                    testId={platformTest.id}
                    disabled={selectedParticipants.length === 0}
                    hasStrictTesterRemoval={false}
                  />
                )}
                {supportsAutomaticTesterManagement && (
                  <AutomaticTesterManagementLabel automaticTesterManagementEnabled={automaticTesterManagementEnabled} />
                )}
              </div>
            </div>
          </Authorization>
        </div>
        <div className="gap-lg flex flex-grow items-end lg:justify-end">
          <Checkbox
            label="Show ‘declined’ & ‘dropped out’"
            onChange={() => toggleStatusFilter(["declined", "dropped_out"])}
            isChecked={!hiddenStatuses.includes("declined") && !hiddenStatuses.includes("dropped_out")}
          />
          <Checkbox
            label="Show 'invited'"
            onChange={() => toggleStatusFilter(["invited"])}
            isChecked={!hiddenStatuses.includes("invited")}
          />
          <Checkbox
            label="Show 'removed'"
            onChange={() => toggleStatusFilter(["removed"])}
            isChecked={!hiddenStatuses.includes("removed")}
          />
        </div>
      </div>
      <EditRequirementsGroupPanel
        selectionRequirementsGroup={selectedRequirementsGroup}
        testId={platformTest?.id || ""}
        isOpen={!!selectedRequirementsGroup}
        close={() => setSelectedRequirementsGroupID(undefined)}
        automaticTesterManagementEnabled={automaticTesterManagementEnabled}
        canModifyNumberOfResultsNeeded={canModifyNumberOfResultsNeeded}
      />
      <Table borderColor="muted" className="pt-2xl mt-2xl" data-testid="testers-overview-table">
        <Header className="border-b border-muted">
          <>
            {platformTest?.hasStrictTesterRemoval || <HeaderCell style={{ width: "2%" }} />}
            <HeaderCell style={{ width: "21%" }}>TESTER</HeaderCell>
            <HeaderCell style={{ width: "15%" }}>STATUS</HeaderCell>
            <HeaderCell style={{ width: "18%" }}>PROGRESS</HeaderCell>
            <HeaderCell style={{ width: "10%" }}></HeaderCell>
            <HeaderCell style={{ width: "12%" }}>ISSUES</HeaderCell>
            <HeaderCell style={{ width: "12%" }}>LAST RESULT</HeaderCell>
            <HeaderCell style={{ width: "10%" }}></HeaderCell>
          </>
        </Header>
        {filteredSelectionRequirementsGroups.map(group => (
          <TesterGroup
            key={group.id}
            group={group}
            testCasesCount={group.numberOfAssignedCases}
            onSelectAll={handleSelectAllParticipants}
            onSelectIndividual={handleSingleParticipantSelection}
            removableTestersIds={removableParticipantsIds}
            selectedTesters={selectedParticipantIds}
            setSelectionRequirementsGroupForEdit={({ id }) => setSelectedRequirementsGroupID(id)}
            testStatus={testStatus}
            testName={platformTest?.name || ""}
            testCode={platformTest?.code || ""}
            hasStrictTesterRemoval={platformTest?.hasStrictTesterRemoval || false}
          />
        ))}
      </Table>
    </>
  );
};

export default Testers;
