import { useQuery } from "@apollo/client";
// @ts-ignore
import { GET_CURRENT_ORGANIZATION_USER } from "components/shared/queries/organization_users_query";
import { OrganizationUser } from "types/graphql";

type QueryData = {
  currentOrganizationUser: OrganizationUser;
};

function useCurrentUser(): OrganizationUser | undefined {
  const { data } = useQuery<QueryData>(GET_CURRENT_ORGANIZATION_USER);

  return data?.currentOrganizationUser;
}

export { useCurrentUser };
