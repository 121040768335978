import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router";

import { PageSection } from "components/flexible_testing/shared/page_section";
import { Form, SubmitButton, TextArea, TextInput, ClearButton } from "components/flexible_testing/shared/form";
import { TestCasesFileError } from "components/flexible_testing/shared/test_cases_file_error";
import { TestCases } from "./test_cases";
import { TestDetails } from "./test_details";
import { websiteContentPaths } from "components/configuration";
import {
  SupervisedTestPreviewPageRequest,
  supervisedTest,
  TesterGroupParams,
} from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import { validationSchema } from "./validation_schema";
import { TesterGroupList } from "./tester_group_list";
import useSupervisedTestInitialValues from "./use_supervised_test_initial_values";
import { Loader } from "@hopper/loading";
import { useTestersWithInvalidDevices } from "components/flexible_testing/result_details_page/testers/edit_requirements_group_panel/use_testers_with_invalid_devices";
import { OperatingSystemVersion, MatchingTester } from "types/graphql";
import { AddTestersValidationModal } from "components/flexible_testing/result_details_page/testers/add_testers_validation_modal";
import queryString from "query-string";

type SupervisedTestContentProps = {
  testRequest?: SupervisedTestPreviewPageRequest;
};

type Params = {
  id: string;
};

const cleanEmailsString = (str: string) =>
  str
    .split(",")
    .map(v => v.trim())
    .filter(v => v.length !== 0)
    .join(", ");

const joinTesterEmails = (testers: MatchingTester[]) => testers.map(({ email }) => email).join(", ") || "";

const processTesterGroups = (testerGroups: TesterGroupParams[]) =>
  testerGroups.map((testerGroup: TesterGroupParams) => {
    const { matchingTesters, automaticTesterSelectionEnabled } = testerGroup;
    const selectedTesterEmails = automaticTesterSelectionEnabled
      ? joinTesterEmails(matchingTesters?.slice(0, testerGroup.numberOfResultsNeeded) || [])
      : cleanEmailsString(testerGroup.selectedTesterEmails);
    const backupTesterEmails = automaticTesterSelectionEnabled
      ? joinTesterEmails(matchingTesters?.slice(testerGroup.numberOfResultsNeeded) || [])
      : cleanEmailsString(testerGroup.backupTesterEmails);

    return {
      ...testerGroup,
      selectedTesterEmails,
      backupTesterEmails,
      numberOfResultsNeeded: testerGroup.numberOfResultsNeeded,
      fromPrev: true,
    };
  });

const ActionButtons = () => (
  <>
    <SubmitButton className="mt-3xl mr-xl" useHopperButton={true} style={{ pointerEvents: "auto" }}>
      Preview and launch test
    </SubmitButton>
    <ClearButton>Clear All</ClearButton>
  </>
);

export const SupervisedTestContent = ({ testRequest }: SupervisedTestContentProps) => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const { search } = useLocation();
  const requestedTestId = queryString.parse(search).requested_test_id || "";
  const previewPath = websiteContentPaths.supervisedTestLaunchChildPreview.replace(":id", id);
  const { data: initialValues, loading } = useSupervisedTestInitialValues(id, testRequest, requestedTestId);
  const { testersWithInvalidDevices } = useTestersWithInvalidDevices();

  const [isValidationModalOpen, setValidationModalOpen] = useState(false);
  const [allInvalidTesters, setAllInvalidTesters] = useState<string[]>([]);
  const [replayAction, setReplayAction] = useState({ continue: () => {} });

  if (loading) return <Loader />;

  const beforeSubmit = (requirementGroups: TesterGroupParams[]) => {
    const preliminaryOperatingSystemCheck = requirementGroups.map(
      async (rqGroup: { selectedTesterEmails: string; operatingSystemVersions: OperatingSystemVersion[] }) => {
        const testerEmails = rqGroup.selectedTesterEmails
          ?.split(",")
          .map(e => e.trim())
          .filter(e => e.length > 0);
        const requiredOsVersionIds = rqGroup.operatingSystemVersions.map(x => x.id);
        const invalidEmails = await testersWithInvalidDevices({ testerEmails, requiredOsVersionIds });

        return invalidEmails;
      },
    );

    return Promise.all(preliminaryOperatingSystemCheck);
  };

  const handleSubmit = async (values: { [field: string]: unknown }): Promise<void> => {
    const submissionResults = await beforeSubmit(values.testerGroups as TesterGroupParams[]);
    const anyInvalidEntry = submissionResults.some(result => result.invalidEmails.length);

    const continueSubmission = () => {
      const {
        applicationUrl,
        name,
        description,
        testCases,
        isTestScheduled,
        credentialsAndAccessInstructions,
        additionalUrlsAndResources,
        testEnvironments,
        testerSpecificationDocument,
        testerGroups,
        payoutBaseAmount,
        requiredAttachmentForPass,
        applyCountryPayoutMultiplier,
        automaticTesterManagementEnabled,
        automaticTesterManagementDisabledReason,
      } = values;
      const testRequest = {
        applicationUrl,
        name,
        description,
        credentialsAndAccessInstructions,
        additionalUrlsAndResources,
        testCases,
        testEnvironments,
        isTestScheduled,
        testerSpecificationDocument,
        testerGroups: processTesterGroups(testerGroups as TesterGroupParams[]),
        payoutBaseAmount,
        requiredAttachmentForPass,
        applyCountryPayoutMultiplier,
        automaticTesterManagementEnabled,
        automaticTesterManagementDisabledReason,
        requestedTestId,
      };

      history.push(previewPath + search, {
        ...testRequest,
        testCasesFile: null,
        testType: supervisedTest,
      });
    };

    if (anyInvalidEntry) {
      setAllInvalidTesters(submissionResults.map(r => r.invalidEmails).flat());
      setValidationModalOpen(true);

      return Promise.resolve(setReplayAction({ continue: continueSubmission }));
    }

    return Promise.resolve(continueSubmission());
  };

  function onValidationModalClose(): void {
    setValidationModalOpen(false);
  }
  function onValidationModalConfirm(): void {
    setValidationModalOpen(false);
    replayAction.continue();
  }

  return (
    <div data-testid="supervised-test-content">
      <Form initialValues={initialValues} validations={validationSchema} onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4xl">
          <div>
            <TestDetails />
            <PageSection title="What would you like to include in this test?">
              <div data-test-role="form-group" className="text-xs mb-xl">
                <TextArea
                  name="description"
                  placeholder="What is the goal or objective of this test?"
                  label="Describe the goal of this test"
                  useHopperInput={true}
                  required={false}
                />
              </div>
              <div data-test-role="form-group" className="text-xs mb-xl">
                <TextInput
                  name="testerSpecificationDocument"
                  placeholder="https://docs.google.com/document/..."
                  label="Tester specification document"
                  required={true}
                  labelClass="font-normal"
                />
              </div>
            </PageSection>
            <PageSection title="Give your test a name" className="mb-2xl">
              <div data-test-role="form-group" className="text-xs">
                <TextInput
                  name="name"
                  placeholder="e.g. Account sign-up flow"
                  label="Test name"
                  required={true}
                  labelClass="font-normal"
                />
              </div>
            </PageSection>
            <TestCases
              payoutBaseAmount={testRequest?.payoutBaseAmount}
              advancedTestRequestId={initialValues.advancedTestRequestId}
              rawTestCases={initialValues?.rawTestCases || []}
            />
            <TestCasesFileError />
            <ActionButtons />
          </div>

          <div>
            <div data-test-role="form-group" className="mb-xl">
              <PageSection title="Create a group of settings for testers">
                <TesterGroupList />
              </PageSection>
            </div>
          </div>
        </div>
      </Form>
      <AddTestersValidationModal
        onClose={onValidationModalClose}
        onConfirm={onValidationModalConfirm}
        isOpen={isValidationModalOpen}
        invalidTesters={allInvalidTesters}
      />
    </div>
  );
};
