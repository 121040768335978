import React from "react";

type DescriptionFieldProps = {
  title: string;
  children: React.ReactNode;
};

function DescriptionField({ title, children }: DescriptionFieldProps): JSX.Element {
  return (
    <div className="mb-3xl">
      <span className="text-dark font-bold uppercase tracking-wide text-xs">{title}</span>
      <div className="break-words">{children}</div>
    </div>
  );
}

export default DescriptionField;
