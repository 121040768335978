import { gql, useQuery } from "@apollo/client";
import { ReproducibilityTestDetailEdge } from "types/graphql";

export const REPRODUCIBILITY_TESTS_QUERY = gql`
  fragment testCase on FlexibleTestingTestCase {
    name
    status
    resultsCount
    completedCaseResults {
      status
      additionalInformation
      actualResult
      caseResultAttachments {
        edges {
          node {
            attachment {
              publicUrl
              url
            }
          }
        }
      }
      testerEnvironment {
        browserOrDevice
        operatingSystemAndVersion
      }
    }
  }

  query ReproducibilityTestDetails($issueId: ID!) {
    reproducibilityTestDetails(filters: { issue_id_eq: $issueId }) {
      edges {
        node {
          test {
            id
            cases {
              totalCount
              nodes {
                ...testCase
              }
            }
          }
        }
      }
    }
  }
`;

function useReproducibilityTests(issue_id: string) {
  const { data } = useQuery(REPRODUCIBILITY_TESTS_QUERY, {
    variables: { issueId: issue_id },
    fetchPolicy: "cache-and-network",
  });
  const reproducibilityTestDetails =
    data?.reproducibilityTestDetails?.edges?.map((edge: ReproducibilityTestDetailEdge) => edge?.node) || [];

  return { reproducibilityTestDetails };
}

export default useReproducibilityTests;
