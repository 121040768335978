import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { PopupMenu, PopupMenuItem } from "@hopper/popup_menu";
import { Button } from "@hopper/button";

import { TestTypes } from "components/flexible_testing/preview_page/preview_page";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { websiteContentPaths } from "components/configuration";
import Popup from "components/shared/popup";

export function CloneTestRunFromMultipleTestingServices(): JSX.Element {
  const [isServiceTypeDropdownOpen, setIsServiceTypeDropdownOpen] = React.useState(false);
  const wrapperRef = useRef(null);
  const history = useHistory();

  useOutsideHandler(wrapperRef, () => setIsServiceTypeDropdownOpen(false));

  const serviceTypeSelected = (serviceType: TestTypes) => {
    const clonePagePath =
      serviceType === "standard_test"
        ? websiteContentPaths.flexcaseTestsForCloning
        : websiteContentPaths.advancedTestsForCloning;

    setIsServiceTypeDropdownOpen(false);
    history.push(clonePagePath);
  };

  return (
    <div ref={wrapperRef}>
      <Popup popupElement={ServiceTypeDropdown(serviceTypeSelected)} isOpen={isServiceTypeDropdownOpen} offset={[0, 2]}>
        <Button
          className="cursor-pointer"
          variant="secondary"
          data-testid="clone-test-button"
          onClick={() => {
            setIsServiceTypeDropdownOpen(!isServiceTypeDropdownOpen);
          }}
        >
          Clone a previous test run
        </Button>
      </Popup>
    </div>
  );
}

function ServiceTypeDropdown(onServiceTypeSelection: (serviceType: TestTypes) => void): JSX.Element {
  return (
    <PopupMenu>
      <PopupMenuItem data-testid="clone-standard-test-option" onClick={() => onServiceTypeSelection("standard_test")}>
        Clone an Express test
      </PopupMenuItem>
      <PopupMenuItem data-testid="clone-advanced-test-option" onClick={() => onServiceTypeSelection("advanced_test")}>
        Clone an Advanced test
      </PopupMenuItem>
    </PopupMenu>
  );
}
