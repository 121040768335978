import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Body, BodyCell, Row } from "components/flexible_testing/shared/table";
import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import { Checkbox } from "@hopper/checkbox";
import OpenRequirementsGroupPanelButton from "./open_requirements_group_panel_button";
import { websiteContentPaths } from "components/configuration";
import pluralize from "pluralize";
import Authorization from "app/authorization/authorization";

import { ProgressBar } from "./progress_bar";
import IssuesModerationStatus from "./issues_moderation_status";

type TesterGroupHeaderProps = {
  isCollapsed: boolean;
  isPublished: boolean;
  group: SelectionRequirementsGroup;
  onClick: () => void;
  isSelectingDisabled?: boolean;
  isSelected?: boolean;
  onSelect?: () => void;
  setSelectionRequirementsGroupForEdit?: (group: SelectionRequirementsGroup) => void;
  testStatus?: string;
  canSelectAll?: boolean;
};

type RouteParams = {
  id?: string;
};

function TesterGroupHeader({
  group,
  isCollapsed,
  isPublished,
  onClick,
  isSelected = false,
  isSelectingDisabled = false,
  onSelect = () => {},
  setSelectionRequirementsGroupForEdit,
  testStatus,
  canSelectAll = true,
}: TesterGroupHeaderProps) {
  const history = useHistory();
  const routeMatch = useRouteMatch<RouteParams>(websiteContentPaths.flexcaseResult);
  const testId = routeMatch?.params?.id;

  const onOpenGroupPanelClick = (e: React.MouseEvent, tabName: string) => {
    e.stopPropagation();

    if (testId) {
      const resultsUrl = websiteContentPaths.flexcaseResult.replace(":id", testId);

      history.push(`${resultsUrl}?tab=testers&testSettingsTab=${tabName}`);
    }

    setSelectionRequirementsGroupForEdit && setSelectionRequirementsGroupForEdit(group);
  };

  return (
    <Body>
      <Row className="min-w-full" testId="group-row">
        {canSelectAll && (
          <BodyCell>
            <Checkbox isChecked={isSelected} disabled={isSelectingDisabled} onChange={onSelect} />
          </BodyCell>
        )}
        <BodyCell className="cursor-pointer" onClick={onClick} colSpan={2}>
          <div className="flex items-center">
            {isCollapsed ? (
              <i className="fa fa-chevron-down text-sm" aria-hidden="true" />
            ) : (
              <i className="fa fa-chevron-up text-sm" aria-hidden="true" />
            )}
            {isPublished && <i className="fa fa-check-circle text-success ml-md" />}
            <div data-testid="group-name" className="uppercase ml-md mr-lg font-bold">
              {group.name}
            </div>
            <Authorization roles={["testManager"]}>
              <>
                {setSelectionRequirementsGroupForEdit && testStatus !== "closed" && (
                  <div>
                    <OpenRequirementsGroupPanelButton
                      text="Add tester"
                      testId="add-tester-button"
                      onClick={event => onOpenGroupPanelClick(event, "addTesters")}
                    />
                    <span style={{ width: "0.3rem", height: "0.3rem" }} className="rounded-full bg-muted mx-sm" />
                    <OpenRequirementsGroupPanelButton
                      text="Edit"
                      testId="edit-group-button"
                      className="mr-sm"
                      onClick={event => onOpenGroupPanelClick(event, "editGroupRequirements")}
                    />
                  </div>
                )}
              </>
            </Authorization>
          </div>
        </BodyCell>
        <BodyCell data-testid="group-progress">
          <ProgressBar
            total={group.numberOfResultsNeeded}
            completed={group.numberOfApprovedResults}
            color={group.numberOfPublishedResults === group.numberOfResultsNeeded ? "success" : "primary"}
          />
        </BodyCell>
        <BodyCell className="text-sm text-dark" data-testid="group-results-needed">
          {group.numberOfResultsNeeded} {pluralize("result", group.numberOfResultsNeeded)} needed
        </BodyCell>
        <BodyCell data-testid="group-issues" colSpan={3}>
          {group.hasReportedIssues && (
            <IssuesModerationStatus
              moderationStatus={group.moderationStatus}
              numberOfPendingIssueModerations={group.numberOfPendingIssueModerations}
              only={["pending", "moderated"]}
            />
          )}
        </BodyCell>
      </Row>
    </Body>
  );
}

export default TesterGroupHeader;
