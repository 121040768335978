import React from "react";
import { Button } from "@hopper/button";

export type ViewMoreButtonProps = {
  onClick: () => void;
  isLoading: boolean;
};

const ViewMoreButton = ({ onClick, isLoading }: ViewMoreButtonProps): JSX.Element => (
  <Button
    data-testid="view-more-button"
    appearance="ghost"
    variant="secondary"
    isLoading={isLoading}
    onClick={onClick}
    className="mt-2xl"
  >
    View more
  </Button>
);

export default ViewMoreButton;
