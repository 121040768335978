import { gql, useQuery } from "@apollo/client";

export const ISSUE_QUERY = gql`
  query FlexibleTestingIssue($id: ID!) {
    flexibleTestingIssue(id: $id) {
      id
      _id
      testCases {
        id
        code
        name
      }
      code
      title
      createdAt
      severity
      triageStatus
      triageReason
      comment
      issueNote {
        note
        createdAt
        updatedAt
        authorName
        lastEditorName
      }
      issueFields {
        edges {
          node {
            id
            value
            issueFieldDefinition {
              id
              code
              label
            }
          }
        }
      }
      attachments {
        edges {
          node {
            url
          }
        }
      }
      issueTags {
        edges {
          node {
            id
            name
          }
        }
      }
      testerCountry {
        name
        code
      }
      test {
        id
        name
        forceSeverityChangeBeforeAccept
        issuesCount
        triagedIssuesCount
        testWindow {
          status
        }
      }
    }
  }
`;

function useIssue(id: string) {
  const { data, loading } = useQuery(ISSUE_QUERY, { variables: { id }, fetchPolicy: "cache-and-network" });

  return {
    data,
    isLoading: loading,
  };
}

export default useIssue;
