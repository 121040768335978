import React from "react";
import classnames from "classnames";
import { FlexibleTestingIssueTag } from "types/graphql";
import pluralize from "pluralize";

type DisplayButtonProps = {
  currentTags: FlexibleTestingIssueTag[];
  isOpen: boolean;
  onClick: () => void;
  issuesLength?: number;
  tagsFilterMode?: string;
};

const FilterDisplayButton = ({ currentTags, isOpen, onClick, issuesLength, tagsFilterMode }: DisplayButtonProps) => {
  const isFilterAll = tagsFilterMode === "all";

  return (
    <>
      <div
        style={{ width: "180px" }}
        className={classnames(
          "flex justify-between cursor-pointer hover:text-body",
          isOpen ? "text-body" : "text-secondary",
        )}
        onClick={onClick}
        data-testid="add-issue-tag-filter"
      >
        <span className={currentTags.length ? (isFilterAll ? "text-danger" : "text-link") : "opacity-50"}>
          <i className={`fa ${currentTags.length < 2 ? "fa-tag" : "fa-tags"}`} />
        </span>
        <span className="px-sm flex-grow text-left">
          With <b>{currentTags.length !== 1 && (isFilterAll ? "all" : "any")}</b> {currentTags.length || ""}{" "}
          {pluralize("Tag", currentTags.length)}
          ...
        </span>
        <span className="text-secondary ml-md">{issuesLength || 0}</span>
      </div>
    </>
  );
};

export default FilterDisplayButton;
