import React from "react";
import { StatusLabel } from "@hopper/status_label";
import { FlexibleTestingCaseResultEditRequest } from "types/graphql";
import { gql, useMutation } from "@apollo/client";
import { differenceInMinutes, differenceInHours } from "date-fns";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

const statuses = ["cancelled", "requested", "overdue", "completed", "moderated"] as const;

type ModerationStatusProps = {
  editRequest: FlexibleTestingCaseResultEditRequest;
  caseResultId: string;
};

type OptionVariation = {
  label: string;
  emphasised: boolean;
  showTimeElapsed: boolean;
  visible: boolean;
  showXButton: boolean;
};

const FINALIZE_CASE_RESULT_MODERATION_MUTATION = gql`
  mutation ($input: FlexibleTestingCaseResultFinalizeModerationInput!) {
    flexibleTestingCaseResultFinalizeModeration(input: $input) {
      successful
      result {
        id
        latestCaseResultEditRequest {
          status
          createdAt
        }
      }
    }
  }
`;

const optionVariations: Record<string, OptionVariation> = {
  cancelled: { label: "Cancelled", emphasised: false, showTimeElapsed: false, visible: false, showXButton: false },
  requested: { label: "Edit requested", emphasised: false, showTimeElapsed: true, visible: true, showXButton: false },
  overdue: { label: "Edit requested", emphasised: true, showTimeElapsed: true, visible: true, showXButton: false },
  completed: { label: "Edit completed", emphasised: true, showTimeElapsed: false, visible: true, showXButton: true },
  moderated: { label: "Moderated", emphasised: false, showTimeElapsed: false, visible: false, showXButton: false },
};

function timeElapsed({ startDate }: { startDate: string }): string {
  const currentDate = new Date();
  const parsedStartDate = new Date(startDate);

  const minutesPassed = differenceInMinutes(currentDate, parsedStartDate);
  const hoursPassed = differenceInHours(currentDate, parsedStartDate);

  return hoursPassed < 1 ? `(${minutesPassed}min ago)` : `(${hoursPassed}h ago)`;
}

function ModerationStatusIndicator({ editRequest, caseResultId }: ModerationStatusProps) {
  const { status, createdAt } = editRequest;
  const editRequestStatus = status as typeof statuses[number];
  const [finalizeModeration] = useMutation(FINALIZE_CASE_RESULT_MODERATION_MUTATION);
  const xMarkSymbol = "\u2716";

  const statusOptions = optionVariations[editRequestStatus];

  const handleFinalizeModerationButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await finalizeModeration({
      variables: {
        input: {
          caseResultId,
        },
      },
      onCompleted: () => {
        notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
          type: "success",
          message: "Case Result has been marked as moderated.",
        });
      },
      onError: () => {
        notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
          type: "danger",
          message: "There was an issue during moderation finalisation.",
        });
      },
    });
  };

  return (
    <>
      {statusOptions?.visible && (
        <div data-testid="moderation-status-indicator">
          <StatusLabel variant={statusOptions.emphasised ? "default" : "neutral"}>
            {statusOptions.label} {statusOptions.showTimeElapsed && timeElapsed({ startDate: createdAt })}
            {statusOptions.showXButton && (
              <button className="mx-xs" onClick={handleFinalizeModerationButtonClick}>
                {xMarkSymbol}
              </button>
            )}
          </StatusLabel>
        </div>
      )}
    </>
  );
}

export default ModerationStatusIndicator;
