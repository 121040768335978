import { isEmpty } from "lodash";
import { FlexibleTestingTestCase } from "types/graphql";
import { AssignedCaseWithOwnResult } from "components/flexible_testing/tester_overview/tester_overview";

export type TestCasesGroup = {
  section: string;
  testCases: Array<FlexibleTestingTestCase | AssignedCaseWithOwnResult>;
};

function groupTestCasesBySection(
  testCases: Array<FlexibleTestingTestCase | AssignedCaseWithOwnResult>,
): Array<TestCasesGroup> {
  return testCases.reduce(groupBySection, []).sort(sortBySection);
}

function groupBySection(
  testCasesGroups: Array<TestCasesGroup>,
  testCase: FlexibleTestingTestCase | AssignedCaseWithOwnResult,
): Array<TestCasesGroup> {
  const sectionName = testCase.section || "";
  const testCasesGroup = testCasesGroups.find(testCasesGroup => testCasesGroup.section === sectionName);

  if (testCasesGroup) {
    testCasesGroup.testCases.push(testCase);
  } else {
    testCasesGroups.push({ section: sectionName, testCases: [testCase] });
  }

  return testCasesGroups;
}

function sortBySection(firstTestCase: TestCasesGroup, secondTestCase: TestCasesGroup): number {
  const firstSectionName = firstTestCase.section.toLowerCase();
  const secondSectionName = secondTestCase.section.toLowerCase();

  if (isEmpty(secondSectionName) && !isEmpty(firstSectionName)) {
    return -1;
  } else if (isEmpty(firstSectionName) || !isEmpty(secondSectionName)) {
    return 1;
  } else {
    return 0;
  }
}

export default groupTestCasesBySection;
