import React from "react";
import { useField, useFormikContext } from "formik";
import { Checkbox } from "@hopper/checkbox";

// @ts-ignore
import { FileUpload } from "components/flexible_testing/shared/test_cases/file_upload";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { CurrencyInput } from "components/flexible_testing/shared/form";
import { useTotalPayoutForTestCases } from "components/flexible_testing/shared/use_total_payout_for_test_cases";
import { SupervisedTestPreviewPageRequest } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import { TestCase } from "types/test_cases";
import { additionalInformationDocumentationUrl, CSVBoxConfig } from "components/configuration";
import { useCsvExport } from "./use_csv_export";
import { FlexibleTestingTestCase } from "types/graphql";

type TestCasesProps = {
  payoutBaseAmount?: number;
  advancedTestRequestId?: string;
  rawTestCases?: Array<FlexibleTestingTestCase>;
};

const TestCases = ({ payoutBaseAmount, rawTestCases, advancedTestRequestId }: TestCasesProps): JSX.Element => {
  const [testCases, _testCasesMeta, { setValue: setTestCases }] = useField<Array<TestCase> | null>("testCases");
  const [payoutBaseAmountInput] = useField<number>("payoutBaseAmount");
  const formattedTotalPayoutAmount = useTotalPayoutForTestCases(testCases.value?.length, payoutBaseAmountInput.value);
  const [requiredAttachmentForPassInput] = useField<boolean>("requiredAttachmentForPass");
  const [applyCountryPayoutMultiplierInput] = useField<boolean>("applyCountryPayoutMultiplier");
  const { setFieldValue } = useFormikContext<SupervisedTestPreviewPageRequest>();
  const { downloadCsvFile } = useCsvExport(advancedTestRequestId);

  const toggleRequiredAttachmentForPass = () =>
    setFieldValue("requiredAttachmentForPass", !requiredAttachmentForPassInput.value);

  const setApplyCountryPayoutMultiplier = (value: boolean) => setFieldValue("applyCountryPayoutMultiplier", value);

  const radioButtonStyle = { marginTop: 0, marginRight: "0.5rem", accentColor: "#373A40" };

  const isDownloadCsvFileButtonVisible = (rawTestCases && rawTestCases.length > 0) || undefined;

  return (
    <>
      <PageSection title="Payout settings" className="mb-2xl">
        <div data-test-role="form-group" className="text-xs">
          <CurrencyInput
            name="payoutBaseAmount"
            placeholder="$0.50"
            defaultValue={payoutBaseAmount || 50}
            label="Payout per test case"
            required={true}
            labelClass="font-normal"
          />
          <p className="text-secondary">{formattedTotalPayoutAmount} per tester</p>
        </div>

        <div>
          <p className="font-bold text-sm max-w-sm mt-sm">Country tier multipliers</p>
          <p className="font-normal text-secondary text-sm max-w-sm mt-xs">
            For localised tests only. If applied, tester payouts will be
            <br />
            automatically increased based on their country tier.
          </p>
          <div data-test-role="radio" className=" text-sm mt-xs">
            <label className="inline-flex justify-center items-center mr-3xl cursor-pointer">
              {/* style is used instead of className to override Bootstrap*/}
              <input
                style={radioButtonStyle}
                checked={!applyCountryPayoutMultiplierInput.value}
                type="radio"
                onChange={() => setApplyCountryPayoutMultiplier(false)}
                name="toggleApplyCountryPayoutMultiplier"
                value="false"
                data-testid="toggleApplyCountryPayoutMultiplier-enable"
              />
              <span>Do not apply tier multipliers</span>
            </label>
            <label className="ml-xs inline-flex justify-center items-center cursor-pointer">
              {/* style is used instead of className to override Bootstrap*/}
              <input
                style={radioButtonStyle}
                checked={applyCountryPayoutMultiplierInput.value}
                type="radio"
                onChange={() => setApplyCountryPayoutMultiplier(true)}
                name="toggleApplyCountryPayoutMultiplier"
                value="true"
                data-testid="toggleApplyCountryPayoutMultiplier-disable"
              />
              <span>Apply tier multipliers</span>
            </label>
          </div>
        </div>
      </PageSection>
      <PageSection title="Upload test cases">
        <p className="font-normal text-secondary text-sm max-w-sm">
          If you’re importing test cases from a file, please use our test case
          <br />
          template format. You can also manually enter your test cases
        </p>

        <p className="font-normal text-secondary text-sm max-w-sm mt-sm">
          <a className="text-link" href={additionalInformationDocumentationUrl} target="_blank">
            View example{" "}
          </a>
          of how to specify additional information required
          <br />
          for each test case e.g. Transaction ID
        </p>

        <div data-test-role="form-group" className="mb-md mt-xl text-sm">
          <Checkbox
            name="requiredAttachmentForPass"
            label="Require attachments for passed test cases"
            onChange={() => toggleRequiredAttachmentForPass()}
            isChecked={requiredAttachmentForPassInput.value}
          />
        </div>

        <FileUpload
          licenseKey={CSVBoxConfig.testCasesLicenseKey}
          uploadedTestCases={testCases.value}
          onTestCasesUpload={setTestCases}
          isAdditionalInformationColumnEnabled={true}
          downloadCsvFile={isDownloadCsvFileButtonVisible && downloadCsvFile}
        />
      </PageSection>
    </>
  );
};

export default TestCases;
