import React, { useState } from "react";
import classNames from "classnames";

import { FlexibleTestingTestCaseParams } from "types/graphql";

type PreviewTestCasesProps = {
  testCases: Array<FlexibleTestingTestCaseParams>;
  shortViewCasesLimit: number;
};

function previewTestCasesTitle(testCasesLength: number): string {
  if (testCasesLength === 0) {
    return "No test cases are included";
  } else if (testCasesLength === 1) {
    return "1 test case is included in this test";
  } else {
    return `${testCasesLength} test cases are included in this test`;
  }
}

function PreviewTestCases({ testCases, shortViewCasesLimit }: PreviewTestCasesProps): JSX.Element {
  const [showAllTestCases, setShowAllTestCases] = useState(false);
  const isTestCasesListEmpty = testCases.length === 0;
  const shouldShowMoreSection = testCases.length >= shortViewCasesLimit;
  const visibleTestCases = showAllTestCases ? testCases : testCases.slice(0, shortViewCasesLimit);
  const titleClassNames = classNames([
    "text-dark",
    "text-sm",
    { "pb-xl": testCases.length > 0 },
    { "border-b": !isTestCasesListEmpty },
    { "border-light": !isTestCasesListEmpty },
  ]);
  const title = previewTestCasesTitle(testCases.length);

  function toggleShowAllTestCases() {
    setShowAllTestCases(!showAllTestCases);
  }

  return (
    <div style={{ maxWidth: "24rem" }}>
      <div className={titleClassNames}>{title}</div>
      {visibleTestCases.map((testCase, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="text-sm border-b border-light py-xl" key={index}>
          {testCase.code} {testCase.name}
        </div>
      ))}
      {shouldShowMoreSection && (
        <button className="focus:outline-none pt-xl text-link" onClick={() => toggleShowAllTestCases()}>
          {showAllTestCases ? (
            <>
              Show less
              <i className="fa fa-chevron-up pl-1" />
            </>
          ) : (
            <>
              Show all
              <i className="fa fa-chevron-down pl-1" />
            </>
          )}
        </button>
      )}
    </div>
  );
}

export default PreviewTestCases;
