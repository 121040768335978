import React from "react";
import AdvancedTestForm from "./advanced_test_form";
import { PreviewPageAdvancedTestRequest } from "../../preview_page/preview_page";
import { DesiredOutcomeCode, SoftwareDevelopmentLifeCycleStageName } from "types/graphql";

type AdvancedTestContentProps = {
  testRequest?: PreviewPageAdvancedTestRequest;
  capabilities?: Array<string>;
  desiredOutcomes?: Array<DesiredOutcomeCode>;
  sdlcStage?: SoftwareDevelopmentLifeCycleStageName;
  customDesiredOutcomes?: string;
  infoSection?: JSX.Element;
};

export function AdvancedTestContent({
  testRequest,
  capabilities,
  sdlcStage,
  desiredOutcomes,
  customDesiredOutcomes,
  infoSection,
}: AdvancedTestContentProps): JSX.Element {
  return (
    <AdvancedTestForm
      testRequest={testRequest}
      capabilities={capabilities}
      desiredOutcomes={desiredOutcomes}
      sdlcStage={sdlcStage}
      customDesiredOutcomes={customDesiredOutcomes}
      infoSection={infoSection}
    />
  );
}
