import React from "react";
import { CaseResultEditLog } from "types/graphql";
import { DetailsField } from "components/flexible_testing/shared/details_field";
// @ts-ignore
import { datetimeFormat } from "components/organization/shared_functions/date_format";

type EditRequestLogSectionProps = {
  caseResultEditLogs: Array<CaseResultEditLog>;
};

export function EditRequestLogSection({ caseResultEditLogs }: EditRequestLogSectionProps) {
  return (
    <DetailsField label="Tester edit request log" testId="tester-edit-requests-log" paddingClass="pt-md">
      {caseResultEditLogs.map(caseResultEditLog => (
        <div className="my-sm text-body">
          <div className="font-bold">{datetimeFormat(caseResultEditLog.timestamp)}</div>
          <div className="pt-2xs">{caseResultEditLog.description}</div>
        </div>
      ))}
    </DetailsField>
  );
}
