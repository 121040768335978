import React from "react";
import { SaveChosenNameForm } from "components/flexible_testing/shared/save_chosen_name_form";

function AskForOrganizationName() {
  return (
    <div className="grid grid-cols-4">
      <div className="my-2xl col-span-4 font-bold">
        Before you view your test results, please let us know your company name
      </div>
      <SaveChosenNameForm />
    </div>
  );
}

export default AskForOrganizationName;
