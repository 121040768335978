import { gql, NetworkStatus, useQuery } from "@apollo/client";
import {
  FlexibleTestingCaseFieldConnection,
  FlexibleTestingStepCaseField,
  FlexibleTestingTest,
  FlexibleTestingTestCaseConnection,
  FlexibleTestingTestCaseFieldsParams,
  FlexibleTestingTestConnection,
  FlexibleTestingTestEdge,
} from "types/graphql";
import { TestCase } from "types/test_cases";
import { AdvancedTest, StandardTest } from "components/flexible_testing/shared/test_cloning";
import { useEffect, useState } from "react";
import { formatDistance, parseISO } from "date-fns";
// @ts-ignore
import { datetimeFormat } from "../../../../components/organization/shared_functions/date_format";
import { getPathForCloning } from "../../shared/test_cloning/test_cloning";

export type Test = {
  _id: string;
  id: string;
  date: string;
  applicationUrl: string;
  testCasesUrl?: string;
  isClosed: boolean;
  failedTestCasesCount?: number;
  testCasesTotalCount?: number;
  description: string;
  code: string;
  name: string;
  testDataToClone: StandardTest | AdvancedTest;
  pathForCloning: string;
};

type TestCaseWithStatus = {
  status?: string | null | undefined;
} & TestCase;

type TestsResponse = {
  flexibleTestingTests: FlexibleTestingTestConnection;
};

export const TESTS_QUERY = gql`
  query FlexibleTestingTestCases($cursor: String) {
    flexibleTestingTests(first: 10, after: $cursor) {
      edges {
        node {
          _id
          id
          code
          name
          applicationUrl
          credentialsAndAccessInstructions
          additionalUrlsAndResources
          parentTestId
          type
          requiredAttachmentForPass
          environments {
            platform
          }
          description
          sdlcStage
          desiredOutcomes {
            code
          }
          cases {
            edges {
              node {
                name
                section
                status
                caseFields(filters: { group_type: steps, display_type: Step }) {
                  edges {
                    node {
                      ... on FlexibleTestingStepCaseField {
                        instruction
                        expectedResult
                      }
                    }
                  }
                }
              }
            }
          }
          testWindow {
            openedAt
            closedAt
            plannedEndAt
            status
          }
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

function useTests() {
  const { data, fetchMore, networkStatus } = useQuery<TestsResponse>(TESTS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });
  const [cursor, setCursor] = useState<string>("");

  useEffect(() => {
    setCursor(data?.flexibleTestingTests?.pageInfo.endCursor || "");
  }, [data]);

  const tests = extractTests(data);

  return {
    isLoading: networkStatus === NetworkStatus.loading,
    isLoadingNextPage: networkStatus === NetworkStatus.fetchMore,
    tests,
    hasNextPage: data?.flexibleTestingTests?.pageInfo.hasNextPage,
    cursor: data?.flexibleTestingTests?.pageInfo.endCursor,
    loadNextPage: async () =>
      fetchMore({
        variables: { cursor },
        updateQuery: (previousQueryResult, { fetchMoreResult, variables }): TestsResponse => ({
          flexibleTestingTests: {
            ...fetchMoreResult?.flexibleTestingTests,
            nodes: fetchMoreResult?.flexibleTestingTests.nodes || [],
            edges: previousQueryResult.flexibleTestingTests.edges.concat(
              fetchMoreResult?.flexibleTestingTests.edges || [],
            ),
            pageInfo:
              fetchMoreResult?.flexibleTestingTests.pageInfo || previousQueryResult.flexibleTestingTests.pageInfo,
          },
        }),
      }),
  };
}

function getTestDataToClone(edge: FlexibleTestingTestEdge) {
  return {
    applicationUrl: edge.node.applicationUrl || "",
    name: edge.node.name,
    description: edge.node.description,
    testCases: (extractTestCases(edge.node.cases) || []).map(({ status, ...testCase }) => ({
      ...testCase,
    })),
    credentialsAndAccessInstructions: edge.node.credentialsAndAccessInstructions || "",
    additionalUrlsAndResources: edge.node.additionalUrlsAndResources || "",
    testEnvironments: edge.node.environments?.map(environment => environment.platform as string) || [],
    sdlcStage: edge.node.sdlcStage || undefined,
    desiredOutcomes: edge.node.desiredOutcomes.map(desiredOutcome => desiredOutcome.code),
    requiredAttachmentForPass: edge.node.requiredAttachmentForPass,
  };
}

function extractTests(data?: TestsResponse): Test[] {
  let tests: Test[] = [];

  if (data?.flexibleTestingTests) {
    tests = data.flexibleTestingTests.edges.map(edge => ({
      _id: edge.node._id,
      id: edge.node.id,
      code: edge.node.code || "",
      name: edge.node.name,
      applicationUrl: edge.node.applicationUrl || "",
      testCasesTotalCount: (edge.node.cases.edges || []).length,
      failedTestCasesCount: countFailedTestCases(extractTestCases(edge.node.cases) || []),
      description: edge.node.description,
      parentTestId: edge.node.parentTestId,
      isClosed: edge.node.testWindow?.status === "closed",
      date:
        edge.node.testWindow?.status === "closed"
          ? `${datetimeFormat(edge.node.testWindow?.closedAt, { format: "dd' 'LLL' 'yyyy' 'h':'mm' 'a" })} (UTC)`
          : `${getRemainingTime(edge.node.testWindow?.status || "", edge.node.testWindow?.plannedEndAt)} REMAINING`,
      testDataToClone: getTestDataToClone(edge),
      pathForCloning: getPathForCloning(edge.node as FlexibleTestingTest),
    }));
  }

  return tests;
}

function extractTestCases(cases: FlexibleTestingTestCaseConnection): TestCaseWithStatus[] {
  return cases.edges.map(edge => {
    const { caseFields, ...node } = edge.node;

    return {
      fields: extractFields(caseFields),
      ...node,
    };
  });
}

function countFailedTestCases(testCases: TestCaseWithStatus[]) {
  return testCases.reduce((count: number, testCase) => (testCase.status === "fail" ? count + 1 : count), 0);
}

function extractFields(fields: FlexibleTestingCaseFieldConnection): FlexibleTestingTestCaseFieldsParams[] {
  return (
    fields?.edges
      ?.map(edge => edge?.node)
      ?.filter((caseField): caseField is FlexibleTestingStepCaseField => !!caseField)
      ?.map(node => ({
        instruction: node?.instruction || "",
        expectedResult: node?.expectedResult || "",
      })) || []
  );
}

function getRemainingTime(state: string, plannedEndAt?: string) {
  if (state === "open") {
    return formatDistance(parseISO(plannedEndAt!), new Date());
  }

  return "";
}

export { useTests };
