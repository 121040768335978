import { gql, useMutation } from "@apollo/client";
import { format } from "date-fns";

import { LaunchAdvancedTestInput, LaunchAdvancedTestPayload } from "types/graphql";
import { PreviewPageAdvancedTestRequest } from "../preview_page";

export const TEST_REQUEST_MUTATION = gql`
  mutation LaunchAdvancedTest($input: LaunchAdvancedTestInput!) {
    launchAdvancedTest(input: $input) {
      successful
      result {
        url
      }
      errors {
        code
        message
      }
    }
  }
`;

type FormSubmitHook = {
  launchTest: (testRequest: PreviewPageAdvancedTestRequest) => Promise<void>;
  testResultsUrl: string;
  isLoading: boolean;
  wasSuccessfullyRequested: boolean;
};

function useLaunchAdvancedTest(): FormSubmitHook {
  const [launchTest, { data, loading, called, error }] = useMutation<
    { launchAdvancedTest: LaunchAdvancedTestPayload },
    { input: LaunchAdvancedTestInput }
  >(TEST_REQUEST_MUTATION);

  return {
    launchTest: async (testRequest): Promise<void> => {
      const {
        accessToApplication,
        testObjective,
        notes,
        name,
        platformsToTestOn,
        deviceRequirements,
        testLocalisation,
        testCasesUrl,
        knownIssuesUrl,
        scheduleAt,
        capabilities,
        sdlcStage,
        desiredOutcomes,
        customDesiredOutcomes,
        testCases,
      } = testRequest;
      const convertedScheduleAt = scheduleAt && format(new Date(scheduleAt), "yyyy'-'LL'-'dd'T'HH':'mm':'ssxx");

      await launchTest({
        variables: {
          input: {
            advancedTestRequestParams: {
              accessToApplication,
              testObjective,
              notes,
              name,
              platformsToTestOn,
              deviceRequirements,
              testLocalisation,
              testCasesUrl,
              knownIssuesUrl,
              scheduleAt: convertedScheduleAt,
              capabilities,
              sdlcStage,
              desiredOutcomes,
              customDesiredOutcomes,
              testCases,
            },
          },
        },
      });
    },
    testResultsUrl: data?.launchAdvancedTest?.result?.url ?? "",
    isLoading: loading,
    wasSuccessfullyRequested: called && !error,
  };
}

export default useLaunchAdvancedTest;
