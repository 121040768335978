import React from "react";
import pluralize from "pluralize";
import { Heading } from "@hopper/heading";

import { StepSummaryHeading } from "./step_summary_heading";
import {
  ACTIVITIES_REQUIRING_SCOPE,
  ACTIVITIES_REQUIRING_TEST_CASE_FIELDS,
} from "components/flexible_testing/test_request_page/requested_test_page/scope_step_content";
import { Activity } from "components/flexible_testing/test_request_page/requested_test_page/activity_step_content";
import { TextTruncate } from "components/shared/text_truncate";

type ScopeStepSummaryProps = {
  activity: Activity;
  testCasesLength: number;
  numberOfResultsPerTestCaseNeeded: string;
  inScope: string;
  outOfScope: string;
};

export const ScopeStepSummary = ({
  activity,
  testCasesLength,
  numberOfResultsPerTestCaseNeeded,
  inScope,
  outOfScope,
}: ScopeStepSummaryProps) => {
  const subheading = ACTIVITIES_REQUIRING_TEST_CASE_FIELDS.includes(activity)
    ? `${testCasesLength} ${pluralize("test case", testCasesLength)}`
    : undefined;

  return (
    <div data-testid="scope-step-summary">
      <StepSummaryHeading heading="Scope" subheading={subheading} />
      {ACTIVITIES_REQUIRING_TEST_CASE_FIELDS.includes(activity) && (
        <div className="text-secondary text-sm">
          {numberOfResultsPerTestCaseNeeded} {pluralize("result", Number(numberOfResultsPerTestCaseNeeded))} will be
          reported by each group for each test case
        </div>
      )}
      {ACTIVITIES_REQUIRING_SCOPE.includes(activity) && (
        <div className="flex gap-4xl">
          <div style={{ width: "22.5rem" }}>
            <Heading size={5}>In scope</Heading>
            <div className="text-secondary text-sm">
              <TextTruncate text={inScope} maxLength={400} />
            </div>
          </div>
          <div style={{ width: "22.5rem" }}>
            <Heading size={5}>Out of scope</Heading>
            <div className="text-secondary text-sm">
              <TextTruncate text={outOfScope} maxLength={400} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
