import React, { useRef } from "react";
import { PopupMenu } from "@hopper/popup_menu";
import { Button } from "@hopper/button";
import Popup from "components/shared/popup";

import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { useTestCaseStatusAfterUndoingRejection } from "./use_test_case_status_after_undoing_rejection";

type UndoTestCaseResultRejectionPopupProps = {
  isOpen: boolean;
  onExtenalElementClick: () => void;
  onUndoButtonClick: () => void;
  onLabelClick: () => void;
  caseResultId: string;
  children: React.ReactNode;
};

type UndoTestCaseResultRejectionPopupContentProps = {
  caseResultId: string;
  onUndoButtonClick: () => void;
};

type statusNameType = { [key: string]: string };

const statusName: statusNameType = {
  pass: "Passed",
  fail: "Failed",
  blocked: "Blocked",
  rejected: "Rejected",
};

const UndoTestCaseResultRejectionPopupContent = ({
  caseResultId,
  onUndoButtonClick,
}: UndoTestCaseResultRejectionPopupContentProps) => {
  const { willTestCaseStatusChangeAfterUndoingRejection, caseStatusAfterUndoingRejection, isLoading } =
    useTestCaseStatusAfterUndoingRejection(caseResultId);

  return (
    <div data-testid="undo-case-result-rejection-popup">
      <PopupMenu>
        <div className="text-secondary text-sm mx-2xl mb-lg">
          <div className="font-bold my-sm py-sm text-md border-b border-light">Undo rejection</div>
          <div className="mb-sm">You rejected this tester's result</div>
          {willTestCaseStatusChangeAfterUndoingRejection && (
            <div data-testid="new-case-status-notification">
              Undoing will change the overall
              <br />
              test case result to
              <span className="font-bold ml-xs">{statusName[caseStatusAfterUndoingRejection]}</span>
            </div>
          )}
          <div className="mt-xl">
            <Button data-testid="undo-case-result-rejection-button" onClick={onUndoButtonClick} disabled={isLoading}>
              Undo
            </Button>
          </div>
        </div>
      </PopupMenu>
    </div>
  );
};

const UndoTestCaseResultRejectionPopup = ({
  isOpen,
  onExtenalElementClick,
  onUndoButtonClick,
  onLabelClick,
  caseResultId,
  children,
}: UndoTestCaseResultRejectionPopupProps) => {
  const wrapperRef = useRef(null);

  useOutsideHandler(wrapperRef, onExtenalElementClick);

  const undoCaseResultRejectionPopupContent = UndoTestCaseResultRejectionPopupContent({
    caseResultId,
    onUndoButtonClick,
  });

  return (
    <div ref={wrapperRef} className="h-4xl pt-sm">
      <Popup popupElement={undoCaseResultRejectionPopupContent} isOpen={isOpen}>
        <div className="cursor-pointer" data-testid="undo-case-result-rejection-label" onClick={onLabelClick}>
          {children}
        </div>
      </Popup>
    </div>
  );
};

export default UndoTestCaseResultRejectionPopup;
