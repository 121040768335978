import React from "react";
import { Heading } from "@hopper/heading";
import { Link } from "react-router-dom";
import { OrganizationStats } from "types/graphql";
import pluralize from "pluralize";
import { Button } from "@hopper/button";

// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import TestCasesSection from "./test_cases_section";
import { IssuesSummary } from "../shared/issues_summary";

type TestStatsContentProps = {
  organizationStats: OrganizationStats;
};

function TestStatsContent({ organizationStats }: TestStatsContentProps) {
  const { testsCount, participantsTotalCount } = organizationStats;
  const tests = pluralize("test", testsCount);
  const testsHave = pluralize("have", testsCount);
  const participants = pluralize("tester", participantsTotalCount);
  const participantsHave = pluralize("have", participantsTotalCount);

  return (
    <div>
      <p>In the past 90 days</p>

      <div className="mt-4xl">
        <Heading size={2}>
          {testsCount} {tests} {testsHave} been run
        </Heading>
        <Heading size={2}>
          {participantsTotalCount} {participants} {participantsHave} participated in your tests
        </Heading>
      </div>

      {organizationStats.testCasesTotalCount > 0 && <TestCasesSection organizationStats={organizationStats} />}
      {organizationStats.issuesTotalCount > 0 && <IssuesSummary issuesStats={organizationStats} />}

      <div className="mt-4xl">
        <Link to={createNavigationUrl("flexcaseLaunch")}>
          <Button>Launch a new test</Button>
        </Link>
      </div>
    </div>
  );
}

export default TestStatsContent;
