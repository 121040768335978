import React from "react";
import { Heading } from "@hopper/heading";

import { Country, Language, MobileDevice, InternetBrowser, OperatingSystemDevice } from "types/graphql";
import {
  TARGETING_FIELDS_ORDER,
  TargetingGroup,
  OperatingSystemVersion,
} from "components/flexible_testing/test_request_page/requested_test_page/targeting_step_content";
import { Activity } from "components/flexible_testing/test_request_page/requested_test_page/activity_step_content";
import { RequestedTest } from "components/flexible_testing/test_request_page/requested_test_page/requested_test_form";
import { StepSummaryHeading } from "./step_summary_heading";

const DEVICE_LABELS = {
  android: "Android",
  ios: "iOS",
  web: null,
} as const;

const OPERATING_SYSTEM_LABELS = {
  android: "Android",
  ios: "iOS",
  web: "operating system",
} as const;

type TargetingStepSummaryProps = {
  activity: Activity;
  applicationType: RequestedTest["applicationType"];
  targetingGroups: TargetingGroup[];
  customTargetingRequirements: RequestedTest["customTargetingRequirements"];
};

type TargetingGroupFieldItemsProps = {
  items: Array<MobileDevice | OperatingSystemVersion | Country | Language | InternetBrowser | OperatingSystemDevice>;
  emptyLabel: string;
  nonEmptyLabel: string;
};

const TargetingGroupFieldItems = ({ items, emptyLabel, nonEmptyLabel }: TargetingGroupFieldItemsProps) => (
  <div>
    {items.length === 0 && <>{emptyLabel}</>}
    {items.length > 0 && (
      <>
        {nonEmptyLabel}:
        <ul className="list-disc list-inside mt-lg ml-sm">
          {items.map(({ name }) => (
            <li key={name}>{name}</li>
          ))}
        </ul>
      </>
    )}
  </div>
);

export const TargetingStepSummary = ({
  applicationType,
  activity,
  targetingGroups,
  customTargetingRequirements,
}: TargetingStepSummaryProps) => {
  const deviceLabel = DEVICE_LABELS[applicationType];
  const operatingSystemLabel = OPERATING_SYSTEM_LABELS[applicationType];
  const fieldOrder = TARGETING_FIELDS_ORDER[applicationType][activity];

  const fieldItemsPropsMapping = {
    devices: (group: TargetingGroup) => ({
      items: group.devices,
      emptyLabel: `Testers that have any${deviceLabel ? ` ${deviceLabel}` : ""} device`,
      nonEmptyLabel: `Testers that have any of the following${deviceLabel ? ` ${deviceLabel}` : ""} devices`,
    }),
    operatingSystemVersions: (group: TargetingGroup) => ({
      items: group.operatingSystemVersions,
      emptyLabel: `Testers that have any ${operatingSystemLabel} version`,
      nonEmptyLabel: `Testers that have any of the following ${operatingSystemLabel} versions`,
    }),
    countries: (group: TargetingGroup) => ({
      items: group.countries,
      emptyLabel: "Testers in any country",
      nonEmptyLabel: "Testers in any of the following countries",
    }),
    languages: (group: TargetingGroup) => ({
      items: group.languages,
      emptyLabel: "Testers who speak any language",
      nonEmptyLabel: "Testers who speak any of the following languages",
    }),
    operatingSystemDeviceTypes: (group: TargetingGroup) => ({
      items: group.operatingSystemDeviceTypes,
      emptyLabel: "Testers that have any device type",
      nonEmptyLabel: "Testers that have any of the following device types",
    }),
    internetBrowsers: (group: TargetingGroup) => ({
      items: group.internetBrowsers,
      emptyLabel: "Testers that have any browser",
      nonEmptyLabel: "Testers that have any of the following browsers",
    }),
  };

  return (
    <div>
      <StepSummaryHeading heading="Targeting" />
      <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4xl">
        {customTargetingRequirements ? (
          <div data-testid="custom-requirements-summary">
            <Heading size={5}>Custom requirements</Heading>
            <div>{customTargetingRequirements}</div>
          </div>
        ) : (
          <>
            {targetingGroups.map((group, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} data-testid="targeting-group-summary">
                <Heading size={5}>Group #{index + 1}</Heading>
                <div className="flex flex-col gap-md text-secondary text-sm">
                  {fieldOrder.map(field => (
                    <TargetingGroupFieldItems key={field} {...fieldItemsPropsMapping[field](group)} />
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
