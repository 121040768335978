import React, { useState } from "react";
import { Form } from "components/flexible_testing/shared/form";
import * as yup from "yup";
import TagFormFields from "./tag_form_fields";
import { useUpdateIssueTag } from "../use_update_issue_tag";
import { useDestroyIssueTag } from "../use_destroy_issue_tag";
import { FlexibleTestingIssueTag } from "types/graphql";
import { Button } from "@hopper/button";
import useTagUsageCount from "components/flexible_testing/shared/tag_cell/options_box/use_tag_usage_count";
import { useRouteMatch } from "react-router-dom";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

type TagFormProps = {
  tag: FlexibleTestingIssueTag;
  onClose: () => void;
};

type RouteParams = {
  testId?: string;
};

const TagForm = ({ onClose, tag }: TagFormProps) => {
  const match = useRouteMatch<RouteParams>("/results/:testId");
  const testId = match?.params.testId;
  const { updateIssueTag } = useUpdateIssueTag(testId);
  const { destroyIssueTag } = useDestroyIssueTag(testId);
  const { usageCount, isLoading } = useTagUsageCount(tag.id);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false);

  const destroyTag = (issueTagId: string) => {
    if (usageCount === 0 || displayDeleteConfirmation) {
      destroyIssueTag(issueTagId);
      notifyIssueTagWasDeleted();
      onClose();
    } else {
      setDisplayDeleteConfirmation(true);
    }
  };

  const notifyIssueTagWasDeleted = () => {
    notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
      type: "success",
      message: "Your tag has been deleted",
    });
  };

  return (
    <div className="px-xl mt-sm py-sm">
      <Form
        initialValues={{
          issueTagName: tag.name,
        }}
        validations={yup.object({
          issueTagName: yup.string().max(30, "Tag name cannot be more than 30 characters"),
        })}
        onSubmit={async (values: { [field: string]: unknown }) => {
          const results = await updateIssueTag(tag.id as string, values.issueTagName as string);

          onClose();

          return results;
        }}
        className="px-lg sm:px-0 mb-md"
      >
        <TagFormFields
          tagName={tag.name}
          usageCount={usageCount}
          isLoading={isLoading}
          displayDeleteConfirmation={displayDeleteConfirmation}
        />
        <Button
          type="button"
          className="mt-xl mr-md"
          variant="danger"
          appearance={displayDeleteConfirmation ? "default" : "ghost"}
          onClick={() => destroyTag(tag.id)}
          data-testid="delete-issue-tag"
        >
          Delete
        </Button>
        <Button className="mt-xl" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default TagForm;
