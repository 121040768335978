import React, { useEffect } from "react";
import { useField } from "formik";
import classnames from "classnames";

import { TestCase } from "types/test_cases";
import { FileUpload } from "components/flexible_testing/shared/test_cases/file_upload";
import { Section } from "components/flexible_testing/shared/section";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { StatusCard } from "@hopper/status_card";
import { CSVBoxConfig } from "components/configuration";

const TestCases = (): JSX.Element => {
  const [includeTestCases, _includeTestCasesMeta, { setValue: setIncludeTestCases }] =
    useField<boolean>("includeTestCases");
  const [testCasesFile, _testCasesFileMeta, { setValue: setTestCasesFile }] = useField<File | null>("testCasesFile");
  const [testCases, _testCasesMeta, { setValue: setTestCases }] = useField<Array<TestCase> | null>("testCases");

  useEffect(() => {
    if (!includeTestCases.value && testCasesFile.value !== null) {
      setTestCasesFile(null);
    }
    if (!includeTestCases.value && testCases.value !== null) {
      setTestCases(null);
    }
  }, [includeTestCases.value]);

  const radioButtonStyle = { marginTop: 0, marginRight: "0.5rem", accentColor: "#373A40" };
  const testCasesClassNames = classnames([{ hidden: !includeTestCases.value }]);

  return (
    <div className="mb-2xl">
      <PageSection title="Does this test run include test cases?">
        <div data-test-role="radio" className="text-xs">
          <label className="inline-flex justify-center items-center mr-3xl cursor-pointer">
            {/* style is used instead of className to override Bootstrap*/}
            <input
              style={radioButtonStyle}
              checked={includeTestCases.value}
              type="radio"
              onChange={() => setIncludeTestCases(true)}
              name="include_test_cases"
              value="true"
              data-testid="include-test-cases"
            />
            Yes
          </label>

          <label className="ml-xs inline-flex justify-center items-center cursor-pointer">
            {/* style is used instead of className to override Bootstrap*/}
            <input
              style={radioButtonStyle}
              checked={!includeTestCases.value}
              type="radio"
              onChange={() => setIncludeTestCases(false)}
              name="include_test_cases"
              value="false"
            />
            <span>No</span>
          </label>
        </div>
      </PageSection>
      <Section title="Add your test cases" className={testCasesClassNames}>
        <div className="mb-sm bg-light text-sm" style={{ width: 400 }}>
          <StatusCard statusSize="sm" isRounded={true}>
            To make importing test cases as easy as possible, export them as a <b>.CSV</b>
          </StatusCard>
        </div>
        <div className="mb-sm bg-light text-sm" style={{ width: 400 }}>
          <StatusCard statusSize="sm" isRounded={true} className="p-sm">
            Need more information from testers? You can request additional information per test case, such as a
            Transaction ID.
            <br />
            {/*The link will be added, once the info page is ready*/}
            <a href="/" target="_blank" className="text-link">
              Learn more
            </a>
          </StatusCard>
        </div>
        <FileUpload
          licenseKey={CSVBoxConfig.testCasesLicenseKey}
          uploadedTestCases={testCases.value}
          onTestCasesUpload={setTestCases}
          isAdditionalInformationColumnEnabled={true}
        />
      </Section>
    </div>
  );
};

export default TestCases;
