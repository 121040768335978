import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonProps } from "react-bootstrap";
import { Button as HopperButton } from "@hopper/button";
import { useFormikContext } from "formik";
import { intersection } from "lodash";

type SubmitButtonProps = {
  useHopperButton?: boolean;
  loading?: boolean;
};

type ButtonLibProps = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;

function SubmitButton({
  children,
  disabled,
  loading,
  useHopperButton,
  ...props
}: SubmitButtonProps & ButtonLibProps): JSX.Element {
  const { isSubmitting, errors, touched } = useFormikContext();
  const isAnyTouchedFieldInvalid = intersection(Object.keys(touched), Object.keys(errors)).length > 0;
  const isButtonDisabled = disabled || isSubmitting || isAnyTouchedFieldInvalid;

  return (
    <>
      {useHopperButton ? (
        <HopperButton
          type="submit"
          disabled={isButtonDisabled}
          {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        >
          {loading || isSubmitting ? (
            <i data-testid="spinning-circle-submit-button" className="fa fa-circle-o-notch fa-spin mr-xs" />
          ) : (
            ""
          )}
          {children}
        </HopperButton>
      ) : (
        <Button type="submit" variant="primary" disabled={isButtonDisabled} {...props}>
          {loading || isSubmitting ? (
            <i data-testid="spinning-circle-submit-button" className="fa fa-circle-o-notch fa-spin mr-xs" />
          ) : (
            ""
          )}
          {children}
        </Button>
      )}
    </>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SubmitButton;
