import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useQuery } from "@apollo/client";
import { Button } from "@hopper/button";
import { useHistory } from "react-router-dom";

// @ts-ignore
import { TextInput } from "../../shared/form";
// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import { JiraConnectClientAuthentication } from "types/graphql";
import { GeneralError, Form, SubmitButton, Switch } from "components/shared/form";
import { ClientAuthenticationProps } from "../integration_page";
import JiraConnectExportFields from "components/shared/results_export/jira_connect_export_form/jira_connect_export_fields";
import { GET_INTEGRATION_EXPORT_ATTRIBUTES } from "components/shared/integration/integration_queries";
import { useIntegrationUpdate } from "../use_integration_update";
import jira from "assets/images/integrations/jira.svg";

export function JiraConnectForm({ integration }: ClientAuthenticationProps) {
  const clientAuthentication = integration.clientAuthentication as JiraConnectClientAuthentication;
  const { siteUrl, projectKey, issuetypeName, labels, useDefaultExportSettings, priorityMappings } =
    clientAuthentication || {};
  const [areExportSettingsEditable, setExportSettingsEditable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const integrationId = integration?.id;
  const { data, loading } = useQuery(GET_INTEGRATION_EXPORT_ATTRIBUTES, { variables: { id: integrationId } });
  const projects = data?.integration?.runsExportAttributes?.projects || [];
  const history = useHistory();

  const { updateIntegration } = useIntegrationUpdate();

  useEffect(() => {
    if (!loading && useDefaultExportSettings) {
      setExportSettingsEditable(true);
    }
  }, [loading]);

  type PriorityMapping = {
    critical: string;
    high: string;
    medium: string;
    low: string;
  };

  type JiraConnectAuthenticationParams = {
    use_default_export_settings: boolean;
    default_export_settings?: {
      issuetype_name: string;
      labels: string;
      project_key: string;
    };
    priority_mappings: PriorityMapping;
  };

  const handleSubmit = (values: { [field: string]: unknown }) => {
    setIsSubmitting(true);

    const { critical, high, medium, low } = values.priority_mappings as PriorityMapping;

    let jiraConnectAuthenticationParams: JiraConnectAuthenticationParams = {
      use_default_export_settings: values.use_default_export_settings as boolean,
      priority_mappings: { critical, high, medium, low },
    };

    if (values.use_default_export_settings) {
      jiraConnectAuthenticationParams = {
        ...jiraConnectAuthenticationParams,
        default_export_settings: {
          issuetype_name: values.issuetype_name as string,
          labels: values.labels as string,
          project_key: values.project_key as string,
        },
      };
    }

    const params = {
      code: "jira_connect",
      jiraConnectAuthenticationParams,
    };

    return updateIntegration(params).finally(() => {
      setIsSubmitting(false);
    });
  };

  const handleCancel = () => history.push(createNavigationUrl("integrationSettings"));

  const handleSwitchToggle = (value: boolean): void => setExportSettingsEditable(value);

  return (
    <>
      {siteUrl ? (
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            site_url: siteUrl,
            use_default_export_settings: useDefaultExportSettings,
            default_export_settings: {
              project_key: projectKey,
              issuetype_name: issuetypeName,
              labels,
            },
            priority_mappings: priorityMappings,
          }}
          validations={() =>
            yup.lazy(values => {
              const priorityMappingsValidations = yup
                .object({
                  critical: yup.string().required("Severity can't be blank").nullable(),
                  high: yup.string().required("Severity can't be blank").nullable(),
                  medium: yup.string().required("Severity can't be blank").nullable(),
                  low: yup.string().required("Severity can't be blank").nullable(),
                })
                .required();

              if (values.use_default_export_settings) {
                return yup
                  .object({
                    project_key: yup.string().required("Project can't be blank").nullable(),
                    issuetype_name: yup.string().required("Issue Type can't be blank").nullable(),
                    labels: yup.string().nullable(),
                    priority_mappings: priorityMappingsValidations,
                  })
                  .required();
              } else {
                return yup.object({ priority_mappings: priorityMappingsValidations });
              }
            })
          }
          className="mb-3xl max-w-sm"
        >
          <GeneralError />

          <div className="mb-2xl font-bold">Integration settings</div>

          <div className="mb-2xl flex justify-between" data-test-role="form-group">
            <TextInput
              id="site_url"
              name="site_url"
              label=""
              className="form-control"
              labelClass="text-sm font-normal"
              data-testid="site-url-input"
              disabled
            />
          </div>

          <div className="mb-sm flex justify-between">Custom priority mappings</div>

          <div className="mb-2xl text-sm flex justify-between">
            You can modify the mapping of GAT's issue severities to custom priorities in your Jira instance. If you use
            default Jira priorities, you don't need to change this.
          </div>

          <div className="mb-2xl" data-test-role="form-group">
            <TextInput
              id="critical"
              name="priority_mappings.critical"
              label="Critical"
              className="form-control"
              labelClass="text-sm font-normal"
              placeholder="Highest"
              data-testid="custom-priority-mappings-critical-input"
            />
          </div>
          <div className="mb-2xl flex justify-between" data-test-role="form-group">
            <TextInput
              id="high"
              name="priority_mappings.high"
              label="High"
              className="form-control"
              placeholder="High"
              labelClass="text-sm font-normal"
              data-testid="custom-priority-mappings-high-input"
            />
          </div>
          <div className="mb-2xl flex justify-between" data-test-role="form-group">
            <TextInput
              id="medium"
              name="priority_mappings.medium"
              label="Medium"
              className="form-control"
              placeholder="Medium"
              labelClass="text-sm font-normal"
              data-testid="custom-priority-mappings-medium-input"
            />
          </div>
          <div className="mb-2xl flex justify-between" data-test-role="form-group">
            <TextInput
              id="low"
              name="priority_mappings.low"
              label="Low"
              className="form-control"
              placeholder="Low"
              labelClass="text-sm font-normal"
              data-testid="custom-priority-mappings-low-input"
            />
          </div>

          <div className="mb-2xl font-bold">Default export settings</div>

          <div className="mb-2xl flex justify-between" data-test-role="form-group">
            <Switch
              id="use_default_export_settings"
              name="use_default_export_settings"
              offLabel="Use default settings"
              onLabel="Default settings enabled"
              className="form-control hover:cursor-not-allowed"
              onToggle={handleSwitchToggle}
            />
          </div>

          <div className="mb-4xl">
            {areExportSettingsEditable && (
              <JiraConnectExportFields
                projects={projects}
                integrationId={integrationId}
                projectKey={projectKey || ""}
                issuetypeName={issuetypeName || ""}
                labelClass="text-sm font-normal"
              />
            )}
          </div>

          <div className="mb-4xl">
            <SubmitButton useHopperButton={true} className="mr-lg" loading={isSubmitting}>
              Save
            </SubmitButton>
            <Button variant="secondary" type="reset" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      ) : (
        <>
          <div className="mb-2xl font-bold">Installation</div>

          <div>
            <p className="mb-lg max-w-sm">
              With our Jira integration, you can run tests right from your Jira issues and receive detailed bug reports
              in the same place.
            </p>
            <p className="mb-3xl max-w-sm">
              Install <strong>Global App Testing for Jira</strong> and follow the instructions to set up the
              integration.
            </p>
            <Button
              variant="secondary"
              appearance="ghost"
              href="https://marketplace.atlassian.com/1224898"
              target="_blank"
            >
              <img src={jira} className="mr-sm h-xl" />
              Install Global App Testing for Jira
            </Button>
          </div>
        </>
      )}
    </>
  );
}
