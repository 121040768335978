import { gql, useMutation } from "@apollo/client";
import { Test } from "types/graphql";

const DISABLE_AUTOMATIC_TESTER_MANAGEMENT = gql`
  mutation ($input: DisableAutomaticTesterManagementInput!) {
    disableAutomaticTesterManagement(input: $input) {
      result {
        id
        automaticTesterManagementEnabled
        canModifyNumberOfResultsNeeded
      }
      successful
      errors {
        code
        message
      }
    }
  }
`;

export const useDisableAutomaticTesterManagement = () => {
  const [disableAutomaticTesterManagementMutation] = useMutation(DISABLE_AUTOMATIC_TESTER_MANAGEMENT);

  return [
    async (testId: Test["id"], reason: string) => {
      const mutation = await disableAutomaticTesterManagementMutation({
        variables: {
          input: {
            testId,
            reason,
          },
        },
      });

      return mutation.data?.disableAutomaticTesterManagement;
    },
  ] as const;
};
