import { useTracking } from "react-tracking";
import React from "react";
import { Link } from "react-router-dom";

import { trackCloning } from "components/flexible_testing/shared/track_cloning";
import { useCurrentUser } from "app/current_user/use_current_user";

export type CloneLinkProps = {
  testTrackingId: string;
  testId: string;
  pathForCloning: string;
  trackingEventName: string;
  children: React.ReactNode;
};

export function CloneLink({ testId, testTrackingId, pathForCloning, trackingEventName, children }: CloneLinkProps) {
  const currentUser = useCurrentUser();
  const tracking = useTracking();
  const [pathname, search] = pathForCloning.split("?");

  return (
    <Link
      onClick={() => trackCloning(tracking, testTrackingId, currentUser?.organization?.publicId, trackingEventName)}
      to={{
        pathname,
        state: { testId },
        search,
      }}
    >
      {children}
    </Link>
  );
}
