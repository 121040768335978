import React, { useState } from "react";
import { useParams } from "react-router";
import { Heading } from "@hopper/heading";
import { Loader } from "@hopper/loading";
import useTestCaseResult from "components/flexible_testing/test_case_result_page/use_test_case_result";
import TestCaseHeader from "./test_case_header";
import { websiteContentPaths } from "components/configuration";
import { BackToPreviousPageLink } from "../shared/back_to_previous_page";
import { PageTemplate } from "../shared/page_template";
import { TesterTabs } from "./tester_tabs";
import { CaseWithoutResults } from "./case_without_results";
import { Overview } from "./overview";
import { statuses } from "components/flexible_testing/shared/status_indicator";

type Params = {
  id: string;
  testId: string;
};

function TestCaseResultPage(): JSX.Element {
  const { id, testId } = useParams<Params>();
  const { testCase, isLoading } = useTestCaseResult(id);
  const caseResults = testCase?.caseResults || [];
  const caseFields = testCase?.fields || {};
  const caseResultsCount = caseResults?.length;
  const [isPageFirstTimeLoading, setIsPageFirstTimeLoading] = useState<boolean>(true);

  function testCaseTitle(): JSX.Element {
    return (
      <TestCaseHeader testId={testId}>
        {testCase?.code} {testCase?.name}
      </TestCaseHeader>
    );
  }

  function header(): JSX.Element {
    return (
      <div className="flex justify-between mr-2xl">
        <Heading size={2}>{testCaseTitle()}</Heading>
        <div>
          {testCase.testWindow && (
            <Overview
              caseStatus={testCase.status as typeof statuses[number]}
              caseResults={caseResults}
              isTestCaseLive={testCase.isTestCaseLive}
              testWindow={testCase.testWindow}
            />
          )}
        </div>
      </div>
    );
  }

  if (isPageFirstTimeLoading && !isLoading) {
    setIsPageFirstTimeLoading(false);
  }

  return (
    <div data-testid="test-case-details-page">
      {isPageFirstTimeLoading && <Loader />}
      {!isPageFirstTimeLoading && (
        <PageTemplate
          backButton={
            <BackToPreviousPageLink
              to={websiteContentPaths.flexcaseTestCaseResults.replace(":id", testId).concat(`#${id}`)}
            >
              Back to Test Run Results
            </BackToPreviousPageLink>
          }
          header={header()}
        >
          {caseResultsCount === 0 ? (
            <CaseWithoutResults
              fields={caseFields}
              testWindowClosedAt={testCase.testWindow?.closedAt}
              prerequisites={testCase.prerequisites}
            />
          ) : (
            <TesterTabs prerequisites={testCase.prerequisites} caseResults={caseResults} caseFields={caseFields} />
          )}
        </PageTemplate>
      )}
    </div>
  );
}

export default TestCaseResultPage;
