import React from "react";
import { Test } from "types/graphql";
import pluralize from "pluralize";
import classnames from "classnames";
import { countBy } from "lodash";

import { sortByStatus } from "components/flexible_testing/shared/sort_by_status";
import CasesStatsLabel from "components/flexible_testing/shared/cases_stats/cases_stats_label/cases_stats_label";
import CasesStatsStatus from "components/flexible_testing/shared/cases_stats/cases_stats_status/cases_stats_status";
import { StatusColor } from "../../result_details_page/test_cases/test_cases_list_grouped_by_section/test_cases_group_status/test_cases_group_status";

type CasesStatsProps = {
  test: Test;
  inProgress: boolean | undefined;
  fontSize?: "sm" | "md";
};

type StatusVariants = {
  [key in string]: {
    message: (count: number) => string;
    color: StatusColor;
  };
};

const CasesStats = ({ test, inProgress, fontSize }: CasesStatsProps): JSX.Element => {
  const DISPLAYED_CASE_STATS_LIMIT = 3;
  const DEFAULT_FONT_SIZE = "sm";
  const FONT_SIZE_STYLES = {
    sm: "text-sm",
    md: "text-md",
  };
  const fontStyle = () => classnames("text-body", FONT_SIZE_STYLES[fontSize || DEFAULT_FONT_SIZE]);
  const casesCount = test.cases.edges.length;
  const isTestInProgress = inProgress && test.cases.edges.some(edge => edge.node.isTestCaseLive);

  const statuses = test.cases.edges.map(edge => edge.node.status);
  const sortedStatuses = [...statuses].sort(sortByStatus);
  const casesCountByStatus = countBy(sortedStatuses);

  const statusVariants: StatusVariants = {
    live: { message: count => `${count} ${pluralize("test case", count)}`, color: "primary" },
    fail: { message: count => `${count} failed`, color: "danger" },
    blocked: { message: count => `${count} blocked`, color: "warning" },
    pass: { message: count => `${count} passed`, color: "success" },
    not_tested: { message: count => `${count} not tested`, color: "secondary" },
  };

  const ellipsis = (fontStyle: string) => (
    <div className="flex leading-100" data-testid="case-stats-ellipsis">
      <span className={fontStyle}>...</span>
    </div>
  );

  const noTestCasesMessage = () => (
    <div className="flex">
      <CasesStatsLabel text="No test cases" />
    </div>
  );

  const caseStatsForLiveTest = () => (
    <CasesStatsStatus
      fontStyle={fontStyle()}
      color={statusVariants["live"]?.color}
      message={statusVariants["live"]?.message(casesCount)}
    />
  );

  const caseStatsForFinishedTest = () => (
    <div className="flex px-0">
      <CasesStatsLabel text="Test cases" withDivider={true} />
      <div className="flex gap-lg">
        {Object.keys(casesCountByStatus)
          .slice(0, DISPLAYED_CASE_STATS_LIMIT)
          .map(status => {
            const statusCasesCount = casesCountByStatus[status] || 0;

            return (
              <CasesStatsStatus
                key={status}
                fontStyle={fontStyle()}
                color={statusVariants[status]?.color}
                message={statusVariants[status]?.message(statusCasesCount)}
              />
            );
          })}
        {Object.keys(casesCountByStatus).length > DISPLAYED_CASE_STATS_LIMIT && ellipsis(fontStyle())}
      </div>
    </div>
  );

  return (
    <>
      {casesCount === 0 && noTestCasesMessage()}
      {casesCount > 0 && isTestInProgress && caseStatsForLiveTest()}
      {casesCount > 0 && !isTestInProgress && caseStatsForFinishedTest()}
    </>
  );
};

export default CasesStats;
