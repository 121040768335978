import React from "react";
import { gql, useQuery } from "@apollo/client";
import { isEmpty } from "lodash";

import { Card } from "@hopper/card";
import { Heading } from "@hopper/heading";
import { StatusLabel } from "@hopper/status_label";

import { FlexibleTestingAutoExportDestinationOption, FlexibleTestingAutoExportSettings } from "types/graphql";
import { PageTemplate } from "../shared/page_template";
import AutoExportSettingsForm from "./auto_export_settings_form";
import AutoExportToggleButton from "./auto_export_toggle_button";
import OrganizationWithoutIntegrationSetup from "./organization_without_integration_setup";
import { Loader } from "@hopper/loading";

export const AUTO_EXPORT_SETTINGS = gql`
  query getFlexibleTestingAutoExportSettings {
    flexibleTestingAutoExportSettings {
      id
      enabled
      enabledDestinations
      severitiesForExport
    }
  }
`;

export const AUTO_EXPORT_DESTINATION_OPTIONS = gql`
  query getFlexibleTestingAutoExportDestinations {
    flexibleTestingAutoExportDestinationOptions {
      name
      value
    }
  }
`;

type HeaderProps = {
  autoExportEnabled: boolean;
  showAutoExportToggleButton: boolean;
};

const Header = ({ autoExportEnabled, showAutoExportToggleButton }: HeaderProps): JSX.Element => {
  const statusLabel = autoExportEnabled ? (
    <StatusLabel>Active</StatusLabel>
  ) : (
    <StatusLabel variant="transparent">Inactive</StatusLabel>
  );

  return (
    <div data-testid="auto-export-header">
      <Heading size={1}>
        Auto-export
        <div className="align-middle ml-lg inline-flex" data-testid="auto-export-status">
          {statusLabel}
        </div>
        {showAutoExportToggleButton && (
          <div data-testid="auto-export-toggle-button" className="text-base inline-flex align-middle">
            <AutoExportToggleButton autoExportEnabled={autoExportEnabled} />
          </div>
        )}
      </Heading>
    </div>
  );
};

type AutoExportSettings = {
  flexibleTestingAutoExportSettings: FlexibleTestingAutoExportSettings;
};

type FlexibleTestingAutoExportDestinationOptions = {
  flexibleTestingAutoExportDestinationOptions: Array<FlexibleTestingAutoExportDestinationOption>;
};

const AutoExportSettingsPage = (): JSX.Element => {
  const { data: dataAutoExportSettings, loading: loadingAutoExportSettings } =
    useQuery<AutoExportSettings>(AUTO_EXPORT_SETTINGS);
  const autoExportSettings = dataAutoExportSettings?.flexibleTestingAutoExportSettings;

  const { data: dataDestinationOptions, loading: loadingDestinationOptions } =
    useQuery<FlexibleTestingAutoExportDestinationOptions>(AUTO_EXPORT_DESTINATION_OPTIONS, {
      fetchPolicy: "cache-and-network",
    });

  const autoExportDestinationOptions = dataDestinationOptions?.flexibleTestingAutoExportDestinationOptions || [];
  const hasAnyIntegrationSetUp = !isEmpty(autoExportDestinationOptions);
  const showAutoExportToggleButton = hasAnyIntegrationSetUp;

  const isLoaded = !loadingAutoExportSettings && !loadingDestinationOptions;
  const header = (
    <Header
      showAutoExportToggleButton={showAutoExportToggleButton}
      autoExportEnabled={autoExportSettings?.enabled || false}
    />
  );

  return (
    <>
      {isLoaded ? (
        <div data-testid="auto-export-settings-page">
          <PageTemplate header={header}>
            <Card>
              <div className="p-3xl">
                {hasAnyIntegrationSetUp ? (
                  <AutoExportSettingsForm
                    autoExportSettings={autoExportSettings}
                    autoExportDestinationOptions={autoExportDestinationOptions}
                  />
                ) : (
                  <OrganizationWithoutIntegrationSetup />
                )}
              </div>
            </Card>
          </PageTemplate>
        </div>
      ) : (
        <div>
          <Loader isLoaded={isLoaded} />
        </div>
      )}
    </>
  );
};

export default AutoExportSettingsPage;
