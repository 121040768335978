import React from "react";

import { FlexibleTestingStringCaseResultFieldResponse } from "types/graphql";
import { TestCaseStringField } from "components/flexible_testing/test_case_result_page/use_test_case_result";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { DecoratedText } from "@hopper/decorated_text";

type TextBasedAdditionalInformationProps = {
  additionalInformationCaseFields: TestCaseStringField[];
  additionalInformationResultFieldResponses: FlexibleTestingStringCaseResultFieldResponse[];
};

const TextBasedAdditionalInformation = ({
  additionalInformationCaseFields,
  additionalInformationResultFieldResponses,
}: TextBasedAdditionalInformationProps) => {
  const generatedAdditionalInformation = additionalInformationCaseFields.map(fieldResponse => {
    const response = additionalInformationResultFieldResponses?.find(
      response => response.caseField.id === fieldResponse.id,
    );

    if (response) {
      return {
        id: fieldResponse.id,
        label: fieldResponse.value,
        response: response.value,
      };
    }

    return null;
  });

  return (
    <div className="border-b border-light pb-xl" data-testid="text-based-additional-information">
      {generatedAdditionalInformation.map(
        additionalInformation =>
          additionalInformation && (
            <DetailsField
              key={additionalInformation.id}
              label={additionalInformation.label}
              paddingClass="pt-lg"
              testId={`additional-information-response-${additionalInformation.label}`}
            >
              <DecoratedText text={additionalInformation.response || "-"} />
            </DetailsField>
          ),
      )}
    </div>
  );
};

export default TextBasedAdditionalInformation;
