import React from "react";
import { Heading } from "@hopper/heading";
import pluralize from "pluralize";

import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import { ProgressBar } from "components/flexible_testing/result_details_page/testers/progress_bar";

type OverallProgressProps = {
  selectionRequirementsGroups: SelectionRequirementsGroup[];
};

export const OverallProgress = ({ selectionRequirementsGroups }: OverallProgressProps) => {
  const groupsCount = selectionRequirementsGroups.length;
  const completedGroupsCount = selectionRequirementsGroups.filter(
    group => group.numberOfApprovedResults === group.numberOfResultsNeeded,
  ).length;
  const publishedGroupsCount = selectionRequirementsGroups.filter(
    group => group.numberOfPublishedResults === group.numberOfResultsNeeded,
  ).length;
  const allCompletedGroupsHaveBeenPublished = publishedGroupsCount === selectionRequirementsGroups.length;

  return (
    <div data-testid="overall-progress">
      <Heading size={2}>Overall progress</Heading>
      <div className="w-[14rem]">
        <ProgressBar
          total={groupsCount}
          completed={completedGroupsCount}
          color={allCompletedGroupsHaveBeenPublished ? "success" : "primary"}
        />
      </div>
      {completedGroupsCount > 0 && (
        <div className="text-sm text-secondary" data-testid="overall-progress-details">
          {allCompletedGroupsHaveBeenPublished ? (
            <>All run groups have been published</>
          ) : (
            <>
              {completedGroupsCount} {pluralize("run group", completedGroupsCount)}{" "}
              {pluralize("have", completedGroupsCount)} a complete set of approved results
            </>
          )}
        </div>
      )}
    </div>
  );
};
