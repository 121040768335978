import React from "react";
import { FlexibleTestingTestCase } from "types/graphql";
import { TestCasesGroupItem } from "./test_cases_group_item";
import groupTestCasesBySection from "components/flexible_testing/utils/group_test_cases_by_section";

type TestCasesListGroupedBySectionProps = {
  testId: string;
  isTestFinished: boolean;
  testCases: Array<FlexibleTestingTestCase>;
};

function TestCasesListGroupedBySection({
  testCases,
  testId,
  isTestFinished,
}: TestCasesListGroupedBySectionProps): JSX.Element {
  const groupedTestCasesBySection = groupTestCasesBySection(testCases);

  return (
    <div className="pt-2xl" data-testid="test-cases-list-grouped-by-section">
      {groupedTestCasesBySection.map(testCasesGroup => (
        <TestCasesGroupItem
          key={testCasesGroup.section}
          testId={testId}
          isTestFinished={isTestFinished}
          section={testCasesGroup.section}
          testCases={testCasesGroup.testCases as FlexibleTestingTestCase[]}
        />
      ))}
    </div>
  );
}

export default TestCasesListGroupedBySection;
