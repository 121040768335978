import React from "react";

import chevronLeft from "./chevron_left.svg";

type LaunchWizardBackButtonProps = {
  onBackButtonClick: () => void;
};

export default function LaunchWizardBackButton({ onBackButtonClick }: LaunchWizardBackButtonProps): JSX.Element {
  return (
    <a
      className="flex cursor-pointer"
      target="_blank"
      onClick={onBackButtonClick}
      data-testid="launch-wizard-back-button"
    >
      <img src={chevronLeft} className="pr-sm" />
      <span className="text-secondary">Go back</span>
    </a>
  );
}
