import React from "react";

type UseCaseReasonsProps = {
  reasons: string[];
};

export function UseCaseReasons({ reasons }: UseCaseReasonsProps): JSX.Element {
  return (
    <div>
      <div className="text-darkest">We can help you:</div>
      <div className="text-body text-sm">
        {reasons.map(reason => (
          <div className="pt-sm flex" key={reason}>
            <div className="pr-sm">
              <i className="fa fa-check text-success" />
            </div>
            {reason}
          </div>
        ))}
      </div>
    </div>
  );
}
