import React, {
  ReactNode,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Channel, StreamChat } from "stream-chat";
import useFaviconCounter from "./use_favicon_counter";
import { ChatChannel, ChatChannelParams, initializeChannels } from "./initialize_channels";

export type ChatClientContextType = {
  client: StreamChat | null;
  setClient: Dispatch<SetStateAction<StreamChat | null>>;
  totalUnreadMessages: number;
  setChannelUnreadMessages: Dispatch<SetStateAction<Map<string, number>>>;
  channelUnreadMessages: Map<string, number>;
  channels: Map<string, Channel | null>;
  registerChannels: (channelParams: ChatChannelParams[]) => void;
};

export const ChatClientContext = createContext<ChatClientContextType>({
  client: null,
  setClient: () => {},
  totalUnreadMessages: 0,
  setChannelUnreadMessages: () => {},
  channelUnreadMessages: new Map(),
  channels: new Map(),
  registerChannels: () => {},
});

export function useChatContext() {
  const context = useContext(ChatClientContext);

  if (!context) {
    throw new Error("useChatContext must be used within the ChatClientContext");
  }

  return context;
}

type ChatProviderProps = {
  children: ReactNode;
};

export function ChatClientContextProvider({ children }: ChatProviderProps) {
  const [client, setClient] = useState<StreamChat | null>(null);
  const [channelUnreadMessages, setChannelUnreadMessages] = useState<Map<string, number>>(new Map());
  const [totalUnreadMessages, setTotalUnreadMessages] = useState<number>(0);
  const [channels, setChannels] = useState<Map<string, Channel | null>>(new Map());
  const registrationQueue = useRef<ChatChannel[]>([]);

  useFaviconCounter(totalUnreadMessages);

  useEffect(
    () => () => {
      if (client)
        client.disconnectUser().catch(() => {
          throw new Error("An error occurred while disconnecting from the chat");
        });
    },
    [client],
  );

  useEffect(() => {
    let totalUnreadMessages = 0;

    channelUnreadMessages.forEach(unreadCount => {
      totalUnreadMessages += unreadCount;
    });

    setTotalUnreadMessages(totalUnreadMessages);
  }, [channelUnreadMessages]);

  const registerChannels = useCallback(
    (channelParams: ChatChannelParams[]) => {
      if (client === null) return;

      const unregisteredChannels = channelParams.filter(
        ({ type, uuid }) => !channels.has(`${type}:${uuid}`),
      ) as ChatChannel[];

      registrationQueue.current.push(...unregisteredChannels);
      initializeChannels(client, setChannels, registrationQueue);
    },
    [client, channels],
  );

  return (
    <ChatClientContext.Provider
      value={{
        client,
        setClient,
        totalUnreadMessages,
        channelUnreadMessages,
        setChannelUnreadMessages,
        channels,
        registerChannels,
      }}
    >
      {children}
    </ChatClientContext.Provider>
  );
}
