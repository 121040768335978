import React from "react";
import { FlexibleTestingTestCaseResult } from "types/graphql";
import TestCaseRow from "./test_case_row";
import { AssignedCaseWithOwnResult } from "../tester_overview";

type TestCasesListProps = {
  testCases: Array<AssignedCaseWithOwnResult>;
  testerId?: string;
  onTestCaseResultRowClick: (testCaseResult: FlexibleTestingTestCaseResult) => void;
  selectedTestCaseResultId?: string;
};

const TestCasesList = ({
  testCases,
  testerId,
  onTestCaseResultRowClick,
  selectedTestCaseResultId,
}: TestCasesListProps): JSX.Element => (
  <>
    {testCases.map((testCase: AssignedCaseWithOwnResult) => (
      <TestCaseRow
        selectedTestCaseResultId={selectedTestCaseResultId}
        testCase={testCase}
        testerId={testerId}
        onTestCaseResultRowClick={onTestCaseResultRowClick}
      />
    ))}
  </>
);

export default TestCasesList;
