import { gql } from "@apollo/client";

export const GET_TEST_BLOCKERS = gql`
  query getTestBlockers($testId: ID!) {
    platformTest(id: $testId) {
      ... on FlexibleTestingTest {
        id
        blockers {
          edges {
            node {
              id
              status
              name
              createdAt
              assistanceRequested
              tester {
                id
                fullName
                country {
                  code
                  name
                }
              }
              chatChannel {
                id
                chatType
                externalId
              }
            }
          }
        }
      }
    }
  }
`;
