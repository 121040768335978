import React from "react";
import { websiteContentPaths } from "components/configuration";
import { ProfessionalService } from "types/graphql";
import { Button } from "@hopper/button";

type ProfessionalServicesCardParams = {
  professionalService: ProfessionalService;
};

export function ProfessionalServiceCard({ professionalService }: ProfessionalServicesCardParams): JSX.Element {
  const professionalServiceRequestPath = websiteContentPaths.newServiceRequest;
  const professionalServiceRequestUrl = professionalServiceRequestPath.replace(
    ":professionalServiceId",
    professionalService.id,
  );

  return (
    <div className="p-xl pr-5xl pb-ms">
      <div className="text-secondary text-xs uppercase tracking-wider font-bold pb-md">{professionalService.cost}</div>
      <div className="text-darkest text-sm">
        <div className="text-lg font-bold pb-md">{professionalService.title}</div>
        <div className="font-bold">{professionalService.serviceLevelAgreement}</div>
        <div className="pb-sm pr-2xl whitespace-pre-wrap">{professionalService.description}</div>
      </div>

      <div className="text-darkest text-sm font-bold items-start">
        <Button type="button" href={professionalServiceRequestUrl} appearance="minimal" className="h-2xl pl-0">
          Request service
        </Button>
      </div>
    </div>
  );
}
