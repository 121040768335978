import React from "react";

import IntegrationInfo from "./integration_info/integration_info";
import { FailureEmoji, WarningEmoji } from "./emoji/emoji";
import { intercom } from "components/configuration";

type ErrorProps = {
  code: string;
  message: string;
};
type ResultInfoProps = {
  error: ErrorProps;
  vendor: string;
};
type AdditionalInfoProps = {
  info?: string | JSX.Element;
};

type FailureOptionType = {
  isWarning?: boolean;
  header?: string;
  additionalInfo?: string | JSX.Element;
  error_message: string;
};

type FailureOptions = {
  [index: string]: FailureOptionType;
  default: FailureOptionType;
};

const AdditionalInfo = ({ info }: AdditionalInfoProps): JSX.Element | null => {
  if (info === "") {
    return null;
  }

  return (
    <div className="mt-2xl">
      {info === undefined ? (
        <>
          {"Please "}
          <a href="#" className={`${intercom.customLauncherClassname} text-link underline`}>
            get in touch
          </a>
          {" with us using the Intercom messenger to resolve the issue."}
        </>
      ) : (
        info
      )}
    </div>
  );
};

const FailureInfo = ({ error, vendor }: ResultInfoProps): JSX.Element => {
  const { code, message } = error;

  const failureOptions: FailureOptions = {
    client_authentication_not_found: {
      additionalInfo: <IntegrationInfo vendor={vendor} />,
      error_message: message,
    },
    request_debounce: {
      header: "This test has already been requested",
      additionalInfo: "",
      error_message: message,
    },
    vendor_error: {
      isWarning: true,
      header: "We have received your test request but there was a problem",
      error_message: message,
    },
    translation_error: {
      error_message: message,
    },
    invalid_integration: {
      error_message: message,
    },
    record_invalid: {
      header: "There was a problem with provided data",
      error_message: message,
    },
    default: {
      error_message: "There was a problem processing your test request.",
    },
  };

  const { isWarning, header, additionalInfo, error_message } = failureOptions[code] || failureOptions["default"];

  return (
    <div>
      <div className="text-xl font-bold mb-xl">
        {isWarning ? <WarningEmoji /> : <FailureEmoji />}
        {header ? header : "Your test request has not been sent"}
      </div>
      <div className="leading-2xl">{error_message}</div>
      <AdditionalInfo info={additionalInfo} />
    </div>
  );
};

export default FailureInfo;
