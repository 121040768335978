import React from "react";
import { CaseResultModerationLog } from "types/graphql";
import ModerationLog from "./moderation_log";

type CaseResultModerationLogsSectionProps = {
  caseResultModerationLogs: CaseResultModerationLog[];
};

const CaseResultModerationLogsSection = ({ caseResultModerationLogs }: CaseResultModerationLogsSectionProps) => (
  <section data-testid="test-manager-audit-log">
    <div className="my-xl text-xl font-bold">Test manager audit log</div>
    <div>
      {caseResultModerationLogs.map(caseResultModerationLog => (
        <ModerationLog moderationLog={caseResultModerationLog} />
      ))}
    </div>
  </section>
);

export default CaseResultModerationLogsSection;
