import React from "react";
import { Button } from "@hopper/button";

import { FlexibleTestingTest, OrganizationUser } from "types/graphql";
import { useAssignCommunityTestManager } from "./use_assign_community_test_manager";

type AssignCommunityTestManagerButtonProps = {
  communityTestManager: OrganizationUser;
  flexibleTestingTestId: FlexibleTestingTest["id"];
};

export const AssignCommunityTestManagerButton = ({
  communityTestManager,
  flexibleTestingTestId,
}: AssignCommunityTestManagerButtonProps) => {
  const { assignCommunityTestManager, loading } = useAssignCommunityTestManager();

  return (
    <Button
      className="h-auto font-normal"
      variant="primary"
      appearance="minimal"
      onClick={() => assignCommunityTestManager({ organizationUserId: communityTestManager.id, flexibleTestingTestId })}
      type="button"
      isLoading={loading}
    >
      Assign
    </Button>
  );
};
