import React from "react";
import { Modal } from "@hopper/legacy_modal";
import { Button } from "@hopper/button";
import { useHistory } from "react-router-dom";

import { websiteContentPaths } from "components/configuration";

const AssignmentAlreadyAcceptedModal = () => {
  const history = useHistory();

  const onClose = () => {
    history.push(websiteContentPaths.root);
  };

  return (
    <Modal
      isShowingCloseButton={false}
      onCloseButtonClicked={() => {}}
      position="center"
      size="lg"
      isOpen={true}
      onOverlayClicked={() => {}}
    >
      <div className="mx-lg max-w-sm">
        <p className="mt-lg font-bold">This assignment has already been accepted</p>
        <p className="mt-md text-secondary">We will notify you if the assignment becomes available</p>

        <div className="flex justify-end mt-6xl">
          <Button onClick={onClose} className="mt-md ml-sm" variant="secondary" size="md">
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignmentAlreadyAcceptedModal;
