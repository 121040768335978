import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";
import { Button } from "@hopper/button";

import {
  useLazyNextIssue,
  FlexibleTestingNextIssueResult,
} from "components/flexible_testing/issue_page/use_lazy_next_issue";
import { Events, websiteContentPaths } from "components/configuration";
import { useCurrentUser } from "app/current_user/use_current_user";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { ApolloError } from "@apollo/client";
import { FlexibleTestingIssueTriageStatus } from "types/graphql";

type ReverseMap<T> = T[keyof T];

type TriageStatuses = ReverseMap<FlexibleTestingIssueTriageStatus>;

type NextIssueLinkProps = {
  testId: string;
  issueId: string;
  issueTriageStatus: TriageStatuses;
  children: React.ReactNode;
};

type stateType = {
  currentFilter?: FlexibleTestingIssueTriageStatus;
};

function NextIssueLink({ testId, issueId, issueTriageStatus, children }: NextIssueLinkProps) {
  const history = useHistory();
  const tracking = useTracking();
  const currentUser = useCurrentUser();
  const { state } = useLocation<stateType>();
  const testResultPath = websiteContentPaths.flexcaseResultAcceptedIssues.replace(":id", testId);
  const triageStatus: FlexibleTestingIssueTriageStatus | TriageStatuses = state?.currentFilter || issueTriageStatus;

  const onCompleted = (data: FlexibleTestingNextIssueResult) => {
    const nextIssueId = data?.flexibleTestingNextIssue?.id;
    const redirectToPath = nextIssueId
      ? websiteContentPaths.flexcaseIssue.replace(":id", nextIssueId).replace(":testId", testId)
      : testResultPath;

    history.push(redirectToPath);
  };

  const onError = (error: ApolloError) => {
    notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
      type: "danger",
      message: error.message,
    });
    history.push(testResultPath);
  };

  const { loadNextIssue } = useLazyNextIssue(onCompleted, onError);

  const handleClick = () => {
    trackNextIssueLinkClicked();
    loadNextIssue({
      variables: { issueId, triageStatus },
    });
  };

  const trackNextIssueLinkClicked = () => {
    tracking.trackEvent({
      action: "track",
      name: Events.NEXT_ISSUE_LINK_CLICKED,
      eventProperties: {
        test_id: testId,
        issue_id: issueId,
        user_id: currentUser?.publicId || "",
        organization_id: currentUser?.organization.publicId || "",
      },
    });
  };

  return (
    <div className="mt-2xl">
      <Button variant="secondary" data-testid="next-issue-link" onClick={handleClick}>
        <span className="mr-sm">{children}</span>
        <i className="fa fa-chevron-right" aria-hidden="true" />
      </Button>
    </div>
  );
}

export default NextIssueLink;
