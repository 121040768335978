import React from "react";
import { Label } from "@hopper/legacy_label";

type CreditAccountBalanceProps = {
  balance?: number;
};

const creditAccountBalanceMessage = (balance: number) => {
  if (balance === 0) {
    return "0 Credits";
  } else if (balance === 1) {
    return "1 Credit";
  } else {
    const credits = Number.isInteger(balance) ? balance : balance?.toFixed(2);

    return `${credits} Credits`;
  }
};

const CreditAccountBalance = ({ balance }: CreditAccountBalanceProps): JSX.Element => {
  const creditAccountBalanceClassName = balance === 0 ? "text-danger" : "text-body";

  return (
    <>
      {balance !== undefined && (
        <Label color="bg-light" className="tracking-extra-wide uppercase">
          <span className={creditAccountBalanceClassName}>{creditAccountBalanceMessage(balance)}</span>
        </Label>
      )}
    </>
  );
};

export default CreditAccountBalance;
