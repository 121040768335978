import React from "react";

import { Heading } from "@hopper/heading";

export default function ErrorPage(): JSX.Element {
  return (
    <div className="px-lg">
      <Heading size={1}>We are sorry, something went wrong.</Heading>
      <div>We've been notified about the issue.</div>
    </div>
  );
}
