import { Heading } from "@hopper/heading";
import { Panel } from "@hopper/panel";
import React, { useEffect } from "react";
import ChatContainer from "./chat_container";
import { Loader } from "@hopper/loading";

import { Tabs, TabBar, Tab, TabPanel } from "components/shared/tabs";
import useChannel from "./use_channel";
import { useOpenChatFromQueryParams } from "./use_open_chat_from_query_params";
import { Participant } from "./helpers";
import { Test } from "types/graphql";
import { ChatType } from "types/app";
import { useChatContext } from "./chat_client_context";
import Authorization from "app/authorization/authorization";
import { useCurrentUser } from "app/current_user/use_current_user";
import { Label } from "@hopper/label";
import { useFeatureFlag } from "app/feature_flags";
import { useChat } from "./use_chat";

type PrivateChatPanelProps = {
  testId: Test["id"];
  testName: string;
  testCode: string;
  participant: Participant;
  communityTestManagersEnabled: boolean;
  isChatOpen: boolean;
  onChatClose: () => void;
  setUnreadMessagesCount: React.Dispatch<React.SetStateAction<number>>;
};

const useInitializeChannel = (chatType: ChatType, targetId: Test["id"], participant: Participant, register = true) => {
  const { channels } = useChatContext();
  const readyChannel = channels.get(`${chatType}:${participant.id}`);
  const { channel, isLoading, unreadMessagesCount } = useChannel({
    targetId,
    testerId: participant.testerId,
    chatType,
    providedChannel: readyChannel,
    shouldInitialize: register || readyChannel ? readyChannel !== undefined : false,
  });
  const { shouldOpenChannel, messageToJumpIntoExternalId } = useOpenChatFromQueryParams(channel?.id);

  return { channel, isLoading, unreadMessagesCount, shouldOpen: shouldOpenChannel, messageToJumpIntoExternalId };
};

const UndreadMessagesIndicator = ({ count }: { count: number }) =>
  !!count ? <Label className="px-xs py-2xs ml-xs">{count}</Label> : null;

const PrivateChatPanel = ({
  testId,
  testName,
  testCode,
  participant,
  isChatOpen,
  communityTestManagersEnabled,
  onChatClose,
  setUnreadMessagesCount,
}: PrivateChatPanelProps) => {
  const { isLoading: isClientLoading, registerChannels } = useChat();
  const isCTMsChatAccessEnabled = useFeatureFlag("ctms-chats-access", false);
  const currentUser = useCurrentUser();
  const isCTM = currentUser?.communityTestManager || false;
  const tmTesterChat = useInitializeChannel("test_private_tms_tester", testId, participant, !isCTM && isChatOpen);
  const ctmTesterChat = useInitializeChannel(
    "test_private_ctms_tester",
    testId,
    participant,
    isCTMsChatAccessEnabled && communityTestManagersEnabled && isChatOpen,
  );
  const isLoading = isClientLoading || tmTesterChat.isLoading || ctmTesterChat.isLoading;
  const shouldInitCTMsChat = isCTMsChatAccessEnabled && communityTestManagersEnabled;

  useEffect(() => {
    const channelTypesToRegister = [];
    if (currentUser?.testManager) channelTypesToRegister.push("test_private_tms_tester");
    if (shouldInitCTMsChat) channelTypesToRegister.push("test_private_ctms_tester");

    registerChannels(
      channelTypesToRegister.map(channelType => ({
        uuid: participant.id,
        externalId: participant.chatChannels?.find(({ chatType }) => chatType === channelType)?.externalId || null,
        type: channelType as ChatType,
      })),
    );
  }, [registerChannels, shouldInitCTMsChat, currentUser?.testManager]);

  useEffect(() => {
    setUnreadMessagesCount(tmTesterChat.unreadMessagesCount + ctmTesterChat.unreadMessagesCount);
  }, [tmTesterChat.unreadMessagesCount, ctmTesterChat.unreadMessagesCount]);

  if (!isChatOpen) return null;

  return (
    <Panel
      isOpen={isChatOpen}
      hasOverlay={true}
      onOverlayClick={onChatClose}
      position="right"
      onCloseButtonClick={onChatClose}
      maxWidth="52rem"
    >
      <div
        className="p-xl pt-0 flex flex-col ml-auto mr-0"
        style={{ height: "95dvh" }}
        data-testid="private-chat-panel"
      >
        <Heading size={2}>{`Chat with ${participant.fullName}`}</Heading>
        <Heading size={4}>{`This chat is only visible to ${participant.fullName}`}</Heading>
        <div>{testName}</div>
        <div className="text-secondary">{testCode}</div>
        {isLoading && <Loader />}
        <Tabs defaultActive={isCTM ? 1 : 0}>
          <div className="shrink-0 pt-3xl">
            <TabBar>
              <Authorization roles={["testManager"]}>
                <Tab index={0}>
                  Tester Work
                  <UndreadMessagesIndicator count={tmTesterChat.unreadMessagesCount} />
                </Tab>
              </Authorization>
              <Tab index={1} hidden={!shouldInitCTMsChat}>
                {isCTM ? "Private chat" : "Community Test Manager"}
                <UndreadMessagesIndicator count={ctmTesterChat.unreadMessagesCount} />
              </Tab>
            </TabBar>
          </div>
          <div className="overflow-hidden grow">
            {!isLoading && isChatOpen && (
              <>
                <Authorization roles={["testManager"]}>
                  <TabPanel index={0}>
                    <ChatContainer
                      channel={tmTesterChat.channel}
                      messageToJumpIntoExternalId={tmTesterChat.messageToJumpIntoExternalId}
                    />
                  </TabPanel>
                </Authorization>
                <TabPanel index={1}>
                  <ChatContainer
                    channel={ctmTesterChat.channel}
                    messageToJumpIntoExternalId={ctmTesterChat.messageToJumpIntoExternalId}
                    readOnly={!isCTM}
                  />
                </TabPanel>
              </>
            )}
          </div>
        </Tabs>
      </div>
    </Panel>
  );
};

export default PrivateChatPanel;
