import React from "react";
import FailedTestsCasesChart from "./failed_test_cases_chart";
import TesterResultsSummary from "./tester_results_summary";
import { TesterResultsStats } from "types/graphql";

type TestCasesSummaryProps = {
  participantsTotalCount: number;
  testCasesTotalCount: number;
  testCasesFailedCount: number;
  testerResults: Array<TesterResultsStats>;
};

function TestCasesSummary({
  testCasesTotalCount,
  testCasesFailedCount,
  testerResults,
}: TestCasesSummaryProps): JSX.Element {
  const testerResultsInProgress = testerResults
    .filter(testerResult => testerResult.status === "in_progress")
    .sort((a, b) => b.testCasesFailedCount - a.testCasesFailedCount);
  const testerResultsWithFailedCases = testerResults
    .filter(testerResult => testerResult.testCasesFailedCount > 0 && testerResult.status === "testing_complete")
    .sort((a, b) => b.testCasesFailedCount - a.testCasesFailedCount);
  const testerResultsNotPassed = [...testerResultsInProgress, ...testerResultsWithFailedCases];

  const testerResultsPassed = testerResults.filter(
    testerResult => testerResult.testCasesFailedCount === 0 && testerResult.status === "testing_complete",
  );

  const testerResultsColumn = (results: Array<TesterResultsStats>) =>
    results.map(testerResult => <TesterResultsSummary key={testerResult.testerName} testerResult={testerResult} />);

  return (
    <div data-testid="test-cases-summary">
      <FailedTestsCasesChart totalTestCasesCount={testCasesTotalCount} failedTestCasesCount={testCasesFailedCount} />

      <div className="grid grid-cols-2 mt-2xl" data-testid="testers-results-summary">
        {testerResultsNotPassed.length > 0 ? (
          <>
            <div style={{ width: "75%" }}>{testerResultsColumn(testerResultsNotPassed)}</div>
            <div style={{ width: "75%" }}>{testerResultsColumn(testerResultsPassed)}</div>
          </>
        ) : (
          <div style={{ width: "75%" }}>{testerResultsColumn(testerResultsPassed)}</div>
        )}
      </div>
    </div>
  );
}

export default TestCasesSummary;
