import React from "react";
import { Link } from "react-router-dom";
import { Card } from "@hopper/card";

import { Integration } from "types/graphql";
import azureDevops from "assets/images/integrations/azure-devops.svg";
import github from "assets/images/integrations/github.svg";
import jira from "assets/images/integrations/jira.svg";
import slack from "assets/images/integrations/slack.svg";
import testRail from "assets/images/integrations/test-rail.svg";
import zephyrSquad from "assets/images/integrations/zephyr-squad.svg";
// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import { IntegrationStateLabel } from "../../shared/integration_state_label";

type IntegrationCardProps = {
  integration: Integration;
};

type StringMap = {
  [key: string]: string;
};

const iconsMap: StringMap = {
  azure_devops: azureDevops,
  github,
  jira,
  jira_connect: jira,
  slack,
  testrail: testRail,
  zephyr_squad: zephyrSquad,
};

const displayIcon = (integrationCode: string) => <img src={iconsMap[integrationCode]} className="mr-sm" />;

const IntegrationCard = ({ integration }: IntegrationCardProps): JSX.Element => (
  <div data-test-role="integration" className="w-full mt-3xl">
    <Card active={!!integration.clientAuthenticationValid}>
      <div style={{ height: "144px" }} className="my-lg mx-sm" data-testid={`${integration.code}-integration-card`}>
        <div className="grid-cols justify-between h-full">
          <div className="mb-md" data-testid="integration-state">
            <IntegrationStateLabel isActive={!!integration.clientAuthenticationValid} />
          </div>
          <div className="flex items-center mb-5xl">
            <div className="ml-md">{displayIcon(integration.code as string)}</div>
            <div data-testid="integration-name" className="text-xl font-bold">
              {integration.name}
            </div>
          </div>
          <div data-testid="integration-edit-button" className="ml-md">
            <Link to={createNavigationUrl("integrationSetting", { code: integration.code || "" })}>
              <span className="text-sm font-bold">
                {integration.clientAuthenticationValid ? "Edit integration" : "Create integration"}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Card>
  </div>
);

export default IntegrationCard;
