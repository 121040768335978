import { FieldHookConfig, useField } from "formik";
import React from "react";
import FormLabel from "../form_label";
import { FormField } from "@hopper/form_field";
import { Textarea, TextareaProps as HopperTextareaProps } from "@hopper/textarea";
import { FieldError } from "components/flexible_testing/shared/form/field_error";

type TextAreaProps = {
  label?: string;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => unknown;
  labelClass?: string;
  useHopperInput?: boolean;
  description?: string;
};

function TextArea({
  label,
  required,
  onChange = () => {},
  validate,
  labelClass,
  description,
  useHopperInput = false,
  ...props
}: TextAreaProps & HopperTextareaProps & FieldHookConfig<string>): JSX.Element {
  const [field, meta] = useField({ validate, ...props });
  const id = props.id ?? props.name;

  return (
    <>
      {useHopperInput ? (
        <FormField
          label={label}
          isOptional={!required}
          description={description}
          errorMessage={meta.touched && meta.error}
        >
          <div style={{ minWidth: "24rem" }}>
            <Textarea
              id={id}
              style={{ minHeight: "5.5rem", maxHeight: "20rem", width: "100%" }}
              {...field}
              {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
              onChange={event => {
                field.onChange(event);
                onChange(event);
              }}
            />
          </div>
        </FormField>
      ) : (
        <>
          <FormLabel
            label={label}
            required={required}
            labelProps={{ htmlFor: props.id || props.name, className: labelClass }}
          />
          <Textarea
            id={id}
            {...field}
            {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            onChange={event => {
              field.onChange(event);
              onChange(event);
            }}
          />
          {meta.touched && meta.error ? <FieldError fieldName={props.name}>{meta.error}</FieldError> : null}
        </>
      )}
    </>
  );
}

export default TextArea;
