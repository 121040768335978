import React, { useEffect } from "react";
import { useFormikContext } from "formik";

import { TextInput } from "components/flexible_testing/shared/form";
import { RequestedTest } from "../requested_test_form";
import { ActivityStepSummary } from "./summaries/activity_step_summary";
import { ApplicationStepSummary } from "./summaries/application_step_summary";
import { ScopeStepSummary } from "./summaries/scope_step_summary";
import { TargetingStepSummary } from "./summaries/targeting_step_summary";
import RequestedTestWizardNavigation from "../requested_test_wizard_navigation";

const ReviewStepContent = ({ isEditing }: { isEditing: boolean }) => {
  const { isValid, validateForm, values } = useFormikContext<RequestedTest>();
  const activity = values.activity;

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <div data-testid="review-step-content">
      <div className="flex flex-col gap-lg">
        <ActivityStepSummary activity={activity} />
        <ApplicationStepSummary
          applicationType={values.applicationType}
          accessToApplicationUrl={values.accessToApplicationUrl}
          requiresAccessToSecureEnvironment={values.requiresAccessToSecureEnvironment}
        />
        <ScopeStepSummary
          activity={activity}
          testCasesLength={values.testCases?.length || 0}
          numberOfResultsPerTestCaseNeeded={values.numberOfResultsPerTestCaseNeeded}
          inScope={values.inScope}
          outOfScope={values.outOfScope}
        />
        <TargetingStepSummary
          activity={activity}
          applicationType={values.applicationType}
          targetingGroups={values.targetingGroups}
          customTargetingRequirements={values.customTargetingRequirements}
        />
      </div>
      <div className="w-5xl my-4xl">
        <hr />
      </div>
      <div className="flex flex-col gap-lg">
        <div>
          <TextInput name="name" placeholder="Test #1" label="Test name" required={true} />
        </div>
      </div>
      <RequestedTestWizardNavigation
        submitDisabled={!isValid}
        submitType="submit"
        submitCaption={isEditing ? "Update test" : "Add test to request"}
      />
    </div>
  );
};

export default ReviewStepContent;
