import React from "react";
import { Button } from "@hopper/button";

import { FlexibleTestingTest, AssignedCommunityTestManager } from "types/graphql";
import { useRemoveAssignedCommunityTestManager } from "./use_remove_assigned_community_test_manager";

type RemoveCommunityTestManagerButtonProps = {
  assignedCommunityTestManager: AssignedCommunityTestManager;
  flexibleTestingTestId: FlexibleTestingTest["id"];
};

export const RemoveCommunityTestManagerButton = ({
  assignedCommunityTestManager,
}: RemoveCommunityTestManagerButtonProps) => {
  const { removeAssignedCommunityTestManager, loading } = useRemoveAssignedCommunityTestManager();

  return (
    <Button
      className="h-auto font-normal text-secondary"
      variant="secondary"
      appearance="minimal"
      onClick={() =>
        removeAssignedCommunityTestManager({ assignedCommunityTestManagerId: assignedCommunityTestManager.id })
      }
      type="button"
      isLoading={loading}
    >
      Remove
    </Button>
  );
};
