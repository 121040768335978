import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Checkbox } from "@hopper/checkbox";
import { DesiredOutcomeCode } from "types/graphql";

type DesiredOutcomeItemProps = {
  desiredOutcomeOption: DesiredOutcomeOption;
  onCheckboxChange: (desiredOutcomeCode: DesiredOutcomeCode) => void;
};

export type DesiredOutcomeOption = {
  isChecked: boolean;
  name: string;
  code: DesiredOutcomeCode;
  description?: string | null;
  isAvailableOnStandard: boolean;
};

function TooltipContent({ description }: { description: string }): JSX.Element {
  return (
    <div className="text-left">
      {description.split("\n").map(item => (
        <>
          {item}
          <br />
        </>
      ))}
    </div>
  );
}

export function DesiredOutcomeItem({ desiredOutcomeOption, onCheckboxChange }: DesiredOutcomeItemProps): JSX.Element {
  return (
    <div key={desiredOutcomeOption.code}>
      <Checkbox
        data-testid={`desired-outcome-option-${desiredOutcomeOption.code}`}
        isChecked={desiredOutcomeOption.isChecked}
        label={desiredOutcomeOption.name}
        onChange={() => onCheckboxChange(desiredOutcomeOption.code)}
      />
      {desiredOutcomeOption.description && (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`desired-outcome-item-${desiredOutcomeOption.code}`}>
              <TooltipContent description={desiredOutcomeOption.description} />
            </Tooltip>
          }
        >
          <i className="fa fa-info-circle ml-sm text-dark" aria-hidden="true" />
        </OverlayTrigger>
      )}
    </div>
  );
}
