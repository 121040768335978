import { useQuery } from "@apollo/client";
import { Test, TestConnection, TestStatus } from "types/graphql";

import { TESTS } from "./live_tests_query";

type TestsList = {
  platformTests: TestConnection;
};

const useLiveTests = () => {
  const variables = { status: "in_progress" as TestStatus.InProgress };
  const { data, loading } = useQuery<TestsList>(TESTS, {
    variables,
  });

  const tests = data?.platformTests?.edges?.map(edge => edge?.node)?.filter((test): test is Test => !!test) || [];
  const liveTestsCount = tests.length;

  return {
    isLoading: loading,
    liveTestsCount,
    tests,
  };
};

export { useLiveTests };
