import Truncate from "react-truncate";

// This is a monkey patch to react-truncate library, which
// was suggested here - https://github.com/pablosichert/react-truncate/issues/105#issuecomment-483711070
// The implementation is based on a comment about workaround here -
// https://github.com/pablosichert/react-truncate/issues/30#issuecomment-483724471
// In short - the library does not support letter-spacing which we are using on several
// occasions.

export default class TruncateLetterSpacing extends Truncate {
  measureWidth(text: string) {
    const target = this.elements.target;

    const targetStyle = window.getComputedStyle(target);
    const letterSpacing: number = parseSpacing(targetStyle.getPropertyValue("letter-spacing"));
    const widthWithoutSpacing = this.canvasContext?.measureText(text).width || 0;
    const widthWithSpacing = widthWithoutSpacing + letterSpacing * (text.length - 1);

    return widthWithSpacing;
  }
}

function parseSpacing(spacingStyle: string): number {
  const parsed = Number(spacingStyle.replace("px", ""));

  return isNaN(parsed) ? 0 : parsed;
}
