import { gql, useLazyQuery } from "@apollo/client";
import { useField, useFormikContext } from "formik";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { MatchingTester, Scalars } from "types/graphql";
import { useCallback, useRef } from "react";
import { debounce, isEqual } from "lodash";
import { TesterGroupData } from "./tester_group_list";

const MATCHING_TESTERS_QUERY = gql`
  query getMatchingTesters(
    $numberOfResultsNeeded: Int!
    $filters: MatchingTestersFilters!
    $excludedTesterIds: [ID!]
    $advancedTestRequestId: ID!
  ) {
    matchingTesters(
      numberOfResultsNeeded: $numberOfResultsNeeded
      filters: $filters
      excludedTesterIds: $excludedTesterIds
      advancedTestRequestId: $advancedTestRequestId
    ) {
      totalNumber
      testers {
        id
        email
        fullName
        availability
        rankPercentile
        testedForCustomer
      }
    }
  }
`;

type ResultData = {
  matchingTesters: {
    totalNumber: number;
    testers: MatchingTester[];
  };
};

const useMatchingTestersQuery = ({ onCompleted }: { onCompleted: (response: ResultData) => void }) => {
  const [fetch, options] = useLazyQuery(MATCHING_TESTERS_QUERY, { onCompleted });
  const debouncedFetch = useCallback(debounce(fetch, 500), [fetch]);

  return [debouncedFetch, options] as const;
};

const processFilter = (filter?: { id: Scalars["ID"] }[]) =>
  filter && filter?.length > 0 ? filter?.map(({ id }) => id) : undefined;

const buildQueryVariables = (
  advancedTestRequestId: Scalars["ID"],
  {
    numberOfResultsNeeded,
    countries,
    languages,
    deviceTypes,
    mobileDevices,
    operatingSystemVersions,
    operatingSystems,
    manufacturers,
  }: TesterGroupData,
) => ({
  advancedTestRequestId,
  numberOfResultsNeeded,
  filters: {
    countryIds: processFilter(countries),
    languageIds: processFilter(languages),
    deviceTypeIds: processFilter(deviceTypes),
    mobileDeviceIds: processFilter(mobileDevices),
    operatingSystemVersionIds: processFilter(operatingSystemVersions),
    operatingSystemIds: processFilter(operatingSystems),
    manufacturerIds: processFilter(manufacturers),
  },
});

const specifySelectedTesters = (currentGroup: TesterGroupData, testerGroups: TesterGroupData[]) =>
  testerGroups
    .filter(group => group !== currentGroup)
    .map(({ matchingTesters, numberOfResultsNeeded }) =>
      matchingTesters.slice(0, numberOfResultsNeeded).map(({ id }) => id),
    )
    .flat();

const useMatchingTesters = (fieldName: string, testerGroup: TesterGroupData) => {
  const [, { value: advancedTestRequestId }] = useField("advancedTestRequestId");
  const [, { value: testerGroups }] = useField("testerGroups");
  const { automaticTesterSelectionEnabled, numberOfResultsNeeded, automaticTesterSelectionInitiated } = testerGroup;
  const variables = buildQueryVariables(advancedTestRequestId, testerGroup);
  const otherGroupsSelectedTesters = specifySelectedTesters(testerGroup, testerGroups);
  const previousVariables = useRef(automaticTesterSelectionInitiated ? variables : null);

  const [fetch, { loading }] = useMatchingTestersQuery({
    onCompleted: ({ matchingTesters: data }: ResultData) => {
      setFieldValue(`${fieldName}.matchingTesters`, data.testers);
      setFieldValue(`${fieldName}.excludedTesters`, []);
      setFieldValue(`${fieldName}.matchingTestersTotalNumber`, data.totalNumber);
      setFieldValue(`${fieldName}.automaticTesterSelectionInitiated`, true);

      if (data.testers.length < (numberOfResultsNeeded || 0)) {
        setFieldValue(`${fieldName}.automaticTesterSelectionEnabled`, false);
        setFieldValue(`${fieldName}.automaticTesterSelectionNotEnoughTesters`, true);
        setFieldValue(`${fieldName}.automaticTesterSelectionDisabledReason`, "Not enough testers");
      } else {
        setFieldValue(`${fieldName}.automaticTesterSelectionNotEnoughTesters`, false);
        setFieldValue(`${fieldName}.automaticTesterSelectionDisabledReason`, "");
      }

      notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
        type: "success",
        message: "Tester list updated below",
      });
    },
  });

  const filtersChanged = !isEqual(variables, previousVariables.current);
  if (numberOfResultsNeeded && automaticTesterSelectionEnabled && filtersChanged) {
    previousVariables.current = variables;
    fetch({ variables: { ...variables, excludedTesterIds: otherGroupsSelectedTesters } });
  }

  const { getFieldMeta, setFieldValue } = useFormikContext();
  const matchingTesters = getFieldMeta<MatchingTester[]>(`${fieldName}.matchingTesters`).value;
  const totalNumber = getFieldMeta<number>(`${fieldName}.matchingTestersTotalNumber`).value;

  if (loading) {
    notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
      type: "info",
      message: "Updating tester list",
    });
  }

  return {
    loading,
    testers: matchingTesters || [],
    totalNumber,
    filtersChanged,
    notEnoughTesters: matchingTesters?.length < (numberOfResultsNeeded || 0),
    initiated: testerGroup.automaticTesterSelectionInitiated,
    excludeTester: (id: MatchingTester["id"]) => {
      const testers = matchingTesters
        .filter(tester => tester.id !== id)
        .filter(tester => !otherGroupsSelectedTesters.includes(tester.id));

      setFieldValue(`${fieldName}.matchingTesters`, testers);
      if (testers.length < (numberOfResultsNeeded || 0)) {
        setFieldValue(`${fieldName}.automaticTesterSelectionEnabled`, false);
        setFieldValue(`${fieldName}.automaticTesterSelectionNotEnoughTesters`, true);
        setFieldValue(`${fieldName}.automaticTesterSelectionDisabledReason`, "Not enough testers");
      }

      const previousValue = getFieldMeta<MatchingTester[]>(`${fieldName}.excludedTesters`).value || [];
      setFieldValue(`${fieldName}.excludedTesters`, [
        ...previousValue,
        matchingTesters?.find(tester => tester.id === id),
      ]);
    },
  };
};

export default useMatchingTesters;
