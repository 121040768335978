import React from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";

import TestrailFlow from "./testrail_flow";
import GeneralFlow from "./general_flow";

type MatchParams = {
  externalTestId: string;
  vendor: string;
};

const VendorTestRequestConfirmationPage = (): JSX.Element => {
  const match = useRouteMatch<MatchParams>("/test-requests/:vendor/:externalTestId");
  const vendor = match?.params?.vendor || "";
  const externalTestId = match?.params?.externalTestId || "";

  return (
    <>
      <Helmet title="Test Request Confirmation" />

      {vendor === "testrail" ? (
        <TestrailFlow vendor={vendor} externalTestId={externalTestId} />
      ) : (
        <GeneralFlow vendor={vendor} externalTestId={externalTestId} />
      )}
    </>
  );
};

export default VendorTestRequestConfirmationPage;
