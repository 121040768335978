import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import OrganizationLayout from "components/layouts/organization_layout";
import FlexibleTestingLayout from "components/layouts/flexible_testing_layout";
import TestRequestsLayout from "components/layouts/test_requests_layout";
import NoHeaderLayout from "components/layouts/no_header_layout";
import NotFoundPage from "components/not_found_page";
import {
  FlexcaseResultsSection,
  AllIssuesListSection,
  FlexibleTestRequestPageSection,
  FlexibleVendorTestRequestResultSection,
  FlexibleVendorTestRequestSection,
  ProfessionalServicesSection,
  OverviewSection,
} from "../flexible_testing";
import OrganizationSettingsSection from "./organization_settings";
import OrganizationUserSettingsSection from "./organization_user_settings";
import OrganizationUserCreditUsagePage from "./organization_user_credit_usage";
import { useCurrentUser } from "app/current_user/use_current_user";
import { websiteContentPaths } from "components/configuration";

const withLayout =
  (Component, Layout = OrganizationLayout) =>
    routeProps => {

    return (
      <Layout {...routeProps}>
        <Component {...routeProps} />
      </Layout>
    )
  };

const CommunityTestManagerAuthorization = ({ children, ...props }) => {
  const currentUser = useCurrentUser();

  if (currentUser.communityTestManager) {
    return <Redirect to={websiteContentPaths.flexcaseResults} />;
  }

  return children;
};

const OrganizationSection = () => (
  <Switch>
    <Route
      path={websiteContentPaths.flexcaseResults}
      render={withLayout(FlexcaseResultsSection, FlexibleTestingLayout)}
    />
    <CommunityTestManagerAuthorization >
      <Switch>
        <Route path={websiteContentPaths.overview} render={withLayout(OverviewSection, FlexibleTestingLayout)} />
        <Route path={websiteContentPaths.organizationUserSettings} render={withLayout(OrganizationUserSettingsSection)} />
        <Route
          path={websiteContentPaths.organizationUserCreditUsage}
          render={withLayout(OrganizationUserCreditUsagePage)}
        />
        <Route path={websiteContentPaths.organizationSettings} render={withLayout(OrganizationSettingsSection)} />
        <Route path={websiteContentPaths.allIssues} render={withLayout(AllIssuesListSection, FlexibleTestingLayout)} />
        <Route
          path={websiteContentPaths.services}
          render={withLayout(ProfessionalServicesSection, FlexibleTestingLayout)}
        />
        <Route
          path={websiteContentPaths.flexcaseAutoExportSettings}
          render={withLayout(FlexcaseResultsSection, FlexibleTestingLayout)}
        />
        <Route
          path={websiteContentPaths.services}
          render={withLayout(ProfessionalServicesSection, FlexibleTestingLayout)}
        />
        <Route
          path={websiteContentPaths.flexcaseLaunch}
          render={withLayout(FlexibleTestRequestPageSection, FlexibleTestingLayout)}
        />
        <Route
          path={websiteContentPaths.testRequestResult}
          render={withLayout(FlexibleVendorTestRequestResultSection, TestRequestsLayout)}
        />
        <Route
          path={websiteContentPaths.testRequestsTestRail}
          render={withLayout(FlexibleVendorTestRequestSection, NoHeaderLayout)}
        />
        <Route
          path={websiteContentPaths.testRequestOther}
          render={withLayout(FlexibleVendorTestRequestSection, TestRequestsLayout)}
        />
      </Switch>
    </CommunityTestManagerAuthorization>
   
    <Route render={withLayout(NotFoundPage)} />
  </Switch>
);

export default OrganizationSection;
