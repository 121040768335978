import React from "react";
import classnames from "classnames";

import { FlexibleTestingIssue } from "types/graphql";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { extractField } from "components/flexible_testing/result_details_page/issues/issues_list/issue_row/extract_field";
// @ts-ignore
import { datetimeFormat } from "components/organization/shared_functions/date_format";
import { FlagIcon } from "../shared/flag";
import { IssueNote } from "components/flexible_testing/issue_page/issue_note";
import { Heading } from "@hopper/heading";
import { createNavigationUrl } from "app/helpers/url_helper";

type IssueDetailsProps = {
  issue: FlexibleTestingIssue;
  displayOriginTest?: boolean;
};

const IssueDetails = ({ issue, displayOriginTest = false }: IssueDetailsProps): JSX.Element => {
  const {
    testerCountry,
    issueFields,
    test: { id: testId, name: testName },
  } = issue;
  const issueActualResult = extractField("actual_results", issueFields);
  const issueExpectedResult = extractField("expected_results", issueFields);
  const environmentFields = [
    extractField("operating_system_and_version", issueFields),
    extractField("operating_system_version", issueFields),
    extractField("browser_or_device", issueFields),
    extractField("browser_and_operating_system", issueFields),
    extractField("mobile_device", issueFields),
  ].filter(environmentField => environmentField !== "");

  return (
    <div className="flex flex-col direction-column mb-xl">
      <div className="break-words">
        <div>
          <div className="border-muted px-0 flex flex-col">
            <div data-testid="issue-note-container">
              <IssueNote issue={issue} />
            </div>

            {issueActualResult && (
              <div className="px-0 mb-md">
                <div className="w-xs mb-xs border-l-4 border-light rounded-full hidden md:block" />
                <DetailsField label="Actual result" testId="actual-result" paddingClass="px-0">
                  {issueActualResult}
                </DetailsField>
              </div>
            )}
            {issueExpectedResult && (
              <div className="px-0 mt-xl">
                <DetailsField label="Expected result" testId="expected-result" paddingClass="px-0">
                  {issueExpectedResult}
                </DetailsField>
              </div>
            )}
            <div className="px-0 mt-xl">
              <DetailsField label="Tester Environment" testId="tester-environment" paddingClass="px-0">
                {environmentFields.join(" | ")}
              </DetailsField>
            </div>
            <div className="px-0 mt-xl">
              {displayOriginTest && (
                <DetailsField label="Reported During" testId="reported-during" paddingClass="px-0">
                  <Heading size={3}>{testName}</Heading>
                  <span>
                    <a
                      className="pl-0 text-link underline"
                      target="_blank"
                      href={createNavigationUrl("flexcaseResult", { id: testId })}
                    >
                      Go to test
                    </a>
                    <i className="text-sm text-dark fa fa-external-link ml-xs" />
                  </span>
                </DetailsField>
              )}
            </div>
            <div
              className={classnames(
                "grid grid-cols-1 md:grid-cols-2 py-xl",
                "border-t border-b border-light text-dark text-sm mt-xl",
              )}
            >
              <div className="flex" data-testid="tester-country">
                {testerCountry?.name && (
                  <>
                    <FlagIcon code={testerCountry.code?.toLowerCase()} className="mr-sm" />
                    Issue found in: {testerCountry.name}
                  </>
                )}
              </div>
              <div className="text-right" data-testid="issue-timestamp">
                Found on {datetimeFormat(issue.createdAt)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueDetails;
