import { gql, useLazyQuery } from "@apollo/client";

type InvalidDeviceTesterNode = {
  email: string;
};

type TestersWithInvalidDevicesParams = {
  testerEmails: string[];
  requiredOsVersionIds: string[];
};

export const TESTERS_WITH_INVALID_DEVICES = gql`
  query getTestersWithInvalidDevices($testerEmails: [String!]!, $requiredOsVersionIds: [ID!]!) {
    testersWithInvalidDevices(testerEmails: $testerEmails, requiredOsVersionIds: $requiredOsVersionIds) {
      edges {
        node {
          email
        }
      }
    }
  }
`;

export const useTestersWithInvalidDevices = () => {
  const [testersWithInvalidDevices, { loading }] = useLazyQuery(TESTERS_WITH_INVALID_DEVICES, {
    fetchPolicy: "network-only",
  });

  return {
    isLoading: loading,
    testersWithInvalidDevices: async ({ testerEmails, requiredOsVersionIds }: TestersWithInvalidDevicesParams) => {
      const { data } = await testersWithInvalidDevices({
        variables: { testerEmails, requiredOsVersionIds },
      });

      return {
        invalidEmails: data.testersWithInvalidDevices?.edges.map(
          ({ node }: { node: InvalidDeviceTesterNode }) => node?.email,
        ),
      };
    },
  };
};
