import React, { useState } from "react";

import { CaseResultPopup } from "components/flexible_testing/shared/case_result_popup";
import { useUpdateRelatedIssueCode } from "./use_update_related_issue_code";
import { ButtonLink } from "components/flexible_testing/shared/button_link/index";
import { useTrackingEditRelatedIssue } from "../track_moderation_events/use_tracking_edit_related_issue";

type EditRelatedIssueCodeButtonProps = {
  caseResultFieldResponseId: string;
  caseResultId: string;
  relatedIssueCode: string;
  isEditButtonDisabled?: boolean;
};

export function EditRelatedIssueCodeButton({
  caseResultFieldResponseId,
  caseResultId,
  relatedIssueCode,
  isEditButtonDisabled,
}: EditRelatedIssueCodeButtonProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const trackEditRelatedIssue = useTrackingEditRelatedIssue();

  const onSuccess = (newIssueId: string) => {
    trackEditRelatedIssue(newIssueId, relatedIssueCode);
    setIsPopupOpen(false);
  };

  const { flexibleTestingUpdateCaseResultResponse, isLoading: isButtonLoading } = useUpdateRelatedIssueCode(onSuccess);

  const onSaveButtonClick = (content: string) => {
    flexibleTestingUpdateCaseResultResponse(caseResultFieldResponseId, caseResultId, content);
  };

  return (
    <>
      <CaseResultPopup
        isOpen={isPopupOpen}
        onCancelButtonClick={() => setIsPopupOpen(false)}
        onSaveButtonClick={onSaveButtonClick}
        isButtonLoading={isButtonLoading}
        label="Related issue code"
        initialValue={relatedIssueCode}
        placeholder="e.g. GAT-123-1 or GAT-123-KI-1"
      >
        <ButtonLink variant="primary" size="md" onClick={() => setIsPopupOpen(true)} disabled={isEditButtonDisabled}>
          Edit
        </ButtonLink>
      </CaseResultPopup>
    </>
  );
}
