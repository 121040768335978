import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Loader } from "@hopper/loading";

import { websiteContentPaths } from "components/configuration";
import ResultDetailsContent from "./result_details_content";
import AcceptAssignmentModal from "./accept_assignment_modal";
import AssignmentAlreadyAcceptedModal from "./assignment_already_accepted_modal";
import { useAssignedCommunityTestManager } from "./use_assigned_community_test_manager";
import { AssignedCommunityTestManager } from "types/graphql";

type RouteParams = {
  testId?: string;
};

const CommunityTestManagerContent = () => {
  const history = useHistory();
  const match = useRouteMatch<RouteParams>("/results/:testId");
  const testId = match?.params.testId || "";

  const { isLoading, assignedCommunityTestManager } = useAssignedCommunityTestManager(testId);
  const status = assignedCommunityTestManager?.status;
  const isAssignmentAccepted = assignedCommunityTestManager?.isAssignmentAccepted;

  useEffect(() => {
    if (
      assignedCommunityTestManager?.status &&
      !["accepted", "assigned"].includes(assignedCommunityTestManager?.status)
    ) {
      history.push(websiteContentPaths.root);
    }
  }, [assignedCommunityTestManager]);

  if (isLoading) return <Loader />;

  return (
    <>
      {status === "accepted" && <ResultDetailsContent />}
      {status === "assigned" && !isAssignmentAccepted && (
        <AcceptAssignmentModal
          assignedCommunityTestManager={assignedCommunityTestManager as AssignedCommunityTestManager}
        />
      )}
      {status === "assigned" && isAssignmentAccepted && <AssignmentAlreadyAcceptedModal />}
    </>
  );
};

export default CommunityTestManagerContent;
