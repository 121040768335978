import { gql } from "@apollo/client";

export const GET_TESTERS_TAB_TEST_DATA = gql`
  query getPlatformTestTestersTab($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        name
        code
        supportsAutomaticTesterManagement
        automaticTesterManagementEnabled
      }
      ... on FlexibleTestingTest {
        id
        name
        code
        supportsAutomaticTesterManagement
        automaticTesterManagementEnabled
        canModifyNumberOfResultsNeeded
        hasStrictTesterRemoval
      }
    }
  }
`;
