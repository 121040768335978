import React from "react";
import { Heading } from "@hopper/heading";
import { Button } from "@hopper/button";
import { useHistory, useLocation } from "react-router-dom";

import { websiteContentPaths } from "components/configuration";
import { PageTemplate } from "components/flexible_testing/shared/page_template";

export default function CustomerRequestSuccessContent(): JSX.Element {
  const location = useLocation<{ state: string }>();
  const testName = location.state;
  const history = useHistory();

  const handleLaunchTest = () => {
    history.push(websiteContentPaths.advancedTestRequestTest);
  };

  return (
    <PageTemplate header={<Heading size={1}>Launch</Heading>}>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <div className="flex items-center mb-xl">
            <span className="text-md pr-md">✅</span>
            <span className="text-black text-xl font-bold">We have received your request</span>
          </div>

          <div className="text-black text-xl font-black mb-xl">{testName}</div>
          <div className="text-secondary text-sm space-y-xl">
            <p>We have received your test request</p>
            <p>We will review your request now and contact you shortly via email</p>
            <p>
              If any required information is missing from the request we will ask you to provide it before we begin
              preparing your test
            </p>
          </div>

          <Button className="mt-4xl" variant="secondary" appearance="ghost" onClick={handleLaunchTest} type="button">
            Launch another test
          </Button>
        </div>
      </div>
    </PageTemplate>
  );
}
