import React, { useState } from "react";
import PrivateChatPanel from "./private_chat_panel";
import { Participant } from "./helpers";
import { InlineButton } from "./inline_button";
import { ActionButton } from "./action_button";

export enum ButtonType {
  Inline = "inline",
  Action = "action",
}

type OpenPrivateChatButtonProps = {
  testName: string;
  testId: string;
  testCode: string;
  participant: Participant;
  communityTestManagersEnabled?: boolean;
  variant: ButtonType;
};

function OpenPrivateChatButton({
  testName,
  testId,
  testCode,
  participant,
  communityTestManagersEnabled = false,
  variant = ButtonType.Inline,
}: OpenPrivateChatButtonProps) {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const onChatButtonClick = () => setIsChatOpen(prev => !prev);

  return (
    <>
      {variant === ButtonType.Inline && (
        <InlineButton onChatButtonClick={onChatButtonClick} unreadMessagesCount={unreadMessagesCount} />
      )}

      {variant === ButtonType.Action && (
        <ActionButton onChatButtonClick={onChatButtonClick} unreadMessagesCount={unreadMessagesCount} />
      )}

      <PrivateChatPanel
        testId={testId}
        testName={testName}
        testCode={testCode}
        participant={participant}
        communityTestManagersEnabled={communityTestManagersEnabled}
        isChatOpen={isChatOpen}
        onChatClose={() => setIsChatOpen(false)}
        setUnreadMessagesCount={setUnreadMessagesCount}
      />
    </>
  );
}

export default OpenPrivateChatButton;
