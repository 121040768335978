import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Heading } from "@hopper/heading";
import { Button } from "@hopper/button";
import { Label } from "@hopper/label";

import { FlexibleTestingIssue, ReproducibilityTestDetail } from "types/graphql";
import { createNavigationUrl } from "app/helpers/url_helper";

import Modal from "app/modal";
import { BackToPreviousPageLink } from "../shared/back_to_previous_page";
import { IssueSeverity } from "../shared/issue_severity";
import { TagCell as IssueTags } from "../shared/tag_cell";
import { usePreviousState } from "../shared/use_previous_state";

import useIssue from "./use_issue";
import useReproducibilityTests from "./use_reproducibility_tests";
import {
  extractAdditionalDetails,
  extractField,
} from "../result_details_page/issues/issues_list/issue_row/extract_field";

import ProgressBar from "./progress_bar";
import IssueAttachments from "./issue_attachments";
import IssueDetails from "./issue_details";
import StepsToReproduce from "./steps_to_reproduce";
import { IssueTriage } from "./issue_triage";

import { ExportDestinations } from "../result_details_page/export_destinations";

import NextIssueLink from "./next_issue_link";
import { IssuePageTemplate } from "./issue_page_template";
import { AdditionalDetails } from "./additional_details";
import { useMarkIssueAsRead } from "./use_mark_issue_as_read";

import { Reproducibility } from "./reproducibility";
import { CaseResultStatus } from "./reproducibility_tests/case_result_status";
import ReproTestsContainer from "./reproducibility_tests/repro_tests_container";
import RelatedTestCases from "components/flexible_testing/issue_page/related_test_cases";

type Params = {
  id: string;
  testId: string;
};

type IssuePageProps = {
  navigationOrigin?: string;
  returnButton?: {
    label: string;
    url: string;
  };
};

const Header = ({ issue }: { issue: FlexibleTestingIssue }) => (
  <div className="grid grid-cols-12 my-sm">
    <div className="col-span-9 pt-sm">
      {issue?.code && <Label className="mr-sm">{issue?.code}</Label>}
      <div data-testid="issue-title">
        <Heading size={2}>{issue?.title}</Heading>
      </div>
    </div>
    <div className="col-span-3 justify-self-end self-center">{issue && <IssueTriage issue={issue} />}</div>
  </div>
);

const ExportIssueButton = ({ setShowExportModal }: { setShowExportModal: (state: boolean) => void }): JSX.Element => (
  <span>
    <Button variant="primary" onClick={() => setShowExportModal(true)} data-testid="export-issue">
      Export this issue
    </Button>
  </span>
);

const IssuePage = ({ returnButton, navigationOrigin }: IssuePageProps): JSX.Element => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [markedAsRead, setMarkedAsRead] = useState<boolean>(false);
  const { id } = useParams<Params>();
  const { data, isLoading } = useIssue(id);
  const { reproducibilityTestDetails } = useReproducibilityTests(id);
  const issue = data?.flexibleTestingIssue as FlexibleTestingIssue;
  const testId = issue?.test?.id;

  const tags = issue?.issueTags?.edges?.map(tag => tag?.node) || [];
  const shouldDisplayNextIssueLink = issue?.test?.testWindow?.status === "closed";
  const issuesCount = issue?.test?.issuesCount;
  const triagedIssuesCount = issue?.test?.triagedIssuesCount;

  const completedReproCaseResults =
    reproducibilityTestDetails
      .flatMap((reproducibilityTestDetail: ReproducibilityTestDetail) =>
        reproducibilityTestDetail?.test?.cases?.nodes.flatMap(node => node.completedCaseResults),
      )
      .filter((caseResult: { status: string }) => caseResult?.status !== "blocked") || [];

  const stepsToReproduce = extractField("reproduction_steps", issue?.issueFields);
  const additionalDetails = extractAdditionalDetails(issue?.issueFields);
  const isAdditionalDetailsAvailable = additionalDetails.length > 0;

  const reproducibleCases = completedReproCaseResults.filter(
    (caseResult: { status: string }) => (caseResult.status as CaseResultStatus) === "pass",
  ).length;

  const previousState = usePreviousState();
  const { markIssueAsRead } = useMarkIssueAsRead();

  const progressInPercent = () => (issuesCount && triagedIssuesCount ? (triagedIssuesCount / issuesCount) * 100 : 0);
  const shouldIssueBeMarkedAsRead = (issue: FlexibleTestingIssue): boolean => !issue?.wasRead && !markedAsRead;

  useEffect(() => {
    if (issue && shouldIssueBeMarkedAsRead(issue)) {
      markIssueAsRead(issue.id);
      setMarkedAsRead(true);
    }
  }, [isLoading]);

  return (
    <IssuePageTemplate
      navigation={
        <div className="grid grid-cols-12 my-sm">
          <div className="col-span-9 pt-sm">
            <BackToPreviousPageLink
              to={{
                pathname: returnButton?.url || createNavigationUrl("flexcaseResults"),
                search: "?tab=issues",
                state: { ...previousState, previousPage: "issuePage" },
              }}
              color="secondary"
            >
              {returnButton?.label || "Back to Test Run Results"}
            </BackToPreviousPageLink>
          </div>

          <div className="col-span-3 justify-self-end self-center">
            {shouldDisplayNextIssueLink && (
              <div className="flex">
                <div className="mr-xl mt-3xl">
                  <ProgressBar
                    headerMessage={`${triagedIssuesCount}/ ${issuesCount} issues triaged`}
                    successPercentage={progressInPercent() as number}
                  />
                </div>
                <div>
                  <NextIssueLink testId={testId} issueId={issue.id} issueTriageStatus={issue.triageStatus}>
                    Next issue
                  </NextIssueLink>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      header={<Header {...{ issue }} />}
    >
      <ExportIssueButton {...{ setShowExportModal }} />

      {issue && (
        <div data-testid="issue-page">
          <div className="mb-2xl pt-sm grid grid-cols-1 lg:grid-cols-2 items-start">
            <div className="flex flex-col order-2 lg:order-1">
              <IssueAttachments issue={issue} />
              {isAdditionalDetailsAvailable && <AdditionalDetails details={additionalDetails} />}
              {issue.testCases && (
                <div data-testid="related-test-case-container">
                  <RelatedTestCases testCases={issue.testCases} testId={testId} />
                </div>
              )}
            </div>

            <div className="flex flex-col lg:ml-4xl order-1 lg:order-2">
              <Heading size={2}>Issue Overview</Heading>
              <div className="grid grid-cols-12 border-b border-light pb-xl mb-xl">
                <div className="col-span-4 self-center" onClick={event => event.stopPropagation()}>
                  <IssueSeverity issue={issue} />
                </div>

                <div className="col-span-8 justify-self-end">
                  <IssueTags tags={tags} issueId={issue.id} isActiveTagsListLimited={false} />
                </div>
              </div>

              <IssueDetails issue={issue} displayOriginTest={navigationOrigin === "all_issues"} />

              <Heading size={2}>Reproducibility</Heading>

              <div className="mb-3xl">
                {!!completedReproCaseResults.length && (
                  <span>
                    This issue was reproducible for <Label className="text-base px-1 py-0">{reproducibleCases}</Label>{" "}
                    other testers.
                  </span>
                )}
              </div>

              {stepsToReproduce && <StepsToReproduce issue={issue} shouldTruncate={isAdditionalDetailsAvailable} />}
              {!!completedReproCaseResults.length ? (
                <ReproTestsContainer {...{ completedReproCaseResults, issue }} />
              ) : (
                <Reproducibility issue={issue} />
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showExportModal}
        title="Export selected issue(s)"
        closeModal={() => setShowExportModal(false)}
        visibleOverflowY={true}
      >
        <ExportDestinations
          issuesToExport={[issue?.id]}
          onSuccessfulExport={() => {
            setShowExportModal(false);
          }}
        />
      </Modal>
    </IssuePageTemplate>
  );
};

export default IssuePage;
