import React, { useState } from "react";
import { NavLink } from "react-router-dom";

// @ts-ignore
import { createNavigationUrl, ContentPath } from "app/helpers/url_helper";
import MenuItemBorder from "../menu_item_border/index";
import Authorization from "app/authorization/authorization";

type MainMenuItemProps = {
  title: string;
  pathName: ContentPath;
};

const MainMenuItem = ({ title, pathName }: MainMenuItemProps): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const shouldDisplayBorder = isActive || isHovered;

  return (
    <NavLink
      key={pathName}
      to={createNavigationUrl(pathName)}
      className="text-body font-bold uppercase mx-2xl pt-xs tracking-extra-wide"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isActive={(match, location) => {
        setIsActive(!!match);

        return !!match;
      }}
    >
      {title}
      <MenuItemBorder isVisible={shouldDisplayBorder} />
    </NavLink>
  );
};

const MainMenu = () => (
  <div data-testid="flexible-testing-nav" className="flex items-center h-6xl border-none">
    <Authorization roles={["testManager", "customerUser"]}>
      <>
        <MainMenuItem title="Overview" pathName="overview" />
        <MainMenuItem title="Launch" pathName="flexcaseLaunch" />
      </>
    </Authorization>
    <MainMenuItem title="Results" pathName="flexcaseResults" />
    <Authorization roles={["testManager", "customerUser"]}>
      <>
        <MainMenuItem title="Issues" pathName="allIssues" />
        <MainMenuItem title="Services" pathName="services" />
      </>
    </Authorization>
  </div>
);

export default MainMenu;
