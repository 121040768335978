import React from "react";

import FailureInfo from "./failure_info/failure_info";
import { SuccessInfo } from "./success_info/success_info";

type ErrorProps = {
  code: string;
  message: string;
};

export type ResultInfoProps = {
  error?: ErrorProps;
  vendor: string;
};

const ResultInfo = ({ error, vendor }: ResultInfoProps): JSX.Element => (
  <div data-testid="result-info">{error ? <FailureInfo error={error} vendor={vendor} /> : <SuccessInfo />}</div>
);

export default ResultInfo;
