import * as yup from "yup";

const launchTestFormCommonValidations = {
  applicationUrl: yup
    .string()
    .url("Please enter a valid URL")
    .trim()
    .required("A link to your application is required"),
  additionalUrlsAndResources: yup.string().trim().max(5000, "Cannot be longer than 5000 characters."),
  description: yup.string().trim().max(100_000, "Cannot be longer than 100000 characters."),
  name: yup.string().trim().required("A test name is required").max(1000, "Cannot be longer than 1000 characters."),
  testCases: yup.mixed().when(["includeTestCases"], {
    is: true,
    then: yup.mixed().required("You have not added any test cases"),
  }),
  testEnvironments: yup.array().min(1, "At least one environment has to be selected").required("required"),
};

export default launchTestFormCommonValidations;
