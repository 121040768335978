import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "@hopper/legacy_modal";
import { Button } from "@hopper/button";
import { Participant } from "./backup_testers_list";
import { useChangeBackupParticipantStatus } from "components/flexible_testing/result_details_page/testers/edit_requirements_group_panel/use_change_backup_participant_status";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

type ParticipantUpdateConfirmationProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedParticipant: Participant | undefined;
  testId: string;
  setShowRemovalConfirmationModal: Dispatch<SetStateAction<boolean>>;
};

export function ConfirmParticipantRemovalModal({
  onClose,
  isOpen,
  selectedParticipant,
  testId,
  setShowRemovalConfirmationModal,
}: ParticipantUpdateConfirmationProps) {
  const { changeBackupParticipantStatus, loading } = useChangeBackupParticipantStatus();

  const removeTester = () => {
    selectedParticipant &&
      changeBackupParticipantStatus("removed", selectedParticipant.id, testId).then(result => {
        if (result?.successful) {
          notificationEventBus.dispatch(DISPLAY_NOTIFICATION, { type: "success", message: "Tester removed" });
        } else {
          notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
            type: "danger",
            message: (result?.errors || [{ message: "Tester could not be removed" }])[0]?.message,
          });
        }
        setShowRemovalConfirmationModal(false);
      });
  };

  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={isOpen}
      position="center"
      size="md"
      onOverlayClicked={onClose}
    >
      <div className="flex flex-col max-w-xs sm:max-w-full ml-xl" data-testid="confirm-participant-removal-modal">
        <div className="mb-3xl font-bold">Remove tester from backup list</div>
        <div>Are you sure you want to remove the following tester as a backup from the test?</div>
        <div className="mt-lg">{selectedParticipant?.fullName}</div>
        <div className="mb-lg">{selectedParticipant?.email}</div>
        <div>Tester can be added as backup tester again.</div>
        <div className="flex mt-4xl items-beginning justify-beginning">
          <Button variant="primary" className="mr-xl" type="submit" onClick={removeTester} disabled={loading}>
            Remove tester
          </Button>
          <Button variant="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
