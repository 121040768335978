import React from "react";
import { OrderNumber } from "components/flexible_testing/shared/order_number";
import classnames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type PageSectionProps = {
  title?: React.ReactNode;
  subTitle?: string;
  orderNumber?: number;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  fullWidthTitle?: boolean;
  className?: string;
  titleClass?: string;
};

function PageSection({
  children,
  title,
  orderNumber,
  subTitle,
  tooltip,
  fullWidthTitle,
  className,
  titleClass,
}: PageSectionProps): JSX.Element {
  return (
    <div className={className}>
      <div className="flex items-center font-bold pb-md">
        {orderNumber && (
          <span className="mr-lg">
            <OrderNumber>{orderNumber}</OrderNumber>
          </span>
        )}
        <span
          className={classnames(`inline-flex items-center break-words`, titleClass, {
            "flex-grow": fullWidthTitle,
          })}
        >
          {title}
        </span>
        {tooltip ? (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id={`test-request-form-tooltip-${orderNumber}`}>{tooltip}</Tooltip>}
          >
            <i className="fa fa-info-circle ml-sm text-dark" aria-hidden="true" />
          </OverlayTrigger>
        ) : null}
        {subTitle ? <div className="text-dark font-normal ml-4xl text-sm mb-md">{subTitle}</div> : null}
      </div>
      <div className={classnames("mt-sm", { "ml-4xl pl-xs": orderNumber })}>{children}</div>
    </div>
  );
}

export default PageSection;
