import React from "react";
import { Heading } from "@hopper/heading";
import { Loader } from "@hopper/loading";
import { Transaction } from "types/graphql";
import { Redirect } from "react-router-dom";

import { PageTemplate } from "components/flexible_testing/shared/page_template";
import TransactionsList from "./components/transactions_list";
import ViewMoreButton from "./components/view_more_button";
import CreditUsageRemainder from "./components/credit_usage_remainder";
import { useCurrentOrganizationCreditAccount } from "components/shared/queries/organization_users_query";
import { useCurrentUser } from "app/current_user/use_current_user";
import { websiteContentPaths } from "components/configuration";

const OrganizationUserCreditUsagePage = () => {
  const currentUser = useCurrentUser();
  const creditAccount = useCurrentOrganizationCreditAccount();

  if (!currentUser?.organization.creditModelAccess) return <Redirect to={{ pathname: websiteContentPaths.root }} />;

  const { isLoading, transactions, balance, hasNextPage, loadNextTransactionsPage, isLoadingNextPage } = creditAccount;

  const header = (
    <div data-testid="credit-usage-header">
      <Heading size={1}>Credit usage</Heading>
    </div>
  );

  return (
    <PageTemplate header={header}>
      {balance && <CreditUsageRemainder balance={balance} />}
      {isLoading ? <Loader /> : <TransactionsList transactions={transactions as Transaction[]} />}
      {hasNextPage && <ViewMoreButton onClick={loadNextTransactionsPage} isLoading={isLoadingNextPage} />}
    </PageTemplate>
  );
};

export default OrganizationUserCreditUsagePage;
