import React from "react";

type PageTemplateProps = {
  children: React.ReactNode;
  header: React.ReactNode;
  backButton?: React.ReactNode;
};

export function PageTemplate({ children, header, backButton = null }: PageTemplateProps): JSX.Element {
  return (
    <div className="max-w-3xl mx-auto mb-6xl">
      <div className="h-3xl">{backButton}</div>
      <div className="pt-2xl">
        {header}
        <div className="mt-5xl">{children}</div>
      </div>
    </div>
  );
}
