import React from "react";
import { Helmet } from "react-helmet";
import { Heading } from "@hopper/heading";
import { useHistory } from "react-router";

import { ResultInfo } from "../shared/result_info";
import Loader from "./loader";
import useLaunchTest from "./use_launch_test";
import useExternalTestDetails from "./use_external_test_details";
import TestrailUserConfirmation from "./testrail_user_confirmation";
import extractError from "./extract_error";
import { PageTemplate } from "components/flexible_testing/shared/page_template";

type TestrailFlowProps = {
  vendor: string;
  externalTestId: string;
};

const TestrailFlow = ({ vendor, externalTestId }: TestrailFlowProps): JSX.Element => {
  const history = useHistory();
  const { isLoading: launchLoading, launchTest } = useLaunchTest();
  const {
    isLoading: prefetchLoading,
    externalTestDetails,
    error: prefetchError,
  } = useExternalTestDetails(externalTestId, vendor);
  const isLoading = launchLoading || prefetchLoading;

  const handleFormSubmit = (values: { [field: string]: unknown }): Promise<unknown> => {
    const { name, description } = externalTestDetails;
    const applicationUrl = values.applicationUrl as string | undefined;
    const credentialsAndAccessInstructions = values.credentialsAndAccessInstructions as string | undefined;
    const additionalUrlsAndResources = values.additionalUrlsAndResources as string | undefined;

    return launchTest(
      vendor,
      "",
      externalTestId,
      name,
      description,
      applicationUrl,
      credentialsAndAccessInstructions,
      additionalUrlsAndResources,
    ).then(response => {
      const launchError = extractError(response);
      history.push(`${externalTestId}/confirmation`, { launchError });
    });
  };

  const decorateError = (message: string) => ({
    code: "invalid_integration",
    message,
  });

  return (
    <>
      <Helmet title="Test Request Confirmation" />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PageTemplate header={<Heading size={1}>Confirm Launch</Heading>}>
            {prefetchError && <ResultInfo error={decorateError(prefetchError.message)} vendor={vendor} />}
            {!prefetchError && (
              <TestrailUserConfirmation
                handleFormSubmit={handleFormSubmit}
                externalTestDetails={externalTestDetails}
                vendor={vendor}
                externalTestId={externalTestId}
              />
            )}
          </PageTemplate>
        </>
      )}
    </>
  );
};

export default TestrailFlow;
