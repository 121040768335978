import React from "react";
import { Header, Table, HeaderCell, Body, Row, BodyCell } from "components/flexible_testing/shared/table";
import { Button } from "@hopper/button";
import pluralize from "pluralize";

import { MatchingTester } from "types/graphql";

type TestersTableProps = {
  testers: MatchingTester[];
  onRemove: (id: MatchingTester["id"]) => void;
};

const TestersTable = ({ testers, onRemove }: TestersTableProps) => (
  <Table noBorder={true} data-testid="selected-testers-table">
    <Header>
      <HeaderCell className="pb-sm pr-sm">Tester</HeaderCell>
      <HeaderCell className="pb-sm pr-sm">Available</HeaderCell>
      <HeaderCell className="pb-sm whitespace-nowrap">Rank %</HeaderCell>
    </Header>
    <Body>
      {testers.map(({ id, fullName, availability, rankPercentile, testedForCustomer }) => (
        <Row noBorder={true} key={id}>
          <BodyCell className="py-sm">
            {fullName}
            {testedForCustomer && <span className="text-danger">*</span>}
          </BodyCell>
          <BodyCell className="py-sm">{availability}</BodyCell>
          <BodyCell className="py-sm">{rankPercentile}</BodyCell>
          <BodyCell className="py-sm pr-0">
            <Button
              appearance="minimal"
              className="text-muted pl-sm px-0 font-normal h-auto"
              onClick={() => onRemove(id)}
              type="button"
            >
              REMOVE
            </Button>
          </BodyCell>
        </Row>
      ))}
    </Body>
  </Table>
);

type AutomaticTesterSelectionSectionProps = {
  selectedTestersLimit: number | undefined;
  testers: MatchingTester[];
  totalNumber: number;
  excludeTester: (id: MatchingTester["id"]) => void;
};

const AutomaticTesterSelectionSection = ({
  selectedTestersLimit,
  testers,
  totalNumber,
  excludeTester,
}: AutomaticTesterSelectionSectionProps) => {
  const selectedTesters = testers.slice(0, selectedTestersLimit);

  if (!selectedTestersLimit)
    return <p className="mt-md mb-2xl">Enter the number of results needed to preview tester selection</p>;

  return (
    <section className="mt-md mb-2xl">
      {selectedTesters.length >= selectedTestersLimit ? (
        <>
          <p className="mb-lg" data-testid="matching-testers-count">
            <strong>{totalNumber}</strong> {pluralize("tester", totalNumber)}{" "}
            {pluralize("match", totalNumber > 1 ? 1 : 2)} the group requirements
          </p>
          <p className="mb-2xl" data-testid="selected-testers-count">
            <strong>{selectedTesters.length}</strong> {pluralize("testers", selectedTesters.length)}{" "}
            {pluralize("have", selectedTesters.length)} been automatically selected and will be invited to participate
            in this test
          </p>
          <TestersTable testers={selectedTesters} onRemove={excludeTester} />
          <p className="text-secondary text-sm mt-sm">
            <span className="text-danger">*</span>This tester has tested an app for this customer before
          </p>
        </>
      ) : (
        <p>
          There are fewer testers than the number of results needed. Testers need to be manually selected or recruited
          by the CM&S team
        </p>
      )}
    </section>
  );
};

export default AutomaticTesterSelectionSection;
