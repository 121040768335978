import React from "react";

const NoIssuesFoundForFinishedTest = () => (
  <div className="mt-xl">
    <div className="font-bold max-w-md pb-md border-b border-light">No issues were found</div>
    <div className="mt-xl">Your test has finished and no more issues can be reported</div>
  </div>
);

export default NoIssuesFoundForFinishedTest;
