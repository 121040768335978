import React from "react";

import { FlexibleTestingIssueNote } from "types/graphql";
// @ts-ignore
import { dateFormat } from "components/organization/shared_functions/date_format";
import { isToday } from "date-fns";

const IssueNoteFooter = ({ issueNote }: { issueNote: FlexibleTestingIssueNote }): JSX.Element => {
  const buildFooter = () => authorFooterPart(issueNote) + editorFootherPart(issueNote);

  return (
    <div className="text-dark text-sm" data-testid="issue-note-footer">
      {buildFooter()}
    </div>
  );
};

function authorFooterPart(issueNote: FlexibleTestingIssueNote): string {
  const createdNoteDate = new Date(issueNote.createdAt);
  const createdNoteDateText = isToday(createdNoteDate) ? "Today" : dateFormat(createdNoteDate);

  return `${issueNote.authorName}, ${createdNoteDateText}`;
}

function editorFootherPart(issueNote: FlexibleTestingIssueNote): string {
  const { authorName, lastEditorName, createdAt, updatedAt } = issueNote;

  const createdNoteDate = new Date(createdAt);
  const editedNoteDate = new Date(updatedAt);
  const isEditedNote = !!lastEditorName;
  const isEditedToday = isToday(editedNoteDate);
  const isCreatedAndEditedSameDay = editedNoteDate.toDateString() === createdNoteDate.toDateString();
  const isCreatorEditedNoteLastTime = authorName === lastEditorName;
  const editedByText = isCreatorEditedNoteLastTime ? "edited" : `edited by ${lastEditorName}`;

  if (!isEditedNote) {
    return "";
  } else if (isEditedToday) {
    return `(${editedByText} today)`;
  } else if (isCreatedAndEditedSameDay) {
    return `(${editedByText})`;
  } else {
    return `(${editedByText} on ${dateFormat(editedNoteDate)})`;
  }
}

export default IssueNoteFooter;
