import React from "react";
import { Button } from "@hopper/button";
import { Spinner } from "@hopper/loading";
import { Link } from "react-router-dom";

type CloneTestRunButtonProps = {
  testsPath: string;
  isLoading?: boolean;
  hasNoPreviousTests?: boolean;
};

function CloneTestRunButton({ testsPath, isLoading, hasNoPreviousTests }: CloneTestRunButtonProps): JSX.Element {
  return (
    <>
      {isLoading ? (
        <span className="mr-5xl">
          <Spinner size={40} />
        </span>
      ) : (
        <Link
          style={hasNoPreviousTests ? { pointerEvents: "none" } : {}}
          className={hasNoPreviousTests ? "cursor-none" : ""}
          to={testsPath}
          data-testid="clone-test-button"
        >
          <Button variant="secondary" disabled={hasNoPreviousTests}>
            Clone a previous test run
          </Button>
        </Link>
      )}
    </>
  );
}

export default CloneTestRunButton;
