import React from "react";
import { Card } from "@hopper/card";
import { Button } from "@hopper/button";
import classNames from "classnames";

import { Activity } from "components/flexible_testing/test_request_page/requested_test_page/activity_step_content/activity_step_content";

type ActivityCardProps = {
  type: string;
  title: string;
  description: string;
  code: Activity | "custom";
  onSelect: () => unknown;
  disabled?: boolean;
};

const ActivityCard = ({ type, title, description, code, disabled = false, onSelect }: ActivityCardProps) => (
  <Card
    key={code}
    className={classNames("flex flex-col justify-between p-2xl", {
      "bg-light bg-opacity-50 border-transparent": disabled,
    })}
  >
    <div className={classNames({ "opacity-50": disabled })}>
      <span className="uppercase text-xs text-secondary font-bold">{type}</span>
      <h2 className="text-3xl font-black my-lg">{title}</h2>
      <p className="text-secondary">{description}</p>
    </div>

    <div>
      <Button
        type="button"
        className="mt-2xl opacity-100"
        variant="secondary"
        data-testid={`${code}-get-started`}
        onClick={onSelect}
        disabled={disabled}
      >
        Get started
      </Button>
    </div>
  </Card>
);

export default ActivityCard;
