import React, { useEffect, useState } from "react";

import { FlexibleTestingTestBlocker } from "types/graphql";
import { Header, HeaderCell, Table, Body } from "components/flexible_testing/shared/table";
import TestBlockerRow from "./blocker_row";
import { Checkbox } from "@hopper/checkbox";
import { ChatChannelParams } from "components/flexible_testing/shared/chat/initialize_channels";
import { useChat } from "components/flexible_testing/shared/chat/use_chat";

type TestBlockersProps = {
  testBlockers: FlexibleTestingTestBlocker[];
};

const TestBlockers = ({ testBlockers }: TestBlockersProps) => {
  const [filteredBlockers, setFilteredBlockers] = useState<FlexibleTestingTestBlocker[]>(testBlockers);
  const [hiddenStatuses, setHiddenStatuses] = useState<Array<string>>(["resolved"]);
  const { registerChannels } = useChat();

  useEffect(() => {
    registerChannels(
      testBlockers.reduce((acc, blocker) => {
        if (blocker.chatChannel)
          acc.push({
            uuid: blocker.id,
            externalId: blocker.chatChannel.externalId,
            type: blocker.chatChannel.chatType,
          });

        return acc;
      }, [] as ChatChannelParams[]),
    );
  }, [registerChannels, testBlockers]);

  useEffect(() => {
    setFilteredBlockers(filterTestBlockersByStatus(hiddenStatuses));
  }, [hiddenStatuses, testBlockers]);

  function filterTestBlockersByStatus(status: string[]) {
    return testBlockers.filter(testBlocker => !status.includes(testBlocker.status));
  }

  return (
    <div>
      <div className="my-4xl">
        <span className="p-md text-sm bg-light border-l-2 rounded border-primary pr-2xl">
          Review blockers to help testers when you can. If you need help from a test manager, <b>click ‘Get help’</b>
        </span>
      </div>
      <Checkbox
        label="Show ‘resolved’"
        onChange={() => (hiddenStatuses.includes("resolved") ? setHiddenStatuses([]) : setHiddenStatuses(["resolved"]))}
        isChecked={!hiddenStatuses.includes("resolved")}
      />
      <Table borderColor="muted" className="pt-2xl mt-2xl" data-testid="blockers-table">
        <Header className="border-b border-muted">
          <>
            <HeaderCell style={{ width: "17%" }}>TESTER</HeaderCell>
            <HeaderCell style={{ width: "40%" }}>BLOCKER</HeaderCell>
            <HeaderCell style={{ width: "9%" }}>STATUS</HeaderCell>
            <HeaderCell style={{ width: "9%" }}>REPORTED</HeaderCell>
            <HeaderCell style={{ width: "25%" }}></HeaderCell>
          </>
        </Header>
        <Body>
          {filteredBlockers.map(testBlocker => (
            <TestBlockerRow key={testBlocker.id} testBlocker={testBlocker} />
          ))}
        </Body>
      </Table>
    </div>
  );
};

export default TestBlockers;
