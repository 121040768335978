import React from "react";
import { useCurrentUser } from "app/current_user/use_current_user";

type AuthorizationProps = {
  roles: ("testManager" | "communityTestManager" | "customerUser")[];
  children: JSX.Element;
};

const Authorization: React.FC<AuthorizationProps> = ({ roles, children }) => {
  const currentUser = useCurrentUser();
  if (!currentUser) return null;

  // This condition is a temporary solution, and the Authorization component should be based on the role field of the OrganizationUser type,
  // which includes the explicit role definition for customerUser. Once changes are made to the backend, the current solution needs to be simplified.
  const isCustomerUser = !currentUser.testManager && !currentUser.communityTestManager;
  if (isCustomerUser && roles.includes("customerUser")) return <>{children}</>;

  const hasRole = roles.some(role => role !== "customerUser" && currentUser[role]);

  return hasRole ? <>{children}</> : null;
};

export default Authorization;
