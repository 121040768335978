import React from "react";
import { Helmet } from "react-helmet";

import styles from "./style.module.css";

import gatLogo from "assets/images/gat-logo.svg";
import { Notifications } from "../shared/notifications";

class TestRequestsLayout extends React.Component {
  render() {
    return (
      <div>
        <Helmet title="Global App Testing" titleTemplate="%s - Global App Testing" />

        <section className={styles.content}>
          <img src={gatLogo} alt="Global App Testing" className={styles.logo} />

          {this.props.children}

          <span className={styles.disclaimer}>
            {`Copyright ${new Date().getFullYear()} Global App Testing - All rights reserved`}
          </span>
        </section>

        <section className={styles.photoBox}>
          <div className={styles.photoBoxPhoto} />
        </section>

        <Notifications />
      </div>
    );
  }
}

export default TestRequestsLayout;
