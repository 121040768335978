import React from "react";
import { DecoratedText } from "@hopper/decorated_text";
// @ts-ignore
import { BodyCell, Row } from "../shared/table";
import InstructionResultDetails from "./instruction_result_details";
import { TestCaseResultFieldResponse } from "components/flexible_testing/test_case_result_page/use_test_case_result";
import { statuses, StatusIndicator } from "../shared/status_indicator";
import { OrderNumber } from "../shared/order_number";

type InstructionResultProps = {
  fieldResponse: TestCaseResultFieldResponse;
  testCaseResultId?: string;
  isCaseResultEditingDisabled?: boolean;
};

type StatusOptions = {
  background: string;
  fontColor: string;
};

type StatusVariation = {
  [key: string]: StatusOptions;
};

function InstructionResult({
  fieldResponse,
  testCaseResultId,
  isCaseResultEditingDisabled,
}: InstructionResultProps): JSX.Element {
  const backgroundClass: StatusVariation = {
    blocked: { background: "bg-warning", fontColor: "text-lightest" },
    fail: { background: "bg-danger", fontColor: "text-lightest" },
    notTested: { background: "bg-light", fontColor: "text-body" },
    pass: { background: "bg-light", fontColor: "text-body" },
  };

  const fieldStatus = (fieldResponse.status as typeof statuses[number]) || "not_tested";

  return (
    <>
      <Row noBorder={true} key={fieldResponse.id} testId="instruction-result">
        <BodyCell className="grid grid-cols-12">
          <div className="col-span-1">
            <OrderNumber
              background={backgroundClass[fieldStatus]?.background}
              fontColor={backgroundClass[fieldStatus]?.fontColor}
            >
              {fieldResponse.ordinalNumber}
            </OrderNumber>
          </div>
          <div className="col-span-11" data-testid="instruction-title">
            <DecoratedText text={fieldResponse.instruction} />
          </div>
        </BodyCell>
        <BodyCell className="align-top">
          <StatusIndicator status={fieldStatus} />
        </BodyCell>
      </Row>
      <InstructionResultDetails
        fieldResponse={fieldResponse}
        testCaseResultId={testCaseResultId}
        isCaseResultEditingDisabled={isCaseResultEditingDisabled}
      />
    </>
  );
}

export default InstructionResult;
