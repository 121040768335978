import React, { useState } from "react";

import { Label } from "@hopper/label";
import { FlexibleTestingTestCase } from "types/graphql";
import { TestCasesList } from "../../test_cases_list";
import { TestCasesGroupStatus } from "../test_cases_group_status";

export type TestCasesGroupItemProps = {
  testId: string;
  section: string;
  testCases: Array<FlexibleTestingTestCase>;
  isTestFinished: boolean;
};

function TestCasesGroupItem({ testId, section, testCases, isTestFinished }: TestCasesGroupItemProps): JSX.Element {
  const currentTestCaseId = location.hash.replace("#", "");
  const defaultShowTestCasesList = !!currentTestCaseId && testCases.some(testCase => testCase.id === currentTestCaseId);
  const [showTestCasesList, setShowTestCasesList] = useState(defaultShowTestCasesList);
  const toggleShowTestCasesList = () => setShowTestCasesList(!showTestCasesList);
  const numberNotTestedCases = testCases.filter(testCase => testCase.status === "not_tested").length;

  return (
    <>
      <div
        data-testid="test-cases-group-row"
        className="grid grid-cols-12 border-b border-light cursor-pointer break-words pt-3xl pb-md"
        onClick={toggleShowTestCasesList}
      >
        <div className="flex col-span-11">
          <div className="flex items-center ga-text-base text-darker leading-xl h-4xl break-words">
            <div className="pb-xs w-lg">
              <TestCasesGroupStatus isTestFinished={isTestFinished} testCases={testCases} />
            </div>
            <div className="text-secondary font-bold text-lg" data-testid="test-cycle-group-name">
              <span className="mr-xs">{section || "Other test cases"}</span>
              <Label>
                <span className="uppercase tracking-extra-wide text-secondary">
                  {testCases.length === 1 ? "1 Test case" : `${testCases.length} Test cases`}
                </span>
              </Label>
              <span className="text-xs text-dark" data-testid="test-cases-group-additional-info">
                {isTestFinished && additionalInfo(numberNotTestedCases)}
              </span>
            </div>
          </div>
        </div>

        <div className="col-span-1">
          <div className="text-secondary pull-right">
            {showTestCasesList ? <i className="fa fa-chevron-up pl-1" /> : <i className="fa fa-chevron-down pl-1" />}
          </div>
        </div>
      </div>
      {showTestCasesList && <TestCasesList groupedBySection={true} testCases={testCases} testId={testId} />}
    </>
  );
}

function additionalInfo(numberNotTestedCases: number): string | undefined {
  if (numberNotTestedCases === 1) {
    return "(1 test case was not tested)";
  } else if (numberNotTestedCases > 1) {
    return `(${numberNotTestedCases} test cases were not tested)`;
  }
}

export default TestCasesGroupItem;
