import React from "react";
import { Heading } from "@hopper/heading";

type StepSummaryHeadingProps = {
  heading: string;
  subheading?: string;
  dataTestId?: string;
};

export const StepSummaryHeading = ({ heading, subheading, dataTestId }: StepSummaryHeadingProps) => (
  <div className="flex gap-md items-center" data-testid={dataTestId}>
    <Heading size={2}>
      <span className="text-body font-bold">{heading}</span>
    </Heading>
    {subheading && (
      <Heading size={4}>
        <span className="text-secondary">{subheading}</span>
      </Heading>
    )}
  </div>
);
