import { gql, useMutation } from "@apollo/client";
import {
  FlexibleTestingCaseResultDeleteRelatedIssueInput,
  FlexibleTestingCaseResultDeleteRelatedIssuePayload,
} from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { TEST_CASE_RESULT_QUERY } from "components/flexible_testing/tester_overview/use_flexible_testing_test_case_result";

const DELETE_TEST_CASE_RELATED_ISSUE_MUTATION = gql`
  mutation ($input: FlexibleTestingCaseResultDeleteRelatedIssueInput!) {
    flexibleTestingCaseResultDeleteRelatedIssue(input: $input) {
      successful
      errors {
        code
        message
      }
    }
  }
`;

const displayError = (message: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message,
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "The related issue has been successfully deleted.",
  });
};

type useDeleteTestCaseRelatedIssueResult = {
  flexibleTestingRelatedIssueDelete: (
    caseResultResponseId: string,
    caseResultId: string,
    changeStatusToPass?: boolean,
  ) => Promise<void>;
  isLoading: boolean;
};

export const useDeleteTestCaseRelatedIssue = (
  onSuccess: (isStatusPass: boolean) => void,
): useDeleteTestCaseRelatedIssueResult => {
  const [flexibleTestingRelatedIssueDeleteMutation, { loading }] = useMutation<
    { flexibleTestingCaseResultDeleteRelatedIssue: FlexibleTestingCaseResultDeleteRelatedIssuePayload },
    { input: FlexibleTestingCaseResultDeleteRelatedIssueInput }
  >(DELETE_TEST_CASE_RELATED_ISSUE_MUTATION, {
    onCompleted: ({ flexibleTestingCaseResultDeleteRelatedIssue }, clientOptions) => {
      if (flexibleTestingCaseResultDeleteRelatedIssue?.successful) {
        displaySuccess();
        onSuccess(clientOptions?.variables?.input?.changeStatusToPass || false);
      } else {
        const errorMessage = flexibleTestingCaseResultDeleteRelatedIssue.errors.map(({ message }) => message).join(",");
        displayError(errorMessage);
      }
    },
    onError: () => displayError("There was an issue with deleting the related issue. Please try again later."),
  });

  return {
    flexibleTestingRelatedIssueDelete: async (
      caseResultResponseId: string,
      caseResultId: string,
      changeStatusToPass?: boolean,
    ): Promise<void> => {
      await flexibleTestingRelatedIssueDeleteMutation({
        variables: { input: { caseResultResponseId, changeStatusToPass } },
        refetchQueries: [{ query: TEST_CASE_RESULT_QUERY, variables: { id: caseResultId } }],
      });
    },
    isLoading: loading,
  };
};
