import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "@hopper/legacy_modal";
import { Checkbox } from "@hopper/checkbox";
import { Button } from "@hopper/button";
import { ParticipantRemovalReason } from "types/graphql";
import ModalSuccessMessage from "./modal_success_message";
import ModalErrorMessage from "./modal_error_message";
import { ModalStatus } from "./remove_tester_button";

type StrictTesterRemovalModalProps = {
  onClose: () => void;
  onSubmit: (reasons: string[]) => void;
  isOpen: boolean;
  status: ModalStatus;
  participantRemovalReasons: ParticipantRemovalReason[];
};

const StrictTesterRemovalModal = ({
  onClose,
  onSubmit,
  isOpen,
  status,
  participantRemovalReasons,
}: StrictTesterRemovalModalProps) => {
  const [reasons, setReasons] = useState<string[]>([]);

  const toggleReasonsFilter = (reason: string) => {
    if (reasons.includes(reason)) {
      setReasons(reasons.filter(r => r !== reason));
    } else {
      setReasons([...reasons, reason]);
    }
  };

  const isCheckboxDisabled = (reason: string) => {
    if (reason === "invited_by_mistake") {
      return reasons.length > 0 && !reasons.includes("invited_by_mistake");
    } else {
      return reasons.includes("invited_by_mistake");
    }
  };

  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={() => onClose()}
      isOpen={isOpen}
      position="center"
      size="lg"
      onOverlayClicked={() => {}}
    >
      {status === "success" && <ModalSuccessMessage numberOfTesters={1} />}
      {status === "error" && <ModalErrorMessage numberOfTesters={1} />}
      {status === "init" && (
        <div className="mt-lg ml-lg mb-sm" data-testid="strict-tester-removal-modal">
          <ModalHeader>Please provide a reason for removing this tester</ModalHeader>
          <ModalBody className="text-secondary mt-2xl mb-3xl mr-6xl pr-2xl">
            <ul>
              {participantRemovalReasons.map(reason => (
                <li key={reason.code} className="mt-xs">
                  <Checkbox
                    label={reason.label}
                    onChange={() => toggleReasonsFilter(reason.code)}
                    isChecked={reasons.includes(reason.code)}
                    disabled={isCheckboxDisabled(reason.code)}
                  />
                </li>
              ))}
            </ul>
          </ModalBody>

          <div className="flex justify-end mt-4xl gap-md">
            <Button onClick={() => onClose()} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => onSubmit(reasons)} disabled={!reasons.length}>
              Remove this tester
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default StrictTesterRemovalModal;
