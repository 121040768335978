import React, { useRef, useState } from "react";
import { PopupMenu } from "@hopper/popup_menu";
import Popup from "components/shared/popup";
import { FlexibleTestingTest, Test } from "types/graphql";
import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import { Participant } from "components/flexible_testing/shared/chat";
import { Button } from "@hopper/button";
import { CsvExport } from "./csv_export";
import { PublishAllResultsModal } from "./publish_all_results_modal";
import { ModeratorsPanel } from "./moderators";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";

type ResultsHeaderPopupProps = {
  testId: string;
  children: React.ReactNode;
  platformTest?: Test;
  isModeratorsButtonVisible: boolean;
  isCsvExportButtonVisible: boolean;
  isPublishAllButtonVisible: boolean | undefined;
  selectionRequirementsGroups: SelectionRequirementsGroup[];
};

type ResultsHeaderPopupContentProps = {
  isModeratorsButtonVisible: boolean;
  isCsvExportButtonVisible: boolean;
  isPublishAllButtonVisible: boolean | undefined;
  approvedParticipantIds: string[];
  setPublishAllResultsModalVisible: (arg0: boolean) => void;
  setCsvExportPanelVisible: (arg0: boolean) => void;
  setModeratorsPanelVisible: (arg0: boolean) => void;
  setIsResultsHeaderPopupOpen: (arg0: boolean) => void;
};

const ResultsHeaderPopupContent = ({
  isModeratorsButtonVisible,
  isCsvExportButtonVisible,
  isPublishAllButtonVisible,
  approvedParticipantIds,
  setPublishAllResultsModalVisible,
  setCsvExportPanelVisible,
  setModeratorsPanelVisible,
  setIsResultsHeaderPopupOpen,
}: ResultsHeaderPopupContentProps) => (
  <div>
    <PopupMenu>
      {isModeratorsButtonVisible && (
        <div className="hover:bg-light px-sm">
          <Button
            appearance="minimal"
            variant="secondary"
            onClick={() => {
              setModeratorsPanelVisible(true);
              setIsResultsHeaderPopupOpen(false);
            }}
          >
            Moderators
          </Button>
        </div>
      )}
      {isCsvExportButtonVisible && (
        <div className="hover:bg-light px-sm">
          <Button
            appearance="minimal"
            variant="secondary"
            onClick={() => {
              setCsvExportPanelVisible(true);
              setIsResultsHeaderPopupOpen(false);
            }}
          >
            Export CSV
          </Button>
        </div>
      )}
      {isPublishAllButtonVisible && (
        <div className="hover:bg-light px-sm">
          <Button
            appearance="minimal"
            variant="secondary"
            onClick={() => {
              setPublishAllResultsModalVisible(true);
              setIsResultsHeaderPopupOpen(false);
            }}
            disabled={approvedParticipantIds.length === 0}
          >
            <span className="font-normal">Publish all</span>
          </Button>
        </div>
      )}
    </PopupMenu>
  </div>
);

const ResultsHeaderPopup = ({
  testId,
  children,
  platformTest,
  isModeratorsButtonVisible,
  isCsvExportButtonVisible,
  isPublishAllButtonVisible,
  selectionRequirementsGroups,
}: ResultsHeaderPopupProps) => {
  const wrapperRef = useRef(null);

  const [isResultsHeaderPopupOpen, setIsResultsHeaderPopupOpen] = useState(false);
  const [publishAllResultsModalVisible, setPublishAllResultsModalVisible] = useState(false);
  const [csvExportPanelVisible, setCsvExportPanelVisible] = useState(false);
  const [moderatorsPanelVisible, setModeratorsPanelVisible] = useState(false);

  const approvedParticipantIds = selectionRequirementsGroups.reduce(
    (participants: Participant["id"][], group) =>
      participants.concat(
        group.participants
          .filter(
            participant =>
              participant.status === "results_approved" && participant.numberOfPendingIssueModerations === 0,
          )
          .map(participant => participant.id),
      ),
    [],
  );

  const resultsHeaderPopupContent = ResultsHeaderPopupContent({
    isModeratorsButtonVisible,
    isCsvExportButtonVisible,
    isPublishAllButtonVisible,
    approvedParticipantIds,
    setPublishAllResultsModalVisible,
    setCsvExportPanelVisible,
    setModeratorsPanelVisible,
    setIsResultsHeaderPopupOpen,
  });

  useOutsideHandler(wrapperRef, () => setIsResultsHeaderPopupOpen(false));

  return (
    <div ref={wrapperRef}>
      <Popup offset={[-4, 8]} popupElement={resultsHeaderPopupContent} isOpen={isResultsHeaderPopupOpen}>
        <div
          className="cursor-pointer"
          onClick={() => setIsResultsHeaderPopupOpen(!isResultsHeaderPopupOpen)}
          data-testid="results-header-popup-button"
        >
          {children}
        </div>
      </Popup>
      {isModeratorsButtonVisible && (
        <ModeratorsPanel
          isPanelOpen={moderatorsPanelVisible}
          onPanelClose={() => setModeratorsPanelVisible(false)}
          platformTest={platformTest as FlexibleTestingTest}
        />
      )}
      {isCsvExportButtonVisible && (
        <CsvExport
          testId={testId}
          isPanelOpen={csvExportPanelVisible}
          onClose={() => setCsvExportPanelVisible(false)}
          selectionRequirementsGroups={selectionRequirementsGroups}
        />
      )}
      {isPublishAllButtonVisible && (
        <PublishAllResultsModal
          participantIds={approvedParticipantIds}
          testId={testId}
          isOpen={publishAllResultsModalVisible}
          onClose={() => setPublishAllResultsModalVisible(false)}
        />
      )}
    </div>
  );
};

export default ResultsHeaderPopup;
