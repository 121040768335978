import React from "react";
import { countBy, times } from "lodash";
import classNames from "classnames";

import { sortByStatus } from "components/flexible_testing/shared/sort_by_status";

export type BreakdownStatuses = "pass" | "fail" | "blocked" | "not_tested" | "rejected" | "skipped" | "missing";

type StatusMap = {
  [key in BreakdownStatuses]: string;
};

const statusToLabelMap: StatusMap = {
  pass: "passed",
  fail: "failed",
  blocked: "blocked",
  not_tested: "not tested",
  rejected: "rejected",
  skipped: "skipped",
  missing: "missing",
};

type TesterResultsBreakdownProps = {
  overallStatus: BreakdownStatuses;
  testCaseResultStatuses: BreakdownStatuses[];
};

type TesterResultBreakdownProps = {
  sequenceNumber: number;
  totalCount: number;
  status: BreakdownStatuses;
};

type AllTesterResultsRejectedBreakdownProp = {
  testCaseResultCount: number;
};

type TesterResultsBreakdownLayoutProps = {
  breakdownLabel: React.ReactNode;
  testerResultsBar: React.ReactNode;
};

function TesterResultsBreakdown({ overallStatus, testCaseResultStatuses }: TesterResultsBreakdownProps): JSX.Element {
  const unrejectedTestCaseResultStatuses = testCaseResultStatuses.filter(status => status !== "rejected");

  if (testCaseResultStatuses.length === 0) {
    return <NoTesterResultsBreakdown />;
  } else if (unrejectedTestCaseResultStatuses.length === 0) {
    return <AllTesterResultsRejectedBreakdown testCaseResultCount={testCaseResultStatuses.length} />;
  } else {
    return (
      <TesterResultsUnrejectedBreakdown
        overallStatus={overallStatus}
        testCaseResultStatuses={unrejectedTestCaseResultStatuses}
      />
    );
  }
}

function TesterResultsUnrejectedBreakdown({
  overallStatus,
  testCaseResultStatuses,
}: TesterResultsBreakdownProps): JSX.Element {
  const sortedStatuses = sortStatuses(testCaseResultStatuses, overallStatus);
  const statusesCounts = countBy(sortedStatuses);
  const overallStatusesCount = statusesCounts[overallStatus] || 0;
  const testCaseResultStatusesCount = testCaseResultStatuses.length;
  const breakdownLabel = `${overallStatusesCount}/${testCaseResultStatusesCount} ${statusToLabelMap[overallStatus]}`;

  return (
    <TesterResultsBreakdownLayout
      breakdownLabel={breakdownLabel}
      testerResultsBar={times(testCaseResultStatusesCount, sequenceNumber => (
        <TesterResultBar
          sequenceNumber={sequenceNumber}
          totalCount={testCaseResultStatusesCount}
          status={sortedStatuses[sequenceNumber] || ("not_tested" as BreakdownStatuses)}
        />
      ))}
    />
  );
}

function NoTesterResultsBreakdown(): JSX.Element {
  return (
    <TesterResultsBreakdownLayout
      breakdownLabel="Not tested"
      testerResultsBar={<TesterResultBar sequenceNumber={1} totalCount={1} status="not_tested" />}
    />
  );
}

function AllTesterResultsRejectedBreakdown({
  testCaseResultCount,
}: AllTesterResultsRejectedBreakdownProp): JSX.Element {
  return (
    <TesterResultsBreakdownLayout
      breakdownLabel={`${testCaseResultCount}/${testCaseResultCount} rejected`}
      testerResultsBar={times(testCaseResultCount, sequenceNumber => (
        <TesterResultBar sequenceNumber={sequenceNumber} totalCount={testCaseResultCount} status="rejected" />
      ))}
    />
  );
}

function TesterResultsBreakdownLayout({
  breakdownLabel,
  testerResultsBar,
}: TesterResultsBreakdownLayoutProps): JSX.Element {
  return (
    <div className="w-full" data-testid="tester-results-breakdown">
      <div className="text-sm text-secondary" data-testid="tester-results-breakdown-label">
        {breakdownLabel}
      </div>
      <div className="flex h-xs mt-xs" data-testid="tester-results-breakdown-bar-container">
        {testerResultsBar}
      </div>
    </div>
  );
}

function TesterResultBar({ sequenceNumber, totalCount, status }: TesterResultBreakdownProps): JSX.Element {
  const statusToColorBarMap: StatusMap = {
    pass: "success",
    fail: "danger",
    blocked: "warning",
    not_tested: "muted",
    rejected: "muted",
    missing: "muted",
    skipped: "muted",
  };
  const color = statusToColorBarMap[status] || "muted";
  const marginInPercent = totalCount > 10 ? 0 : 1;
  const className = [
    { "rounded-base": totalCount === 1 },
    { "rounded-l-base": totalCount > 1 && sequenceNumber === 0 },
    { "rounded-r-base": totalCount > 1 && sequenceNumber === totalCount - 1 },
    "h-xs",
    `bg-${color}`,
  ];
  const styles = {
    width: `${Math.round((100 - marginInPercent * (totalCount - 1)) / totalCount)}%`,
    marginRight: totalCount > 1 && sequenceNumber < totalCount - 1 ? `${marginInPercent}%` : "0%",
  };

  return (
    <span
      key={sequenceNumber}
      className={classNames(className)}
      style={styles}
      data-testid={`tester-results-breakdown-bar-${sequenceNumber}-${status}`}
    />
  );
}

function sortStatuses(statuses: BreakdownStatuses[], overallStatus: BreakdownStatuses): BreakdownStatuses[] {
  return [...statuses].sort((firstStatus: BreakdownStatuses, secondStatus: BreakdownStatuses) => {
    if (firstStatus === overallStatus) {
      return -1;
    } else if (secondStatus === overallStatus) {
      return 1;
    } else {
      return sortByStatus(firstStatus, secondStatus);
    }
  });
}

export default TesterResultsBreakdown;
