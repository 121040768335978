import React from "react";

import { Route, Switch, withRouter } from "react-router-dom";
import Organization from "components/organization";
import SignUp from "components/sign_up";
import ErrorPage from "components/error_page";
import { TestManager } from "components/test_manager";
import GoogleAnalytics from "react-router-ga";
import { googleAnalyticsTrackId, websiteContentPaths } from "components/configuration";
import { isProductionHost } from "app/app_environment";
import { ErrorBoundary } from "./components/error_boundary";
import Auth from "./components/auth";

class AutoScrollTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const AutoScrollTopRouted = withRouter(AutoScrollTop);

class Root extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <GoogleAnalytics id={googleAnalyticsTrackId} debug={!isProductionHost()}>
          <AutoScrollTopRouted>
            <div className="root">
              <Switch>
                <Route path={websiteContentPaths.auth} component={Auth} />
                <Route path={websiteContentPaths.error} component={ErrorPage} />
                <Route path={websiteContentPaths.signUp} component={SignUp} />
                <Route path={websiteContentPaths.testManagers} component={TestManager} />
                <Route path={websiteContentPaths.root} component={Organization} />
              </Switch>
            </div>
          </AutoScrollTopRouted>
        </GoogleAnalytics>
      </ErrorBoundary>
    );
  }
}

export default Root;
