import { IssueSeverityOption } from "types/graphql";
import { gql, useQuery } from "@apollo/client";

type useSeverityOptionsResult = {
  issueSeverities: Array<IssueSeverityOption>;
  isLoading: boolean;
};

type IssueSeverityOptions = {
  issueSeverityOptions: Array<IssueSeverityOption>;
};

export const ISSUE_SEVERITY_OPTIONS = gql`
  query IssueSeverityOptions {
    issueSeverityOptions {
      name
    }
  }
`;

export const useIssueSeverities = (): useSeverityOptionsResult => {
  const { data, loading } = useQuery<IssueSeverityOptions>(ISSUE_SEVERITY_OPTIONS);
  const issueSeverities = data?.issueSeverityOptions || [];

  return {
    issueSeverities,
    isLoading: loading,
  };
};
