import React from "react";
import { CopyToClipboard } from "components/shared/copy_to_clipboard";

type NewApiKeyInputProps = {
  apiKey?: string;
};

function NewApiKeyInput({ apiKey }: NewApiKeyInputProps): JSX.Element {
  return (
    <>
      <div className="my-sm my-xl" data-testid="generated-api-key-secret-field">
        <CopyToClipboard value={apiKey} />
      </div>
      <p className="mb-6xl text-sm text-dark">
        <i className="fa fa-exclamation-triangle text-warning mr-sm" />
        This is the only time you will be able to copy this API key
      </p>
    </>
  );
}

export default NewApiKeyInput;
