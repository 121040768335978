import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Heading } from "@hopper/heading";

import { Content } from "./content";
import {
  DesiredOutcomeCode,
  FlexibleTestingTestCaseParams,
  Scalars,
  SoftwareDevelopmentLifeCycleStageName,
} from "types/graphql";
import { PageTemplate } from "../shared/page_template";
import { BackToPreviousPageLink } from "components/flexible_testing/shared/back_to_previous_page";
import { formatDatetime } from "../shared/format_datetime";

type StandardTest = "standard_test";
type AdvancedTest = "advanced_test";
export const standardTest: StandardTest = "standard_test";
export const advancedTest: AdvancedTest = "advanced_test";
export type TestTypes = StandardTest | AdvancedTest;

export type PreviewPageAdvancedTestRequest = {
  accessToApplication: string;
  testObjective: string;
  notes: string;
  name: string;
  platformsToTestOn: string;
  deviceRequirements: string;
  testLocalisation: string;
  isTestLocalised: boolean;
  testCasesUrl: string;
  isTestCasesIncluded: boolean;
  knownIssuesUrl: string;
  isKnownIssuesIncluded: boolean;
  scheduleAt: Scalars["ISO8601DateTime"];
  isTestScheduled: boolean;
  testType: AdvancedTest;
  capabilities?: Array<string>;
  sdlcStage?: SoftwareDevelopmentLifeCycleStageName;
  desiredOutcomes?: Array<DesiredOutcomeCode>;
  customDesiredOutcomes?: string;
  testCases?: Array<FlexibleTestingTestCaseParams>;
};

export type PreviewPageStandardTestRequest = {
  applicationUrl: string;
  name: string;
  description: string;
  credentialsAndAccessInstructions: string;
  additionalUrlsAndResources: string;
  testCasesFile: File | null;
  testCases: Array<FlexibleTestingTestCaseParams>;
  testEnvironments: Array<string>;
  scheduleAt: Scalars["ISO8601DateTime"];
  isTestScheduled: boolean;
  testType: StandardTest;
  desiredOutcomes?: Array<DesiredOutcomeCode>;
  sdlcStage?: SoftwareDevelopmentLifeCycleStageName;
  testerSpecificationDocument?: string;
  requiredAttachmentForPass: boolean;
};

type BackToLaunchStandardTestProps = {
  testType: TestTypes;
  pathname: string;
};

type PreviewPageProps = {
  testRequest: PreviewPageStandardTestRequest | PreviewPageAdvancedTestRequest;
  editPath: string;
};

function BackToLaunchStandardTest({ testType, pathname }: BackToLaunchStandardTestProps): JSX.Element {
  const location = useLocation();

  return (
    <BackToPreviousPageLink to={{ pathname, search: `?tab=${testType}`, state: location.state }}>
      Back to launch
    </BackToPreviousPageLink>
  );
}

export function PreviewPage({ testRequest, editPath }: PreviewPageProps): JSX.Element {
  const history = useHistory();
  const { testType } = testRequest;

  function handleEditTest() {
    history.push(`${editPath}?tab=${testType}`, { ...testRequest });
  }

  const formattedTestLaunchDateTime = formatDatetime(testRequest.scheduleAt);

  return (
    <div data-testid="preview-test-page">
      <PageTemplate
        backButton={<BackToLaunchStandardTest testType={testType} pathname={editPath} />}
        header={<Heading size={1}>Test run preview</Heading>}
      >
        <Content
          handleEditTest={handleEditTest}
          testRequest={testRequest}
          formattedTestLaunchDateTime={formattedTestLaunchDateTime}
        />
      </PageTemplate>
    </div>
  );
}
