import { gql, useMutation } from "@apollo/client";
import { Maybe, ExportRawTestCasesToCsvPayload } from "types/graphql";

export const CSV_MUTATION = gql`
  mutation ($input: ExportRawTestCasesToCSVInput!) {
    exportRawTestCasesToCsv(input: $input) {
      result
      errors {
        message
        code
        path
      }
      successful
    }
  }
`;

type MutationData = {
  exportRawTestCasesToCsv?: Maybe<ExportRawTestCasesToCsvPayload>;
};
type DownloadCsvFileType = {
  (): Promise<void>;
};

export function useCsvExport(advancedTestRequestId?: string): {
  downloadCsvFile: DownloadCsvFileType;
  isLoading: boolean;
} {
  const [exportRawTestCasesToCsv, { loading }] = useMutation<MutationData>(CSV_MUTATION);

  return {
    isLoading: loading,
    downloadCsvFile: async () => {
      const result = await exportRawTestCasesToCsv({
        variables: {
          input: {
            advancedTestRequestId,
          },
        },
      });
      const mutation_results = result.data?.exportRawTestCasesToCsv?.result;
      if (mutation_results) {
        mutation_results.forEach((csv_file, index) => {
          /**
           * This timeout is a workaround for a limit of max 10 files downloaded at one time in Chrome.
           */
          setTimeout(() => {
            downloadCsv(csv_file.body, csv_file.filename);
          }, 100 * index);
        });
      }
    },
  };
}

function downloadCsv(body: BlobPart, fileName: string) {
  const blob = new Blob([body], { type: "text/csv" });
  const reader = new FileReader();
  reader.onload = e => {
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = e?.target?.result as string;
    anchor.download = fileName;
    anchor.click();
  };
  reader.readAsDataURL(blob);
}
