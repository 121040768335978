import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { RouteComponentProps } from "react-router-dom";

// @ts-ignore
import { AuthTokenProvider } from "components/auth_token_provider";
// @ts-ignore
import { CurrentOrganizationUserProvider } from "components/current_organization_user_provider";
// @ts-ignore
import { Analytics } from "app/analytics";
// @ts-ignore
import { UserSignInEventSender } from "components/user_sign_in_event_sender";
// @ts-ignore
import RootPageSelector from "components/root_page_selector/root_page_selector";
// @ts-ignore
import OrganizationInitializer from "components/organization/initializer";
import { SessionValidator } from "components/session_validator";
import { IntercomFailedToLoadEventSender } from "components/intercom_failed_to_load_event_sender";
import { TestManagerSection } from "./test_manager_section";

const TestManager = (props: RouteComponentProps) => (
  <AuthTokenProvider>
    <SessionValidator>
      <CurrentOrganizationUserProvider>
        <Analytics {...props}>
          <UserSignInEventSender>
            <IntercomFailedToLoadEventSender>
              <RootPageSelector>
                <OrganizationInitializer>
                  <TestManagerSection />
                </OrganizationInitializer>
              </RootPageSelector>
            </IntercomFailedToLoadEventSender>
          </UserSignInEventSender>
        </Analytics>
      </CurrentOrganizationUserProvider>
    </SessionValidator>
  </AuthTokenProvider>
);

function getLoginOptions() {
  let redirectedWithError = {};

  if (window.location.search.includes("error=403")) {
    const errorMessage =
      "Account with a given email does not exist in our system. Please verify the email or contact our Customer Support to create new account.";
    redirectedWithError = { redirectedWithError: errorMessage };
  } else if (window.location.search.includes("trial=expired")) {
    redirectedWithError = { trialExpired: true };
  }

  return redirectedWithError;
}

export default withAuthenticationRequired(TestManager, { loginOptions: getLoginOptions() });
