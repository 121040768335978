import { useQuery } from "@apollo/client";
import { Test } from "types/graphql";
import { GET_FEEDBACK_TAB_DATA } from "./feedback_query";

function useFeedbackTabData(testId?: string) {
  const { loading, data } = useQuery(GET_FEEDBACK_TAB_DATA, {
    variables: { testId },
    skip: !testId,
  });

  const platformTest = data?.platformTest;

  return {
    isLoading: loading,
    testWindow: platformTest?.testWindow,
    testerFeedback: extractTesterFeedback(platformTest),
  };
}

function extractTesterFeedback(platformTest?: Test) {
  return (
    platformTest?.testerSessions?.edges
      ?.map(testerSession => testerSession?.node)
      .filter(testerSession => !!testerSession)
      .filter(testerSession => testerSession.feedback) || []
  );
}

export { useFeedbackTabData };
