import { Label } from "@hopper/legacy_label";
import { Button } from "@hopper/button";
import React from "react";
import { Test } from "../use_tests";
import { TestCase } from "types/test_cases";
import FailedTestCases from "./failed_test_cases";
import { CloneLink } from "components/flexible_testing/shared/clone_link/clone_link";

type TestsListItemProps = {
  test: Test;
  trackingEventName: string;
};

function TestsListItem({ test, trackingEventName }: TestsListItemProps): JSX.Element {
  const {
    _id,
    code,
    name,
    date,
    applicationUrl,
    testCasesUrl,
    isClosed,
    failedTestCasesCount,
    testCasesTotalCount,
    description,
  } = test;

  return (
    <div data-testid="test-list-item" className="border-b border-light grid grid-cols-12 gap-xl w-full items-center">
      <span className="py-md col-span-4" data-testid="test-list-item-name">
        <div className="uppercase text-secondary tracking-wide text-sm">{code}</div>
        <div className="truncate whitespace-nowrap font-bold mb-xs">{name}</div>
        <span className="tracking-wide uppercase text-xs text-secondary font-bold">
          {isClosed ? (
            <span className="mr-sm uppercase">finished</span>
          ) : (
            <Label className="ml-0 mr-sm uppercase" color="bg-primary">
              live
            </Label>
          )}

          {date}
        </span>
      </span>
      <span className="py-md col-span-2 truncate" data-testid="test-list-item-url">
        <a className="text-link text-sm" href={applicationUrl}>
          {applicationUrl}
        </a>
      </span>
      <span className="py-md col-span-2 truncate" data-testid="test-list-item-cases">
        {testCasesUrl ? (
          <a className="text-link text-sm" href={applicationUrl}>
            {testCasesUrl}
          </a>
        ) : (
          <FailedTestCases
            failedTestCasesCount={failedTestCasesCount || 0}
            totalTestCasesCount={testCasesTotalCount || 0}
          />
        )}
      </span>
      <span className="py-md text-sm col-span-3" data-testid="test-list-item-description">
        {description}
      </span>
      <span className="py-md" data-testid="test-list-item-clone-action">
        <CloneLink
          trackingEventName={trackingEventName}
          testTrackingId={_id}
          pathForCloning={test.pathForCloning}
          testId={test.id}
        >
          <Button variant="secondary" size="sm">
            Clone
          </Button>
        </CloneLink>
      </span>
    </div>
  );
}

export function filterOutTestCasesDataNotUsedInLaunching(testCases?: TestCase[]): TestCase[] | undefined {
  return testCases?.map(testCase => ({ name: testCase.name, fields: testCase.fields }));
}

export default TestsListItem;
