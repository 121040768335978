import React from "react";
import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";
import { UseCaseCard } from "./use_case_card";
import releaseIcon from "./release_icon.svg";

const USE_CASE_REASONS = [
  "Confirm existing functionality still works as expected",
  "Identify failing test cases",
  "Identify unexpected bugs",
  "Identify usability issues",
  "Confirm the feature matches the design",
];

type ReleaseCardProps = {
  onButtonClick: (sdlcStageCode: SoftwareDevelopmentLifeCycleStageName) => void;
};

export function ReleaseCard({ onButtonClick }: ReleaseCardProps): JSX.Element {
  return (
    <UseCaseCard
      icon={releaseIcon}
      title="Release"
      stageCode={"release" as SoftwareDevelopmentLifeCycleStageName}
      description="All planned changes are done and you are preparing a new version for release"
      useCaseReasons={USE_CASE_REASONS}
      onButtonClick={onButtonClick}
    />
  );
}
