import React from "react";
import classnames from "classnames";

import { FlexibleTestingIssueTag } from "types/graphql";

type FullActiveTagsListProps = {
  tags: FlexibleTestingIssueTag[];
  onClick: () => void;
  classNames: string;
};

const FullActiveTagsList = ({ tags, onClick, classNames }: FullActiveTagsListProps) => (
  <div className="cursor-pointer flex flex-wrap gap-sm" onClick={onClick}>
    {tags.map(tag => (
      <span key={tag.id} className={classnames(classNames, "flex flex-nowrap")} data-testid="current-issue-tag">
        {tag?.name}
      </span>
    ))}
    <i data-testid="add-issue-tag" className={classnames(classNames, "fa fa-tag")} />
  </div>
);

export default FullActiveTagsList;
