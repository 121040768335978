import { useQuery } from "@apollo/client";
import { OrganizationUser, OrganizationUserConnection } from "types/graphql";

// @ts-ignore
import { GET_ORGANIZATION_USERS } from "components/shared/queries/organization_users_query";

type OrganizationUsersList = {
  organizationUsers: OrganizationUserConnection;
};

type OrganizationUsersListResponse = {
  isLoading: boolean;
  data: OrganizationUser[];
};

function useOrganizationUsersList(): OrganizationUsersListResponse {
  const { data, loading } = useQuery<OrganizationUsersList>(GET_ORGANIZATION_USERS);

  const organizationUsers = data?.organizationUsers?.edges.map(edge => edge.node) || [];

  return { isLoading: loading, data: organizationUsers };
}

export default useOrganizationUsersList;
