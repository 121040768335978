import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingAutoExportToggleInput, FlexibleTestingAutoExportTogglePayload } from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

export const TOGGLE_AUTO_EXPORT_SETTINGS = gql`
  mutation FlexibleTestingAutoExportToggle($input: FlexibleTestingAutoExportToggleInput!) {
    flexibleTestingAutoExportToggle(input: $input) {
      successful
      result {
        id
        enabled
      }
      errors {
        code
        message
      }
    }
  }
`;

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with saving auto-export settings",
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Auto-export settings has been successfully saved.",
  });
};

type useToggleAutoExportSettingsInterface = {
  toggleAutoExportSettings: () => Promise<void>;
};

export const useToggleAutoExportSettings = (): useToggleAutoExportSettingsInterface => {
  const [toggleAutoExportSettings] = useMutation<
    { flexibleTestingAutoExportToggle: FlexibleTestingAutoExportTogglePayload },
    { input: FlexibleTestingAutoExportToggleInput }
  >(TOGGLE_AUTO_EXPORT_SETTINGS, {
    onCompleted: ({ flexibleTestingAutoExportToggle }) => {
      if (flexibleTestingAutoExportToggle?.successful) {
        displaySuccess();
      } else {
        displayError();
      }
    },
    onError: displayError,
  });

  return {
    toggleAutoExportSettings: async (): Promise<void> => {
      await toggleAutoExportSettings({ variables: { input: {} } });
    },
  };
};
