import React from "react";
import { Heading } from "@hopper/heading";

import { PageTemplate } from "components/flexible_testing/shared/page_template";
import AdvancedTestForm from "../advanced_test_content/advanced_test_form";
import { PreviewPageAdvancedTestRequest } from "components/flexible_testing/preview_page/preview_page";

type CustomTestRequestContentProps = {
  testRequest?: PreviewPageAdvancedTestRequest;
};

const CustomTestRequestContent = ({ testRequest }: CustomTestRequestContentProps) => (
  <PageTemplate header={<Heading size={1}>Launch</Heading>}>
    <div className="mb-2xl">
      <Heading size={5}>Create your own test</Heading>
    </div>
    <AdvancedTestForm testRequest={testRequest} />
  </PageTemplate>
);

export default CustomTestRequestContent;
