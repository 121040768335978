import React from "react";
import { PreviewPageStandardTestRequest, PreviewPageAdvancedTestRequest } from "../preview_page";
import AdvancedTestContent from "./advanced_test_content/advanced_test_content";
import StandardTestContent from "./standard_test_content/standard_test_content";

type ContentProps = {
  testRequest: PreviewPageStandardTestRequest | PreviewPageAdvancedTestRequest;
  handleEditTest: () => void;
  formattedTestLaunchDateTime: string;
};

const tests = {
  standard_test: StandardTestContent,
  advanced_test: AdvancedTestContent,
};

function Content({ testRequest, handleEditTest, formattedTestLaunchDateTime }: ContentProps): JSX.Element {
  const PreviewContent = tests[testRequest.testType];

  if (PreviewContent === undefined) {
    console.error("PreviewContent is not available:", testRequest.testType);
  }

  return (
    <PreviewContent
      // @ts-ignore
      testRequest={testRequest}
      handleEditTest={handleEditTest}
      formattedTestLaunchDateTime={formattedTestLaunchDateTime}
    />
  );
}

export default Content;
