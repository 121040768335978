import React from "react";
import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";
import { UseCaseCard } from "./use_case_card";
import liveIcon from "./live_icon.svg";

const USE_CASE_REASONS = [
  "Identify unexpected bugs",
  "Identify usability issues",
  "Localize to a specific country or region",
  "Identify language issues",
];

type LiveCardProps = {
  onButtonClick: (sdlcStageCode: SoftwareDevelopmentLifeCycleStageName) => void;
};

export function LiveCard({ onButtonClick }: LiveCardProps): JSX.Element {
  return (
    <UseCaseCard
      icon={liveIcon}
      title="Live"
      stageCode={"live" as SoftwareDevelopmentLifeCycleStageName}
      description="Your app is already live"
      useCaseReasons={USE_CASE_REASONS}
      onButtonClick={onButtonClick}
    />
  );
}
