import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { AdvancedTestRequest, AdvancedTestRequestConnection } from "types/graphql";
import { useEffect, useState } from "react";
import { Test } from "../tests_list/use_tests";
// @ts-ignore
import { datetimeFormat } from "../../../../components/organization/shared_functions/date_format";

type AdvancedTestsResponse = {
  advancedTestRequests: AdvancedTestRequestConnection;
};

export const ADVANCED_TESTS_QUERY = gql`
  query AdvancedTestRequests($cursor: String) {
    advancedTestRequests(first: 10, after: $cursor) {
      edges {
        node {
          id
          name
          code
          accessToApplication
          testObjective
          notes
          testCasesUrl
          platformsToTestOn
          deviceRequirements
          knownIssuesUrl
          testLocalisation
          capabilities
          sdlcStage
          desiredOutcomes {
            code
          }
          customDesiredOutcomes
          testWindow {
            status
            closedAt
          }
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

function useAdvancedTests() {
  const { data, fetchMore, networkStatus } = useQuery<AdvancedTestsResponse>(ADVANCED_TESTS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });
  const [cursor, setCursor] = useState("");

  useEffect(() => {
    setCursor(data?.advancedTestRequests?.pageInfo.endCursor || "");
  }, [data]);

  const tests = extractTests(data);

  return {
    isLoading: networkStatus === NetworkStatus.loading,
    isLoadingNextPage: networkStatus === NetworkStatus.fetchMore,
    tests,
    hasNextPage: data?.advancedTestRequests?.pageInfo.hasNextPage,
    cursor: data?.advancedTestRequests?.pageInfo.endCursor,
    loadNextPage: async () =>
      fetchMore({
        variables: { cursor },
        updateQuery: (previousQueryResult, { fetchMoreResult, variables }): AdvancedTestsResponse => ({
          advancedTestRequests: {
            ...fetchMoreResult?.advancedTestRequests,
            nodes: fetchMoreResult?.advancedTestRequests.nodes || [],
            edges: previousQueryResult.advancedTestRequests.edges.concat(
              fetchMoreResult?.advancedTestRequests.edges || [],
            ),
            pageInfo:
              fetchMoreResult?.advancedTestRequests.pageInfo || previousQueryResult.advancedTestRequests.pageInfo,
          },
        }),
      }),
  };
}

function extractTests(data?: AdvancedTestsResponse): Test[] {
  let tests: Test[] = [];

  if (data?.advancedTestRequests) {
    tests = data.advancedTestRequests.edges.map(edge => {
      const accessToApplication = edge.node.accessToApplication || "";
      const testCasesUrl = edge.node.testCasesUrl || "";
      const code = edge.node.code || "";
      const name = edge.node.name;
      const testObjective = edge.node.testObjective;

      return {
        _id: edge.node._id,
        id: edge.node.id,
        code,
        name,
        applicationUrl: accessToApplication,
        testCasesUrl,
        description: testObjective,
        isClosed: edge.node.testWindow?.status === "closed",
        pathForCloning: "/launch?tab=advanced_test",
        date: `${datetimeFormat(edge.node.testWindow?.closedAt, {
          format: "dd' 'LLL' 'yyyy' 'h':'mm' 'a",
          placeholder: " ",
        })}`,
        testDataToClone: getTestDataToClone({
          accessToApplication,
          testObjective,
          name,
          test: edge.node,
          testCasesUrl,
        }),
      };
    });
  }

  return tests;
}

type TestDataToClone = {
  accessToApplication: string;
  testObjective: string;
  name: string;
  testCasesUrl: string;
  test: AdvancedTestRequest;
};

function getTestDataToClone({ accessToApplication, testObjective, name, test, testCasesUrl }: TestDataToClone) {
  return {
    accessToApplication,
    testObjective,
    name,
    notes: test.notes,
    platformsToTestOn: test.platformsToTestOn || "",
    deviceRequirements: test.deviceRequirements || "",
    testLocalisation: test.testLocalisation || "",
    testCasesUrl,
    knownIssuesUrl: test.knownIssuesUrl || "",
    capabilities: test.capabilities,
    sdlcStage: test.sdlcStage || undefined,
    desiredOutcomes: test.desiredOutcomes.map(desiredOutcome => desiredOutcome.code) || [],
    customDesiredOutcomes: test.customDesiredOutcomes || undefined,
  };
}

export { useAdvancedTests };
