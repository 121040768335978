import React, { useEffect } from "react";
import { useTracking } from "react-tracking";
import { useCurrentUser } from "app/current_user/use_current_user";

type IntercomFailedToLoadEventSenderProps = {
  children: React.ReactNode;
};

/**
 * This is a VERY temporary solution to track our issue with Intercom chat intermittently failing to load.
 * May I be forgiven for this peace of c... ode, it's supposed to be written fast, not beautiful
 */
function IntercomFailedToLoadEventSender({ children }: IntercomFailedToLoadEventSenderProps) {
  const tracking = useTracking();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (!currentUser?.organization.flexibleTestingAccess) {
      return;
    }

    let times = 0;

    const interval = setInterval(() => {
      times++;

      const intercom = document.querySelector(".intercom-lightweight-app");

      if (intercom) {
        clearInterval(interval);
      } else if (times === 5) {
        clearInterval(interval);
        tracking.trackEvent({
          action: "track",
          name: "FlexibleTestingIntercomFailedToLoad",
          eventProperties: {
            organization_id: currentUser.organization.publicId,
          },
        });
      }
    }, 1000);
  }, []);

  return <>{children}</>;
}

export default IntercomFailedToLoadEventSender;
