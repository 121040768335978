import { FetchResult } from "@apollo/client";
import { FlexibleTestingLaunchTestPayload } from "types/graphql";

type Error = {
  code: string;
  message: string;
};

const extractError = (
  response: FetchResult<{ mutation: FlexibleTestingLaunchTestPayload }> | undefined,
): Error | null | undefined => {
  if (response?.errors) {
    return { message: "Unknown error", code: "unknown_error" };
  }
  if (response?.data?.mutation?.errors) {
    return response.data.mutation.errors[0];
  }

  return null;
};

export default extractError;
