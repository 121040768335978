import { format } from "date-fns";
import { isEmpty } from "lodash";

function formatDatetime(datetime: string) {
  if (!isEmpty(datetime)) {
    const date = format(new Date(datetime), "LLLL' 'dd', 'yyyy");
    const time = format(new Date(datetime), "HH':'mm");

    return date + " at " + time;
  } else {
    return "";
  }
}

export default formatDatetime;
