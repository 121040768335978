import React, { useState } from "react";
import { Label } from "@hopper/label";
import { FlexibleTestingTestCaseResult } from "components/flexible_testing/test_case_result_page/use_test_case_result";
import UndoTestCaseResultRejectionPopup from "./undo_test_case_result_rejection_popup";
import { useUnrejectTestCaseResult } from "./use_unreject_test_case_result";

type TestCaseResponseRejectionReasonLabelProps = {
  caseResult: FlexibleTestingTestCaseResult;
};

const TestCaseResponseRejectionReasonLabel = ({ caseResult }: TestCaseResponseRejectionReasonLabelProps) => {
  const [isUndoCaseResultRejectionPopupOpen, setUndoCaseResultRejectionPopupOpen] = useState(false);
  const { unrejectTestCaseResult } = useUnrejectTestCaseResult();

  function onButtonClick() {
    unrejectTestCaseResult(caseResult.testCase.id, caseResult.id);
    setUndoCaseResultRejectionPopupOpen(false);
  }

  return (
    <UndoTestCaseResultRejectionPopup
      isOpen={isUndoCaseResultRejectionPopupOpen}
      onExtenalElementClick={() => setUndoCaseResultRejectionPopupOpen(false)}
      onUndoButtonClick={onButtonClick}
      onLabelClick={() => setUndoCaseResultRejectionPopupOpen(!isUndoCaseResultRejectionPopupOpen)}
      caseResultId={caseResult.id}
    >
      <Label>
        <span className="uppercase tracking-extra-wide">Rejected: {caseResult.rejectionReason?.substring(0, 25)}</span>
      </Label>
    </UndoTestCaseResultRejectionPopup>
  );
};

export default TestCaseResponseRejectionReasonLabel;
