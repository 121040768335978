import { useState, useEffect } from "react";
import { featureFlags } from "./";

export default function useFeatureFlag(name, defaultValue) {
  const [variation, setVariation] = useState(defaultValue);

  useEffect(() => {
    Promise.resolve(featureFlags.variation(name, defaultValue)).then(variation => setVariation(variation));
  });

  return variation;
}
