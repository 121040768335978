import React, { useRef, useState } from "react";
import { FlexibleTestingIssue, IssueSeverity } from "types/graphql";
import { Button } from "@hopper/button";
import { useIssueAccept } from "components/flexible_testing/issue_page/use_issue_accept";
import { useIssueReject } from "components/flexible_testing/issue_page/use_issue_reject";
import AcceptedStatus from "components/flexible_testing/issue_page/issue_triage/accepted_status";
import RejectedStatus from "components/flexible_testing/issue_page/issue_triage/rejected_status";
import Popup from "components/shared/popup";
import { RejectionReasons } from "components/flexible_testing/shared/rejection_reasons";
import { SeverityPopup } from "components/flexible_testing/shared/severity_popup";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";

type IssueTriageProps = {
  issue: FlexibleTestingIssue;
};

const IssueTriage = ({ issue }: IssueTriageProps): JSX.Element => {
  const { triageStatus, triageReason } = issue;
  const isIssueTriaged = triageStatus !== "untriaged";
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState(false);
  const [isSeverityPopupOpen, setIsSeverityPopupOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { acceptIssue } = useIssueAccept(issue.id);
  const { rejectIssue } = useIssueReject(issue.id);

  const handleOutsideClick = () => setIsRejectPopupOpen(false);

  useOutsideHandler(wrapperRef, handleOutsideClick);

  const onRejectIssueButtonClick = (rejectionReason: string) => {
    rejectIssue(issue.id, rejectionReason);

    setIsRejectPopupOpen(false);
  };

  const onSeveritySelected = (severity: IssueSeverity) => {
    acceptIssue(issue.id, severity);
    setIsSeverityPopupOpen(false);
  };

  const onAcceptIssueButtonClick = () => {
    if (issue.test?.forceSeverityChangeBeforeAccept) {
      setIsSeverityPopupOpen(!isSeverityPopupOpen);
    } else {
      acceptIssue(issue.id);
    }
  };

  const severityPopupProps = {
    onSeveritySelected,
    isOpen: isSeverityPopupOpen,
    onExtenalElementClick: () => setIsSeverityPopupOpen(false),
    label: "Select a severity level",
  };

  const issueStatus = () => (
    <div data-testid="triaged-status">
      {triageStatus === "accepted" ? (
        <AcceptedStatus
          isRejectPopupOpen={isRejectPopupOpen}
          setIsRejectPopupOpen={setIsRejectPopupOpen}
          rejectIssue={onRejectIssueButtonClick}
        />
      ) : (
        <SeverityPopup {...severityPopupProps}>
          <RejectedStatus acceptIssue={onAcceptIssueButtonClick} triageReason={triageReason} />
        </SeverityPopup>
      )}
    </div>
  );

  const issueRejectElement = () => (
    <div
      className="mt-sm border border-muted shadow rounded-base bg-lightest z-10"
      style={{ width: "170px" }}
      data-testid="options-box"
    >
      <RejectionReasons rejectIssue={onRejectIssueButtonClick} />
    </div>
  );

  const issueStatusUpdateButtons = () => (
    <div className="flex">
      <SeverityPopup {...severityPopupProps}>
        <Button
          className="mr-md"
          variant="primary"
          onClick={onAcceptIssueButtonClick}
          data-testid="issue-accept-button"
        >
          Accept
        </Button>
      </SeverityPopup>
      <div ref={wrapperRef}>
        <Popup popupElement={issueRejectElement()} isOpen={isRejectPopupOpen} offset={[0, 0]}>
          <Button
            className="cursor-pointer"
            variant="primary"
            onClick={() => {
              setIsRejectPopupOpen(!isRejectPopupOpen);
            }}
            data-testid="issue-reject-button"
          >
            Reject
          </Button>
        </Popup>
      </div>
    </div>
  );

  return isIssueTriaged ? issueStatus() : issueStatusUpdateButtons();
};

export default IssueTriage;
