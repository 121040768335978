import React from "react";
import { TestCaseFields } from "components/flexible_testing/test_case_result_page/use_test_case_result";
import LiveTestInfo from "./live_test_info";
import FinishedTestInfo from "./finished_test_info";
import FieldsList from "./fields_list";
import { Heading } from "@hopper/heading";
import { DecoratedText } from "@hopper/decorated_text";

type CaseWithoutResultsProps = {
  fields: TestCaseFields;
  testWindowClosedAt: string;
  prerequisites?: string | null;
};

const CaseWithoutResults = ({ fields, testWindowClosedAt, prerequisites }: CaseWithoutResultsProps) => (
  <div className="grid grid-cols-1 gap-x-3xl md:grid-cols-12">
    <div className="col-span-5">{testWindowClosedAt ? <FinishedTestInfo /> : <LiveTestInfo />}</div>
    <div className="col-end-12 col-span-5">
      {prerequisites && (
        <div className="my-3xl" style={{ clear: "both" }} data-testid="test-case-prerequisites">
          <Heading size={5}>Prerequisites</Heading>
          <DecoratedText text={prerequisites} />
        </div>
      )}
      <FieldsList fields={fields} />
    </div>
  </div>
);

export default CaseWithoutResults;
