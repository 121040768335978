import React from "react";

type Props = {
  label?: string;
  required?: boolean;
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
};

function FormLabel({ label, required = false, labelProps }: Props): JSX.Element | null {
  if (!label) {
    return null;
  }

  return (
    <label {...labelProps}>
      {label}
      {required && <span className="text-danger">*</span>}
    </label>
  );
}

export default FormLabel;
