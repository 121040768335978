import { useMutation } from "@apollo/client";

import { OrganizationUserUpdateInput, OrganizationUserUpdatePayload } from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { UPDATE_CURRENT_ORGANIZATION_USER } from "components/shared/queries/organization_users_query";

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with saving your details.",
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Personal details have been successfully saved.",
  });
};

const useUpdateCurrentOrganizationUser = () => {
  const [updateCurrentOrganizationUser, { loading }] = useMutation<
    { organizationUserUpdate: OrganizationUserUpdatePayload },
    { input: OrganizationUserUpdateInput }
  >(UPDATE_CURRENT_ORGANIZATION_USER, {
    onCompleted: displaySuccess,
    onError: displayError,
  });

  return {
    isLoading: loading,
    updateCurrentOrganizationUser: async (
      firstName: string,
      lastName: string,
      organizationUserId: string,
    ): Promise<void> => {
      await updateCurrentOrganizationUser({
        variables: { input: { firstName, lastName, organizationUserId } },
      });
    },
  };
};

export default useUpdateCurrentOrganizationUser;
