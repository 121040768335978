import React from "react";
import { Heading } from "@hopper/heading";

import { BackToPreviousPageLink } from "components/flexible_testing/shared/back_to_previous_page";
import { websiteContentPaths, Events } from "components/configuration";
import { PageTemplate } from "../shared/page_template";
import { TestsList } from "./tests_list";
import { useAdvancedTests } from "components/flexible_testing/tests_for_cloning_page/tests_list/use_advanced_tests";

function AdvancedTestForCloningPage(): JSX.Element {
  const { isLoading, tests, hasNextPage, loadNextPage, isLoadingNextPage } = useAdvancedTests();

  return (
    <div data-testid="tests-for-cloning-page">
      <PageTemplate
        backButton={<BackToPreviousPageLink to={websiteContentPaths.advancedTestLaunch}>Back</BackToPreviousPageLink>}
        header={<Heading size={1}>Clone a Test Run</Heading>}
      >
        <TestsList
          isLoading={isLoading}
          tests={tests}
          hasNextPage={hasNextPage}
          loadNextPage={loadNextPage}
          isLoadingNextPage={isLoadingNextPage}
          trackingEventName={Events.ADVANCED_TEST_CLONED}
        />
      </PageTemplate>
    </div>
  );
}

export default AdvancedTestForCloningPage;
