type StatusScore = {
  [key: string]: number;
};

function sortByStatus(firstStatus: string, secondStatus: string): number {
  const values: StatusScore = {
    fail: 1,
    blocked: 2,
    pass: 3,
    live: 4,
    not_tested: 5,
  };
  const firstValue = values[firstStatus] || 6;
  const secondValue = values[secondStatus] || 6;

  if (firstValue < secondValue) {
    return -1;
  }

  if (firstValue > secondValue) {
    return 1;
  }

  return 0;
}

export default sortByStatus;
