import React from "react";

type TestingServiceCardPricingProps = {
  children: React.ReactNode;
};

export function TestingServiceCardPricing({ children }: TestingServiceCardPricingProps): JSX.Element {
  return (
    <div className="pt-2xl">
      <div className="font-bold uppercase tracking-extra-wide text-secondary text-xs">Pricing</div>
      {children}
    </div>
  );
}
