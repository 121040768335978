import { gql, useQuery } from "@apollo/client";
import { AdvancedTestRequestConnection } from "types/graphql";

type PreviousTestsAvailable = {
  isLoading: boolean;
  hasNoPreviousTests: boolean;
};

export const PREVIOUS_ADVANCED_TEST_AVAILABLE_QUERY = gql`
  query AdvancedTestRequests {
    advancedTestRequests(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

function usePreviousAdvancedTestAvailable(): PreviousTestsAvailable {
  const { loading, data } = useQuery<{ advancedTestRequests?: AdvancedTestRequestConnection }>(
    PREVIOUS_ADVANCED_TEST_AVAILABLE_QUERY,
  );

  return {
    isLoading: loading,
    hasNoPreviousTests: !data?.advancedTestRequests?.edges.length,
  };
}

export default usePreviousAdvancedTestAvailable;
