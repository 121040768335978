import React from "react";

import warningIcon from "../../../../../../assets/images/icon--warning.svg";

export const FailureEmoji = (): JSX.Element => (
  <span role="emoji" aria-label="failure" className="mr-sm" data-testid="failure-emoji">
    ❌
  </span>
);

export const WarningEmoji = (): JSX.Element => (
  <img src={warningIcon} className="w-2xl float-left mr-sm" data-testid="warning-emoji" />
);
