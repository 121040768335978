import { Heading } from "@hopper/heading";
import { Button } from "@hopper/button";
import React from "react";

type SuccessMessageProps = {
  onCloseModalClicked?: () => void;
  formattedTestLaunchDateTime: string | null;
  resultsUrl: string;
};

function SuccessMessage({ onCloseModalClicked, formattedTestLaunchDateTime, resultsUrl }: SuccessMessageProps) {
  return (
    <div className="flex flex-col items-center justify-center max-w-xs sm:max-w-full text-center">
      <div
        className="rounded-full flex items-center justify-center mb-2xl mt-6xl"
        style={{ backgroundColor: "rgba(64, 189, 166, 0.12)", height: "4.5rem", width: "4.5rem" }}
        data-testid="success-icon"
      >
        <i className="fa fa-check text-success opacity-100 text-3xl" aria-hidden="true" />
      </div>
      <div className="mb-xs">
        <Heading size={3}>We have received your test request</Heading>
      </div>
      <Heading size={4}>We will review your request now and contact you shortly</Heading>
      <Heading size={4}>
        If any required information is missing from the request we will ask you to provide it before we begin preparing
        your test
      </Heading>
      {formattedTestLaunchDateTime ? (
        <Button variant="secondary" onClick={onCloseModalClicked} className="mt-md">
          Close
        </Button>
      ) : (
        <Button appearance="minimal" href={resultsUrl}>
          View test results
        </Button>
      )}
    </div>
  );
}

export default SuccessMessage;
