import React from "react";
import { Checkbox } from "@hopper/checkbox";
import { FormField } from "@hopper/form_field";
import { Textarea } from "@hopper/textarea";

type CustomDesiredOutcomeProps = {
  isChecked: boolean;
  onCheckboxChange: () => void;
  onValueChange: (value: string) => void;
  value: string;
};

export function CustomDesiredOutcome({
  isChecked,
  onCheckboxChange,
  onValueChange,
  value,
}: CustomDesiredOutcomeProps): JSX.Element {
  return (
    <>
      <Checkbox
        data-testid="custom-desired-outcome-checkbox"
        isChecked={isChecked}
        label="Something else"
        onChange={onCheckboxChange}
      />
      {isChecked && (
        <div className="pl-2xl">
          <FormField label="What would you like to learn from this test?" isOptional={false}>
            <div style={{ minWidth: "24rem" }}>
              <Textarea
                placeholder="Please describe your desired outcome"
                onChange={event => onValueChange(event.target.value)}
              >
                {value}
              </Textarea>
            </div>
          </FormField>
        </div>
      )}
    </>
  );
}
