import { useQuery } from "@apollo/client";
import { FlexibleTestingIssue, Test } from "types/graphql";
import { GET_ISSUES_TAB_DATA } from "./issues_tab_data_query";

function useIssuesTabData(testId?: string) {
  const { loading, data } = useQuery(GET_ISSUES_TAB_DATA, {
    variables: { testId },
    skip: !testId,
  });

  const platformTest = data?.platformTest;

  return {
    isLoading: loading,
    issues: extractIssues(platformTest),
    testStatus: platformTest?.testWindow?.status,
  };
}

function extractIssues(platformTest?: Test): FlexibleTestingIssue[] {
  return platformTest?.issues?.edges?.map(issue => issue?.node).filter(issue => !!issue) || [];
}

export { useIssuesTabData };
