import React, { useState } from "react";
import Modal from "app/modal";

import { Heading } from "@hopper/heading";
import { Loader } from "@hopper/loading";

import { PageTemplate } from "../shared/page_template";
import { IssuesList } from "./issues_list";
import useIssues, { ISSUE_QUERY } from "./use_issues";
import { Button } from "@hopper/button";
import { Select } from "@hopper/select";
import { FlexibleTestingIssueSort, FlexibleTestingIssueTag, InputMaybe, IssueTriageStatus } from "types/graphql";
import IssueFilters from "../shared/issue_filters";
import useIssueStats from "./use_issue_stats";
import { ExportDestinations } from "../result_details_page/export_destinations";
import pluralize from "pluralize";
import { Helmet } from "react-helmet";

const Header = () => (
  <div data-testid="all-issues-header">
    <Heading size={1}>All Issues</Heading>
  </div>
);

function currentTagsFilter(tags: Array<FlexibleTestingIssueTag>, mode?: string) {
  if (tags.length > 0) {
    const tagIds = tags.map(tag => tag.id);

    return mode === "all" ? { issue_tags_ids_eq: tagIds } : { issue_tags_ids_contains: tagIds };
  }

  return {};
}

function AllIssuesPage(): JSX.Element {
  const [currentStatusFilter, setCurrentStatusFilter] = useState("untriaged");
  const [currentTags, setCurrentTags] = useState<FlexibleTestingIssueTag[]>([]);
  const [currentSort, setCurrentSort] = useState<FlexibleTestingIssueSort[]>([
    { field: "created_at", direction: "desc" },
  ] as FlexibleTestingIssueSort[]);
  const [tagsFilterMode, setTagsFilterMode] = useState<string>("any");
  const [currentTagFilter, setCurrentTagFilter] = useState({});
  const filters = {
    triage_status_eq: currentStatusFilter as InputMaybe<IssueTriageStatus>,
    ...currentTagFilter,
  };
  const { isLoading, issues, hasNextPage, loadNextPage, isLoadingNextPage } = useIssues(filters, currentSort);

  const { issueStats } = useIssueStats(currentTagFilter);
  const [selectedIssuesIds, setSelectedIssuesIds] = useState<string[]>([]);
  const [showExportModal, setShowExportModal] = useState(false);

  const updateSelectedTags = (tags: Array<FlexibleTestingIssueTag>, mode?: string) => {
    setTagsFilterMode(mode || "any");
    setCurrentTags(tags);
    setCurrentTagFilter(currentTagsFilter(tags, mode));
    setSelectedIssuesIds([]);
  };

  function handleSelectSingleIssue(id: string): void {
    if (selectedIssuesIds.includes(id)) {
      setSelectedIssuesIds(prevState => prevState.filter(issueId => issueId !== id));
    } else {
      setSelectedIssuesIds(prevState => prevState.concat(id));
    }
  }

  function handleSuccessfulExport(): void {
    setShowExportModal(false);
    setSelectedIssuesIds([]);
  }

  const exportButtonText = () => {
    if (selectedIssuesIds.length === 0) {
      return "Export issues";
    } else {
      return `Export ${selectedIssuesIds.length} ${pluralize("issue", selectedIssuesIds.length)}`;
    }
  };

  return (
    <>
      <Helmet>
        <title>Issues</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <PageTemplate header={<Header />}>
          <div className=" flex items-center mb-4xl justify-between">
            <div className="flex items-center">
              <IssueFilters
                currentStatusFilter={currentStatusFilter}
                setCurrentStatusFilter={setCurrentStatusFilter}
                currentTags={currentTags}
                updateSelectedTags={updateSelectedTags}
                issues={issues}
                tagsFilterMode={tagsFilterMode}
                newIssuesCount={issueStats?.newIssuesCount || 0}
                acceptedIssuesCount={issueStats?.acceptedIssuesCount || 0}
                rejectedIssuesCount={issueStats?.rejectedIssuesCount || 0}
              />
              <div className="ml-md">
                <Select
                  data-testid="sort-issues-by"
                  onChange={event => {
                    setCurrentSort([
                      { field: "created_at", direction: event.target.value },
                    ] as FlexibleTestingIssueSort[]);
                  }}
                  size="md"
                  options={[
                    { value: "desc", label: "Sort by: Newest first" },
                    { value: "asc", label: "Sort by: Oldest first" },
                  ]}
                />
              </div>
            </div>
            <div className="text-center">
              <Button
                data-testid="export-issues-button"
                variant="primary"
                disabled={selectedIssuesIds.length === 0 || selectedIssuesIds.length > 50}
                onClick={() => setShowExportModal(true)}
              >
                {exportButtonText()}
              </Button>
              <p className="text-sm text-secondary">Max 50 issues at once</p>
            </div>
          </div>
          <IssuesList
            issues={issues || []}
            handleSelectSingle={handleSelectSingleIssue}
            selectedIssues={selectedIssuesIds}
            setSelectedIssuesIds={setSelectedIssuesIds}
          />
          <div className="flex justify-center mt-4xl">
            <Button
              variant="secondary"
              appearance="ghost"
              isLoading={isLoadingNextPage}
              disabled={!hasNextPage}
              onClick={loadNextPage}
            >
              Load more
            </Button>
          </div>
        </PageTemplate>
      )}
      <Modal
        show={showExportModal}
        title="Export selected issue(s)"
        closeModal={() => setShowExportModal(false)}
        visibleOverflowY={true}
      >
        <ExportDestinations
          issuesToExport={selectedIssuesIds}
          onSuccessfulExport={handleSuccessfulExport}
          refetchQuery={{ query: ISSUE_QUERY, variables: { filters, sort: currentSort } }}
        />
      </Modal>
    </>
  );
}

export default AllIssuesPage;
