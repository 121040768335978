import React from "react";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
// @ts-ignore
import { datetimeFormat } from "components/organization/shared_functions/date_format";
import { Test } from "types/graphql";

type TestStatusLabelProps = {
  testWindow: Test["testWindow"];
};

const formatDateTime = (datetime: string): string =>
  datetimeFormat(datetime, { format: "dd' 'LLL' 'yyyy' 'h':'mma' (UTC)" });

const statusMessage = (testWindow: Test["testWindow"]): string => {
  let message = "";

  if (testWindow?.status === "prep") {
    message = "preparing";
  } else if (testWindow?.status === "scheduled") {
    message = `scheduled ${formatDateTime(testWindow.scheduleAt)}`;
  } else if (testWindow?.status === "open" && testWindow?.plannedEndAt) {
    const overdue = new Date(testWindow?.plannedEndAt) < new Date();
    const timeDistance = formatDistanceToNowStrict(parseISO(testWindow.plannedEndAt));

    message = overdue ? `by ${timeDistance}` : `${timeDistance} remaining`;
  } else if (testWindow?.closedAt) {
    message = `Finished ${formatDateTime(testWindow.closedAt)}`;
  } else if (testWindow?.status === "cancelled") {
    message = `Cancelled ${formatDateTime(testWindow.cancelledAt)}`;
  }

  return message;
};

const TestStatusLabel = ({ testWindow }: TestStatusLabelProps): JSX.Element => (
  <span
    className="uppercase text-secondary font-bold tracking-wider inline-block text-xs"
    data-testid="test-status-message"
  >
    {statusMessage(testWindow)}
  </span>
);

export default TestStatusLabel;
