import { gql } from "@apollo/client";

export const SELECTION_REQUIREMENTS_GROUPS_QUERY = gql`
  query getSelectionRequirementsGroups($testId: ID!) {
    selectionRequirementsGroups(filters: { test_id_eq: $testId }) {
      edges {
        node {
          id
          name
          platforms
          numberOfApprovedResults
          numberOfPublishedResults
          numberOfResultsNeeded
          numberOfAssignedCases
          tags
          operatingSystemVersions {
            edges {
              node {
                id
                fullName
              }
            }
          }
          deviceTypes {
            edges {
              node {
                id
                name
              }
            }
          }
          manufacturers {
            edges {
              node {
                id
                name
              }
            }
          }
          mobileDevices {
            edges {
              node {
                id
                name
              }
            }
          }
          operatingSystems {
            edges {
              node {
                id
                name
              }
            }
          }
          internetBrowsers {
            edges {
              node {
                id
                name
              }
            }
          }
          countries {
            edges {
              node {
                id
                name
              }
            }
          }
          languages {
            edges {
              node {
                id
                name
              }
            }
          }
          participants {
            totalCount
            edges {
              node {
                id
                testerId
                status
                fullName
                email
                country {
                  name
                  code
                }
                chatChannels {
                  edges {
                    node {
                      id
                      chatType
                      externalId
                    }
                  }
                }
                approvedTestCasesCount
                lastCaseResultCompletedAt
                joinedAt
                invitedAt
                isActiveInTestCasesExecution
                hasReportedIssue
                numberOfPendingIssueModerations
                resultsPublishable
                moderationStatus
              }
            }
          }
          other
          isFull
          status
        }
      }
    }
  }
`;
