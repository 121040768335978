import style from "components/flexible_testing/shared/tag_cell/style.module.css";
import TagItem from "components/flexible_testing/shared/tag_cell/tag_item";
import { FlexibleTestingIssue, FlexibleTestingIssueTag } from "types/graphql";
import React, { useState } from "react";

import { useCreateIssueTag } from "components/flexible_testing/shared/tag_cell/use_create_issue_tag";
import { Button } from "@hopper/button";

type TagsListProps = {
  activeTags: FlexibleTestingIssueTag[];
  availableTags: Array<FlexibleTestingIssueTag>;
  handleTagsUpdate: (tags: Array<FlexibleTestingIssueTag>, mode?: string) => Promise<void> | void;
  onTagEdit: (tag: FlexibleTestingIssueTag) => void;
  setIsOpen: (fn: (prevValue: boolean) => boolean) => void;
  issues?: Array<FlexibleTestingIssue>;
  isReadOnly: boolean;
  tagsFilterMode?: string;
  withoutCounter?: boolean;
};

type CreateTagTextProps = {
  tagName: string;
  onClick: () => void;
};

const CreateTagText = ({ tagName, onClick }: CreateTagTextProps): JSX.Element => (
  <div
    className="font-bold px-lg my-sm py-sm hover:bg-light cursor-pointer text-sm text-body leading-lg"
    onClick={onClick}
    data-testid="create-tag-text"
  >
    <i className="fa fa-plus mr-sm" />
    Create "{tagName}"
  </div>
);

function TagsList({
  availableTags,
  handleTagsUpdate,
  activeTags,
  onTagEdit,
  setIsOpen,
  isReadOnly,
  issues = [],
  tagsFilterMode,
  withoutCounter,
}: TagsListProps) {
  const [search, setSearch] = useState("");
  const [filterMode, setFilterMode] = useState(tagsFilterMode || "any");
  const { createIssueTag } = useCreateIssueTag();

  const doesMatchSearch = (tag: FlexibleTestingIssueTag) =>
    search.length < 2
      ? tag.name.toLowerCase().startsWith(search.toLowerCase())
      : tag.name.toLowerCase().includes(search.toLowerCase());

  const isActiveTag = (tag: FlexibleTestingIssueTag) => !!activeTags.find(t => t.id === tag.id);
  const issuesWithThisTag = (tag: FlexibleTestingIssueTag): number =>
    issues.filter(issue => issue.issueTags.edges.find(rawTag => rawTag.node.name === tag.name)).length;

  const filteredTags = availableTags.filter(tag => !isActiveTag(tag));
  const displayTags = (isReadOnly ? availableTags : [...activeTags, ...filteredTags]).filter(doesMatchSearch);

  const noTagsInfo = () => (
    <div className="p-xs">
      <div className="font-bold mb-xl">
        No tags have been created yet. Type in the bar above to create your first tag
      </div>
      <div className="mb-xl">Tags can be used across all your test results to help organise and triage issues</div>
    </div>
  );

  const changeFilterMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterMode(e?.target?.value);
    handleTagsUpdate(activeTags, e?.target?.value);
  };

  const handleTagsUpdateWithMode = (tags: Array<FlexibleTestingIssueTag>, mode?: string) =>
    handleTagsUpdate(tags, filterMode);

  const clearAllTags = () => handleTagsUpdate([]);

  return (
    <>
      <div className="px-lg my-sm py-sm text-sm text-body">
        <input
          type="text"
          onChange={e => setSearch(e.target.value)}
          value={search}
          className="w-full border-b border-light py-xs focus:outline-none"
          placeholder={isReadOnly ? "Search existing tags..." : "Search or enter a new tag name..."}
          data-testid="search-tag-input"
        />
        <i
          data-testid="close-tag-option-box"
          className="fa fa-times text-secondary absolute right-sm top-sm"
          onClick={() => setIsOpen(prevValue => !prevValue)}
        />
      </div>
      {isReadOnly && (
        <div className="flex w-full pb-sm items-baseline justify-center" onChange={changeFilterMode}>
          <span className="mr-md text-sm">Match: </span>
          <span className="mr-md text-sm font-normal ">
            <label className="cursor-pointer">
              <input type="radio" value="all" name="searchType" checked={filterMode === "all"} /> All
            </label>
          </span>
          <span className="mr-md text-sm font-normal">
            <label className="cursor-pointer">
              <input type="radio" value="any" name="searchType" checked={filterMode === "any"} /> Any
            </label>
          </span>
          <span>
            <Button variant="danger" size="sm" className="text-xs py-xs px-xl" onClick={clearAllTags}>
              <i className="fa fa-trash" />
              <span className="px-sm">Clear</span>
            </Button>
          </span>
        </div>
      )}
      <div className={`overflow-y-auto mx-md ${style.thinScrollbar}`} style={{ maxHeight: "300px" }}>
        {availableTags.length === 0 && noTagsInfo()}
        {displayTags?.map(tag => (
          <TagItem
            onTagEdit={onTagEdit}
            key={tag.id}
            tag={tag}
            activeTags={activeTags}
            isActive={isActiveTag(tag)}
            handleTagsUpdate={handleTagsUpdateWithMode}
            isReadOnly={isReadOnly}
            tagCount={withoutCounter ? undefined : issuesWithThisTag(tag)}
          />
        ))}
        {search.length > 0 && search.length <= 30 && !isReadOnly && (
          <CreateTagText
            tagName={search}
            onClick={() => {
              createIssueTag(search)
                .then(id => {
                  handleTagsUpdate([...activeTags, { id, name: search } as FlexibleTestingIssueTag]);
                })
                .then(() => {
                  setSearch("");
                });
            }}
          />
        )}
        {search.length > 30 && (
          <div className="text-danger text-sm px-lg my-sm py-sm leading-lg">
            <i className="fa fa-exclamation-circle text-xs mr-xs font-bolder" />
            Tag name cannot be more than 30 characters
          </div>
        )}
      </div>
    </>
  );
}

export default TagsList;
