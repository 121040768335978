import React from "react";
import { FileUpload } from "components/flexible_testing/shared/test_cases/file_upload";
import { CSVBoxConfig } from "components/configuration";
import { StatusBar } from "@hopper/status_bar";
import { TextInput } from "components/flexible_testing/shared/form";
import { useField, useFormikContext } from "formik";
import { TestCase } from "types/test_cases";
import { RequestedTest } from "../requested_test_form";
import { FormInlineError } from "@hopper/form_inline_error";

const TestCaseFields = () => {
  const { values } = useFormikContext<RequestedTest>();
  const [testCases, { touched, error }, { setValue, setTouched }] = useField<Array<TestCase> | null>("testCases");

  const setTestCases = (testCases: TestCase[] | null) => {
    setTouched(true);
    setValue(testCases);
  };

  return (
    <div className="flex flex-col gap-lg">
      <div>
        <FileUpload
          licenseKey={CSVBoxConfig.testCasesLicenseKey}
          uploadedTestCases={testCases.value}
          onTestCasesUpload={setTestCases}
          isAdditionalInformationColumnEnabled={true}
        />
        {touched && error && <FormInlineError>{error}</FormInlineError>}
      </div>
      {testCases?.value === undefined && (
        <div className="flex flex-col gap-lg">
          <div className="relative border border-light bg-light p-lg leading-150">
            <StatusBar position="left" size="sm" />
            To make importing test cases as easy as possible, export them as a <span className="font-bold">.CSV</span>
          </div>
          <div className="relative border border-light bg-light p-lg leading-150">
            <StatusBar position="left" size="sm" />
            Need more information from testers? You can request additional information per test case, such as a
            <span className="font-bold"> Transaction ID.</span>
            <div className="mt-sm">
              <a
                target="_blank"
                href="https://help.globalapptesting.com/en/articles/9015165-requesting-additional-information-from-testers"
                className="text-link underline "
              >
                Learn how
              </a>
            </div>
          </div>
        </div>
      )}
      <TextInput
        name="numberOfResultsPerTestCaseNeeded"
        value={values.numberOfResultsPerTestCaseNeeded}
        label="How many results do you need for each test case?"
        labelClass="font-normal"
        required
      />
    </div>
  );
};

export default TestCaseFields;
