import React, { useState, useRef } from "react";
import * as yup from "yup";
import { DecoratedText } from "@hopper/decorated_text";
// @ts-ignore
import SweetAlert from "sweetalert-react";

import { FlexibleTestingIssue } from "types/graphql";
import { ClearButton, Form, SubmitButton, TextArea } from "components/flexible_testing/shared/form";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import Popup from "components/shared/popup";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { useIssueNoteCreate } from "./use_issue_note_create";
import { useIssueNoteUpdate } from "./use_issue_note_update";
import { useIssueNoteDestroy } from "./use_issue_note_destroy";
import IssueNoteFooter from "./issue_note_footer";

type AddIssueNoteButtonProps = {
  popupElement: JSX.Element;
  isNotePopupOpened: boolean;
  onAddButtonClick: () => void;
};

type ConfirmationModalProps = {
  isConfirmationModalOpen: boolean;
  onConfirmButtonClick: () => void;
  onCancelButtonClick: () => void;
};

type IssueNoteProps = {
  issue: FlexibleTestingIssue;
};

const IssueNote = ({ issue }: IssueNoteProps): JSX.Element => {
  const { id: issueId, issueNote } = issue;
  const notePopupWrapperRef = useRef(null);
  const [isNotePopupOpened, setIsNotePopupOpened] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { updateIssueNote } = useIssueNoteUpdate();
  const { createIssueNote } = useIssueNoteCreate();
  const { destroyIssueNote } = useIssueNoteDestroy();

  useOutsideHandler(notePopupWrapperRef, () => setIsNotePopupOpened(false));

  const updateNote = (note: string): Promise<void> => {
    setIsNotePopupOpened(!isNotePopupOpened);

    return updateIssueNote(issueId, note);
  };

  const createNote = (note: string): Promise<void> => {
    setIsNotePopupOpened(!isNotePopupOpened);

    return createIssueNote(issueId, note);
  };

  const onDeleteButtonClick = () => setIsConfirmationModalOpen(!isConfirmationModalOpen);
  const onEditButtonClick = () => setIsNotePopupOpened(!isNotePopupOpened);
  const onDeleteCancelButtonClick = () => setIsConfirmationModalOpen(false);
  const onAddButtonClick = () => setIsNotePopupOpened(!isNotePopupOpened);
  const onDeleteConfirmButtonClick = () => {
    destroyIssueNote(issueId);
    setIsNotePopupOpened(false);
    setIsConfirmationModalOpen(false);
  };

  const actionButtons = (
    <div className="text-right" ref={notePopupWrapperRef}>
      <ConfirmationModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        onConfirmButtonClick={onDeleteConfirmButtonClick}
        onCancelButtonClick={onDeleteCancelButtonClick}
      />
      <Popup
        popupElement={issueNotePopup(updateNote, issueNote?.note)}
        isOpen={isNotePopupOpened}
        placement="bottom-start"
        offset={[-300, 0]}
      >
        <div>
          <EditButton onButtonClick={onEditButtonClick} />
          {" | "}
          <DeleteButton onButtonClick={onDeleteButtonClick} />
        </div>
      </Popup>
    </div>
  );

  if (issueNote) {
    return (
      <div className="px-0 mb-lg">
        <DetailsField label="Triaging note" testId="issue-note-label" paddingClass="px-0" rightContent={actionButtons}>
          <span data-testid="issue-note-content">
            <DecoratedText text={issueNote.note} />
          </span>
          <IssueNoteFooter issueNote={issueNote} />
        </DetailsField>
      </div>
    );
  } else {
    return (
      <div className="mb-lg flex" ref={notePopupWrapperRef}>
        <AddIssueNoteButton
          popupElement={issueNotePopup(createNote)}
          isNotePopupOpened={isNotePopupOpened}
          onAddButtonClick={onAddButtonClick}
        />
      </div>
    );
  }
};

const AddIssueNoteButton = ({
  popupElement,
  isNotePopupOpened,
  onAddButtonClick,
}: AddIssueNoteButtonProps): JSX.Element => (
  <Popup popupElement={popupElement} isOpen={isNotePopupOpened} placement="bottom-start" offset={[0, 0]}>
    <button
      onClick={onAddButtonClick}
      className="font-bold rounded-base inline-flex items-center relative text-link focus:outline-none cursor-pointer h-4xl"
      data-testid="add-triage-note"
    >
      Add a triage note
    </button>
  </Popup>
);

const ConfirmationModal = ({
  isConfirmationModalOpen,
  onConfirmButtonClick,
  onCancelButtonClick,
}: ConfirmationModalProps): JSX.Element => (
  <SweetAlert
    show={isConfirmationModalOpen}
    title="Delete triaging note"
    type="info"
    showCancelButton={true}
    confirmButtonColor="#EC6B58"
    confirmButtonText="Yes, delete it!"
    text="Are you sure you want to delete the triaging note? This will delete the note for all users in your organization."
    onConfirm={onConfirmButtonClick}
    onCancel={onCancelButtonClick}
  />
);

const EditButton = ({ onButtonClick }: { onButtonClick: () => void }): JSX.Element => (
  <span className="text-link focus:outline-none cursor-pointer" onClick={onButtonClick} data-testid="edit-issue-note">
    Edit
  </span>
);

const DeleteButton = ({ onButtonClick }: { onButtonClick: () => void }): JSX.Element => (
  <span
    className="text-danger focus:outline-none cursor-pointer"
    onClick={onButtonClick}
    data-testid="delete-issue-note"
  >
    Delete
  </span>
);

function issueNotePopup(onSaveButtonClick: (note: string) => Promise<void>, issueNote?: string | undefined) {
  const handleSubmit = async (values: { [field: string]: unknown }): Promise<void> => {
    await onSaveButtonClick(values.note as string);
  };

  return (
    <div className="mt-sm p-lg border border-muted shadow rounded-base bg-lightest z-10">
      <Form
        data-testid="issue-note-form"
        initialValues={{
          note: issueNote || "",
        }}
        onSubmit={handleSubmit}
        validations={yup.object({
          note: yup.string().required(),
        })}
      >
        <div className="text-xs">
          <TextArea
            data-testid="issue-note-input"
            name="note"
            placeholder="Add a note here (your note will be visible to anyone viewing this issue)"
            required={true}
            useHopperInput
          />
        </div>
        <SubmitButton data-testid="save-issue-note" className="mr-md" useHopperButton style={{ pointerEvents: "auto" }}>
          Save
        </SubmitButton>
        <ClearButton>Clear</ClearButton>
      </Form>
    </div>
  );
}

export default IssueNote;
