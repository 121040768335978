import React from "react";
import classnames from "classnames";

export const statuses = ["pass", "fail", "blocked", "not_tested", "live"] as const;

type StatusIndicatorProps = {
  status: typeof statuses[number];
  message?: string | number;
};

export const optionVariations = {
  pass: { defaultMessage: "Passed", color: "success", bold: false },
  fail: { defaultMessage: "Failed", color: "danger", bold: true },
  blocked: { defaultMessage: "Blocked", color: "warning", bold: true },
  not_tested: { defaultMessage: "Not tested", color: "secondary", bold: false },
  live: { defaultMessage: "Live", color: "primary", bold: false },
  rejected: { defaultMessage: "Rejected", color: "muted", bold: false },
  skipped: { defaultMessage: "Skipped", color: "muted", bold: false },
  missing: { defaultMessage: "Missing", color: "muted", bold: false },
};

function StatusIndicator({ status, message }: StatusIndicatorProps): JSX.Element | null {
  const statusOptions = optionVariations[status];

  return statusOptions ? (
    <div
      className={classnames("flex items-center", !message && statusOptions.bold && "font-bold")}
      data-testid="status-indicator"
    >
      <i
        className={classnames("fa fa-circle mr-sm text-2xs", `text-${statusOptions.color}`)}
        data-testid="status-icon"
      />
      {message ?? statusOptions.defaultMessage}
    </div>
  ) : null;
}

export default StatusIndicator;
