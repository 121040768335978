import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ResultSectionsList } from "./result_sections_list";
import { websiteContentPaths } from "components/configuration";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import Authorization from "app/authorization/authorization";

import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";
import { Helmet } from "react-helmet";

const AutoExportButton = (): JSX.Element => (
  <Link to={websiteContentPaths.flexcaseAutoExportSettings}>
    <Button data-testid="auto-export-settings" variant="secondary">
      Auto-export settings
    </Button>
  </Link>
);

function scrolledFarDown() {
  // Checks if user scrolled more than 4 times the height of the window
  return window.scrollY > 4 * window.innerHeight;
}

function ResultsPage(): JSX.Element {
  const [backToTop, setBackToTop] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      if (scrolledFarDown()) {
        setBackToTop(true);
      } else {
        setBackToTop(false);
      }
    };
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Results</title>
      </Helmet>
      <div data-testid="results-overview-page">
        <PageTemplate
          header={
            <div className="flex justify-between items-center">
              <Heading size={1}>Results</Heading>
              <Authorization roles={["testManager", "customerUser"]}>
                <AutoExportButton />
              </Authorization>
            </div>
          }
        >
          <ResultSectionsList />
          {backToTop && (
            <a
              className="fixed text-dark"
              href="#"
              style={{ bottom: "50px", right: "100px" }}
              onClick={e => {
                e.preventDefault();
                window.scrollTo({ top: 0 });
              }}
            >
              <i className="fa fa-chevron-up mr-md" />
              BACK TO TOP
            </a>
          )}
        </PageTemplate>
      </div>
    </>
  );
}

export default ResultsPage;
