import React from "react";
import * as yup from "yup";
import { Card } from "@hopper/card";
import { FormField } from "@hopper/form_field";
import { Heading } from "@hopper/heading";
import { useQuery } from "@apollo/client";

// @ts-ignore
import inEU from "@segment/in-eu";
import { openConsentManager } from "@segment/consent-manager";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { Form, TextInput, ResetButton, SubmitButton } from "components/flexible_testing/shared/form";
import { GET_CURRENT_ORGANIZATION_USER } from "components/shared/queries/organization_users_query";

import useUpdateCurrentOrganizationUser from "./use_update_current_organization_user";

const ProfilePage = () => {
  const { data, loading } = useQuery(GET_CURRENT_ORGANIZATION_USER, { fetchPolicy: "no-cache" });
  const { updateCurrentOrganizationUser, isLoading } = useUpdateCurrentOrganizationUser();
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();

    openConsentManager();
  };
  const handleSubmit = (values: { [field: string]: unknown }): Promise<unknown> =>
    updateCurrentOrganizationUser(
      values.firstName as string,
      values.lastName as string,
      data.currentOrganizationUser.id as string,
    );

  const header = (
    <div data-testid="profile-header">
      <Heading size={1}>Profile</Heading>
    </div>
  );

  return (
    <PageTemplate header={header}>
      <Card>
        <div data-testid="profile-form" className="p-3xl grid grid-cols-1 md:grid-cols-2">
          {!loading && (
            <>
              <PageSection orderNumber={1} title="Your details">
                <Form
                  data-testid="personal-details-form"
                  initialValues={{
                    firstName: data.currentOrganizationUser.firstName,
                    lastName: data.currentOrganizationUser.lastName,
                  }}
                  onSubmit={handleSubmit}
                  validations={yup.object().shape({
                    firstName: yup
                      .string()
                      .required("Your first name cannot be blank")
                      .max(255, "First name is too long (maximum is 255 characters)"),
                    lastName: yup
                      .string()
                      .required("Your last name cannot be blank")
                      .max(255, "Last name is too long (maximum is 255 characters)"),
                  })}
                >
                  <div className="row">
                    <div className="pl-lg">
                      <TextInput data-testid="first-name-field" name="firstName" label="First name" required={false} />
                      <div className="mt-xl">
                        <TextInput data-testid="last-name-field" name="lastName" label="Last name" required={false} />
                      </div>
                    </div>
                  </div>
                  <div className="mr-lg inline-block">
                    <SubmitButton
                      data-testid="submit-personal-details"
                      className="mt-xl"
                      useHopperButton={true}
                      loading={isLoading}
                    >
                      Save
                    </SubmitButton>
                  </div>
                  <div className="inline-block">
                    <ResetButton>Cancel</ResetButton>
                  </div>
                </Form>
              </PageSection>
              <PageSection orderNumber={2} title="Your preferences">
                {inEU() && (
                  <FormField label="Cookie preferences" isOptional>
                    <a href="#" className="text-link underline text-sm" onClick={handleClick}>
                      Review your cookie preferences
                    </a>
                  </FormField>
                )}
              </PageSection>
            </>
          )}
        </div>
      </Card>
    </PageTemplate>
  );
};

export default ProfilePage;
