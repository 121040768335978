import { Button } from "@hopper/button";
import { PageSection } from "components/flexible_testing/shared/page_section";
import React from "react";

function CliDownload(): JSX.Element {
  return (
    <PageSection className="mt-5xl" title="Use our CLI">
      <Button
        data-testid="download-cli-button"
        className="flex-none text-xs"
        size="sm"
        href="https://assets.globalapptesting.com/cli/release/v0.2.0/gat-cli-Linux-x86_64.tar.gz"
      >
        Download the CLI
      </Button>
    </PageSection>
  );
}

export default CliDownload;
