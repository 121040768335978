import React, { useRef } from "react";
import { PopupMenu } from "@hopper/popup_menu";
import Popup from "components/shared/popup";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";

import { IssueSeverity, IssueSeverityOption } from "types/graphql";
import SeverityPopupItems from "./severity_popup_items";
import { useIssueSeverities } from "./use_issue_severities";

type SeverityPopupProps = {
  currentSeverity?: IssueSeverity;
  label?: string;
  isOpen: boolean;
  onSeveritySelected: (fn: IssueSeverity) => void;
  onExtenalElementClick: () => void;
  onPopupClick?: () => void;
  children: React.ReactNode;
};

type SeverityPopupContentProps = {
  currentSeverity?: IssueSeverity;
  label?: string;
  onSeveritySelected: (fn: IssueSeverity) => void;
  issueSeverities: Array<IssueSeverityOption>;
};

const SeverityPopupContent = ({
  currentSeverity,
  onSeveritySelected,
  label,
  issueSeverities,
}: SeverityPopupContentProps) => (
  <div data-testid="severity-option-box">
    <PopupMenu>
      <div
        className="text-sm font-bold my-sm py-sm text-xs text-dark
        border-b border-light mx-2xl"
      >
        {label || "Change the severity level"}
      </div>
      <SeverityPopupItems
        onSeveritySelected={onSeveritySelected}
        currentSeverity={currentSeverity}
        issueSeverities={issueSeverities}
      />
    </PopupMenu>
  </div>
);

const SeverityPopup = ({
  currentSeverity,
  onSeveritySelected,
  label,
  isOpen,
  onExtenalElementClick,
  onPopupClick,
  children,
}: SeverityPopupProps) => {
  const { issueSeverities } = useIssueSeverities();
  const wrapperRef = useRef(null);

  useOutsideHandler(wrapperRef, onExtenalElementClick);

  const severityPopupContent = SeverityPopupContent({
    currentSeverity,
    onSeveritySelected,
    label,
    issueSeverities,
  });

  return (
    <div ref={wrapperRef}>
      <Popup popupElement={severityPopupContent} isOpen={isOpen}>
        <div className="cursor-pointer" onClick={onPopupClick}>
          {children}
        </div>
      </Popup>
    </div>
  );
};

export default SeverityPopup;
