import React, { useState } from "react";
import { Button } from "@hopper/button";
import { Modal, ModalHeader } from "@hopper/legacy_modal";
import { useField } from "formik";
import { string } from "yup";
import { FormInlineError } from "@hopper/form_inline_error";

import { supervisedTestSettingsUrl } from "components/configuration";
import { TextArea } from "components/flexible_testing/shared/form";
import warningIcon from "assets/images/icon--warning.svg";

type ConfirmationModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
};

const reasonValidator = string().trim().required();

const ConfirmationModal = ({ onConfirm, onClose, isOpen }: ConfirmationModalProps) => {
  const [_field, { value }] = useField({ name: "automaticTesterManagementDisabledReason" });

  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={isOpen}
      position="center"
      size="lg"
      onOverlayClicked={onClose}
    >
      <div className="pl-2xl">
        <ModalHeader>Help us improve this feature</ModalHeader>
        <p className="mb-2xl mt-xs">
          The automated system that manages backup testers is designed to reduce test manager overhead. Providing a
          reason why you are unable to use the automated system will help us identify ways to improve it soon and save
          you time & effort
        </p>
        <TextArea
          name="automaticTesterManagementDisabledReason"
          label="Please provide a reason"
          required={true}
          placeholder="Reason for not using automatic management of backup testers"
          className="w-full p-sm"
        />
        <div className="flex items-beginning justify-end mt-2xl">
          <Button
            variant="primary"
            className="mr-xl"
            onClick={onConfirm}
            type="button"
            disabled={!reasonValidator.isValidSync(value)}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const AutomaticTesterManagementSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [_enabledField, { value }, { setValue }] = useField({ name: "automaticTesterManagementEnabled" });
  const [_reasonField, { error }] = useField({ name: "automaticTesterManagementDisabledReason" });

  const onConfirm = () => {
    setValue(false);
    setShowModal(false);
  };

  return (
    <>
      <p className="text-sm">
        {value ? (
          <>
            Backup testers will be managed automatically according to the&nbsp;
            <a href={supervisedTestSettingsUrl} className="text-link underline" target="_blank">
              Tester Management Settings
            </a>
            .
          </>
        ) : (
          <>
            <img
              src={warningIcon}
              className="mr-xs inline-block"
              alt="Warning icon"
              style={{ height: "16px", width: "16px" }}
            />
            Backup testers need to be manually invited
          </>
        )}
        {error && (
          <div className="mt-sm">
            <FormInlineError>{error}</FormInlineError>
          </div>
        )}
      </p>
      <Button
        className="text-sm my-sm px-0"
        variant="primary"
        appearance="minimal"
        onClick={() => (value ? setShowModal(true) : setValue(true))}
        type="button"
      >
        {value ? "I want to manage backup testers manually" : "Switch back to automatic tester management"}
      </Button>
      <ConfirmationModal onConfirm={onConfirm} onClose={() => setShowModal(false)} isOpen={showModal} />
    </>
  );
};

export default AutomaticTesterManagementSection;
