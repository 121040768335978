import React from "react";
import { Participant } from "components/flexible_testing/shared/chat";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import formatDistanceShortLocale from "tools/format_distance_short_locale";
import STATUS_CONFIG from "./status_config";

type StatusLabelProps = {
  status: Participant["status"];
  invitedAt?: string | null;
};

export const statusVariations = (status: Participant["status"]) => STATUS_CONFIG[status];

const StatusLabel = ({ status, invitedAt }: StatusLabelProps) => {
  const statusOptions = statusVariations(status);

  return statusOptions ? (
    <span className="flex items-center text-sm" data-testid="participant-status">
      <img src={statusOptions.icon} className="mr-sm" />
      {statusOptions.label}
      {status === "invited" && invitedAt && (
        <span className="ml-xs">
          {formatDistanceToNowStrict(parseISO(invitedAt), {
            locale: formatDistanceShortLocale,
            roundingMethod: "floor",
          })}
        </span>
      )}
    </span>
  ) : null;
};

export default StatusLabel;
