import { useQuery } from "@apollo/client";
import { Integration, JiraConnectIssuetype } from "types/graphql";

import { GET_INTEGRATION_PROJECT_ISSUE_TYPES } from "components/shared/integration/integration_queries";

type useProjectIssuetypesResponse = {
  isLoading: boolean;
  data: JiraConnectIssuetype[];
};

function useProjectIssuetypes(integrationId: string, projectKey: string): useProjectIssuetypesResponse {
  const { data, loading } = useQuery<{ integration: Integration }>(GET_INTEGRATION_PROJECT_ISSUE_TYPES, {
    variables: { id: integrationId, projectKey },
  });

  return { isLoading: loading, data: data?.integration?.exportSettings?.issueTypes || [] };
}

export default useProjectIssuetypes;
