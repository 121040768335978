import { useMutation } from "@apollo/client";

import { INTEGRATION_RESET } from "components/shared/integration/integration_queries";
import { IntegrationResetInput } from "types/graphql";

type UseIntegrationResetResult = {
  resetIntegration: (params: IntegrationResetInput) => Promise<void>;
};

export const useIntegrationReset = (): UseIntegrationResetResult => {
  const [resetIntegration] = useMutation(INTEGRATION_RESET);

  return {
    resetIntegration: async (params: IntegrationResetInput): Promise<void> => {
      await resetIntegration({
        variables: {
          input: params,
        },
      });
    },
  };
};
