import { IntegrationTypes } from "components/flexible_testing/result_details_page/export_destinations/export_destinations";

export const integrations = {
  jira: {
    image_src: "/assets/images/integrations/jira_logo.svg",
    defaultColor: "#505F79",
    viewBox: "0 0 75 32",
    viewBoxAlt: "0 0 75 32",
  },
  jira_connect: {
    image_src: "/assets/images/integrations/jira_logo.svg",
    defaultColor: "#505F79",
    viewBox: "0 0 75 32",
    viewBoxAlt: "0 0 75 32",
  },
  github: {
    image_src: "/assets/images/integrations/github_logo.svg",
    defaultColor: "#101315",
    viewBox: "0 0 324 66",
    viewBoxAlt: "0 0 324 80",
  },
  azure_devops: {
    image_src: "/assets/images/integrations/azure_devops_logo.svg",
    defaultColor: "#101315",
    viewBox: "0 0 878 256",
    viewBoxAlt: "0 0 878 300",
  },
  csv: {
    iconClass: "fa fa-file-text",
    buttonText: "Download as .csv",
  },
};

type Integration = {
  image_src?: string;
  defaultColor?: string;
  viewBox?: string;
  viewBoxAlt?: string;
  iconClass?: string;
  buttonText?: string;
};

export const getIntegrationConfigByCode = (code: IntegrationTypes): Integration => {
  const integration = integrations[code];

  if (integration === undefined) {
    console.error("Integration is not available:", code);
  }

  return integration;
};
