import { gql, useQuery } from "@apollo/client";

export const CASE_STATUS_AFTER_REJECTION_QUERY = gql`
  query getCaseStatusAfterRejection($id: ID!) {
    flexibleTestingTestCaseResult(id: $id) {
      id
      caseStatusAfterRejection
      testCase {
        status
      }
    }
  }
`;

type TestCaseStatusAfterRejectionReponse = {
  caseStatusAfterRejection: string;
  willTestCaseStatusChangeAfterRejection: boolean;
  isLoading: boolean;
};

export function useTestCaseStatusAfterRejection(id: string): TestCaseStatusAfterRejectionReponse {
  const { data, loading } = useQuery(CASE_STATUS_AFTER_REJECTION_QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });
  const caseStatusAfterRejection = data?.flexibleTestingTestCaseResult?.caseStatusAfterRejection;
  const currentTestCaseStatus = data?.flexibleTestingTestCaseResult?.testCase?.status;
  const willTestCaseStatusChangeAfterRejection =
    caseStatusAfterRejection !== "unknown" && caseStatusAfterRejection !== currentTestCaseStatus;

  return {
    willTestCaseStatusChangeAfterRejection,
    caseStatusAfterRejection,
    isLoading: loading,
  };
}
