import React, { useState } from "react";
import { Multiselect } from "@hopper/multiselect";

import { Options } from "./multiselect";

type ControlledMultiselectProps = {
  options: Options;
  onChange: (selectedValues: Array<string>) => void;
  placeholder: string;
  displayPrimaryButton?: boolean;
  selectedItemsLabel?: string;
};

const label = (selectedOptions: Options, placeholder: string, selectedItemsLabel?: string) => {
  if (selectedItemsLabel) {
    return selectedItemsLabel;
  } else if (selectedOptions.length > 0) {
    return selectedOptions.map(item => item.label).join(", ");
  } else {
    return placeholder;
  }
};

export const ControlledMultiselect = ({
  options,
  onChange,
  placeholder,
  displayPrimaryButton,
  selectedItemsLabel,
}: ControlledMultiselectProps) => {
  const [isOpen, updateIsOpen] = useState(false);
  const selectedOptions = options.filter(option => option.isSelected);

  const onOutsideClick = (options: Options) => {
    closePopup();
    if (displayPrimaryButton === false) {
      const selectedValues = options.filter(option => option.isSelected).map(option => option.value);

      onChange(selectedValues);
    }
  };

  const closePopup = () => updateIsOpen(false);

  const onPrimaryButtonClicked = (options: Options) => {
    closePopup();
    const selectedValues = options.filter(option => option.isSelected).map(option => option.value);

    onChange(selectedValues);
  };

  const clearOptions = () => {
    closePopup();
    onChange([]);
  };

  const toggleIsOpen = () => {
    updateIsOpen(!isOpen);
  };

  return (
    <Multiselect
      optionsList={options}
      onInputClick={toggleIsOpen}
      isOpen={isOpen}
      value={label(selectedOptions, placeholder, selectedItemsLabel)}
      primaryButtonText="Apply"
      secondaryButtonText="Clear"
      onPrimaryButtonClicked={onPrimaryButtonClicked}
      onSecondaryButtonClicked={clearOptions}
      onOutsideClick={onOutsideClick}
      size="sm"
      displayPrimaryButton={displayPrimaryButton}
    />
  );
};
