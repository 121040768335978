import React from "react";
import { gql, useApolloClient } from "@apollo/client";

export const JIRA_COMPONENTS_QUERY = gql`
  query jiraComponents($projectId: ID!, $integrationId: ID!) {
    jiraComponentsCollection(projectId: $projectId, integrationId: $integrationId) {
      jiraComponents {
        id
        name
      }
    }
  }
`;

function withJiraComponents(WrappedComponent) {
  return function ({ ...props }) {
    const client = useApolloClient();

    return (
      <WrappedComponent
        {...props}
        loadJiraComponents={async (projectId, integrationId) => {
          const { data } = await client.query({
            query: JIRA_COMPONENTS_QUERY,
            variables: { projectId, integrationId },
          });

          return data?.jiraComponentsCollection?.jiraComponents;
        }}
      />
    );
  };
}

export { withJiraComponents };
