import { Button } from "@hopper/button";
import React from "react";

type NewApiKeyButtonProps = {
  isKeyAlreadyGenerated: boolean;
  onClick: () => void;
};

function NewApiKeyButton({ isKeyAlreadyGenerated, onClick }: NewApiKeyButtonProps): JSX.Element {
  const buttonContent = isKeyAlreadyGenerated ? (
    <>
      <i className="fa fa-check mr-sm text-lg text-dark" />
      <span className="text-dark">Generated</span>
    </>
  ) : (
    "Generate new API key"
  );

  const label = isKeyAlreadyGenerated
    ? "A new API key has been generated successfully and copied to your clipboard"
    : "Generating a new key will revoke all other API keys.";

  return (
    <div className="flex items-center mt-sm">
      <Button
        variant={isKeyAlreadyGenerated ? "secondary" : "primary"}
        onClick={onClick}
        size="sm"
        className="flex-none text-xs"
        data-testid="generate-api-key-button"
      >
        {buttonContent}
      </Button>
      <p className="ml-lg text-sm text-dark">{label}</p>
    </div>
  );
}

export default NewApiKeyButton;
