import { Link, LinkProps } from "react-router-dom";
import React from "react";
import classnames from "classnames";

type BackToPreviousPageLinkProps = {
  children: React.ReactNode;
  color?: string;
} & LinkProps;

function BackToPreviousPageLink({ to, children, color = "dark" }: BackToPreviousPageLinkProps) {
  const spanClassName = classnames(`text-${color}`, "text-sm font-bold uppercase tracking-extra-wide");

  return (
    <Link className="inline-block mt-3xl" to={to} data-testid="back-to-previous-page">
      <span className={spanClassName}>
        <i className="fa fa-chevron-left" aria-hidden="true" /> {children}
      </span>
    </Link>
  );
}

export default BackToPreviousPageLink;
