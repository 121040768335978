import { gql } from "@apollo/client";

export const GET_TEST_DETAILS = gql`
  query getPlatformTestDetails($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        _id
        name
        code
        accessToApplication
        testObjective
        platformsToTestOn
        deviceRequirements
        testLocalisation
        testCasesUrl
        knownIssuesUrl
        type
        sdlcStage
        desiredOutcomes {
          name
          code
        }
        customDesiredOutcomes
        requestedTests {
          id
          name
          activity
          applicationType
          inScope
          testCasesCount
        }
      }
      ... on FlexibleTestingTest {
        id
        _id
        description
        credentialsAndAccessInstructions
        additionalUrlsAndResources
        applicationUrl
        type
        sdlcStage
        payoutBaseAmount
        parentTestId
        requiredAttachmentForPass
        linkedTestCycleUrl
        applyCountryPayoutMultiplier
        supportsAutomaticTesterManagement
        automaticTesterManagementEnabled
        activityType
        desiredOutcomes {
          name
          code
        }
        environments {
          platform
        }
        parentTest {
          advancedTestRequest {
            _id
          }
        }
        cases {
          totalCount
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
