import { useState } from "react";
import { StreamChat, Channel } from "stream-chat";
import { ChatType } from "types/app";

import { useChatContext } from "./chat_client_context";
import useRegisterChat from "./use_register_chat";

type useChannelType = {
  targetId: string;
  chatType: ChatType;
  testerId?: string;
  providedChannel?: Channel | null;
  shouldInitialize?: boolean;
};

const useChannel = ({ targetId, chatType, testerId, providedChannel, shouldInitialize = true }: useChannelType) => {
  const [channel, setChannel] = useState<Channel>();
  const [isLoading, setLoading] = useState<boolean>();
  const { registerChat } = useRegisterChat(chatType);
  const { client, setChannelUnreadMessages, channelUnreadMessages } = useChatContext();
  const unreadMessagesCount = channelUnreadMessages.get(channel?.id || "") || 0;

  const getChannel = async (client: StreamChat): Promise<Channel | undefined> => {
    // This is to handle the case when the channel was already retrieved
    // and we don't need to initialize it again, currently used for private chats
    if (providedChannel) return providedChannel;

    const chatChannel = await registerChat(targetId, testerId);
    const filter = { type: "messaging", cid: `messaging:${chatChannel?.externalId}` };

    try {
      const channels = await client.queryChannels(filter, undefined, { watch: true });

      return channels[0];
    } catch {
      throw new Error("There was an unexpected issue while fetching chat channel");
    }
  };

  const setupChannel = (client: StreamChat, channel: Channel) => {
    if (channel.id) {
      setChannelUnreadMessages(new Map(channelUnreadMessages.set(channel.id, channel.countUnread())));
    }

    channel.on("message.new", event => {
      if (client.userID !== event.message?.user?.id && channel.id) {
        setChannelUnreadMessages(new Map(channelUnreadMessages.set(channel.id, channel.countUnread())));
      }
    });
    channel.on("notification.mark_read", event => {
      if (channel.id) {
        setChannelUnreadMessages(new Map(channelUnreadMessages.set(channel.id, 0)));
      }
    });
  };

  if (client && isLoading === undefined && shouldInitialize) {
    setLoading(true);
    getChannel(client)
      .then(returnedChannel => {
        if (!returnedChannel) return;

        setupChannel(client, returnedChannel);
        setChannel(returnedChannel);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {
    channel,
    isLoading,
    unreadMessagesCount,
  };
};

export default useChannel;
