import React from "react";
import * as yup from "yup";
import { DecoratedText } from "@hopper/decorated_text";
import pluralize from "pluralize";
import Truncate from "react-truncate";

import { FlexibleTestingExternalTestDetails } from "types/graphql";
import { DetailsField } from "../shared/details_field";
import { Form, TextArea, TextInput, SubmitButton } from "components/flexible_testing/shared/form";
import useTestRequestsInProgress from "./use_test_requests_in_progress";
import Loader from "./loader";
import extractUrls from "./extract_urls";

type ConfirmationMessageProps = {
  externalTestDetails: FlexibleTestingExternalTestDetails;
  handleFormSubmit: (values: { [field: string]: unknown }) => Promise<unknown>;
  vendor: string;
  externalTestId: string;
};

function TestrailUserConfirmation({
  handleFormSubmit,
  externalTestDetails,
  vendor,
  externalTestId,
}: ConfirmationMessageProps) {
  const [firstUrl, ...additionalUrls] = extractUrls(externalTestDetails.description);
  const { isLoading: isLoadingTestRequestInProgress, isTestRequestInProgress } = useTestRequestsInProgress({
    source: vendor,
    externalTestId,
  });

  return (
    <div data-testid="confirmation-page" style={{ width: "435px" }}>
      <div className="flex justify-between items-center border-b-2 border-light mb-xl mt-5xl gap-md">
        <div data-testid="test-detail-name" className="text-base font-bold">
          <Truncate lines={1}>{externalTestDetails.name}</Truncate>
        </div>
        <div className="text-dark text-right whitespace-nowrap">
          Includes {externalTestDetails.numberOfCases} test {pluralize("case", externalTestDetails.numberOfCases)}
        </div>
      </div>

      <DetailsField label="Test Run Description" testId="test-detail-description" paddingClass="px-0">
        <DecoratedText text={externalTestDetails.description}></DecoratedText>
      </DetailsField>

      <Form
        data-testid="testrail-launch-form"
        initialValues={{
          applicationUrl: firstUrl,
          credentialsAndAccessInstructions: "",
          additionalUrlsAndResources: additionalUrls.join("\n"),
        }}
        onSubmit={handleFormSubmit}
        className="mt-3xl"
        validations={yup.object().shape({
          applicationUrl: yup
            .string()
            .url("Please enter a valid Application URL")
            .trim()
            .required("Include a link to where testers can access your application"),
          credentialsAndAccessInstructions: yup
            .string()
            .trim()
            .required("Credentials or access instructions are required"),
        })}
      >
        <TextInput data-testid="application-url-field" name="applicationUrl" label="Application URL" required={true} />

        <TextArea
          data-testid="credentials-and-access-instructions-field"
          name="credentialsAndAccessInstructions"
          label="Credentials and/or access instructions"
          useHopperInput={true}
          required={true}
          placeholder="Will testers need login credentials for accessing the application? Please add any access instructions here"
        />

        <TextArea
          data-testid="additional-urls-and-resources-field"
          name="additionalUrlsAndResources"
          label="Additional URLs and resources"
          useHopperInput={true}
          required={false}
          placeholder="Will testers need any additional URLs to complete this test (e.g. admin app, production environments)? Please add them here along with any credentials needed"
        />

        {isLoadingTestRequestInProgress && <Loader />}
        {!isLoadingTestRequestInProgress && isTestRequestInProgress && (
          <div data-testid="test-in-progress-warning" className="mt-3xl text-sm font-bold text-body leading-125">
            There is already a test in progress for this test run, are you sure you want to launch this test?
          </div>
        )}

        <SubmitButton data-testid="confirmation-button" className="mt-3xl" useHopperButton={true}>
          Confirm and launch
        </SubmitButton>
      </Form>

      <div className="text-dark text-left mb-5xl mt-xl">
        If you do not want to launch this test, you can close this tab
      </div>
    </div>
  );
}

export default TestrailUserConfirmation;
