import React from "react";
import { useFormikContext } from "formik";
import { TextInput } from "../../shared/form";
import { Switch } from "@hopper/switch";
import { defaultAzureDevopsSite } from "./azure_devops_form";
import { PageSection } from "../../shared/page_section";

type IntegrationSiteFieldsProps = {
  isHostedOnAdo: boolean;
  setIsHostedOnAdo: React.Dispatch<React.SetStateAction<boolean>>;
  isEditable: boolean;
  defaultValues: { [key: string]: string };
};

type Values = { [key: string]: string };

function IntegrationSiteFields({
  isHostedOnAdo,
  setIsHostedOnAdo,
  isEditable,
  defaultValues,
}: IntegrationSiteFieldsProps) {
  const { resetForm, values } = useFormikContext();
  const typesValues = values as Values;

  function handleSwitchChange() {
    if (isEditable) {
      resetForm({ values: defaultValues });
      setIsHostedOnAdo(prevValue => !prevValue);
    }
  }

  function isSiteDefault() {
    return typesValues.site === defaultAzureDevopsSite;
  }

  return (
    <PageSection title="Integration settings" orderNumber={1}>
      <div className="mb-2xl">
        <div className="mb-lg" data-test-role="form-group">
          <TextInput
            id="site"
            name="site"
            label="What is your site URL?"
            labelClass="text-sm"
            className="form-control max-w-xs mb-md"
            placeholder="https://dev.azure.com"
            disabled={!isEditable}
            data-testid="site-input"
            required={true}
          />
          <div data-testid="hosted-on-switch">
            <Switch
              onLabel="Hosted on Azure DevOps"
              offLabel="Hosted on Azure DevOps"
              isOn={isHostedOnAdo}
              onChange={handleSwitchChange}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div data-test-role="form-group">
          {isHostedOnAdo ? (
            <>
              <TextInput
                id="organization"
                name="organization"
                label="Which organization would you like to use?"
                labelClass="text-sm"
                className="form-control max-w-xs mb-sm"
                placeholder="Your Azure DevOps organization name"
                disabled={!isEditable || !isSiteDefault()}
                data-testid="organization-input"
                required={true}
              />
              <div className="text-sm text-dark" style={{ width: "65%" }}>
                This is the name of your organization on Azure DevOps. In the URL, https://dev.azure.com/MyOrg/... where{" "}
                <strong>MyOrg</strong> is your organization name.
              </div>
            </>
          ) : (
            <>
              <TextInput
                id="contextPath"
                name="contextPath"
                label="What is the context path for your organization?"
                labelClass="text-sm"
                className="form-control max-w-xs mb-sm"
                placeholder="e.g. /tfs/Collection"
                disabled={!isEditable}
              />
              <div className="text-sm text-dark" style={{ width: "65%" }}>
                This is the context path relative to your site URL. In the URL, https://your.domain.com/team/ado, the
                context path is <strong>/team/ado</strong>.
              </div>
            </>
          )}
        </div>
      </div>
    </PageSection>
  );
}

export default IntegrationSiteFields;
