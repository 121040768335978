import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import { helpCenterUrl } from "components/configuration";
import { useLogout } from "components/shared/hooks/use_logout";
import Authorization from "app/authorization/authorization";

type AccountNavigationProps = {
  showCreditModel: boolean;
};

const AccountNavigation = ({ showCreditModel }: AccountNavigationProps): JSX.Element => {
  const { logout } = useLogout();

  const navLinkClassName = classnames(
    "focus:bg-light focus:text-darkest hover:bg-light hover:text-darkest",
    "rounded-base px-xl py-sm text-sm font-bold tracking-wider",
  );

  return (
    <>
      <Authorization roles={["testManager", "customerUser"]}>
        <>
          <Link className={classnames(navLinkClassName, "block", "pr-2xl")} to={createNavigationUrl("profileSettings")}>
            Profile
          </Link>
          <Link
            className={classnames(navLinkClassName, "block", "pr-2xl")}
            to={createNavigationUrl("organizationUsers")}
          >
            Organization
          </Link>
          {showCreditModel && (
            <Link
              className={classnames(navLinkClassName, "block", "pr-2xl")}
              to={createNavigationUrl("organizationUserCreditUsage")}
            >
              Credit Usage
            </Link>
          )}
          <Link
            className={classnames(navLinkClassName, "block", "pr-2xl")}
            to={createNavigationUrl("integrationSettings")}
          >
            Integrations
          </Link>
          <Link className={classnames(navLinkClassName, "block", "pr-2xl")} to={createNavigationUrl("apiSettings")}>
            API
          </Link>
          <a className={classnames(navLinkClassName, "block", "pr-2xl")} href={helpCenterUrl} target="_blank">
            Help Center
          </a>
        </>
      </Authorization>
      <div className="my-2xs">
        <a
          className={classnames(navLinkClassName, "block mt-xs")}
          data-testid="flexible-testing-logout"
          onClick={event => {
            event.preventDefault();
            logout({
              returnTo: window.location.origin,
            });
          }}
        >
          Logout
        </a>
      </div>
    </>
  );
};

export default AccountNavigation;
