import React from "react";
import { Heading } from "@hopper/heading";
import { Link } from "react-router-dom";

// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";

const EmptyLiveTests = () => (
  <div>
    <br />

    <div className="mt-4xl">
      <Heading size={2}>There are no tests in progress</Heading>
      <p>
        Ready to test?{" "}
        <Link to={createNavigationUrl("flexcaseLaunch")} className="text-link focus:outline-none cursor-pointer">
          Launch now
        </Link>{" "}
        to catch any issues early.
      </p>
    </div>
  </div>
);

export default EmptyLiveTests;
