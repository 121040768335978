import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { AUTHENTICATION_TOKEN_QUERY } from "app/authentication_token/authentication_token_query";

function AuthTokenProvider(props) {
  const { children } = props;
  const { getAccessTokenSilently } = useAuth0();
  const client = useApolloClient();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      if (token) {
        client.writeQuery({ query: AUTHENTICATION_TOKEN_QUERY, data: { authenticationToken: token } });
        localStorage.setItem("isAuthenticated", true);

        setAuthenticated(true);
      }
    });
  }, []);

  return authenticated ? children : null;
}

export default AuthTokenProvider;
