import React from "react";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import pluralize from "pluralize";

import GithubExportForm from "components/shared/results_export/github_export_form";
import JiraExportForm from "components/shared/results_export/jira_export_form";
import JiraConnectExportForm from "components/shared/results_export/jira_connect_export_form";
import AzureDevopsExportForm from "components/shared/results_export/azure_devops_export_form";
import { GET_INTEGRATION_EXPORT_ATTRIBUTES } from "components/shared/integration/integration_queries";
import { Loader } from "@hopper/loading";

export const EXPORT_ISSUES_TO_GITHUB = gql`
  mutation ExportIssuesToGithub($input: ExportIssuesToGithubInput!) {
    exportIssuesToGithub(input: $input) {
      successful
      result
    }
  }
`;

export const EXPORT_ISSUES_TO_JIRA = gql`
  mutation ExportIssuesToJira($input: ExportIssuesToJiraInput!) {
    exportIssuesToJira(input: $input) {
      successful
      result
    }
  }
`;

export const EXPORT_ISSUES_TO_JIRA_CONNECT = gql`
  mutation ExportIssuesToJiraConnect($input: ExportIssuesToJiraConnectInput!) {
    exportIssuesToJiraConnect(input: $input) {
      successful
      result
    }
  }
`;

export const EXPORT_ISSUES_TO_AZURE_DEVOPS = gql`
  mutation ExportIssuesToAzureDevops($input: ExportIssuesToAzureDevopsInput!) {
    exportIssuesToAzureDevops(input: $input) {
      successful
      result
    }
  }
`;

function ExportDetails({ itemsToExport, integration, onExportSubmit, onExportReset }) {
  const itemsCount = itemsToExport.length;
  const ExportForm = {
    jira: JiraExportForm,
    jira_connect: JiraConnectExportForm,
    github: GithubExportForm,
    azure_devops: AzureDevopsExportForm,
  }[integration.code];
  const mutation = {
    jira: EXPORT_ISSUES_TO_JIRA,
    jira_connect: EXPORT_ISSUES_TO_JIRA_CONNECT,
    github: EXPORT_ISSUES_TO_GITHUB,
    azure_devops: EXPORT_ISSUES_TO_AZURE_DEVOPS,
  }[integration.code];
  const [bugsExport] = useMutation(mutation);
  const { data, loading } = useQuery(GET_INTEGRATION_EXPORT_ATTRIBUTES, { variables: { id: integration.id } });

  // the export form are legacy code and they expect snake case key for runs_export_attributes
  const formattedIntegration = {
    ...integration,
    runs_export_attributes: data?.integration?.runsExportAttributes || {},
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="grid-cols-12">
          <div className="col-span-full">
            <h2 className="text-center mt-lg text-lg" data-testid="count-info">
              You are exporting <strong>{itemsCount}</strong>&nbsp;
              {pluralize("result", itemsCount)} to <strong>{integration.name}</strong>
            </h2>
          </div>

          <div className="md:col-span-10 md:col-start-2 col-span-12">
            <ExportForm
              integration={formattedIntegration}
              onSubmit={values => onExportSubmit(values, bugsExport)}
              onReset={onExportReset}
            />
            <div className="clear-both" />
          </div>
        </div>
      )}
    </>
  );
}

ExportDetails.propTypes = {
  itemsToExport: PropTypes.arrayOf(PropTypes.string).isRequired,
  integration: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }),
  onExportSubmit: PropTypes.func,
  onExportReset: PropTypes.func,
};

export default ExportDetails;
