import React from "react";
import { Button } from "react-bootstrap";

type ConfirmationMessageProps = {
  onLaunchConfirmed: () => void;
};

function UserConfirmation({ onLaunchConfirmed }: ConfirmationMessageProps) {
  return (
    <>
      <div data-testid="confirmation-page" className="text-xl font-bold mb-xl">
        Launch this test run?
      </div>
      <div className="text-dark">Your test will be launched to our crowd of testers</div>
      <Button data-testid="confirmation-button" className="mt-2xl" onClick={onLaunchConfirmed}>
        Confirm launch
      </Button>
    </>
  );
}

export default UserConfirmation;
