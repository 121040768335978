import { gql, useMutation, useApolloClient } from "@apollo/client";

import { FlexibleTestingIssueTag } from "types/graphql";

import { FLEXIBLE_TESTING_ISSUE_TAGS_FRAGMENT } from "components/flexible_testing/result_details_page/issues/issues_tab_data_query";

type useIssueUpdateTagsResult = {
  updateIssueTags: (issueId: string, issueTagIds: Array<FlexibleTestingIssueTag>) => Promise<void>;
  isLoading: boolean;
  wasSuccessfullyRequested: boolean;
};

export const ISSUE_UPDATE_TAGS_MUTATION = gql`
  mutation ($input: FlexibleTestingIssueUpdateTagsInput!) {
    flexibleTestingIssueUpdateTags(input: $input) {
      successful
      errors {
        code
        message
      }
    }
  }
`;

export const useIssueUpdateTags = (): useIssueUpdateTagsResult => {
  const client = useApolloClient();
  const [updateIssueTags, { loading, called, error }] = useMutation(ISSUE_UPDATE_TAGS_MUTATION);

  function writeTagsToCache(issueId: string, issueTags: Array<FlexibleTestingIssueTag>): Promise<unknown> {
    const wrappedTags = issueTags.map(tag => ({
      node: tag,
    }));

    return Promise.resolve(
      client.writeFragment({
        id: `FlexibleTestingIssue:${issueId}`,
        fragment: FLEXIBLE_TESTING_ISSUE_TAGS_FRAGMENT,
        data: {
          issueTags: {
            __typename: "FlexibleTestingIssueTagConnection",
            edges: wrappedTags,
          },
        },
      }),
    );
  }

  return {
    updateIssueTags: async (issueId, issueTags): Promise<void> => {
      await updateIssueTags({
        variables: {
          input: {
            issueId,
            issueTagIds: issueTags.map(tag => tag.id),
          },
        },
      }).then(() => writeTagsToCache(issueId, issueTags));
    },
    isLoading: loading,
    wasSuccessfullyRequested: called && !error,
  };
};
