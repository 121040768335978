import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingConfirmNoMassPassingInput, FlexibleTestingConfirmNoMassPassingPayload } from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

const CONFIRM_NO_MASS_PASSING_MUTATION = gql`
  mutation ($input: FlexibleTestingConfirmNoMassPassingInput!) {
    flexibleTestingConfirmNoMassPassing(input: $input) {
      successful
      result {
        id
        suspectedMassPassing
      }
      errors {
        code
        message
      }
    }
  }
`;

const displayError = (message: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message,
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "The mass passing warning has been dismissed for the current participant.",
  });
};

type UseConfirmNoMassPassing = {
  flexibleTestingConfirmNoMassPassing: (testId: string, participantId: string) => Promise<void>;
  isLoading: boolean;
};

export const useConfirmNoMassPassing = (): UseConfirmNoMassPassing => {
  const [flexibleTestingConfirmNoMassPassingMutation, { loading }] = useMutation<
    { flexibleTestingConfirmNoMassPassing: FlexibleTestingConfirmNoMassPassingPayload },
    { input: FlexibleTestingConfirmNoMassPassingInput }
  >(CONFIRM_NO_MASS_PASSING_MUTATION, {
    onCompleted: ({ flexibleTestingConfirmNoMassPassing }) => {
      if (flexibleTestingConfirmNoMassPassing?.successful) {
        displaySuccess();
      } else {
        const errorMessage = flexibleTestingConfirmNoMassPassing.errors.map(({ message }) => message).join(",");
        displayError(errorMessage);
      }
    },
    onError: () => displayError("There was an issue with dismissing the mass passing warning. Please try again later."),
  });

  return {
    flexibleTestingConfirmNoMassPassing: async (testId: string, participantId: string): Promise<void> => {
      await flexibleTestingConfirmNoMassPassingMutation({ variables: { input: { testId, participantId } } });
    },
    isLoading: loading,
  };
};
