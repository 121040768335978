import {
  Country,
  Device,
  InternetBrowser,
  Language,
  MobileDevice,
  MobileDeviceBrand,
  OperatingSystem,
  OperatingSystemVersion,
} from "types/graphql";
import React, { KeyboardEventHandler, useState } from "react";
import { Form, Formik } from "formik";
import { prepareOperatingSystemVersionOptions } from "components/flexible_testing/test_manager/test_request_page/supervised_test_content/tester_group_list/environment_selection_section";
import { useEnvironments } from "components/flexible_testing/test_manager/test_request_page/supervised_test_content/tester_group_list/use_environments";
import useUpdateRequirementsGroup from "components/flexible_testing/result_details_page/testers/edit_requirements_group_panel/use_update_requirements_group";
import { Loader } from "@hopper/loading";
import { MultiSelect, TextArea, TextInput } from "components/flexible_testing/shared/form";
import { Options } from "components/shared/form/multiselect/multiselect";
import { useFeatureFlag } from "app/feature_flags";

type RequirementsGroupFormProps = {
  testId: string;
  groupId: string;
  groupName: string;
  numberOfResultsNeeded: number;
  platformsToTestOn: Array<string>;
  selectedOperatingSystemVersions?: Pick<OperatingSystemVersion, "id" | "fullName">[];
  selectedDeviceTypes: Pick<Device, "id" | "name">[];
  selectedManufacturers: Pick<MobileDeviceBrand, "id" | "name">[];
  selectedMobileDevices: Pick<MobileDevice, "id" | "name">[];
  selectedOperatingSystems: Pick<OperatingSystem, "id" | "name">[];
  selectedInternetBrowsers: Pick<InternetBrowser, "id" | "name">[];
  selectedCountries: Pick<Country, "id" | "name">[];
  selectedLanguages: Pick<Language, "id" | "name">[];
  onOperatingSystemVersionIdsChange: (operatingSystemVersions: string[]) => void;
  other: string;
  canModifyNumberOfResultsNeeded: boolean;
  testCaseTags: Array<string>;
};

const handleEnterPress: KeyboardEventHandler<HTMLInputElement> = e => {
  if (e.key === "Enter") {
    (e?.currentTarget as HTMLInputElement).blur();
  }
};

function RequirementsGroupForm({
  selectedOperatingSystemVersions = [],
  platformsToTestOn,
  groupId,
  groupName,
  numberOfResultsNeeded,
  onOperatingSystemVersionIdsChange,
  selectedDeviceTypes,
  selectedManufacturers,
  selectedMobileDevices,
  selectedOperatingSystems,
  selectedInternetBrowsers,
  selectedCountries,
  selectedLanguages,
  other,
  canModifyNumberOfResultsNeeded,
  testCaseTags,
}: RequirementsGroupFormProps) {
  const {
    operatingSystemVersions,
    deviceTypes,
    manufacturers,
    mobileDevices,
    operatingSystems,
    internetBrowsers,
    countries,
    languages,
    environmentsLoading,
  } = useEnvironments();
  const testCaseTagsEnabled = useFeatureFlag("test-case-tags", false);

  const [selectedOperatingSystemVersionIds, setSelectedOperatingSystemVersionIds] = useState<Array<string>>(
    selectedOperatingSystemVersions.map(({ id }) => id),
  );
  const [selectedDeviceTypeIds, setSelectedDeviceTypeIds] = useState<Array<string>>(
    selectedDeviceTypes.map(({ id }) => id),
  );
  const [selectedManufacturerIds, setSelectedManufacturerIds] = useState<Array<string>>(
    selectedManufacturers.map(({ id }) => id),
  );
  const [selectedMobileDeviceIds, setSelectedMobileDeviceIds] = useState<Array<string>>(
    selectedMobileDevices.map(({ id }) => id),
  );
  const [selectedOperatingSystemIds, setSelectedOperatingSystemIds] = useState<Array<string>>(
    selectedOperatingSystems.map(({ id }) => id),
  );
  const [selectedInternetBrowserIds, setSelectedInternetBrowserIds] = useState<Array<string>>(
    selectedInternetBrowsers.map(({ id }) => id),
  );
  const [selectedCountryIds, setSelectedCountryIds] = useState<Array<string>>(selectedCountries.map(({ id }) => id));
  const [selectedLanguageIds, setSelectedLanguageIds] = useState<Array<string>>(selectedLanguages.map(({ id }) => id));
  const { updateRequirementsGroup } = useUpdateRequirementsGroup();
  const [fieldLoading, setFieldLoading] = useState<"groupName" | "numberOfResultsNeeded" | "">("");

  const operatingSystemVersionOptions = prepareOperatingSystemVersionOptions(
    operatingSystemVersions,
    selectedOperatingSystemVersionIds,
    platformsToTestOn,
  );
  const deviceTypesOptions: Options = deviceTypes.map(type => ({
    value: type.id,
    label: type.name || "",
    isSelected: selectedDeviceTypeIds.includes(type.id),
  }));
  const manufacturersOptions: Options = manufacturers.map(type => ({
    value: type.id,
    label: type.name || "",
    isSelected: selectedManufacturerIds.includes(type.id),
  }));
  const mobileDevicesOptions: Options = mobileDevices.map(type => ({
    value: type.id,
    label: type.name || "",
    isSelected: selectedMobileDeviceIds.includes(type.id),
  }));
  const operatingSystemsOptions: Options = operatingSystems.map(type => ({
    value: type.id,
    label: type.name || "",
    isSelected: selectedOperatingSystemIds.includes(type.id),
  }));
  const internetBrowsersOptions: Options = internetBrowsers.map(type => ({
    value: type.id,
    label: type.name || "",
    isSelected: selectedInternetBrowserIds.includes(type.id),
  }));
  const countriesOptions: Options = countries.map(country => ({
    value: country.id,
    label: country.name || "",
    isSelected: selectedCountryIds.includes(country.id),
  }));
  const languageOptions: Options = languages.map(language => ({
    value: language.id,
    label: language.name || "",
    isSelected: selectedLanguageIds.includes(language.id),
  }));

  const onSubmit = async (values: { [field: string]: unknown }): Promise<void> => {
    await updateRequirementsGroup({
      requirementsGroupId: groupId,
      operatingSystemVersionIds: values.operatingSystemVersions as string[],
      deviceTypeIds: values.deviceTypes as string[],
      numberOfResultsNeeded: values.numberOfResultsNeeded as number,
      manufacturerIds: values.manufacturers as string[],
      mobileDeviceIds: values.mobileDevices as string[],
      operatingSystemIds: values.operatingSystems as string[],
      internetBrowserIds: values.internetBrowsers as string[],
      countryIds: values.countries as string[],
      languageIds: values.languages as string[],
      name: values.groupName as string,
      other: values.other as string,
    });

    setFieldLoading("");
  };

  if (environmentsLoading) return <Loader isLoaded={!environmentsLoading} isCentered />;

  return (
    <Formik
      initialValues={{
        numberOfResultsNeeded,
        groupName,
        operatingSystemVersions: selectedOperatingSystemVersionIds,
        deviceTypes: selectedDeviceTypeIds,
        manufacturers: selectedManufacturerIds,
        mobileDevices: selectedMobileDeviceIds,
        operatingSystems: selectedOperatingSystemIds,
        internetBrowsers: selectedInternetBrowserIds,
        countries: selectedCountryIds,
        languages: selectedLanguageIds,
        other,
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <Form className="flex flex-col w-[24rem]">
          {testCaseTagsEnabled && (
            <TextInput name="testCaseTags" label="Test case tags" value={testCaseTags.join(", ")} disabled />
          )}
          {canModifyNumberOfResultsNeeded ? (
            <TextInput
              type="number"
              name="numberOfResultsNeeded"
              label="Number of results needed"
              isLoading={fieldLoading.includes("numberOfResultsNeeded")}
              onBlurCapture={async () => {
                setFieldLoading("numberOfResultsNeeded");
                await submitForm();
              }}
              onKeyUp={handleEnterPress}
              min={1}
              data-testid="number-of-results-needed"
            />
          ) : (
            <div className="text-body leading-125 mb-sm">
              Number of results needed: <span>{numberOfResultsNeeded}</span>
            </div>
          )}
          <TextInput
            name="groupName"
            label="Name"
            isLoading={fieldLoading.includes("groupName")}
            onBlurCapture={async () => {
              setFieldLoading("groupName");
              await submitForm();
            }}
            onKeyUp={handleEnterPress}
          />
          <MultiSelect
            name="countries"
            label="Countries"
            placeholder="Any"
            options={countriesOptions}
            errorMessage={undefined}
            data-testid="country-selector"
            onChange={async selectedValues => {
              setSelectedCountryIds(selectedValues);
              await submitForm();
            }}
          />
          <MultiSelect
            name="languages"
            label="Languages"
            placeholder="Any"
            options={languageOptions}
            errorMessage={undefined}
            data-testid="language-selector"
            onChange={async selectedValues => {
              setSelectedLanguageIds(selectedValues);
              await submitForm();
            }}
          />
          <MultiSelect
            name="deviceTypes"
            label="Device type"
            placeholder="Select device type"
            options={deviceTypesOptions}
            errorMessage={undefined}
            data-testid="device-type-selector"
            onChange={async selectedValues => {
              setSelectedDeviceTypeIds(selectedValues);
              await submitForm();
            }}
          />
          <MultiSelect
            name="manufacturers"
            label="Manufacturer"
            placeholder="Select manufacturer"
            options={manufacturersOptions}
            errorMessage={undefined}
            onChange={async selectedValues => {
              setSelectedManufacturerIds(selectedValues);
              await submitForm();
            }}
          />
          <MultiSelect
            name="mobileDevices"
            label="Device"
            placeholder="Select a device"
            options={mobileDevicesOptions}
            errorMessage={undefined}
            onChange={async selectedValues => {
              setSelectedMobileDeviceIds(selectedValues);
              await submitForm();
            }}
          />
          <MultiSelect
            name="operatingSystems"
            label="Operating System"
            placeholder="Select operating system"
            options={operatingSystemsOptions}
            errorMessage={undefined}
            onChange={async selectedValues => {
              setSelectedOperatingSystemIds(selectedValues);
              await submitForm();
            }}
          />
          <div data-testId="os-selector">
            <MultiSelect
              name="operatingSystemVersions"
              label="Operating system version"
              placeholder="Select operating system"
              options={operatingSystemVersionOptions}
              errorMessage={undefined}
              onChange={async selectedValues => {
                setSelectedOperatingSystemVersionIds(selectedValues);
                await submitForm();
                onOperatingSystemVersionIdsChange(selectedValues);
              }}
            />
          </div>
          <MultiSelect
            name="internetBrowsers"
            label="Browser"
            placeholder="Select browser"
            options={internetBrowsersOptions}
            errorMessage={undefined}
            onChange={async selectedValues => {
              setSelectedInternetBrowserIds(selectedValues);
              await submitForm();
            }}
          />
          <TextArea
            name="other"
            label="Other"
            placeholder="Enter any additional requirements for testers"
            useHopperInput
            onBlurCapture={async () => {
              await submitForm();
            }}
          />
        </Form>
      )}
    </Formik>
  );
}

export default RequirementsGroupForm;
