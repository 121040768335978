import React, { useState } from "react";

import { FlexibleTestingIssue, FlexibleTestingIssueTag } from "types/graphql";
import TagsList from "./tags_list";
import TagForm from "./tag_form";

type OptionsBoxProps = {
  activeTags: FlexibleTestingIssueTag[];
  availableTags: Array<FlexibleTestingIssueTag>;
  handleTagsUpdate: (tags: Array<FlexibleTestingIssueTag>, mode?: string) => Promise<void> | void;
  setIsOpen: (fn: (prevValue: boolean) => boolean) => void;
  isReadOnly: boolean;
  issues?: Array<FlexibleTestingIssue>;
  tagsFilterMode?: string;
  withoutCounter?: boolean;
};

const OptionsBox = ({
  activeTags,
  availableTags,
  handleTagsUpdate,
  setIsOpen,
  isReadOnly = true,
  issues = [],
  tagsFilterMode,
  withoutCounter,
}: OptionsBoxProps) => {
  const [editedTag, setEditedTag] = useState<FlexibleTestingIssueTag | null>();

  return (
    <div
      className="absolute mt-sm border border-muted shadow rounded-base bg-lightest"
      style={{ width: "280px", zIndex: 1 }}
      data-testid="tag-options-box"
    >
      {editedTag ? (
        <TagForm tag={editedTag} onClose={() => setEditedTag(null)} />
      ) : (
        <TagsList
          onTagEdit={tagToEdit => setEditedTag(tagToEdit)}
          availableTags={availableTags}
          handleTagsUpdate={handleTagsUpdate}
          activeTags={activeTags}
          setIsOpen={setIsOpen}
          isReadOnly={isReadOnly}
          issues={issues}
          tagsFilterMode={tagsFilterMode}
          withoutCounter={withoutCounter}
        />
      )}
    </div>
  );
};

export default OptionsBox;
