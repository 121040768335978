import React from "react";
import { FlexibleTestingTestCaseResult } from "types/graphql";
import { Heading } from "@hopper/heading";
import TesterCasesTable from "./tester_cases_table";
import { AssignedCaseWithOwnResult } from "./tester_overview";

function filterTestCases(testCases: Array<AssignedCaseWithOwnResult>) {
  const approvedTestCases = testCases.filter(testCase => testCase.caseResult.moderationStatus === "approved");
  const outstandingTestCases = testCases.filter(testCase => testCase.caseResult.moderationStatus !== "approved");

  return { outstandingTestCases, approvedTestCases };
}

function TesterCasesTables({
  testCases,
  onTestCaseResultRowClick,
  testerId,
  selectedTestCaseResultId,
}: {
  testCases: Array<AssignedCaseWithOwnResult>;
  onTestCaseResultRowClick: (testCaseResult: FlexibleTestingTestCaseResult) => void;
  testerId?: string;
  selectedTestCaseResultId?: string;
}) {
  const { outstandingTestCases, approvedTestCases } = filterTestCases(testCases);

  return (
    <>
      <Heading size={3}>Outstanding test case results</Heading>
      {outstandingTestCases.length > 0 ? (
        <TesterCasesTable
          onTestCaseResultRowClick={onTestCaseResultRowClick}
          testCases={outstandingTestCases}
          testerId={testerId}
          selectedTestCaseResultId={selectedTestCaseResultId}
        />
      ) : (
        <p>There are no outstanding test case results</p>
      )}
      <div className="my-[5rem]">
        <Heading size={3}>✅ Approved test case results</Heading>
        <TesterCasesTable
          onTestCaseResultRowClick={onTestCaseResultRowClick}
          testCases={approvedTestCases}
          testerId={testerId}
          selectedTestCaseResultId={selectedTestCaseResultId}
        />
      </div>
    </>
  );
}

export default TesterCasesTables;
