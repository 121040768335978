const videoPlaybackRateKey = "videoPlaybackRate";

export function getPlaybackRate(): number {
  return (
    Number(
      document.cookie
        .split("; ")
        .find(element => element.startsWith(videoPlaybackRateKey))
        ?.split("=")[1],
    ) || 1
  );
}

export function setPlaybackRate(playbackRate: number): void {
  document.cookie = `${videoPlaybackRateKey}=${playbackRate}`;
}
