import React from "react";

import {
  FlexibleTestingCaseResultRelatedIssue,
  FlexibleTestingCaseResultRelatedIssueModerationStatus,
  Maybe,
} from "types/graphql";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { EditRelatedIssueCodeButton } from "./edit_related_issue_code_button";
import { DeleteRelatedIssueButton } from "./delete_related_issue_button";
import { useTrackingOpenRelatedIssue } from "../track_moderation_events/use_tracking_open_related_issue";
import { StatusLabel, StatusLabelProps } from "@hopper/status_label";

type RelatedIssuesProps = {
  relatedIssue: FlexibleTestingCaseResultRelatedIssue;
  caseResultId: string;
  caseResultStatusFieldResponseId: string;
  isCaseResultEditingDisabled: boolean;
  clickRequestEditButton: () => void;
};

export function RelatedIssue({
  relatedIssue,
  caseResultId,
  caseResultStatusFieldResponseId,
  isCaseResultEditingDisabled,
  clickRequestEditButton,
}: RelatedIssuesProps) {
  const relatedIssueCode = relatedIssue.label;
  const trackOpenRelatedIssue = useTrackingOpenRelatedIssue();

  return (
    <>
      <div className="mt-3xl">
        <DetailsField label="Related Issue" testId="related-issue-section" paddingClass="pt-md">
          {(!!relatedIssue.moderationStatus || !relatedIssue.existsInSystem) && (
            <div className="my-xs" style={{ position: "relative", left: "-4px" }}>
              <RelatedIssueModerationStatus
                moderationStatus={relatedIssue.moderationStatus || null}
                existsInSystem={relatedIssue.existsInSystem}
                known={relatedIssue.known}
              />
            </div>
          )}
          <div className="flex flex-col">
            <div className="flex items-center justify-between">
              <a
                href={relatedIssue.url}
                target="_blank"
                rel="noopener noreferrer"
                className="pl-0 text-sm text-link underline"
                onClick={() => trackOpenRelatedIssue(relatedIssueCode)}
              >
                {relatedIssue.label}
              </a>
              <div>
                <div className="flex items-center">
                  <EditRelatedIssueCodeButton
                    caseResultFieldResponseId={caseResultStatusFieldResponseId}
                    caseResultId={caseResultId}
                    relatedIssueCode={relatedIssueCode}
                    isEditButtonDisabled={isCaseResultEditingDisabled}
                  />
                  <div className="mx-sm">|</div>
                  <DeleteRelatedIssueButton
                    caseResultStatusFieldResponseId={caseResultStatusFieldResponseId}
                    onRequestEditButtonClicked={clickRequestEditButton}
                    relatedIssueId={relatedIssueCode}
                    caseResultId={caseResultId}
                    isDeleteButtonDisabled={isCaseResultEditingDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </DetailsField>
      </div>
    </>
  );
}

type ModerationStatusProperties = {
  label: string;
  colorVariant: StatusLabelProps["variant"];
  classNames?: string;
};

function RelatedIssueModerationStatus({
  moderationStatus,
  existsInSystem,
  known,
}: {
  moderationStatus: Maybe<FlexibleTestingCaseResultRelatedIssueModerationStatus>;
  existsInSystem: boolean;
  known: boolean;
}) {
  const moderationStatusMapping: Record<
    FlexibleTestingCaseResultRelatedIssueModerationStatus | "invalid" | "known",
    ModerationStatusProperties
  > = {
    accepted: {
      label: "Accepted",
      colorVariant: "success",
      classNames: "text-lightest",
    },
    rejected: {
      label: "Rejected",
      colorVariant: "danger",
      classNames: undefined,
    },
    not_moderated: {
      label: "Not Moderated",
      colorVariant: "neutral",
      classNames: undefined,
    },
    invalid: {
      label: "Fake issue",
      colorVariant: "warning",
      classNames: undefined,
    },
    known: {
      label: "Known issue",
      colorVariant: "warning",
      classNames: undefined,
    },
  };

  const status = existsInSystem ? (known ? "known" : moderationStatus) : "invalid";
  const {
    label: moderationStatusLabel,
    colorVariant: moderationStatusColorVariant,
    classNames: moderationStatusClasses,
  } = moderationStatusMapping[status as keyof typeof moderationStatusMapping];

  return (
    <div className={moderationStatusClasses}>
      <StatusLabel variant={moderationStatusColorVariant}>{moderationStatusLabel}</StatusLabel>
    </div>
  );
}
