import React, { useRef, useState } from "react";

import FilterDisplayButton from "./filter_display_button";
import { OptionsBox } from "../../../shared/tag_cell/options_box";
import { useIssueTags } from "../../../shared/tag_cell/use_issue_tags";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { FlexibleTestingIssue, FlexibleTestingIssueTag } from "types/graphql";

type TagFilterProps = {
  tags?: FlexibleTestingIssueTag[];
  updateSelectedTags?: (tags: string[], mode?: string) => void;
  issues: Array<FlexibleTestingIssue>;
  taggedIssues: Array<FlexibleTestingIssue>;
  filterMode?: string;
};

const TagFilter = ({ updateSelectedTags, issues, taggedIssues, filterMode }: TagFilterProps) => {
  const wrapperRef = useRef(null);

  const [tags, setTags] = useState<FlexibleTestingIssueTag[]>([]);

  const handleTagsUpdate = (issueTags: FlexibleTestingIssueTag[], mode?: string): void => {
    setTags(issueTags);
    if (updateSelectedTags) {
      const tagsSelected = issueTags.map(tag => tag.name);
      updateSelectedTags?.(tagsSelected, mode);
    }
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOutsideClick = () => setIsOpen(false);
  useOutsideHandler(wrapperRef, handleOutsideClick);

  const handleOpenToggle = () => {
    setIsOpen(!isOpen);
  };

  const { issueTags } = useIssueTags();

  return (
    <div className="cursor-default items-center" ref={wrapperRef} data-testid="tag-filter">
      <FilterDisplayButton onClick={handleOpenToggle} {...{ tags, isOpen, taggedIssues, filterMode }} />

      {isOpen && (
        <OptionsBox
          activeTags={tags}
          availableTags={issueTags}
          handleTagsUpdate={handleTagsUpdate}
          setIsOpen={setIsOpen}
          isReadOnly={true}
          issues={issues}
        />
      )}
    </div>
  );
};

export default TagFilter;
