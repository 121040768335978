import { useQuery } from "@apollo/client";
import { GET_TESTERS_TAB_TEST_DATA } from "./testers_tab_test_data_query";

function useTestersTabTestData(testId?: string) {
  const { loading, data } = useQuery(GET_TESTERS_TAB_TEST_DATA, {
    variables: { testId },
    skip: !testId,
  });

  return {
    isLoading: loading,
    platformTest: data?.platformTest,
  };
}

export { useTestersTabTestData };
