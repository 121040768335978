import React from "react";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { FlexibleTestingIssueTag } from "types/graphql";

import LimitedActiveTagsList from "./active_tags_lists/limited_active_tags_list";
import FullActiveTagsList from "./active_tags_lists/full_active_tags_list";

type DisplayButtonProps = {
  tags: FlexibleTestingIssueTag[];
  isOpen: boolean;
  onClick: () => void;
  isActiveTagsListLimited: boolean;
};

const DisplayButton = ({ tags, isOpen, onClick, isActiveTagsListLimited }: DisplayButtonProps) => {
  const spanClassNames = classnames("bg-light py-xs px-sm rounded-base", "text-body leading-lg font-bold text-sm");
  const ActiveTagsList = isActiveTagsListLimited ? LimitedActiveTagsList : FullActiveTagsList;

  return (
    <>
      {isEmpty(tags) ? (
        <div
          className={classnames("cursor-pointer hover:text-body", isOpen ? "text-body" : "text-secondary")}
          style={isActiveTagsListLimited ? { width: "260px" } : {}}
          onClick={onClick}
          data-testid="add-issue-tag"
        >
          <span className={spanClassNames}>
            <i className="fa fa-tag" />
          </span>
        </div>
      ) : (
        <ActiveTagsList tags={tags} onClick={onClick} classNames={spanClassNames} />
      )}
    </>
  );
};

export default DisplayButton;
