import React from "react";

type FieldErrorProps = {
  fieldName: string;
  children: React.ReactNode;
  className?: string;
};

function FieldError({ fieldName, children, className }: FieldErrorProps) {
  return (
    <div className={`flex items-baseline text-danger mb-l mt-xs ${className}`} data-testid={`field-error-${fieldName}`}>
      <i className="text-2xs fa fa-exclamation-circle mr-xs" aria-hidden="true" />
      {children}
    </div>
  );
}

export default FieldError;
