import React, { useState } from "react";
import classnames from "classnames";
import { without } from "lodash";
import { Checkbox } from "@hopper/checkbox";

import { FlexibleTestingIssueTag } from "types/graphql";

type SelectableTagOptionProps = {
  tag: FlexibleTestingIssueTag;
  activeTags: FlexibleTestingIssueTag[];
  active: boolean;
  handleTagsUpdate: (tags: Array<FlexibleTestingIssueTag>) => Promise<void> | void;
  onEdit: (tag: FlexibleTestingIssueTag) => void;
  isReadOnly: boolean;
  tagCount?: number;
};

const SelectableTagOption = ({
  tag,
  active,
  handleTagsUpdate,
  onEdit,
  activeTags,
  isReadOnly,
  tagCount,
}: SelectableTagOptionProps) => {
  const [showEditButton, setShowEditButton] = useState(false);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    onEdit(tag);
  };

  const handleOnClick = (currentActiveTags: FlexibleTestingIssueTag[]) => {
    if (active) {
      handleTagsUpdate(without(currentActiveTags, tag));
    } else {
      currentActiveTags.push(tag);
      handleTagsUpdate(currentActiveTags);
    }
  };

  const editLinkClass = "flex items-center text-xs leading-sm text-link cursor-pointer hover:underline";

  return (
    <>
      <div
        key={tag.id}
        className={classnames(
          "flex justify-between px-lg my-sm py-1 text-left",
          "hover:bg-light cursor-pointer text-sm text-body leading-lg",
        )}
        onClick={() => handleOnClick(activeTags)}
        onMouseEnter={() => setShowEditButton(true)}
        onMouseLeave={() => setShowEditButton(false)}
        data-testid={active ? "active-tag-option" : "tag-option"}
      >
        <Checkbox isChecked={active} readOnly />
        <span className={`flex-grow text-align-left ${isReadOnly && tagCount === 0 && "opacity-50"}`}>{tag.name}</span>
        {showEditButton && !isReadOnly && (
          <span className={editLinkClass} onClick={handleClick} data-testid="edit-custom-tag">
            Edit
          </span>
        )}
        {isReadOnly && <span className={`${tagCount ? "opacity-75" : "opacity-25"} font-normal`}>{tagCount}</span>}
      </div>
    </>
  );
};

export default SelectableTagOption;
