import { FlexibleTestingExternalTestDetails } from "types/graphql";
import { gql, useQuery, ApolloError } from "@apollo/client";

type useExternalTestDetailsResult = {
  externalTestDetails: FlexibleTestingExternalTestDetails;
  isLoading: boolean;
  error?: ApolloError;
};

export const EXTERNAL_TEST_DETAILS_QUERY = gql`
  query FlexibleTestingExternalTestDetails($externalTestId: String!, $source: String!) {
    flexibleTestingExternalTestDetails(externalTestId: $externalTestId, source: $source) {
      name
      description
      numberOfCases
    }
  }
`;

export const useExternalTestDetails = (externalTestId: string, source: string): useExternalTestDetailsResult => {
  const { data, loading, error } = useQuery(EXTERNAL_TEST_DETAILS_QUERY, { variables: { externalTestId, source } });

  return {
    externalTestDetails: data?.flexibleTestingExternalTestDetails,
    isLoading: loading,
    error,
  };
};

export default useExternalTestDetails;
