import React from "react";
import { Heading } from "@hopper/heading";

type HeaderSectionProps = {
  shouldShowCloneButton: boolean;
  cloneButtonComponent?: React.ReactNode;
};

function HeaderSection({ shouldShowCloneButton, cloneButtonComponent }: HeaderSectionProps): JSX.Element {
  return (
    <div className="flex justify-between items-center">
      <Heading size={1}>Launch</Heading>
      {shouldShowCloneButton && cloneButtonComponent}
    </div>
  );
}

export default HeaderSection;
