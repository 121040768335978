import React, { useState } from "react";
import { FlexibleTestingTest } from "types/graphql";
import { DecoratedText } from "@hopper/decorated_text";
import CloneTestButton from "components/flexible_testing/result_details_page/clone_test_buton";
import pluralize from "pluralize";
import { Events } from "components/configuration";
import { testEnvironmentsToLabel } from "components/flexible_testing/shared/test_environments";
import DescriptionField from "./description_field";
import { formatAmount } from "components/utils/format_amount";
import { EndTestButton } from "./end_test_button";
import { getPathForCloning } from "components/flexible_testing/shared/test_cloning/test_cloning";
import { LinkedTestCycle } from "./linked_test_cycle";
import { Checkbox } from "@hopper/checkbox";
import useToggleRequiredAttachmentForPass from "./use_toggle_required_attachment_for_pass";
import DisableAutomaticTesterManagementButton from "./disable_automatic_tester_management_button";
import Authorization from "app/authorization/authorization";

export type StandardTestDescriptionParams = {
  flexibleTestingTest: FlexibleTestingTest;
  testWindow?: FlexibleTestingTest["testWindow"];
};

const StandardTestDescription = ({ flexibleTestingTest, testWindow }: StandardTestDescriptionParams): JSX.Element => {
  const testCasesLength = flexibleTestingTest.cases.totalCount || 0;
  const testEnvironments = flexibleTestingTest.environments?.map(environment => environment.platform as string) || [];
  const testEnvironmentLabels = testEnvironmentsToLabel(testEnvironments);
  const shouldDisplayEndTestButton = flexibleTestingTest.type === "Supervised Test" && testWindow?.status === "open";
  const shouldDisplayTestCycleLink = flexibleTestingTest.type === "Supervised Test";
  const shouldDisplayManualManagementButton =
    flexibleTestingTest.supportsAutomaticTesterManagement && flexibleTestingTest.automaticTesterManagementEnabled;
  const [displayEndTestButton, setDisplayEndTestButton] = useState(shouldDisplayEndTestButton);
  const { toggleRequiredAttachmentForPass } = useToggleRequiredAttachmentForPass();

  return (
    <div data-testid="test-description">
      <div className="my-4xl">
        <DescriptionField title="Activity type">
          <span data-testid="test-activity-type">{flexibleTestingTest.activityType || "N/A"}</span>
        </DescriptionField>
        <Authorization roles={["testManager"]}>
          <>
            {shouldDisplayTestCycleLink && (
              <DescriptionField title="Test cycle">
                <LinkedTestCycle flexibleTestingTest={flexibleTestingTest} />
              </DescriptionField>
            )}
          </>
        </Authorization>
        {flexibleTestingTest.sdlcStage && flexibleTestingTest.desiredOutcomes && (
          <DescriptionField title={`${flexibleTestingTest.sdlcStage} test`}>
            <ul className="mb-md list-disc ml-2xl">
              {flexibleTestingTest.desiredOutcomes?.map(desiredOutcome => (
                <li key={desiredOutcome.name}>{desiredOutcome.name}</li>
              ))}
            </ul>
          </DescriptionField>
        )}
        <DescriptionField title="Application link">
          <a className="text-link" data-testid="test-application-link" href={flexibleTestingTest.applicationUrl || ""}>
            {flexibleTestingTest.applicationUrl}
          </a>
        </DescriptionField>
        {testEnvironmentLabels.length > 0 && (
          <DescriptionField title="Environment(s) included in this test">
            <span className="text-darkest">{testEnvironmentLabels.join(", ")}</span>
          </DescriptionField>
        )}
        <DescriptionField title="Credentials and access instructions">
          <DecoratedText text={flexibleTestingTest.credentialsAndAccessInstructions || "N/A"} />
        </DescriptionField>
        <DescriptionField title="Additional URLs and resources">
          <DecoratedText text={flexibleTestingTest.additionalUrlsAndResources || "N/A"} />
        </DescriptionField>
        <DescriptionField title="Test Run Objective">
          <span data-testid="test-objective">
            <DecoratedText text={flexibleTestingTest.description} />
          </span>
        </DescriptionField>
        <DescriptionField title="Test Cases">
          <Authorization roles={["testManager"]}>
            <Checkbox
              label="Require attachments for passed test cases"
              onChange={() => toggleRequiredAttachmentForPass(flexibleTestingTest.id)}
              isChecked={flexibleTestingTest.requiredAttachmentForPass}
            />
          </Authorization>
          <div data-testid="test-cases">
            <span className="w-sm h-sm self-center inline-block rounded-full mr-sm bg-primary" />
            {testCasesLength} test {pluralize("case", testCasesLength)}
          </div>
        </DescriptionField>
        <Authorization roles={["testManager"]}>
          <>
            <DescriptionField title="Payout per Test Case">
              {formatAmount(flexibleTestingTest.payoutBaseAmount)}
            </DescriptionField>
            <DescriptionField title="Apply country tier multipliers">
              {flexibleTestingTest.applyCountryPayoutMultiplier ? "Yes" : "No"}
            </DescriptionField>
          </>
        </Authorization>
        <div className="flex gap-2xl">
          <Authorization roles={["testManager", "customerUser"]}>
            <CloneTestButton
              testTrackingId={flexibleTestingTest._id}
              testId={flexibleTestingTest.id}
              pathForCloning={getPathForCloning(flexibleTestingTest)}
              trackingEventName={Events.FLEXIBLE_TESTING_TEST_CLONED}
            />
          </Authorization>
          <Authorization roles={["testManager"]}>
            <>
              {displayEndTestButton && (
                <EndTestButton testId={flexibleTestingTest.id} onClick={() => setDisplayEndTestButton(false)} />
              )}
            </>
          </Authorization>
        </div>
        <Authorization roles={["testManager"]}>
          <>
            {shouldDisplayManualManagementButton && (
              <div className="mt-md">
                <DisableAutomaticTesterManagementButton testId={flexibleTestingTest.id} />
              </div>
            )}
          </>
        </Authorization>
      </div>
    </div>
  );
};

export default StandardTestDescription;
