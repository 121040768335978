import React from "react";
import { SaveChosenNameForm } from "components/flexible_testing/shared/save_chosen_name_form";
import { ModalHeader } from "@hopper/modal";

type AskForOrganizationNameProps = {
  onSave: () => void;
};

function AskForOrganizationName({ onSave }: AskForOrganizationNameProps) {
  return (
    <>
      <ModalHeader>Congratulations!</ModalHeader>
      <div className="font-bold mt-xl mb-5xl">&#x1F389; You’ve launched your first test!</div>
      <div className="mb-2xl">Before you view your results, please let us know your company name</div>
      <SaveChosenNameForm onSave={onSave} />
    </>
  );
}

export default AskForOrganizationName;
