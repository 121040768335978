import React from "react";
import * as yup from "yup";
import { useFormikContext } from "formik";

import { RequestedTest, defaultValues } from "../requested_test_form";
import ActivityCard from "../activity_card";
import { useCustomerRequestContext } from "components/flexible_testing/test_request_page/customer_request_context";
import { useHistory } from "react-router";
import { websiteContentPaths } from "components/configuration";

export enum Activity {
  TestCaseRun = "test_case_run",
  Exploratory = "exploratory",
  Localization = "localization",
  Translation = "translation",
}

export const ACTIVITIES = [
  {
    type: "functional test",
    title: "Test case run",
    code: Activity.TestCaseRun,
    description:
      "Testers execute test cases to ensure your app functions as expected. " +
      "The results are moderated and delivered within 48 hours (subject to change based on requirements)",
  },
  {
    type: "functional test",
    title: "Exploratory test",
    code: Activity.Exploratory,
    description:
      "Testers explore your app and find bugs. " +
      "The bugs are moderated and delivered within 48 hours (subject to change based on requirements)",
  },
  {
    type: "local insights",
    title: "Localization test",
    code: Activity.Localization,
    description:
      "Testers explore your app from their unique cultural perspective and report insights. " +
      "These are moderated and delivered within 48 hours (subject to change based on  requirements)",
  },
  {
    type: "language quality",
    title: "Translation test",
    code: Activity.Translation,
    description:
      "Testers explore your app and report translation issues. " +
      "The issues are moderated and delivered within 48 hours (subject to change based on requirements)",
  },
] as const;

export const validations = yup.object().shape({
  activity: yup.string().required("Please select an activity."),
});

const ActivityStepContent = ({ nextStep }: { nextStep: () => unknown }) => {
  const { values, resetForm } = useFormikContext<RequestedTest>();
  const { tests } = useCustomerRequestContext();
  const history = useHistory();

  const onSelect = (activity: RequestedTest["activity"]) => {
    if (values.activity !== activity) resetForm({ values: { ...defaultValues, activity, name: values.name } });

    nextStep();
  };

  return (
    <div data-testid="activity-step-content">
      <p className="mb-2xl text-sm text-secondary">What testing activity would you like to start with?</p>
      <div className="grid grid-cols-1 gap-4xl md:grid-cols-2 lg:grid-cols-3">
        {ACTIVITIES.map(({ code, type, title, description }) => (
          <ActivityCard
            key={code}
            type={type}
            title={title}
            description={description}
            code={code}
            onSelect={() => onSelect(code)}
          />
        ))}

        <ActivityCard
          type="Custom test"
          title="Create your own"
          description="Do you have custom requirements? We’ll work with you to design your test and deliver exactly what you need. Delivery times and cost are calculated based on your requirements"
          code="custom"
          onSelect={() => history.push(websiteContentPaths.advancedTestRequestCustomTest)}
          disabled={tests.length > 0}
        />
      </div>
    </div>
  );
};

export default ActivityStepContent;
