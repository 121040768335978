import React from "react";
import { OptionsToggle, OptionsToggleProps } from "@hopper/options_toggle";
import { capitalize } from "lodash";
import { useFormikContext } from "formik";

import { RequestedTest } from "./requested_test_form";
import { ActivityStepContent, validations as activityValidations } from "./activity_step_content";
import AppStepContent, { validations as appValidations } from "./app_step_content";
import { ScopeStepContent, validations as scopeValidations } from "./scope_step_content";
import { TargetingStepContent, validations as targetingValidations } from "./targeting_step_content";
import { ReviewStepContent, validations as reviewValidations } from "./review_step_content";

export const WIZARD_STEPS = [
  {
    name: "activity",
    component: ActivityStepContent,
    validations: activityValidations,
  },
  {
    name: "app",
    component: AppStepContent,
    validations: appValidations,
  },
  {
    name: "scope",
    component: ScopeStepContent,
    validations: scopeValidations,
  },
  {
    name: "targeting",
    component: TargetingStepContent,
    validations: targetingValidations,
  },
  {
    name: "review",
    component: ReviewStepContent,
    validations: reviewValidations,
  },
] as const;

export const getStepByName = (name: WizardStepName) =>
  WIZARD_STEPS.find(({ name: stepName }) => stepName === name) as typeof WIZARD_STEPS[number];

export type WizardStepName = typeof WIZARD_STEPS[number]["name"];

const buildToggleOptions = (values: RequestedTest) => {
  let prevInvalid = false;

  return WIZARD_STEPS.map(({ name }, index) => {
    const isValid = getStepByName(name).validations.isValidSync(values);

    const option = {
      value: name,
      label: `${index + 1}. ${capitalize(name)}`,
      disabled: prevInvalid,
    };

    prevInvalid = prevInvalid || !isValid;

    return option;
  }) as OptionsToggleProps["optionsList"];
};

const Stepper = ({
  activeStep,
  setActiveStep,
}: {
  activeStep: WizardStepName;
  setActiveStep: React.Dispatch<React.SetStateAction<WizardStepName>>;
}) => {
  const { values } = useFormikContext<RequestedTest>();

  return (
    <OptionsToggle
      onOptionChange={id => setActiveStep(id as WizardStepName)}
      optionsList={buildToggleOptions(values)}
      selectedOption={activeStep}
    />
  );
};

export default Stepper;
