// useIssueStats.ts
import { gql, useQuery } from "@apollo/client";

export const ISSUE_STATS_QUERY = gql`
  query IssueStats($issueTagsIdsEq: [ID!], $issueTagsIdsContains: [ID!]) {
    issueStats(issueTagsIdsEq: $issueTagsIdsEq, issueTagsIdsContains: $issueTagsIdsContains) {
      newIssuesCount
      acceptedIssuesCount
      rejectedIssuesCount
    }
  }
`;

type IssueStatsResponse = {
  issueStats: {
    newIssuesCount: number;
    acceptedIssuesCount: number;
    rejectedIssuesCount: number;
  };
};

type IssueStatsFilter = {
  issue_tags_ids_eq?: string[];
  issue_tags_ids_contains?: string[];
};

function useIssueStats(filters?: IssueStatsFilter) {
  const { data, loading, error } = useQuery<IssueStatsResponse>(ISSUE_STATS_QUERY, {
    variables: {
      issueTagsIdsEq: filters?.issue_tags_ids_eq,
      issueTagsIdsContains: filters?.issue_tags_ids_contains,
    },
  });

  const issueStats = data?.issueStats;

  return {
    issueStats,
    isLoading: loading,
    error,
  };
}

export default useIssueStats;
