import { gql, useMutation } from "@apollo/client";
import { FinishCaseResultModerationActivityInput, FinishCaseResultModerationActivityPayload } from "types/graphql";

export const FINISH_CASE_RESULT_MODERATION_ACTIVITY_MUTATION = gql`
  mutation FinishCaseResultModerationActivity($input: FinishCaseResultModerationActivityInput!) {
    finishCaseResultModerationActivity(input: $input) {
      successful
      errors {
        code
        message
      }
    }
  }
`;

type useFinishCaseResultModerationActivityResult = {
  finishCaseResultModerationActivity: (caseResultId: string) => Promise<void>;
};

export const useFinishCaseResultModerationActivity = (): useFinishCaseResultModerationActivityResult => {
  const [finishCaseResultModerationActivityMutation] = useMutation<
    { finishCaseResultModerationActivity: FinishCaseResultModerationActivityPayload },
    { input: FinishCaseResultModerationActivityInput }
  >(FINISH_CASE_RESULT_MODERATION_ACTIVITY_MUTATION);

  return {
    finishCaseResultModerationActivity: async (caseResultId: string): Promise<void> => {
      await finishCaseResultModerationActivityMutation({ variables: { input: { caseResultId } } });
    },
  };
};
