import React from "react";
import { Modal } from "@hopper/legacy_modal";
import { Heading } from "@hopper/heading";
import { Button } from "@hopper/button";
import { intercom } from "components/configuration";
import { DecoratedText } from "@hopper/decorated_text";

type SuccessPopupProps = {
  isOpen?: boolean;
  onCloseModalClicked?: () => void;
  errorMessage?: string;
};

function TestRequestErrorModal({
  isOpen = false,
  onCloseModalClicked = () => {},
  errorMessage,
}: SuccessPopupProps): JSX.Element {
  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onCloseModalClicked}
      position="center"
      size="lg"
      isOpen={isOpen}
      onOverlayClicked={onCloseModalClicked}
    >
      <div
        className="flex flex-col items-center justify-center max-w-xs sm:max-w-full text-center"
        style={{ width: "28rem" }}
      >
        <div
          className="rounded-full flex items-center justify-center mb-2xl"
          style={{ backgroundColor: "rgba(236, 107, 88, 0.12)", height: "4.5rem", width: "4.5rem" }}
          data-testid="error-icon"
        >
          <i className="fa fa-exclamation text-danger opacity-100 text-3xl" aria-hidden="true" />
        </div>
        {!!errorMessage ? (
          <>
            <Heading size={3}>The test has not been launched</Heading>
            <DecoratedText text={errorMessage} />
          </>
        ) : (
          <>
            <Heading size={3}>Your test request has not been sent</Heading>
            <Heading size={4}>There was a problem processing your test request</Heading>
            <Heading size={4}>
              <p className="text-center">Please try again or get in touch with us through the</p>
              <p className="text-center">
                <a href="#" className={`${intercom.customLauncherClassname} text-link underline`}>
                  Intercom messenger
                </a>
                {" to resolve the issue"}
              </p>
            </Heading>
          </>
        )}
        <Button type="button" onClick={onCloseModalClicked} className="mt-md">
          Try again
        </Button>
      </div>
    </Modal>
  );
}

export default TestRequestErrorModal;
