import { useQuery } from "@apollo/client";
import { GET_RESULTS_HEADER_DATA } from "./results_header_data_query";

function useResultsHeaderData(testId?: string) {
  const { loading, data } = useQuery(GET_RESULTS_HEADER_DATA, {
    variables: { testId },
    skip: !testId,
  });

  return {
    isLoading: loading,
    platformTest: data?.platformTest,
  };
}

export { useResultsHeaderData };
