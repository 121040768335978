import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Events, websiteContentPaths } from "components/configuration";
import { BackToPreviousPageLink } from "../shared/back_to_previous_page";
import { TesterOverviewTemplate } from "./tester_overview_template";
import { Heading } from "@hopper/heading";
import { Label } from "@hopper/label";
import { flatMap } from "lodash";

import TesterCasesTables from "./tester_cases_tables";
import { FlagIcon } from "../shared/flag";
import {
  FlexibleTestingCaseResultEditRequest,
  FlexibleTestingCaseResultRelatedIssue,
  FlexibleTestingTest,
  FlexibleTestingTestCase,
  FlexibleTestingTestCaseResult,
  TestParticipant,
} from "types/graphql";
import { TestCaseResultsPanel } from "components/flexible_testing/tester_overview/test_case_results_panel";
import useTestParticipant from "./use_test_participant";
import StatusLabel from "../result_details_page/testers/status_label";
import { Loader } from "@hopper/loading";
// @ts-ignore
import { datetimeFormat } from "components/organization/shared_functions/date_format";
import { useTracking } from "react-tracking";
import { useCurrentUser } from "app/current_user/use_current_user";
import MassPassingWarning from "../shared/mass_passing_warning/mass_passing_warning";
import OpenPrivateChatButton, { ButtonType } from "components/flexible_testing/shared/chat/open_private_chat_button";
import { mapParticipant } from "components/flexible_testing/shared/chat/helpers";
import TesterProgressBar from "components/flexible_testing/result_details_page/testers/tester_progress_bar";
import pluralize from "pluralize";
import { PublishTesterResultsButton } from "components/flexible_testing/shared/publish_tester_results_button";
import { JOINED_STATUSES } from "../result_details_page/testers/tester_group_participant";
import Authorization from "app/authorization/authorization";
import { RemoveTesterButton } from "../shared/remove_tester_button";
import { useFeatureFlag } from "app/feature_flags";

type RouteParams = {
  testId: string;
  participantId: string;
};

export type AssignedCaseWithOwnResult = Omit<FlexibleTestingTestCase, "caseResults"> & {
  caseResult: FlexibleTestingTestCaseResult;
};

function TesterOverview(): JSX.Element {
  const { testId, participantId } = useParams<RouteParams>();
  const tracking = useTracking();
  const currentUser = useCurrentUser();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedTestCaseResultId, setSelectedTestCaseResultId] = useState<string>();
  const { testParticipant } = useTestParticipant(participantId);
  const CTMsChatAccessEnabled = useFeatureFlag("ctms-chats-access", false);

  useEffect(() => {
    tracking.trackEvent({
      action: "track",
      name: Events.TESTER_DETAIL_PAGE_VIEWED,
      eventProperties: {
        test_id: testId,
        participant_id: participantId,
        user_id: currentUser?.publicId || "",
        organization_id: currentUser?.organization.publicId || "",
      },
    });
  }, []);

  if (!testParticipant) return <Loader isLoaded={false} />;

  const participant = testParticipant && mapParticipant(testParticipant);
  const assignedCases = testParticipant?.assignedCases.edges.map(edge => edge?.node) || [];
  const caseResults = testParticipant?.caseResults.edges.map(edge => edge?.node) || [];
  const testCases = mapTestCasesWithResults(assignedCases, caseResults);
  const test = testParticipant.test;
  const suspectedMassPassing = testParticipant.suspectedMassPassing;
  const hourlyCaseFieldCompletionRate = testParticipant.hourlyCaseFieldCompletionRate;
  const pendingIssuesCount = ((test && extractPendingIssues(test, testParticipant)) || []).length;
  const pendingEditRequestsCount = ((test && extractPendingEditRequests(test, testParticipant)) || []).length;
  const hasParticipantJoined = participant && JOINED_STATUSES.includes(participant.status);
  const alreadyPublished = participant.status === "results_published";
  const isRemovable = testParticipant.isRemovable;
  const hasStrictTesterRemoval = test.hasStrictTesterRemoval;
  const participantRemovalReasons = test.participantRemovalReasons;

  const closePanel = () => {
    setSelectedTestCaseResultId(undefined);
    setIsPanelOpen(false);
  };

  const openTestCaseResultPanel = (testCaseResult: FlexibleTestingTestCaseResult) => {
    setSelectedTestCaseResultId(testCaseResult.id);
    setIsPanelOpen(true);
  };

  const testerEnvironmentText =
    testParticipant.operatingSystemAndVersion &&
    [testParticipant.operatingSystemAndVersion, testParticipant.browserOrDevice].filter(Boolean).join(" - ");

  return (
    <>
      <TesterOverviewTemplate
        navigation={
          <div className="grid grid-cols-12 my-sm">
            <div className="col-span-9 pt-sm">
              <BackToPreviousPageLink
                to={{
                  pathname: websiteContentPaths.flexcaseResult.replace(":id", testId),
                  search: "?tab=testers",
                }}
                color="secondary"
              >
                Back to Testers
              </BackToPreviousPageLink>
            </div>
          </div>
        }
      >
        <div data-testid="tester-overview-page">
          <Heading size={5}>{testParticipant.test.name}</Heading>
          <div className="mb-3xl pt-sm grid grid-cols-1 items-start pb-lg">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div data-testid="participant-personal-info">
                <Heading size={2}>{testParticipant.fullName}</Heading>
                <span data-testid="participant-email" className="text-secondary">
                  {testParticipant.email}
                </span>

                <div className="flex items-center my-xl" data-testid="participant-environment">
                  {testerEnvironmentText && <Label className="flex items-center">{testerEnvironmentText}</Label>}
                  {testParticipant.country && (
                    <Label className="flex items-center ml-md">
                      <FlagIcon className="mr-sm" code={testParticipant.country.code?.toLowerCase()} />
                      {testParticipant.country?.name}
                    </Label>
                  )}
                </div>

                <div>
                  {testParticipant.joinedAt && (
                    <p className="text-xs text-secondary">
                      Joined {datetimeFormat(new Date(testParticipant.joinedAt))}
                    </p>
                  )}
                  {testParticipant.finishedAt && (
                    <p className="text-xs text-secondary">
                      Finished {datetimeFormat(new Date(testParticipant.finishedAt))}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-xl md:mt-0">
                <div className="mb-lg">
                  <div className="flex items-center gap-xs">
                    <Authorization roles={["testManager"]}>
                      <>
                        {!alreadyPublished && participant.resultsPublishable && (
                          <PublishTesterResultsButton participant={participant} testId={testId} />
                        )}
                      </>
                    </Authorization>
                    <Authorization roles={["testManager", "communityTestManager"]}>
                      <>
                        {hasStrictTesterRemoval && participantRemovalReasons && (
                          <RemoveTesterButton
                            participants={[participant]}
                            testId={testId}
                            disabled={!isRemovable}
                            hasStrictTesterRemoval={hasStrictTesterRemoval}
                            participantRemovalReasons={participantRemovalReasons}
                          />
                        )}
                      </>
                    </Authorization>
                    {test.code && (
                      <>
                        <Authorization roles={["testManager"]}>
                          <OpenPrivateChatButton
                            participant={participant}
                            testId={testId}
                            testCode={test.code}
                            testName={test.name}
                            communityTestManagersEnabled={testParticipant.test.communityTestManagersEnabled}
                            variant={ButtonType.Action}
                          />
                        </Authorization>
                        {CTMsChatAccessEnabled && (
                          <Authorization roles={["communityTestManager"]}>
                            <OpenPrivateChatButton
                              participant={participant}
                              testId={testId}
                              testCode={test.code}
                              testName={test.name}
                              communityTestManagersEnabled={true}
                              variant={ButtonType.Action}
                            />
                          </Authorization>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <StatusLabel status={testParticipant.status || ""} />
                {hasParticipantJoined && (
                  <div className="w-[33%] my-md">
                    <TesterProgressBar
                      total={testCases.length || 0}
                      completed={participant.approvedTestCasesCount}
                      isPublished={participant.status === "results_published"}
                    />
                  </div>
                )}
                {pendingIssuesCount > 0 && (
                  <p className="text-sm text-secondary">
                    {`${pendingIssuesCount} ${pluralize("issue", pendingIssuesCount)} pending moderation`}
                  </p>
                )}

                {pendingEditRequestsCount > 0 && (
                  <p className="text-sm text-secondary">
                    {`${pendingEditRequestsCount} test case ${pluralize("result", pendingEditRequestsCount)}
                    pending edit request`}
                  </p>
                )}
              </div>
            </div>

            {suspectedMassPassing && (
              <div className="grid md:grid-cols-2 mt-2xl mb-md">
                <MassPassingWarning
                  testId={testId!}
                  participantId={participantId}
                  hourlyCaseFieldCompletionRate={hourlyCaseFieldCompletionRate!}
                />
              </div>
            )}
          </div>
          <TesterCasesTables
            onTestCaseResultRowClick={openTestCaseResultPanel}
            testCases={testCases}
            testerId={testParticipant.testerId}
            selectedTestCaseResultId={selectedTestCaseResultId}
          />
        </div>
      </TesterOverviewTemplate>

      {selectedTestCaseResultId && (
        <TestCaseResultsPanel
          isOpen={isPanelOpen}
          testCaseResultId={selectedTestCaseResultId}
          onPanelClose={closePanel}
        />
      )}
    </>
  );
}

function extractPendingIssues(
  test: FlexibleTestingTest,
  participant: TestParticipant,
): FlexibleTestingCaseResultRelatedIssue[] {
  const relatedIssues = flatMap(test?.cases?.edges || [], caseEdge =>
    flatMap(caseEdge?.node?.caseResults?.edges || [], caseResultEdge =>
      caseResultEdge?.node?.tester?.id === participant.testerId ? caseResultEdge?.node?.relatedIssues : [],
    ),
  );

  return relatedIssues.filter(issue => issue.moderationStatus === "not_moderated");
}

function extractPendingEditRequests(
  test: FlexibleTestingTest,
  participant: TestParticipant,
): FlexibleTestingCaseResultEditRequest[] {
  const latestEditRequests = flatMap(test?.cases?.edges || [], caseEdge =>
    flatMap(caseEdge?.node?.caseResults?.edges || [], caseResultEdge =>
      caseResultEdge?.node?.tester?.id === participant.testerId
        ? caseResultEdge?.node?.latestCaseResultEditRequest
        : null,
    ),
  ).filter(Boolean) as FlexibleTestingCaseResultEditRequest[];

  return latestEditRequests.filter(editRequest => ["requested", "overdue"].includes(editRequest?.status || ""));
}

const mapTestCasesWithResults = (
  assignedCases: FlexibleTestingTestCase[],
  caseResults: FlexibleTestingTestCaseResult[],
): AssignedCaseWithOwnResult[] =>
  assignedCases.map(assignedCase => {
    const caseResult = caseResults.find(caseResult => caseResult?.testCase?.id === assignedCase?.id);

    return {
      ...assignedCase,
      caseResult: caseResult || ({} as FlexibleTestingTestCaseResult),
    };
  });

export default TesterOverview;
