import React from "react";
import { Label } from "@hopper/label";
import classnames from "classnames";

const COLOR_BG_CLASS_MAPPING = {
  primary: "bg-primary",
  success: "bg-success",
  dark: "bg-dark",
} as const;

type ProgressBarProps = {
  total: number;
  completed: number;
  color?: keyof typeof COLOR_BG_CLASS_MAPPING;
};

// TODO: Hopper UI's ProgressBar component is not flexible enough to satisfy the design.
// .     So I'm adding a compact reimplementation of it here and will expand the current Hopper UI component to
//       support the features needed here

export const ProgressBar = ({ total, completed, color = "primary" }: ProgressBarProps) => {
  const completion = Math.min(~~((completed * 100) / total), 100);
  const compactNumber = Intl.NumberFormat("en", { notation: "compact" }).format;

  return (
    <Label className="flex items-center px-sm">
      <div className="mr-sm">
        {compactNumber(~~completed)} / {compactNumber(total)}
      </div>
      <div className="w-full">
        <div className="bg-muted rounded">
          <div
            className={classnames("rounded", COLOR_BG_CLASS_MAPPING[color])}
            style={{ width: `${completion}%`, height: "0.3rem" }}
            role="progressbar"
          />
        </div>
      </div>
    </Label>
  );
};
