import { useQuery } from "@apollo/client";

import { GET_TEST_DETAILS } from "./test_details_query";

function useTestDetails(testId?: string) {
  const { loading, data } = useQuery(GET_TEST_DETAILS, {
    variables: { testId },
    skip: !testId,
  });

  return {
    isLoading: loading,
    platformTest: data?.platformTest,
  };
}

export { useTestDetails };
