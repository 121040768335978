import { gql, useMutation } from "@apollo/client";
import { ImportTestCasesFromTestrailTestRunPayload, ImportTestCasesFromTestrailTestRunInput } from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { TestCase } from "types/test_cases";

const IMPORT_TEST_CASES_FROM_TESTRAIL_TESTRUN = gql`
  mutation ImportTestCasesFromTestrailTestRun($input: ImportTestCasesFromTestrailTestRunInput!) {
    importTestCasesFromTestrailTestRun(input: $input) {
      result
      errors {
        message
        code
      }
      successful
    }
  }
`;

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Test Cases have been successfully imported.",
  });
};

const displayError = (message: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message,
  });
};

type SetValueType = (value: Array<TestCase> | null, shouldValidate?: boolean) => void;
export const useImportTestCases = (setTestCases: SetValueType) => {
  const [importTestCasesMutation, { loading }] = useMutation<
    { importTestCasesFromTestrailTestRun: ImportTestCasesFromTestrailTestRunPayload },
    { input: ImportTestCasesFromTestrailTestRunInput }
  >(IMPORT_TEST_CASES_FROM_TESTRAIL_TESTRUN, {
    onCompleted: data => {
      const { successful, result, errors } = data.importTestCasesFromTestrailTestRun;

      if (successful) {
        displaySuccess();
        if (result !== undefined) {
          setTestCases(result as TestCase[]);
        } else {
          setTestCases([]);
        }
      } else {
        displayError(errors[0]!.message);
      }
    },
    onError: e => {
      displayError(e.message);
    },
  });

  const fetchTestCases = async (runId: string) => {
    runId = runId.charAt(0) === "R" ? runId.substring(1) : runId;

    await importTestCasesMutation({ variables: { input: { testRunId: runId } } });
  };

  return { fetchTestCases, loading };
};
