import React from "react";
import gql from "graphql-tag";
import { useSubscription } from "@apollo/client";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import OrganizationUserNotificationContent from "../organization_user_notifications/components/notification_content";

export const GET_NOTIFICATIONS = gql`
  subscription notifications {
    notificationWasSent {
      kind
      publicId
      details
    }
  }
`;

const NotificationsSubscription = ({ currentUser }) => {
  useSubscription(GET_NOTIFICATIONS, {
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData["data"]) {
        notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
          type: "info",
          message: (
            <OrganizationUserNotificationContent notification={subscriptionData["data"]["notificationWasSent"]} />
          ),
          details: subscriptionData["data"]["notificationWasSent"],
        });
      } else {
        console.error("Missing data field from subscription");
      }
    },
  });

  return null;
};

export default NotificationsSubscription;
