import React from "react";

import { usePreviousAdvancedTestAvailable } from "components/flexible_testing/shared/use_previous_test_available";
import { CloneTestRunButton } from "components/flexible_testing/shared/clone_test_run_button";
import { websiteContentPaths } from "components/configuration";

export function AdvancedTestInfoSection() {
  const { isLoading, hasNoPreviousTests } = usePreviousAdvancedTestAvailable();

  return (
    <div className="mt-3xl mb-3xl grid grid-cols-1 md:grid-cols-2">
      <div>
        <div className="text-xl font-black">Does your release have bespoke testing needs? We can help</div>
        <div className="text-sm mt-xs">
          Advanced tests support any custom or niche testing need and deliver results in 48 hours or less
        </div>
      </div>
      <CloneTestRunButton
        testsPath={websiteContentPaths.advancedTestsForCloning}
        isLoading={isLoading}
        hasNoPreviousTests={hasNoPreviousTests}
      />
    </div>
  );
}
