import React from "react";
import { Modal } from "@hopper/legacy_modal";
import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";

type EditRequestCancelConfirmationProps = {
  onClose: () => void;
  onConfirm: () => void;
};

export function EditRequestCancelConfirmation({ onConfirm, onClose }: EditRequestCancelConfirmationProps) {
  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={true}
      position="center"
      size="lg"
      onOverlayClicked={onClose}
    >
      <div
        className="flex flex-col max-w-xs sm:max-w-full ml-xl"
        data-testid="edit-request-cancel-confirmation-section"
      >
        <Heading size={2}>Cancel edit request?</Heading>
        <div>Are you sure you want to cancel the edit request?</div>
        <div className="mt-lg">
          The tester will no longer be able to edit the test case. You will still be able to edit the test case and
          request a new tester edit.
        </div>
        <div className="flex mt-4xl items-center justify-center">
          <Button variant="secondary" className="mr-xl" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={onConfirm}>
            Cancel edit request
          </Button>
        </div>
      </div>
    </Modal>
  );
}
