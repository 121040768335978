import { FlexibleTestingIssueTag, FlexibleTestingIssueTagEdge } from "types/graphql";
import { gql, useQuery } from "@apollo/client";

type useIssueTagsResult = {
  issueTags: Array<FlexibleTestingIssueTag>;
  isLoading: boolean;
};

export const ISSUE_TAGS_QUERY = gql`
  query FlexibleTestingIssueTags {
    flexibleTestingIssueTags {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const useIssueTags = (): useIssueTagsResult => {
  const { data, loading } = useQuery(ISSUE_TAGS_QUERY);
  const issueTags = data?.flexibleTestingIssueTags?.edges.map((edge: FlexibleTestingIssueTagEdge) => edge?.node) || [];

  return {
    issueTags,
    isLoading: loading,
  };
};
