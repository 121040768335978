import React from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { useHistory } from "react-router";

import useLaunchTest from "components/flexible_testing/vendor_test_request_confirmation_page/use_launch_test";
import UserConfirmation from "components/flexible_testing/vendor_test_request_confirmation_page/user_confirmation";
import Loader from "./loader";
import extractError from "./extract_error";

type GeneralFlowProps = {
  vendor: string;
  externalTestId: string;
};

const GeneralFlow = ({ vendor, externalTestId }: GeneralFlowProps): JSX.Element => {
  const history = useHistory();
  const params = queryString.parse(location.search);
  const { isLoading, launchTest } = useLaunchTest();

  const onLaunchConfirmed = () => {
    launchTest(vendor, params["project_id"], externalTestId).then(response => {
      const launchError = extractError(response);
      history.push(`${externalTestId}/confirmation`, { launchError });
    });
  };

  return (
    <>
      <Helmet title="Test Request Confirmation" />

      {isLoading ? <Loader /> : <UserConfirmation onLaunchConfirmed={onLaunchConfirmed} />}
    </>
  );
};

export default GeneralFlow;
