import React from "react";
import OrganizationSection from "./organization_section";
import OrganizationInitializer from "./initializer";
import { Analytics } from "app/analytics";
import { CurrentOrganizationUserProvider } from "../current_organization_user_provider";
import RootPageSelector from "../root_page_selector/root_page_selector";
import { AuthTokenProvider } from "../auth_token_provider";
import { UserSignInEventSender } from "../user_sign_in_event_sender";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { IntercomFailedToLoadEventSender } from "../intercom_failed_to_load_event_sender";
import { SessionValidator } from "../session_validator";

const Organization = props => (
  <AuthTokenProvider>
    <SessionValidator>
      <CurrentOrganizationUserProvider>
        <Analytics {...props}>
          <UserSignInEventSender>
            <IntercomFailedToLoadEventSender>
              <RootPageSelector>
                <OrganizationInitializer>
                  <OrganizationSection />
                </OrganizationInitializer>
              </RootPageSelector>
            </IntercomFailedToLoadEventSender>
          </UserSignInEventSender>
        </Analytics>
      </CurrentOrganizationUserProvider>
    </SessionValidator>
  </AuthTokenProvider>
);

function getLoginOptions() {
  let redirectedWithError = {};

  if (window.location.search.includes("error=403")) {
    const errorMessage =
      "Account with a given email does not exist in our system. Please verify the email or contact our Customer Support to create new account.";
    redirectedWithError = { redirectedWithError: errorMessage };
  } else if (window.location.search.includes("trial=expired")) {
    redirectedWithError = { trialExpired: true };
  }

  return redirectedWithError;
}

export default withAuthenticationRequired(Organization, { loginOptions: getLoginOptions() });
