import React, { useEffect } from "react";

import { useLogout } from "../shared/hooks/use_logout";

type SessionValidatorProps = {
  children: React.ReactNode;
};

function SessionValidator({ children }: SessionValidatorProps) {
  const { logout } = useLogout();

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
  }, []);

  function handleStorageChange({ key, newValue }: StorageEvent) {
    if (key === "isAuthenticated" && newValue === "false") {
      logout({
        returnTo: window.location.origin,
      });
    }
  }

  return <>{children}</>;
}

export default SessionValidator;
