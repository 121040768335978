import React from "react";

type MainImageAttachmentProps = {
  attachmentUrl: string;
  onClick: () => void;
};

function MainImageAttachment({ attachmentUrl, onClick }: MainImageAttachmentProps): JSX.Element {
  return (
    <div className="border-light border rounded-base mb-xl flex justify-center" data-testid="main-image-attachment">
      <img
        alt="Image attachment"
        src={attachmentUrl}
        className="cursor-pointer max-h-4xs sm:max-h-xs"
        onClick={() => onClick()}
      />
    </div>
  );
}

export default MainImageAttachment;
