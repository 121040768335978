import * as Sentry from "@sentry/browser";

import { useTracking } from "react-tracking";
import { useCurrentUser } from "app/current_user/use_current_user";
import { useReactiveVar } from "@apollo/client";
import { testCaseResultTrackingIds } from "components/flexible_testing/tester_overview/use_flexible_testing_test_case_result";

export function useTrackingModeration() {
  const currentUser = useCurrentUser();
  const organizationUserEmail = currentUser?.email;
  const tracking = useTracking();
  const { trackingCaseResultId, trackingTestId } = useReactiveVar(testCaseResultTrackingIds);

  if (!trackingCaseResultId || !trackingTestId) {
    Sentry.captureMessage("trackingCaseResultId and trackingTestId must be set", { level: "error" });
  }

  const tackingData = {
    tracking,
    organizationUserEmail,
    trackingCaseResultId,
    trackingTestId,
  };

  return tackingData;
}
