import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ProfilePage from "./pages/profile_page";

class OrganizationUserSettingsSection extends Component {
  render() {
    const {
      match: { path },
    } = this.props;

    return (
      <Switch>
        <Route path={path + "/profile"} component={ProfilePage} />
      </Switch>
    );
  }
}

export default OrganizationUserSettingsSection;
