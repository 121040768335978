import React from "react";

export const SuccessInfo = (): JSX.Element => (
  <>
    <div className="text-xl font-bold mb-xl">
      <span role="emoji" aria-label="success" className="mr-sm" data-testid="success-emoji">
        ✅
      </span>
      We have received your test request
    </div>
    <div className="leading-2xl">Our testers will begin testing your app soon.</div>
    <div className="leading-2xl">We will update your test as results come in from our testers.</div>
    <div className="mt-3xl text-xs text-dark">You can close this window</div>
  </>
);
