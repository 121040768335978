import React from "react";
import { useHistory } from "react-router-dom";
import { Heading } from "@hopper/heading";
import { Loader } from "@hopper/loading";

import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { BackToPreviousPageLink } from "components/flexible_testing/shared/back_to_previous_page";
import { Integration } from "types/graphql";
import { websiteContentPaths } from "components/configuration";
import { IntegrationStateLabel } from "../shared/integration_state_label";
import ResetButton from "./reset_button";
import { Testrail } from "./testrail";
import { AzureDevops } from "./azure_devops";
import { JiraConnect } from "./jira_connect";
import { useIntegrationReset } from "./use_integration_reset";
// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";

type IntegrationPageProps = {
  integration: Integration;
  loading: boolean;
};

export type ClientAuthenticationProps = {
  integration: Integration;
};

type IntegrationMap = {
  [key in string]: React.FunctionComponent<ClientAuthenticationProps>;
};

const IntegrationPage = ({ integration, loading }: IntegrationPageProps) => {
  const isActive = !!integration?.clientAuthenticationValid;
  const usesCardComponent = ["testrail", "azure_devops"].includes(integration?.code || "");
  const integrationMap: IntegrationMap = {
    testrail: Testrail,
    azure_devops: AzureDevops,
    jira_connect: JiraConnect,
  };
  const integrationCode = integration?.code || "none";
  const IntegrationComponent = integrationMap[integrationCode];
  const { resetIntegration } = useIntegrationReset();
  const history = useHistory();

  const handleReset = () =>
    resetIntegration({ code: integrationCode }).then(() => history.push(createNavigationUrl("integrationSettings")));

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PageTemplate
          header={
            <div className="flex items-center">
              <Heading size={1}>{integration?.name}</Heading>
              <div className="ml-md">
                <IntegrationStateLabel isActive={isActive} />
              </div>
            </div>
          }
          backButton={
            <BackToPreviousPageLink to={websiteContentPaths.integrationSettings}>
              Back to integrations
            </BackToPreviousPageLink>
          }
        >
          {IntegrationComponent && <IntegrationComponent integration={integration} />}
          <ResetButton isActive={isActive} onResetIntegration={handleReset} usesCardComponent={usesCardComponent} />
        </PageTemplate>
      )}
    </>
  );
};

export default IntegrationPage;
