import { ApolloCache, FetchResult, gql, InMemoryCache, useMutation } from "@apollo/client";
import {
  CustomerApiKey,
  CustomerApiKeyConnection,
  CustomerApiKeyCreateInput,
  CustomerApiKeyCreatePayload,
} from "types/graphql";
import { API_KEYS_QUERY } from "../existing_api_keys/use_api_keys";

type UseNewKeyProps = {
  onLoadingFinished: () => void;
};

type UseNewKeyReturn = {
  generateNewKey: () => void;
  data: CustomerApiKey | Record<string, never>;
};

type ApiKeysReturn = {
  node: CustomerApiKey;
  __typename?: string | undefined;
};

export const GENERATE_NEW_API_KEY_MUTATION = gql`
  mutation CustomerApiKeyCreate($input: CustomerApiKeyCreateInput!) {
    customerApiKeyCreate(input: $input) {
      successful
      result {
        id
        apiKey
        apiKeyDigest
        active
        createdAt
      }
    }
  }
`;

function useNewKey({ onLoadingFinished }: UseNewKeyProps): UseNewKeyReturn {
  const [createApiKey, { data }] = useMutation<
    { customerApiKeyCreate: CustomerApiKeyCreatePayload },
    { input: CustomerApiKeyCreateInput }
  >(GENERATE_NEW_API_KEY_MUTATION, {
    onCompleted: onLoadingFinished,
    update: updateCache,
  });

  return {
    generateNewKey: () => {
      createApiKey({ variables: { input: {} } }).catch(errnoError => {
        console.error(errnoError);
      });
    },
    data: data?.customerApiKeyCreate?.result ?? {},
  };
}

function updateCache(
  cache: ApolloCache<InMemoryCache>,
  { data }: FetchResult<{ customerApiKeyCreate: CustomerApiKeyCreatePayload }>,
) {
  const result = data?.customerApiKeyCreate?.result;
  const currentApiKeysList = cache.readQuery<{ customerApiKeys: CustomerApiKeyConnection }>({ query: API_KEYS_QUERY });

  const updatedKeyList = {
    ...currentApiKeysList,
    customerApiKeys: {
      ...currentApiKeysList?.customerApiKeys,
      edges: getApiKeys(currentApiKeysList?.customerApiKeys),
    },
  };

  const { ...newKey } = result;
  delete newKey.apiKey;

  updatedKeyList.customerApiKeys.edges?.unshift({ __typename: "CustomerApiKeyEdge", node: newKey });

  cache.writeQuery({ query: API_KEYS_QUERY, data: updatedKeyList });
}

function getApiKeys(apiKeys: CustomerApiKeyConnection | undefined): ApiKeysReturn[] {
  const edges = apiKeys?.edges || [];

  return edges.map(edge => ({
    ...edge,
    node: { ...edge.node, active: false },
  }));
}

export default useNewKey;
