import { Redirect, Route } from "react-router";
import React from "react";
import { createNavigationUrl } from "../../app/helpers/url_helper";
import { websiteContentPaths } from "../configuration";
import { withApollo } from "@apollo/client/react/hoc";

function RootPageSelector(props) {
  const { children } = props;

  return (
    <>
      <Route path={websiteContentPaths.root} exact render={() => <Redirect to={createNavigationUrl("overview")} />} />
      {children}
    </>
  );
}

export { RootPageSelector };
export default withApollo(RootPageSelector);
