import React, { useRef, useState } from "react";

import FilterDisplayButton from "./filter_display_button";
import { OptionsBox } from "../../tag_cell/options_box";
import { useIssueTags } from "./../../tag_cell/use_issue_tags";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { FlexibleTestingIssue, FlexibleTestingIssueTag } from "types/graphql";

type TagsFilterProps = {
  currentTags: FlexibleTestingIssueTag[];
  updateSelectedTags: (tags: Array<FlexibleTestingIssueTag>, mode?: string) => void;
  issues: Array<FlexibleTestingIssue>;
  tagsFilterMode: string;
  issuesCount: number;
};

const TagsFilter = ({ currentTags, updateSelectedTags, issues, tagsFilterMode, issuesCount }: TagsFilterProps) => {
  const wrapperRef = useRef(null);

  const handleTagsUpdate = (issueTags: FlexibleTestingIssueTag[], mode?: string): void => {
    if (updateSelectedTags) {
      updateSelectedTags(issueTags, mode);
    }
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOutsideClick = () => setIsOpen(false);
  useOutsideHandler(wrapperRef, handleOutsideClick);

  const handleOpenToggle = () => {
    setIsOpen(!isOpen);
  };

  const { issueTags } = useIssueTags();

  return (
    <div className="cursor-default items-center" ref={wrapperRef} data-testid="tag-filter">
      <FilterDisplayButton
        onClick={handleOpenToggle}
        currentTags={currentTags}
        isOpen={isOpen}
        tagsFilterMode={tagsFilterMode}
        issuesLength={issuesCount}
      />

      {isOpen && (
        <OptionsBox
          activeTags={currentTags}
          availableTags={issueTags}
          handleTagsUpdate={handleTagsUpdate}
          setIsOpen={setIsOpen}
          isReadOnly={true}
          issues={issues}
          tagsFilterMode={tagsFilterMode}
          withoutCounter={true}
        />
      )}
    </div>
  );
};

export default TagsFilter;
