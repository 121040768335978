import React from "react";

type NoResultMessageProps = {
  message: string;
  dataTestid?: string;
};

function NoResultMessage({ message, dataTestid }: NoResultMessageProps): JSX.Element {
  return (
    <div className="mt-6xl" data-testid={dataTestid}>
      <div className="mt-xl">{message}</div>
    </div>
  );
}

export default NoResultMessage;
