import React from "react";

import { AdvancedTestRequest } from "types/graphql";
import { AdvancedTestDetails } from "./advanced_test_details";
import { RequestedTestDetails } from "./requested_test_details";

type AdvancedTestDescriptionParams = {
  advancedTestRequest: AdvancedTestRequest;
  testWindow?: AdvancedTestRequest["testWindow"];
};

const AdvancedTestDescription = ({ advancedTestRequest, testWindow }: AdvancedTestDescriptionParams) => {
  const hasRequestedTests = advancedTestRequest.requestedTests && advancedTestRequest.requestedTests.length > 0;

  return (
    <div className="my-4xl">
      {hasRequestedTests ? (
        (advancedTestRequest.requestedTests || []).map(requestedTest => (
          <RequestedTestDetails key={requestedTest.id} requestedTest={requestedTest} />
        ))
      ) : (
        <AdvancedTestDetails advancedTestRequest={advancedTestRequest} testWindow={testWindow} />
      )}
    </div>
  );
};
export default AdvancedTestDescription;
