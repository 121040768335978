import { chunk, debounce, uniqBy } from "lodash";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { Channel, DefaultGenerics, StreamChat } from "stream-chat";

import { ChatType } from "types/app";

export type ChatChannelParams = {
  uuid: string;
  externalId: string | null;
  type: ChatType;
  connection?: Channel<DefaultGenerics>;
};

export type ChatChannel = ChatChannelParams & { externalId: string };

const fetchChannels = async (client: StreamChat<DefaultGenerics>, channelIds: ChatChannel["externalId"][]) => {
  if (channelIds.length === 0) return [];

  const filter = {
    type: "messaging",
    cid: { $in: channelIds.map(channelId => `messaging:${channelId}`) },
  };

  const channels = await client.queryChannels(filter, undefined, { watch: true, limit: channelIds.length });

  return channels || [];
};

const retrieveChannels = async (client: StreamChat<DefaultGenerics>, channels: ChatChannel[]) => {
  const streamChannels = await fetchChannels(
    client,
    channels.map(({ externalId }) => externalId),
  );

  return streamChannels.reduce((acc, connection) => {
    const channelId = connection.cid.split(":")[1];
    const chatChannel = channels.find(chatChannel => chatChannel.externalId === channelId);

    if (chatChannel) acc.push({ ...chatChannel, connection });

    return acc;
  }, [] as Required<ChatChannel>[]);
};

const getStreamChannels = async (client: StreamChat<DefaultGenerics>, channels: ChatChannel[]) => {
  const retrieved = await Promise.all(
    chunk(channels, 30).flatMap(channelsChunk => retrieveChannels(client, channelsChunk)),
  );

  return retrieved.flat();
};

// We use debounce (with channels queue) to avoid multiple API calls.
// This approach allows us to register chat instances close to their usage.
// Having centralized chat registration, allows us to eliminate the need for duplicated conditional logic,
//
// To remove debounce, all channels must be registered upfront, for example, for testers' chats in
// src/components/flexible_testing/result_details_page/testers/testers.tsx like this:
//
// const channelTypesToRegister = ["test_private_ctms_tester"];
// if (currentUser?.testManager) channelTypesToRegister.push("test_private_tms_tester");
//
// const channelsToRegister = selectionRequirementsGroups
//   .flatMap(({ participants }) => participants)
//   .filter(({ status }) => status !== "backup")
//   .flatMap(participant =>
//     channelTypesToRegister.map(channelType => ({
//       uuid: participant.id,
//       externalId: participant.chatChannels?.find(({ chatType }) => chatType === channelType)?.externalId || null,
//       type: channelType as ChatType,
//     })),
//   );
//
// registerChannels(channelsToRegister);

export const initializeChannels = debounce(
  async (
    client: StreamChat,
    setChannels: Dispatch<SetStateAction<Map<string, Channel<DefaultGenerics> | null>>>,
    channels: MutableRefObject<ChatChannel[]>,
  ) => {
    const currentChannels = [...channels.current];
    channels.current = [];

    const channelsToBeRegistered = currentChannels.filter(({ externalId }) => !externalId);
    const existingChannels = currentChannels.filter(({ externalId }) => externalId);

    const channelsToRetrieve = uniqBy([...existingChannels], "externalId");
    const streamChannels = await getStreamChannels(client, channelsToRetrieve);

    const newChannels = new Map(
      [...streamChannels, ...channelsToBeRegistered].map(channel => [
        `${channel.type}:${channel.uuid}`,
        channel.connection || null,
      ]),
    );

    if (newChannels.size > 0) setChannels(current => new Map([...current, ...newChannels]));
  },
  400,
);
