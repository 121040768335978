import React from "react";
import { DecoratedText } from "@hopper/decorated_text";

import { FlexibleTestingStringCaseField } from "types/graphql";

type RequestedAttachmentBasedAdditionalInformationProps = {
  caseFields: FlexibleTestingStringCaseField[];
};

export const RequestedAttachmentBasedAdditionalInformation = ({
  caseFields,
}: RequestedAttachmentBasedAdditionalInformationProps) => {
  if (caseFields.length === 0) return null;

  return (
    <div className="mt-lg" data-testid="requested-attachment-based-additional-information">
      <div className="font-bold mb-sm">The tester was asked to provide the following attachments:</div>
      <ul className="list-disc list-inside">
        {caseFields.map(caseField => (
          <li key={caseField.id}>
            <DecoratedText text={caseField.value || ""} />
          </li>
        ))}
      </ul>
    </div>
  );
};
