import { MultiSelect } from "components/shared/form";
import React from "react";
import { OperatingSystemVersion } from "types/graphql";
import { FormikErrors, useFormikContext } from "formik";
import { TestRequestParams } from "components/flexible_testing/test_manager/test_request_page/supervised_test_content/tester_group_list/tester_group";
import { useEnvironments } from "components/flexible_testing/test_manager/test_request_page/supervised_test_content/tester_group_list/use_environments";
import { Options } from "components/shared/form/multiselect/multiselect";
import { TesterGroupData } from "components/flexible_testing/test_manager/test_request_page/supervised_test_content/tester_group_list/tester_group_list";
import { TesterGroupParams } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import { Loader } from "@hopper/loading";

type EnvironmentSelectionSectionProps = {
  fieldName: string;
  testerGroup: TesterGroupData;
  errors: FormikErrors<TesterGroupParams>;
};

const filterRelevantOperatingSystemVersions = (
  operatingSystemVersions: OperatingSystemVersion[],
  selectedOperatingSystemVersionIds: string[],
  testEnvironments: string[],
) => {
  if (!operatingSystemVersions) return [];
  if (testEnvironments.includes("web") || testEnvironments.length === 0) return operatingSystemVersions;

  return operatingSystemVersions.filter(
    ({ id, operatingSystemCode }) =>
      selectedOperatingSystemVersionIds.includes(id) || testEnvironments.includes(operatingSystemCode),
  );
};

export const prepareOperatingSystemVersionOptions = (
  operatingSystemVersions: OperatingSystemVersion[],
  selectedOperatingSystemVersionIds: string[],
  testEnvironments: string[],
) => {
  const relevantOperatingSystemVersions = filterRelevantOperatingSystemVersions(
    operatingSystemVersions,
    selectedOperatingSystemVersionIds,
    testEnvironments,
  );

  return relevantOperatingSystemVersions?.map(({ id, fullName }: OperatingSystemVersion) => ({
    value: id,
    label: fullName,
    isSelected: selectedOperatingSystemVersionIds.includes(id),
  }));
};

function EnvironmentSelectionSection({ fieldName, testerGroup, errors }: EnvironmentSelectionSectionProps) {
  const { setFieldValue, values } = useFormikContext<TestRequestParams>();
  const {
    operatingSystemVersions,
    deviceTypes,
    manufacturers,
    mobileDevices,
    operatingSystems,
    internetBrowsers,
    countries,
    languages,
    environmentsLoading,
  } = useEnvironments();

  const deviceTypesOptions: Options = deviceTypes.map(type => ({
    value: type.id,
    label: type.name || "",
    isSelected: testerGroup.deviceTypes.some(device => device.id === type.id),
  }));
  const operatingSystemVersionOptions = prepareOperatingSystemVersionOptions(
    operatingSystemVersions,
    testerGroup.operatingSystemVersions.map(({ id }) => id),
    values.testEnvironments,
  );
  const manufacturerOptions: Options = manufacturers.map(manufacturer => ({
    value: manufacturer.id,
    label: manufacturer.name || "",
    isSelected: testerGroup.manufacturers.some(selectedManufacturer => selectedManufacturer.id === manufacturer.id),
  }));
  const mobileDeviceOptions: Options = mobileDevices.map(device => ({
    value: device.id,
    label: device.name || "",
    isSelected: testerGroup.mobileDevices.some(selectedDevice => selectedDevice.id === device.id),
  }));
  const operatingSystemOptions: Options = operatingSystems.map(operatingSystem => ({
    value: operatingSystem.id,
    label: operatingSystem.name || "",
    isSelected: testerGroup.operatingSystems.some(selectedSystem => selectedSystem.id === operatingSystem.id),
  }));
  const internetBrowsersOptions: Options = internetBrowsers.map(internetBrowser => ({
    value: internetBrowser.id,
    label: internetBrowser.name || "",
    isSelected: testerGroup.internetBrowsers.some(selectedBrowser => selectedBrowser.id === internetBrowser.id),
  }));
  const countryOptions: Options = countries.map(country => ({
    value: country.id,
    label: country.name || "",
    isSelected: testerGroup.countries.some(selectedCountry => selectedCountry.id === country.id),
  }));
  const languageOptions: Options = languages.map(language => ({
    value: language.id,
    label: language.name || "",
    isSelected: testerGroup.languages.some(selectedLanguage => selectedLanguage.id === language.id),
  }));

  const updateOperatingSystemVersions = (selectedIds: Array<string>) => {
    const selectedOperatingSystemVersions = operatingSystemVersions.filter(({ id }: OperatingSystemVersion) =>
      selectedIds.includes(id),
    );

    setFieldValue(`${fieldName}.operatingSystemVersions`, selectedOperatingSystemVersions);
  };

  const updateField = <T extends { id: string }[]>(field: string, selectedIds: string[], values: T) => {
    const selectedValues = values.filter(({ id }) => selectedIds.includes(id));

    setFieldValue(`${fieldName}.${field}`, selectedValues);
  };

  if (environmentsLoading) return <Loader isLoaded={!environmentsLoading} />;

  return (
    <>
      <div data-test-role="form-group" className="text-xs mb-xl">
        <MultiSelect
          label="Countries"
          errorMessage={errors.countries as string}
          options={countryOptions}
          onChange={selectedValues => {
            updateField("countries", selectedValues, countries);
          }}
          placeholder="Any"
        />
      </div>
      <div data-test-role="form-group" className="text-xs mb-xl">
        <MultiSelect
          label="Languages"
          errorMessage={errors.countries as string}
          options={languageOptions}
          onChange={selectedValues => {
            updateField("languages", selectedValues, languages);
          }}
          placeholder="Any"
        />
      </div>
      <div data-test-role="form-group" className="text-xs mb-xl">
        <MultiSelect
          label="Device type"
          errorMessage={errors.deviceTypes as string}
          options={deviceTypesOptions}
          onChange={selectedValues => {
            updateField("deviceTypes", selectedValues, deviceTypes);
          }}
          placeholder="Select device type"
        />
      </div>
      <div data-test-role="form-group" className="text-xs mb-xl">
        <MultiSelect
          label="Manufacturer"
          errorMessage={errors.manufacturers as string}
          options={manufacturerOptions}
          onChange={selectedValues => {
            updateField("manufacturers", selectedValues, manufacturers);
          }}
          placeholder="Select manufacturer"
        />
      </div>
      <div data-test-role="form-group" className="text-xs mb-xl">
        <MultiSelect
          label="Device"
          errorMessage={errors.mobileDevices as string}
          options={mobileDeviceOptions}
          onChange={selectedValues => {
            updateField("mobileDevices", selectedValues, mobileDevices);
          }}
          placeholder="Select a device"
        />
      </div>
      <div data-test-role="form-group" className="text-xs mb-xl">
        <MultiSelect
          label="Operating System"
          errorMessage={errors.operatingSystems as string}
          options={operatingSystemOptions}
          onChange={selectedValues => {
            updateField("operatingSystems", selectedValues, operatingSystems);
          }}
          placeholder="Select an operating system"
        />
      </div>
      <div data-test-role="form-group" className="text-xs mb-xl">
        <MultiSelect
          label="Operating system version"
          placeholder="Select an operating system version"
          errorMessage={errors.operatingSystemVersions as string | undefined}
          onChange={updateOperatingSystemVersions}
          options={operatingSystemVersionOptions}
        />
      </div>
      <div data-test-role="form-group" className="text-xs mb-xl">
        <MultiSelect
          label="Browser"
          errorMessage={errors.internetBrowsers as string}
          options={internetBrowsersOptions}
          onChange={selectedValues => {
            updateField("internetBrowsers", selectedValues, internetBrowsers);
          }}
          placeholder="Select a browser"
        />
      </div>
    </>
  );
}

export default EnvironmentSelectionSection;
