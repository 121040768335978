import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { FlexibleTestingTestCase } from "types/graphql";
import { sortByStatus } from "components/flexible_testing/shared/sort_by_status";
import { TestCaseRow } from "./test_case_row";

export type TestCasesListProps = {
  testId: string;
  testCases: Array<FlexibleTestingTestCase>;
  groupedBySection?: boolean;
};

export type TestCase = {
  isNew: boolean;
  originalStatus: string;
  isSelected: boolean;
} & FlexibleTestingTestCase;

function TestCasesList({ testId, testCases, groupedBySection = false }: TestCasesListProps): JSX.Element {
  const [currentTestCasesWithStatusIds, setCurrentTestCasesWithStatusIds] = useState<string[]>([]);
  const [testCasesToDisplay, setTestCasesToDisplay] = useState<TestCase[]>([]);
  const testCaseRowClass = classNames(["border-b", "border-light", { "pl-lg": groupedBySection }]);
  const currentTestCaseId = location.hash.replace("#", "");

  useEffect(() => {
    const currentTestCasesWithStatus = testCases.filter(testCase => testCase.status !== null);
    const mappedTestCases = testCases.map(testCase => {
      const isNew =
        currentTestCasesWithStatusIds.length > 0 &&
        !currentTestCasesWithStatusIds.includes(testCase.id) &&
        testCase.status !== null;
      const isSelected = currentTestCaseId === testCase.id;

      return {
        ...testCase,
        isNew,
        status: testCaseStatus(testCase),
        originalStatus: testCase.status,
        isSelected,
      };
    });

    setTestCasesToDisplay(mappedTestCases.sort(testCasesByStatus).sort(testCasesByState));
    setCurrentTestCasesWithStatusIds(currentTestCasesWithStatus.map(testCase => testCase.id));
  }, [testCases]);

  return (
    <div className={groupedBySection ? "" : "pt-2xl"} data-testid="test-cases-list">
      {testCasesToDisplay.map(testCase => (
        <div className={testCaseRowClass} key={testCase.id}>
          <TestCaseRow testCase={testCase} testId={testId} />
        </div>
      ))}
    </div>
  );
}

function testCaseStatus(testCase: FlexibleTestingTestCase): string {
  if (testCase.isTestCaseLive) {
    return "live";
  } else if (
    testCase.completedCaseResults?.length &&
    testCase.completedCaseResults?.every(result => result.isRejected)
  ) {
    return "rejected";
  } else {
    return testCase.status;
  }
}

function testCasesByStatus(firstTestCase: TestCase, secondTestCase: TestCase): number {
  return sortByStatus(firstTestCase.originalStatus, secondTestCase.originalStatus);
}

function testCasesByState(firstTestCase: TestCase, secondTestCase: TestCase): number {
  if (firstTestCase.isTestCaseLive && !secondTestCase.isTestCaseLive) {
    return 1;
  } else if (!firstTestCase.isTestCaseLive && secondTestCase.isTestCaseLive) {
    return -1;
  } else {
    return 0;
  }
}

export default TestCasesList;
