import React from "react";
import { Link, useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import * as yup from "yup";

import { Button } from "@hopper/button";

import {
  IssueSeverityOption,
  FlexibleTestingAutoExportDestinationOption,
  FlexibleTestingAutoExportSettings,
  AutoExportDestination,
  IssueSeverity,
} from "types/graphql";
import { Form, CheckboxGroup, SubmitButton } from "../shared/form";
import { PageSection } from "../shared/page_section";
import { LineSeparator } from "../shared/line_separator";
import { CheckboxOption } from "../shared/form/checkbox_group/checkbox_group";
import { useSaveAutoExportSettings } from "./use_save_auto_export_settings";
import { websiteContentPaths } from "components/configuration";
import { sortBySeverity } from "../shared/sort_by_severity";

export const ISSUE_SEVERITY_OPTIONS = gql`
  query getIssueSeverities {
    issueSeverityOptions {
      name
    }
  }
`;

type AutoExportSettingsFormProps = {
  autoExportSettings?: FlexibleTestingAutoExportSettings;
  autoExportDestinationOptions: Array<FlexibleTestingAutoExportDestinationOption>;
};

type IssueSeverityOptions = {
  issueSeverityOptions: Array<IssueSeverityOption>;
};

const AutoExportSettingsForm = ({
  autoExportSettings,
  autoExportDestinationOptions,
}: AutoExportSettingsFormProps): JSX.Element => {
  const { data: dataIssueSeverityOptions } = useQuery<IssueSeverityOptions>(ISSUE_SEVERITY_OPTIONS);
  let issueSeverityOptions = dataIssueSeverityOptions?.issueSeverityOptions || [];
  issueSeverityOptions = issueSeverityOptions.filter(obj => obj.name !== "unknown");

  const { saveAutoExportSettings } = useSaveAutoExportSettings();

  const selectedSeverities = autoExportSettings?.severitiesForExport || [];
  const selectedDestinations = autoExportSettings?.enabledDestinations || [];

  const destinationOptions = autoExportDestinationOptions.map(destination => ({
    label: destination.name,
    value: destination.value,
  }));

  const formatSeverityLabel = (issueSeverityName: IssueSeverity | null | undefined): string =>
    `Export ${issueSeverityName} severity issues`;

  const severityOptions = issueSeverityOptions
    ?.map(issueSeverity => ({
      label: formatSeverityLabel(issueSeverity?.name),
      value: issueSeverity.name || "",
    }))
    .sort((firstSeverity, secondSeverity) =>
      sortBySeverity(firstSeverity.value as IssueSeverity, secondSeverity.value as IssueSeverity),
    );

  const history = useHistory();

  const onCancel = () => {
    history.push(websiteContentPaths.flexcaseResults);
  };

  return (
    <Form
      onSubmit={async (values: { [field: string]: unknown }) => {
        await saveAutoExportSettings(
          values.destinationOptions as Array<AutoExportDestination>,
          values.issueSeverityOptions as Array<IssueSeverity>,
        );
      }}
      initialValues={{
        issueSeverityOptions: selectedSeverities,
        destinationOptions: selectedDestinations,
      }}
      validations={yup.object({
        destinationOptions: yup.array().min(1, "At least one destination has to be selected").required("required"),
      })}
      enableReinitialize
    >
      <div data-testid="auto-export-form" className="grid grid-cols-1 md:grid-cols-2">
        <PageSection orderNumber={1} title="Auto-export settings">
          <CheckboxGroup
            label="Reported issues"
            name="issueSeverityOptions"
            options={severityOptions as Array<CheckboxOption>}
          />
        </PageSection>
        <PageSection orderNumber={2} title="Destinations">
          <CheckboxGroup
            label="Where should we export to?"
            required
            name="destinationOptions"
            options={destinationOptions as Array<CheckboxOption>}
          />
          <p>
            If the destination you would like to export to is not listed here, visit the{" "}
            <b>
              <Link className="text-link mr-xs" to={websiteContentPaths.integrationSettings}>
                Integrations
              </Link>
            </b>
            section to set up and activate that integration
          </p>
        </PageSection>
      </div>
      <LineSeparator />
      <SubmitButton data-testid="submit-auto-export-settings" useHopperButton>
        Save
      </SubmitButton>
      <Button
        data-testid="cancel-auto-export-settings"
        type="button"
        className="ml-lg"
        variant="secondary"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </Form>
  );
};

export default AutoExportSettingsForm;
