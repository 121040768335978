import { FieldHookConfig, useField } from "formik";
import React, { useEffect, useState } from "react";
import { FormField } from "@hopper/form_field";
import { Input } from "@hopper/input";

const parseValue = (val: string) => {
  const numericValue: number = parseInt(val.replace(/[^0-9]/g, ""), 10) || 0;
  const formattedValue: string = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numericValue / 100);

  return { formattedValue, numericValue };
};

type CurrencyInputProps = {
  label?: string;
  labelClass?: string;
  errorClass?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  "data-testid"?: string;
  setInputValue?: (fn: number) => void;
  defaultValue: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

function CurrencyInput({
  label,
  required,
  defaultValue,
  onChange = () => {},
  onBlur = () => {},
  validate,
  labelClass,
  errorClass,
  setInputValue = () => {},
  ...props
}: CurrencyInputProps & FieldHookConfig<number>): JSX.Element {
  const [field, meta, helpers] = useField({ validate, ...props });
  const id = props.id ?? props.name;
  const [renderValue, setRenderValue] = useState<string>(parseValue((defaultValue || 0).toString()).formattedValue);

  useEffect(() => {
    helpers.setValue(defaultValue || 0);
  }, []);

  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const { formattedValue, numericValue } = parseValue(value);
    setRenderValue(formattedValue);
    helpers.setValue(numericValue);

    onChange && onChange(e);
  };

  const inputProps = { ...props, value: renderValue };
  const fieldParams = { ...field, onChange: handleChange };

  return (
    <FormField label={label} isOptional={!required} errorMessage={meta.touched && meta.error}>
      <Input id={id} style={{ minWidth: "24rem" }} {...fieldParams} {...inputProps} />
    </FormField>
  );
}

export default CurrencyInput;
