import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { Button } from "@hopper/button";
import { Card } from "@hopper/card";

import useLaunchStandardTest from "../use_launch_standard_test";
import { usePreviousTestAvailable } from "components/flexible_testing/shared/use_previous_test_available";
import { useIsOrganizationNameChosen } from "components/flexible_testing/shared/use_is_organization_name_chosen";
import { websiteContentPaths } from "components/configuration";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { TestRequestErrorModal } from "components/flexible_testing/shared/test_request_error_modal";
import { testEnvironmentsToLabel } from "components/flexible_testing/shared/test_environments";
import { PreviewPageStandardTestRequest } from "../../preview_page";
import { PreviewField } from "components/flexible_testing/shared/preview_field";
import { PreviewTestCases } from "components/flexible_testing/shared/preview_test_cases";
import { SuccessModal } from "../success_modal";

import { DesiredOutcomeCode, TestEnvironmentPlatform } from "types/graphql";

type StandardTestContentProps = {
  testRequest: PreviewPageStandardTestRequest;
  handleEditTest: () => void;
  formattedTestLaunchDateTime: string;
  desiredOutcomes?: Array<DesiredOutcomeCode>;
  sdlcStage?: string;
};

function StandardTestContent({
  testRequest,
  handleEditTest,
  formattedTestLaunchDateTime,
}: StandardTestContentProps): JSX.Element {
  const {
    applicationUrl,
    name,
    description,
    testCases,
    scheduleAt,
    credentialsAndAccessInstructions,
    additionalUrlsAndResources,
    testEnvironments,
    desiredOutcomes,
    sdlcStage,
  } = testRequest;
  const { launchTest, testResultsUrl, isLoading, wasSuccessfullyRequested } = useLaunchStandardTest();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { isLoading: isLoadingPreviousTestInfo, hasNoPreviousTests } = usePreviousTestAvailable();
  const isOrganizationNameChosen = useIsOrganizationNameChosen();

  const SHORT_VIEW_CASES_LIMIT = 3;

  function handleLaunchTest() {
    const casesWithoutTypename = JSON.parse(JSON.stringify(testCases), omitTypename);
    const environments =
      testEnvironments?.map(environment => ({ platform: environment as TestEnvironmentPlatform })) || [];

    launchTest(
      name,
      description,
      credentialsAndAccessInstructions,
      additionalUrlsAndResources,
      casesWithoutTypename,
      applicationUrl,
      scheduleAt,
      environments,
      desiredOutcomes,
      sdlcStage,
    )
      .then(() => {
        setShowSuccessModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      });
  }

  const testEnvironmentLabels = testEnvironmentsToLabel(testEnvironments || []);

  return (
    <Card>
      <div className="p-3xl grid grid-cols-1 md:grid-cols-2">
        <PageSection title={name} fullWidthTitle={true}>
          <PreviewField label="Application url">
            <a href={applicationUrl} className="text-link underline text-body">
              {applicationUrl}
            </a>
          </PreviewField>

          {testEnvironmentLabels.length > 0 && (
            <PreviewField label="Environment(s) included in this test">{testEnvironmentLabels.join(", ")}</PreviewField>
          )}

          {credentialsAndAccessInstructions && (
            <PreviewField label="Credentials and access instructions">{credentialsAndAccessInstructions}</PreviewField>
          )}

          {additionalUrlsAndResources && (
            <PreviewField label="Additional URLs and resources">{additionalUrlsAndResources}</PreviewField>
          )}

          <PreviewField label="Test launch date/time">
            <div data-testid="test-launch-time-message">
              {isEmpty(scheduleAt) ? "Immediately" : formattedTestLaunchDateTime}
            </div>
          </PreviewField>
        </PageSection>

        <div>
          {description && <PreviewField label="Test run objective">{description}</PreviewField>}
          <PreviewField label="Test cases">
            <PreviewTestCases testCases={testCases} shortViewCasesLimit={SHORT_VIEW_CASES_LIMIT} />
          </PreviewField>
        </div>

        <div className="mt-2xl">
          <Button
            disabled={wasSuccessfullyRequested}
            isLoading={isLoading || isLoadingPreviousTestInfo}
            variant="primary"
            onClick={handleLaunchTest}
            data-testid="launch-test"
          >
            {wasSuccessfullyRequested ? "Test launched" : "Launch this test"}
          </Button>

          <Button
            disabled={wasSuccessfullyRequested}
            variant="secondary"
            className="ml-lg"
            onClick={handleEditTest}
            data-testid="edit-test-and-re-upload"
          >
            Edit test
          </Button>

          {wasSuccessfullyRequested && (
            <div className="text-md font-bold">
              <div className="mt-xl">This test has been launched</div>
              <div className="mt-sm">
                <Link className="text-link mr-xs" to={websiteContentPaths.flexcaseResults}>
                  View results
                </Link>
                or
                <Link className="text-link ml-xs" to={websiteContentPaths.flexcaseLaunch}>
                  launch another test
                </Link>
              </div>
            </div>
          )}
        </div>

        <div style={{ lineHeight: "normal" }}>
          <SuccessModal
            formattedTestLaunchDateTime={formattedTestLaunchDateTime}
            resultsUrl={testResultsUrl}
            isOpen={showSuccessModal}
            onCloseModalClicked={() => setShowSuccessModal(false)}
            shouldAskForOrganizationChosenName={hasNoPreviousTests && !isOrganizationNameChosen}
          />
        </div>

        <TestRequestErrorModal isOpen={showErrorModal} onCloseModalClicked={() => setShowErrorModal(false)} />
      </div>
    </Card>
  );
}

function omitTypename(key: string, value: string | undefined): string | undefined {
  return key === "__typename" ? undefined : value;
}

export default StandardTestContent;
