import React from "react";

type BodyProps = {
  children: React.ReactNode;
  dataTestId?: string;
};

function Body({ children, dataTestId }: BodyProps) {
  return <tbody data-testid={dataTestId}>{children}</tbody>;
}

export default Body;
