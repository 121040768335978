import * as yup from "yup";

const emailSchema = yup
  .string()
  .transform((_, value) => value.trim())
  .email();

export const testerGroupTesterEmailsValidations = yup
  .array()
  .max(1000, "You can add up to 1000 testers")
  .transform((_, value) => value?.split(",")?.map((e: string) => e.trim()))
  .test({
    name: "tester-emails",
    test: async (value = [], testContext) => {
      const result = await Promise.all(
        value.map(async (email: string) => ((await emailSchema.isValid(email)) ? null : email)),
      );
      const invalidEmails = result.filter(v => v);

      return (
        invalidEmails.length === 0 ||
        testContext.createError({ message: `Following emails are invalid: ${invalidEmails.join(", ")}` })
      );
    },
  });
