import React from "react";

type IssuePageTemplateProps = {
  children: React.ReactNode;
  header: React.ReactNode;
  navigation?: React.ReactNode;
};

export function IssuePageTemplate({ children, header, navigation = null }: IssuePageTemplateProps): JSX.Element {
  return (
    <>
      {navigation && (
        <div>
          <div className="max-w-3xl mx-auto" data-testid="page-navigation">
            <div className="py-lg">{navigation}</div>
          </div>
        </div>
      )}
      <div className="max-w-3xl mx-auto mb-6xl" data-testid="page-content">
        <div className="pt-2xl">
          {header}
          <div className="mt-5xl">{children}</div>
        </div>
      </div>
    </>
  );
}
