import { IssueSeverity } from "types/graphql";

type SeverityScore = {
  [key: string]: number;
};

export const severityScores: SeverityScore = {
  critical: 1,
  high: 2,
  medium: 3,
  low: 4,
  unknown: 5,
};

function sortBySeverity(firstSeverity: IssueSeverity, secondSeverity: IssueSeverity) {
  const firstValue = severityScores[firstSeverity] || 0;
  const secondValue = severityScores[secondSeverity] || 0;

  return firstValue - secondValue;
}

export default sortBySeverity;
