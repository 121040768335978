import {
  FlexibleTestingCaseFieldConnection,
  FlexibleTestingTestCase as FlexibleTestingTestCaseGQL,
  FlexibleTestingStepCaseResultFieldResponse,
  FlexibleTestingStringCaseResultFieldResponse,
  FlexibleTestingStringCaseField,
  FlexibleTestingTestCaseResult as FlexibleTestingTestCaseResultGQL,
  FlexibleTestingTestWindow,
  Maybe,
} from "types/graphql";
import { gql, useQuery } from "@apollo/client";

type useTestCaseResultReturn = {
  testCase: TestCase | Record<string, never>;
  isLoading: boolean;
};

type FlexibleTestingTestCase = Omit<FlexibleTestingTestCaseGQL, "caseFields"> & {
  stepCaseFields: FlexibleTestingTestCaseGQL["stepCaseFields"];
  additionalInformationCaseFields: FlexibleTestingTestCaseGQL["caseFields"];
};

type TestCaseData = {
  name: string;
  status?: string | null;
  caseResults?: Maybe<Array<FlexibleTestingTestCaseResult>>;
  fields: TestCaseFields;
  testWindow?: FlexibleTestingTestWindow | null;
  isTestCaseLive: boolean;
  code?: string | null;
  prerequisites?: string | null;
};

export type FlexibleTestingTestCaseResult = FlexibleTestingTestCaseResultGQL & {
  stepCaseResultFieldResponses?: Array<FlexibleTestingStepCaseResultFieldResponse>;
  additionalInformationResultFieldResponses?: Array<FlexibleTestingStringCaseResultFieldResponse>;
};

export type TestCaseFields = {
  stepCaseFields: FlexibleTestingTestCaseGQL["stepCaseFields"];
  additionalInformationCaseFields: TestCaseStringField[];
};

export type TestCaseStepField = {
  _id: string;
  id: string;
  ordinalNumber?: number | null;
  instruction: string;
  expectedResult: string;
};

export type TestCaseStringField = {
  id: string;
  ordinalNumber?: number | null;
  value: string;
};

export type TestCaseResultFieldResponse = {
  _id: string;
  id: string;
  ordinalNumber?: number | null;
  instruction: string;
  expectedResult: string;
  status: string;
  actualResult?: string | null;
  originalActualResult?: string | null;
};

export type TestCase = TestCaseData | Record<string, never>;

export const TEST_CASE_QUERY = gql`
  query FlexibleTestingTestCase($id: ID!) {
    flexibleTestingTestCase(id: $id) {
      id
      name
      code
      status
      prerequisites
      isTestCaseLive
      testWindow {
        openedAt
        closedAt
        plannedEndAt
        status
      }
      completedCaseResults {
        id
        status
        actualResult
        additionalInformation
        authorSignature
        wasRead
        completedAt
        isRejected
        rejectionReason
        relatedIssues {
          url
          label
        }
        testerEnvironment {
          operatingSystemAndVersion
          browserOrDevice
        }
        testerCountry {
          name
          code
        }
        tester {
          fullName
        }
        caseResultAttachments {
          edges {
            node {
              id
              archived
              attachment {
                url
              }
            }
          }
        }
        caseFieldAttachmentResponses {
          edges {
            node {
              id
              archived
              attachment {
                url
              }
            }
          }
        }

        stepCaseResultFieldResponses: caseResultFieldResponses(caseFieldDisplayType: Step, caseFieldGroupType: steps) {
          ... on FlexibleTestingStepCaseResultFieldResponse {
            status
            actualResult
            originalActualResult
            expectedResult
            instruction
            ordinalNumber
          }
        }
        additionalInformationResultFieldResponses: caseResultFieldResponses(
          caseFieldDisplayType: string
          caseFieldGroupType: additional_information
        ) {
          ... on FlexibleTestingStringCaseResultFieldResponse {
            value
            caseField {
              ... on FlexibleTestingStringCaseField {
                id
              }
            }
          }
        }
        testCase {
          id
        }
      }
      stepCaseFields {
        _id
        id
        ordinalNumber
        expectedResult
        instruction
      }
      additionalInformationCaseFields: caseFields(
        filters: { group_type: additional_information, display_type: string }
      ) {
        edges {
          node {
            ... on FlexibleTestingStringCaseField {
              id
              ordinalNumber
              value
            }
          }
        }
      }
    }
  }
`;

function useTestCaseResult(id: string): useTestCaseResultReturn {
  const { data, loading } = useQuery(TEST_CASE_QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  return {
    testCase: getData(data),
    isLoading: loading,
  };
}

function getData(data: { flexibleTestingTestCase: FlexibleTestingTestCase } | null): TestCase {
  return data
    ? {
        code: data?.flexibleTestingTestCase?.code,
        name: data?.flexibleTestingTestCase?.name,
        status: data?.flexibleTestingTestCase?.status,
        prerequisites: data?.flexibleTestingTestCase?.prerequisites,
        caseResults: data?.flexibleTestingTestCase?.completedCaseResults,
        fields: {
          stepCaseFields: data?.flexibleTestingTestCase?.stepCaseFields || [],
          additionalInformationCaseFields: getAdditionalInfoFields(
            data?.flexibleTestingTestCase?.additionalInformationCaseFields,
          ),
        },
        testWindow: data?.flexibleTestingTestCase?.testWindow,
        isTestCaseLive: data?.flexibleTestingTestCase?.isTestCaseLive,
      }
    : {};
}

function getAdditionalInfoFields(fields: FlexibleTestingCaseFieldConnection): TestCaseStringField[] {
  const nodes =
    fields?.edges
      ?.map(edge => edge?.node)
      ?.filter((caseField): caseField is FlexibleTestingStringCaseField => !!caseField) || [];

  return nodes.map(node => ({
    id: node?.id || "",
    ordinalNumber: node?.ordinalNumber,
    value: node?.value || "",
  }));
}

export default useTestCaseResult;
