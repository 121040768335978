import React from "react";
import { TestCaseResultFieldResponse } from "components/flexible_testing/test_case_result_page/use_test_case_result";
import { Header, Table, HeaderCell, Body } from "../shared/table";
import InstructionResult from "./instruction_result";

type TestCaseInstructionsProps = {
  resultFieldResponses: TestCaseResultFieldResponse[];
  testCaseResultId?: string;
  isCaseResultEditingDisabled?: boolean;
};

function TestCaseInstructions({
  resultFieldResponses,
  testCaseResultId,
  isCaseResultEditingDisabled,
}: TestCaseInstructionsProps): JSX.Element {
  return (
    <Table noBorder={true}>
      <Header data-testid="test-case-list-header">
        <HeaderCell>TEST CASE STEPS</HeaderCell>
        <HeaderCell style={{ width: "120px" }}>RESULT</HeaderCell>
      </Header>
      <Body>
        {resultFieldResponses.map(fieldResponse => (
          <InstructionResult
            testCaseResultId={testCaseResultId}
            fieldResponse={fieldResponse}
            key={fieldResponse.id}
            isCaseResultEditingDisabled={isCaseResultEditingDisabled}
          />
        ))}
      </Body>
    </Table>
  );
}

export default TestCaseInstructions;
