import { gql, useQuery } from "@apollo/client";

export const TEST_CASE_RESULT_REJECTION_REASONS = gql`
  query FlexibleTestingTestCaseResultRejectionReasons {
    flexibleTestingTestCaseResultRejectionReasons
  }
`;

function useTestCaseResultRejectionReasons(): Array<string> {
  const { data } = useQuery(TEST_CASE_RESULT_REJECTION_REASONS);

  return data?.flexibleTestingTestCaseResultRejectionReasons || [];
}

export { useTestCaseResultRejectionReasons };
