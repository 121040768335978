import React, { useState } from "react";
import { Loader } from "@hopper/loading";

import Modal from "app/modal";
import { IssuesList } from "./issues_list";
import { ExportDestinations } from "../export_destinations";
import { FlexibleTestingIssue } from "types/graphql";
import { GET_ISSUES_TAB_DATA } from "./issues_tab_data_query";

type IssuesProps = {
  testId: string;
  issues: FlexibleTestingIssue[];
  testStatus: string;
  isLoading: boolean;
};

function Issues({ testId, issues, testStatus, isLoading }: IssuesProps) {
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedIssuesIds, setSelectedIssuesIds] = useState<string[]>([]);

  function handleSelectSingleIssue(id: string): void {
    if (selectedIssuesIds.includes(id)) {
      setSelectedIssuesIds(prevState => prevState.filter(issueId => issueId !== id));
    } else {
      setSelectedIssuesIds(prevState => prevState.concat(id));
    }
  }

  function handleSuccessfulExport(): void {
    setShowExportModal(false);
    setSelectedIssuesIds([]);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <IssuesList
        testId={testId}
        testStatus={testStatus}
        issues={issues}
        selectedIssues={selectedIssuesIds}
        handleSelectSingle={handleSelectSingleIssue}
        handleExportClick={() => setShowExportModal(true)}
        setSelectedIssuesIds={setSelectedIssuesIds}
      />
      <Modal
        show={showExportModal}
        title="Export selected issue(s)"
        closeModal={() => setShowExportModal(false)}
        visibleOverflowY={true}
      >
        <ExportDestinations
          issuesToExport={selectedIssuesIds}
          onSuccessfulExport={handleSuccessfulExport}
          refetchQuery={{ query: GET_ISSUES_TAB_DATA, variables: { testId } }}
        />
      </Modal>
    </>
  );
}

export default Issues;
