import { gql } from "@apollo/client";

export const GET_TEST_BASE_INFO = gql`
  query getPlatformTestBaseInfo($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        name
        testWindow {
          openedAt
          scheduleAt
          cancelledAt
          closedAt
          plannedEndAt
          status
        }
      }
      ... on FlexibleTestingTest {
        id
        name
        testWindow {
          openedAt
          scheduleAt
          cancelledAt
          closedAt
          plannedEndAt
          status
        }
      }
    }
  }
`;
