import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingIssueRejectInput, FlexibleTestingIssueRejectPayload } from "types/graphql";

export const REJECT_ISSUE = gql`
  mutation FlexibleTestingIssueReject($input: FlexibleTestingIssueRejectInput!) {
    flexibleTestingIssueReject(input: $input) {
      successful
      result {
        id
        triageStatus
        triageReason
        test {
          triagedIssuesCount
        }
      }
    }
  }
`;

type useIssueRejectResult = {
  rejectIssue: (issueId: string, triageReason: string) => Promise<void>;
};

export const useIssueReject = (issueId: string): useIssueRejectResult => {
  const [rejectIssueMutation] = useMutation<
    { flexibleTestingIssueReject: FlexibleTestingIssueRejectPayload },
    { input: FlexibleTestingIssueRejectInput }
  >(REJECT_ISSUE);

  return {
    rejectIssue: async (issueId: string, rejectionReason: string): Promise<void> => {
      await rejectIssueMutation({
        variables: {
          input: {
            issueId,
            rejectionReason,
          },
        },
      });
    },
  };
};
