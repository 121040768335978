import React from "react";
import rocketIcon from "assets/images/rocket.svg";

const NoIssuesRejected = () => (
  <div className="mt-xl">
    <div className="font-bold max-w-md pb-md border-b border-light">No issues have been rejected</div>
    <div className="mt-xl flex">
      <img src={rocketIcon} className="mr-xs" />
      <span>All issues were accepted for this test run</span>
    </div>
  </div>
);

export default NoIssuesRejected;
