import React from "react";

type TestingServiceCardContentProps = {
  items: string[];
};

export function TestingServiceCardContent({ items }: TestingServiceCardContentProps): JSX.Element {
  return (
    <div className="text-body text-sm">
      {items.map(content => (
        <div key={content} className="pt-sm">
          {content}
        </div>
      ))}
    </div>
  );
}
