import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import classnames from "classnames";

export const severities = ["critical", "high", "medium", "low", "unknown"] as const;

type SeverityOptionProps = {
  severity: typeof severities[number];
  displayChevron?: boolean;
};

const SeverityOption = ({ severity, displayChevron = false }: SeverityOptionProps) => {
  const severityColors = {
    critical: "danger",
    high: "warning",
    medium: "secondary",
    low: "muted",
    unknown: "light",
  };

  const severityColor = severityColors[severity];

  return severityColor ? (
    <div
      className="flex items-center font-bold text-xs uppercase tracking-extra-wide"
      data-testid={`severity-indicator-${severity}`}
    >
      <i className={classnames("fa fa-circle mr-sm text-2xs", `text-${severityColor}`)} data-testid="severity-icon" />
      <span>
        {capitalize(severity)} {displayChevron && <i className="fa fa-chevron-down ml-xs" />}
      </span>
    </div>
  ) : null;
};

SeverityOption.propTypes = {
  severity: PropTypes.string.isRequired,
};

export default SeverityOption;
