import React, { Component } from "react";
import * as yup from "yup";

import ExportIntegrationForm from "../export_integration_form";
import JiraExportFields from "./jira_export_fields";

const validations = yup.object({
  project_key: yup.string().required("Project can't be blank"),
  issuetype_name: yup.string().required("Issue Type can't be blank"),
  labels: yup.string().max(255),
});

class JiraExportForm extends Component {
  render() {
    const clientAuthentication = this.props.integration.clientAuthentication || {};
    const { projectKey, issuetypeName, componentId, labels } = clientAuthentication;
    const {
      runs_export_attributes: { projects },
      id: integrationId,
    } = this.props.integration;

    const initialValues = {
      project_key: projectKey,
      issuetype_name: issuetypeName,
      labels,
      componentId,
    };

    return (
      <ExportIntegrationForm initialValues={initialValues} validations={validations} {...this.props}>
        <JiraExportFields
          projects={projects}
          integrationId={integrationId}
          projectKey={projectKey}
          issuetypeName={issuetypeName}
          componentId={componentId}
        />
      </ExportIntegrationForm>
    );
  }
}

export default JiraExportForm;
