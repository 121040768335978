type TestEnvironment = {
  value: string;
  label: string;
};

export const testEnvironments: TestEnvironment[] = [
  { value: "web", label: "Web" },
  { value: "android", label: "Android" },
  { value: "ios", label: "iOS" },
];

export function testEnvironmentsToLabel(values: string[]) {
  return values.map(value => testEnvironments?.find(env => env?.value === value)?.label);
}
