import { gql, useMutation } from "@apollo/client";
import {
  FlexibleTestingUpdateTestCaseResultResponseInput,
  FlexibleTestingUpdateTestCaseResultResponsePayload,
} from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { TEST_CASE_RESULT_QUERY } from "components/flexible_testing/tester_overview/use_flexible_testing_test_case_result";

export const CASE_RESULT_EDIT_RELATED_ISSUE_CODE_MUTATION = gql`
  mutation FlexibleTestingUpdateTestCaseResultResponse($input: FlexibleTestingUpdateTestCaseResultResponseInput!) {
    flexibleTestingUpdateTestCaseResultResponse(input: $input) {
      successful
      result {
        id
      }
      errors {
        code
        message
      }
    }
  }
`;

const displayError = (message: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message,
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Related issue code has been updated successfully.",
  });
};

type useUpdateRelatedIssueCodeResult = {
  flexibleTestingUpdateCaseResultResponse: (
    caseResultResponseId: string,
    testCaseResultId: string,
    content: string,
  ) => Promise<void>;
  isLoading: boolean;
};

export const useUpdateRelatedIssueCode = (onSuccess: (newIssueId: string) => void): useUpdateRelatedIssueCodeResult => {
  const [flexibleTestingUpdateCaseResultResponseMutation, { loading }] = useMutation<
    { flexibleTestingUpdateTestCaseResultResponse: FlexibleTestingUpdateTestCaseResultResponsePayload },
    { input: FlexibleTestingUpdateTestCaseResultResponseInput }
  >(CASE_RESULT_EDIT_RELATED_ISSUE_CODE_MUTATION, {
    onCompleted: ({ flexibleTestingUpdateTestCaseResultResponse }, clientOptions) => {
      if (flexibleTestingUpdateTestCaseResultResponse?.successful) {
        displaySuccess();
        onSuccess(clientOptions?.variables?.input?.value?.issueId);
      } else {
        const errorMessage = flexibleTestingUpdateTestCaseResultResponse.errors.map(({ message }) => message).join(",");
        displayError(errorMessage);
      }
    },
    onError: () => displayError("There was an issue with submitting your edit request. Please try again later."),
  });

  return {
    flexibleTestingUpdateCaseResultResponse: async (
      caseResultResponseId: string,
      caseResultId: string,
      content: string,
    ): Promise<void> => {
      const value = { stepValue: { issueId: content } };
      await flexibleTestingUpdateCaseResultResponseMutation({
        variables: { input: { caseResultResponseId, value } },
        refetchQueries: [{ query: TEST_CASE_RESULT_QUERY, variables: { id: caseResultId } }],
      });
    },
    isLoading: loading,
  };
};
