import React, { useState } from "react";
import { Button } from "@hopper/button";
import ConfirmationModal from "./confirmation_modal";

type ApiKeyProps = {
  apiKeyId: string;
  isActive: boolean;
};

function RevokeApiKeyButton({ apiKeyId, isActive }: ApiKeyProps): JSX.Element {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const buttonTitle = isActive ? "Revoke" : "Revoked";
  const appearance = isActive ? "default" : "minimal";

  return (
    <>
      <Button
        size="sm"
        appearance={appearance}
        variant="secondary"
        disabled={!isActive}
        onClick={() => setIsConfirmationModalOpen(true)}
        className="text-xs"
        data-testid="api-key-revoke-button"
      >
        {buttonTitle}
      </Button>
      <ConfirmationModal
        apiKeyId={apiKeyId}
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      />
    </>
  );
}

export default RevokeApiKeyButton;
