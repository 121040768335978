import { gql, useMutation } from "@apollo/client";
import { RegisterCaseResultModerationActivityInput, RegisterCaseResultModerationActivityPayload } from "types/graphql";

export const REGISTER_CASE_RESULT_MODERATION_ACTIVITY_MUTATION = gql`
  mutation RegisterCaseResultModerationActivity($input: RegisterCaseResultModerationActivityInput!) {
    registerCaseResultModerationActivity(input: $input) {
      successful
      errors {
        code
        message
      }
    }
  }
`;

type useRegisterCaseResultModerationActivityResult = {
  registerCaseResultModerationActivity: (caseResultId: string) => Promise<void>;
};

export const useRegisterCaseResultModerationActivity = (): useRegisterCaseResultModerationActivityResult => {
  const [registerCaseResultModerationActivityMutation] = useMutation<
    { registerCaseResultModerationActivity: RegisterCaseResultModerationActivityPayload },
    { input: RegisterCaseResultModerationActivityInput }
  >(REGISTER_CASE_RESULT_MODERATION_ACTIVITY_MUTATION);

  return {
    registerCaseResultModerationActivity: async (caseResultId: string): Promise<void> => {
      await registerCaseResultModerationActivityMutation({ variables: { input: { caseResultId } } });
    },
  };
};
