import React, { useRef } from "react";
import { isEmpty, partition } from "lodash";

import { FlexibleTestingTestCaseResult } from "./use_flexible_testing_test_case_result";
import { FlexibleTestingCaseField, FlexibleTestingStringCaseField, Maybe } from "types/graphql";

import { statuses } from "components/flexible_testing/shared/status_indicator";
import TestCaseInstructions from "components/flexible_testing/test_case_result_page/test_case_instructions";
import { Attachment, RemovableAttachments } from "components/flexible_testing/tester_overview/removable_attachments";
import { CaseResultTimeAndCountry } from "components/flexible_testing/shared/case_result/case_result_time_and_country";
import { OverallResult } from "components/flexible_testing/shared/case_result/overall_result";
import { generateCaseInstructionsWithResponses } from "components/flexible_testing/shared/case_result/generate_step_instructions_with_responses";
import { CaseResultStatusSection } from "components/flexible_testing/shared/case_result/case_result_status_section";
import { EditRequestLogSection } from "./case_result_edit_request/edit_request_log_section";
import CaseResultModerationLogsSection from "./case_result_moderation_logs_section";
import { CreateEditRequestButton } from "./case_result_edit_request/create_edit_request_button";
import { CancelEditRequestButton } from "./case_result_edit_request/cancel_edit_request_button";
import { RelatedIssue } from "components/flexible_testing/tester_overview/test_case_result/related_issue";
import { StatusCard } from "@hopper/status_card";
import ModerationStatusIndicator from "components/flexible_testing/tester_overview/moderation_status";
import { RequestedAttachmentBasedAdditionalInformation } from "./requested_attachment_based_additional_information";
import { AdditionalInformationTextResponses } from "./additional_information_text_responses";
import { generateAdditionalInformationWithTextResponses } from "./generate_additional_information_with_text_responses";
import { DecoratedText } from "@hopper/decorated_text";
import { Heading } from "@hopper/heading";
import ModerationStatus from "./test_case_result/moderation_status";
import Authorization from "app/authorization/authorization";

type TestCaseResultProps = {
  caseResult: FlexibleTestingTestCaseResult;
};

export type CaseResultStatus = typeof statuses[number];

const isStringCaseField = (
  caseField: Maybe<FlexibleTestingCaseField> | undefined,
): caseField is FlexibleTestingStringCaseField => !!caseField && "value" in caseField;

const TestCaseResult = ({ caseResult }: TestCaseResultProps): JSX.Element => {
  const caseResultAttachments: Attachment[] =
    caseResult.caseResultAttachments?.edges?.map(edge => ({
      ...edge?.node?.attachment?.url,
      id: edge?.node?.id,
      trackingId: edge?.node?._id,
      archived: edge?.node?.archived,
      attachmentType: "CaseResultAttachment",
    })) || [];
  const additionalAttachments: Attachment[] =
    caseResult.caseFieldAttachmentResponses?.edges?.map(edge => ({
      ...edge?.node?.attachment?.url,
      id: edge?.node?.id,
      trackingId: edge?.node?._id,
      archived: edge?.node?.archived,
      name: edge?.node?.caseField?.value,
      attachmentType: "CaseFieldAttachmentResponse",
    })) || [];
  const attachments = [...caseResultAttachments, ...additionalAttachments];
  const relatedIssue = caseResult.relatedIssues[0];
  const status = caseResult?.status as CaseResultStatus;

  const statusLabel =
    {
      pass: "Passed",
      fail: "Failed",
      blocked: "Blocked",
      not_tested: "Not tested",
      live: "Live",
      missing: "Missing",
      skipped: "Skipped",
    }[status] || "Tested";

  const stepCaseFields = caseResult.testCase.stepCaseFields || [];
  const additionalInformationCaseFields =
    caseResult.testCase.additionalInformationCaseFields?.edges?.map(edge => edge?.node).filter(isStringCaseField) || [];
  const [textBasedAdditionalInformationCaseFields, attachmentBasedAdditionalInformationCaseFields] = partition(
    additionalInformationCaseFields,
    "hasResultFields",
  );
  const responses = generateCaseInstructionsWithResponses(caseResult.caseResultFieldResponses, stepCaseFields);
  const additionalInformationTextResponses = generateAdditionalInformationWithTextResponses(
    caseResult.additionalInformationResultFieldResponses,
    textBasedAdditionalInformationCaseFields,
  );
  const caseResultEditLogs = caseResult.caseResultEditLogs;
  const caseResultModerationLogs = caseResult.caseResultModerationLogs;
  const hasOpenCaseResultEditRequest = caseResult.hasOpenCaseResultEditRequest;
  const canRemoveAttachment = caseResult.canRemoveAttachment;
  const caseResultStatusFieldResponseId = caseResult?.caseResultStatusFieldResponseId;
  const latestEditRequest = caseResult?.latestCaseResultEditRequest;
  const prerequisites = caseResult.testCase.prerequisites;

  const requestEditButtonRef = useRef<HTMLSpanElement | null>(null);

  const clickRequestEditButton = () => {
    const requestEditButtonElement = requestEditButtonRef.current;
    requestEditButtonElement?.click();
  };

  return (
    <div data-testid="tester-test-case-panel-content">
      <CaseResultStatusSection
        statusCode={status}
        testerEnvironment={caseResult.testerEnvironment}
        statusLabel={statusLabel}
      />
      <ModerationStatus caseResult={caseResult} />
      <div data-testid="test-case-code" className="text-md font-bold mt-lg text-secondary">
        {caseResult.testCase.code}
      </div>
      <div data-testid="test-case-name" className="text-lg font-bold mt-md">
        {caseResult.testCase.name}
      </div>
      <div className="mt-lg">
        {latestEditRequest && (
          <ModerationStatusIndicator editRequest={latestEditRequest} caseResultId={caseResult.id} />
        )}
      </div>
      <div className="flex gap- mt-lg">
        <div className="mr-md">
          {hasOpenCaseResultEditRequest ? (
            <>
              <CancelEditRequestButton caseResultId={caseResult.id} />
              <div className="mt-md">
                <StatusCard statusColor="warning">
                  Test case results cannot be edited while an edit request is open.
                  <br />
                  If you want to make edits you need to cancel the edit request first.
                </StatusCard>
              </div>
            </>
          ) : (
            <CreateEditRequestButton
              disabled={!caseResult.canRequestEdit.isAllowed}
              disabledReason={caseResult.canRequestEdit.reason || ""}
              caseResultId={caseResult.id}
              ref={requestEditButtonRef}
            />
          )}
        </div>
      </div>

      {!isEmpty(caseResultEditLogs) && (
        <div className="mt-lg">
          <EditRequestLogSection caseResultEditLogs={caseResultEditLogs} />
        </div>
      )}

      <div className="mt-lg">
        <OverallResult
          status={status}
          actualResult={caseResult.actualResult}
          originalActualResult={caseResult.originalActualResult}
          additionalInformation={caseResult.additionalInformation}
          originalAdditionalInformation={caseResult.originalAdditionalInformation}
          caseResultResponseId={caseResult.caseResultStatusFieldResponseId}
          testCaseResultId={caseResult.id}
          isCaseResultEditingDisabled={hasOpenCaseResultEditRequest}
        />
      </div>
      <RequestedAttachmentBasedAdditionalInformation caseFields={attachmentBasedAdditionalInformationCaseFields} />
      <div className="mt-3xl" data-testid="attachments-section">
        <RemovableAttachments attachments={attachments} canRemoveAttachment={canRemoveAttachment} />
      </div>
      <AdditionalInformationTextResponses
        resultFieldResponses={additionalInformationTextResponses}
        testCaseResultId={caseResult.id}
        isCaseResultEditingDisabled={hasOpenCaseResultEditRequest}
      />
      <div className="grid grid-cols-1 mt-3xl text-dark text-sm md:grid-cols-2">
        <CaseResultTimeAndCountry testerCountry={caseResult.testerCountry} completedAt={caseResult.completedAt} />
      </div>

      {relatedIssue && caseResultStatusFieldResponseId && (
        <RelatedIssue
          relatedIssue={relatedIssue}
          caseResultId={caseResult.id}
          caseResultStatusFieldResponseId={caseResultStatusFieldResponseId}
          isCaseResultEditingDisabled={hasOpenCaseResultEditRequest}
          clickRequestEditButton={clickRequestEditButton}
        />
      )}

      {prerequisites && (
        <div className="mt-3xl" data-testid="test-case-prerequisites">
          <Heading size={5}>Prerequisites</Heading>
          <DecoratedText text={prerequisites} />
        </div>
      )}

      <div className="mt-3xl" data-testid="step-results-section">
        <TestCaseInstructions
          resultFieldResponses={responses}
          testCaseResultId={caseResult.id}
          isCaseResultEditingDisabled={hasOpenCaseResultEditRequest}
        />
      </div>
      <Authorization roles={["testManager"]}>
        <CaseResultModerationLogsSection caseResultModerationLogs={caseResultModerationLogs} />
      </Authorization>
    </div>
  );
};

export default TestCaseResult;
