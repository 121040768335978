import { gql, useMutation } from "@apollo/client";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { ToggleRequiredAttachmentForPassInput, ToggleRequiredAttachmentForPassPayload } from "types/graphql";

export const TOGGLE_REQUIRED_ATTACHMENT_FOR_PASS = gql`
  mutation ($input: ToggleRequiredAttachmentForPassInput!) {
    toggleRequiredAttachmentForPass(input: $input) {
      result {
        id
        requiredAttachmentForPass
      }
      errors {
        message
        code
      }
      successful
    }
  }
`;

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Attachment requirement for passed test cases has been successfully updated.",
  });
};

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with changing attachment requirement for passed test cases.",
  });
};

const useToggleRequiredAttachmentForPass = () => {
  const [toggleRequiredAttachmentForPass] = useMutation<
    { toggleRequiredAttachmentForPass: ToggleRequiredAttachmentForPassPayload },
    { input: ToggleRequiredAttachmentForPassInput }
  >(TOGGLE_REQUIRED_ATTACHMENT_FOR_PASS, {
    onCompleted: ({ toggleRequiredAttachmentForPass: { successful } }) =>
      successful ? displaySuccess() : displayError(),
    onError: displayError,
  });

  return {
    toggleRequiredAttachmentForPass: async (testId: string): Promise<void> => {
      await toggleRequiredAttachmentForPass({
        variables: { input: { testId } },
      });
    },
  };
};

export default useToggleRequiredAttachmentForPass;
