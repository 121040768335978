import React from "react";
import { Label } from "@hopper/legacy_label";
import rocketIcon from "assets/images/rocket.svg";

const LiveTestInfo = () => (
  <div>
    <div className="font-bold pb-2xl border-b border-light flex items-center">
      <Label className="mr-sm py-0 px-sm" color="bg-primary">
        LIVE
      </Label>
      <div>Our testers are currently testing this test case</div>
    </div>
    <div className="py-2xl border-b rounded-y-full border-light flex items-center">
      <img src={rocketIcon} className="float-left mr-sm" />
      <div>Results will be shown here in real-time as soon as they are reported</div>
    </div>
  </div>
);

export default LiveTestInfo;
