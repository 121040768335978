import React from "react";
import { Body, BodyCell, Row } from "components/flexible_testing/shared/table";
import { Link } from "react-router-dom";
import StatusLabel from "../../result_details_page/testers/status_label";
import { Checkbox } from "@hopper/checkbox";
import { Participant } from "components/flexible_testing/shared/chat";
import { TestParticipationStatus } from "types/app";

type ParticipantRowProps = {
  testId?: string;
  participant: Participant;
  handleSelectSingleParticipant: (id: string) => void;
  selectedParticipantsIds: Array<string>;
  allowedParticipantStatuses: Readonly<TestParticipationStatus[]>;
};
const ParticipantRow = ({
  testId,
  participant,
  handleSelectSingleParticipant,
  selectedParticipantsIds,
  allowedParticipantStatuses,
}: ParticipantRowProps): JSX.Element => {
  const isTestingCompleted = allowedParticipantStatuses.includes(participant.status);

  return (
    <Body dataTestId="publish-results-participant">
      <Row>
        <BodyCell>
          <div data-testid="participant-checkbox">
            <Checkbox
              onChange={() => handleSelectSingleParticipant(participant.id)}
              isChecked={selectedParticipantsIds.includes(participant.id) && isTestingCompleted}
              disabled={!isTestingCompleted}
            />
          </div>
        </BodyCell>
        <BodyCell className="text-sm">
          <Link to={`/results/${testId}/participant/${participant.id}`}>{participant.fullName}</Link>
          <div className="text-dark text-xs">{participant.email}</div>
        </BodyCell>
        <BodyCell>
          <div data-testid="participant-statuslabel">
            <StatusLabel status={participant.status} />
          </div>
        </BodyCell>
      </Row>
    </Body>
  );
};

export default ParticipantRow;
