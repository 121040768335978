import { Label } from "@hopper/legacy_label";
import React from "react";

import { Test } from "types/graphql";

const TestProgressLabel = ({ test }: { test: Test }) => {
  const isTestOpen = test?.testWindow?.status === "open";
  const overdue = isTestOpen && test?.testWindow?.plannedEndAt && new Date(test?.testWindow?.plannedEndAt) < new Date();

  if (overdue) {
    return (
      <span>
        <Label className="ml-0 mr-sm py-0 mt-xs px-xs" color="bg-danger">
          OVERDUE
        </Label>
      </span>
    );
  } else if (isTestOpen) {
    return (
      <span>
        <Label className="ml-0 mr-sm py-0 mt-xs px-xs" color="bg-primary">
          LIVE
        </Label>
      </span>
    );
  } else return null;
};

export default TestProgressLabel;
