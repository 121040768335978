import React from "react";
import { TesterResultsStats } from "types/graphql";
import { FlagIcon } from "../../../shared/flag";

type TesterResultsSummaryProps = {
  testerResult: Pick<
    TesterResultsStats,
    "testerName" | "country" | "status" | "testCasesFailedCount" | "testCasesPassedCount" | "testCasesTotalCount"
  >;
};
function TesterResultsSummary({ testerResult }: TesterResultsSummaryProps): JSX.Element {
  const { testerName, country, status, testCasesFailedCount, testCasesPassedCount, testCasesTotalCount } = testerResult;

  const inProgress = status === "in_progress";
  const passed = testCasesFailedCount === 0 && status === "testing_complete";
  const progressBarColor = passed ? "bg-success" : "bg-danger";
  const progressBarBackgroundColor = inProgress ? "bg-primary bg-opacity-25" : "bg-light";
  const progressBarCompletion = passed
    ? completionPercentage(testCasesPassedCount, testCasesTotalCount)
    : completionPercentage(testCasesFailedCount, testCasesTotalCount);

  return (
    <>
      <div className="mb-xl" data-testid="tester-result-summary">
        <div className="mb-md text-base">
          {testerName}
          <FlagIcon code={country?.code?.toLowerCase()} className="ml-sm" />
        </div>

        <div data-testid="tester-summary-progress">
          <span className={`rounded-base ${progressBarBackgroundColor} flex`}>
            <div className="w-full">
              <div>
                <div
                  className={`rounded-base ${progressBarColor}`}
                  data-testid="tester-summary-progress-bar"
                  style={{ width: `${progressBarCompletion}%`, height: "0.4rem" }}
                />
              </div>
            </div>
          </span>
          <div>
            <div
              className="flex mt-sm text-sm text-secondary font-bold tracking-wider uppercase"
              data-testid="tester-progress"
            >
              {passed ? (
                <div>{testCasesPassedCount} passed</div>
              ) : (
                <>
                  <div>{testCasesFailedCount} failed</div>
                  <div className="mx-sm">-</div>
                  {["testing_complete", "results_approved"].includes(status || "") ? (
                    <span>finished</span>
                  ) : (
                    <span className="text-primary"> in progress</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function completionPercentage(completed: number, total: number): number {
  return Math.min(Math.floor((completed * 100) / total), 100);
}

export default TesterResultsSummary;
