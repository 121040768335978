import React from "react";
import { Checkbox } from "@hopper/checkbox";
import { StatusLabel } from "@hopper/status_label";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import style from "./style.module.css";

import RejectionReasonPopup from "./rejection_reason_popup";
import { extractTags } from "./extract_field";
import { IssueProps } from "../issues_list";
import { TagCell } from "components/flexible_testing/shared/tag_cell";
import { Row, BodyCell } from "components/flexible_testing/shared/table";
import { IssueSeverity } from "components/flexible_testing/shared/issue_severity";
import { usePreviousState } from "components/flexible_testing/shared/use_previous_state";
import { Label } from "@hopper/label";
import { formatDistanceToNowStrict, parseISO } from "date-fns";

type IssueRowProps = {
  issue: IssueProps;
  handleSelectSingle: (id: string) => void;
  isSelected: boolean;
  currentIssuesFilter: string;
  shouldDisplayRejectionReason: boolean;
  destinationPath: string;
  showReportedAt: boolean;
  disableSelection: boolean;
};

function timeDistance(createdAt: string): string {
  return formatDistanceToNowStrict(parseISO(createdAt), { roundingMethod: "floor" }) + " ago";
}

function IssueRow({
  issue,
  handleSelectSingle,
  isSelected,
  currentIssuesFilter,
  shouldDisplayRejectionReason,
  destinationPath,
  showReportedAt,
  disableSelection,
}: IssueRowProps): JSX.Element {
  const history = useHistory();
  const tags = extractTags(issue.issueTags);
  const animation = issue.isNew ? { animationName: "highlight", animationDuration: "4s" } : {};
  const previousState = usePreviousState();

  const onRowClick = () => {
    history.push(destinationPath, { ...{ ...previousState, currentFilter: currentIssuesFilter } });
  };

  const isUnread = (issue: IssueProps): boolean => !issue.wasRead;

  return (
    <Row testId="issue-row" style={{ ...animation }} className="cursor-pointer break-words" onClick={onRowClick}>
      <BodyCell onClick={event => event.stopPropagation()}>
        <div className="ml-lg mr-sm" data-testid="issue-checkbox">
          <Checkbox
            onChange={() => handleSelectSingle(issue.id)}
            isChecked={isSelected}
            disabled={disableSelection && !isSelected}
          />
        </div>
      </BodyCell>

      <BodyCell>
        <div className="flex items-center text-base text-darker leading-xl h-4xl break-words">
          {issue.code && <Label className="mr-sm">{issue.code}</Label>}
          <div data-testid="issue-title" className={classNames(style.lineClamp, "flex-grow pr-5xl")}>
            {issue.title}
          </div>
          {!!issue.issueNote && <div className="text-sm text-dark mr-lg whitespace-nowrap">1 triage note</div>}
          {isUnread(issue) && (
            <div>
              <StatusLabel variant="neutral">Unread</StatusLabel>
            </div>
          )}
        </div>
      </BodyCell>

      {showReportedAt && (
        <BodyCell>
          {issue.createdAt && <span className="text-sm text-secondary">{timeDistance(issue.createdAt)}</span>}
        </BodyCell>
      )}

      <BodyCell>
        <TagCell tags={tags} issueId={issue.id} isActiveTagsListLimited={true} />
      </BodyCell>

      {shouldDisplayRejectionReason && (
        <BodyCell onClick={event => event.stopPropagation()}>
          <RejectionReasonPopup issue={issue} />
        </BodyCell>
      )}

      <BodyCell onClick={event => event.stopPropagation()}>
        <IssueSeverity issue={issue} />
      </BodyCell>

      <BodyCell>
        <div className="mx-md">
          <i className="fa fa-chevron-right text-dark" />
        </div>
      </BodyCell>
    </Row>
  );
}

export default IssueRow;
