import React, { useState, useEffect } from "react";
import { Button } from "@hopper/button";
import { useField, useFormikContext } from "formik";

import { useEnvironments } from "./use_environments";
import TargetingRow, { TargetingGroup } from "./targeting_row";
import InfoCard from "./info_card";
import { RequestedTest } from "../requested_test_form";
import { Activity } from "../activity_step_content";
import { Loader } from "@hopper/loading";
import RequestedTestWizardNavigation from "../requested_test_wizard_navigation";
import { TextArea } from "components/flexible_testing/shared/form";

const EMPTY_GROUP: TargetingGroup = {
  devices: [],
  operatingSystemVersions: [],
  countries: [],
  languages: [],
  internetBrowsers: [],
  operatingSystemDeviceTypes: [],
};

const buildEmptyGroup = (languages: TargetingGroup["languages"]) => ({
  ...EMPTY_GROUP,
  languages: languages.filter(language => language.name === "English"),
});

const TargetingStepContent = ({ nextStep }: { nextStep: () => unknown }) => {
  const {
    values: { activity, applicationType, numberOfResultsPerTestCaseNeeded, customTargetingRequirements },
    isValid,
  } = useFormikContext<RequestedTest>();
  const [customTargeting, setCustomTargeting] = useState(!!customTargetingRequirements);
  const [{ value: targetingGroups = [] }, _, { setValue }] = useField<TargetingGroup[]>({ name: "targetingGroups" });
  const { loading, ...environments } = useEnvironments({ platform: applicationType });
  const canAddGroup = activity === Activity.TestCaseRun;

  const addGroup = (template?: TargetingGroup) =>
    setValue([...targetingGroups, template || buildEmptyGroup(environments.languages)]);

  const removeGroup = (index: number) => setValue(targetingGroups.filter((_, i) => i !== index));

  useEffect(() => {
    if (targetingGroups.length === 0 && !loading) addGroup(buildEmptyGroup(environments.languages));
  }, [loading]);

  return (
    <Loader isLoaded={!loading}>
      <div data-testid="targeting-step-content">
        <InfoCard activity={activity} numberOfResultsPerTestCaseNeeded={Number(numberOfResultsPerTestCaseNeeded)} />
        {customTargeting ? (
          <div data-testid="custom-targeting-section">
            <div className="text-secondary my-lg text-xs uppercase">Custom targeting</div>
            <div>
              <TextArea
                name="customTargetingRequirements"
                placeholder="e.g., Testers must have a Samsung smart TV"
                label="What are the targeting requirements?"
                useHopperInput={true}
                required
              />
              <p className="text-sm text-secondary mb-sm">
                Custom targeting may delay testing and incur additional fees
              </p>
            </div>
            <div>
              <Button
                variant="primary"
                appearance="minimal"
                type="button"
                className="mt-lg px-0 font-normal"
                onClick={() => setCustomTargeting(false)}
              >
                Switch back to regular targeting
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-3xl">
              {targetingGroups.map((targetingGroup, index) => (
                <TargetingRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  targetingGroup={targetingGroup}
                  environments={environments}
                  duplicate={() => addGroup(targetingGroup)}
                  remove={() => removeGroup(index)}
                  canDuplicate={canAddGroup}
                  canRemove={targetingGroups.length > 1}
                />
              ))}
            </div>

            <div>
              {canAddGroup && (
                <Button
                  variant="secondary"
                  appearance="ghost"
                  type="button"
                  className="mt-3xl mr-xl"
                  onClick={() => addGroup()}
                >
                  Add another group
                </Button>
              )}

              <Button
                variant="primary"
                appearance="minimal"
                type="button"
                className="mt-3xl font-normal px-0"
                onClick={() => setCustomTargeting(true)}
              >
                My test needs custom targeting
              </Button>
            </div>
          </>
        )}
        <RequestedTestWizardNavigation onSubmit={nextStep} submitDisabled={!isValid} />
      </div>
    </Loader>
  );
};

export default TargetingStepContent;
