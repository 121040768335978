import { gql } from "@apollo/client";

export const FLEXIBLE_TESTING_ISSUE_TAGS_FRAGMENT = gql`
  fragment flexibleTestingIssueTags on FlexibleTestingIssue {
    issueTags {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const FLEXIBLE_TESTING_ISSUE_FRAGMENT = gql`
  fragment flexibleTestingIssue on FlexibleTestingIssue {
    id
    _id
    severity
    triageStatus
    triageReason
    wasRead
    code
    title
    issueNote {
      id
    }
    ...flexibleTestingIssueTags
  }

  ${FLEXIBLE_TESTING_ISSUE_TAGS_FRAGMENT}
`;

export const GET_ISSUES_TAB_DATA = gql`
  query getPlatformTestIssuesTab($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        _id
        issues {
          totalCount
          edges {
            node {
              ...flexibleTestingIssue
            }
          }
        }
      }
      ... on FlexibleTestingTest {
        id
        _id
        issues {
          totalCount
          edges {
            node {
              ...flexibleTestingIssue
            }
          }
        }
      }
    }
  }

  ${FLEXIBLE_TESTING_ISSUE_FRAGMENT}
`;
