import React from "react";

import { TesterGroupParams } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import { PreviewField } from "components/flexible_testing/shared/preview_field";

const PreviewCollectionField = <T extends { length: number }>({
  collection,
  selection,
}: {
  collection: T;
  selection: string;
}) => {
  if (collection.length === 0) return <span className="text-sm text-dark">No items were selected</span>;

  return <>{selection}</>;
};

const PreviewTesterEmails = ({ testerEmails }: { testerEmails: string }) => {
  if (testerEmails.length === 0) return <span className="text-sm text-dark">No tester emails provided</span>;

  return (
    <>
      {testerEmails}
      <hr className="my-xs" />
      <span className="text-xs">Number of testers added: {testerEmails.split(",").length}</span>
    </>
  );
};

export const PreviewTesterGroupList = ({ testerGroups }: { testerGroups: Array<TesterGroupParams> }) => {
  if (testerGroups.length === 0) return <span className="text-sm text-dark">No groups were created</span>;

  return (
    <>
      {testerGroups.map(testerGroup => (
        <div key={testerGroup.name} data-testid="group">
          <div className="font-bold inline-flex items-center break-words pb-2xl pt-md">{testerGroup.name}</div>
          <PreviewField label="Number of results needed">
            <span className="text-sm">{testerGroup.numberOfResultsNeeded}</span>
          </PreviewField>
          <PreviewField label="Tags">
            <PreviewCollectionField collection={testerGroup.tags} selection={testerGroup.tags.join(", ")} />
          </PreviewField>
          <PreviewField label="Countries">
            <PreviewCollectionField
              collection={testerGroup.countries}
              selection={testerGroup.countries.map(country => country.name).join(", ")}
            />
          </PreviewField>
          <PreviewField label="Languages">
            <PreviewCollectionField
              collection={testerGroup.languages}
              selection={testerGroup.languages.map(language => language.name).join(", ")}
            />
          </PreviewField>
          <PreviewField label="Device types">
            <PreviewCollectionField
              collection={testerGroup.deviceTypes}
              selection={testerGroup.deviceTypes.map(device => device.name).join(", ")}
            />
          </PreviewField>
          <PreviewField label="Manufacturers">
            <PreviewCollectionField
              collection={testerGroup.manufacturers}
              selection={testerGroup.manufacturers.map(manufacturer => manufacturer.name).join(", ")}
            />
          </PreviewField>
          <PreviewField label="Devices">
            <PreviewCollectionField
              collection={testerGroup.mobileDevices}
              selection={testerGroup.mobileDevices.map(device => device.name).join(", ")}
            />
          </PreviewField>
          <PreviewField label="Operating System">
            <PreviewCollectionField
              collection={testerGroup.operatingSystems}
              selection={testerGroup.operatingSystems.map(os => os.name).join(", ")}
            />
          </PreviewField>
          <PreviewField label="Operating System Version">
            <PreviewCollectionField
              collection={testerGroup.operatingSystemVersions}
              selection={testerGroup.operatingSystemVersions.map(({ fullName }) => fullName).join(", ")}
            />
          </PreviewField>
          <PreviewField label="Browser">
            <PreviewCollectionField
              collection={testerGroup.internetBrowsers}
              selection={testerGroup.internetBrowsers.map(({ name }) => name).join(", ")}
            />
          </PreviewField>
          <PreviewField label="Other">
            <span className="text-sm">{testerGroup.other}</span>
          </PreviewField>
          <PreviewField label="Testers to invite at launch">
            <PreviewTesterEmails testerEmails={testerGroup.selectedTesterEmails} />
          </PreviewField>
          {testerGroup.automaticTesterSelectionEnabled || (
            <PreviewField label="Backup testers">
              <PreviewTesterEmails testerEmails={testerGroup.backupTesterEmails} />
            </PreviewField>
          )}
        </div>
      ))}
    </>
  );
};
