import React from "react";
import { Loader } from "@hopper/loading";

import NoResultMessage from "../no_result_message";
import { TestCasesList } from "./test_cases_list";
import { TestCasesListGroupedBySection } from "./test_cases_list_grouped_by_section";
import { FlexibleTestingTestCase, FlexibleTestingTestWindow } from "types/graphql";

type TestCasesProps = {
  testId?: string;
  testCases: FlexibleTestingTestCase[];
  testWindow: FlexibleTestingTestWindow;
  isLoading: boolean;
};

function TestCases({ testId, testCases, testWindow, isLoading }: TestCasesProps) {
  const isSectionNameDetected = testCases.some(testCase => !!testCase.section);
  const isTestFinished = !!testWindow?.closedAt;

  if (isLoading) {
    return <Loader />;
  }

  if (testCases.length === 0) {
    return <NoResultMessage message="No test cases were included in this test" />;
  } else if (isSectionNameDetected) {
    return (
      <TestCasesListGroupedBySection isTestFinished={isTestFinished} testId={testId || ""} testCases={testCases} />
    );
  } else {
    return <TestCasesList testId={testId || ""} testCases={testCases} />;
  }
}

export default TestCases;
