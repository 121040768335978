import { gql, useQuery, makeVar } from "@apollo/client";

import {
  FlexibleTestingStepCaseResultFieldResponse,
  FlexibleTestingStringCaseResultFieldResponse,
  FlexibleTestingTestCase as FlexibleTestingTestCaseGQL,
  FlexibleTestingTestCaseResult as FlexibleTestingTestCaseResultGQL,
  Maybe,
} from "types/graphql";

type TestCaseResultTrackingIds = {
  trackingCaseResultId: string;
  trackingTestId: string;
};

type FlexibleTestingTestCase = Omit<FlexibleTestingTestCaseGQL, "caseFields"> & {
  stepCaseFields: FlexibleTestingTestCaseGQL["caseFields"];
  additionalInformationCaseFields: FlexibleTestingTestCaseGQL["caseFields"];
};

export type FlexibleTestingTestCaseResult = Omit<FlexibleTestingTestCaseResultGQL, "testCase"> & {
  testCase: FlexibleTestingTestCase;
  caseResultFieldResponses: Maybe<Array<FlexibleTestingStepCaseResultFieldResponse>>;
  additionalInformationResultFieldResponses: Maybe<Array<FlexibleTestingStringCaseResultFieldResponse>>;
};

type UseFlexibleTestingTestCaseResultReturn = {
  data: { flexibleTestingTestCaseResult: FlexibleTestingTestCaseResult | null };
  loading: boolean;
};

export const TEST_CASE_RESULT_QUERY = gql`
  query getFlexibleTestingTestCaseResult($id: ID!) {
    flexibleTestingTestCaseResult(id: $id) {
      _id
      id
      status
      actualResult
      originalActualResult
      additionalInformation
      originalAdditionalInformation
      caseResultStatusFieldResponseId
      authorSignature
      wasRead
      completedAt
      isRejected
      rejectionReason
      hasOpenCaseResultEditRequest
      moderationStatus
      testParticipant {
        id
        moderationStatus
        numberOfPendingIssueModerations
      }
      canRequestEdit {
        isAllowed
        reason
      }
      canRemoveAttachment
      relatedIssues {
        url
        label
        moderationStatus
        existsInSystem
        known
      }
      testerEnvironment {
        operatingSystemAndVersion
        browserOrDevice
      }
      testerCountry {
        name
        code
      }
      caseResultEditLogs {
        description
        timestamp
      }
      caseResultModerationLogs {
        eventType
        eventCreatedAt
        organizationUserFullName
        organizationUserRole
      }
      caseResultAttachments {
        edges {
          node {
            _id
            id
            archived
            attachment {
              url
            }
          }
        }
      }
      caseFieldAttachmentResponses {
        edges {
          node {
            id
            caseField {
              id
              value
            }
            archived
            attachment {
              url
            }
          }
        }
      }
      caseResultFieldResponses(caseFieldDisplayType: Step, caseFieldGroupType: steps) {
        ... on FlexibleTestingStepCaseResultFieldResponse {
          _id
          id
          status
          actualResult
          originalActualResult
          expectedResult
          instruction
          ordinalNumber
        }
      }
      additionalInformationResultFieldResponses: caseResultFieldResponses(
        caseFieldDisplayType: string
        caseFieldGroupType: additional_information
      ) {
        ... on FlexibleTestingStringCaseResultFieldResponse {
          _id
          id
          value
          caseField {
            ... on FlexibleTestingStringCaseField {
              id
            }
          }
        }
      }
      latestCaseResultEditRequest {
        status
        createdAt
      }
      testCase {
        id
        name
        code
        prerequisites
        stepCaseFields {
          _id
          id
          ordinalNumber
          expectedResult
          instruction
        }
        additionalInformationCaseFields: caseFields(
          filters: { group_type: additional_information, display_type: string }
        ) {
          edges {
            node {
              ... on FlexibleTestingStringCaseField {
                _id
                id
                ordinalNumber
                value
                hasResultFields
              }
            }
          }
        }
        test {
          _id
          id
        }
      }
    }
  }
`;
export const testCaseResultTrackingIds = makeVar<TestCaseResultTrackingIds>({} as TestCaseResultTrackingIds);

function useFlexibleTestingTestCaseResult(id: string): UseFlexibleTestingTestCaseResultReturn {
  const { data, loading } = useQuery(TEST_CASE_RESULT_QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const trackingIds = {
        trackingCaseResultId: data.flexibleTestingTestCaseResult._id,
        trackingTestId: data.flexibleTestingTestCaseResult.testCase.test._id,
      };

      testCaseResultTrackingIds(trackingIds);
    },
    onError: error => {
      console.error("Error while fetching test case result", error);
    },
  });

  return { data, loading };
}

export default useFlexibleTestingTestCaseResult;
