import React from "react";
import { useFormikContext } from "formik";

function GeneralError() {
  const { errors } = useFormikContext();

  return (
    !!errors.generalError && (
      <p className="text-danger" data-testid="general-error">
        {errors.generalError}
      </p>
    )
  );
}

export default GeneralError;
