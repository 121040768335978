import React from "react";
import { Manager, Popper, Reference } from "react-popper";
import type { Placement } from "@popperjs/core";

type PopupProps = {
  children: JSX.Element;
  popupElement: JSX.Element;
  isOpen: boolean;
  offset?: [number, number];
  placement?: Placement;
};

const Popup = ({
  children,
  popupElement,
  isOpen,
  offset = [-10, 8],
  placement = "bottom-end",
}: PopupProps): JSX.Element => {
  const modifiers = [
    {
      name: "offset",
      options: { offset },
    },
  ];

  return (
    <Manager>
      <Reference>{({ ref }) => <div ref={ref}>{children}</div>}</Reference>
      <Popper placement={placement} modifiers={modifiers}>
        {({ ref, style }) =>
          isOpen && (
            <div ref={ref} style={style} className="z-50">
              {popupElement}
            </div>
          )
        }
      </Popper>
    </Manager>
  );
};

export default Popup;
