import {
  ACTIVITIES,
  Activity,
} from "components/flexible_testing/test_request_page/requested_test_page/activity_step_content";
import { APPLICATION_TYPES } from "components/flexible_testing/test_request_page/requested_test_page/app_step_content";
import { RequestedTest } from "components/flexible_testing/test_request_page/requested_test_page/requested_test_form";

export const getActivityTitle = (activity: Activity) =>
  (ACTIVITIES.find(({ code }) => code === activity) as typeof ACTIVITIES[number]).title;

export const getApplicationTypeLabel = (applicationType: RequestedTest["applicationType"]) =>
  APPLICATION_TYPES.find(({ value }) => value === applicationType)?.label;
