import React, { useState } from "react";
import { AdvancedTestingServiceCard } from "../../advanced_testing_service_card";
import { ExpressTestingServiceCard } from "../../express_testing_service_card";
import { Checkbox } from "@hopper/checkbox";
import { Button } from "@hopper/button";
import { StageHeader } from "components/flexible_testing/test_request_page/launch_wizard_content/stage_header";
import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";

type CapabilitySelectionWizardStepProps = {
  onCapabilitiesChange: (selectedCapabilities: Array<string>) => void;
  hasExpressTestDisabled: boolean;
  selectedCapabilities: Array<string>;
  sdlcStageName?: SoftwareDevelopmentLifeCycleStageName;
  isBackButtonVisible?: boolean;
  onBackButtonClick: () => void;
  onExpressTestButtonClick: () => void;
  onAdvancedTestButtonClick: () => void;
};

export function CapabilitySelectionWizardStep({
  onCapabilitiesChange,
  hasExpressTestDisabled,
  isBackButtonVisible = false,
  selectedCapabilities,
  sdlcStageName,
  onBackButtonClick,
  onExpressTestButtonClick,
  onAdvancedTestButtonClick,
}: CapabilitySelectionWizardStepProps): JSX.Element {
  const [checkboxStates, setCheckboxStates] = useState<CapabilityOption[]>(initCapabilities(selectedCapabilities));
  const [isExpressCardDisabled, setExpressCardDisabled] = useState(
    hasExpressTestDisabled || selectedCapabilities.length > 0,
  );
  const customTestingRequirments = checkboxStates.filter(({ type }) => type === "customRequirment");
  const testerActivities = checkboxStates.filter(({ type }) => type === "testerActivity");
  const onCheckboxClick = (label: string): void => {
    const newCheckboxStates: CapabilityOption[] = checkboxStates.map(checkboxState =>
      checkboxState.label === label ? { ...checkboxState, isChecked: !checkboxState.isChecked } : checkboxState,
    );

    const selectedCapabilities = newCheckboxStates.filter(({ isChecked }) => isChecked).map(({ label }) => label);
    const isAnyCapabilityChecked = newCheckboxStates.some(({ isChecked }) => isChecked);

    setCheckboxStates(newCheckboxStates);
    onCapabilitiesChange(selectedCapabilities);
    setExpressCardDisabled(hasExpressTestDisabled || isAnyCapabilityChecked);
  };

  return (
    <div className="grid grid-cols-12" data-testid="capability-selection-wizard-step">
      <div className="col-span-9">
        <StageHeader
          sdlcStageName={sdlcStageName}
          isBackButtonVisible={isBackButtonVisible}
          onBackButtonClick={onBackButtonClick}
        />
        <CheckboxesSection
          title="Do you have any of these custom testing requirements?"
          capabilities={customTestingRequirments}
          onCheckboxClick={onCheckboxClick}
        />
        <CheckboxesSection
          title="Will testers need to do any of the following during the test?"
          capabilities={testerActivities}
          onCheckboxClick={onCheckboxClick}
        />
        <div className="flex pt-lg">
          <Button
            data-testid="capability-selection-wizard-express-continue-button"
            className="mr-2xl"
            disabled={isExpressCardDisabled}
            onClick={onExpressTestButtonClick}
          >
            Continue with Express
          </Button>
          <Button
            data-testid="capability-selection-wizard-advanced-continue-button"
            onClick={onAdvancedTestButtonClick}
          >
            Continue with Advanced
          </Button>
        </div>
      </div>
      <div className="col-span-3">
        <ExpressTestingServiceCard isDisabled={isExpressCardDisabled} />
        <AdvancedTestingServiceCard />
      </div>
    </div>
  );
}

type CheckboxesSectionProps = {
  title: string;
  capabilities: CapabilityOption[];
  onCheckboxClick: (label: string) => void;
};

function CheckboxesSection({ title, capabilities, onCheckboxClick }: CheckboxesSectionProps) {
  return (
    <div className="mb-lg">
      <div className="text-darkest text-lg font-bold pb-lg">{title}</div>
      {capabilities.map(capability => (
        <div key={capability.label}>
          <Checkbox
            isChecked={capability.isChecked}
            label={capability.label}
            onChange={() => onCheckboxClick(capability.label)}
          />
        </div>
      ))}
    </div>
  );
}

type CapabilityOption = {
  label: string;
  isChecked: boolean;
  type: "customRequirment" | "testerActivity";
};

function initCapabilities(selectedCapabilities: Array<string>): CapabilityOption[] {
  return [
    { isChecked: false, label: "I need testing in a specific country", type: "customRequirment" },
    { isChecked: false, label: "My app needs to be tested on a specific device/OS/browser", type: "customRequirment" },
    { isChecked: false, label: "I need to test my app with a specific demographic", type: "customRequirment" },
    { isChecked: false, label: "Complete financial transactions with real money", type: "testerActivity" },
    { isChecked: false, label: "Perform KYC check(s)", type: "testerActivity" },
    { isChecked: false, label: "Provide personal information/documentation", type: "testerActivity" },
    {
      isChecked: false,
      label: "Move to a different location during testing (e.g. to test GPS functionality)",
      type: "testerActivity",
    },
    { isChecked: false, label: "Interact with other testers", type: "testerActivity" },
  ].map(capability => ({
    ...capability,
    isChecked: selectedCapabilities.includes(capability.label),
  })) as CapabilityOption[];
}
