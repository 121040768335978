import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Button } from "@hopper/button";
import { Base64 } from "js-base64";
import queryString from "query-string";
import { useFormikContext } from "formik";

import { GeneralError, Form, SubmitButton } from "components/shared/form";
import { Maybe, AzureDevopsClientAuthentication } from "types/graphql";
import AuthenticationFields from "./authentication_fields";
import IntegrationSiteFields from "./integration_site_fields";

export type AzureDevopsFormProps = {
  clientAuthentication?: Maybe<AzureDevopsClientAuthentication>;
  isEditable: boolean;
  credentialsKey?: string;
  credentialsCreateError?: string;
  handleSubmit: (values: { [field: string]: unknown }) => Promise<void>;
  handleCancel: () => void;
};

export const defaultAzureDevopsSite = "https://dev.azure.com";

const defaultValues = {
  site: defaultAzureDevopsSite,
  organization: "",
  contextPath: "",
  username: "",
  accessToken: "",
};

function AzureDevopsForm({
  clientAuthentication,
  handleSubmit,
  handleCancel,
  credentialsCreateError,
  credentialsKey,
  isEditable,
}: AzureDevopsFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const isSelfHosted = clientAuthentication?.contextPath;
  const [isHostedOnAdo, setIsHostedOnAdo] = useState(clientAuthentication ? !isSelfHosted : true);

  const onSubmit = (values: { [field: string]: unknown }) => {
    setIsLoading(true);

    return handleSubmit(values).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        site: !isEditable ? clientAuthentication?.site : defaultValues.site,
        organization: !isEditable ? clientAuthentication?.organization : defaultValues.organization,
        contextPath: !isEditable ? clientAuthentication?.contextPath : defaultValues.contextPath,
        username: !isEditable ? clientAuthentication?.username : defaultValues.username,
        accessToken: defaultValues.accessToken,
      }}
      validations={yup.object({
        site: yup.string().required("can't be blank"),
      })}
    >
      <GeneralError />
      <FormPreset />

      <div className="grid grid-cols-2 mb-xl">
        <IntegrationSiteFields
          isHostedOnAdo={isHostedOnAdo}
          setIsHostedOnAdo={setIsHostedOnAdo}
          isEditable={isEditable}
          defaultValues={defaultValues}
        />
        <AuthenticationFields
          isHostedOnAdo={isHostedOnAdo}
          isEditable={isEditable}
          credentialsKey={credentialsKey}
          credentialsCreateError={credentialsCreateError}
        />
      </div>

      <div className="ml-4xl mb-4xl">
        <SubmitButton useHopperButton={true} className="mr-lg" loading={isLoading}>
          Save
        </SubmitButton>
        <Button variant="secondary" type="reset" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
}

function FormPreset() {
  const { setValues } = useFormikContext();

  useEffect(() => {
    const parsedQuery = queryString.parse(window.location.search);

    if (parsedQuery.state) {
      const valuesFromRedirect = JSON.parse(Base64.decode(parsedQuery.state)).values;

      setValues(valuesFromRedirect);
    }
  }, []);

  return null;
}

export default AzureDevopsForm;
