import React from "react";
import classnames from "classnames";
import { PopupMenuItem } from "@hopper/popup_menu";

import { IssueSeverity, IssueSeverityOption } from "types/graphql";
import { SeverityOption } from "../severity_option";

type SeverityPopupItemsProps = {
  onSeveritySelected: (issueSeverity: IssueSeverity) => void;
  currentSeverity?: IssueSeverity;
  issueSeverities: IssueSeverityOption[];
};

const SeverityPopupItems = ({ onSeveritySelected, currentSeverity, issueSeverities }: SeverityPopupItemsProps) => {
  const isCurrentSeverity = (severity: string) => currentSeverity === severity;
  const issueSeveritiesToDisplay = issueSeverities.filter(obj => obj.name !== "unknown");

  return (
    <div className="px-sm mb-sm">
      {issueSeveritiesToDisplay
        .map((edge: IssueSeverityOption) => edge?.name)
        .map(
          severity =>
            severity && (
              <PopupMenuItem
                key={severity}
                className={classnames("text-md", isCurrentSeverity(severity) && "text-muted")}
                onClick={() => onSeveritySelected(severity)}
                data-testid={`severity-popup-item-${severity}`}
              >
                <SeverityOption severity={severity} />
              </PopupMenuItem>
            ),
        )
        .reverse()}
    </div>
  );
};

export default SeverityPopupItems;
