import { gql, useMutation } from "@apollo/client";
import { TEST_CASE_QUERY } from "../use_test_case_result";

type RejectTestCaseResult = {
  rejectTestCaseResult: (testCaseId: string, caseResultId: string, rejectionReason: string) => Promise<void>;
  wasSuccessfullyRequested: boolean;
};

export const REJECT_TEST_CASE_RESULT = gql`
  mutation RejectTestCaseResult($input: FlexibleTestingRejectCaseResultInput!) {
    flexibleTestingRejectCaseResult(input: $input) {
      successful
      result {
        id
        isRejected
        rejectionReason
      }
      errors {
        code
        message
      }
    }
  }
`;

export const useRejectTestCaseResult = (): RejectTestCaseResult => {
  const [rejectTestCaseResult, { error }] = useMutation(REJECT_TEST_CASE_RESULT);

  return {
    rejectTestCaseResult: async (testCaseId, caseResultId, rejectionReason): Promise<void> => {
      await rejectTestCaseResult({
        variables: { input: { caseResultId, rejectionReason } },
        refetchQueries: [{ query: TEST_CASE_QUERY, variables: { id: testCaseId } }],
      });
    },
    wasSuccessfullyRequested: !error,
  };
};
