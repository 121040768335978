import React from "react";
import { Route, Switch } from "react-router-dom";

import NotFoundPage from "components/not_found_page";
// @ts-ignore
import TestRequestsLayout from "components/layouts/test_requests_layout";
import { websiteContentPaths } from "components/configuration";
import SignUp from "./sign_up";
import SignUpError from "./sign_up_error";

// @ts-ignore
const withLayout = (Component, Layout) => routeProps =>
  (
    <Layout {...routeProps}>
      <Component {...routeProps} />
    </Layout>
  );

function SignUpPage(): JSX.Element {
  return (
    <Switch>
      <Route path={websiteContentPaths.signUpError} render={withLayout(SignUpError, TestRequestsLayout)} />
      <Route path={websiteContentPaths.signUp} render={withLayout(SignUp, TestRequestsLayout)} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default SignUpPage;
