import React from "react";
import { FieldArray, getIn } from "formik";
import { Checkbox } from "@hopper/checkbox";
import { FormInlineError } from "@hopper/form_inline_error";
import FormLabel from "../form_label";

export type CheckboxOption = {
  value: string;
  label: string;
};

type CheckboxGroupProps = {
  label: string;
  required?: boolean;
  name: string;
  options: Array<CheckboxOption>;
};

const CheckboxGroup = ({ label, required = false, name, options }: CheckboxGroupProps) => (
  <FieldArray
    name={name}
    render={({ remove, push, form }) => {
      const errors = getIn(form.errors, name);
      const touch = getIn(form.touched, name);

      return (
        <div className="text-sm">
          <FormLabel label={label} required={required} labelProps={{ htmlFor: name }} />

          {options.map(option => {
            const isChecked = getIn(form.values, name).includes(option.value);

            return (
              <div key={option.value} data-test-role={`checkbox-${name}`}>
                <Checkbox
                  data-testid={`checkbox-${name}-${option.value}`}
                  label={option.label}
                  isChecked={isChecked}
                  onChange={() => {
                    if (isChecked) {
                      const idx = getIn(form.values, name).indexOf(option.value);
                      remove(idx);
                    } else {
                      push(option.value);
                    }
                  }}
                />
              </div>
            );
          })}
          {touch && errors && <FormInlineError>{errors}</FormInlineError>}
        </div>
      );
    }}
  />
);

export default CheckboxGroup;
