import React from "react";
import { Heading } from "@hopper/heading";
import { Link } from "react-router-dom";

// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";

type EmptyStatsProps = {
  areLiveTestsPresent: boolean;
};

const EmptyStats = ({ areLiveTestsPresent }: EmptyStatsProps) =>
  areLiveTestsPresent ? <LiveTestsPresent /> : <NothingInProgress />;

function NothingInProgress() {
  return (
    <div>
      <p>Welcome to Global App Testing!</p>

      <div className="mt-4xl">
        <Heading size={2}>It looks like it's time to launch your first test</Heading>
        <p>We're excited about the start of your journey with us. Where should we begin?</p>
      </div>

      <div className="mt-2xl">
        <Link
          to={createNavigationUrl("designTestLaunch")}
          className="font-bold text-link focus:outline-none cursor-pointer"
        >
          Launch a design test
        </Link>
        <p>You are designing your next product or feature</p>
      </div>

      <div className="mt-2xl">
        <Link
          to={createNavigationUrl("developmentTestLaunch")}
          className="font-bold text-link focus:outline-none cursor-pointer"
        >
          Launch a development test
        </Link>
        <p>You are making changes to your app and you want to test them as you go</p>
      </div>

      <div className="mt-2xl">
        <Link
          to={createNavigationUrl("releaseTestLaunch")}
          className="font-bold text-link focus:outline-none cursor-pointer"
        >
          Launch a release test
        </Link>
        <p>All planned changes are done and you are preparing a new version for release</p>
      </div>

      <div className="mt-2xl">
        <Link
          to={createNavigationUrl("liveTestLaunch")}
          className="font-bold text-link focus:outline-none cursor-pointer"
        >
          Launch a live test
        </Link>
        <p>You app is already live</p>
      </div>
    </div>
  );
}

function LiveTestsPresent() {
  return (
    <div>
      <p>There is no historical data, yet</p>

      <div className="mt-4xl">
        <Heading size={2}>Testing in progress...</Heading>
        <p>Test data will appear when your first test has finished</p>
      </div>
    </div>
  );
}

export default EmptyStats;
