import { getEnv } from "app/env";
import * as Sentry from "@sentry/browser";

const SENTRY_TRACES_SAMPLE_RATE = Number(getEnv("SENTRY_TRACES_SAMPLE_RATE") || "0.0");

const install = (): void => {
  if (getEnv("SENTRY_DSN")) {
    Sentry.init({
      dsn: getEnv("SENTRY_DSN"),
      environment: getEnv("SENTRY_ENVIRONMENT") || getEnv("APP_ENV"),
      initialScope: {
        tags: { app_env: getEnv("APP_ENV"), owner: getEnv("OWNER") },
      },
      release: getEnv("SENTRY_RELEASE"),
      autoSessionTracking: true,
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: false,
        }),
        new Sentry.BrowserTracing(),
      ],
      ignoreErrors: [
        /ResizeObserver loop limit exceeded/,
        /You need to sign in before continuing./,
        /NetworkError/,
        /Failed to execute 'removeChild' on 'Node'/,
        /this.remove is not a function/,
        /Cannot read property '_avast_submit' of undefined/, // avast extension error
        /Cannot read property 'getReadMode.*' of undefined/, // unknown source, not related to our app https://stackoverflow.com/questions/64175183/what-does-these-error-means-getreadmodeconfig-getreadmoderender-getreadmodeext

        // the list below is based on https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        /.*translate\.goog.*/,
        /.*SecurityError: Blocked a frame with origin.*from accessing a cross-origin frame.*/,
        /.*BetterJsPop.*/,
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        "Script error.",
        // Avast extension error
        "_avast_submit",
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      tracesSampler: (): boolean | number => {
        if (SENTRY_TRACES_SAMPLE_RATE <= 0.0 || isNaN(SENTRY_TRACES_SAMPLE_RATE)) {
          return false;
        } else {
          // TODO: filter only to specific pages otherwise we risk tracing too much
          // and/or pages we don't care about
          return SENTRY_TRACES_SAMPLE_RATE;
        }
      },
    });
  }
};

export default { install };
