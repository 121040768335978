import React from "react";
import { StatusLabel } from "@hopper/status_label";
import { Scalars } from "types/graphql";

const TestStatusBadge = ({ plannedEndAt }: { plannedEndAt: Scalars["ISO8601DateTime"] | null }) => {
  const beforeExpiration = !plannedEndAt || new Date(plannedEndAt) >= new Date();

  return (
    <div>
      <StatusLabel variant={beforeExpiration ? "default" : "danger"}>
        {beforeExpiration ? "Live" : "Overdue"}
      </StatusLabel>
    </div>
  );
};

export default TestStatusBadge;
