import React, { useState } from "react";
import { Button } from "@hopper/button";
import { Card } from "@hopper/card";
import { DecoratedText } from "@hopper/decorated_text";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { PageSection } from "components/flexible_testing/shared/page_section";
import { testEnvironmentsToLabel } from "components/flexible_testing/shared/test_environments";
import { PreviewField } from "components/flexible_testing/shared/preview_field";
import { PreviewTestCases } from "components/flexible_testing/shared/preview_test_cases";
import { PreviewTesterGroupList } from "./preview_tester_group_list";
import { SupervisedTestPreviewPageRequest } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import useLaunchSupervisedTest, {
  TesterGroup,
} from "components/flexible_testing/preview_page/content/use_launch_supervised_test";
import { TestEnvironmentPlatform } from "types/graphql";
import { websiteContentPaths } from "components/configuration";
import SupervisedTestLaunchedModal from "components/flexible_testing/test_manager/preview_page/supervised_test_launched_modal";
import { TestRequestErrorModal } from "components/flexible_testing/shared/test_request_error_modal";
import { formatAmount } from "components/utils/format_amount";
import { useTotalPayoutForTestCases } from "components/flexible_testing/shared/use_total_payout_for_test_cases";

type SupervisedTestContentProps = {
  testRequest: SupervisedTestPreviewPageRequest;
  handleEditTest: () => void;
};

const removeInvalidBackupTesterEmails = (testerGroups: TesterGroup[]) => {
  const selectedTesterEmails = testerGroups.flatMap(({ selectedTesterEmails }) => selectedTesterEmails);

  return testerGroups.map(testerGroup => {
    if (!testerGroup.automaticTesterSelectionEnabled) return testerGroup;

    return {
      ...testerGroup,
      backupTesterEmails: testerGroup.backupTesterEmails.filter(email => !selectedTesterEmails.includes(email)),
    };
  });
};

const SupervisedTestContent = ({ testRequest, handleEditTest }: SupervisedTestContentProps): JSX.Element => {
  const {
    applicationUrl,
    name,
    description,
    testCases,
    credentialsAndAccessInstructions,
    additionalUrlsAndResources,
    testEnvironments,
    testerSpecificationDocument,
    testerGroups,
    payoutBaseAmount,
    requiredAttachmentForPass,
    applyCountryPayoutMultiplier,
    automaticTesterManagementEnabled,
    automaticTesterManagementDisabledReason,
    requestedTestId,
  } = testRequest;
  const { launchTest, testResultsUrl, advancedTestRequestId, testId, isLoading, wasSuccessfullyRequested } =
    useLaunchSupervisedTest();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [launchTestErrorMessage, setLaunchTestErrorMessage] = useState("");
  const SHORT_VIEW_CASES_LIMIT = 3;
  const { id: parentTestId } = useParams<{ id: string }>();

  function omitTypename(key: string, value: string | undefined): string | undefined {
    return key === "__typename" ? undefined : value;
  }

  function prepareTesterEmails(testerEmails: string) {
    return testerEmails
      .split(",")
      .map(v => v.trim())
      .filter(v => v.length !== 0);
  }

  const handleLaunchTest = () => {
    const casesWithoutTypename = JSON.parse(JSON.stringify(testCases), omitTypename);
    const platforms = testRequest.testEnvironments as [TestEnvironmentPlatform];
    const testerGroups = testRequest.testerGroups.map(testerGroup => ({
      name: testerGroup.name,
      selectedTesterEmails: prepareTesterEmails(testerGroup.selectedTesterEmails),
      backupTesterEmails: prepareTesterEmails(testerGroup.backupTesterEmails),
      operatingSystemVersionIds: testerGroup.operatingSystemVersions.map(testerGroup => testerGroup.id),
      numberOfResultsNeeded: testerGroup.numberOfResultsNeeded,
      deviceTypeIds: testerGroup.deviceTypes.map(deviceType => deviceType.id),
      manufacturerIds: testerGroup.manufacturers.map(manufacturer => manufacturer.id),
      mobileDeviceIds: testerGroup.mobileDevices.map(mobileDevice => mobileDevice.id),
      operatingSystemIds: testerGroup.operatingSystems.map(operatingSystem => operatingSystem.id),
      internetBrowserIds: testerGroup.internetBrowsers.map(internetBrowser => internetBrowser.id),
      countryIds: testerGroup.countries.map(country => country.id),
      languageIds: testerGroup.languages.map(language => language.id),
      other: testerGroup.other,
      automaticTesterSelectionEnabled: testerGroup.automaticTesterSelectionEnabled,
      automaticTesterSelectionDisabledReason: testerGroup.automaticTesterSelectionDisabledReason,
      tags: testerGroup.tags,
    }));

    launchTest(
      name,
      description,
      credentialsAndAccessInstructions,
      additionalUrlsAndResources,
      casesWithoutTypename,
      applicationUrl,
      parentTestId,
      testerSpecificationDocument,
      platforms,
      removeInvalidBackupTesterEmails(testerGroups),
      payoutBaseAmount,
      requiredAttachmentForPass,
      applyCountryPayoutMultiplier,
      automaticTesterManagementEnabled,
      automaticTesterManagementDisabledReason,
      requestedTestId,
    )
      .then(() => setShowSuccessModal(true))
      .catch(error => {
        setLaunchTestErrorMessage(error.message);
        setShowErrorModal(true);
      });
  };

  const testEnvironmentLabels = testEnvironmentsToLabel(testEnvironments || []);
  const formattedPayoutAmount = formatAmount(payoutBaseAmount);
  const formattedTotalPayoutAmount = useTotalPayoutForTestCases(testCases?.length, payoutBaseAmount);

  return (
    <Card>
      <div className="p-3xl grid grid-cols-1 gap-4xl md:grid-cols-2">
        <PageSection title={name} fullWidthTitle={true}>
          <PreviewField label="Application url">
            <a href={applicationUrl} className="text-link underline">
              {applicationUrl}
            </a>
          </PreviewField>

          {testEnvironmentLabels.length > 0 && (
            <PreviewField label="Environment(s) included in this test">{testEnvironmentLabels.join(", ")}</PreviewField>
          )}

          {credentialsAndAccessInstructions && (
            <PreviewField label="Credentials and access instructions">
              <DecoratedText text={credentialsAndAccessInstructions} />
            </PreviewField>
          )}

          {additionalUrlsAndResources && (
            <PreviewField label="Additional URLs and resources">
              <DecoratedText text={additionalUrlsAndResources} />
            </PreviewField>
          )}

          {description && (
            <PreviewField label="Test objective">
              <DecoratedText text={description} />
            </PreviewField>
          )}
          <PreviewField label="Tester specification document">
            <DecoratedText text={testerSpecificationDocument} />
          </PreviewField>
        </PageSection>

        <div>
          <PreviewField label="Test cases">
            <PreviewTestCases testCases={testCases || []} shortViewCasesLimit={SHORT_VIEW_CASES_LIMIT} />
          </PreviewField>

          <PreviewField label="Require attachments for passed test cases">
            {requiredAttachmentForPass ? "Yes" : "No"}
          </PreviewField>

          <PreviewField label="Payout per test case">{formattedPayoutAmount}</PreviewField>

          <PreviewField label="Total payout for test cases">{formattedTotalPayoutAmount}</PreviewField>

          <PreviewField label="Apply country tier multipliers">
            {applyCountryPayoutMultiplier ? "Yes" : "No"}
          </PreviewField>

          <PageSection title="Group of settings for testers">
            <PreviewTesterGroupList testerGroups={testerGroups} />
          </PageSection>
        </div>

        <div className="mt-2xl">
          <Button
            disabled={wasSuccessfullyRequested}
            isLoading={isLoading}
            variant="primary"
            onClick={handleLaunchTest}
          >
            {wasSuccessfullyRequested ? "Test launched" : "Launch this test"}
          </Button>

          <Button
            disabled={wasSuccessfullyRequested}
            variant="secondary"
            className="ml-lg"
            onClick={handleEditTest}
            data-testid="edit-test-and-re-upload"
          >
            Edit test
          </Button>

          {wasSuccessfullyRequested && (
            <div className="text-md font-bold">
              <div className="mt-xl">This test has been launched</div>
              <div className="mt-sm">
                <Link className="text-link mr-xs" to={websiteContentPaths.flexcaseResults}>
                  View results
                </Link>
              </div>
            </div>
          )}

          <div style={{ lineHeight: "normal" }}>
            <SupervisedTestLaunchedModal
              testResultsUrl={testResultsUrl}
              testId={testId}
              advancedTestRequestId={advancedTestRequestId}
              isOpen={showSuccessModal}
              onCloseModalClicked={() => setShowSuccessModal(false)}
            />
          </div>

          <TestRequestErrorModal
            isOpen={showErrorModal}
            onCloseModalClicked={() => setShowErrorModal(false)}
            errorMessage={launchTestErrorMessage}
          />
        </div>
      </div>
    </Card>
  );
};

export default SupervisedTestContent;
