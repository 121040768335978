import React, { useState, useEffect } from "react";
import { JiraConnectIssuetype } from "types/graphql";

import { Select } from "components/shared/form";
import useProjectIssuetypes from "./use_project_issuetypes";

type IssuetypeFieldProps = {
  integrationId: string;
  projectKey: string;
  issuetypeName: string;
  labelClass?: string;
};

type MappedIssuetype = {
  value: string;
  label: string;
  isDisabled: boolean;
};

const RenderIssuetypesError = () => (
  <p className="bg-warning mb-lg p-lg">
    Some issue types are disabled because they require fields that are not sent while exporting. Please modify your JIRA
    settings if you'd like to use this issue type.
  </p>
);

function IssuetypeField({ integrationId, projectKey, issuetypeName, labelClass }: IssuetypeFieldProps): JSX.Element {
  const { isLoading, data: issuetypes } = useProjectIssuetypes(integrationId, projectKey);
  const placeholder = !projectKey && "Select project first";
  const isDisabled = !projectKey || isLoading;

  const [issuetypeOptions, setIssuetypeOptions] = useState<MappedIssuetype[]>([]);
  const [selectedIssuetype, setSelectedIssuetype] = useState<MappedIssuetype | null>();
  const [showIssuetypesError, setShowIssuetypesError] = useState(false);

  useEffect(() => {
    if (!isLoading && issuetypes) {
      const mappedIssuetypes = mapIssuetypes(issuetypes);
      const showIssuetypesError = !!mappedIssuetypes.find(({ isDisabled }) => isDisabled);

      setIssuetypeOptions(mappedIssuetypes);
      setShowIssuetypesError(showIssuetypesError);
      if (issuetypeName) {
        setSelectedIssuetype(getIssuetypeOption(mappedIssuetypes, issuetypeName));
      }
    }
  }, [projectKey, isLoading]);

  useEffect(() => {
    if (!issuetypeName) {
      setSelectedIssuetype(null);
    }
  }, [projectKey]);

  function onIssuetypeChange(issuetypeName: string) {
    setSelectedIssuetype(getIssuetypeOption(issuetypeOptions, issuetypeName));
  }

  return (
    <>
      <div className="mb-lg" data-test-role="form-group">
        <Select
          required
          name="issuetype_name"
          label="Issue Type"
          options={issuetypeOptions}
          isDisabled={isDisabled}
          placeholder={placeholder}
          value={selectedIssuetype}
          onChange={onIssuetypeChange}
          labelClass={labelClass}
          data-testid="issuetype-name-input"
        />
      </div>
      {showIssuetypesError && <RenderIssuetypesError />}
    </>
  );
}

function mapIssuetypes(issuetypes: JiraConnectIssuetype[]) {
  return issuetypes.map(({ name: value, error }) => ({
    value,
    label: error ? `${value} (${error})` : value,
    isDisabled: !!error,
  }));
}

function getIssuetypeOption(issuetypes: MappedIssuetype[], issuetypeName: string): MappedIssuetype | undefined {
  return issuetypes.find(({ value }) => value === issuetypeName);
}

export default IssuetypeField;
