import { gql, useMutation } from "@apollo/client";
import { ChatRegisterInput, ChatRegisterPayload, ChatType as ChatTypeEnum } from "types/graphql";
import { ChatType } from "types/app";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

const CHAT_REGISTER_MUTATION = gql`
  mutation ChatRegister($input: ChatRegisterInput!) {
    chatRegister(input: $input) {
      result {
        externalId
      }
    }
  }
`;

const useRegisterChat = (type: ChatType) => {
  const [registerChat] = useMutation<
    { chatRegister: ChatRegisterPayload },
    {
      input: ChatRegisterInput;
    }
  >(CHAT_REGISTER_MUTATION, {
    onError: () => {
      notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
        type: "danger",
        message: "An error occurred while fetching chat",
      });
    },
  });

  return {
    registerChat: async (targetId: ChatRegisterInput["targetId"], testerId: ChatRegisterInput["testerId"]) => {
      const { data } = await registerChat({
        variables: {
          input: {
            targetId,
            type: type as ChatTypeEnum,
            testerId,
          },
        },
      });

      return data?.chatRegister.result;
    },
  } as const;
};

export default useRegisterChat;
