import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingDestroyIssueTagInput, FlexibleTestingDestroyIssueTagPayload } from "types/graphql";
import { ISSUE_TAGS_QUERY } from "./use_issue_tags";
import { GET_ISSUES_TAB_DATA } from "components/flexible_testing/result_details_page/issues";

export const DESTROY_TAG = gql`
  mutation FlexibleTestingDestroyIssueTag($input: FlexibleTestingDestroyIssueTagInput!) {
    flexibleTestingDestroyIssueTag(input: $input) {
      successful
    }
  }
`;

type useDestroyIssueTagResult = {
  destroyIssueTag: (tagId: string) => Promise<void>;
};

export const useDestroyIssueTag = (testId: string | undefined): useDestroyIssueTagResult => {
  const [destroyIssueTagMutation] = useMutation<
    { flexibleTestingDestroyIssueTag: FlexibleTestingDestroyIssueTagPayload },
    { input: FlexibleTestingDestroyIssueTagInput }
  >(DESTROY_TAG, {
    update(cache, result) {
      cache.evict({ id: "ROOT_QUERY", fieldName: "flexibleTestingTest" });
    },
    refetchQueries: () => [{ query: ISSUE_TAGS_QUERY }, { query: GET_ISSUES_TAB_DATA, variables: { testId } }],
  });

  return {
    destroyIssueTag: async (issueTagId: string): Promise<void> => {
      await destroyIssueTagMutation({
        variables: {
          input: {
            issueTagId,
          },
        },
      });
    },
  };
};
