import { gql } from "@apollo/client";

export const GET_RESULTS_HEADER_DATA = gql`
  query getPlatformTestResultsHeader($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        _id
        name
        code
        accessToApplication
        testObjective
        notes
        platformsToTestOn
        deviceRequirements
        testLocalisation
        testCasesUrl
        knownIssuesUrl
        type
        capabilities
        sdlcStage
        supportsAutomaticTesterManagement
        automaticTesterManagementEnabled
        desiredOutcomes {
          name
          code
        }
        customDesiredOutcomes
        testerSessions {
          edges {
            node {
              id
              feedback
            }
          }
        }
      }
      ... on FlexibleTestingTest {
        id
        _id
        name
        code
        description
        credentialsAndAccessInstructions
        additionalUrlsAndResources
        applicationUrl
        createdAt
        type
        sdlcStage
        payoutBaseAmount
        parentTestId
        requiredAttachmentForPass
        linkedTestCycleUrl
        applyCountryPayoutMultiplier
        supportsAutomaticTesterManagement
        automaticTesterManagementEnabled
        canModifyNumberOfResultsNeeded
        desiredOutcomes {
          name
          code
        }
        environments {
          platform
        }
        testerSessions {
          edges {
            node {
              id
              feedback
            }
          }
        }
        parentTest {
          advancedTestRequest {
            _id
          }
        }
        availableCommunityTestManagers {
          edges {
            node {
              id
              fullName
            }
          }
        }
        assignedCommunityTestManagers {
          edges {
            node {
              id
              fullName
              status
            }
          }
        }
        communityTestManagersEnabled
      }
    }
  }
`;
