import { gql, useQuery } from "@apollo/client";

export const CASE_STATUS_AFTER_UNDOING_REJECTION_QUERY = gql`
  query getCaseStatusAfterUndoingRejection($id: ID!) {
    flexibleTestingTestCaseResult(id: $id) {
      id
      caseStatusAfterUndoingRejection
      testCase {
        status
      }
    }
  }
`;

type TestCaseStatusAfterUndoingRejectionReponse = {
  caseStatusAfterUndoingRejection: string;
  willTestCaseStatusChangeAfterUndoingRejection: boolean;
  isLoading: boolean;
};

export function useTestCaseStatusAfterUndoingRejection(id: string): TestCaseStatusAfterUndoingRejectionReponse {
  const { data, loading } = useQuery(CASE_STATUS_AFTER_UNDOING_REJECTION_QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  const caseStatusAfterUndoingRejection = data?.flexibleTestingTestCaseResult?.caseStatusAfterUndoingRejection;
  const currentTestCaseStatus = data?.flexibleTestingTestCaseResult?.testCase?.status;
  const willTestCaseStatusChangeAfterUndoingRejection =
    caseStatusAfterUndoingRejection !== "unknown" && caseStatusAfterUndoingRejection !== currentTestCaseStatus;

  return {
    willTestCaseStatusChangeAfterUndoingRejection,
    caseStatusAfterUndoingRejection,
    isLoading: loading,
  };
}
