import React from "react";

type ProgressBarProps = {
  headerMessage: string;
  successPercentage: number;
};

const ProgressBar = ({ headerMessage, successPercentage }: ProgressBarProps): JSX.Element => (
  <div>
    <div className="text-sm text-secondary">{headerMessage}</div>
    <div className="relative h-xs mt-xs">
      <div className="rounded-base absolute h-xs bg-muted" style={{ width: "100%" }} />
      <div className="rounded-base absolute h-xs bg-success" style={{ width: `${successPercentage}%` }} />
    </div>
  </div>
);

export default ProgressBar;
