import { Events } from "components/configuration";
import { useTrackingModeration } from "./use_tracking_moderation";

export function useTrackingOpenRelatedIssue() {
  const eventName = Events.RELATED_ISSUE_OPENED;
  const { tracking, organizationUserEmail, trackingCaseResultId, trackingTestId } = useTrackingModeration();

  const trackOpenRelatedIssue = (relatedIssueId: string) => {
    tracking.trackEvent({
      action: "track",
      name: eventName,
      eventProperties: {
        related_issue_id: relatedIssueId,
        test_id: trackingTestId,
        case_result_id: trackingCaseResultId,
        organization_user_email: organizationUserEmail,
      },
    });
  };

  return trackOpenRelatedIssue;
}
