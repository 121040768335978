import { Form, GeneralError, SubmitButton, TextInput } from "components/flexible_testing/shared/form";
import React from "react";
import { useSaveOrganizationChosenName } from "components/flexible_testing/shared/use_save_organization_chosen_name";
import { useOrganizationChosenNameSuggestion } from "components/flexible_testing/shared/use_organization_chosen_name_suggestion";

type SaveChosenNameFormProps = {
  onSave?: () => void;
};

function SaveChosenNameForm({ onSave }: SaveChosenNameFormProps) {
  const { saveOrganizationChosenName, isLoading } = useSaveOrganizationChosenName();
  const nameSuggestion = useOrganizationChosenNameSuggestion();

  return (
    <Form
      data-testid="chosen-name-form"
      initialValues={{ chosenName: nameSuggestion }}
      onSubmit={async values => {
        await saveOrganizationChosenName(values.chosenName as string);

        onSave && onSave();
      }}
    >
      <div data-test-role="form-group">
        <TextInput name="chosenName" label="Company" />
      </div>
      <GeneralError />
      <SubmitButton className="mt-lg" useHopperButton={true} loading={isLoading}>
        Save
      </SubmitButton>
    </Form>
  );
}

export default SaveChosenNameForm;
