import React from "react";
import { useQuery } from "@apollo/client";
import { IntegrationsPage as FlexibleTestingIntegrationsPage } from "../../../../flexible_testing/integrations_page";
import { GET_AVAILABLE_INTEGRATIONS } from "components/shared/integration/integration_queries";

const IntegrationsQuery = () => {
  const { loading, error, data } = useQuery(GET_AVAILABLE_INTEGRATIONS);

  if (error) {
    return <p>{error.Error}</p>;
  }

  const integrations =
    (data && data.availableIntegrations && data.availableIntegrations.edges.map(edge => edge.node)) || [];

  return <FlexibleTestingIntegrationsPage integrationsLoaded={!loading} integrations={integrations} />;
};

export default IntegrationsQuery;
