import React from "react";
import DefaultLayoutHelmet from "../shared/default_layout_helmet";
import { Helmet } from "react-helmet";
// @ts-ignore
import HeaderNavigation from "./components/header_navigation/index";
// @ts-ignore
import { Notifications } from "../shared/notifications";
import { StickyContainer } from "react-sticky";
import styles from "./style.module.css";

type FlexibleTestingLayoutProps = {
  children: JSX.Element;
};

const FlexibleTestingLayout = ({ children }: FlexibleTestingLayoutProps): JSX.Element => (
  <div id="organization">
    <StickyContainer>
      <DefaultLayoutHelmet />
      <Helmet title="Global App Testing" />
      <div id="wrapper">
        <div className="bg-lightest">
          <HeaderNavigation />

          <Notifications />

          <div className={`px-lg overflow-auto mt-6xl mx-auto mb-0 ${styles.content}`}>{children}</div>
        </div>
      </div>
    </StickyContainer>
  </div>
);

export default FlexibleTestingLayout;
