import { CaseResultStatus } from "../case_result_status";

const statusColorMap: Record<CaseResultStatus, string> = {
  pass: "info",
  fail: "muted",
  blocked: "muted",
  not_tested: "muted",
  none: "muted",
} as const;

export default statusColorMap;
