import React from "react";
import pluralize from "pluralize";

import { ParticipantModerationStatusEnum } from "types/graphql";
import { pick } from "lodash";

const MODERATION_STATUS_LABEL = {
  invalid_issue: "Fake issue",
  known_issue: "Known issue",
  moderated: "Moderated",
  pending: undefined,
} as const;

type IssuesModerationStatusProps = {
  numberOfPendingIssueModerations: number;
  moderationStatus: `${ParticipantModerationStatusEnum}` | undefined;
  only?: `${ParticipantModerationStatusEnum}`[];
};

const IssuesModerationStatus = ({
  numberOfPendingIssueModerations,
  moderationStatus,
  only = Object.keys(MODERATION_STATUS_LABEL) as (keyof typeof MODERATION_STATUS_LABEL)[],
}: IssuesModerationStatusProps) => {
  const possibleLabels = pick(MODERATION_STATUS_LABEL, only);
  let issuesInfo: string = moderationStatus ? possibleLabels[moderationStatus] || "" : "";

  if (moderationStatus === "pending")
    issuesInfo = `${numberOfPendingIssueModerations} pending ${pluralize(
      "moderation",
      numberOfPendingIssueModerations,
    )}`;

  return <span className="text-secondary text-sm">{issuesInfo}</span>;
};

export default IssuesModerationStatus;
