import React, { useRef, useState } from "react";
import classnames from "classnames";

import Popup from "components/shared/popup";
import { RejectionReasons } from "components/flexible_testing/shared/rejection_reasons";
import { useIssueReject } from "components/flexible_testing/issue_page/use_issue_reject";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { FlexibleTestingIssue } from "types/graphql";

type RejectionReasonPopupProps = {
  issue: FlexibleTestingIssue;
};

const RejectionReasonPopup = ({ issue }: RejectionReasonPopupProps): JSX.Element => {
  const labelClassNames = classnames(
    "bg-light py-xs px-sm rounded-base cursor-pointer whitespace-nowrap",
    "text-body leading-lg font-bold text-xs uppercase tracking-extra-wide",
  );
  const [displayRejectionReasons, setDisplayRejectionReasons] = useState(false);
  const { rejectIssue } = useIssueReject(issue.id);
  const wrapperRef = useRef(null);

  const handleOutsideClick = () => setDisplayRejectionReasons(false);

  useOutsideHandler(wrapperRef, handleOutsideClick);

  const onRejectIssueButtonClick = (rejectionReason: string) => {
    rejectIssue(issue.id, rejectionReason);

    setDisplayRejectionReasons(false);
  };

  const rejectionReasonOptionsElement = () => (
    <div
      className="mt-sm border border-muted shadow rounded-base bg-lightest z-10"
      style={{ width: "170px" }}
      data-testid="options-box"
    >
      <RejectionReasons rejectIssue={onRejectIssueButtonClick} />
    </div>
  );

  return (
    <div ref={wrapperRef}>
      <Popup popupElement={rejectionReasonOptionsElement()} isOpen={displayRejectionReasons} offset={[-18, 8]}>
        <span
          className={labelClassNames}
          data-testid="rejection-reason-label"
          onClick={() => {
            setDisplayRejectionReasons(!displayRejectionReasons);
          }}
        >
          {issue.triageReason}
        </span>
      </Popup>
    </div>
  );
};

export default RejectionReasonPopup;
