import React from "react";
import { Link } from "react-router-dom";
import { Heading } from "@hopper/heading";

import { websiteContentPaths } from "components/configuration";

const OrganizationWithoutIntegrationSetup = (): JSX.Element => (
  <>
    <Heading>You don't have any integrations set up, yet</Heading>
    <p data-testid="information-section">
      Visit the{" "}
      <b>
        <Link className="text-link" data-testid="integrations-link" to={websiteContentPaths.integrationSettings}>
          Integrations
        </Link>
      </b>{" "}
      section to set up and activate an integration
    </p>
  </>
);

export default OrganizationWithoutIntegrationSetup;
