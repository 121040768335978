import React, { useRef, RefObject } from "react";
import { Button } from "@hopper/button";
import { Input } from "@hopper/legacy_input";

type CopyToClipboardProps = {
  value?: string;
};

function CopyToClipboard({ value }: CopyToClipboardProps): JSX.Element {
  const input = useRef<HTMLInputElement>(null);

  const copyToClipboard = (input: RefObject<HTMLInputElement>) => {
    input?.current?.select();
    document.execCommand("copy");
  };

  return (
    <span className="flex">
      <Input
        ref={input}
        readOnly
        value={value}
        className="rounded-r-none border-none bg-light text-sm text-body"
        data-testid="copy-to-clipboard"
      />
      <Button
        className="rounded-l-none text-xs"
        onClick={() => copyToClipboard(input)}
        size="sm"
        type="button"
        variant="primary"
      >
        Copy
      </Button>
    </span>
  );
}

export default CopyToClipboard;
