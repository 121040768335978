import React from "react";
import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";
import { Button } from "@hopper/button";
import { UseCaseReasons } from "./use_case_reasons";

type UseCaseCardProps = {
  title: string;
  stageCode: SoftwareDevelopmentLifeCycleStageName;
  description: string;
  icon: string;
  useCaseReasons: string[];
  onButtonClick: (sdlcStageCode: SoftwareDevelopmentLifeCycleStageName) => void;
};

export function UseCaseCard({
  title,
  stageCode,
  description,
  icon,
  useCaseReasons,
  onButtonClick,
}: UseCaseCardProps): JSX.Element {
  return (
    <div
      className="rounded-base border-2 border-light p-2xl h-full"
      data-testid={`sdlc-selection-wizard-${stageCode}-card`}
    >
      <img src={icon} />
      <div className="font-bold text-xl pt-xl text-darkest">{title}</div>
      <div className="pt-xs" style={{ height: "4.75rem" }}>
        {description}
      </div>
      <div className="pt-xl pb-md">
        <Button onClick={() => onButtonClick(stageCode)} data-testid={`sdlc-selection-wizard-${stageCode}-button`}>
          Launch a {stageCode} test
        </Button>
      </div>
      <div className="pt-xl">
        <UseCaseReasons reasons={useCaseReasons} />
      </div>
    </div>
  );
}
