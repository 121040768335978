import { gql, useMutation } from "@apollo/client";
import {
  LaunchSupervisedTestPayload,
  FlexibleTestingTestCaseParams,
  TestEnvironmentPlatform,
  MutationError,
} from "types/graphql";

export type TesterGroup = {
  name: string;
  selectedTesterEmails: Array<string>;
  backupTesterEmails: Array<string>;
  operatingSystemVersionIds: Array<string>;
  numberOfResultsNeeded: number;
  deviceTypeIds: string[];
  manufacturerIds: string[];
  mobileDeviceIds: string[];
  operatingSystemIds: string[];
  internetBrowserIds: string[];
  countryIds: string[];
  other?: string;
  automaticTesterSelectionEnabled: boolean;
  automaticTesterSelectionDisabledReason?: string;
  tags?: string[];
};

type LaunchSupervisedTestInput = {
  additionalUrlsAndResources?: string;
  applicationUrl: string;
  clientMutationId?: string;
  credentialsAndAccessInstructions?: string;
  description?: string;
  name: string;
  parentTestId: string;
  platforms?: Array<TestEnvironmentPlatform>;
  testCases?: Array<FlexibleTestingTestCaseParams>;
  testerGroups?: Array<TesterGroup>;
  testerSpecificationDocument?: string;
  payoutBaseAmount: number;
  requiredAttachmentForPass: boolean;
  applyCountryPayoutMultiplier: boolean;
  automaticTesterManagementEnabled: boolean;
  automaticTesterManagementDisabledReason: string;
  requestedTestId?: string;
};

export const LAUNCH_SUPERVISED_TEST_MUTATION = gql`
  mutation LaunchSupervisedTest($input: LaunchSupervisedTestInput!) {
    launchSupervisedTest(input: $input) {
      successful
      result {
        testId
        resultsPageUrl
        advancedTestRequestId
      }
      errors {
        message
      }
    }
  }
`;

type FormSubmitHook = {
  launchTest: (
    name: string,
    description: string,
    credentialsAndAccessInstructions: string,
    additionalUrlsAndResources: string,
    testCases: Array<FlexibleTestingTestCaseParams>,
    applicationUrl: string,
    parentTestId: string,
    testerSpecificationDocument: string,
    platforms: [TestEnvironmentPlatform],
    testerGroups: Array<TesterGroup>,
    payoutBaseAmount: number,
    requiredAttachmentForPass: boolean,
    applyCountryPayoutMultiplier: boolean,
    automaticTesterManagementEnabled: boolean,
    automaticTesterManagementDisabledReason: string,
    requestedTestId?: string,
  ) => Promise<void>;
  testResultsUrl: string;
  advancedTestRequestId: string;
  testId: string;
  isLoading: boolean;
  wasSuccessfullyRequested: boolean;
  errors: Array<MutationError> | undefined;
};

function useLaunchSupervisedTest(): FormSubmitHook {
  const [launchTest, { data, loading, called, error }] = useMutation<
    { launchSupervisedTest: LaunchSupervisedTestPayload },
    { input: LaunchSupervisedTestInput }
  >(LAUNCH_SUPERVISED_TEST_MUTATION);

  return {
    launchTest: async (
      name,
      description,
      credentialsAndAccessInstructions,
      additionalUrlsAndResources,
      testCases,
      applicationUrl,
      parentTestId,
      testerSpecificationDocument,
      platforms,
      testerGroups,
      payoutBaseAmount,
      requiredAttachmentForPass,
      applyCountryPayoutMultiplier,
      automaticTesterManagementEnabled,
      automaticTesterManagementDisabledReason,
      requestedTestId,
    ): Promise<void> => {
      const result = await launchTest({
        variables: {
          input: {
            name,
            description,
            credentialsAndAccessInstructions,
            additionalUrlsAndResources,
            testCases,
            applicationUrl,
            parentTestId,
            testerSpecificationDocument,
            platforms,
            testerGroups,
            payoutBaseAmount,
            requiredAttachmentForPass,
            applyCountryPayoutMultiplier,
            automaticTesterManagementEnabled,
            automaticTesterManagementDisabledReason,
            requestedTestId: requestedTestId || undefined,
          },
        },
      });
      const launchSupervisedTestResult = result.data?.launchSupervisedTest;

      if (!launchSupervisedTestResult?.successful) {
        throw new Error(launchSupervisedTestResult?.errors[0]?.message);
      }
    },
    testResultsUrl: data?.launchSupervisedTest?.result?.resultsPageUrl ?? "",
    advancedTestRequestId: data?.launchSupervisedTest?.result?.advancedTestRequestId ?? "",
    testId: data?.launchSupervisedTest?.result?.testId ?? "",
    isLoading: loading,
    errors: data?.launchSupervisedTest?.errors,
    wasSuccessfullyRequested: called && !error && data?.launchSupervisedTest?.errors.length === 0,
  };
}

export default useLaunchSupervisedTest;
