import { gql } from "@apollo/client";

const FLEXIBLE_TESTING_ISSUE_SIMPLE_FRAGMENT = gql`
  fragment flexibleTestingIssueSimple on FlexibleTestingIssue {
    id
    code
    title
    severity
  }
`;

const TEST_STATS_FRAGMENT = gql`
  fragment testStats on TestStats {
    participantsTotalCount
    issuesTotalCount
    issuesLowSeverityCount
    issuesMediumSeverityCount
    issuesHighSeverityCount
    issuesCriticalSeverityCount
    testCasesTotalCount
    testCasesFailedCount
    testersResults {
      testerName
      status
      country {
        id
        code
      }
      testCasesTotalCount
      testCasesPassedCount
      testCasesFailedCount
    }
  }
`;

export const GET_SUMMARY_TAB_DATA = gql`
  query getPlatformTestSummaryTab($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        issues {
          totalCount
          edges {
            node {
              ...flexibleTestingIssueSimple
            }
          }
        }
        testStats {
          ...testStats
        }
      }
      ... on FlexibleTestingTest {
        id
        issues {
          totalCount
          edges {
            node {
              ...flexibleTestingIssueSimple
            }
          }
        }
        testStats {
          ...testStats
        }
      }
    }
  }

  ${FLEXIBLE_TESTING_ISSUE_SIMPLE_FRAGMENT}
  ${TEST_STATS_FRAGMENT}
`;
