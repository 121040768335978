import React, { useEffect } from "react";
import { useField } from "formik";

import { TestCase } from "types/test_cases";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { Button } from "@hopper/button";
import { Section } from "components/flexible_testing/shared/section";
import classnames from "classnames";
import { FormField } from "@hopper/form_field";
import { Input } from "@hopper/input";
import { Spinner } from "@hopper/loading";
import TestCasesList from "components/flexible_testing/shared/test_cases/file_upload/test_cases_list";
import { useImportTestCases } from "./use_import_test_cases_form_testrail_testrun";

const TestCases = (): JSX.Element => {
  const [includeTestCases, _includeTestCasesMeta, { setValue: setIncludeTestCases }] =
    useField<boolean>("includeTestCases");
  const [testCases, _testCasesMeta, { setValue: setTestCases }] = useField<Array<TestCase> | null>("testCases");
  const [testRunId, _testRunIdeMeta, { setValue: _setTestRunId }] = useField<string | "">({ name: "testRunId" });

  const { fetchTestCases, loading } = useImportTestCases(setTestCases);

  useEffect(() => {
    if (!includeTestCases.value && testCases.value !== null) {
      setTestCases(null);
    }
  }, [includeTestCases.value]);

  const radioButtonStyle = { marginTop: 0, marginRight: "0.5rem", accentColor: "#373A40" };
  const testCasesClassNames = classnames([{ hidden: !includeTestCases.value }]);

  const handleButtonClick = async () => {
    let runId = testRunId.value;
    runId = runId.charAt(0) === "R" ? runId.substring(1) : runId;

    await fetchTestCases(runId);
  };

  return (
    <div className="mb-2xl">
      <PageSection title="Does this test run include test cases?">
        <div data-test-role="radio" className="text-xs">
          <label className="inline-flex justify-center items-center mr-3xl cursor-pointer">
            {/* style is used instead of className to override Bootstrap*/}
            <input
              style={radioButtonStyle}
              checked={includeTestCases.value}
              type="radio"
              onChange={() => setIncludeTestCases(true)}
              name="include_test_cases"
              value="true"
              data-testid="include-test-cases"
            />
            Yes
          </label>

          <label className="ml-xs inline-flex justify-center items-center cursor-pointer">
            {/* style is used instead of className to override Bootstrap*/}
            <input
              style={radioButtonStyle}
              checked={!includeTestCases.value}
              type="radio"
              onChange={() => setIncludeTestCases(false)}
              name="include_test_cases"
              value="false"
            />
            <span>No</span>
          </label>
        </div>
      </PageSection>
      <Section title="Import Test Cases From TestRail" className={testCasesClassNames}>
        <div className="flex items-end">
          <FormField label="Id of the Test Rails Test Run" isOptional={true}>
            <div className="relative">
              <Input
                id="testRunId"
                style={{ minWidth: "12rem" }}
                data-testid="test-run-id"
                placeholder="eg 12345, R12345"
                {...testRunId}
              />
              <div className="absolute top-0 right-0">{loading && <Spinner size={40} />}</div>
            </div>
          </FormField>
          <Button
            variant="secondary"
            className="ml-xl mb-sm"
            type="button"
            disabled={!testRunId.value || loading}
            onClick={handleButtonClick}
          >
            {loading ? "Loading..." : "Import Test Cases"}
          </Button>
        </div>
        {testCases.value && (
          <TestCasesList testCases={testCases.value as TestCase[]} onTestCasesUpload={setTestCases} />
        )}
      </Section>
    </div>
  );
};

export default TestCases;
