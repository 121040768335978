import classNames from "classnames";
import React from "react";
type StatusSectionProps = {
  children?: JSX.Element;
  status?: string;
  className?: string;
};

const StatusSection = ({ children, status = "muted", className }: StatusSectionProps): JSX.Element => (
  <div style={{ borderLeftWidth: "3px" }} className={classNames("mb-xl", className, `border-${status}`)}>
    {children}
  </div>
);

export default StatusSection;
