import React from "react";
import { StatusIndicator } from "components/flexible_testing/shared/status_indicator";
import { FlexibleTestingTestCaseResult, FlexibleTestingTestWindow } from "types/graphql";
import pluralize from "pluralize";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import TestStatusBadge from "components/flexible_testing/result_details_page/test_status_badge";

type OverviewProps = {
  caseResults: FlexibleTestingTestCaseResult[] | null | undefined;
  testWindow: FlexibleTestingTestWindow;
};

type TestStatusProps = {
  testWindow: FlexibleTestingTestWindow;
  overdue: boolean;
};

const TestStatus = ({ testWindow, overdue }: TestStatusProps) => {
  if (overdue) {
    return (
      <div className="ml-md" data-testid="status-indicator">
        <TestStatusBadge plannedEndAt={testWindow.plannedEndAt} />
      </div>
    );
  } else {
    return <StatusIndicator status="live" />;
  }
};

const LiveCaseOverview = ({ caseResults, testWindow }: OverviewProps) => {
  const testersCount = caseResults?.length;
  const overdue = new Date(testWindow?.plannedEndAt) < new Date();
  const timeDistance = formatDistanceToNowStrict(parseISO(testWindow.plannedEndAt));
  const message = overdue ? `by ${timeDistance}` : `${timeDistance} remaining`;

  return (
    <div className="mt-4xl flex">
      <div className="border-r-2 border-muted pr-xl py-0 flex flex-col justify-between leading-100">
        <div className="text-xl font-bold pt-0 pl-xs mb-sm" data-testid="test-case-overall-status">
          <TestStatus testWindow={testWindow} overdue={overdue} />
        </div>
        <div className="text-xs font-bold text-secondary tracking-extra-wide uppercase ml-xl leading-100">
          {message}
        </div>
      </div>
      <div className="pl-xl text-xs flex items-center text-secondary">
        <div>{`${testersCount} ${pluralize("tester", testersCount)} finished this test case`}</div>
      </div>
    </div>
  );
};

export default LiveCaseOverview;
