import React from "react";
import NoIssuesFoundYet from "./no_issues_found_yet";
import NoIssuesFoundForFinishedTest from "./no_issues_found_for_finished_test";
import NoIssuesAcceptedYet from "./no_issues_accepted_yet";
import NoIssuesRejectedYet from "./no_issues_rejected_yet";
import NoIssuesRejected from "./no_issues_rejected";
import NoIssuesAccepted from "./no_issues_accepted";
import IssueTriageCompleted from "./issue_triage_completed";
import NoIssuesForNotOpenTestYet from "./no_issues_for_not_open_test_yet";

type ReportedIssuesInfoProps = {
  currentIssuesFilter: string;
  testStatus: string;
  issuesToDisplayCount: number;
  newIssuesCount: number;
  acceptedIssuesCount: number;
  rejectedIssuesCount: number;
};

const ReportedIssuesInfo = ({
  currentIssuesFilter,
  testStatus,
  issuesToDisplayCount,
  newIssuesCount,
  acceptedIssuesCount,
  rejectedIssuesCount,
}: ReportedIssuesInfoProps): JSX.Element | null => {
  let message = null;
  const isTestFinished = isStandardTestingFinishedStatus(testStatus) || isAdvancedTestingFinishedStatus(testStatus);
  const isTestPrep = testStatus === "prep";
  const noIssuesForNotOpenTestYet = isTestPrep && currentIssuesFilter === "untriaged" && newIssuesCount === 0;
  const noIssuesFoundYet = !isTestFinished && newIssuesCount === 0 && currentIssuesFilter === "untriaged";
  const isTriageCompleted =
    isTestFinished &&
    currentIssuesFilter === "untriaged" &&
    acceptedIssuesCount + rejectedIssuesCount === issuesToDisplayCount;
  const noIssuesFoundAfterTestFinished =
    isTestFinished && issuesToDisplayCount === 0 && currentIssuesFilter === "untriaged";
  const noIssuesAcceptedYet =
    isTestFinished && currentIssuesFilter === "accepted" && acceptedIssuesCount === 0 && newIssuesCount > 0;
  const noIssuesRejectedYet =
    isTestFinished && currentIssuesFilter === "rejected" && rejectedIssuesCount === 0 && newIssuesCount > 0;
  const noIssuesRejected =
    isTestFinished &&
    currentIssuesFilter === "rejected" &&
    issuesToDisplayCount > 0 &&
    acceptedIssuesCount === issuesToDisplayCount;
  const noIssuesAccepted =
    isTestFinished &&
    currentIssuesFilter === "accepted" &&
    issuesToDisplayCount > 0 &&
    rejectedIssuesCount === issuesToDisplayCount;

  if (noIssuesForNotOpenTestYet) {
    message = <NoIssuesForNotOpenTestYet />;
  } else if (noIssuesFoundYet) {
    message = <NoIssuesFoundYet />;
  } else if (noIssuesFoundAfterTestFinished) {
    message = <NoIssuesFoundForFinishedTest />;
  } else if (noIssuesAcceptedYet) {
    message = <NoIssuesAcceptedYet />;
  } else if (noIssuesRejectedYet) {
    message = <NoIssuesRejectedYet />;
  } else if (noIssuesRejected) {
    message = <NoIssuesRejected />;
  } else if (noIssuesAccepted) {
    message = <NoIssuesAccepted />;
  } else if (isTriageCompleted) {
    message = <IssueTriageCompleted />;
  }

  return message;
};

function isStandardTestingFinishedStatus(status: string): boolean {
  return status === "closed";
}

function isAdvancedTestingFinishedStatus(status: string): boolean {
  return status === "finished";
}

export default ReportedIssuesInfo;
