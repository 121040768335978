import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { LinkContainer } from "react-router-bootstrap";

// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import { helpCenterUrl } from "components/configuration";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { useLogout } from "components/shared/hooks/use_logout";
import Authorization from "app/authorization/authorization";

type CollapsedMenuProps = {
  shouldShowCreditModelMenuOption: boolean;
};

const CollapsedMenu = ({ shouldShowCreditModelMenuOption }: CollapsedMenuProps): JSX.Element => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useLogout();
  const { pathname } = useLocation();
  const inactiveLinkClassName = "py-md pl-2xl font-bold text-secondary border-l-4 border-lightest hover:text-darkest";
  const activeLinkClassName = "py-md pl-2xl font-bold bg-light border-l-4 border-darkest text-darkest";

  const generateLinkClassName = (linkUrl: string) =>
    pathname === linkUrl ? activeLinkClassName : inactiveLinkClassName;

  const handleOutsideClick = () => {
    setIsOpen(false);
  };

  useOutsideHandler(wrapperRef, handleOutsideClick);

  return (
    <div ref={wrapperRef}>
      <div className="transform scale-75">
        <button
          className={classnames("hamburger hamburger--squeeze focus:outline-none", isOpen ? "is-active" : "")}
          type="button"
          onClick={() => setIsOpen(prev => !prev)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
      <div
        className={classnames(
          "flex flex-col absolute bg-lightest shadow overflow-y-scroll",
          "transition-margin duration transition-ease-in",
        )}
        style={{ width: 250, height: "100vh", top: 68, right: 0, marginRight: isOpen ? 0 : -250 }}
      >
        <div className="flex flex-col pt-4xl">
          <Authorization roles={["testManager", "customerUser"]}>
            <>
              <LinkContainer to={createNavigationUrl("overview")} onClick={handleOutsideClick}>
                <a>
                  <div className={generateLinkClassName(createNavigationUrl("overview"))}>Overview</div>
                </a>
              </LinkContainer>

              <LinkContainer to={createNavigationUrl("flexcaseLaunch")} onClick={handleOutsideClick}>
                <a>
                  <div className={generateLinkClassName(createNavigationUrl("flexcaseLaunch"))}>Launch a Test</div>
                </a>
              </LinkContainer>
            </>
          </Authorization>

          <LinkContainer to={createNavigationUrl("flexcaseResults")} onClick={handleOutsideClick}>
            <a>
              <div className={generateLinkClassName(createNavigationUrl("flexcaseResults"))}>Results</div>
            </a>
          </LinkContainer>

          <Authorization roles={["testManager", "customerUser"]}>
            <>
              <LinkContainer to={createNavigationUrl("allIssues")} onClick={handleOutsideClick}>
                <a>
                  <div className={generateLinkClassName(createNavigationUrl("allIssues"))}>Issues</div>
                </a>
              </LinkContainer>
              <LinkContainer to={createNavigationUrl("services")} onClick={handleOutsideClick}>
                <a>
                  <div className={generateLinkClassName(createNavigationUrl("services"))}>Services</div>
                </a>
              </LinkContainer>
              <div className="ml-2xl border-b-2 border-light" style={{ height: 14, width: 186 }} />
            </>
          </Authorization>
        </div>
        <Authorization roles={["testManager", "customerUser"]}>
          <div className="flex flex-col pt-md">
            <LinkContainer to={createNavigationUrl("integrationSettings")} onClick={handleOutsideClick}>
              <a>
                <div className={generateLinkClassName(createNavigationUrl("integrationSettings"))}>
                  Integration settings
                </div>
              </a>
            </LinkContainer>
            <LinkContainer to={createNavigationUrl("apiSettings")} onClick={handleOutsideClick}>
              <a>
                <div className={generateLinkClassName(createNavigationUrl("apiSettings"))}>API key</div>
              </a>
            </LinkContainer>
            <div className="ml-2xl border-b-2 border-light" style={{ height: 14, width: 186 }} />
          </div>
        </Authorization>
        <div className="flex flex-col py-md mb-6xl">
          <Authorization roles={["testManager", "customerUser"]}>
            <>
              <LinkContainer to={createNavigationUrl("profileSettings")} onClick={handleOutsideClick}>
                <a>
                  <div
                    className={generateLinkClassName(createNavigationUrl("profileSettings"))}
                    onClick={handleOutsideClick}
                  >
                    Profile
                  </div>
                </a>
              </LinkContainer>
              <LinkContainer to={createNavigationUrl("organizationUsers")} onClick={handleOutsideClick}>
                <a>
                  <div
                    className={generateLinkClassName(createNavigationUrl("organizationUsers"))}
                    onClick={handleOutsideClick}
                  >
                    Team
                  </div>
                </a>
              </LinkContainer>
              {shouldShowCreditModelMenuOption && (
                <LinkContainer to={createNavigationUrl("organizationUserCreditUsage")} onClick={handleOutsideClick}>
                  <a>
                    <div
                      className={generateLinkClassName(createNavigationUrl("organizationUserCreditUsage"))}
                      onClick={handleOutsideClick}
                    >
                      Credit Usage
                    </div>
                  </a>
                </LinkContainer>
              )}
              <a href={helpCenterUrl} target="_blank" onClick={handleOutsideClick}>
                <div className={inactiveLinkClassName}>Help Center</div>
              </a>
            </>
          </Authorization>
          <a
            onClick={event => {
              event.preventDefault();
              logout({
                returnTo: window.location.origin,
              });
            }}
          >
            <div className={inactiveLinkClassName}>Logout</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CollapsedMenu;
