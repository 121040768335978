import classNames from "classnames";
import React from "react";

type OrderNumberProps = {
  children: React.ReactNode;
  background?: string;
  fontColor?: string;
};

function OrderNumber({ children, background = "bg-light", fontColor = "text-body" }: OrderNumberProps) {
  return (
    <span
      style={{ width: "1.75rem", height: "1.75rem" }}
      className={classNames("inline-flex items-center justify-center rounded-full", background, fontColor)}
      data-testid="ordinal-number"
    >
      {children}
    </span>
  );
}

export default OrderNumber;
