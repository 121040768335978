import React from "react";

import { FlexibleTestingIssueTag } from "types/graphql";
import SelectableTagOption from "./selectable_tag_option";

type TagItemProps = {
  tag: FlexibleTestingIssueTag;
  isActive: boolean;
  activeTags: FlexibleTestingIssueTag[];
  handleTagsUpdate: (tags: Array<FlexibleTestingIssueTag>) => Promise<void> | void;
  onTagEdit: (tag: FlexibleTestingIssueTag) => void;
  isReadOnly: boolean;
  tagCount?: number;
};

const TagItem = ({ tag, isActive, handleTagsUpdate, onTagEdit, activeTags, isReadOnly, tagCount }: TagItemProps) => (
  <SelectableTagOption
    onEdit={onTagEdit}
    tag={tag}
    handleTagsUpdate={handleTagsUpdate}
    active={isActive}
    activeTags={activeTags}
    isReadOnly={isReadOnly}
    tagCount={tagCount}
  />
);

export default TagItem;
