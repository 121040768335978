import React from "react";
import { TextArea } from "components/flexible_testing/shared/form";

const ScopeFields = () => (
  <div>
    <div>
      <TextArea
        name="inScope"
        placeholder="e.g., Focus on account creation, posting photos and videos, commenting on posts, and searching posts"
        label="What is in scope for this test?"
        useHopperInput={true}
        required
      />
    </div>
    <div>
      <TextArea
        name="outOfScope"
        placeholder="e.g., Do not test the Profile section"
        label="What is out of scope for this test?"
        useHopperInput={true}
        required
      />
    </div>
  </div>
);

export default ScopeFields;
