import React from "react";
import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";
import { UseCaseCard } from "./use_case_card";
import designIcon from "./design_icon.svg";

const USE_CASE_REASONS = [
  "Identify usability issues",
  "Validate the look and feel",
  "Localize to a specific country or region",
  "Conduct a survey",
];

type DesignCardProps = {
  onCardClick: (sdlcStageCode: SoftwareDevelopmentLifeCycleStageName) => void;
};

export function DesignCard({ onCardClick }: DesignCardProps): JSX.Element {
  return (
    <UseCaseCard
      icon={designIcon}
      title="Design"
      stageCode={"design" as SoftwareDevelopmentLifeCycleStageName}
      description="You are designing your next product or feature"
      useCaseReasons={USE_CASE_REASONS}
      onButtonClick={onCardClick}
    />
  );
}
