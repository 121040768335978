import React from "react";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { Link } from "react-router-dom";
import { websiteContentPaths } from "components/configuration";
import { FlexibleTestingTestCase } from "types/graphql";

type TestCaseProps = {
  testCases: FlexibleTestingTestCase[];
  testId: string;
};

const RelatedTestCases = ({ testCases, testId }: TestCaseProps): JSX.Element => (
  <div className="px-0">
    <DetailsField label="Related Test Cases" testId="related-test-cases" paddingClass="px-0 mb-lg">
      {testCases.map(testCase => (
        <Link
          key={testCase.id}
          to={websiteContentPaths.flexcaseTestCase.replace(":id", testCase.id).replace(":testId", testId)}
          className="font-bold text-link focus:outline-none cursor-pointer"
          data-testid="related-test-case-link"
        >
          {testCase.name}
        </Link>
      ))}
    </DetailsField>
  </div>
);

export default RelatedTestCases;
