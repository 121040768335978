import selectedIcon from "assets/images/participant_status_icons/selected.svg";
import invitedIcon from "assets/images/participant_status_icons/invited.svg";
import joinedIcon from "assets/images/participant_status_icons/joined.svg";
import inProgressIcon from "assets/images/participant_status_icons/in_progress.svg";
import testingCompleteIcon from "assets/images/participant_status_icons/testing_complete.svg";
import underReviewIcon from "assets/images/participant_status_icons/under_review.svg";
import resultsPublishedIcon from "assets/images/participant_status_icons/results_published.svg";
import droppedOutIcon from "assets/images/participant_status_icons/dropped_out.svg";
import publishingFailedIcon from "assets/images/participant_status_icons/publishing_failed.svg";

const config = {
  selected: { priority: 0, position: 8, label: "Selected", icon: selectedIcon },
  invited: { priority: 1, position: 7, label: "Invited", icon: invitedIcon },
  joined: { priority: 2, position: 6, label: "Joined", icon: joinedIcon },
  in_progress: { priority: 3, position: 5, label: "In progress", icon: inProgressIcon },
  testing_complete: { priority: 4, position: 4, label: "Testing complete", icon: testingCompleteIcon },
  under_review: { priority: 4, position: 4, label: "Under review", icon: underReviewIcon },
  results_approved: { priority: 5, position: 3, label: "Approved", icon: testingCompleteIcon },
  publishing_results: { priority: 6, position: 2, label: "Publishing..", icon: testingCompleteIcon },
  results_published: { priority: 7, position: 0, label: "Published", icon: resultsPublishedIcon },
  dropped_out: { priority: 8, position: 10, label: "Dropped out", icon: droppedOutIcon },
  removed: { priority: 9, position: 9, label: "Removed", icon: droppedOutIcon },
  declined: { priority: 10, position: 11, label: "Declined", icon: droppedOutIcon },
  expired: { priority: 11, position: 12, label: "Expired", icon: droppedOutIcon },
  publishing_failed: { priority: 12, position: 1, label: "Publishing Failed", icon: publishingFailedIcon },
  "": { priority: 0, position: 0, label: "Unknown", icon: undefined },
  backup_removed: { priority: 0, position: 0, label: "Unknown", icon: undefined },
  backup: { priority: 0, position: 0, label: "Unknown", icon: undefined },
} as const;

export default config;
