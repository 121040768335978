import React, { useEffect } from "react";
import Truncate from "react-truncate";
import { useHistory } from "react-router-dom";

import TesterResultsBreakdown, { BreakdownStatuses } from "./tester_results_breakdown";
import { StatusIndicator, statuses } from "components/flexible_testing/shared/status_indicator";
import { StatusLabel } from "@hopper/status_label";
import { websiteContentPaths } from "components/configuration";
import { some } from "lodash";
import { TestCase } from "../test_cases_list";

export type TestCaseRowProps = {
  testId: string;
  testCase: TestCase;
};

const SCROLL_OFFSET = 95;

function TestCaseRow({ testId, testCase }: TestCaseRowProps): JSX.Element {
  const rowRef = React.useRef<HTMLInputElement>(null);
  const styles = testCase.isNew ? { animationName: "highlight", animationDuration: "4s" } : {};
  const history = useHistory();
  const testCaseStatus = (testCase.status as typeof statuses[number]) || "not_tested";
  const testCaseResultStatuses =
    testCase?.completedCaseResults?.map(
      result => (result.isRejected ? "rejected" : result.status || "") as BreakdownStatuses,
    ) || [];

  useEffect(() => {
    if (!testCase.isSelected) return;

    if (rowRef.current) {
      window.scrollTo({ top: (rowRef.current.offsetTop || 0) - SCROLL_OFFSET });
    }
  }, [rowRef.current]);

  const openDetails = () => {
    location.hash = testCase.id;
    const detailsLink = websiteContentPaths.flexcaseTestCase.replace(":testId", testId).replace(":id", testCase.id);

    history.push(detailsLink);
  };

  const anyUnreadResults = (testCase: TestCase): boolean => some(testCase.completedCaseResults, { wasRead: false });

  return (
    <div
      style={styles}
      data-testid="test-case-row"
      className="grid grid-cols-12 py-xl cursor-pointer break-words"
      onClick={openDetails}
      ref={rowRef}
    >
      <div className="flex col-span-11 pr-6xl">
        <div className="flex items-center text-base" style={{ width: "5rem" }}>
          {testCase.code}
        </div>
        <div
          className="flex items-center flex-grow text-base text-darker leading-xl mr-auto"
          data-testid="test-case-name"
        >
          <Truncate width={650} ellipsis="..." lines={1}>
            {testCase.name}
          </Truncate>
        </div>
        <div className="flex pr-6xl">
          {anyUnreadResults(testCase) && (
            <div>
              <StatusLabel variant="neutral">Unread Result(s)</StatusLabel>
            </div>
          )}
        </div>
        <div style={{ width: "8rem" }} className="flex justify-end mx-3xl">
          <TesterResultsBreakdown
            overallStatus={testCase.originalStatus as BreakdownStatuses}
            testCaseResultStatuses={testCaseResultStatuses}
          />
        </div>
      </div>

      <div className="col-span-1">
        <StatusLabel variant="neutral">
          <StatusIndicator status={testCaseStatus} />
        </StatusLabel>
      </div>
    </div>
  );
}

export default TestCaseRow;
