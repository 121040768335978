import React from "react";

import { FlexibleTestingTest, OrganizationUser } from "types/graphql";
import { Table, Body, BodyCell, Row } from "components/flexible_testing/shared/table";
import { AssignCommunityTestManagerButton } from "./assign_community_test_manager_button";

type AvailableCommunityTestManagersTableProps = {
  availableCommunityTestManagers: OrganizationUser[];
  flexibleTestingTestId: FlexibleTestingTest["id"];
};

export const AvailableCommunityTestManagersTable = ({
  availableCommunityTestManagers,
  flexibleTestingTestId,
}: AvailableCommunityTestManagersTableProps) => (
  <Table className="table-fixed w-50" noBorder data-testid="available-community-test-managers-table">
    <Body>
      {availableCommunityTestManagers.map(communityTestManager => (
        <Row key={communityTestManager.id} className="min-w-full break-words" noBorder>
          <BodyCell className="p-0" style={{ width: "80%" }}>
            <div className="font-bold">{communityTestManager.fullName}</div>
          </BodyCell>
          <BodyCell className="p-0" style={{ width: "20%" }}>
            <AssignCommunityTestManagerButton
              communityTestManager={communityTestManager}
              flexibleTestingTestId={flexibleTestingTestId}
            />
          </BodyCell>
        </Row>
      ))}
    </Body>
  </Table>
);
