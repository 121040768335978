import React from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";

import { SupervisedTestRequestPage } from "./test_request_page";
import {
  SupervisedTestPreviewPage,
  SupervisedTestPreviewPageRequest,
} from "./preview_page/supervised_test_preview_page";

type CloneTestRequest = {
  testId: string;
};

export const SupervisedTestRequestPageSection = ({ match: { path } }: RouteComponentProps) => (
  <Switch>
    <Route
      exact
      path={path + "/preview"}
      render={routeProps => (
        <SupervisedTestPreviewPage testRequest={routeProps.location.state as SupervisedTestPreviewPageRequest} />
      )}
    />
    <Route
      path={path}
      render={routeProps => {
        const state = routeProps.location.state as CloneTestRequest;

        return (
          <SupervisedTestRequestPage
            testIdForCloning={state?.testId}
            previewTestRequest={routeProps.location.state as SupervisedTestPreviewPageRequest}
          />
        );
      }}
    />
  </Switch>
);
