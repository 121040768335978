import { gql, useQuery } from "@apollo/client";
import { AdvancedTestRequest } from "types/graphql";
import { SupervisedTestPreviewPageRequest } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";

import { URL_REGEX } from "lib/regexes";

export const GET_TEST = gql`
  query getPlatformTest($testId: ID!, $requestedTestId: ID!, $fetchRequestedTest: Boolean!) {
    platformTest(id: $testId) {
      ... on FlexibleTestingTest {
        advancedTestRequest {
          id
          name
          accessToApplication
          testObjective
          rawTestCases
          requestedTest(id: $requestedTestId) @include(if: $fetchRequestedTest) {
            name
            applicationType
            accessToApplicationUrl
            testCases
          }
        }
      }
    }
  }
`;

const urlRegex = new RegExp(`^${URL_REGEX.source}$`);

const DEFAULT_PAYOUT_BASE_AMOUNT = 0.5;

const generateInitialValues = (
  testRequest: SupervisedTestPreviewPageRequest | undefined,
  data: AdvancedTestRequest | undefined,
) => {
  const accessToApplicationUrl = data?.requestedTest?.accessToApplicationUrl || data?.accessToApplication;
  const isSingleURL = urlRegex.test(accessToApplicationUrl || "");
  const urls = {
    applicationUrl: isSingleURL ? accessToApplicationUrl : "",
    additionalUrlsAndResources: isSingleURL ? "" : accessToApplicationUrl,
  };

  let testCases = null;
  if (testRequest && Array.isArray(testRequest.testCases) && testRequest.testCases.length > 0) {
    testCases = testRequest.testCases;
  } else if (
    data?.requestedTest &&
    Array.isArray(data.requestedTest.testCases) &&
    data.requestedTest.testCases.length > 0
  ) {
    testCases = data.requestedTest.testCases;
  } else if (data && Array.isArray(data.rawTestCases) && data.rawTestCases.length > 0) {
    testCases = data.rawTestCases;
  }

  const requestedTestEnvironment = data?.requestedTest?.applicationType ? [data.requestedTest.applicationType] : null;

  return {
    applicationUrl: testRequest?.applicationUrl || urls.applicationUrl || "",
    automaticTesterManagementEnabled: testRequest?.automaticTesterManagementEnabled ?? true,
    automaticTesterManagementDisabledReason: testRequest?.automaticTesterManagementDisabledReason || "",
    name: testRequest?.name || data?.requestedTest?.name || data?.name || "",
    credentialsAndAccessInstructions: testRequest?.credentialsAndAccessInstructions || "",
    additionalUrlsAndResources: testRequest?.additionalUrlsAndResources || urls.additionalUrlsAndResources || "",
    description: testRequest?.description || data?.testObjective || "",
    testCasesFile: testRequest?.testCasesFile ? testRequest?.testCasesFile : null,
    testCases,
    testEnvironments: testRequest?.testEnvironments || requestedTestEnvironment || [],
    isTestScheduled: testRequest?.isTestScheduled === true,
    testerSpecificationDocument: testRequest?.testerSpecificationDocument || "",
    testerGroups: testRequest?.testerGroups || [],
    payoutBaseAmount: testRequest?.payoutBaseAmount || DEFAULT_PAYOUT_BASE_AMOUNT,
    requiredAttachmentForPass: testRequest?.requiredAttachmentForPass || false,
    applyCountryPayoutMultiplier: testRequest?.applyCountryPayoutMultiplier || false,
    advancedTestRequestId: data?.id,
    rawTestCases: data?.requestedTest?.testCases || data?.rawTestCases,
  };
};

const useSupervisedTestInitialValues = (
  testId: string,
  testRequest: SupervisedTestPreviewPageRequest | undefined,
  requestedTestId: string,
) => {
  const { data, loading } = useQuery(GET_TEST, {
    variables: { testId, requestedTestId, fetchRequestedTest: !!requestedTestId },
  });

  return {
    data: generateInitialValues(testRequest, data?.platformTest?.advancedTestRequest),
    loading,
  };
};

export default useSupervisedTestInitialValues;
