import { NetworkStatus, gql, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Test, TestConnection, TestStatus } from "types/graphql";

export const TESTS = gql`
  query getTests($status: TestStatus, $closedAtLt: ISO8601DateTime, $closedAtGt: ISO8601DateTime, $cursor: String) {
    platformTests(
      filters: { status: $status, closed_at_lt: $closedAtLt, closed_at_gt: $closedAtGt }
      first: 10
      after: $cursor
    ) {
      edges {
        node {
          ... on AdvancedTestRequest {
            id
            code
            name
            type
            issuesCount
            url
            testWindow {
              openedAt
              scheduleAt
              cancelledAt
              closedAt
              plannedEndAt
              status
            }
            cases {
              edges {
                node {
                  id
                  name
                  status
                  isTestCaseLive
                }
              }
            }
          }
          ... on FlexibleTestingTest {
            id
            code
            name
            type
            issuesCount
            url
            testWindow {
              openedAt
              scheduleAt
              cancelledAt
              closedAt
              plannedEndAt
              status
            }
            cases {
              edges {
                node {
                  id
                  name
                  status
                  isTestCaseLive
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export type TestsFilter = {
  status?: TestStatus;
  closedAtGt?: Date;
  closedAtLt?: Date;
};

export type TestsList = {
  platformTests: TestConnection;
};

function useTestList(variables: TestsFilter) {
  const { data, loading, networkStatus, fetchMore } = useQuery<TestsList, TestsFilter>(TESTS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const [cursor, setCursor] = useState("");
  const tests = data?.platformTests?.edges?.map(edge => edge?.node)?.filter((test): test is Test => !!test) || [];

  useEffect(() => {
    setCursor(data?.platformTests?.pageInfo.endCursor || "");
  }, [data]);

  return {
    tests,
    loading,
    isLoadingNextPage: networkStatus === NetworkStatus.fetchMore,
    hasNextPage: data?.platformTests?.pageInfo.hasNextPage,
    totalCount: data?.platformTests.totalCount,
    cursor: data?.platformTests?.pageInfo.endCursor,
    loadNextPage: async () =>
      fetchMore({
        variables: { cursor },
        updateQuery: (previousQueryResult, { fetchMoreResult, variables }): TestsList => ({
          platformTests: {
            ...fetchMoreResult?.platformTests,
            nodes: fetchMoreResult?.platformTests.nodes || [],
            edges: previousQueryResult?.platformTests?.edges?.concat(fetchMoreResult?.platformTests.edges || []),
            pageInfo: fetchMoreResult?.platformTests.pageInfo || previousQueryResult.platformTests.pageInfo,
          },
        }),
      }),
  };
}

function useLazyTestList(variables: TestsFilter) {
  const [loadTests, { called: testsLoadingCalled, loading, networkStatus, fetchMore, data }] = useLazyQuery<
    TestsList,
    TestsFilter
  >(TESTS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const [cursor, setCursor] = useState("");
  const tests = data?.platformTests?.edges?.map(edge => edge?.node)?.filter((test): test is Test => !!test) || [];

  useEffect(() => {
    setCursor(data?.platformTests?.pageInfo.endCursor || "");
  }, [data]);

  return {
    tests,
    loading,
    isLoadingNextPage: networkStatus === NetworkStatus.fetchMore,
    hasNextPage: data?.platformTests?.pageInfo.hasNextPage,
    totalCount: data?.platformTests.totalCount,
    cursor: data?.platformTests?.pageInfo.endCursor,
    loadTests,
    testsLoadingCalled,
    loadNextPage: async () =>
      fetchMore({
        variables: { cursor },
        updateQuery: (previousQueryResult, { fetchMoreResult, variables }): TestsList => ({
          platformTests: {
            ...fetchMoreResult?.platformTests,
            nodes: fetchMoreResult?.platformTests.nodes || [],
            edges: previousQueryResult?.platformTests?.edges?.concat(fetchMoreResult?.platformTests.edges || []),
            pageInfo: fetchMoreResult?.platformTests.pageInfo || previousQueryResult.platformTests.pageInfo,
          },
        }),
      }),
  };
}

export { useTestList, useLazyTestList };
