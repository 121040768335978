import { useEffect, useState, useRef } from "react";
import { gql } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";
import { GET_CURRENT_ORGANIZATION_USER } from "../shared/queries/organization_users_query";
import { featureFlags } from "app/feature_flags";

const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    featureFlags
  }
`;

function getCurrentUser(client) {
  let currentUser;

  try {
    currentUser = client.readQuery({
      query: GET_CURRENT_ORGANIZATION_USER,
    })?.currentOrganizationUser;
  } catch (e) {
    currentUser = null;
  }

  return currentUser;
}

function CurrentOrganizationProvider({ client, children }) {
  const featureFlagsSubscription = useRef(null);
  const [currentUser, setCurrentUser] = useState(() => getCurrentUser(client));

  useEffect(() => {
    if (!currentUser) {
      featureFlagsSubscription.current = client
        .watchQuery({
          query: GET_FEATURE_FLAGS,
          pollInterval: featureFlags.pollInterval,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({ data }) => {
            featureFlags.setEnabled(data.featureFlags);
          },
        });

      client.query({ query: GET_CURRENT_ORGANIZATION_USER }).then(({ data }) => {
        setCurrentUser(data.currentOrganizationUser);
      });
    }
  }, [currentUser]);

  return !!currentUser ? children : null;
}

export default withApollo(CurrentOrganizationProvider);
