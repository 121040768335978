import React, { useEffect, useState } from "react";
import { DecoratedText } from "@hopper/decorated_text";

import { FlexibleTestingIssue } from "types/graphql";
import { extractField } from "components/flexible_testing/result_details_page/issues/issues_list/issue_row/extract_field";
import { DetailsField } from "components/flexible_testing/shared/details_field";

type IssueDetailsProps = {
  issue: FlexibleTestingIssue;
  shouldTruncate: boolean;
};

const StepsToReproduce = ({ issue, shouldTruncate }: IssueDetailsProps): JSX.Element => {
  // We're using custom logic for truncating here (instead of react-truncate) because it allows us to
  // show how many lines have been hidden.
  // In the future, we may need to extract this logic so that we can use it in other parts of the application.
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const stepsToReproduce = extractField("reproduction_steps", issue.issueFields);
  const lines = stepsToReproduce.split("\n");
  const linesLength = lines.length;
  const defaultLinesLimit = shouldTruncate ? 5 : linesLength;
  const linesLimit = isExpanded ? linesLength : defaultLinesLimit;
  const hiddenLinesLength = linesLength - linesLimit;
  const ellipsis = isExpanded ? "Show less" : `Show ${hiddenLinesLength} more...`;
  const shouldDisplayEllipsis = isTruncated || isExpanded;

  useEffect(() => {
    setIsTruncated(linesLimit < linesLength);
  }, [linesLimit]);

  return (
    <DetailsField label="Steps to reproduce" testId="steps-to-reproduce" paddingClass="px-0">
      {lines.slice(0, linesLimit).map(line => (
        <DecoratedText key={line} text={line} />
      ))}
      {shouldDisplayEllipsis && (
        <div
          className="flex items-center text-primary cursor-pointer font-bold"
          onClick={() => setIsExpanded(!isExpanded)}
          data-testid="steps-to-reproduce-ellipsis"
        >
          {ellipsis}
        </div>
      )}
    </DetailsField>
  );
};

export default StepsToReproduce;
