import React from "react";

type TestingServiceCardHeaderProps = {
  testType: string;
  testDescription: string;
  icon?: string;
  isUnavailable?: boolean;
};

export function TestingServiceCardHeader({
  testType,
  testDescription,
  isUnavailable,
  icon,
}: TestingServiceCardHeaderProps): JSX.Element {
  return (
    <div className="text-darkest font-bold uppercase tracking-extra-wide pb-md">
      <div className="pb-md flex items-center">
        <span>{testType}</span>
        {isUnavailable && (
          <span
            className="pl-lg text-xs text-body uppercase tracking-extra-wide"
            data-testid={`${testType}-test-card-unavailable`}
          >
            Unavailable
          </span>
        )}
      </div>
      <div className="flex">
        {icon && <img src={icon} className="pr-sm" />}
        <div className={!icon ? "text-secondary" : ""}>{testDescription}</div>
      </div>
    </div>
  );
}
