import React from "react";
import classnames from "classnames";

type HeaderCellProps = {
  children?: React.ReactNode;
} & React.ThHTMLAttributes<HTMLTableHeaderCellElement>;

function HeaderCell({ children, className, ...otherProps }: HeaderCellProps) {
  return (
    <th
      className={classnames("align-top text-xs text-secondary tracking-wider uppercase pb-md", className)}
      {...otherProps}
    >
      {children}{" "}
    </th>
  );
}

export default HeaderCell;
