import React, { useState } from "react";
import { FormInlineError } from "@hopper/form_inline_error";

import { FlexibleTestingTest } from "types/graphql";

import { NewTestCycleButton } from "components/flexible_testing/shared/new_test_cycle_button";
import { Button } from "@hopper/button";
import LinkTestCycleForm from "./link_test_cycle_form";

export const LinkedTestCycle = ({ flexibleTestingTest }: { flexibleTestingTest: FlexibleTestingTest }) => {
  const linkedTestCycleUrl = flexibleTestingTest.linkedTestCycleUrl;
  const advancedTestRequestId = flexibleTestingTest.parentTest?.advancedTestRequest?._id;
  const testId = flexibleTestingTest.id;
  const [displayLinkTestCycleForm, setDisplayLinkTestCycleForm] = useState(false);

  return (
    <>
      {linkedTestCycleUrl && (
        <a className="text-link" data-testid="panel-test-cycle-link" href={linkedTestCycleUrl} target="_blank">
          {linkedTestCycleUrl}
        </a>
      )}
      {!linkedTestCycleUrl && advancedTestRequestId && (
        <>
          <div className="mt-xs">
            <FormInlineError>Test cycle is missing</FormInlineError>
          </div>
          <div className="mt-sm flex">
            <NewTestCycleButton
              testId={testId}
              advancedTestRequestId={advancedTestRequestId}
              size="sm"
              variant="secondary"
              appearance="ghost"
            />
            <span className="mx-md text-secondary">or</span>
            <Button
              type="submit"
              variant="secondary"
              appearance="ghost"
              size="sm"
              disabled={displayLinkTestCycleForm}
              onClick={() => setDisplayLinkTestCycleForm(true)}
            >
              Link an existing test cycle
            </Button>
          </div>
          {displayLinkTestCycleForm && <LinkTestCycleForm flexibleTestingTestId={flexibleTestingTest.id} />}
        </>
      )}
    </>
  );
};
