import React from "react";
import { StatusLabel } from "@hopper/status_label";
import rocketIcon from "assets/images/rocket.svg";

const NoIssuesFoundYet = () => (
  <div className="mt-xl" data-testid="no-issues-found-yet-message">
    <div className="flex font-bold max-w-md pb-md border-b border-light">
      <StatusLabel>LIVE</StatusLabel>
      <div className="ml-sm">Testing in progress</div>
    </div>
    <div className="mt-xl flex">
      <img src={rocketIcon} className="mr-xs" />
      <span>Issues will be shown here as soon as they are reported by our testers</span>
    </div>
  </div>
);

export default NoIssuesFoundYet;
