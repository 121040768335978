import React from "react";

import * as yup from "yup";
import { Button } from "@hopper/button";
import { FormikHelpers } from "formik";
import isString from "lodash/isString";

import useOrganizationUsersFormSubmit from "./use_organization_users_form_submit";
import { Form, GeneralError, TextArea } from "components/flexible_testing/shared/form";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

const OrganizationUsersForm = (): JSX.Element => {
  const { submitRequest } = useOrganizationUsersFormSubmit();
  const handleSubmit = (
    values: { emails: string },
    { resetForm }: FormikHelpers<{ emails: string }>,
  ): Promise<unknown> => {
    const emails = isString(values?.emails) ? values.emails.split(/[\s,]+/) : [];

    return submitRequest(emails as string[]).then(({ data }) => {
      if (data?.organizationUsersCreate?.successful) {
        notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
          type: "success",
          message: "Team members have been successfully invited.",
        });
        resetForm();
      }
    });
  };

  return (
    <Form<{ emails: string }>
      initialValues={{ emails: "" }}
      validations={yup.object().shape({
        emails: yup
          .array()
          .transform((value, originalValue) => (originalValue ? originalValue.split(/[\s,]+/) : []))
          .of(yup.string().email(({ value }) => `"${value}" is not a valid email`))
          .compact()
          .required("At least one email address required")
          .max(256, "Cannot add more than 256 email addresses in one request"),
      })}
      onSubmit={handleSubmit}
    >
      <div className="row">
        <div className="pl-lg">
          <GeneralError />
          <TextArea
            label="Team members"
            labelClass="text-sm"
            name="emails"
            data-testid="organization-users-textarea"
            placeholder="Add multiple team members by adding a comma after&#10;each email address"
            style={{ minHeight: "6rem", minWidth: "17rem" }}
          />
        </div>
      </div>
      <div className="row pl-lg mt-xl">
        <Button type="submit">Add</Button>
      </div>
    </Form>
  );
};

export default OrganizationUsersForm;
