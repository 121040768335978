import React from "react";
import { useLocation } from "react-router-dom";

import { websiteContentPaths } from "components/configuration";
import { BackToPreviousPageLink } from "../../shared/back_to_previous_page";
import { TestStatusLabel } from "../../shared/test_status_label";
import TestStatusBadge from "../test_status_badge";
import { OpenPublicChatButton } from "components/flexible_testing/shared/chat";
import { OverallProgress } from "./overall_progress";
import Authorization from "app/authorization/authorization";
import { Test } from "types/graphql";
import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import { Label } from "@hopper/label";
import ResultsHeaderPopup from "./results_header_popup";
import { Button } from "@hopper/button";
import { useFeatureFlag } from "app/feature_flags";
import { useCurrentUser } from "app/current_user/use_current_user";

type ResultsHeaderProps = {
  testId: string;
  platformTest?: Test;
  testWindow?: Test["testWindow"];
  isLoading: boolean;
  selectionRequirementsGroups: SelectionRequirementsGroup[];
  testersLoading: boolean;
};

function ResultsHeader({
  testId,
  platformTest,
  testWindow,
  isLoading,
  selectionRequirementsGroups,
  testersLoading,
}: ResultsHeaderProps) {
  const location = useLocation();
  const isSupervisedTest = platformTest?.type === "Supervised Test";
  const isCsvExportButtonVisible = !isLoading && isSupervisedTest;
  const isOverallProgressVisible = !isLoading && !testersLoading;
  const isCommunityTestManagersEnabled =
    platformTest?.__typename === "FlexibleTestingTest" && platformTest?.communityTestManagersEnabled;
  const isModeratorsButtonVisible = !isLoading && isSupervisedTest && isCommunityTestManagersEnabled;
  const isPublishAllButtonVisible = platformTest && isSupervisedTest && !testersLoading;
  const ctmsChatAccess = useFeatureFlag("ctms-chats-access", false);
  const currentUser = useCurrentUser();
  const ctmsAccess = ctmsChatAccess && isCommunityTestManagersEnabled;
  const isChatButtonVisible = !isLoading && isSupervisedTest && (currentUser?.testManager || ctmsAccess);

  return (
    <>
      <div>
        <BackToPreviousPageLink
          to={{
            pathname: websiteContentPaths.flexcaseResults,
            state: location.state,
          }}
        >
          Back to Results
        </BackToPreviousPageLink>
        <div className="flex justify-between flex-col sm:flex-row mt-2xl">
          <div data-testid="result-header" className="flex max-w-2xl shrink">
            <div className="max-w-lg flex items-start flex-wrap flex-column mb-lg">
              <h1 className="text-xl font-black text-body tracking-widest uppercase leading-125 auto-qa-heading mr-xl mb-sm">
                {platformTest?.name}
              </h1>
              <div className="mt-md flex items-center gap-sm" data-testid="test-status">
                {platformTest && <Label className="flex items-center">{platformTest.code}</Label>}
                {testWindow?.status === "open" && <TestStatusBadge plannedEndAt={testWindow.plannedEndAt} />}
                {platformTest && <TestStatusLabel testWindow={testWindow} />}
              </div>
            </div>
          </div>
          <div className="shrink-0">
            <div className="flex sm:justify-end items-end flex-row">
              <Authorization roles={ctmsAccess ? ["testManager", "communityTestManager"] : ["testManager"]}>
                <>
                  {isChatButtonVisible && platformTest && (
                    <OpenPublicChatButton
                      testName={platformTest.name}
                      testId={platformTest.id}
                      testCode={platformTest.code || ""}
                      ctmsAccess={ctmsAccess}
                    />
                  )}
                  {(isModeratorsButtonVisible || isCsvExportButtonVisible || isPublishAllButtonVisible) &&
                    platformTest && (
                      <ResultsHeaderPopup
                        testId={testId}
                        platformTest={platformTest}
                        selectionRequirementsGroups={selectionRequirementsGroups}
                        isModeratorsButtonVisible={isModeratorsButtonVisible}
                        isCsvExportButtonVisible={isCsvExportButtonVisible}
                        isPublishAllButtonVisible={isPublishAllButtonVisible}
                      >
                        <Button variant="secondary" className="m-xs">
                          <i className="fa fa-ellipsis-h" />
                        </Button>
                      </ResultsHeaderPopup>
                    )}
                </>
              </Authorization>
            </div>
            <Authorization roles={["testManager", "communityTestManager"]}>
              <>
                {isOverallProgressVisible && (
                  <div className="ml-xs mt-xs">
                    <OverallProgress selectionRequirementsGroups={selectionRequirementsGroups} />
                  </div>
                )}
              </>
            </Authorization>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResultsHeader;
