import React, { RefObject, useState } from "react";
import DisplayButton from "./display_button";
import { OptionsBox } from "./options_box";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { useIssueTags } from "./use_issue_tags";
import { FlexibleTestingIssueTag } from "types/graphql";

type TagSelectProps = {
  tags: FlexibleTestingIssueTag[];
  isActiveTagsListLimited: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
  handleTagsUpdate: (issueTags: Array<FlexibleTestingIssueTag>) => Promise<void>;
  isReadOnly: boolean;
};

const TagSelect = ({ tags, isActiveTagsListLimited, wrapperRef, handleTagsUpdate, isReadOnly }: TagSelectProps) => {
  const { issueTags } = useIssueTags();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOutsideClick = () => {
    setIsOpen(false);
  };
  const handleOpenToggle = () => {
    setIsOpen(!isOpen);
  };

  useOutsideHandler(wrapperRef, handleOutsideClick);

  return (
    <>
      <DisplayButton
        tags={tags}
        isOpen={isOpen}
        onClick={handleOpenToggle}
        isActiveTagsListLimited={isActiveTagsListLimited}
      />
      {isOpen && (
        <OptionsBox
          activeTags={tags}
          availableTags={issueTags}
          handleTagsUpdate={handleTagsUpdate}
          setIsOpen={setIsOpen}
          isReadOnly={isReadOnly}
        />
      )}
    </>
  );
};

export default TagSelect;
