import classnames from "classnames";
import React from "react";

type BodyCellProps = {
  children: React.ReactNode;
} & React.TdHTMLAttributes<HTMLTableCellElement>;

function BodyCell({ children, className, ...otherProps }: BodyCellProps) {
  return (
    <td className={classnames("py-md pr-md", className)} {...otherProps}>
      {children}
    </td>
  );
}

export default BodyCell;
