import React from "react";

import { FlexibleTestingIssue } from "types/graphql";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { extractField } from "components/flexible_testing/result_details_page/issues/issues_list/issue_row/extract_field";

type ReproducibilityProps = {
  issue: FlexibleTestingIssue;
};

const Reproducibility = ({ issue }: ReproducibilityProps): JSX.Element => {
  const { issueFields } = issue;
  const reproducibility =
    extractField("reproducibility", issueFields) || extractField("reproducibility_express", issueFields);
  const isReproducible = reproducibility !== "";

  return (
    <>
      {isReproducible && (
        <DetailsField label="Reproducibility" testId="reproducibility" paddingClass="px-0">
          {reproducibility}
        </DetailsField>
      )}
    </>
  );
};

export { Reproducibility };
