import { gql, useMutation } from "@apollo/client";
import { ProfessionalServiceRequestCreateInput, ProfessionalServiceRequestCreatePayload } from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

export const PROFESSIONAL_SERVICE_REQUES_CREATE_MUTATION = gql`
  mutation ProfessionalServiceRequestCreate($input: ProfessionalServiceRequestCreateInput!) {
    professionalServiceRequestCreate(input: $input) {
      successful
      errors {
        message
      }
    }
  }
`;

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with submitting your service request. Please try again later.",
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Your request has been successfully submitted.",
  });
};

type useProfessionalServiceRequestCreateResult = {
  professionalServiceRequestCreate: (professionalServiceId: string, content: string) => Promise<void>;
  isLoading: boolean;
};

export const useProfessionalServiceRequestCreate = (
  onSuccess: () => void,
): useProfessionalServiceRequestCreateResult => {
  const [professionalServiceRequestCreateMutation, { loading }] = useMutation<
    { professionalServiceRequestCreate: ProfessionalServiceRequestCreatePayload },
    { input: ProfessionalServiceRequestCreateInput }
  >(PROFESSIONAL_SERVICE_REQUES_CREATE_MUTATION, {
    onCompleted: ({ professionalServiceRequestCreate }) => {
      if (professionalServiceRequestCreate?.successful) {
        displaySuccess();
        onSuccess();
      } else {
        displayError();
      }
    },
    onError: displayError,
  });

  return {
    professionalServiceRequestCreate: async (professionalServiceId: string, content: string): Promise<void> => {
      await professionalServiceRequestCreateMutation({ variables: { input: { content, professionalServiceId } } });
    },
    isLoading: loading,
  };
};
