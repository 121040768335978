import React, { createContext, useContext, useState } from "react";

import { RequestedTest } from "components/flexible_testing/test_request_page/requested_test_page/requested_test_form";

type CustomerRequestContextType = {
  tests: RequestedTest[];
  addTest: (newTest: RequestedTest) => void;
  removeTest: (newTest: RequestedTest) => void;
  updateTest: (originalTest: RequestedTest, updatedTest: RequestedTest) => void;
  clearContext: () => void;
};

const CustomerRequestContext = createContext<CustomerRequestContextType>({
  tests: [],
  addTest: () => {},
  removeTest: () => {},
  updateTest: () => {},
  clearContext: () => {},
});

export const CustomerRequestContextProvider = ({
  tests: initialTests = [],
  children,
}: {
  tests?: RequestedTest[];
  children: React.ReactNode;
}) => {
  const [tests, setTests] = useState<RequestedTest[]>(initialTests);

  const addTest = (newTest: RequestedTest) => {
    setTests([...tests, newTest]);
  };

  const updateTest = (originalTest: RequestedTest, updatedTest: RequestedTest) => {
    const updatedTests = tests.map(test => (test === originalTest ? updatedTest : test));
    setTests(updatedTests);
  };

  const removeTest = (test: RequestedTest) => {
    setTests(tests.filter(t => t !== test));
  };

  const clearContext = () => {
    setTests([]);
  };

  return (
    <CustomerRequestContext.Provider value={{ tests, addTest, removeTest, updateTest, clearContext }}>
      {children}
    </CustomerRequestContext.Provider>
  );
};

export function useCustomerRequestContext() {
  const context = useContext(CustomerRequestContext);

  if (!context) {
    throw new Error("useCustomerRequestContext must be used within the CustomerRequestContextProvider");
  }

  return context;
}
