import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// @ts-ignore
import { Loader } from "@hopper/loading";
import queryString from "query-string";
import { websiteContentPaths } from "components/configuration";

export const SignUpError = () => {
  const { loginWithRedirect } = useAuth0();
  const params = queryString.parse(window.location.search);

  useEffect(() => {
    loginWithRedirect({
      signUp: true,
      errorCode: params.errorCode,
      rootUrl: window.location.origin,
      appState: { returnTo: websiteContentPaths.signUp },
    });
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};

export default SignUpError;
