import React from "react";

const NoIssuesRejectedYet = () => (
  <div className="mt-xl">
    <div className="font-bold max-w-md pb-md border-b border-light">No issues have been rejected yet</div>
    <div className="mt-xl">
      To reject an issue, open the issue from the <span className="font-bold">New issues</span> section and select{" "}
      <span className="font-bold">Reject</span>
    </div>
  </div>
);

export default NoIssuesRejectedYet;
