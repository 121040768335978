import {
  FlexibleTestingCaseResultFieldResponse,
  FlexibleTestingStepCaseField,
  FlexibleTestingStepCaseResultFieldResponse,
  Maybe,
} from "types/graphql";
import {
  TestCaseStepField,
  TestCaseResultFieldResponse,
} from "components/flexible_testing/test_case_result_page/use_test_case_result";

const isStepCaseResultFieldResponse = (
  response: FlexibleTestingCaseResultFieldResponse,
): response is FlexibleTestingStepCaseResultFieldResponse => "instruction" in response;

export function generateCaseInstructionsWithResponses(
  caseResultStepResponses: Maybe<FlexibleTestingCaseResultFieldResponse[]> | undefined,
  caseFields: TestCaseStepField[] | FlexibleTestingStepCaseField[],
) {
  const getCaseFields = (): TestCaseResultFieldResponse[] =>
    caseFields.map(field => ({
      _id: field._id,
      id: field.id,
      ordinalNumber: field.ordinalNumber,
      instruction: field?.instruction || "",
      expectedResult: "",
      status: "",
      actualResult: "",
    }));

  const getStepResponses = (): TestCaseResultFieldResponse[] =>
    caseResultStepResponses?.filter(isStepCaseResultFieldResponse)?.map(stepResponse => ({
      _id: stepResponse._id,
      id: stepResponse.id,
      ordinalNumber: stepResponse.ordinalNumber,
      instruction: stepResponse.instruction || "",
      expectedResult: stepResponse.expectedResult || "",
      status: stepResponse.status || "",
      actualResult: stepResponse.actualResult,
      originalActualResult: stepResponse.originalActualResult,
    })) || [];

  return caseResultStepResponses && caseResultStepResponses.length > 0 ? getStepResponses() : getCaseFields();
}
