import React, { useEffect, useMemo, useRef } from "react";
import { Panel } from "@hopper/panel";
import { Loader } from "@hopper/loading";
import { debounce } from "lodash";

import useFlexibleTestingTestCaseResult from "components/flexible_testing/tester_overview/use_flexible_testing_test_case_result";
import TestCaseResult from "components/flexible_testing/tester_overview/test_case_result";
import { useRegisterCaseResultModerationActivity } from "./use_register_case_result_moderation_activity";
import { useFinishCaseResultModerationActivity } from "./use_finish_case_result_moderation_activity";

const DEBOUNCE_ACTIVITY_TIMEOUT_IN_MILLISECONDS = 1000; // 1s
const REGISTER_ACTIVITY_INTERVAL_IN_MILLISECONDS = 60 * 1000; // every 1 minute

type TestCaseResultsPanelProps = {
  isOpen: boolean;
  onPanelClose: () => void;
  testCaseResultId: string;
};

export function TestCaseResultsPanel({ isOpen, onPanelClose, testCaseResultId }: TestCaseResultsPanelProps) {
  const { data, loading } = useFlexibleTestingTestCaseResult(testCaseResultId);
  const { registerCaseResultModerationActivity } = useRegisterCaseResultModerationActivity();
  const { finishCaseResultModerationActivity } = useFinishCaseResultModerationActivity();
  const testCaseResult = data?.flexibleTestingTestCaseResult;

  const lastRequestAtRef = useRef(Date.now());
  const lastActivityAtRef = useRef(Date.now());

  const registerActivity = () => {
    if (lastActivityAtRef.current >= lastRequestAtRef.current) {
      registerCaseResultModerationActivity(testCaseResultId);
    }

    lastRequestAtRef.current = Date.now();
  };

  const updateLastActivityTime = useMemo(
    () =>
      debounce(() => {
        lastActivityAtRef.current = Date.now();
      }, DEBOUNCE_ACTIVITY_TIMEOUT_IN_MILLISECONDS),
    [],
  );

  useEffect(() => {
    let registerActivityInterval: ReturnType<typeof setInterval>;

    if (isOpen) {
      registerActivity();
      window.addEventListener("mousemove", updateLastActivityTime);
      registerActivityInterval = setInterval(registerActivity, REGISTER_ACTIVITY_INTERVAL_IN_MILLISECONDS);
      lastActivityAtRef.current = Date.now();
    } else {
      window.removeEventListener("mousemove", updateLastActivityTime);
      updateLastActivityTime.cancel();
      finishCaseResultModerationActivity(testCaseResultId);
    }

    return () => {
      clearInterval(registerActivityInterval);
    };
  }, [isOpen]);

  return (
    <Panel
      isOpen={isOpen}
      hasOverlay={true}
      onOverlayClick={onPanelClose}
      onCloseButtonClick={onPanelClose}
      maxWidth="52rem"
    >
      <div className="h-screen overflow-y-auto pb-4xl">
        <div className="m-4xl mt-0">
          {loading ? <Loader /> : testCaseResult && <TestCaseResult caseResult={testCaseResult} />}
        </div>
      </div>
    </Panel>
  );
}
