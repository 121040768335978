import React from "react";
import { format, formatDistanceToNowStrict, parseISO } from "date-fns";
import classnames from "classnames";

import { Participant } from "components/flexible_testing/shared/chat";
import formatDistanceShortLocale from "tools/format_distance_short_locale";

export function TimeSinceLastResult({ participant }: { participant: Participant }) {
  const isWaitingForResults = ["joined", "in_progress"].includes(participant.status);
  const lastActivityAtIso = participant.lastCaseResultCompletedAt || participant.joinedAt;

  if (!isWaitingForResults || !lastActivityAtIso) return null;

  const lastActivityAt = parseISO(lastActivityAtIso);

  return (
    <span
      title={format(lastActivityAt, "yyyy-MM-dd HH:mm O")}
      className={classnames("text-xs", !participant.isActiveInTestCasesExecution && "font-bold")}
      data-testid="time-since-last-result"
    >
      {formatDistanceToNowStrict(lastActivityAt, { locale: formatDistanceShortLocale, roundingMethod: "floor" })}
      {!participant.lastCaseResultCompletedAt && <span> no&nbsp;result</span>}
    </span>
  );
}
