import { gql, useMutation } from "@apollo/client";

import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { AssignCommunityTestManagerInput, AssignCommunityTestManagerPayload } from "types/graphql";

export const ASSIGN_COMMUNITY_TEST_MANAGER = gql`
  mutation ($input: AssignCommunityTestManagerInput!) {
    assignCommunityTestManager(input: $input) {
      successful
      result {
        id
        availableCommunityTestManagers {
          edges {
            node {
              id
              fullName
            }
          }
        }
        assignedCommunityTestManagers {
          edges {
            node {
              id
              fullName
            }
          }
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "The moderator has been successfully assigned to the test.",
  });
};

const displayError = (errorMessage: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: errorMessage || "There was an issue with assigning the moderator to the test.",
  });
};

export const useAssignCommunityTestManager = () => {
  const [assignCommunityTestManager, { loading }] = useMutation<{
    assignCommunityTestManager: AssignCommunityTestManagerPayload;
  }>(ASSIGN_COMMUNITY_TEST_MANAGER, {
    onCompleted: ({ assignCommunityTestManager: { successful, errors } }) => {
      if (successful) {
        displaySuccess();
      } else {
        const errorMessage = errors.map(({ message }: { message: string }) => message).join(", ");

        displayError(errorMessage);
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = graphQLErrors.map(({ message }) => message).join(", ");

      displayError(errorMessage);
    },
  });

  return {
    assignCommunityTestManager: async ({
      organizationUserId,
      flexibleTestingTestId,
    }: AssignCommunityTestManagerInput): Promise<void> => {
      await assignCommunityTestManager({
        variables: {
          input: {
            organizationUserId,
            flexibleTestingTestId,
          },
        },
      });
    },
    loading,
  };
};
