import React, { useState } from "react";
import { Heading } from "@hopper/heading";
import { PageTemplate } from "../flexible_testing/shared/page_template";
import { ApiKeys } from "components/api_settings/api_keys";
import { TabBar } from "@hopper/tab_bar";
import { Tab } from "@hopper/tab";

const Tabs = {
  API_KEYS: "apiKeys",
};

function ApiSettingsPage(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(Tabs.API_KEYS);

  const header = (
    <div className="flex justify-between items-center">
      <Heading size={1}>API</Heading>
    </div>
  );

  return (
    <PageTemplate header={header}>
      <TabBar>
        <Tab active={true} onClick={() => setSelectedTab(Tabs.API_KEYS)}>
          API keys & CLI
        </Tab>
      </TabBar>
      <div className="mt-6xl">{selectedTab === Tabs.API_KEYS && <ApiKeys />}</div>
    </PageTemplate>
  );
}

export default ApiSettingsPage;
