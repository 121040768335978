import React from "react";
import { Modal } from "@hopper/legacy_modal";
import { Heading } from "@hopper/heading";

import { NewTestCycleButton } from "components/flexible_testing/shared/new_test_cycle_button";

type SupervisedTestLaunchedModalProps = {
  isOpen?: boolean;
  onCloseModalClicked?: () => void;
  advancedTestRequestId: string;
  testId: string;
  testResultsUrl: string;
};

function SupervisedTestLaunchedModal({
  isOpen = false,
  onCloseModalClicked = () => {},
  advancedTestRequestId,
  testId,
  testResultsUrl,
}: SupervisedTestLaunchedModalProps): JSX.Element {
  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onCloseModalClicked}
      position="center"
      size="lg"
      isOpen={isOpen}
      onOverlayClicked={onCloseModalClicked}
    >
      <div data-testid="success-modal" style={{ width: "28rem", height: "23rem" }}>
        <div className="flex flex-col items-center justify-center max-w-xs sm:max-w-full text-center">
          <div
            className="rounded-full flex items-center justify-center mb-2xl mt-6xl"
            style={{ backgroundColor: "rgba(64, 189, 166, 0.12)", height: "4.5rem", width: "4.5rem" }}
            data-testid="success-icon"
          >
            <i className="fa fa-check text-success opacity-100 text-3xl" aria-hidden="true" />
          </div>
          <div className="mb-xs">
            <Heading size={3}>We have received your test request</Heading>
          </div>
          <Heading size={4}>This test will begin once the related test cycle has been started.</Heading>
          <NewTestCycleButton advancedTestRequestId={advancedTestRequestId} testId={testId} />
          <div className="text-secondary text-sm mt-2xl mx-2xl">
            If a test cycle has previously been created, please enter the code on the{" "}
            <a href={testResultsUrl} className="text-link underline text-body">
              test details page
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SupervisedTestLaunchedModal;
