import { gql, useQuery } from "@apollo/client";
import { FlexibleTestingIssueTag } from "types/graphql";

type UseTagUsageCount = {
  isLoading: boolean;
  usageCount?: number | null;
};

export const ISSUE_TAG_USAGE_COUNT_QUERY = gql`
  query FlexibleTestingIssueTag($id: ID!) {
    flexibleTestingIssueTag(id: $id) {
      attachedToIssuesCount
    }
  }
`;

function useTagUsageCount(id: string): UseTagUsageCount {
  const { data, loading } = useQuery<{ flexibleTestingIssueTag?: FlexibleTestingIssueTag }>(
    ISSUE_TAG_USAGE_COUNT_QUERY,
    {
      variables: { id },
      fetchPolicy: "network-only",
    },
  );

  return {
    isLoading: loading,
    usageCount: data?.flexibleTestingIssueTag?.attachedToIssuesCount,
  };
}

export default useTagUsageCount;
