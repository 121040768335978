import React from "react";
import { FormLabel as HopperLabel } from "@hopper/form_label";

type Props = {
  label?: string;
  required?: boolean;
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  useHopperLabel?: boolean;
};

function FormLabel({ label, labelProps, required = false, useHopperLabel = false }: Props): JSX.Element {
  const { className, ...otherLabelProps } = labelProps;

  return (
    <>
      {useHopperLabel ? (
        <HopperLabel>{label}</HopperLabel>
      ) : (
        <label className={`${className} font-bold`} {...otherLabelProps}>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
    </>
  );
}

export default FormLabel;
