import { gql, useMutation } from "@apollo/client";

type MarkCaseResultAsRead = {
  markCaseResultAsRead: (caseResultId: string) => Promise<void>;
  wasSuccessfullyRequested: boolean;
};

export const MARK_CASE_RESULT_AS_READ_MUTATION = gql`
  mutation MarkCaseResultAsRead($input: FlexibleTestingMarkCaseResultAsReadInput!) {
    flexibleTestingMarkCaseResultAsRead(input: $input) {
      successful
      result {
        id
        wasRead
      }
      errors {
        code
        message
      }
    }
  }
`;

export const useMarkCaseResultAsRead = (): MarkCaseResultAsRead => {
  const [markCaseResultAsRead, { error }] = useMutation(MARK_CASE_RESULT_AS_READ_MUTATION, {
    onCompleted: () => {},
    onError: () => {},
  });

  return {
    markCaseResultAsRead: async (caseResultId): Promise<void> => {
      await markCaseResultAsRead({
        variables: {
          input: { caseResultId },
        },
      });
    },
    wasSuccessfullyRequested: !error,
  };
};
