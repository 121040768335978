import React from "react";
import classnames from "classnames";
import pluralize from "pluralize";
import { isEmpty } from "lodash";
import { CSVBoxButton } from "@csvbox/react";

import { TestCase } from "types/test_cases";
import Template from "components/flexible_testing/shared/test_cases/template";
import TestCasesList from "./test_cases_list";
import { useCurrentUser } from "app/current_user/use_current_user";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { Button } from "@hopper/button";

type CSVBoxUploaderProps = {
  uploadedTestCases: Array<TestCase> | null;
  onTestCasesUpload: (testCases: Array<TestCase> | null) => void;
  isAdditionalInformationColumnEnabled: boolean;
  downloadCsvFile?: () => Promise<void>;
  licenseKey: string;
};

type CSVBoxData = {
  row_success: number;
  rows: Array<CSVBoxRowData>;
};

type CSVBoxRowData = {
  section?: string;
  code?: string;
  title: string;
  prerequisites?: string;
  instruction: string;
  expectedResult: string;
  additionalInformationText?: string;
  additionalInformationAttachment?: string;
  tags?: string;
};

function populateMissingTitlesWithPreviousStepTitle(data: Array<CSVBoxRowData>): void {
  let previousTitle: null | string = null;

  data.forEach(row => {
    if (previousTitle && isEmpty(row.title)) {
      row.title = previousTitle;
    } else {
      previousTitle = row.title;
    }
  });
}

function populateMissingSectionsWithPreviousStepSection(data: Array<CSVBoxRowData>): void {
  let previousSection: null | string = null;

  data.forEach(row => {
    if (previousSection && isEmpty(row.section)) {
      row.section = previousSection;
    } else if (!!row.section) {
      previousSection = row.section;
    }
  });
}

const splitByNewLine = (value: string | undefined): string[] => value?.split("\n") || [];

function CSVBoxUploader({
  onTestCasesUpload,
  uploadedTestCases,
  isAdditionalInformationColumnEnabled,
  downloadCsvFile,
  licenseKey,
}: CSVBoxUploaderProps): JSX.Element {
  const currentUser = useCurrentUser();

  const handleImport = async (result: boolean, { rows }: CSVBoxData) => {
    if (result) {
      const testCases: Array<TestCase> = [];

      populateMissingTitlesWithPreviousStepTitle(rows);
      populateMissingSectionsWithPreviousStepSection(rows);

      rows.forEach((row: CSVBoxRowData) => {
        const {
          section,
          code,
          title,
          prerequisites,
          instruction,
          expectedResult,
          additionalInformationText,
          additionalInformationAttachment,
          tags,
        } = row;
        const lastIndex = testCases.length - 1;
        const lastTitle = lastIndex >= 0 ? testCases[lastIndex]?.name : null;
        const additionalInformationTextArray = splitByNewLine(additionalInformationText);
        const additionalInformationAttachmentArray = splitByNewLine(additionalInformationAttachment);
        const tagsArray =
          tags
            ?.split(",")
            .map(tag => tag.trim())
            .filter(tag => tag) || [];

        if (title !== lastTitle) {
          const newEntry = {
            section,
            code,
            name: title,
            prerequisites,
            fields: [
              {
                instruction,
                expectedResult,
              },
            ],
            ...(isAdditionalInformationColumnEnabled && {
              additionalInformation: {
                textRequirements: additionalInformationTextArray,
                attachmentRequirements: additionalInformationAttachmentArray,
              },
            }),
            tags: tagsArray,
          };

          testCases.push(newEntry);
        } else if (lastIndex >= 0) {
          const field = {
            instruction,
            expectedResult,
          };

          testCases[lastIndex]?.fields.push(field);
        }
      });

      onTestCasesUpload(testCases);
      displaySuccess();
    } else {
      displayError();
    }
  };

  const renderButton = (launch: () => void, isLoading: boolean) => (
    <button
      type="button"
      disabled={isLoading}
      onClick={launch}
      data-testid="upload-test-cases-button"
      className={classnames(
        "rounded-base inline-flex items-center relative h-4xl px-lg",
        "font-bold text-secondary border-secondary border border-solid",
        "focus:outline-none cursor-pointer mt-md",
      )}
    >
      {uploadedTestCases && uploadedTestCases.length > 0 ? "Replace test cases" : "Import Test Cases"}
    </button>
  );

  const userDetails = {
    user_id: currentUser?.publicId || "anonymous",
  };

  return (
    <>
      <div className="flex">
        <CSVBoxButton licenseKey={licenseKey} user={userDetails} onImport={handleImport} render={renderButton} />
        <div className="ml-xl">
          <Template additionalInformation={isAdditionalInformationColumnEnabled} />
        </div>
      </div>
      {uploadedTestCases && (
        <div className="mt-sm text-sm">
          <div className="flex justify-between">
            <div>
              <i className="fa fa-check text-success" />
              <span className="pl-xs" data-testid="test-cases-count-message">{`You have imported ${
                uploadedTestCases.length
              } ${pluralize("test case", uploadedTestCases.length)}`}</span>
            </div>
            {downloadCsvFile && (
              <span>
                <Button type="button" size="sm" variant="secondary" onClick={downloadCsvFile}>
                  Download
                </Button>
              </span>
            )}
          </div>
          <TestCasesList testCases={uploadedTestCases} onTestCasesUpload={onTestCasesUpload} />
        </div>
      )}
    </>
  );
}

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with importing test cases. Please reload the page and try again.",
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Test cases imported successfully",
  });
};

export default CSVBoxUploader;
