import React from "react";

type MenuItemBorderProps = {
  isVisible: boolean;
};

const MenuItemBorder = ({ isVisible }: MenuItemBorderProps): JSX.Element => (
  <div
    className={`block border-t-2 ${isVisible ? "border-dark" : "border-transparent"}`}
    style={{ width: "1.5em", marginTop: "2px" }}
  />
);

export default MenuItemBorder;
