import React from "react";
import { Select as HopperSelect, SelectProps as HopperSelectProps } from "@hopper/select";
import { FormField, FormFieldProps } from "@hopper/form_field";
import { useField } from "formik";

export type Options = SelectProps["options"];

type SelectProps = Pick<FormFieldProps, "label" | "isOptional"> &
  Pick<HopperSelectProps, "options" | "selectedValue"> & {
    name: string;
    onChange?: (value: string) => void;
  };

const Select = ({ label, name, onChange, isOptional = true, ...props }: SelectProps) => {
  const [_props, { error }, { setValue }] = useField({ name });

  return (
    <FormField label={label} errorMessage={error} isOptional={isOptional}>
      <HopperSelect
        name={name}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange?.(value);
        }}
        {...props}
      />
    </FormField>
  );
};

export default Select;
