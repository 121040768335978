import { gql, useMutation } from "@apollo/client";
import {
  DesiredOutcomeCode,
  FlexibleTestingLaunchTestFromAdaInput,
  FlexibleTestingLaunchTestFromAdaPayload,
  FlexibleTestingTestCaseParams,
  FlexibleTestingTestEnvironments,
  Scalars,
  SoftwareDevelopmentLifeCycleStageName,
} from "types/graphql";
import { format } from "date-fns";

export const TEST_REQUEST_MUTATION = gql`
  mutation FlexibleTestingLaunchTestFromAda($input: FlexibleTestingLaunchTestFromAdaInput!) {
    flexibleTestingLaunchTestFromAda(input: $input) {
      successful
      result {
        resultsPageUrl
      }
      errors {
        message
      }
    }
  }
`;

type FormSubmitHook = {
  launchTest: (
    name: string,
    description: string,
    credentialsAndAccessInstructions: string,
    additionalUrlsAndResources: string,
    testCases: Array<FlexibleTestingTestCaseParams>,
    applicationUrl: string,
    scheduleAt: Scalars["ISO8601DateTime"],
    environments: Array<FlexibleTestingTestEnvironments>,
    desiredOutcomes?: Array<DesiredOutcomeCode>,
    sdlcStage?: SoftwareDevelopmentLifeCycleStageName,
  ) => Promise<void>;
  testResultsUrl: string;
  isLoading: boolean;
  wasSuccessfullyRequested: boolean;
};

function useLaunchStandardTest(): FormSubmitHook {
  const [launchTest, { data, loading, called, error }] = useMutation<
    { flexibleTestingLaunchTestFromAda: FlexibleTestingLaunchTestFromAdaPayload },
    { input: FlexibleTestingLaunchTestFromAdaInput }
  >(TEST_REQUEST_MUTATION);

  return {
    launchTest: async (
      name,
      description,
      credentialsAndAccessInstructions,
      additionalUrlsAndResources,
      testCases,
      applicationUrl,
      scheduleAt,
      environments,
      desiredOutcomes,
      sdlcStage,
    ): Promise<void> => {
      scheduleAt = scheduleAt ? format(new Date(scheduleAt), "yyyy'-'LL'-'dd'T'HH':'mm':'ssxx") : null;

      const result = await launchTest({
        variables: {
          input: {
            name,
            description,
            credentialsAndAccessInstructions,
            additionalUrlsAndResources,
            testCases,
            applicationUrl,
            scheduleAt,
            environments,
            desiredOutcomes,
            sdlcStage,
          },
        },
      });
      const flexibleTestingLaunchResult = result.data?.flexibleTestingLaunchTestFromAda;

      if (!flexibleTestingLaunchResult?.successful) {
        throw new Error(flexibleTestingLaunchResult?.errors[0]?.message);
      }
    },
    testResultsUrl: data?.flexibleTestingLaunchTestFromAda?.result?.resultsPageUrl ?? "",
    isLoading: loading,
    wasSuccessfullyRequested: called && !error,
  };
}

export default useLaunchStandardTest;
