import React, { useEffect } from "react";
import format from "date-fns/format";
import { Scalars } from "types/graphql";
import { useField } from "formik";
import { PageSection } from "components/flexible_testing/shared/page_section";

function formatDateTime(dateTime: Scalars["ISO8601DateTime"]): Scalars["ISO8601DateTime"] {
  return format(dateTime, "yyyy'-'LL'-'dd'T'HH':'mm");
}

type ScheduleTestSectionProps = {
  initScheduleAt?: Scalars["ISO8601DateTime"];
  orderNumber?: number;
  title?: string;
  titleClass?: string;
};

function ScheduleTestSection({
  initScheduleAt,
  orderNumber,
  title = "When should this test begin?",
  titleClass,
}: ScheduleTestSectionProps): JSX.Element {
  const timeNow = new Date();
  const formattedCurrentTime = formatDateTime(timeNow);
  const initScheduleAtTime = initScheduleAt || formattedCurrentTime;
  const amountOfDaysInTwoWeeks = 14;
  const twoWeeksFromNow = timeNow.setDate(timeNow.getDate() + amountOfDaysInTwoWeeks);
  const formattedTwoWeeksFromNow = formatDateTime(twoWeeksFromNow);
  const [{ value: scheduleAt }, _scheduleAtMeta, { setValue: setScheduleAt }] =
    useField<Scalars["ISO8601DateTime"]>("scheduleAt");
  const [{ value: isSchedulingTest }, _isTestScheduledMeta, { setValue: setIsTestScheduled }] =
    useField<Scalars["ISO8601DateTime"]>("isTestScheduled");

  function onIsTestScheduledChange(schedule: boolean) {
    setIsTestScheduled(schedule);
  }

  useEffect(() => {
    if (isSchedulingTest) {
      setScheduleAt(initScheduleAtTime);
    } else if (isSchedulingTest === false && scheduleAt !== undefined) {
      setScheduleAt(null);
    }
  }, [isSchedulingTest]);

  const radioButtonStyle = { marginTop: 0, marginRight: "0.5rem", accentColor: "#373A40" };

  return (
    <>
      <PageSection title={title} titleClass={titleClass} tooltip={createTooltip()}>
        <div data-test-role="radio" className="text-xs">
          <label className="inline-flex justify-center items-center mr-3xl cursor-pointer">
            {/* style is used instead of className to override Bootstrap*/}
            <input
              style={radioButtonStyle}
              checked={!isSchedulingTest}
              type="radio"
              onChange={() => onIsTestScheduledChange(false)}
              name="is_scheduling_test"
              value="false"
              data-testid="launch-test-immediately"
              id="launch-test-immediately"
            />
            <span>Now</span>
          </label>
          <label className="ml-xs inline-flex justify-center items-center cursor-pointer">
            {/* style is used instead of className to override Bootstrap*/}
            <input
              style={radioButtonStyle}
              checked={isSchedulingTest}
              type="radio"
              onChange={() => onIsTestScheduledChange(true)}
              name="is_scheduling_test"
              value="true"
              data-testid="schedule-test-launch"
              id="schedule-test-launch"
            />
            Later
          </label>
        </div>
        {isSchedulingTest && (
          <div className="text-xs">
            <input
              style={{ width: "50%" }}
              type="datetime-local"
              name="schedule_at"
              data-testid="schedule-test-at"
              className="form-control mb-xs text-xs"
              min={formattedCurrentTime}
              max={formattedTwoWeeksFromNow}
              onChange={e => {
                setScheduleAt(e.target.value);
              }}
              defaultValue={initScheduleAtTime}
            />
          </div>
        )}
      </PageSection>
    </>
  );
}

function createTooltip() {
  return (
    <p className="mb-sm text-left">
      You can schedule your test run to begin at a specific date and time within the next 2 weeks
    </p>
  );
}

export default ScheduleTestSection;
