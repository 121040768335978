import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Heading } from "@hopper/heading";
import { AttachmentRemovalConfirmationModal } from "components/flexible_testing/tester_overview/attachment_removal_confirmation_modal";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { ButtonLink } from "components/flexible_testing/shared/button_link/index";
import { useTrackingOpenAttachment } from "./track_moderation_events/use_tracking_open_attachment";
import { useTrackingRemoveAttachment } from "./track_moderation_events/use_tracking_remove_attachment";
import { useTrackingRestoreAttachment } from "./track_moderation_events/use_tracking_restore_attachment";

export type Attachment = {
  id: string;
  trackingId: string;
  archived: boolean;
  type: string;
  name?: string;
  url: string;
  attachmentType: "CaseResultAttachment" | "CaseFieldAttachmentResponse";
};

type AttachmentsProps = {
  attachments: Array<Attachment>;
  canRemoveAttachment: boolean;
};

const ARCHIVE_CASE_RESULT_ATTACHMENT = gql`
  mutation ($input: FlexibleTestingArchiveCaseResultAttachmentInput!) {
    flexibleTestingArchiveCaseResultAttachment(input: $input) {
      successful
      result {
        id
        archived
        caseResult {
          id
          canRemoveAttachment
        }
      }
    }
  }
`;

const UNARCHIVE_CASE_RESULT_ATTACHMENT = gql`
  mutation ($input: FlexibleTestingUnarchiveCaseResultAttachmentInput!) {
    flexibleTestingUnarchiveCaseResultAttachment(input: $input) {
      successful
      result {
        id
        archived
        caseResult {
          id
          canRemoveAttachment
        }
      }
    }
  }
`;

const ARCHIVE_CASE_FIELD_ATTACHMENT_RESPONSE = gql`
  mutation ($input: FlexibleTestingArchiveCaseFieldAttachmentResponseInput!) {
    flexibleTestingArchiveCaseFieldAttachmentResponse(input: $input) {
      successful
      result {
        id
        archived
        caseResult {
          id
          canRemoveAttachment
        }
      }
    }
  }
`;

const UNARCHIVE_CASE_FIELD_ATTACHMENT_RESPONSE = gql`
  mutation ($input: FlexibleTestingUnarchiveCaseFieldAttachmentResponseInput!) {
    flexibleTestingUnarchiveCaseFieldAttachmentResponse(input: $input) {
      successful
      result {
        id
        archived
        caseResult {
          id
          canRemoveAttachment
        }
      }
    }
  }
`;

const handleRestoreError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue during attachment restoration.",
  });
};

const handleRemoveError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue during attachment deletion.",
  });
};

export function RemovableAttachments({ attachments, canRemoveAttachment }: AttachmentsProps) {
  const ATTACHMENT_IDENTIFIER_LENGTH = 5;

  const [attachmentSelectedForRemovalId, setAttachmentSelectedForRemovalId] = useState<string | null>(null);

  const [archiveCaseResultAttachment, { loading: isRemovingCaseResultAttachmentInProgress }] =
    useMutation(ARCHIVE_CASE_RESULT_ATTACHMENT);
  const [archiveCaseFieldAttachmentResponse] = useMutation(ARCHIVE_CASE_FIELD_ATTACHMENT_RESPONSE);
  const [unarchiveCaseFieldAttachmentResponse] = useMutation(UNARCHIVE_CASE_FIELD_ATTACHMENT_RESPONSE);

  const [unarchiveCaseResultAttachment, { loading: isRemovingCaseFieldAttachmentResponseInProgress }] = useMutation(
    UNARCHIVE_CASE_RESULT_ATTACHMENT,
  );

  const trackRemoveAttachment = useTrackingRemoveAttachment();
  const trackOpenAttachment = useTrackingOpenAttachment();
  const trackRestoreAttachment = useTrackingRestoreAttachment();

  const archivedAttachments = attachments.filter(attachment => attachment.archived);
  const availableAttachments = attachments.filter(attachment => !attachment.archived);

  const handleRemoveButtonClick = (id: string) => {
    setAttachmentSelectedForRemovalId(id);
  };

  const handleRestoreSuccess = (trackingId: string, attachmentType: string) => {
    notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
      type: "success",
      message: "Attachment has been successfully restored.",
    });

    trackRestoreAttachment(trackingId, attachmentType);
  };

  const handleRemoveSuccess = (trackingId: string, attachmentType: string) => {
    notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
      type: "success",
      message: "Attachment has been successfully deleted.",
    });

    trackingId && trackRemoveAttachment(trackingId, attachmentType);
  };

  const handleRestoreButtonClick = async (id: string, trackingId: string) => {
    const attachment = attachments.find(attachment => attachment.id === id);

    if (attachment?.attachmentType === "CaseResultAttachment") {
      await unarchiveCaseResultAttachment({
        variables: {
          input: { caseResultAttachmentId: id },
        },
        onCompleted: () => handleRestoreSuccess(trackingId, attachment.attachmentType),
        onError: handleRestoreError,
      });
    } else if (attachment?.attachmentType === "CaseFieldAttachmentResponse") {
      await unarchiveCaseFieldAttachmentResponse({
        variables: {
          input: { caseFieldAttachmentResponseId: id },
        },
        onCompleted: () => handleRestoreSuccess(trackingId, attachment.attachmentType),
        onError: handleRestoreError,
      });
    }
  };

  const handleRemoveAttachmentConfirmation = async (id: string) => {
    const attachment = attachments.find(attachment => attachment.id === id);

    if (attachment?.attachmentType === "CaseResultAttachment") {
      await archiveCaseResultAttachment({
        variables: {
          input: { caseResultAttachmentId: id },
        },
        onCompleted: () => handleRemoveSuccess(attachment.trackingId, attachment.attachmentType),
        onError: handleRemoveError,
      });
    } else if (attachment?.attachmentType === "CaseFieldAttachmentResponse") {
      await archiveCaseFieldAttachmentResponse({
        variables: {
          input: { caseFieldAttachmentResponseId: id },
        },
        onCompleted: () => handleRemoveSuccess(attachment.trackingId, attachment.attachmentType),
        onError: handleRemoveError,
      });
    }
  };

  return (
    <>
      <div className="flex font-bold">
        <Heading size={5}>Attachments</Heading>
      </div>

      {attachments.length > 0 ? (
        <ul className="flex flex-col">
          {availableAttachments.map(attachment => (
            <li
              className="flex items-center justify-between mb-2"
              key={attachment.id}
              data-testid="case-result-attachment"
            >
              <a
                href={attachment.url}
                className="text-sm text-darkest text-link underline"
                target="_blank"
                onClick={() => trackOpenAttachment(attachment.trackingId, attachment.attachmentType)}
              >
                {attachment.name
                  ? attachment.name
                  : `${attachment.type} attachment ${attachment?.url?.slice(-ATTACHMENT_IDENTIFIER_LENGTH)}`}
              </a>
              <ButtonLink
                variant="danger"
                size="sm"
                onClick={() => handleRemoveButtonClick(attachment.id)}
                disabled={!canRemoveAttachment}
              >
                Remove
              </ButtonLink>
            </li>
          ))}

          {archivedAttachments.map(archivedAttachment => (
            <li
              className="flex items-center justify-between mb-2"
              key={archivedAttachment.id}
              data-testid="case-result-attachment-removed"
            >
              <a
                href={archivedAttachment.url}
                className="text-sm font-bold text-secondary line-through"
                target="_blank"
                onClick={() => trackOpenAttachment(archivedAttachment.trackingId, archivedAttachment.attachmentType)}
              >
                {archivedAttachment.name
                  ? archivedAttachment.name
                  : `${archivedAttachment.type} attachment ${archivedAttachment?.url?.slice(
                      -ATTACHMENT_IDENTIFIER_LENGTH,
                    )}`}
              </a>
              <ButtonLink
                variant="secondary"
                size="sm"
                onClick={() => handleRestoreButtonClick(archivedAttachment.id, archivedAttachment.trackingId)}
                disabled={false}
              >
                Restore
              </ButtonLink>
            </li>
          ))}
        </ul>
      ) : (
        <span className="text-darkest">There are no attachments linked to this Test Case Result</span>
      )}
      {attachmentSelectedForRemovalId && (
        <AttachmentRemovalConfirmationModal
          onRemove={() => handleRemoveAttachmentConfirmation(attachmentSelectedForRemovalId)}
          onClose={() => setAttachmentSelectedForRemovalId(null)}
          isButtonLoading={isRemovingCaseResultAttachmentInProgress || isRemovingCaseFieldAttachmentResponseInProgress}
        />
      )}
    </>
  );
}
