import { gql } from "@apollo/client";

export const JIRA_CLIENT_AUTHENTICATION_FRAGMENT = gql`
  fragment jiraClientAuthentication on JiraClientAuthentication {
    site
    username
    contextPath
    useDefaultPriority
  }
`;

export const AZURE_DEVOPS_CLIENT_AUTHENTICATION_FRAGMENT = gql`
  fragment azureDevopsClientAuthentication on AzureDevopsClientAuthentication {
    site
    organization
    contextPath
    username
  }
`;

export const TESTRAIL_CLIENT_AUTHENTICATION_FRAGMENT = gql`
  fragment testrailClientAuthentication on TestrailClientAuthentication {
    site
    username
  }
`;

export const JIRA_CONNECT_CLIENT_AUTHENTICATION_FRAGMENT = gql`
  fragment jiraConnectClientAuthentication on JiraConnectClientAuthentication {
    siteUrl
    projectKey
    issuetypeName
    labels
    useDefaultExportSettings
    priorityMappings {
      critical
      high
      medium
      low
    }
  }
`;

export const GET_INTEGRATION_EXPORT_ATTRIBUTES = gql`
  query IntegrationExportSettings($id: ID!) {
    integration(id: $id) {
      id
      runsExportAttributes
    }
  }
`;

export const GET_INTEGRATION_PROJECT_ISSUE_TYPES = gql`
  query IntegrationExportIssueTypes($id: ID!, $projectKey: String!) {
    integration(id: $id) {
      id
      exportSettings {
        ... on JiraConnectExportSettings {
          issueTypes(projectKey: $projectKey) {
            name
            error
          }
        }
      }
    }
  }
`;

export const GET_AVAILABLE_INTEGRATIONS = gql`
  query AvailableIntegrations {
    availableIntegrations {
      edges {
        node {
          id
          code
          name
          description
          clientAuthenticationValid
          features
          clientAuthentication {
            ...jiraClientAuthentication
            ...jiraConnectClientAuthentication
            ...azureDevopsClientAuthentication
            ...testrailClientAuthentication
            ...jiraConnectClientAuthentication
          }
        }
      }
    }
  }

  ${JIRA_CLIENT_AUTHENTICATION_FRAGMENT}

  ${AZURE_DEVOPS_CLIENT_AUTHENTICATION_FRAGMENT}

  ${TESTRAIL_CLIENT_AUTHENTICATION_FRAGMENT}

  ${JIRA_CONNECT_CLIENT_AUTHENTICATION_FRAGMENT}
`;

export const INTEGRATION_UPDATE = gql`
  mutation IntegrationUpdate($input: IntegrationUpdateInput!) {
    integrationUpdate(input: $input) {
      successful
      result {
        id
        code
        name
        description
        clientAuthenticationValid
        features
        clientAuthentication {
          ...jiraClientAuthentication
          ...azureDevopsClientAuthentication
          ...testrailClientAuthentication
          ...jiraConnectClientAuthentication
        }
      }
    }
  }

  ${JIRA_CLIENT_AUTHENTICATION_FRAGMENT}

  ${AZURE_DEVOPS_CLIENT_AUTHENTICATION_FRAGMENT}

  ${TESTRAIL_CLIENT_AUTHENTICATION_FRAGMENT}

  ${JIRA_CONNECT_CLIENT_AUTHENTICATION_FRAGMENT}
`;

export const INTEGRATION_RESET = gql`
  mutation IntegrationReset($input: IntegrationResetInput!) {
    integrationReset(input: $input) {
      successful
      result {
        id
        code
        name
        description
        clientAuthenticationValid
        features
        clientAuthentication {
          ...jiraClientAuthentication
          ...azureDevopsClientAuthentication
          ...testrailClientAuthentication
          ...jiraConnectClientAuthentication
        }
      }
    }
  }

  ${JIRA_CLIENT_AUTHENTICATION_FRAGMENT}

  ${AZURE_DEVOPS_CLIENT_AUTHENTICATION_FRAGMENT}

  ${TESTRAIL_CLIENT_AUTHENTICATION_FRAGMENT}

  ${JIRA_CONNECT_CLIENT_AUTHENTICATION_FRAGMENT}
`;

export const AZURE_DEVOPS_CREDENTIALS_CREATE = gql`
  mutation AzureDevopsCredentialsCreate($input: AzureDevopsCredentialsCreateInput!) {
    azureDevopsCredentialsCreate(input: $input) {
      successful
      result
    }
  }
`;
