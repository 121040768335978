import { FetchResult, gql, useMutation } from "@apollo/client";
import { OrganizationUsersCreateInput, OrganizationUsersCreatePayload } from "types/graphql";

// @ts-ignore
import { GET_ORGANIZATION_USERS } from "components/shared/queries/organization_users_query";

export const ORGANIZATION_USERS_CREATE_MUTATION = gql`
  mutation OrganizationUsersCreate($input: OrganizationUsersCreateInput!) {
    organizationUsersCreate(input: $input) {
      successful
      errors {
        message
        path
      }
    }
  }
`;

type FormSubmitHook = {
  submitRequest: (
    emails: string[],
  ) => Promise<FetchResult<{ organizationUsersCreate: OrganizationUsersCreatePayload }>>;
};

function useOrganizationUsersFormSubmit(): FormSubmitHook {
  const [createOrganizationUsers] = useMutation<
    { organizationUsersCreate: OrganizationUsersCreatePayload },
    { input: OrganizationUsersCreateInput }
  >(ORGANIZATION_USERS_CREATE_MUTATION, { refetchQueries: () => [{ query: GET_ORGANIZATION_USERS }] });

  return {
    submitRequest: async (emails): Promise<FetchResult<{ organizationUsersCreate: OrganizationUsersCreatePayload }>> =>
      await createOrganizationUsers({ variables: { input: { emails } } }),
  };
}

export default useOrganizationUsersFormSubmit;
