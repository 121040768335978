import React, { useRef, useState } from "react";
import { PopupMenu } from "@hopper/popup_menu";
import { Button } from "@hopper/button";
import Popup from "components/shared/popup";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";

type InfoPopupProps = {
  label: string;
  content: string;
  children: React.ReactNode;
};

type InfoPopupContentProps = {
  label: string;
  content: string;
  onCancelButtonClick: () => void;
};

function InfoPopupContent({ label, content, onCancelButtonClick }: InfoPopupContentProps) {
  return (
    <PopupMenu>
      <div className="text-body px-lg py-sm">
        <div className="pb-md font-bold">{label}</div>
        <div className="text-sm pb-3xl" style={{ width: "18rem" }}>
          {content}
        </div>
        <div>
          <Button variant="secondary" className="mr-lg" onClick={onCancelButtonClick}>
            Close
          </Button>
        </div>
      </div>
    </PopupMenu>
  );
}

function InfoPopup({ label, content, children }: InfoPopupProps) {
  const wrapperRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const closeInfoPopup = () => setIsPopupOpen(false);

  useOutsideHandler(wrapperRef, closeInfoPopup);

  const infoPopupContent = InfoPopupContent({ content, label, onCancelButtonClick: closeInfoPopup });

  return (
    <div ref={wrapperRef}>
      <Popup placement="bottom-start" popupElement={infoPopupContent} isOpen={isPopupOpen} offset={[0, 10]}>
        <div onClick={() => setIsPopupOpen(!isPopupOpen)}>{children}</div>
      </Popup>
    </div>
  );
}

export default InfoPopup;
