import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { Heading } from "@hopper/heading";
import { Panel } from "@hopper/panel";
import { Label } from "@hopper/label";
import { Loader } from "@hopper/loading";
import ChatContainer from "./chat_container";
import { Tabs, TabBar, Tab, TabPanel } from "components/shared/tabs";
import { useChat } from "./use_chat";
import useChannel from "./use_channel";
import { useOpenChatFromQueryParams } from "./use_open_chat_from_query_params";
import { useCurrentUser } from "app/current_user/use_current_user";
import { ChatType } from "types/app";

type PublicChatPanelProps = {
  testId: string;
  isChatOpen: boolean;
  onChatOpen: () => void;
  onChatClose: () => void;
  testName: string;
  testCode: string;
  ctmsAccess: boolean;
  setUnreadMessagesCount: React.Dispatch<React.SetStateAction<number>>;
};

function PublicChatPanel({
  testId,
  onChatOpen,
  onChatClose,
  isChatOpen,
  testName,
  testCode,
  ctmsAccess,
  setUnreadMessagesCount,
}: PublicChatPanelProps) {
  const { isLoading: isClientLoading } = useChat();
  const [defaultActiveTabNumber, setDefaultActiveTabNumber] = useState<number>();
  const currentUser = useCurrentUser();
  const location = useLocation();
  const channelId = queryString.parse(location.search).channel_id;

  const useInitializeChannel = (chatType: ChatType, shouldInitialize = true) => {
    const { channel, isLoading, unreadMessagesCount } = useChannel({ targetId: testId, chatType, shouldInitialize });
    const { shouldOpenChannel, messageToJumpIntoExternalId } = useOpenChatFromQueryParams(channel?.id);

    return { channel, isLoading, unreadMessagesCount, shouldOpen: shouldOpenChannel, messageToJumpIntoExternalId };
  };
  const tmsTestersChannel = useInitializeChannel("test_public_tms_testers");
  const tmsCtmsChannel = useInitializeChannel("test_public_tms_ctms", ctmsAccess);

  const shouldOpenTmsTestersChannelFromQueryParams =
    channelId && tmsTestersChannel.shouldOpen && (ctmsAccess || currentUser?.testManager);
  const shouldOpenTmsCtmsChannelFromQueryParams = channelId && tmsCtmsChannel.shouldOpen && ctmsAccess;

  if (defaultActiveTabNumber === undefined) {
    if (shouldOpenTmsTestersChannelFromQueryParams) {
      setDefaultActiveTabNumber(0);
      onChatOpen();
    } else if (shouldOpenTmsCtmsChannelFromQueryParams) {
      setDefaultActiveTabNumber(1);
      onChatOpen();
    } else if (!channelId) {
      setDefaultActiveTabNumber(0);
    }
  }

  const isLoading =
    isClientLoading &&
    tmsTestersChannel.isLoading &&
    (tmsCtmsChannel.isLoading || !ctmsAccess) &&
    defaultActiveTabNumber === undefined;

  useEffect(() => {
    setUnreadMessagesCount(tmsTestersChannel.unreadMessagesCount + tmsCtmsChannel.unreadMessagesCount);
  }, [tmsTestersChannel.unreadMessagesCount, tmsCtmsChannel.unreadMessagesCount]);

  return (
    <Panel
      isOpen={isChatOpen}
      position="right"
      hasOverlay={true}
      onOverlayClick={onChatClose}
      onCloseButtonClick={onChatClose}
      maxWidth="52rem"
    >
      <div className="p-xl pt-0 flex flex-col ml-auto mr-0 w-screen sm:w-auto" style={{ height: "95dvh" }}>
        <Heading size={2}>Test chat</Heading>
        <Heading size={4}>This chat is visible to all participants of this test.</Heading>
        <div>{testName}</div>
        <div className="text-secondary">{testCode}</div>
        {isLoading && <Loader />}
        {!isLoading && isChatOpen && (
          <Tabs defaultActive={defaultActiveTabNumber}>
            <div className="shrink-0 pt-3xl">
              <TabBar>
                <Tab index={0}>
                  Public Chat
                  {!!tmsTestersChannel.unreadMessagesCount && (
                    <Label className="px-xs py-2xs ml-xs">{tmsTestersChannel.unreadMessagesCount}</Label>
                  )}
                </Tab>
                <Tab index={1} hidden={!ctmsAccess}>
                  Tester Work
                  {!!tmsCtmsChannel.unreadMessagesCount && (
                    <Label className="px-xs py-2xs ml-xs">{tmsCtmsChannel.unreadMessagesCount}</Label>
                  )}
                </Tab>
              </TabBar>
            </div>
            <div className="overflow-hidden grow">
              <TabPanel index={0}>
                <ChatContainer
                  channel={tmsTestersChannel.channel}
                  messageToJumpIntoExternalId={tmsTestersChannel.messageToJumpIntoExternalId}
                />
              </TabPanel>
              <TabPanel index={1}>
                <ChatContainer
                  channel={tmsCtmsChannel.channel}
                  messageToJumpIntoExternalId={tmsCtmsChannel.messageToJumpIntoExternalId}
                />
              </TabPanel>
            </div>
          </Tabs>
        )}
      </div>
    </Panel>
  );
}

export default PublicChatPanel;
