import { useEffect, RefObject } from "react";

// Based on https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
function useOutsideHandler(ref: RefObject<HTMLDivElement>, eventHandler: () => void): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      const target = event.target;
      const domNode = ref.current;

      if (domNode && target instanceof Node && !domNode.contains(target)) {
        eventHandler();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export { useOutsideHandler };
