import { Events } from "components/configuration";
import { useTrackingModeration } from "./use_tracking_moderation";

export function useTrackingEditOverallResult() {
  const eventName = Events.OVERALL_RESULT_EDITED;
  const { tracking, organizationUserEmail, trackingCaseResultId, trackingTestId } = useTrackingModeration();

  const trackEditOverallResult = (previousValue: string, currentValue: string) => {
    tracking.trackEvent({
      action: "track",
      name: eventName,
      eventProperties: {
        previous_value: previousValue,
        current_value: currentValue,
        test_id: trackingTestId,
        case_result_id: trackingCaseResultId,
        organization_user_email: organizationUserEmail,
      },
    });
  };

  return trackEditOverallResult;
}
