import React from "react";
import { Heading } from "@hopper/heading";

import { FlexibleTestingIssueField } from "types/graphql";
import { DetailsField } from "components/flexible_testing/shared/details_field";

type AdditionalDetailsProps = {
  details: Array<FlexibleTestingIssueField>;
};

const AdditionalDetails = ({ details }: AdditionalDetailsProps): JSX.Element => (
  <div className="mt-lg break-words">
    <div className="border-b border-light">
      <Heading size={5}>Additional Details</Heading>
    </div>
    <div className="mt-2xl grid grid-cols-1 lg:grid-cols-2 gap-y-lg gap-x-xl lg:gap-x-5xl">
      {details.map(detail => (
        <DetailsField
          label={detail.issueFieldDefinition.label}
          testId={detail.issueFieldDefinition.label}
          paddingClass="px-0"
          key={detail.id}
        >
          {detail.value}
        </DetailsField>
      ))}
    </div>
  </div>
);

export { AdditionalDetails };
