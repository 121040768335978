import React from "react";
import { useFormikContext } from "formik";

import { MultiSelect, Options, MultiSelectProps } from "components/shared/form/multiselect/multiselect";
import { Select } from "components/flexible_testing/shared/form";
import type { TargetingGroup } from "./targeting_row";
import { Maybe, Scalars } from "types/graphql";
import { RequestedTest } from "../requested_test_form";
import { buildSelectedItemsLabel } from "./build_selected_items_label";

export type Item = { id: Scalars["ID"]; name?: Maybe<string> | undefined };

type TargetingFieldProps = {
  name: keyof TargetingGroup;
  label: string;
  availableItems: Item[];
  value: TargetingGroup[keyof TargetingGroup];
  errorMessage: string | undefined;
  onChange: <T extends Item[]>(field: string, selectedIds: string[], values: T) => void;
  singleSelect?: boolean;
};

const TargetingMultiSelect = (props: MultiSelectProps) => (
  <MultiSelect {...props} style={{ minWidth: "100%" }} labelClassNames="text-body" />
);

const buildOptions = <T extends { id: Scalars["ID"]; name?: Maybe<string> | undefined }>(
  items: T[],
  selectedItems: T[],
): Options =>
  items.map(item => ({
    value: item.id,
    label: item.name || "",
    isSelected: selectedItems.some(selectedItem => selectedItem.id === item.id),
  }));

const TargetingField = ({
  name,
  label,
  value,
  availableItems,
  errorMessage,
  onChange,
  singleSelect = false,
}: TargetingFieldProps) => {
  const {
    values: { applicationType },
  } = useFormikContext<RequestedTest>();
  const options = buildOptions(availableItems, value);

  return (
    <>
      {singleSelect ? (
        <Select
          label={<div className="text-body leading-150">Languages</div>}
          name="languages"
          selectedValue={options.find(option => option.value === value[0]?.id)?.value}
          options={options}
          onChange={selectedValue => onChange(name, [selectedValue], availableItems)}
        />
      ) : (
        <TargetingMultiSelect
          key={name}
          label={label}
          errorMessage={errorMessage}
          options={options}
          onChange={selectedValues => onChange(name, selectedValues, availableItems)}
          placeholder="Any"
          selectedItemsLabel={buildSelectedItemsLabel(applicationType, name, value)}
        />
      )}
    </>
  );
};

export default TargetingField;
