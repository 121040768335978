import { RootQuery } from "types/graphql";

const toMinutesMsMultiplier = 1000 * 60;
const pollInterval = 10 * toMinutesMsMultiplier;

class FeatureFlags {
  pollInterval: number = pollInterval;
  enabledFeatureFlags: RootQuery["featureFlags"] = [];

  variation(name: string, defaultValue: boolean) {
    return this.enabledFeatureFlags.indexOf(name) > -1 || defaultValue;
  }

  setEnabled(featureFlags: RootQuery["featureFlags"]) {
    this.enabledFeatureFlags = featureFlags;
  }

  shutdown() {
    this.enabledFeatureFlags = [];
  }
}

export default new FeatureFlags();
