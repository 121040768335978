import { useMutation } from "@apollo/client";

import { FlexibleTestingTestBlocker } from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { RESOLVE_TEST_BLOCKER_MUTATION } from "./resolve_test_blocker_mutation";

export const useResolveTestBlocker = () => {
  const [resolveTestBlocker, { loading }] = useMutation(RESOLVE_TEST_BLOCKER_MUTATION, {
    onCompleted: ({ resolveTestBlocker: { successful, errors } }) => {
      if (!successful) {
        const errorMessage = errors.map(({ message }: { message: string }) => message).join(", ");

        displayError(errorMessage);
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = graphQLErrors.map(({ message }) => message).join(", ");

      displayError(errorMessage);
    },
  });

  return {
    resolveTestBlocker: async (testBlockerId: FlexibleTestingTestBlocker["id"]) => {
      await resolveTestBlocker({
        variables: { input: { testBlockerId } },
      });
    },
    isLoading: loading,
  };
};

function displayError(errorMessage: string) {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: errorMessage || "Test blocker couldn't be resolved.",
  });
}
