import React, { useEffect } from "react";
import { useField } from "formik";

import { PageSection } from "components/flexible_testing/shared/page_section";
import { TextInput } from "components/flexible_testing/shared/form";

type OptionalSectionProps = {
  contentFieldName: string;
  label: string;
  placeholder?: string;
  title: string;
  toggleFieldName: string;
  tooltip?: string;
  dataTestId: string;
};

function OptionalSection({
  contentFieldName,
  label,
  placeholder,
  title,
  toggleFieldName,
  tooltip,
  dataTestId,
}: OptionalSectionProps): JSX.Element {
  const [{ value: contentFieldValue }, _contentFieldValueMeta, { setValue: setContentValue }] =
    useField<string>(contentFieldName);
  const [{ value: isToggledField }, _isToggledFieldMeta, { setValue: setIsToggledField }] =
    useField<boolean>(toggleFieldName);
  const radioButtonStyle = { marginTop: 0, marginRight: "0.5rem", accentColor: "#373A40" };
  const tooltipMessage = <p className="mb-sm text-left">{tooltip}</p>;

  useEffect(() => {
    if (isToggledField === false && contentFieldValue !== "") {
      setContentValue("");
    }
  }, [isToggledField]);

  return (
    <PageSection title={title} tooltip={tooltipMessage} className="mb-2xl">
      <div data-test-role="radio" className="text-xs">
        <label className="inline-flex justify-center items-center mr-3xl cursor-pointer">
          {/* style is used instead of className to override Bootstrap*/}
          <input
            style={radioButtonStyle}
            checked={isToggledField}
            type="radio"
            onChange={() => setIsToggledField(true)}
            name={toggleFieldName}
            value="false"
            data-testid={`${dataTestId}-enable`}
          />
          <span>Yes</span>
        </label>
        <label className="ml-xs inline-flex justify-center items-center cursor-pointer">
          {/* style is used instead of className to override Bootstrap*/}
          <input
            style={radioButtonStyle}
            checked={!isToggledField}
            type="radio"
            onChange={() => setIsToggledField(false)}
            name={toggleFieldName}
            value="true"
            data-testid={`${dataTestId}-disable`}
          />
          <span>No</span>
        </label>
      </div>
      {isToggledField && (
        <div className="text-xs pt-lg">
          <TextInput
            label={label}
            placeholder={placeholder}
            name={contentFieldName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContentValue(e.target.value)}
            required={true}
            labelClass="font-normal"
          />
        </div>
      )}
    </PageSection>
  );
}

export default OptionalSection;
