import React from "react";
import { Card } from "@hopper/card";
import { Heading } from "@hopper/heading";

import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { SupervisedTestContent } from "./supervised_test_content";
import { SupervisedTestPreviewPageRequest } from "../preview_page/supervised_test_preview_page";
import { useTest } from "../../test_request_page/use_test";
import { Loader } from "@hopper/loading";

type SupervisedTestRequestPageProps = {
  testIdForCloning?: string;
  previewTestRequest?: SupervisedTestPreviewPageRequest;
};

export const SupervisedTestRequestPage = ({ testIdForCloning, previewTestRequest }: SupervisedTestRequestPageProps) => {
  const { getTest, testRequest, isLoading } = useTest();
  const isTestRequestLoading = testIdForCloning && isLoading;

  if (testIdForCloning && !isLoading && !testRequest) {
    getTest(testIdForCloning);
  }

  return (
    <section data-testid="supervised-test-request-page">
      {isTestRequestLoading ? (
        <Loader />
      ) : (
        <PageTemplate header={<Heading size={1}>Launch</Heading>}>
          <div data-testid="launch-supervised-test-page">
            <Card>
              <div className="p-3xl">
                <SupervisedTestContent
                  testRequest={(testRequest as SupervisedTestPreviewPageRequest | undefined) || previewTestRequest}
                />
              </div>
            </Card>
          </div>
        </PageTemplate>
      )}
    </section>
  );
};
