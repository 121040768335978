import React from "react";

const IntegrationLink = () => (
  <a href="/organization-settings/integrations">
    <span className="text-link text-md underline">create an integration</span>
  </a>
);

type IntegrationInfoProps = {
  vendor: string;
};

const IntegrationInfo = ({ vendor }: IntegrationInfoProps): JSX.Element => (
  <>
    You can <IntegrationLink /> with {vendor} from your Global App Testing account.
  </>
);

export default IntegrationInfo;
