import React, { useRef, useState } from "react";
import { getPlaybackRate, setPlaybackRate } from "./use_playback_rate";

type MainVideoAttachmentProps = {
  attachmentUrl: string;
};

function MainVideoAttachment({ attachmentUrl }: MainVideoAttachmentProps): JSX.Element {
  // Setting the type as HTMLVideoElement in this case is not possible because
  // this type does not support all full screen functions for different browsers (e.g. mozRequestFullscreen).
  // Adding a new HTMLVideoElement extension type fails due to the limited set of acceptable types for ref.
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const videoRef = useRef<any>(null);
  const [isFullscreen, setFullscreen] = useState(true);

  const handlePlaybackRate = () => {
    const { current } = videoRef;

    current.playbackRate = getPlaybackRate();
  };

  const handlePlaybackRateChange = () => {
    const { current } = videoRef;

    setPlaybackRate(current.playbackRate);
  };

  const handlePlaying = () => {
    const { current } = videoRef;

    current.playbackRate = getPlaybackRate();

    handleFullscreen();
  };

  const handleFullscreen = () => {
    const { current } = videoRef;

    if (!isFullscreen) {
      return;
    }

    if (current.requestFullscreen) {
      current.requestFullscreen();
    } else if (current.webkitRequestFullscreen) {
      current.webkitRequestFullscreen();
    } else if (current.mozRequestFullScreen) {
      current.mozRequestFullScreen();
    } else if (current.msRequestFullscreen) {
      current.msRequestFullscreen();
    } else if (current.exitFullscreen) {
      current.exitFullscreen();
    }

    setFullscreen(false);
  };

  return (
    <div className="border-light border rounded-base mb-xl">
      <video
        src={attachmentUrl}
        className="max-h-4xs sm:max-h-xs"
        controls
        muted
        data-testid="main-video-attachment"
        ref={videoRef}
        onClick={handleFullscreen}
        onPlaying={handlePlaying}
        onProgress={handlePlaybackRate}
        onRateChange={handlePlaybackRateChange}
      />
    </div>
  );
}

export default MainVideoAttachment;
