import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { OverviewPage } from "./overview_page";

function OverviewSection({ match: { path } }: RouteComponentProps): JSX.Element {
  return (
    <Switch>
      <Route path={path} component={OverviewPage} />
    </Switch>
  );
}

export default OverviewSection;
