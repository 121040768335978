import React from "react";
import { Spinner } from "@hopper/loading";

import CloneTestRunButton from "components/flexible_testing/shared/clone_test_run_button/clone_test_run_button";
import { websiteContentPaths } from "components/configuration";
import { CloneTestRunFromMultipleTestingServices } from "components/flexible_testing/test_request_page/clone_test_run_from_multiple_testing_services";
import {
  usePreviousAdvancedTestAvailable,
  usePreviousTestAvailable,
} from "components/flexible_testing/shared/use_previous_test_available";

export function LaunchWizardCloneContainer(): JSX.Element | null {
  const { isLoading: isLoadingPreviousStandardTests, hasNoPreviousTests: hasNoPreviousStandardTests } =
    usePreviousTestAvailable();
  const { isLoading: isLoadingPreviousAdvancedTests, hasNoPreviousTests: hasNoPreviousAdvancedTests } =
    usePreviousAdvancedTestAvailable();
  const isLoading = isLoadingPreviousStandardTests || isLoadingPreviousAdvancedTests;

  if (isLoading) {
    return (
      <span className="mr-5xl">
        <Spinner size={40} />
      </span>
    );
  }

  if (hasNoPreviousStandardTests && hasNoPreviousAdvancedTests) {
    return null;
  }

  const hasPreviousStandardTests = !hasNoPreviousStandardTests;
  const hasPreviousAdvancedTests = !hasNoPreviousAdvancedTests;
  const hasPreviousTestsFromMultipleTestingServices = hasPreviousStandardTests && hasPreviousAdvancedTests;

  if (hasPreviousTestsFromMultipleTestingServices) {
    return <CloneTestRunFromMultipleTestingServices />;
  } else if (hasPreviousStandardTests) {
    return <CloneButton testsPath={websiteContentPaths.flexcaseTestsForCloning} />;
  } else {
    return <CloneButton testsPath={websiteContentPaths.advancedTestsForCloning} />;
  }
}

function CloneButton({ testsPath }: { testsPath: string }): JSX.Element {
  return (
    <div className="flex items-center">
      <CloneTestRunButton testsPath={testsPath} isLoading={false} hasNoPreviousTests={false} />
    </div>
  );
}
