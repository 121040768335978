import { gql } from "@apollo/client";

export const GET_TAB_HEADERS_DATA = gql`
  query getPlatformTestTabHeaders($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        issues {
          totalCount
          edges {
            node {
              id
            }
          }
        }
        cases {
          totalCount
          edges {
            node {
              id
            }
          }
        }
        testerSessions {
          edges {
            node {
              id
              feedback
            }
          }
        }
      }
      ... on FlexibleTestingTest {
        id
        issues {
          totalCount
          edges {
            node {
              id
            }
          }
        }
        cases {
          totalCount
          edges {
            node {
              id
            }
          }
        }
        testerSessions {
          edges {
            node {
              id
              feedback
            }
          }
        }
      }
    }
  }
`;
