import { useCurrentUser } from "./use_current_user";
import React from "react";

function withCurrentUser(WrappedComponent) {
  return function ({ ...props }) {
    const currentUser = useCurrentUser();

    return <WrappedComponent {...props} currentUser={currentUser} />;
  };
}

export { withCurrentUser };
