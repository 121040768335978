import { gql, useQuery } from "@apollo/client";

import {
  CountryConnection,
  DeviceConnection,
  InternetBrowserConnection,
  LanguageConnection,
  MobileDeviceBrandConnection,
  MobileDeviceConnection,
  OperatingSystemConnection,
  OperatingSystemVersionConnection,
} from "types/graphql";

export const GET_ENVIRONMENTS = gql`
  query getEnvironments {
    operatingSystemVersions {
      edges {
        node {
          id
          fullName
          operatingSystemCode
        }
      }
    }
    deviceTypes {
      edges {
        node {
          id
          name
        }
      }
    }
    manufacturers {
      edges {
        node {
          id
          name
        }
      }
    }
    mobileDevices {
      edges {
        node {
          id
          name
        }
      }
    }
    operatingSystems {
      edges {
        node {
          id
          name
        }
      }
    }
    internetBrowsers(availableForPlatform: true) {
      edges {
        node {
          id
          name
        }
      }
    }
    countries {
      edges {
        node {
          id
          name
        }
      }
    }
    languages {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const useEnvironments = () => {
  const { data, loading: environmentsLoading } = useQuery<{
    operatingSystemVersions: OperatingSystemVersionConnection;
    deviceTypes: DeviceConnection;
    manufacturers: MobileDeviceBrandConnection;
    mobileDevices: MobileDeviceConnection;
    operatingSystems: OperatingSystemConnection;
    internetBrowsers: InternetBrowserConnection;
    countries: CountryConnection;
    languages: LanguageConnection;
  }>(GET_ENVIRONMENTS);

  const operatingSystemVersions = data?.operatingSystemVersions.edges.map(({ node }) => node) || [];
  const deviceTypes = data?.deviceTypes?.edges.map(({ node }) => node) || [];
  const manufacturers = data?.manufacturers?.edges.map(({ node }) => node) || [];
  const mobileDevices = data?.mobileDevices?.edges.map(({ node }) => node) || [];
  const operatingSystems = data?.operatingSystems?.edges.map(({ node }) => node) || [];
  const internetBrowsers = data?.internetBrowsers?.edges.map(({ node }) => node) || [];
  const countries = data?.countries?.edges.map(({ node }) => node) || [];
  const languages = data?.languages?.edges.map(({ node }) => node) || [];

  return {
    operatingSystemVersions,
    deviceTypes,
    manufacturers,
    mobileDevices,
    operatingSystems,
    internetBrowsers,
    countries,
    languages,
    environmentsLoading,
  };
};
