import React from "react";
import { Button } from "@hopper/button";
import { FlexibleTestingIssue } from "types/graphql";

import TagFilter from "./filter_by_tags/tag_filter";
import IssueFiltersToggle from "./issue_filters_toggle";

type IssueFiltersProps = {
  currentIssuesFilter: string;
  setFilter: (argument: string) => void;
  issues: Array<FlexibleTestingIssue>;
  newIssues: Array<FlexibleTestingIssue>;
  acceptedIssues: Array<FlexibleTestingIssue>;
  rejectedIssues: Array<FlexibleTestingIssue>;
  taggedIssues: Array<FlexibleTestingIssue>;
  updateSelectedTags?: (argument: string[]) => void;
  filterMode?: string;
};

const IssueFilters = ({
  currentIssuesFilter,
  setFilter,
  issues,
  newIssues,
  acceptedIssues,
  rejectedIssues,
  taggedIssues = [],
  updateSelectedTags,
  filterMode,
}: IssueFiltersProps) => (
  <div className="flex">
    <Button className="mr-md text-sm" size="sm" variant="secondary" data-testid="filter-by-tag" appearance="ghost">
      <TagFilter {...{ updateSelectedTags, issues, taggedIssues, filterMode }} />
    </Button>
    <IssueFiltersToggle
      {...{ setFilter, currentIssuesFilter }}
      newIssues={newIssues.length}
      acceptedIssues={acceptedIssues.length}
      rejectedIssues={rejectedIssues.length}
    />
  </div>
);

export default IssueFilters;
