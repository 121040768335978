import React from "react";
import { PageSection } from "components/flexible_testing/shared/page_section";
import ExistingApiKeysTable from "./existing_api_keys_table";

type ApiKeysProps = {
  onLoadingFinished?: () => void;
};

function ExistingApiKeys({ onLoadingFinished }: ApiKeysProps): JSX.Element {
  const title = (
    <div className="block mt-xl">
      Your existing API keys
      <div className="text-dark font-normal text-sm">Be sure to keep your API keys secure</div>
    </div>
  );

  return (
    <PageSection className="md:ml-4xl" title={title}>
      <div className="mt-lg">
        <ExistingApiKeysTable onLoadingFinished={onLoadingFinished} />
      </div>
    </PageSection>
  );
}

export default ExistingApiKeys;
