import React, { useRef } from "react";
import { PopupMenu } from "@hopper/popup_menu";
import Popup from "components/shared/popup";
import { Button } from "@hopper/button";

import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";
import { useTestCaseStatusAfterRejection } from "./use_test_case_status_after_rejection";

type TestCaseResponseRejectionPopupProps = {
  isOpen: boolean;
  onExtenalElementClick: () => void;
  onRejectionReasonOptionClick: (rejectionReason: string) => void;
  onButtonClick: () => void;
  children: React.ReactNode;
  rejectionReasons: Array<string>;
  caseResultId: string;
};

type TestCaseResponseRejectionPopupContentProps = {
  onRejectionReasonOptionClick: (rejectionReason: string) => void;
  rejectionReasons: Array<string>;
  caseResultId: string;
};

type statusNameType = { [key: string]: string };

const statusName: statusNameType = {
  pass: "Passed",
  fail: "Failed",
  blocked: "Blocked",
  rejected: "Rejected",
};

const TestCaseResponseRejectionPopupContent = ({
  onRejectionReasonOptionClick,
  rejectionReasons,
  caseResultId,
}: TestCaseResponseRejectionPopupContentProps) => {
  const { willTestCaseStatusChangeAfterRejection, caseStatusAfterRejection, isLoading } =
    useTestCaseStatusAfterRejection(caseResultId);

  return (
    <div data-testid="test-case-response-rejection-reasons-popup">
      <PopupMenu>
        <div className="text-secondary text-sm mx-2xl mb-sm">
          <div className="font-bold my-sm py-sm text-md border-b border-light">Select reason for rejection</div>
          {willTestCaseStatusChangeAfterRejection && (
            <div data-testid="new-case-status-notification">
              This will change the overall test
              <br />
              case result to <span className="font-bold ml-xs">{statusName[caseStatusAfterRejection]}</span>
            </div>
          )}
        </div>
        {!isLoading && (
          <div className="pb-sm" data-testid="test-case-response-rejection-reasons-list">
            {rejectionReasons.map(rejectionReason => (
              <div className="hover:bg-light px-sm">
                <Button
                  appearance="minimal"
                  variant="secondary"
                  onClick={() => onRejectionReasonOptionClick(rejectionReason)}
                  disabled={isLoading}
                >
                  <span className="font-normal">{rejectionReason}</span>
                </Button>
              </div>
            ))}
          </div>
        )}
      </PopupMenu>
    </div>
  );
};

const TestCaseResponseRejectionPopup = ({
  isOpen,
  onExtenalElementClick,
  onRejectionReasonOptionClick,
  onButtonClick,
  children,
  rejectionReasons,
  caseResultId,
}: TestCaseResponseRejectionPopupProps) => {
  const wrapperRef = useRef(null);

  useOutsideHandler(wrapperRef, onExtenalElementClick);

  const testCaseResponseRejectionPopupContent = TestCaseResponseRejectionPopupContent({
    onRejectionReasonOptionClick,
    rejectionReasons,
    caseResultId,
  });

  return (
    <div ref={wrapperRef}>
      <Popup popupElement={testCaseResponseRejectionPopupContent} isOpen={isOpen}>
        <div className="cursor-pointer" data-testid="test-case-result-reject-button" onClick={onButtonClick}>
          {children}
        </div>
      </Popup>
    </div>
  );
};

export default TestCaseResponseRejectionPopup;
