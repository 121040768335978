import React from "react";
import { Label } from "@hopper/label";

import { TestCaseResultFieldResponse } from "components/flexible_testing/test_case_result_page/use_test_case_result";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { InfoPopup } from "components/flexible_testing/shared/info_popup";
// @ts-ignore
import { Row } from "../shared/table";
import { StatusSection } from "./status_section";
import { EditButton } from "components/flexible_testing/shared/case_result/edit_button";
import { Events } from "components/configuration";

type InstructionResultDetailsProps = {
  fieldResponse: TestCaseResultFieldResponse;
  testCaseResultId?: string;
  isCaseResultEditingDisabled?: boolean;
};

function InstructionResultDetails({
  fieldResponse,
  testCaseResultId,
  isCaseResultEditingDisabled,
}: InstructionResultDetailsProps): JSX.Element {
  const instructionLabelText = (status: string): string =>
    ({
      fail: "Actual Result",
      blocked: "Selected reason for blocked step",
    }[status] || "Tester Comments");

  const originalActualResultPostfixLabel = fieldResponse.originalActualResult && (
    <InfoPopup label="Original text" content={fieldResponse.originalActualResult}>
      <div className="cursor-pointer" style={{ marginTop: "-6px" }}>
        <Label>Edited automatically</Label>
      </div>
    </InfoPopup>
  );

  return (
    <Row noBorder={true} key={`details-${fieldResponse.id}`} testId="instruction-result-details">
      <StatusSection className="ml-4xl">
        <>
          <div className="pb-md whitespace-pre-wrap">
            <DetailsField label="Expected Result" testId="expected-result">
              {fieldResponse.expectedResult}
            </DetailsField>
          </div>
          {fieldResponse.actualResult && (
            <div>
              <DetailsField
                label={instructionLabelText(fieldResponse.status)}
                testId="actual-result"
                postfixLabel={originalActualResultPostfixLabel}
              >
                {fieldResponse.actualResult}
              </DetailsField>

              <div className="lg:px-md">
                {fieldResponse.status === "fail" && testCaseResultId && (
                  <EditButton
                    fieldType="Step"
                    fieldName="actualResult"
                    fieldLabel="Actual result"
                    caseResultResponseId={fieldResponse.id}
                    initialValue={fieldResponse.actualResult}
                    testCaseResultId={testCaseResultId}
                    isEditButtonDisabled={isCaseResultEditingDisabled}
                    trackingCaseResultFieldResponseId={fieldResponse._id}
                    trackingEventName={Events.STEP_RESPONSE_RESULT_EDITED}
                  />
                )}
              </div>
            </div>
          )}
        </>
      </StatusSection>
    </Row>
  );
}

export default InstructionResultDetails;
