import { useCurrentUser } from "app/current_user/use_current_user";

function useOrganizationChosenNameSuggestion(): string {
  const currentUser = useCurrentUser();

  const isRandomGeneratedName = (name?: string | null): boolean => /^Trial [0-9]+$/.test(name || "");

  return isRandomGeneratedName(currentUser?.organization.name) ? "" : currentUser?.organization.name || "";
}

export default useOrganizationChosenNameSuggestion;
