import { gql, useQuery } from "@apollo/client";
import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";

export const TEST_SDLC_STAGE_QUERY = gql`
  query FlexibleSestingTestSdlcStage($stageCode: SoftwareDevelopmentLifeCycleStageName!) {
    flexibleTestingTestSdlcStage(stageCode: $stageCode) {
      name
      desiredOutcomes {
        code
        name
        description
        launchFormInstructions
        isAvailableOnStandard
      }
    }
  }
`;

export function useTestSdlcStage(sdlcStage: SoftwareDevelopmentLifeCycleStageName) {
  const { data, loading } = useQuery(TEST_SDLC_STAGE_QUERY, {
    variables: { stageCode: sdlcStage },
    fetchPolicy: "cache-and-network",
  });
  const desiredOutcomes = data?.flexibleTestingTestSdlcStage?.desiredOutcomes || [];

  return {
    desiredOutcomes,
    isLoading: loading,
  };
}
