import { Heading } from "@hopper/heading";
import { Panel } from "@hopper/panel";
import React from "react";
import ChatContainer from "./chat_container";
import { Loader } from "@hopper/loading";
import { Channel } from "stream-chat";

type BlockerChatPanelProps = {
  isChatOpen: boolean;
  chatTitle: string;
  chatSubtitle: string;
  userFullName: string;
  onChatClose: () => void;
  channel?: Channel;
  isLoading?: boolean;
  messageToJumpIntoExternalId?: string | null;
};

const BlockerChatPanel = ({
  isChatOpen,
  chatTitle,
  chatSubtitle,
  userFullName,
  onChatClose,
  channel,
  isLoading,
  messageToJumpIntoExternalId,
}: BlockerChatPanelProps) => (
  <Panel
    isOpen={isChatOpen}
    hasOverlay={true}
    onOverlayClick={onChatClose}
    position="right"
    onCloseButtonClick={onChatClose}
    maxWidth="52rem"
  >
    <div className="p-xl pt-0 flex flex-col ml-auto mr-0" style={{ height: "95dvh" }} data-testid="private-chat-panel">
      <Heading size={2}>{`Chat with ${userFullName}`}</Heading>
      <Heading size={4}>{`This chat is only visible to ${userFullName}`}</Heading>
      <div>{chatTitle}</div>
      <div className="text-secondary">{chatSubtitle}</div>
      <hr className="mt-lg bg-muted" />
      {isLoading && <Loader />}
      <div className="overflow-hidden grow pb-4xl">
        {!isLoading && <ChatContainer channel={channel} messageToJumpIntoExternalId={messageToJumpIntoExternalId} />}
      </div>
    </div>
  </Panel>
);

export default BlockerChatPanel;
