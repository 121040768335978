import React from "react";
import Button, { ButtonProps } from "./button";
import { useFormikContext } from "formik";

function ResetButton({ children, disabled, loading, ...props }: ButtonProps): JSX.Element {
  const { isSubmitting, dirty } = useFormikContext();

  return (
    <Button disabled={disabled || isSubmitting || !dirty} {...props}>
      {children}
    </Button>
  );
}

export default ResetButton;
