import React from "react";
import Row from "./row";
import { Test } from "types/graphql";

export type ResultsListProps = {
  tests: Test[];
  inProgress?: boolean;
  animate?: boolean;
};

const ResultsList = ({ tests, inProgress, animate }: ResultsListProps): JSX.Element => (
  <div className="border-b border-light w-full">
    {tests.map(test => (
      <Row key={test.id} test={test} inProgress={inProgress} animate={animate} />
    ))}
  </div>
);

export default ResultsList;
