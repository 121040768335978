import React from "react";

import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { Heading } from "@hopper/heading";
import { IntegrationCard } from "./integration_card";
import { Integration } from "types/graphql";

type IntegrationsPageProps = {
  integrations: Integration[];
};

const IntegrationsPage = ({ integrations }: IntegrationsPageProps) => (
  <div className="auto-qa-integrations-page">
    <PageTemplate
      header={
        <div className="flex justify-between items-center">
          <Heading size={1}>Integrations</Heading>
        </div>
      }
    >
      <div className="row">
        {integrations.map(integration => (
          <div className="col-lg-4 col-sm-6" key={integration.code}>
            <IntegrationCard integration={integration} />
          </div>
        ))}
      </div>
    </PageTemplate>
  </div>
);

export default IntegrationsPage;
