import React from "react";

import { Heading } from "@hopper/heading";
import pluralize from "pluralize";

const ModalErrorMessage = ({ numberOfTesters }: { numberOfTesters: number }) => (
  <div className="flex flex-col items-center justify-center max-w-xs sm:max-w-full text-center">
    <div
      className="rounded-full flex items-center justify-center mb-2xl"
      style={{ backgroundColor: "rgba(236, 107, 88, 0.12)", height: "4.5rem", width: "4.5rem" }}
      data-testid="error-icon"
    >
      <i className="fa fa-exclamation text-danger opacity-100 text-3xl" aria-hidden="true" />
    </div>
    <Heading size={3}>Error</Heading>
    <Heading size={4}>
      {`An error has occurred, no ${pluralize("tester", numberOfTesters)} ${pluralize(
        "was",
        numberOfTesters,
      )} removed from this test.`}
    </Heading>
  </div>
);

export default ModalErrorMessage;
