import React from "react";
import { formatDistance, parseISO } from "date-fns";
import pluralize from "pluralize";
import { FlexibleTestingTestCaseResult, FlexibleTestingTestWindow } from "types/graphql";
import { statuses, StatusIndicator } from "components/flexible_testing/shared/status_indicator";
// @ts-ignore
import { datetimeFormat } from "components/organization/shared_functions/date_format";

type OverviewProps = {
  caseResults: FlexibleTestingTestCaseResult[];
  caseStatus: typeof statuses[number];
  testWindow: FlexibleTestingTestWindow;
};

const FinishedCaseOverview = ({ caseResults, caseStatus, testWindow }: OverviewProps) => {
  const testersCount = caseResults?.length;
  const startedAt = testWindow.openedAt;
  const completedAt = caseResults?.slice(-1)[0]?.completedAt || testWindow.closedAt;
  const caseDuration = () => formatDistance(parseISO(startedAt), parseISO(completedAt));
  const isTestCaseFinished = testWindow.openedAt && caseResults && caseResults.length > 0;
  const caseDurationInfo = isTestCaseFinished ? `Finished in ${caseDuration()}` : "Test case was not finished";

  return (
    <div className="mt-4xl flex">
      <div className="border-r-2 border-muted pr-xl py-0 flex flex-col justify-between leading-100">
        <div className="text-xl font-bold pt-0 pl-xs" data-testid="test-case-overall-status">
          <StatusIndicator status={caseStatus} />
        </div>
        <div className="text-xs font-bold text-secondary uppercase tracking-extra-wide ml-xl leading-100">
          {datetimeFormat(new Date(completedAt))}
        </div>
      </div>
      <div className="pl-xl text-xs flex flex-col justify-center">
        <div className="mb-xs">{`${testersCount} ${pluralize("tester", testersCount)}`}</div>
        <div>{caseDurationInfo}</div>
      </div>
    </div>
  );
};

export default FinishedCaseOverview;
