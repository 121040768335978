import React from "react";
import { ConsentManager } from "@segment/consent-manager";

import { analyticsConfig } from "components/configuration";

const CookieConsent = () => (
  <div>
    <ConsentManager
      writeKey={analyticsConfig.segmentWriteKey}
      defaultDestinationBehavior="enable"
      shouldRequireConsent={() => false}
      initialPreferences={{ functional: true, marketingAndAnalytics: true, advertising: true }}
    />
  </div>
);

export default CookieConsent;
