import { gql, useQuery } from "@apollo/client";
import { FlexibleTestingTestConnection } from "types/graphql";

type PreviousTestsAvailable = {
  isLoading: boolean;
  hasNoPreviousTests: boolean;
};

export const PREVIOUS_TEST_AVAILABLE_QUERY = gql`
  query FlexibleTestingTests {
    flexibleTestingTests(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

function usePreviousTestAvailable(): PreviousTestsAvailable {
  const { loading, data } = useQuery<{ flexibleTestingTests?: FlexibleTestingTestConnection }>(
    PREVIOUS_TEST_AVAILABLE_QUERY,
  );

  return {
    isLoading: loading,
    hasNoPreviousTests: !data?.flexibleTestingTests?.edges.length,
  };
}

export default usePreviousTestAvailable;
