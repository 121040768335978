import React from "react";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css";
import style from "./style.module.css";
import { getPlaybackRate, setPlaybackRate } from "components/flexible_testing/shared/attachments/use_playback_rate";

class MediaAttachmentLightbox extends React.Component {
  constructor(props) {
    super(props);
    this.videos = [];
    this.state = {
      currentSource: props.currentSource || 0,
      previousSource: props.currentSource || 0,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.currentSource !== state.previousSource) {
      return {
        previousSource: nextProps.currentSource,
        currentSource: nextProps.currentSource,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      setTimeout(() => {
        this.playCurrentVideo();
      }, 0);
    }
  }

  previousSource = () => this.state.currentSource - 1;
  nextSource = () => this.state.currentSource + 1;

  gotoPrevious = () => this.setState({ currentSource: this.previousSource() }, this.playCurrentVideo);
  gotoNext = () => this.setState({ currentSource: this.nextSource() }, this.playCurrentVideo);

  createMissingRefs = () => {
    this.props.media.forEach((media, index) => {
      if (!this.videos[index] && media.videoSources) {
        this.videos[index] = React.createRef();
      }
    });
  };

  playCurrentVideo = () => {
    if (!this.props.autoPlay) {
      return;
    }

    this.videos.forEach((reference, index) => {
      if (reference && reference.current && index === this.state.currentSource) {
        reference.current.play();
      } else if (reference && reference.current) {
        reference.current.pause();
        reference.current.currentTime = 0;
      }
    });
  };

  handlePlaybackRate = () => {
    this.videos[this.state.currentSource].current.playbackRate = getPlaybackRate();
  };

  handlePlaybackRateChange = () => {
    const currentPlaybackRate = this.videos[this.state.currentSource].current.playbackRate;

    setPlaybackRate(currentPlaybackRate);
  };

  renderMedia() {
    this.createMissingRefs();

    return this.props.media.map((media, index) => {
      if (media.image) {
        return media.image;
      } else if (media.videoSources) {
        return (
          <video
            controls
            muted
            className={style.videoLightbox}
            ref={this.videos[index]}
            onRateChange={this.handlePlaybackRateChange}
            onProgress={this.handlePlaybackRate}
            onPlaying={this.handlePlaybackRate}
          >
            {media.videoSources.map(source => (
              <source key={source.url} src={source.url} type={source.type} />
            ))}
          </video>
        );
      }

      return null;
    });
  }

  renderLightBox() {
    const renderedMedia = this.renderMedia();

    if (renderedMedia.length > 0 && this.props.isOpen) {
      return (
        <Lightbox
          mainSrc={renderedMedia[this.state.currentSource]}
          reactModalStyle={{ overlay: { zIndex: 2501 } }}
          imagePadding={20}
          nextSrc={renderedMedia[this.nextSource()]}
          prevSrc={renderedMedia[this.previousSource()]}
          onMovePrevRequest={this.gotoPrevious}
          onMoveNextRequest={this.gotoNext}
          onCloseRequest={this.props.onClose}
          enableZoom={false}
        />
      );
    }
  }

  render() {
    return <div>{this.renderLightBox()}</div>;
  }
}

export default MediaAttachmentLightbox;
