import React from "react";
import { Transaction } from "types/graphql";
import { Link } from "react-router-dom";
import { Label } from "@hopper/legacy_label";
// @ts-ignore
import { datetimeFormat } from "components/organization/shared_functions/date_format";
import { BodyCell, Row } from "components/flexible_testing/shared/table";

export type TransactionRowProps = {
  transaction: Transaction;
};

const TransactionAmount = ({ amount }: { amount: number }) => {
  const content = amount > 0 ? <span className="text-success">+{amount}</span> : amount;

  return (
    <div data-testid="transaction-amount" className="text-body text-md font-bold tracking-wider">
      {content}
    </div>
  );
};

const BalanceAfterTransaction = ({ balanceAfterTransaction }: { balanceAfterTransaction: number }) => {
  const testColor = balanceAfterTransaction > 0 ? "text-body" : "text-danger";

  return (
    <Label color="bg-light" className="tracking-extra-wide uppercase">
      <span data-testid="balance-after-transaction" className={testColor} style={{ fontSize: "1rem" }}>
        {balanceAfterTransaction}
      </span>
    </Label>
  );
};

const TransactionLabel = ({ transaction }: { transaction: Transaction }) => {
  if (transaction.amount > 0) {
    return (
      <Label size="sm" color="bg-success" className="tracking-extra-wide uppercase text-xs">
        <span data-testid="transaction-label">Credit</span>
      </Label>
    );
  } else if (transaction.subject) {
    return (
      <Label size="sm" color="bg-light" className="tracking-extra-wide uppercase">
        <span data-testid="transaction-label" className="text-body text-xs">
          {transaction.subject.type}
        </span>
      </Label>
    );
  } else {
    return (
      <Label size="sm" color="bg-light" className="tracking-extra-wide uppercase">
        <span data-testid="transaction-label" className="text-body text-xs">
          Deduction
        </span>
      </Label>
    );
  }
};

const TransactionTitle = ({ transaction }: { transaction: Transaction }) => {
  const content = transaction.subject?.name || transaction.description;
  const title = transaction.amount > 0 && transaction.subject ? `Refund for: ${content}` : content;

  return (
    <div className="text-base font-bold pl-xs" data-testid="transaction-description">
      {title}
    </div>
  );
};

const TransactionDescription = ({ transaction }: { transaction: Transaction }) => {
  const description = transaction.subject ? transaction.description : null;

  return (
    <div className="text-dark text-xs uppercase tracking-wider ml-xs mb-xs" style={{ whiteSpace: "pre-wrap" }}>
      {description}
    </div>
  );
};

const TransactionContent = ({ transaction }: { transaction: Transaction }) => {
  const transactionDescription = (
    <div className="flex items-center pb-sm">
      <TransactionLabel transaction={transaction} />
      <TransactionTitle transaction={transaction} />
    </div>
  );

  return transaction.subject ? (
    <Link to={transaction.subject.url as string}>{transactionDescription}</Link>
  ) : (
    transactionDescription
  );
};

const TransactionRow = ({ transaction }: TransactionRowProps): JSX.Element => (
  <Row key={transaction.id} className="border-muted border-b hover:bg-light">
    <BodyCell>
      <TransactionContent transaction={transaction} />
      <TransactionDescription transaction={transaction} />

      <div className="text-dark text-xs uppercase tracking-wider ml-xs">{datetimeFormat(transaction.createdAt)}</div>
    </BodyCell>
    <BodyCell className="text-center">
      <TransactionAmount amount={transaction.amount} />
    </BodyCell>
    <BodyCell className="text-center">
      <BalanceAfterTransaction balanceAfterTransaction={transaction.balanceAfterTransaction} />
    </BodyCell>
  </Row>
);

export default TransactionRow;
