import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingCreateIssueTagInput, FlexibleTestingCreateIssueTagPayload } from "types/graphql";
import { ISSUE_TAGS_QUERY } from "./use_issue_tags";

export const CREATE_TAG = gql`
  mutation FlexibleTestingCreateIssueTag($input: FlexibleTestingCreateIssueTagInput!) {
    flexibleTestingCreateIssueTag(input: $input) {
      successful
      result {
        id
        name
      }
    }
  }
`;

type useCreateIssueTagResult = {
  createIssueTag: (tagName: string) => Promise<string | undefined>;
};

export const useCreateIssueTag = (): useCreateIssueTagResult => {
  const [createIssueTagMutation] = useMutation<
    { flexibleTestingCreateIssueTag: FlexibleTestingCreateIssueTagPayload },
    { input: FlexibleTestingCreateIssueTagInput }
  >(CREATE_TAG, { refetchQueries: () => [{ query: ISSUE_TAGS_QUERY }] });

  return {
    createIssueTag: async (tagName: string): Promise<string | undefined> => {
      const tmp = await createIssueTagMutation({
        variables: {
          input: {
            name: tagName as string,
          },
        },
      });

      return tmp.data?.flexibleTestingCreateIssueTag.result?.id;
    },
  };
};
