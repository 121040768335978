import React, { useState } from "react";

import { Select, TextInput } from "components/shared/form";
import IssuetypeField from "./issuetype_field";
import { JiraConnectProject } from "types/graphql";

type JiraConnectExportFieldsProps = {
  projects: Array<JiraConnectProject>;
  integrationId: string;
  labelClass: string;
  projectKey: string;
  issuetypeName: string;
};

const JiraConnectExportFields = ({
  projects,
  integrationId,
  labelClass,
  projectKey,
  issuetypeName,
}: JiraConnectExportFieldsProps) => {
  const getProjectOption = (projectKey: string) => projectOptions?.find(({ value }) => value === projectKey);

  const projectOptions = projects?.map(({ key: value, name: label }) => ({ value, label }));
  const [selectedProject, setSelectedProject] = useState(getProjectOption(projectKey));
  const [initialIssuetypeName, setInitialIssuetypeName] = useState<string | null>(issuetypeName);

  function onProjectChange(projectKey: string) {
    setSelectedProject(getProjectOption(projectKey));
    setInitialIssuetypeName(null);
  }

  return (
    <>
      <div className="mb-lg" data-test-role="form-group">
        <Select
          required
          name="project_key"
          label="Project"
          options={projectOptions}
          onChange={onProjectChange}
          clearable={false}
          labelClass={labelClass}
          data-testid="project-key-input"
          value={selectedProject}
        />
      </div>
      <IssuetypeField
        integrationId={integrationId}
        projectKey={selectedProject?.value || ""}
        issuetypeName={initialIssuetypeName || ""}
        labelClass={labelClass}
      />
      <div className="mb-lg" data-test-role="form-group">
        <TextInput
          id="labels"
          name="labels"
          label="Labels (comma separated values)"
          placeholder="bug, critical"
          className="form-control"
          labelClass={labelClass}
          data-testid="labels-input"
        />
      </div>
    </>
  );
};

export default JiraConnectExportFields;
