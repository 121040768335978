import React from "react";
import { FlexibleTestingTestCaseResult } from "types/graphql";
import { Header, HeaderCell, Table } from "../../shared/table";
import TestCasesList from "./test_cases_list";
import TestCasesListGroupedBySection from "./test_cases_list_grouped_by_section";
import { AssignedCaseWithOwnResult } from "../tester_overview";

function TesterCasesTable({
  testCases,
  onTestCaseResultRowClick,
  testerId,
  selectedTestCaseResultId,
}: {
  testCases: Array<AssignedCaseWithOwnResult>;
  onTestCaseResultRowClick: (testCaseResult: FlexibleTestingTestCaseResult) => void;
  testerId?: string;
  selectedTestCaseResultId?: string;
}) {
  const isSectionNameDetected = testCases.some(testCase => !!testCase.section);

  const testCasesList = (): JSX.Element => {
    if (isSectionNameDetected) {
      return (
        <TestCasesListGroupedBySection
          selectedTestCaseResultId={selectedTestCaseResultId}
          testCases={testCases}
          testerId={testerId}
          onTestCaseResultRowClick={onTestCaseResultRowClick}
        />
      );
    } else {
      return (
        <TestCasesList
          selectedTestCaseResultId={selectedTestCaseResultId}
          testCases={testCases}
          testerId={testerId}
          onTestCaseResultRowClick={onTestCaseResultRowClick}
        />
      );
    }
  };

  return (
    <Table borderColor="muted" className="pt-2xl mt-2xl">
      <Header className="border-b border-muted">
        <HeaderCell style={{ width: "10%" }}>RESULT</HeaderCell>
        <HeaderCell style={{ width: "10%" }}>ID</HeaderCell>
        <HeaderCell style={{ width: "45%" }}>TITLE</HeaderCell>
        <HeaderCell style={{ width: "15%" }}>MODERATION</HeaderCell>
        <HeaderCell style={{ width: "20%" }}>TAGS</HeaderCell>
      </Header>
      {testCasesList()}
    </Table>
  );
}

export default TesterCasesTable;
