import { getEnv } from "app/env";

export const ApiRoot = getEnv("API_ROOT") || "http://localhost:3000";
export const TesterWorkRoot = getEnv("TESTERWORK_ROOT") || "http://tester-work-local.gatdev.io";

const servicesNamespace = "/services";
const testManagersNamespace = "/test_managers";

export const websiteContentPaths = {
  root: "/",
  error: "/error",
  auth: "/auth",
  authLogout: "/auth/logout",
  authLogin: "/auth/login",
  signUp: "/signup",
  signUpError: "/signup/error",
  notFoundError: "/not_found_page",

  overview: "/overview",
  services: "/services",
  newServiceRequest: servicesNamespace + "/:professionalServiceId/professional-service-requests/new",

  organizationUserSettings: "/organization-user-settings",
  notificationsSettings: "/organization-user-settings/notifications",
  profileSettings: "/organization-user-settings/profile",
  organizationUserCreditUsage: "/organization-user/credit-usage",

  organizationSettings: "/organization-settings",
  integrationSettings: "/organization-settings/integrations",
  integrationSetting: "/organization-settings/integrations/:code",
  organizationUsers: "/organization-settings/users",

  apiSettings: "/organization-settings/api-settings",

  testRequestsTestRail: "/test-requests/testrail/:externalTestId",
  testRequestOther: "/test-requests/:vendor/:externalTestId",
  testRequestResult: "/test-requests/:vendor/:externalTestId/confirmation",

  allIssues: "/issues",
  flexcaseIssue: "/issues/:id",
  flexcaseIssueFromResults: "/results/:testId/issue/:id",
  flexcaseResults: "/results",
  flexcaseResult: "/results/:id",
  flexcaseParticipantResult: "/results/:testId/participant/:participantId",
  flexcaseResultAcceptedIssues: "/results/:id?tab=issues&filter=accepted",
  flexcaseTestCaseResults: "/results/:id?tab=test-case-results",
  flexcaseTestCase: "/results/:testId/test-case/:id",
  flexcaseLaunch: "/launch",
  testPreview: "/launch/preview",
  advancedTestLaunch: "/launch?tab=advanced_test",
  designTestLaunch: "/launch?sdlc_stage=design",
  developmentTestLaunch: "/launch?sdlc_stage=development",
  releaseTestLaunch: "/launch?sdlc_stage=release",
  liveTestLaunch: "/launch?sdlc_stage=live",
  flexcaseTestsForCloning: "/launch/tests-for-cloning",
  advancedTestsForCloning: "/launch/advanced-tests-for-cloning",
  advancedTestRequestTest: "/launch/test",
  advancedTestRequestCustomTest: "/launch/custom",
  successfulCustomerRequest: "/launch/success",
  flexcaseAutoExportSettings: "/auto-export-settings",

  supervisedTestTesterOverview: "/results/:testId/tester/:testerId",

  testManagers: testManagersNamespace,
  supervisedTestLaunchChild: testManagersNamespace + "/advanced_tests/:id/launch_child",
  supervisedTestLaunchChildPreview: testManagersNamespace + "/advanced_tests/:id/launch_child/preview",
};

const ApiNamespace = "/api/customer_portal";
export const ApiPath = ApiRoot + ApiNamespace;

export const exampleImportTestCasesSpreadsheetUrl = ApiRoot + "/examples/test_cases_import.xlsx";
export const newSupervisedTestTestCycleUrl =
  ApiRoot + "/admin/ungrouped_test_cycles/new?advanced_test_request_id=:id&test_id=:testId";
export const supervisedTestSettingsUrl = ApiRoot + "/admin/tester_management_settings";

export const intercom = {
  customLauncherClassname: "open-intercom-link",
};

export const productionHosts = ["customer-portal.gatserver.com", "app.globalapptesting.com"];

export const analyticsConfig = {
  segmentWriteKey: getEnv("SEGMENT_WRITE_KEY"),
};

export const googleAnalyticsTrackId = getEnv("GOOGLE_ANALYTICS_TRACK_ID");

export const Events = {
  SIGNED_IN: "SignedIn",
  FLEXIBLE_TESTING_TEST_CLONED: "FlexibleTestingTestCloned",
  ADVANCED_TEST_CLONED: "AdvancedTestCloned",
  NEXT_ISSUE_LINK_CLICKED: "NextIssueLinkClicked",
  TESTER_DETAIL_PAGE_VIEWED: "TesterDetailPageViewed",
  REPRO_TEST_TAB_CLICKED: "ReproTestTabClicked",
  CASE_RESULT_ATTACHMENT_OPENED: "CaseResultAttachmentOpened",
  ATTACHMENT_REMOVED: "AttachmentRemoved",
  ATTACHMENT_RESTORED: "AttachmentRestored",
  OVERALL_RESULT_EDITED: "OverallResultEdited",
  STEP_RESPONSE_RESULT_EDITED: "ModeratedStepResponseResultEdited",
  RELATED_ISSUE_EDITED: "RelatedIssueEdited",
  RELATED_ISSUE_DELETED: "RelatedIssueDeleted",
  RELATED_ISSUE_OPENED: "RelatedIssueOpened",
  TEST_CASE_RESULT_MARKED_AS_PASSED: "TestCaseResultMarkedAsPassed",
  ADDITIONAL_INFORMATION_RESPONSE_RESULT_EDITED: "AdditionalInformationResponseResultEdited",
};

export const Auth0Config = {
  domain: getEnv("AUTH0_DOMAIN"),
  clientId: getEnv("AUTH0_CLIENT_ID"),
  audience: getEnv("AUTH0_AUDIENCE"),
};

export const CSVBoxConfig = {
  testCasesLicenseKey: getEnv("CSVBOX_TEST_CASES_LICENCE_KEY") || "",
  expressTestsLicenseKey: getEnv("CSVBOX_EXPRESS_TESTS_LICENSE_KEY") || "",
};

const AzureDevopsConfig = {
  clientId: getEnv("AZURE_DEVOPS_APP_ID"),
  callbackUrl: getEnv("AZURE_DEVOPS_CALLBACK_URL"),
};

export const StreamChatConfig = {
  apiKey: getEnv("STREAM_CHAT_API_KEY") || "",
};

export const azureDevopsRedirectUrl =
  "https://app.vssps.visualstudio.com/oauth2/authorize?" +
  `client_id=${AzureDevopsConfig.clientId}` +
  `&response_type=Assertion` +
  `&scope=${encodeURIComponent("vso.work_write vso.project")}` +
  `&redirect_uri=${AzureDevopsConfig.callbackUrl}`;

export const slackOauthUrl =
  "https://slack.com/oauth/v2/authorize?" +
  `client_id=${getEnv("SLACK_CLIENT_ID")}` +
  "&scope=channels:join,channels:read,chat:write,commands,incoming-webhook,team:read" +
  "&user_scope=channels:read";

export const helpCenterUrl = "https://help.globalapptesting.com";

export const additionalInformationDocumentationUrl =
  "https://gatteam.atlassian.net/wiki/spaces" +
  "/OPS/pages/3531800577/Supervised+Tests#Setting-up-Test-Cases-with-Additional-Info-required";

export const websocketServerUrl = getEnv("WS_SERVER_URL") || `${ApiRoot.replace(/https/, "wss")}/ws/`;

export const isInternalEmail = (email: string) => {
  const domain = email.split("@")[1] || "";

  return ["globalapptesting.com", "internal-testing.gatdev.io"].includes(domain);
};
