import React, { useState } from "react";

type CollapsibleSectionProps = {
  initiallyOpen: boolean;
  title: JSX.Element;
  onChange?: (open: boolean) => void;
  children: JSX.Element;
  chevronPosition?: "right" | "left";
};

function CollapsibleSection({
  initiallyOpen,
  title,
  onChange,
  children,
  chevronPosition = "right",
}: CollapsibleSectionProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(initiallyOpen);

  const chevronStyle = {
    transition: "transform 100ms",
    transformOrigin: "center",
    transform: "rotate(0deg)",
  };

  if (!open) {
    chevronStyle.transform = "rotate(180deg)";
  }

  const chevron = (
    <div>
      <i className="fa fa-chevron-up" style={chevronStyle} data-testid="expand-section" />
    </div>
  );

  return (
    <>
      <div
        className="text-dark font-bold text-xl py-md border-b border-light flex cursor-pointer"
        onClick={() => {
          setOpen(!open);
          onChange?.(!open);
        }}
      >
        {chevronPosition === "left" && chevron}
        <div className="flex flex-grow">{title}</div>
        {chevronPosition === "right" && chevron}
      </div>
      {open && <div className="pt-2xl">{children}</div>}
    </>
  );
}

export { CollapsibleSection };
