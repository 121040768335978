import React from "react";

import { Table, Body, BodyCell, Row } from "components/flexible_testing/shared/table";
import { FlexibleTestingTest, AssignedCommunityTestManager } from "types/graphql";
import { RemoveCommunityTestManagerButton } from "./remove_community_test_manager_button";

type AssignedModeratorsTableProps = {
  assignedCommunityTestManagers: AssignedCommunityTestManager[];
  flexibleTestingTestId: FlexibleTestingTest["id"];
};

type StatusMapOptions = {
  [key: string]: string;
};

const statusIconMap: StatusMapOptions = {
  assigned: "",
  accepted: "✅ ",
  rejected: "⚠️",
  removed: "❌",
};

const statusInfoMap: StatusMapOptions = {
  assigned: "",
  accepted: "accepted the assignment",
  rejected: "rejected the assignment",
  removed: "was removed from the assignment",
};

const statesValidForRemoval = ["accepted", "assigned"];

export const AssignedCommunityTestManagersTable = ({
  assignedCommunityTestManagers,
  flexibleTestingTestId,
}: AssignedModeratorsTableProps) => (
  <Table className="table-fixed w-52" noBorder data-testid="assigned-community-test-managers-table">
    <Body>
      {assignedCommunityTestManagers.map(assignedCommunityTestManager => (
        <Row key={assignedCommunityTestManager.id} className="min-w-full break-words" noBorder>
          <BodyCell className="p-0 py-1" style={{ width: "55%" }}>
            <div>
              <span className="font-bold">{statusIconMap[assignedCommunityTestManager.status]}</span>{" "}
              {assignedCommunityTestManager.fullName}{" "}
              <span className="text-secondary">{statusInfoMap[assignedCommunityTestManager.status]}</span>
            </div>
          </BodyCell>
          <BodyCell className="p-0" style={{ width: "45%" }}>
            {statesValidForRemoval.includes(assignedCommunityTestManager.status) && (
              <RemoveCommunityTestManagerButton
                assignedCommunityTestManager={assignedCommunityTestManager}
                flexibleTestingTestId={flexibleTestingTestId}
              />
            )}
          </BodyCell>
        </Row>
      ))}
    </Body>
  </Table>
);
