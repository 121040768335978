import React from "react";
import { Tab } from "@hopper/tab";
import { TabBar } from "@hopper/tab_bar";
import { isNil } from "lodash";

import { FlexibleTestingTesterSession, Test } from "types/graphql";
import { HeadingNumber } from "../../shared/heading_number";
import Authorization from "app/authorization/authorization";
import { useFeatureFlag } from "app/feature_flags";

type TabHeadersProps = {
  testId?: string;
  selectedTab: string;
  platformTest?: Test;
  platformTestLoading: boolean;
  openBlockersCount?: number;
  blockersLoading: boolean;
  onTabClicked: (tabName: string) => void;
};

type TabTitleProps = {
  title: string;
  testIdPrefix: string;
  headingNumber?: number;
  isLoading?: boolean;
  backgroundType?: string;
};

function TabHeaders({
  platformTest,
  platformTestLoading,
  selectedTab,
  openBlockersCount,
  blockersLoading,
  onTabClicked,
}: TabHeadersProps): JSX.Element {
  const issuesCount = platformTest?.issues?.totalCount || 0;
  const casesCount = platformTest?.cases?.totalCount || 0;
  const feedbackCount = extractTesterFeedback(platformTest).length;
  const blockersCount = openBlockersCount === 0 ? undefined : openBlockersCount;
  const hasCTMsBlockersAccess = useFeatureFlag("ctms-blockers-access", false);

  return (
    <div className="border-b border-light mt-lg">
      <TabBar>
        <Tab active={selectedTab === "summary"} onClick={() => onTabClicked("summary")}>
          {tabTitle({ title: "Summary", testIdPrefix: "summary" })}
        </Tab>
        <Tab active={selectedTab === "test-details"} onClick={() => onTabClicked("test-details")}>
          {tabTitle({ title: "Test details", testIdPrefix: "test-details" })}
        </Tab>
        <Authorization roles={["communityTestManager", "testManager"]}>
          <Tab active={selectedTab === "testers"} onClick={() => onTabClicked("testers")}>
            {tabTitle({
              title: "Testers",
              testIdPrefix: "testers",
            })}
          </Tab>
        </Authorization>
        <Authorization roles={hasCTMsBlockersAccess ? ["testManager", "communityTestManager"] : ["testManager"]}>
          <Tab active={selectedTab === "blockers"} onClick={() => onTabClicked("blockers")}>
            {tabTitle({
              title: "Blockers",
              testIdPrefix: "blockers",
              headingNumber: blockersCount,
              isLoading: blockersLoading,
              backgroundType: "danger",
            })}
          </Tab>
        </Authorization>
        <Authorization roles={["testManager", "customerUser"]}>
          <>
            <Tab active={selectedTab === "issues"} onClick={() => onTabClicked("issues")}>
              {tabTitle({
                title: "Issues reported",
                testIdPrefix: "issues",
                headingNumber: issuesCount,
                isLoading: platformTestLoading,
              })}
            </Tab>
            <Tab active={selectedTab === "test-case-results"} onClick={() => onTabClicked("test-case-results")}>
              {tabTitle({
                title: "Test case results",
                testIdPrefix: "case-results",
                headingNumber: casesCount,
                isLoading: platformTestLoading,
              })}
            </Tab>
            <Tab active={selectedTab === "tester-feedback"} onClick={() => onTabClicked("tester-feedback")}>
              {tabTitle({
                title: "Tester feedback",
                testIdPrefix: "tester-feedback",
                headingNumber: feedbackCount,
                isLoading: platformTestLoading,
              })}
            </Tab>
          </>
        </Authorization>
      </TabBar>
    </div>
  );
}

function extractTesterFeedback(platformTest?: Test): FlexibleTestingTesterSession[] {
  return (
    platformTest?.testerSessions?.edges
      ?.map(testerSession => testerSession?.node)
      .filter(testerSession => !!testerSession)
      .filter(testerSession => testerSession.feedback) || []
  );
}

function tabTitle({
  title,
  testIdPrefix,
  headingNumber,
  isLoading = false,
  backgroundType,
}: TabTitleProps): JSX.Element {
  return (
    <div className="flex" data-testid={`${testIdPrefix}-tab`}>
      <span className="mr-xs">{title}</span>
      {!isNil(headingNumber) && (
        <HeadingNumber testId={`${testIdPrefix}-heading-number`} backgroundType={backgroundType}>
          {isLoading ? <span>...</span> : headingNumber}
        </HeadingNumber>
      )}
    </div>
  );
}

export default TabHeaders;
