import React from "react";
import { components } from "react-select";

function customOptionWrapper(WrappedComponent) {
  return function ({ data, ...props }) {
    return (
      <components.Option {...props}>
        <WrappedComponent option={data} />
      </components.Option>
    );
  };
}

export default customOptionWrapper;
