import React from "react";
import { Modal } from "@hopper/legacy_modal";

import SuccessMessage from "components/flexible_testing/preview_page/content/success_modal/success_message";

type TestLaunchedModalProps = {
  formattedTestLaunchDateTime: string | null;
  isOpen?: boolean;
  onCloseModalClicked?: () => void;
  resultsUrl: string;
};

function TestLaunchedModal({
  formattedTestLaunchDateTime,
  isOpen = false,
  onCloseModalClicked = () => {},
  resultsUrl,
}: TestLaunchedModalProps): JSX.Element {
  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onCloseModalClicked}
      position="center"
      size="lg"
      isOpen={isOpen}
      onOverlayClicked={onCloseModalClicked}
    >
      <div data-testid="success-modal" style={{ width: "28rem", height: "23rem" }}>
        <SuccessMessage
          onCloseModalClicked={onCloseModalClicked}
          formattedTestLaunchDateTime={formattedTestLaunchDateTime}
          resultsUrl={resultsUrl}
        />
      </div>
    </Modal>
  );
}

export default TestLaunchedModal;
