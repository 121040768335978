import React from "react";
import * as yup from "yup";

import { Form, ResetButton, SubmitButton, TextInput } from "components/flexible_testing/shared/form";
import { useSaveOrganizationChosenName } from "components/flexible_testing/shared/use_save_organization_chosen_name";

type OrganizationFormProps = {
  chosenName: string;
  setChosenName: (fn: string) => void;
};

const OrganizationForm = ({ chosenName, setChosenName }: OrganizationFormProps): JSX.Element => {
  const { saveOrganizationChosenName, isLoading } = useSaveOrganizationChosenName();

  const handleSubmit = (values: { [field: string]: unknown }): Promise<unknown> =>
    saveOrganizationChosenName(values.chosenName as string);

  return (
    <>
      <Form
        data-testid="chosen-name-form"
        initialValues={{ chosenName }}
        validations={yup.object().shape({
          chosenName: yup
            .string()
            .required("Your organization name cannot be blank")
            .max(255, "Name cannot be longer than 255 characters."),
        })}
        onSubmit={handleSubmit}
      >
        <div data-test-role="form-group">
          <div data-testid="organisation-form" className="font-bold">
            Edit organization name
          </div>
          <div className="font-normal text-secondary text-sm max-w-sm">
            Changing your organization name will update the name for all users within
            <br />
            your organization
          </div>
          <div className="mt-xl">
            <TextInput
              name="chosenName"
              label="Organization name"
              setInputValue={setChosenName}
              value={chosenName}
              required={false}
            />
          </div>
        </div>
        <div className="mr-lg inline-block">
          <SubmitButton className="mt-xl" useHopperButton={true} loading={isLoading}>
            Save
          </SubmitButton>
        </div>
        <div className="inline-block">
          <ResetButton>Cancel</ResetButton>
        </div>
      </Form>
    </>
  );
};

export default OrganizationForm;
