import React, { useRef, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { PopupMenu } from "@hopper/popup_menu";
import { Button } from "@hopper/button";
import { Textarea } from "@hopper/textarea";
import Popup from "components/shared/popup";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";

type CaseResultPopupProps = {
  isOpen: boolean;
  onCancelButtonClick: () => void;
  onSaveButtonClick: (content: string) => void;
  isButtonLoading?: boolean;
  label: string;
  placeholder?: string;
  initialValue?: string;
  children: React.ReactNode;
};

type CaseResultPopupContentProps = {
  onCancelButtonClick: () => void;
  onSaveButtonClick: (content: string) => void;
  isButtonLoading?: boolean;
  shouldResetContent: boolean;
  label: string;
  placeholder?: string;
  initialValue?: string;
};

const CaseResultPopupContent = ({
  onCancelButtonClick,
  onSaveButtonClick,
  isButtonLoading,
  shouldResetContent,
  label,
  placeholder,
  initialValue,
}: CaseResultPopupContentProps) => {
  const [content, setContent] = useState(String(initialValue || ""));
  const isSaveButtonDisabled = isEmpty(content);

  useEffect(() => setContent(String(initialValue || "")), [shouldResetContent]);

  return (
    <div data-testid="case-result-edit-request-box">
      <PopupMenu>
        <div className="text-body px-lg py-sm">
          {label}
          <span className="pl-xs text-danger">*</span>
          <div className="py-sm">
            <Textarea
              placeholder={placeholder}
              onChange={event => setContent(event.target.value)}
              style={{ minHeight: "96px", width: "380px" }}
              value={content}
            />
          </div>
          <div>
            <Button variant="secondary" className="mr-lg" onClick={onCancelButtonClick}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSaveButtonClick(content);
              }}
              disabled={isSaveButtonDisabled}
              isLoading={isButtonLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </PopupMenu>
    </div>
  );
};

const CaseResultPopup = ({
  isOpen,
  onCancelButtonClick,
  onSaveButtonClick,
  isButtonLoading,
  label,
  placeholder,
  initialValue,
  children,
}: CaseResultPopupProps) => {
  const wrapperRef = useRef(null);

  useOutsideHandler(wrapperRef, onCancelButtonClick);

  const editRequestPopupContent = CaseResultPopupContent({
    onCancelButtonClick,
    onSaveButtonClick,
    isButtonLoading,
    shouldResetContent: isOpen,
    label,
    placeholder,
    initialValue,
  });

  return (
    <div ref={wrapperRef}>
      <Popup placement="bottom-start" popupElement={editRequestPopupContent} isOpen={isOpen} offset={[0, 10]}>
        <>{children}</>
      </Popup>
    </div>
  );
};

export default CaseResultPopup;
