import React, { useEffect } from "react";
import { Channel as StreamChannel } from "stream-chat";
import { Channel, Chat, MessageInput, MessageList, Thread, useChannelActionContext, Window } from "stream-chat-react";
import { CustomMessage } from "./custom_message";
import { useChatContext } from "./chat_client_context";

type ChatContainerProps = {
  channel?: StreamChannel;
  messageToJumpIntoExternalId?: string | null;
  readOnly?: boolean;
};

const MESSAGE_ACTIONS = ["delete", "edit", "react", "reply", "quote"];
const THREAD_MESSAGE_ACTIONS = ["delete", "edit", "react", "quote"];

function ChatContainer({ channel, messageToJumpIntoExternalId, readOnly = false }: ChatContainerProps) {
  const { client } = useChatContext();

  if (!client || !channel) return <></>;

  return (
    <Chat client={client}>
      <Channel channel={channel}>
        <Window hideOnThread>
          <JumpToMessage messageId={messageToJumpIntoExternalId} />
          <MessageList messageActions={readOnly ? [] : MESSAGE_ACTIONS} Message={CustomMessage} />
          {readOnly || <MessageInput grow />}
        </Window>
        <Thread
          fullWidth
          virtualized
          additionalMessageInputProps={{ grow: true }}
          messageActions={THREAD_MESSAGE_ACTIONS}
          Message={CustomMessage}
        />
      </Channel>
    </Chat>
  );
}

type JumpToMessageProps = {
  messageId?: string | null;
};

const JumpToMessage = ({ messageId }: JumpToMessageProps) => {
  const { jumpToMessage } = useChannelActionContext();

  useEffect(() => {
    if (messageId) {
      void jumpToMessage(messageId);
    }
  }, []);

  return <></>;
};

export default ChatContainer;
