import { FetchResult, useMutation } from "@apollo/client";
import { useState } from "react";
import { FlexibleTestingLaunchTestPayload, MutationError } from "types/graphql";
import gql from "graphql-tag";

type Error = {
  code: string;
  message: string;
};

type useLaunchTestResult = {
  isLoading: boolean;
  launchTest: (
    vendor: string,
    projectId: string,
    externalTestId: string,
    name?: string,
    description?: string,
    applicationUrl?: string,
    credentialsAndAccessInstructions?: string,
    additionalUrlsAndResources?: string,
  ) => Promise<FetchResult<{ mutation: FlexibleTestingLaunchTestPayload }> | undefined>;
  error?: Error;
};

type testrailParams = {
  externalTestId: string;
  vendor: string;
  name?: string;
  description?: string;
  applicationUrl?: string;
  credentialsAndAccessInstructions?: string;
  additionalUrlsAndResources?: string;
};

type zephyrSquadParams = {
  externalTestId: string;
  vendor: string;
  issueId: string;
  projectId: string;
};

type integrationParams = {
  [key: string]: zephyrSquadParams | testrailParams;
};

export const LAUNCH_TEST = gql`
  mutation flexibleTestingLaunchTest($input: FlexibleTestingLaunchTestInput!) {
    mutation: flexibleTestingLaunchTest(input: $input) {
      successful
      errors {
        code
        message
      }
    }
  }
`;

function useLaunchTest(): useLaunchTestResult {
  const [error, setError] = useState<MutationError | undefined>();

  const [launchTest, { loading }] = useMutation(LAUNCH_TEST, {
    onCompleted: ({ mutation: { successful, errors } }) => {
      if (!successful) {
        setError(errors[0]);
      }
    },
    onError: () => {
      setError({ message: "Unknown error", code: "unknown_error" });
    },
  });

  return {
    isLoading: loading,
    launchTest: async (
      vendor,
      projectId,
      externalTestId,
      name = undefined,
      description = undefined,
      applicationUrl = undefined,
      credentialsAndAccessInstructions = undefined,
      additionalUrlsAndResources = undefined,
    ): Promise<FetchResult<{ mutation: FlexibleTestingLaunchTestPayload }> | undefined> => {
      if (vendor) {
        const inputParamsFactory: integrationParams = {
          "zephyr-squad": {
            vendor,
            projectId,
            externalTestId,
          },
          testrail: {
            externalTestId,
            vendor,
            name,
            description,
            applicationUrl,
            credentialsAndAccessInstructions,
            additionalUrlsAndResources,
          },
        };

        return await launchTest({
          variables: {
            input: inputParamsFactory[vendor],
          },
        });
      }
    },
    error,
  };
}

export default useLaunchTest;
