import React from "react";
import rocketIcon from "assets/images/rocket.svg";

const NoIssuesForNotOpenTestYet = () => (
  <div className="mt-xl" data-testid="no-issues-found-yet-message">
    <div className="flex font-bold max-w-md pb-md border-b border-light">
      <span className="uppercase text-secondary font-bold tracking-wider inline-block text-xs">Preparing</span>
    </div>
    <div className="mt-xl flex">
      <img src={rocketIcon} className="mr-xs" />
      <span>Issues will be shown here as soon as they are reported by our testers</span>
    </div>
  </div>
);

export default NoIssuesForNotOpenTestYet;
