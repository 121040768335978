import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import queryString from "query-string";

import { websiteContentPaths } from "components/configuration";
import { usePageViewTrack } from "./use_page_view_track";
import { useFeatureFlag } from "app/feature_flags";

import { useIsOrganizationNameChosen } from "components/flexible_testing/shared/use_is_organization_name_chosen";
import AskForOrganizationName from "components/flexible_testing/result_details_page/ask_for_organization_name";
import { TesterFeedback } from "./tester_feedback";
import { Testers } from "components/flexible_testing/result_details_page/testers";
import { useTestBaseInfo } from "./use_test_base_info";
import { ResultsHeader } from "./results_header";
import { TabHeaders } from "./tab_headers";
import { TestCases } from "./test_cases";
import { Issues } from "./issues";
import { Summary } from "./summary";
import { TestDetails } from "./test_details";
import { TestBlockers } from "./blockers";
import Authorization from "app/authorization/authorization";
import { useTestersTabTestData } from "./testers/use_testers_tab_test_data";
import { useSelectionRequirementsGroups } from "components/flexible_testing/shared/use_selection_requirements_groups";
import { useCurrentUser } from "app/current_user/use_current_user";
import { useFeedbackTabData } from "./tester_feedback/use_feedback_tab_data";
import { useTestDetails } from "./test_details/use_test_details";
import { useTestCasesTabData } from "./test_cases/use_test_cases_tab_data";
import { useTabHeadersData } from "./tab_headers/use_tab_headers_data";
import { useSummaryTabData } from "./summary/use_summary_tab_data";
import { useResultsHeaderData } from "./results_header/use_results_header_data";
import { useIssuesTabData } from "./issues/use_issues_tab_data";
import { useTestBlockers } from "./blockers/use_test_blockers_tab_data";

type RouteParams = {
  testId?: string;
};

function ResultDetailsContent(): JSX.Element {
  const location = useLocation();
  const hasCTMsBlockersAccess = useFeatureFlag("ctms-blockers-access", false);

  const paramsTab = queryString.parse(location.search).tab;
  const [selectedTab, setSelectedTab] = useState<string>(paramsTab || "summary");
  const match = useRouteMatch<RouteParams>("/results/:testId");
  const { trackPageView } = usePageViewTrack();
  const testId = match?.params.testId || "";

  const currentUser = useCurrentUser();
  const { testName, testWindow, testStatus, isLoading: testNameLoading } = useTestBaseInfo(testId);
  const { platformTest: resultsHeaderTest, isLoading: resultsHeaderLoading } = useResultsHeaderData(testId);
  const { platformTest: tabHeadersTest, isLoading: tabHeadersLoading } = useTabHeadersData(testId);
  const { platformTest: summaryTabTest, isLoading: summaryTabLoading } = useSummaryTabData(testId);
  const { platformTest: testDetailsTabTest, isLoading: testDetailsTabLoading } = useTestDetails(testId);
  const { platformTest: testersTabTest, isLoading: testersTabLoading } = useTestersTabTestData(testId);
  const { issues, isLoading: issuesTabLoading } = useIssuesTabData(testId);
  const { testCases, isLoading: testCasesTabLoading } = useTestCasesTabData(testId);
  const { selectionRequirementsGroups, loading: testersLoading } = useSelectionRequirementsGroups(testId, currentUser);
  const { isLoading: feedbackTabLoading, testerFeedback } = useFeedbackTabData(testId);
  const { isLoading: blockersTabLoading, testBlockers, openTestBlockersCount } = useTestBlockers(testId, currentUser);

  const isOrganizationNameChosen = useIsOrganizationNameChosen();
  const history = useHistory();

  const onTabClicked = (tabName: string) => {
    setSelectedTab(tabName);
    testId && history.push(`${websiteContentPaths.flexcaseResult.replace(":id", testId)}?tab=${tabName}`);
  };

  const newElementEffect = `
    @keyframes highlight {
      0%   { background-color: #fffff; }
      50%  { background-color: #f3f4f5; }
      100% { background-color: #fffff; }
    }
  `;

  useEffect(() => {
    trackPageView("results_details");
  }, []);

  useEffect(() => {
    // Timeout was added to prevent it of being overridden during load. Helmet solution does not work consistently.
    // 100 ms was chosen to make order of tab title overrides more manageable
    setTimeout(() => (document.title = testName || ""), 100);
  }, [testNameLoading]);

  const resultPageTabContents: { [k: string]: JSX.Element | boolean } = {
    summary: (
      <Summary
        platformTest={summaryTabTest}
        isLoading={summaryTabLoading}
        onAllIssuesClick={() => {
          onTabClicked("issues");
        }}
        onAllResultsClicked={() => onTabClicked("test-case-results")}
      />
    ),
    "test-details": (
      <TestDetails platformTest={testDetailsTabTest} testWindow={testWindow} isLoading={testDetailsTabLoading} />
    ),
    testers: (
      <Authorization roles={["testManager", "communityTestManager"]}>
        <Testers
          currentUser={currentUser}
          platformTest={testersTabTest}
          isLoading={testersTabLoading}
          selectionRequirementsGroups={selectionRequirementsGroups}
          testersLoading={testersLoading}
          testStatus={testStatus}
        />
      </Authorization>
    ),
    blockers: (
      <Authorization roles={hasCTMsBlockersAccess ? ["testManager", "communityTestManager"] : ["testManager"]}>
        <TestBlockers testBlockers={testBlockers} />
      </Authorization>
    ),
    "test-case-results": (
      <Authorization roles={["testManager", "customerUser"]}>
        <TestCases testId={testId} testCases={testCases} testWindow={testWindow} isLoading={testCasesTabLoading} />
      </Authorization>
    ),
    issues: (
      <Authorization roles={["testManager", "customerUser"]}>
        <Issues testId={testId} issues={issues} testStatus={testStatus} isLoading={issuesTabLoading} />
      </Authorization>
    ),
    "tester-feedback": (
      <Authorization roles={["testManager", "customerUser"]}>
        <TesterFeedback testWindow={testWindow} isLoading={feedbackTabLoading} testerFeedback={testerFeedback} />
      </Authorization>
    ),
  };

  return (
    <>
      <style children={newElementEffect} />
      <div className="max-w-3xl m-auto" data-testid="result-details-page">
        <ResultsHeader
          testId={testId}
          platformTest={resultsHeaderTest}
          isLoading={resultsHeaderLoading}
          selectionRequirementsGroups={selectionRequirementsGroups}
          testersLoading={testersLoading}
          testWindow={testWindow}
        />
        <TabHeaders
          onTabClicked={onTabClicked}
          selectedTab={selectedTab}
          platformTest={tabHeadersTest}
          platformTestLoading={tabHeadersLoading}
          openBlockersCount={openTestBlockersCount}
          blockersLoading={blockersTabLoading}
        />
        {isOrganizationNameChosen ? resultPageTabContents[selectedTab] : <AskForOrganizationName />}
      </div>
    </>
  );
}

export default ResultDetailsContent;
