import { gql, useMutation } from "@apollo/client";

import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { PublishIssuesAndCaseResultsInput, PublishIssuesAndCaseResultsPayload, Test } from "types/graphql";
import { Participant } from "components/flexible_testing/shared/chat";

export const PUBLISH_ISSUES_AND_CASE_RESULTS = gql`
  mutation PublishIssuesAndCaseResults($input: PublishIssuesAndCaseResultsInput!) {
    publishIssuesAndCaseResults(input: $input) {
      successful
      result {
        id
        status
        resultsPublishable
      }
      errors {
        message
      }
    }
  }
`;

const displaySuccess = (numberOfPublishedResults: number) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: `The publishing of ${
      numberOfPublishedResults > 1 ? "testers'" : "tester"
    } results has been successfully scheduled.`,
  });
};

const displayError = (errorMessage: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: errorMessage,
  });
};

export const usePublishIssuesAndCaseResults = () => {
  const [publishIssuesAndCaseResults, { loading }] = useMutation<
    { publishIssuesAndCaseResults: PublishIssuesAndCaseResultsPayload },
    { input: PublishIssuesAndCaseResultsInput }
  >(PUBLISH_ISSUES_AND_CASE_RESULTS, {
    onCompleted: ({ publishIssuesAndCaseResults: { successful, errors } }, clientOptions) => {
      if (successful) displaySuccess(clientOptions?.variables?.input?.participantIds?.length || 0);
      else {
        const errorMessage = errors.map(({ message }) => message).join(", ");

        displayError(errorMessage);
      }
    },
    onError: () => displayError("There was an issue with publishing tester results"),
  });

  return {
    isLoading: loading,
    publishIssuesAndCaseResults: async (testId: Test["id"], participantIds: Participant["id"][]) => {
      await publishIssuesAndCaseResults({
        variables: { input: { testId, participantIds } },
      });
    },
  };
};
