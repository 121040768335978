import React from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";

import { useLocation } from "react-router";
import { ResultInfo } from "../shared/result_info";

type MatchParams = {
  externalTestId: string;
  vendor: string;
};

type ErrorProps = {
  code: string;
  message: string;
};

type RequestResultProps = {
  launchError: ErrorProps;
};

const VendorTestRequestResultPage = (props: RequestResultProps): JSX.Element => {
  const location = useLocation();
  const requestResults = location.state as RequestResultProps;

  const match = useRouteMatch<MatchParams>("/test-requests/:vendor/:externalTestId");
  const vendor = match?.params?.vendor || "";

  return (
    <>
      <Helmet title="Test Request Result" />

      <ResultInfo error={requestResults?.launchError} vendor={vendor} />
    </>
  );
};

export default VendorTestRequestResultPage;
