import { gql, useQuery } from "@apollo/client";

import { AssignedCommunityTestManager } from "types/graphql";

export const ASSIGNED_COMMUNITY_TEST_MANAGER = gql`
  query getAssignedCommunityTestManager($testId: ID!) {
    assignedCommunityTestManager(testId: $testId) {
      id
      status
      isAssignmentAccepted
    }
  }
`;

type useAssignedCommunityTestManagerType = {
  isLoading: boolean;
  assignedCommunityTestManager?: AssignedCommunityTestManager;
};

export const useAssignedCommunityTestManager = (testId: string): useAssignedCommunityTestManagerType => {
  const { loading, data } = useQuery(ASSIGNED_COMMUNITY_TEST_MANAGER, {
    variables: { testId },
  });

  return {
    isLoading: loading,
    assignedCommunityTestManager: data?.assignedCommunityTestManager,
  };
};
