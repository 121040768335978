import React from "react";

type PreviewFieldProps = {
  label: string;
  children?: React.ReactNode;
};

const PreviewField = ({ label, children }: PreviewFieldProps): JSX.Element => (
  <div className="pb-2xl break-words" style={{ maxWidth: "24rem" }} data-testid="preview-field">
    <div className="pb-xs text-dark text-xs uppercase font-bold tracking-extra-wide">{label}</div>
    <div className="whitespace-pre-wrap" data-testid="preview-field-value">
      {children}
    </div>
  </div>
);

export default PreviewField;
