import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingIssueNoteDestroyInput, FlexibleTestingIssueNoteDestroyPayload } from "types/graphql";

export const DESTROY_ISSUE_NOTE = gql`
  mutation FlexibleTestingIssueNoteDestroy($input: FlexibleTestingIssueNoteDestroyInput!) {
    flexibleTestingIssueNoteDestroy(input: $input) {
      successful
      result {
        id
        issueNote {
          id
          note
          authorName
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

type useIssueNoteDestroyResult = {
  destroyIssueNote: (issueId: string) => Promise<void>;
};

export const useIssueNoteDestroy = (): useIssueNoteDestroyResult => {
  const [destroyIssueNoteMutation] = useMutation<
    { flexibleTestingIssueNoteDestroy: FlexibleTestingIssueNoteDestroyPayload },
    { input: FlexibleTestingIssueNoteDestroyInput }
  >(DESTROY_ISSUE_NOTE);

  return {
    destroyIssueNote: async (issueId: string): Promise<void> => {
      await destroyIssueNoteMutation({
        variables: {
          input: { issueId },
        },
      });
    },
  };
};
