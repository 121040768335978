import React from "react";

import { Spinner } from "@hopper/loading";

const Loader = () => (
  <div className="flex justify-center">
    <Spinner />
  </div>
);

export default Loader;
