import React from "react";

import { FlexibleTestingTestCase } from "types/graphql";
import { Status } from "@hopper/status";

export type TestCasesGroupStatusProps = {
  testCases: Array<FlexibleTestingTestCase>;
  isTestFinished: boolean;
};

export type StatusColor = "success" | "secondary" | "info" | "danger" | "primary" | "warning";

function TestCasesGroupStatus({ testCases, isTestFinished }: TestCasesGroupStatusProps): JSX.Element {
  const color = getStatusColor(testCases, isTestFinished);

  return <Status color={color} />;
}

function getStatusColor(testCases: Array<FlexibleTestingTestCase>, isTestFinished: boolean): StatusColor | undefined {
  const allTestCasesCompleted = testCases.every(isTestCaseCompleted);

  if (!isTestFinished && !allTestCasesCompleted) {
    return "primary";
  }

  const isAnyFiled = testCases.some(testCase => testCase.status === "fail");

  if (isAnyFiled) {
    return "danger";
  }

  const isAnyBlocked = testCases.some(testCase => testCase.status === "blocked");

  if (isAnyBlocked) {
    return "warning";
  }

  const isAnyPassed = testCases.some(testCase => testCase.status === "pass");

  if (isAnyPassed) {
    return "success";
  }

  const noResults = testCases.every(testCase => testCase.status === "not_tested");

  if (noResults) {
    return "secondary";
  }
}

function isTestCaseCompleted(testCase: FlexibleTestingTestCase): boolean {
  return testCase.completedCaseResults?.length === testCase.caseResultsLimit;
}

export default TestCasesGroupStatus;
