import TesterGroupHeader from "components/flexible_testing/result_details_page/testers/tester_group_header";
import TesterGroupParticipant from "components/flexible_testing/result_details_page/testers/tester_group_participant";
import React, { useState } from "react";
import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import STATUS_CONFIG from "./status_config";

const applySorting = (participants: SelectionRequirementsGroup["participants"]) =>
  participants.sort((a, b) => STATUS_CONFIG[a.status].position - STATUS_CONFIG[b.status].position);

type TesterGroupProps = {
  testCasesCount: number;
  group: SelectionRequirementsGroup;
  onSelectAll: () => void;
  onSelectIndividual: (id: string) => void;
  removableTestersIds: Set<string>;
  selectedTesters: Set<string>;
  setSelectionRequirementsGroupForEdit: (group: SelectionRequirementsGroup) => void;
  testStatus: string;
  testName: string;
  testCode: string;
  hasStrictTesterRemoval: boolean;
};

function TesterGroup({
  testCasesCount,
  group,
  onSelectAll,
  onSelectIndividual,
  removableTestersIds,
  selectedTesters,
  setSelectionRequirementsGroupForEdit,
  testStatus,
  testName,
  testCode,
  hasStrictTesterRemoval,
}: TesterGroupProps): JSX.Element {
  const isPublished = group.status === "published";
  const [isCollapsed, setIsCollapsed] = useState(isPublished);
  const participantsExcludingBackup = group.participants.filter(({ status }) => status !== "backup");

  return (
    <>
      <TesterGroupHeader
        isCollapsed={isCollapsed}
        isPublished={isPublished}
        onClick={() => setIsCollapsed(!isCollapsed)}
        isSelected={selectedTesters.size === removableTestersIds.size}
        isSelectingDisabled={removableTestersIds.size === 0}
        onSelect={onSelectAll}
        setSelectionRequirementsGroupForEdit={setSelectionRequirementsGroupForEdit}
        testStatus={testStatus}
        canSelectAll={!hasStrictTesterRemoval}
        {...{ group }}
      />
      {!isCollapsed &&
        applySorting(participantsExcludingBackup).map(participant => (
          <TesterGroupParticipant
            key={participant.id}
            canSelect={!hasStrictTesterRemoval}
            isSelected={selectedTesters.has(participant.id)}
            isSelectingDisabled={!removableTestersIds.has(participant.id)}
            onSelect={onSelectIndividual}
            testName={testName}
            testCode={testCode}
            {...{ participant, testCasesCount }}
          />
        ))}
    </>
  );
}

export default TesterGroup;
