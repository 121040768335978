import { useState, useEffect } from "react";
import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { OrganizationUser } from "types/graphql";

type OrganizationUserResponse = {
  currentOrganizationUser?: OrganizationUser;
};

export const GET_ORGANIZATION_USERS = gql`
  query getOrganizationUsers {
    organizationUsers {
      edges {
        node {
          email
          fullName
        }
      }
    }
  }
`;

export const GET_CURRENT_ORGANIZATION_USER = gql`
  query getCurrentOrganizationUser {
    currentOrganizationUser {
      id
      fullName
      publicId
      firstName
      lastName
      email
      createdAt
      testManager
      communityTestManager
      intercomUserHash
      organization {
        id
        publicId
        name
        chosenName
        isNameChosen
        flexibleTestingAccess
        isServiceTypeSelectionEnabled
        advancedTestsAccess
        creditModelAccess
        newLaunchUxEnabled
        creditAccount {
          balance
        }
      }
      trial {
        id
        finishesAt
        finishedAt
      }
    }
  }
`;

export const GET_CURRENT_ORGANIZATION_USER_CREDIT_ACCOUNT = gql`
  query getCurrentOrganizationUser($cursor: String) {
    currentOrganizationUser {
      id
      publicId
      organization {
        id
        publicId
        creditAccount {
          balance
          transactions(first: 10, after: $cursor) {
            pageInfo {
              hasNextPage
              endCursor
            }
            nodes {
              description
              createdAt
              subject {
                ... on AdvancedTestRequest {
                  name
                  id
                  type
                  url
                }
                ... on FlexibleTestingTest {
                  name
                  id
                  type
                  url
                }
              }
              amount
              balanceAfterTransaction
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CURRENT_ORGANIZATION_USER = gql`
  mutation OrganizationUserUpdate($input: OrganizationUserUpdateInput!) {
    organizationUserUpdate(input: $input) {
      successful
      result {
        id
        fullName
        publicId
        firstName
        lastName
        email
        createdAt
        organization {
          id
          publicId
          name
        }
      }
    }
  }
`;

function useCurrentOrganizationCreditAccount() {
  const { data, fetchMore, networkStatus } = useQuery<OrganizationUserResponse>(
    GET_CURRENT_ORGANIZATION_USER_CREDIT_ACCOUNT,
    { notifyOnNetworkStatusChange: true },
  );
  const [cursor, setCursor] = useState("");

  useEffect(() => {
    setCursor(data?.currentOrganizationUser?.organization?.creditAccount?.transactions?.pageInfo.endCursor || "");
  }, [data]);

  return {
    isLoading: networkStatus === NetworkStatus.loading,
    isLoadingNextPage: networkStatus === NetworkStatus.fetchMore,
    hasNextPage: data?.currentOrganizationUser?.organization.creditAccount?.transactions.pageInfo.hasNextPage,
    cursor: data?.currentOrganizationUser?.organization.creditAccount?.transactions.pageInfo.endCursor,
    balance: data?.currentOrganizationUser?.organization.creditAccount?.balance,
    transactions: data?.currentOrganizationUser?.organization.creditAccount?.transactions.nodes,
    loadNextTransactionsPage: async () =>
      fetchMore({
        variables: { cursor },
        updateQuery: (previousQueryResult, { fetchMoreResult }): OrganizationUserResponse => {
          if (
            fetchMoreResult.currentOrganizationUser?.organization.creditAccount &&
            previousQueryResult.currentOrganizationUser?.organization.creditAccount
          ) {
            const previousTransactions =
              previousQueryResult.currentOrganizationUser.organization.creditAccount.transactions;
            const transactions = fetchMoreResult.currentOrganizationUser?.organization.creditAccount.transactions;
            const pageInfo = transactions.pageInfo;
            const newNodes = transactions.nodes;

            return {
              currentOrganizationUser: {
                ...previousQueryResult.currentOrganizationUser,
                organization: {
                  ...fetchMoreResult?.currentOrganizationUser.organization,
                  creditAccount: {
                    ...fetchMoreResult?.currentOrganizationUser.organization.creditAccount,
                    transactions: {
                      ...previousTransactions,
                      nodes: [...previousTransactions.nodes, ...newNodes],
                      pageInfo,
                    },
                  },
                },
              },
            };
          } else {
            return previousQueryResult;
          }
        },
      }),
  };
}

export { useCurrentOrganizationCreditAccount };
