import { gql, useMutation } from "@apollo/client";
import {
  FlexibleTestingUpdateTestCaseResultResponseInput,
  FlexibleTestingUpdateTestCaseResultResponsePayload,
} from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { TEST_CASE_RESULT_QUERY } from "components/flexible_testing/tester_overview/use_flexible_testing_test_case_result";

const UPDATE_CASE_RESULT_RESPONSE_MUTATION = gql`
  mutation ($input: FlexibleTestingUpdateTestCaseResultResponseInput!) {
    flexibleTestingUpdateTestCaseResultResponse(input: $input) {
      errors {
        message
        code
      }
      successful
    }
  }
`;

const displaySuccess = (fieldLabel: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: `${fieldLabel} has been successfully updated.`,
  });
};

const displayError = (errorMessage: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: errorMessage,
  });
};

type useUpdateCaseResultContentResult = {
  flexibleTestingUpdateCaseResultResponse: (
    caseResultResponseId: string,
    testCaseResultId: string,
    content: string,
  ) => Promise<void>;
  isLoading: boolean;
};

type FieldType = "Status" | "Step" | "String";

const buildValue = (fieldType: FieldType, content: string, fieldName: string | undefined) => {
  switch (fieldType) {
    case "Status":
    case "Step":
      if (!fieldName) throw new Error("Field name is required for status and step fields");

      return { stepValue: { [fieldName]: content } };
    case "String":
      return { stringValue: content };
  }
};

export const useUpdateCaseResultContent = (
  onSuccess: (newContent: string) => void,
  fieldName: string | undefined,
  fieldLabel: string,
  fieldType: FieldType,
): useUpdateCaseResultContentResult => {
  const [flexibleTestingUpdateCaseResultResponseMutation, { loading }] = useMutation<
    { flexibleTestingUpdateTestCaseResultResponse: FlexibleTestingUpdateTestCaseResultResponsePayload },
    { input: FlexibleTestingUpdateTestCaseResultResponseInput }
  >(UPDATE_CASE_RESULT_RESPONSE_MUTATION, {
    onCompleted: ({ flexibleTestingUpdateTestCaseResultResponse: { successful, errors } }, clientOptions) => {
      if (successful) {
        displaySuccess(fieldLabel);
        onSuccess(clientOptions?.context?.content);
      } else {
        const errorMessage = errors.map(({ message }) => message).join(",");
        displayError(errorMessage);
      }
    },
    onError: () => displayError("There was an issue with submitting your changes. Please try again later."),
  });

  return {
    flexibleTestingUpdateCaseResultResponse: async (
      caseResultResponseId: string,
      testCaseResultId: string,
      content: string,
    ): Promise<void> => {
      await flexibleTestingUpdateCaseResultResponseMutation({
        variables: { input: { caseResultResponseId, value: buildValue(fieldType, content, fieldName) } },
        refetchQueries: [{ query: TEST_CASE_RESULT_QUERY, variables: { id: testCaseResultId } }],
        context: { content },
      });
    },
    isLoading: loading,
  };
};
