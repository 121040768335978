import React from "react";
import { Card } from "@hopper/card";

export type CreditUsageRemainderProps = {
  balance: number;
};

const DecoratedBalance = ({ balance }: { balance: number }) => {
  const balanceClassName = balance === 0 ? "text-danger" : "text-body";

  return <div className={`font-bold tracking-wider ${balanceClassName}`}>{balance}</div>;
};

const CreditUsageRemainder = ({ balance }: CreditUsageRemainderProps): JSX.Element => (
  <div className="items-center pb-3xl" style={{ width: "21.5rem" }}>
    <Card active={true}>
      <div className="items-center text-center mt-2xl" style={{ height: "4.5rem" }}>
        <DecoratedBalance balance={balance} />
        <div className="font-bold mt-xs tracking-wider text-xs uppercase text-secondary">Credits remaining</div>
        {balance === 0 && (
          <div className="text-xs text-secondary">
            <span className="fa fa-info-circle mr-xs" aria-hidden="true" />
            Contact your Customer Success Manager to top up
          </div>
        )}
      </div>
    </Card>
  </div>
);

export default CreditUsageRemainder;
