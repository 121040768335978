import { getAuthenticationToken } from "../authentication_token/get_authentication_token";
import { ApiPath } from "../../components/configuration";

function customFetcher(uri, options) {
  const authenticationToken = getAuthenticationToken();

  options.headers.Authorization = `Bearer ${authenticationToken}`;

  return fetch(`${ApiPath}/graphql`, options);
}

export { customFetcher };
