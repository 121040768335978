import React from "react";
import { Link } from "react-router-dom";

export default class NotificationContent extends React.Component {
  render() {
    const {
      notification: {
        details: { link, description },
      },
    } = this.props;
    const pathname = link ? new URL(link).pathname : null;

    return link ? <Link to={pathname}>{description}</Link> : <span>{description}</span>;
  }
}
