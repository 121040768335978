import { useQuery } from "@apollo/client";
import { Test } from "types/graphql";
import { GET_TAB_HEADERS_DATA } from "./tab_headers_query";

function useTabHeadersData(testId?: string) {
  const { loading, data } = useQuery<{
    platformTest: Test;
  }>(GET_TAB_HEADERS_DATA, {
    variables: { testId },
    skip: !testId,
  });

  return {
    isLoading: loading,
    platformTest: data?.platformTest,
  };
}

export { useTabHeadersData };
