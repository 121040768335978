import { gql, useQuery } from "@apollo/client";

import {
  CountryConnection,
  LanguageConnection,
  MobileDeviceConnection,
  OperatingSystemVersionConnection,
  OperatingSystemVersion as GQLOperatingSystemVersion,
  InternetBrowserConnection,
  OperatingSystemDevice,
} from "types/graphql";
import { RequestedTest } from "components/flexible_testing/test_request_page/requested_test_page/requested_test_form";

export type OperatingSystemVersion = Omit<GQLOperatingSystemVersion, "fullName"> & { name: string };

export const GET_ENVIRONMENTS = gql`
  query getEnvironments($platform: TestEnvironmentPlatform, $webPlatform: Boolean!) {
    operatingSystemVersions(filters: { platform_code: $platform }) @skip(if: $webPlatform) {
      edges {
        node {
          id
          name: fullName
          operatingSystemCode
        }
      }
    }
    mobileDevices(filters: { platform_code: $platform }) @skip(if: $webPlatform) {
      edges {
        node {
          id
          name
        }
      }
    }
    countries {
      edges {
        node {
          id
          name
        }
      }
    }
    languages {
      edges {
        node {
          id
          name
        }
      }
    }
    internetBrowsers(availableForPlatform: true) @include(if: $webPlatform) {
      edges {
        node {
          id
          name
        }
      }
    }
    operatingSystemDeviceTypes @include(if: $webPlatform) {
      id
      name
    }
  }
`;

type UseEnvironmentsArguments = {
  skip?: boolean;
  platform?: RequestedTest["applicationType"];
};

export const useEnvironments = ({ skip, platform }: UseEnvironmentsArguments = { skip: false }) => {
  const isWebPlatform = platform === "web";
  const platformFilter = isWebPlatform ? undefined : platform;
  const { data, loading } = useQuery<{
    operatingSystemVersions: OperatingSystemVersionConnection;
    mobileDevices: MobileDeviceConnection;
    countries: CountryConnection;
    languages: LanguageConnection;
    internetBrowsers: InternetBrowserConnection;
    operatingSystemDeviceTypes: [OperatingSystemDevice];
  }>(GET_ENVIRONMENTS, { skip, variables: { platform: platformFilter, webPlatform: isWebPlatform } });

  const operatingSystemVersions = data?.operatingSystemVersions?.edges.map(({ node }) => node) || [];
  const devices = data?.mobileDevices?.edges.map(({ node }) => node) || [];
  const countries = data?.countries?.edges.map(({ node }) => node) || [];
  const languages = data?.languages?.edges.map(({ node }) => node) || [];
  const internetBrowsers = data?.internetBrowsers?.edges.map(({ node }) => node) || [];
  const operatingSystemDeviceTypes = data?.operatingSystemDeviceTypes || [];

  return {
    operatingSystemVersions,
    devices,
    countries,
    languages,
    internetBrowsers,
    operatingSystemDeviceTypes,
    loading,
  };
};
