import React from "react";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";
import pluralize from "pluralize";

// @ts-ignore
import { preset } from "@hopper/styles";

type FailedTestCasesChartProps = {
  totalTestCasesCount: number;
  failedTestCasesCount: number;
};

ChartJS.register(ArcElement);

const FailedTestCasesChart = ({ totalTestCasesCount, failedTestCasesCount }: FailedTestCasesChartProps) => {
  const notFailedTestCasesCount = totalTestCasesCount - failedTestCasesCount;
  const failedTestCasesPercentage =
    totalTestCasesCount && Math.round((failedTestCasesCount / totalTestCasesCount) * 100);

  const { danger, muted } = preset.theme.colors;
  const chartData = {
    datasets: [
      {
        data: [failedTestCasesCount, notFailedTestCasesCount],
        backgroundColor: [danger, muted],
        borderWidth: 0,
      },
    ],
  };

  const cases = pluralize("case", totalTestCasesCount);
  const have = pluralize("have", failedTestCasesCount);

  return (
    <div className="mt-3xl flex">
      <div className="w-[80px]" data-testid="failed-test-cases-chart">
        <Pie data={chartData} />
      </div>
      <div className="ml-lg">
        <div
          className="text-xl font-black text-darkest"
          data-testid="failed-test-cases-summary"
        >{`${failedTestCasesCount} of ${totalTestCasesCount} test ${cases} ${have} failed`}</div>
        <div data-testid="failed-test-cases-percentage">{`${failedTestCasesPercentage}% failure rate`}</div>
      </div>
    </div>
  );
};

export default FailedTestCasesChart;
