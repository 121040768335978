import React from "react";
import { Card } from "@hopper/card";
import {
  TestingServiceCardContent,
  TestingServiceCardHeader,
  TestingServiceCardPricing,
  TestingServiceCardPricingItem,
} from "../testing_service_card";

export default function AdvancedTestingServiceCard(): JSX.Element {
  return (
    <Card active={false}>
      <div className="p-2xl">
        <TestingServiceCardHeader testType="advanced" testDescription="Results in 48 hours" />
        <TestingServiceCardContent
          items={[
            "Our test managers will manage the entire testing process for you",
            "All results are moderated to remove obvious duplicates and invalid bugs",
          ]}
        />
        <TestingServiceCardPricing>
          <TestingServiceCardPricingItem itemTitle="Exploratory testing" price="488 credits*" />
          <TestingServiceCardPricingItem
            itemTitle="Test case execution"
            price="1.25 credits*"
            note="(3+ results per test case)"
          />
        </TestingServiceCardPricing>
        <div className="text-secondary text-xs mt-md italic">
          <span className="text-primary text-xl leading-2xs align-text-bottom mr-xs">*</span>
          <span>The credit price may vary depending on targeting options selected</span>
        </div>
      </div>
    </Card>
  );
}
