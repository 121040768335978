import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "@apollo/client/react/hoc";
import NotificationsSubscription from "./notifications_subscription";
import IssuesSubscription from "../../flexible_testing/graphql_subscriptions/issues_subscription";
import TestsSubscription from "../../flexible_testing/graphql_subscriptions/tests_subscription";
import { GET_CURRENT_ORGANIZATION_USER } from "../../shared/queries/organization_users_query";
import { compose } from "lodash/fp";
import { withCurrentUser } from "../../../app/current_user/with_current_user";

class OrganizationInitializer extends Component {
  render() {
    if (this.isUserLoggedIn()) {
      return (
        <>
          <NotificationsSubscription currentUser={this.props.currentUser} />
          <IssuesSubscription />
          <TestsSubscription />
          {this.props.children}
        </>
      );
    }
  }

  isUserLoggedIn = () => {
    let currentOrganizationUser;

    try {
      currentOrganizationUser = this.props.client.readQuery({ query: GET_CURRENT_ORGANIZATION_USER });
    } catch (e) {
      currentOrganizationUser = null;
    }

    return !!currentOrganizationUser;
  };
}

export default compose(withApollo, withRouter, withCurrentUser)(OrganizationInitializer);
