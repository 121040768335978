import { gql, useQuery } from "@apollo/client";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "../../../../app/notificationsEventBus";
import { CustomerApiKey, CustomerApiKeyConnection } from "types/graphql";

type UseApiKeysProps = {
  onLoadingFinished?: () => void;
};

export type UseApiKeysReturn = {
  isLoading: boolean;
  data: CustomerApiKey[];
};

export const API_KEYS_QUERY = gql`
  query CustomerApiKeys {
    customerApiKeys {
      edges {
        node {
          id
          apiKeyDigest
          createdAt
          active
        }
      }
    }
  }
`;

function useApiKeys({ onLoadingFinished }: UseApiKeysProps): UseApiKeysReturn {
  const { data, loading } = useQuery(API_KEYS_QUERY, { onCompleted: onLoadingFinished, onError: displayError });

  return { isLoading: loading, data: getData(data) ?? [] };
}

function getData(data: { customerApiKeys: CustomerApiKeyConnection }): CustomerApiKey[] {
  const edges = data?.customerApiKeys.edges || [];

  return edges.map(edge => edge.node);
}

function displayError() {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with loading api key list, please try again later",
  });
}

export default useApiKeys;
