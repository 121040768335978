import React from "react";
import { TestStats } from "types/graphql";
import TestCasesSummary from "./test_cases_summary";
import { Button } from "@hopper/button";

type TesterResultsSummaryProps = {
  testStats?: TestStats;
  onAllResultsClicked: () => void;
};

function TestCasesSection({ testStats, onAllResultsClicked }: TesterResultsSummaryProps): JSX.Element {
  const testerResults = testStats?.testersResults || [];
  const testCasesTotalCount = testStats?.testCasesTotalCount || 0;

  return (
    <div data-testid="test-cases-section">
      <TestCasesSummary
        participantsTotalCount={testStats?.participantsTotalCount || 0}
        testCasesTotalCount={testCasesTotalCount}
        testCasesFailedCount={testStats?.testCasesFailedCount || 0}
        testerResults={testerResults}
      />
      {testerResults.length > 0 && testCasesTotalCount > 0 && (
        <Button
          className="my-sm px-0"
          variant="primary"
          appearance="minimal"
          onClick={onAllResultsClicked}
          type="button"
          data-testid="view-all-case-results-button"
        >
          View all test case results
        </Button>
      )}
    </div>
  );
}

export default TestCasesSection;
