import React from "react";
import { Button } from "@hopper/button";
import classnames from "classnames";

import { getIntegrationConfigByCode } from "app/config/integrations";
import styles from "./style.module.css";
import { IntegrationTypes } from "./export_destinations";

export type IntegrationType = {
  code: IntegrationTypes;
  name?: string;
};

type ExportIntegrationCardProps = {
  integration: IntegrationType;
  inactive: boolean;
  onIntegrationSelect: (code: IntegrationTypes) => void;
};

function ExportIntegrationCard({
  integration,
  inactive,
  onIntegrationSelect,
}: ExportIntegrationCardProps): JSX.Element {
  function integrationConfig() {
    return getIntegrationConfigByCode(integration.code);
  }

  function buttonText(): string {
    const integrationName = integration.name;

    if (integrationName) {
      return inactive ? `Integrate with ${integrationName}` : `Export to ${integrationName}`;
    } else {
      return "Download as .csv";
    }
  }

  function renderIcon(): JSX.Element {
    const config = integrationConfig();
    const image_src = config.image_src;
    const fillColor = inactive ? "#D1D6D9" : config.defaultColor;

    if (image_src) {
      return (
        <div className="p-sm text-center">
          <svg viewBox={config.viewBoxAlt} preserveAspectRatio="xMinYMin meet" className="h-3xl mx-auto block">
            <use xlinkHref={`${image_src}#logo`} fill={fillColor} />
          </svg>
        </div>
      );
    } else {
      return (
        <div className="py-sm text-center">
          <i className="fa fa-download text-secondary text-3xl" />
        </div>
      );
    }
  }

  function handleIntegrationSelect(): void {
    onIntegrationSelect(integration.code);
  }

  return (
    <div className="lg:mx-xs flex-1 mt-lg px-0 border-2 border-light rounded-base">
      <div className={classnames("flex items-center justify-around", styles.integrationBox)}>
        <div>
          {renderIcon()}
          <div className="text-primary text-sm text-center">
            {inactive ? (
              <Button variant="primary" appearance="minimal" className="mt-md" onClick={handleIntegrationSelect}>
                {buttonText()}
              </Button>
            ) : (
              <Button data-testid="export-button" variant="success" className="mt-lg" onClick={handleIntegrationSelect}>
                {buttonText()}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportIntegrationCard;
