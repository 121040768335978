import { useAuth0, LogoutOptions } from "@auth0/auth0-react";

function useLogout() {
  const { logout } = useAuth0();

  return {
    logout: (options?: LogoutOptions | undefined) => {
      localStorage.setItem("isAuthenticated", "false");

      logout(options);
    },
  };
}

export { useLogout };
