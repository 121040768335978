import React from "react";
import classnames from "classnames";
import { Heading } from "@hopper/heading";

type FieldProps = {
  label: string;
  testId: string;
  children: React.ReactNode;
  rightContent?: React.ReactNode | string;
  paddingClass?: string;
  postfixLabel?: React.ReactNode | string;
};

function DetailsField({
  testId,
  label,
  children,
  rightContent,
  postfixLabel,
  paddingClass = "lg:px-md",
}: FieldProps): JSX.Element {
  return (
    <div className={classnames("my-sm", paddingClass)} data-testid={testId}>
      <div data-testid="details-field-title" className="flex">
        <div className="flex">
          <Heading size={5}>{label}</Heading>
        </div>
        <div className="flex pl-sm">{postfixLabel}</div>
        <div className="text-sm flex-1 text-right">{rightContent}</div>
      </div>
      <div data-testid="details-field-content" className="flex flex-col">
        {children}
      </div>
    </div>
  );
}

export default DetailsField;
