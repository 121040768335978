import React, { useEffect, useState } from "react";
import { Button } from "@hopper/button";
import { Loader } from "@hopper/loading";
import {
  DesiredOutcomeCode,
  FlexibleTestingTestDesiredOutcome,
  SoftwareDevelopmentLifeCycleStageName,
} from "types/graphql";
import { ExpressTestingServiceCard } from "../../express_testing_service_card";
import { AdvancedTestingServiceCard } from "../../advanced_testing_service_card";
import { DesiredOutcomeItem, DesiredOutcomeOption } from "./desired_outcome_item";
import { CustomDesiredOutcome } from "./custom_desired_outcome";
import { useTestSdlcStage } from "./use_test_sdlc_stage";
import { StageHeader } from "components/flexible_testing/test_request_page/launch_wizard_content/stage_header";

type DesiredOutcomesSelectionWizardStepProps = {
  sdlcStageName: SoftwareDevelopmentLifeCycleStageName;
  initialDesiredOutcomeCodes: Array<DesiredOutcomeCode>;
  customDesiredOutcomes: string;
  onBackButtonClick: () => void;
  onContinueButtonClick: (
    selectedDesiredOutcomes: Array<DesiredOutcomeCode>,
    customDesiredOutcomes: string,
    isExpressTestDisabled: boolean,
  ) => void;
};

export function DesiredOutcomesSelectionWizardStep({
  sdlcStageName,
  initialDesiredOutcomeCodes,
  customDesiredOutcomes,
  onBackButtonClick,
  onContinueButtonClick,
}: DesiredOutcomesSelectionWizardStepProps): JSX.Element {
  const { desiredOutcomes, isLoading } = useTestSdlcStage(sdlcStageName);
  const [isButtonEnabled, setIsButtonEnabled] = useState(
    customDesiredOutcomes || initialDesiredOutcomeCodes.length > 0,
  );
  const [isExpressTestDisabled, setIsExpressTestDisabled] = useState(false);
  const [isCustomDesiredOutcomeChecked, setIsCustomDesiredOutcomeChecked] = useState(!!customDesiredOutcomes);
  const [customDesiredOutcomeValue, setCustomDesiredOutcomeValue] = useState(customDesiredOutcomes);
  const [selectedDesiredOutcomes, setSelectedDesiredOutcomes] =
    useState<Array<DesiredOutcomeCode>>(initialDesiredOutcomeCodes);
  const [desiredOutcomeOptions, setDesiredOutcomeOptions] = useState<DesiredOutcomeOption[]>([]);

  useEffect(() => {
    if (!isLoading) {
      setDesiredOutcomeOptions(initDesiredOutcomeOptions(desiredOutcomes, initialDesiredOutcomeCodes));
    }
  }, [isLoading]);

  useEffect(updateExpressTestDisability, [desiredOutcomeOptions, isCustomDesiredOutcomeChecked]);

  useEffect(updateButtonAvailability, [
    isCustomDesiredOutcomeChecked,
    customDesiredOutcomeValue,
    selectedDesiredOutcomes,
  ]);

  function updateExpressTestDisability() {
    const isAnyDesiredOutcomesUnavailableOnStandard = desiredOutcomeOptions.some(
      desiredOutcome => desiredOutcome.isChecked && !desiredOutcome.isAvailableOnStandard,
    );

    setIsExpressTestDisabled(isAnyDesiredOutcomesUnavailableOnStandard || isCustomDesiredOutcomeChecked);
  }

  function updateButtonAvailability() {
    const isCustomDesiredOutcomeValid = isCustomDesiredOutcomeChecked && !!customDesiredOutcomeValue;
    const isOnlyDesiredOutcomesSelected = !isCustomDesiredOutcomeChecked && selectedDesiredOutcomes.length > 0;

    setIsButtonEnabled(isCustomDesiredOutcomeValid || isOnlyDesiredOutcomesSelected);
  }

  function onCheckboxChange(desiredOutcomeCode: DesiredOutcomeCode) {
    const desiredOutcomes = desiredOutcomeOptions.map(desiredOutcomeOption => {
      if (desiredOutcomeOption.code === desiredOutcomeCode) {
        return { ...desiredOutcomeOption, isChecked: !desiredOutcomeOption.isChecked };
      } else {
        return desiredOutcomeOption;
      }
    });

    setSelectedDesiredOutcomes(desiredOutcomes.filter(item => item.isChecked).map(item => item.code));
    setDesiredOutcomeOptions(desiredOutcomes);
  }

  return isLoading ? (
    <Loader isLoaded={!isLoading} />
  ) : (
    <div className="grid grid-cols-12" data-testid="desired-outcomes-selection-wizard-step">
      <div className="col-span-9">
        <StageHeader sdlcStageName={sdlcStageName} onBackButtonClick={onBackButtonClick} />
        <div className="text-darkest text-base font-bold">What would you like to test?</div>
        <div className="text-body pt-2xl">
          {desiredOutcomeOptions.map(desiredOutcomeOption => (
            <DesiredOutcomeItem desiredOutcomeOption={desiredOutcomeOption} onCheckboxChange={onCheckboxChange} />
          ))}
          <CustomDesiredOutcome
            isChecked={isCustomDesiredOutcomeChecked}
            value={customDesiredOutcomeValue}
            onCheckboxChange={() => setIsCustomDesiredOutcomeChecked(!isCustomDesiredOutcomeChecked)}
            onValueChange={setCustomDesiredOutcomeValue}
          />
        </div>
        <div className="pt-xl">
          <Button
            data-testid="desired-outcomes-selection-wizard-continue-button"
            disabled={!isButtonEnabled}
            onClick={() =>
              onContinueButtonClick(selectedDesiredOutcomes, customDesiredOutcomeValue, isExpressTestDisabled)
            }
          >
            Continue
          </Button>
        </div>
      </div>
      <div className="col-span-3">
        <ExpressTestingServiceCard isDisabled={isExpressTestDisabled} />
        <AdvancedTestingServiceCard />
      </div>
    </div>
  );
}

function initDesiredOutcomeOptions(
  desiredOutcomes: FlexibleTestingTestDesiredOutcome[],
  initialDesiredOutcomeCodes: Array<string>,
): DesiredOutcomeOption[] {
  return desiredOutcomes.map(desiredOutcome => ({
    isChecked: initialDesiredOutcomeCodes.includes(desiredOutcome.code),
    name: desiredOutcome.name,
    code: desiredOutcome.code,
    description: desiredOutcome.description,
    isAvailableOnStandard: desiredOutcome.isAvailableOnStandard,
  }));
}
