import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { ResultDetailsPage } from "./result_details_page";
import { ResultsPage } from "./results_page";
import NotFoundPage from "components/not_found_page";
import { TestCaseResultPage } from "components/flexible_testing/test_case_result_page";
import { IssuePage } from "components/flexible_testing/issue_page";
import { AutoExportSettingsPage } from "./auto_export_settings_page";
import { websiteContentPaths } from "components/configuration";
import { TesterOverview } from "./tester_overview";
import { ChatClientContextProvider } from "./shared/chat/chat_client_context";

const IssuePageWrapper = (props: RouteComponentProps) => (
  <IssuePage
    {...props}
    returnButton={{
      label: "Back to Test Run Results",
      url: `/results/${(props.match.params as { testId: string }).testId}`,
    }}
  />
);

function FlexcaseResultsSection(): JSX.Element {
  return (
    <ChatClientContextProvider>
      <Switch>
        <Route exact path="/results" component={ResultsPage} />
        <Route exact path="/results/:id" component={ResultDetailsPage} />
        <Route exact path={websiteContentPaths.flexcaseParticipantResult} component={TesterOverview} />
        <Route path={websiteContentPaths.flexcaseTestCase} component={TestCaseResultPage} />
        <Route path={websiteContentPaths.flexcaseIssueFromResults} component={IssuePageWrapper} />
        <Route exact path={websiteContentPaths.flexcaseAutoExportSettings} component={AutoExportSettingsPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </ChatClientContextProvider>
  );
}

export default FlexcaseResultsSection;
