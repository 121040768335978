import { ProfessionalService } from "types/graphql";
import { gql, useQuery } from "@apollo/client";

export const PROFESSIONAL_SERVICE_QUERY = gql`
  query getProfessionalService($id: ID!) {
    professionalService(id: $id) {
      id
      title
      requestFormInstructions
    }
  }
`;

type ProfessionalServiceResult = {
  professionalService: ProfessionalService;
  isLoading: boolean;
};

export const useProfessionalService = (id: string): ProfessionalServiceResult => {
  const { data, loading } = useQuery(PROFESSIONAL_SERVICE_QUERY, { variables: { id } });

  return {
    professionalService: data?.professionalService,
    isLoading: loading,
  };
};
