import React, { useState } from "react";
import { Button } from "@hopper/button";
import { Checkbox } from "@hopper/checkbox";
import { Modal, ModalBody, ModalHeader } from "@hopper/legacy_modal";

import { Test } from "types/graphql";
import { Participant } from "components/flexible_testing/shared/chat";
import { Warning } from "components/flexible_testing/shared/warning";
import { usePublishIssuesAndCaseResults } from "components/flexible_testing/shared/use_publish_issues_and_case_results";

const WARNING_MAP = {
  resultsNotApproved: {
    warningContents: (
      <>
        This tester's results are not <span className="font-bold">Approved</span>, yet
      </>
    ),
    checkboxLabel:
      "I understand that I am publishing unmoderated test case results and only accepted issues. Unmoderated issues cannot be published later",
  },
  issuesNotModerated: {
    warningContents: "This tester has unmoderated issues",
    checkboxLabel:
      "I understand that I am publishing approved test case results and only accepted issues. Unmoderated issues cannot be published later",
  },
} as const;

type PublishTesterResultsModalProps = {
  onClose: () => void;
  isOpen: boolean;
  participant: Participant;
  testId: Test["id"];
};

export const PublishTesterResultsModal = ({ onClose, isOpen, participant, testId }: PublishTesterResultsModalProps) => {
  const resultsApproved = participant.status === "results_approved";
  const issuesModerated = participant.numberOfPendingIssueModerations === 0;
  const resultsReady = resultsApproved && issuesModerated;
  const warningKey = !resultsApproved ? "resultsNotApproved" : !issuesModerated ? "issuesNotModerated" : null;
  const [publishButtonEnabled, setPublishButtonEnabled] = useState(resultsReady);
  const { publishIssuesAndCaseResults, isLoading } = usePublishIssuesAndCaseResults();

  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={isOpen}
      position="center"
      size="md"
      onOverlayClicked={() => {}}
    >
      <div data-testid="publish-tester-results-modal">
        <ModalHeader>Publish the results and issues from this tester</ModalHeader>
        <ModalBody className="flex flex-col h-full overflow-hidden gap-lg">
          <p>Are you sure? This action cannot be undone</p>
          {resultsReady && (
            <div className="flex gap-sm">
              <p>✅</p>
              <p>
                This tester has a complete set of approved results and all of their issues have been moderated. Only
                accepted issues will be published
              </p>
            </div>
          )}
          {warningKey && (
            <div className="flex flex-col gap-sm">
              <Warning>{WARNING_MAP[warningKey].warningContents}</Warning>
              <Checkbox
                onChange={() => setPublishButtonEnabled(!publishButtonEnabled)}
                isChecked={publishButtonEnabled}
                label={WARNING_MAP[warningKey].checkboxLabel}
                position="top"
              />
            </div>
          )}
        </ModalBody>
        <div className="flex justify-end mt-2xl gap-md">
          <Button
            onClick={() => publishIssuesAndCaseResults(testId, [participant.id])}
            disabled={!publishButtonEnabled}
            isLoading={isLoading}
          >
            Publish
          </Button>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
