import React from "react";
import classnames from "classnames";

type HeadingNumberProps = {
  children: React.ReactNode;
  className?: string;
  backgroundType?: string;
  testId?: string;
};

function HeadingNumber({ children, className, backgroundType, testId = "heading-number" }: HeadingNumberProps) {
  const backgroundColor = backgroundType ? `bg-${backgroundType}` : "bg-light";
  const textColor = backgroundType ? "text-lightest" : "text-secondary";

  return (
    <span className={`inline-flex rounded-base ${backgroundColor} self-center`} data-testid={testId}>
      <span className={classnames(`text-xs ${textColor} leading-xs font-bold px-sm py-xs`, className)}>{children}</span>
    </span>
  );
}

export default HeadingNumber;
