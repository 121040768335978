import React from "react";
import { DecoratedText } from "@hopper/decorated_text";

import { FlexibleTestingStringCaseField, FlexibleTestingStringCaseResultFieldResponse } from "types/graphql";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { EditButton } from "components/flexible_testing/shared/case_result/edit_button";
import { Events } from "components/configuration";

export type AdditionalInformationTextResponse = {
  _id: FlexibleTestingStringCaseResultFieldResponse["_id"];
  id: FlexibleTestingStringCaseResultFieldResponse["id"];
  value: FlexibleTestingStringCaseResultFieldResponse["value"];
  label: FlexibleTestingStringCaseField["value"];
};

type AdditionalInformationResponseProps = {
  resultFieldResponse: AdditionalInformationTextResponse;
  testCaseResultId: string;
  isCaseResultEditingDisabled: boolean;
};

const AdditionalInformationTextResponseDetail = ({
  resultFieldResponse,
  testCaseResultId,
  isCaseResultEditingDisabled,
}: AdditionalInformationResponseProps) => {
  const label = resultFieldResponse.label || "";

  return (
    <>
      <DetailsField label={label} paddingClass="pt-lg" testId="additional-information-response">
        <DecoratedText text={resultFieldResponse.value || "-"} />
      </DetailsField>
      <EditButton
        fieldType="String"
        fieldLabel={label}
        caseResultResponseId={resultFieldResponse.id}
        initialValue={resultFieldResponse.value || ""}
        testCaseResultId={testCaseResultId}
        isEditButtonDisabled={isCaseResultEditingDisabled}
        trackingCaseResultFieldResponseId={resultFieldResponse._id}
        trackingEventName={Events.ADDITIONAL_INFORMATION_RESPONSE_RESULT_EDITED}
      />
    </>
  );
};

type AdditionalInformationResponsesProps = {
  resultFieldResponses: AdditionalInformationTextResponse[];
  testCaseResultId: string;
  isCaseResultEditingDisabled: boolean;
};

export const AdditionalInformationTextResponses = ({
  resultFieldResponses,
  testCaseResultId,
  isCaseResultEditingDisabled,
}: AdditionalInformationResponsesProps) => {
  if (!resultFieldResponses || resultFieldResponses.length === 0) return null;

  return (
    <div className="mt-lg" data-testid="text-based-additional-information-responses">
      {resultFieldResponses.map(response => (
        <AdditionalInformationTextResponseDetail
          key={response.id}
          resultFieldResponse={response}
          testCaseResultId={testCaseResultId}
          isCaseResultEditingDisabled={isCaseResultEditingDisabled}
        />
      ))}
    </div>
  );
};
