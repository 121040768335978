import React from "react";
import { Button } from "@hopper/button";
import classnames from "classnames";

type ResetButtonProps = {
  isActive: boolean;
  onResetIntegration: () => void;
  usesCardComponent: boolean;
};

const ResetButton = ({ isActive, onResetIntegration, usesCardComponent }: ResetButtonProps) => (
  <>
    {isActive && (
      <div
        style={{ width: "85%" }}
        className={classnames("border-t border-light pt-4xl", usesCardComponent && "ml-4xl")}
      >
        <Button variant="secondary" appearance="ghost" onClick={onResetIntegration}>
          Reset this integration
        </Button>
        <div style={{ width: "30%" }} className="mt-sm text-dark text-sm">
          This will revoke the authentication and make the integration inactive
        </div>
      </div>
    )}
  </>
);

export default ResetButton;
