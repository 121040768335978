import React from "react";
import classnames from "classnames";

type CasesStatsLabelProps = {
  text: string;
  withDivider?: boolean;
};

function CasesStatsLabel({ text, withDivider }: CasesStatsLabelProps): JSX.Element {
  return (
    <span
      className={classnames("text-xs font-bold text-dark", withDivider && "mr-sm pr-sm border-r-2 border-light")}
      data-testid="cases-stats-label"
    >
      {text}
    </span>
  );
}

export default CasesStatsLabel;
