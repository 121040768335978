import React from "react";
import { FormInlineError } from "@hopper/form_inline_error";

import { ControlledMultiselect } from "./controlled_multiselect";
import classNames from "classnames";

export type Options = Array<{ label: string; value: string; isSelected: boolean }>;

export type MultiSelectProps = {
  label: string;
  options: Options;
  errorMessage: string | undefined;
  placeholder: string;
  onChange: (selectedValues: Array<string>) => void;
  displayPrimaryButton?: boolean;
  style?: React.CSSProperties;
  labelClassNames?: string;
  selectedItemsLabel?: string;
};

export const MultiSelect = ({
  label,
  options,
  placeholder,
  errorMessage,
  onChange,
  style = {},
  displayPrimaryButton = false,
  labelClassNames = "",
  selectedItemsLabel,
}: MultiSelectProps) => (
  // INFO: By default clicking on label focuses input, in this case selecting option
  // will do the same and thus scroll list to the top. This is workaround.
  // Jira: https://gatteam.atlassian.net/browse/TEAMX-3288
  <label onClick={e => e.preventDefault()} className="w-full">
    <div className={classNames("text-sm mb-sm", labelClassNames)}>{label}</div>

    <div className="text-base">
      <div style={{ minWidth: "24rem", ...style }}>
        <ControlledMultiselect
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          displayPrimaryButton={displayPrimaryButton}
          selectedItemsLabel={selectedItemsLabel}
        />
      </div>
    </div>

    {errorMessage && (
      <div className="mt-sm">
        <FormInlineError>{errorMessage}</FormInlineError>
      </div>
    )}
  </label>
);
