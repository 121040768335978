import { useQuery } from "@apollo/client";
import { GET_TEST_BASE_INFO } from "./test_base_info_query";

function useTestBaseInfo(testId?: string) {
  const { loading, data } = useQuery(GET_TEST_BASE_INFO, {
    variables: { testId },
    skip: !testId,
  });

  const platformTest = data?.platformTest;

  return {
    isLoading: loading,
    testName: platformTest?.name,
    testWindow: platformTest?.testWindow,
    testStatus: platformTest?.testWindow?.status,
  };
}

export { useTestBaseInfo };
