import { Heading } from "@hopper/heading";
import { Button } from "@hopper/button";
import { Checkbox } from "@hopper/checkbox";
import { Header, HeaderCell, Table } from "components/flexible_testing/shared/table";
import TesterGroupHeader from "components/flexible_testing/result_details_page/testers/tester_group_header";
import ParticipantRow from "components/flexible_testing/shared/participants_panel/participant_row";
import { Panel } from "@hopper/panel";
import React, { useEffect, useState } from "react";
import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import { TestParticipationStatus } from "types/app";

type ParticipantsPanelProps = {
  testId?: string;
  show: boolean;
  onClose: () => void;
  isLoading: boolean;
  onActionButtonPress: () => void;
  actionButtonLabel: string;
  selectionRequirementsGroups: SelectionRequirementsGroup[];
  collapsedSelectionRequirementsGroupIds: string[];
  allowedParticipantStatuses: Readonly<TestParticipationStatus[]>;
  onParticipantSelectionChange: (ids: string[]) => void;
  title: string;
  "data-testid"?: string;
};

function ParticipantsPanel({
  testId,
  show,
  onClose,
  isLoading,
  onActionButtonPress,
  actionButtonLabel,
  selectionRequirementsGroups,
  collapsedSelectionRequirementsGroupIds,
  allowedParticipantStatuses,
  onParticipantSelectionChange,
  title,
  "data-testid": dataTestId,
}: ParticipantsPanelProps): JSX.Element {
  const [collapsedGroupIds, setCollapsedGroupIds] = useState<string[]>([]);
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState<string[]>([]);

  useEffect(() => {
    onParticipantSelectionChange(selectedParticipantsIds);
  }, [selectedParticipantsIds]);

  useEffect(() => {
    if (isLoading === false) setSelectedParticipantsIds([]);
  }, [isLoading]);

  useEffect(() => {
    setCollapsedGroupIds(collapsedSelectionRequirementsGroupIds);
  }, [collapsedSelectionRequirementsGroupIds]);

  const toggleGroup = (groupId: string) => {
    if (collapsedGroupIds.includes(groupId)) {
      setCollapsedGroupIds(collapsedGroupIds.filter(id => id !== groupId));
    } else {
      setCollapsedGroupIds([...collapsedGroupIds, groupId]);
    }
  };

  const handleSelectSingleParticipant = (id: string): void => {
    if (selectedParticipantsIds.includes(id)) {
      setSelectedParticipantsIds(prevState => prevState.filter(participantId => participantId !== id));
    } else {
      setSelectedParticipantsIds(prevState => prevState.concat(id));
    }
  };

  const allowedParticipants = selectionRequirementsGroups
    .map(group => group.participants.filter(({ status }) => allowedParticipantStatuses.includes(status)))
    .flat();

  function handleSelectAll() {
    if (selectedParticipantsIds.length !== allowedParticipants.length) {
      setSelectedParticipantsIds(allowedParticipants.map(({ id }) => id));
    } else {
      setSelectedParticipantsIds([]);
    }
  }

  return (
    <Panel isOpen={show} hasOverlay={true} onOverlayClick={onClose} onCloseButtonClick={onClose} maxWidth="52rem">
      <div className="p-4xl pt-0 relative overflow-hidden h-screen flex flex-col" data-testid={dataTestId}>
        <Heading size={2}>{title}</Heading>
        <div className="pb-sm text-sm" data-testid="participants-panel-action-button">
          <Button
            size="sm"
            isLoading={isLoading}
            onClick={onActionButtonPress}
            disabled={selectedParticipantsIds.length === 0}
          >
            {actionButtonLabel}
          </Button>
        </div>
        <Heading size={3}>Testers</Heading>
        <div data-testid="participants-panel-selectall">
          <Checkbox
            label="Select all"
            onChange={() => handleSelectAll()}
            disabled={!allowedParticipants.length || !selectionRequirementsGroups.length}
            isChecked={selectedParticipantsIds.length === allowedParticipants.length}
          />
        </div>

        <div className="overflow-x-hidden">
          <Table className="table-fixed">
            <Header>
              <HeaderCell style={{ width: "5%" }} />
              <HeaderCell style={{ width: "70%" }} />
              <HeaderCell style={{ width: "25%" }} />
            </Header>
            {selectionRequirementsGroups.map(group => {
              const isPublished = group.status === "published";
              const isGroupCollapsed = collapsedGroupIds.includes(group.id);
              const relevantParticipants = group.participants.filter(({ status }) => status !== "backup");

              return (
                <React.Fragment key={group.id}>
                  <TesterGroupHeader
                    isSelected={group.participants.every(participant =>
                      selectedParticipantsIds.includes(participant.id),
                    )}
                    isCollapsed={isGroupCollapsed}
                    isPublished={isPublished}
                    group={group}
                    onClick={() => toggleGroup(group.id)}
                  />
                  {!isGroupCollapsed &&
                    relevantParticipants.map(participant => (
                      <ParticipantRow
                        key={participant.id}
                        testId={testId}
                        participant={participant}
                        handleSelectSingleParticipant={handleSelectSingleParticipant}
                        selectedParticipantsIds={selectedParticipantsIds}
                        allowedParticipantStatuses={allowedParticipantStatuses}
                      />
                    ))}
                </React.Fragment>
              );
            })}
          </Table>
        </div>
      </div>
    </Panel>
  );
}

export default ParticipantsPanel;
