import React from "react";

type MaxRequestedTestsInfoProps = {
  maxRequestedTests: number;
};

export const MaxRequestedTestsInfo = ({ maxRequestedTests }: MaxRequestedTestsInfoProps) => (
  <div className="p-md text-sm bg-light border-l-2 rounded border-primary w-full lg:w-[55%] md:w-[75%] mb-3xl -mt-2xl">
    {`You have reached the limit of ${maxRequestedTests} tests for a single request. ` +
      "To request additional test activities, please launch this test and then create a new request."}
  </div>
);
