import React, { useEffect, useState } from "react";
import { useTracking } from "react-tracking";
import { SoftwareDevelopmentLifeCycleStageName, DesiredOutcomeCode } from "types/graphql";

import { StandardTestContent, StandardTestInfoSection } from "../standard_test_content";
import { AdvancedTestContent } from "../advanced_test_content/advanced_test_content";
import { PreviewPageAdvancedTestRequest, PreviewPageStandardTestRequest } from "../../preview_page/preview_page";
import {
  CapabilitySelectionWizardStep,
  DesiredOutcomesSelectionWizardStep,
  SdlcSelectionWizardStep,
} from "./launch_wizard_steps";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import HeaderSection from "components/flexible_testing/test_request_page/header_section";
import { LaunchWizardCloneContainer } from "components/flexible_testing/test_request_page/launch_wizard_clone_container";
import { StageHeader } from "components/flexible_testing/test_request_page/launch_wizard_content/stage_header";
import { AdvancedTestInfoSection } from "components/flexible_testing/test_request_page/advanced_test_content/advanced_test_info_section";
import { useCurrentUser } from "app/current_user/use_current_user";

export const launchWizardSteps = {
  SdlcSelection: "SdlcSelection",
  DesiredOutcomesSelection: "DesiredOutcomesSelection",
  CapabilitySelection: "CapabilitySelection",
  StandardTestForm: "StandardTestForm",
  AdvancedTestForm: "AdvancedTestForm",
} as const;

type StepKey = keyof typeof launchWizardSteps;
export type FormSteps = Extract<
  typeof launchWizardSteps.StandardTestForm | typeof launchWizardSteps.AdvancedTestForm,
  StepKey
>;

type LaunchWizardStep = {
  key: StepKey;
  component: JSX.Element;
};

export type LaunchWizardContentProps = {
  initWithStep: FormSteps | null;
  initWithSdlcStage?: SoftwareDevelopmentLifeCycleStageName;
  testRequest?: PreviewPageStandardTestRequest | PreviewPageAdvancedTestRequest;
};

export function LaunchWizardContent({
  testRequest,
  initWithStep,
  initWithSdlcStage,
}: LaunchWizardContentProps): JSX.Element {
  const tracking = useTracking();
  const currentUser = useCurrentUser();

  const firstWizardStep: StepKey = launchWizardSteps.SdlcSelection;
  const [currentStep, setCurrentStep] = useState<StepKey>(initWithStep || firstWizardStep);
  const initialCapabilities: string[] =
    (testRequest && "capabilities" in testRequest && testRequest.capabilities) || [];
  const [capabilities, setCapabilities] = useState<string[]>(initialCapabilities);
  const [desiredOutcomes, setDesiredOutcomes] = useState<Array<DesiredOutcomeCode>>(testRequest?.desiredOutcomes || []);
  const initialCustomDesiredOutcome: string =
    (testRequest && "customDesiredOutcomes" in testRequest && testRequest.customDesiredOutcomes) || "";
  const [customDesiredOutcomes, setCustomDesiredOutcomes] = useState<string>(initialCustomDesiredOutcome);
  const [sdlcStage, setSdlcStage] = useState<SoftwareDevelopmentLifeCycleStageName | undefined>(
    testRequest?.sdlcStage || initWithSdlcStage,
  );
  const [hasExpressTestDisabled, setHasExpressTestDisabled] = useState<boolean>(false);
  const shouldShowCloneButton = (
    [
      launchWizardSteps.SdlcSelection,
      launchWizardSteps.DesiredOutcomesSelection,
      launchWizardSteps.CapabilitySelection,
    ] as StepKey[]
  ).includes(currentStep);
  const shouldShowInfoSectionOnFormSteps = !sdlcStage;

  useEffect(trackStep, [sdlcStage]);

  function onSdlcCardSelect(sdlcStageName: SoftwareDevelopmentLifeCycleStageName) {
    setSdlcStage(sdlcStageName);
    setCurrentStep("DesiredOutcomesSelection");
  }

  function onContinueDesiredOutcomesButtonClick(
    desiredOutcomes: Array<DesiredOutcomeCode>,
    customDesiredOutcomes: string,
    isExpressTestDisabled: boolean,
  ) {
    setDesiredOutcomes(desiredOutcomes);
    setCustomDesiredOutcomes(customDesiredOutcomes);
    setHasExpressTestDisabled(isExpressTestDisabled);
    setCurrentStep(launchWizardSteps.CapabilitySelection);
  }

  function onDesiredOutcomesBackButtonClick() {
    setHasExpressTestDisabled(false);
    setSdlcStage(undefined);
    setDesiredOutcomes([]);
    setCustomDesiredOutcomes("");
    setCurrentStep(launchWizardSteps.SdlcSelection);
  }

  function onCapabilitySelectionBackButtonClick() {
    setCapabilities([]);
    setCurrentStep(launchWizardSteps.DesiredOutcomesSelection);
  }

  function onTestBackButtonClick() {
    const previousStep =
      sdlcStage && (desiredOutcomes.length > 0 || customDesiredOutcomes)
        ? launchWizardSteps.CapabilitySelection
        : firstWizardStep;

    setCurrentStep(previousStep);
  }

  function trackStep() {
    if (!!currentUser) {
      tracking.trackEvent({
        action: "track",
        name: `${currentStep}LaunchWizardStepEntered`,
        eventProperties: {
          user_id: currentUser.publicId,
          organization_id: currentUser.organization.publicId,
          capabilities: capabilities.join(", "),
          desired_outcomes: desiredOutcomes.join(", "),
          custom_desired_outcomes: customDesiredOutcomes,
          sdlc_stage: sdlcStage,
        },
      });
    }
  }

  const LaunchWizardSteps: LaunchWizardStep[] = [
    {
      key: launchWizardSteps.SdlcSelection,
      component: <SdlcSelectionWizardStep onButtonClick={onSdlcCardSelect} />,
    },
    {
      key: launchWizardSteps.DesiredOutcomesSelection,
      component: sdlcStage ? (
        <DesiredOutcomesSelectionWizardStep
          initialDesiredOutcomeCodes={desiredOutcomes}
          customDesiredOutcomes={customDesiredOutcomes}
          sdlcStageName={sdlcStage}
          onBackButtonClick={onDesiredOutcomesBackButtonClick}
          onContinueButtonClick={onContinueDesiredOutcomesButtonClick}
        />
      ) : (
        <div />
      ),
    },
    {
      key: launchWizardSteps.CapabilitySelection,
      component: (
        <CapabilitySelectionWizardStep
          onCapabilitiesChange={setCapabilities}
          isBackButtonVisible={true}
          hasExpressTestDisabled={hasExpressTestDisabled}
          sdlcStageName={sdlcStage}
          selectedCapabilities={capabilities}
          onBackButtonClick={onCapabilitySelectionBackButtonClick}
          onExpressTestButtonClick={() => setCurrentStep(launchWizardSteps.StandardTestForm)}
          onAdvancedTestButtonClick={() => setCurrentStep(launchWizardSteps.AdvancedTestForm)}
        />
      ),
    },
    {
      key: launchWizardSteps.StandardTestForm,
      component: (
        <>
          <StageHeader
            sdlcStageName={sdlcStage}
            onBackButtonClick={onTestBackButtonClick}
            additionalLabel="(Express)"
          />
          <StandardTestContent
            testRequest={testRequest as PreviewPageStandardTestRequest}
            sdlcStage={sdlcStage}
            desiredOutcomes={desiredOutcomes}
            infoSection={shouldShowInfoSectionOnFormSteps ? <StandardTestInfoSection /> : undefined}
          />
        </>
      ),
    },
    {
      key: launchWizardSteps.AdvancedTestForm,
      component: (
        <>
          <StageHeader
            sdlcStageName={sdlcStage}
            onBackButtonClick={onTestBackButtonClick}
            additionalLabel="(Advanced)"
          />
          <AdvancedTestContent
            capabilities={capabilities}
            sdlcStage={sdlcStage}
            desiredOutcomes={desiredOutcomes}
            customDesiredOutcomes={customDesiredOutcomes}
            testRequest={testRequest as PreviewPageAdvancedTestRequest}
            infoSection={shouldShowInfoSectionOnFormSteps ? <AdvancedTestInfoSection /> : undefined}
          />
        </>
      ),
    },
  ];

  return (
    <PageTemplate
      header={
        <HeaderSection
          shouldShowCloneButton={shouldShowCloneButton}
          cloneButtonComponent={<LaunchWizardCloneContainer />}
        />
      }
    >
      {currentStep && LaunchWizardSteps.find(step => step.key === currentStep)?.component}
    </PageTemplate>
  );
}
