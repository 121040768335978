import { FlexibleTestingIssueTagConnection, FlexibleTestingIssueTagEdge } from "types/graphql";

const POSSIBLE_DUPLICATE_TAG = "possible duplicate";

export function isPossibleDuplicate(issueTags: FlexibleTestingIssueTagConnection) {
  return issueTags.edges
    .map(({ node: { name } }: FlexibleTestingIssueTagEdge) => name?.toLowerCase())
    .includes(POSSIBLE_DUPLICATE_TAG);
}

export default isPossibleDuplicate;
