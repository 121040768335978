import React, { useRef, useState } from "react";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";

type DropdownProps = {
  children: JSX.Element;
  title: string;
  alignment?: "left" | "right";
};

const Dropdown = ({ children, title, alignment = "left" }: DropdownProps): JSX.Element => {
  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const handleOutsideClick = () => setShowDropdownContent(false);
  const wrapperRef = useRef(null);
  const dropdownContentClassName =
    alignment === "left"
      ? "z-100 bg-lightest whitespace-nowrap origin-top-right border border-solid " +
        "rounded-base p-xs border-muted absolute left-0 text-body text-xs " +
        "tracking-extra-wide mt-sm"
      : "z-100 bg-lightest whitespace-nowrap origin-top-right border border-solid " +
        "rounded-base p-xs border-muted absolute right-0 text-body text-xs " +
        "tracking-extra-wide mt-sm";

  useOutsideHandler(wrapperRef, handleOutsideClick);

  return (
    <div className="relative inline-block text-right" ref={wrapperRef}>
      <span>
        <button
          onClick={() => setShowDropdownContent(!showDropdownContent)}
          className="inline-flex focus:outline-none text-md uppercase font-bold tracking-extra-wide"
          id="menu-button"
        >
          {title} <i className="fa fa-caret-down ml-xs text-base leading-2xl h-xl" />
        </button>
      </span>

      {showDropdownContent && (
        <div onClick={() => setShowDropdownContent(false)} className={dropdownContentClassName}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
