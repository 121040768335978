import React from "react";
import { useField } from "formik";

const TestCasesFileError = () => {
  const [_, meta] = useField<File | null>("testCasesFile");

  if (meta.error) {
    return (
      <div className="text-danger text-xs font-bold mt-md ml-4xl">
        <i className="fa fa-exclamation-circle mr-xs" />
        {meta.error}
      </div>
    );
  } else {
    return null;
  }
};

export default TestCasesFileError;
