import React from "react";

import { Country, Maybe } from "types/graphql";

import { FlagIcon } from "components/flexible_testing/shared/flag";
// @ts-ignore
import { datetimeFormat } from "components/organization/shared_functions/date_format";

type CaseResultTimeAndCountryProps = {
  testerCountry?: Maybe<Country>;
  completedAt?: string | null;
};

export function CaseResultTimeAndCountry({ testerCountry, completedAt }: CaseResultTimeAndCountryProps) {
  return (
    <>
      <div className="flex" data-testid="tester-country">
        {testerCountry?.name && (
          <>
            <FlagIcon code={testerCountry.code?.toLowerCase()} className="mr-sm" />
            Tested in: {testerCountry.name}
          </>
        )}
      </div>
      <div className="text-right">Finished on {datetimeFormat(completedAt)}</div>
    </>
  );
}
