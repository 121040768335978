import { gql, useQuery } from "@apollo/client";
import { TestParticipant } from "types/graphql";

export const GET_TESTER_PARTICIPANT = gql`
  query getTestParticipant($id: ID!) {
    testParticipant(id: $id) {
      id
      status
      email
      fullName
      testerId
      country {
        code
        name
      }
      browserOrDevice
      operatingSystemAndVersion
      joinedAt
      finishedAt
      hourlyCaseFieldCompletionRate
      numberOfPendingIssueModerations
      suspectedMassPassing
      approvedTestCasesCount
      resultsPublishable
      isRemovable
      chatChannels {
        edges {
          node {
            id
            chatType
            externalId
          }
        }
      }
      assignedCases {
        edges {
          node {
            id
            name
            code
            section
          }
        }
      }
      caseResults {
        edges {
          node {
            id
            status
            moderationStatus
            latestCaseResultEditRequest {
              status
              createdAt
            }
            tester {
              id
            }
            relatedIssues {
              moderationStatus
              existsInSystem
              known
            }
            testCase {
              id
            }
          }
        }
      }
      test {
        id
        name
        code
        type
        hasStrictTesterRemoval
        participantRemovalReasons {
          label
          code
        }
        communityTestManagersEnabled
      }
    }
  }
`;

function useTestParticipant(id: string) {
  const { data } = useQuery<{ testParticipant: TestParticipant }>(GET_TESTER_PARTICIPANT, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  return { testParticipant: data?.testParticipant };
}

export default useTestParticipant;
