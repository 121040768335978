import React from "react";
import { Helmet } from "react-helmet";

class DefaultLayoutHelmet extends React.Component {
  render(): JSX.Element {
    return (
      <div>
        <Helmet
          title="Global App Testing"
          titleTemplate="%s - Global App Testing"
          meta={[{ name: "description", content: "Global App Testing" }]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default DefaultLayoutHelmet;
