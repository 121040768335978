import React from "react";
import { DecoratedText } from "@hopper/decorated_text";

import { AdvancedTestRequest } from "types/graphql";
import { formatDatetime } from "components/flexible_testing/shared/format_datetime";
import CloneTestButton from "components/flexible_testing/result_details_page/clone_test_buton";
import { Events } from "components/configuration";
import { Field } from "./field";

type AdvancedTestDetailsProps = {
  advancedTestRequest: AdvancedTestRequest;
  testWindow?: AdvancedTestRequest["testWindow"];
};

export const AdvancedTestDetails = ({ advancedTestRequest, testWindow }: AdvancedTestDetailsProps) => {
  const formattedTestLaunchDateTime = formatDatetime(testWindow?.scheduleAt);

  return (
    <>
      {advancedTestRequest.sdlcStage && advancedTestRequest.desiredOutcomes && (
        <Field label={`${advancedTestRequest.sdlcStage} test`} dataTestid="test-desired-outcomes">
          <ul className="mb-md list-disc ml-2xl">
            {advancedTestRequest.desiredOutcomes.map(desiredOutcome => (
              <li key={desiredOutcome.name}>{desiredOutcome.name}</li>
            ))}
            {advancedTestRequest.customDesiredOutcomes && <li>{advancedTestRequest.customDesiredOutcomes}</li>}
          </ul>
        </Field>
      )}
      <Field label="Application Link" dataTestid="test-application-link">
        <DecoratedText text={advancedTestRequest.accessToApplication} />
      </Field>
      <Field label="Test Run Objective" dataTestid="test-objective">
        <DecoratedText text={advancedTestRequest.testObjective} />
      </Field>
      <Field label="Notes / Extra informations" dataTestid="test-notes">
        <DecoratedText text={advancedTestRequest.notes} />
      </Field>
      {formattedTestLaunchDateTime && (
        <Field label="Test Launch date/time" dataTestid="test-launch-date">
          <DecoratedText text={formattedTestLaunchDateTime} />
        </Field>
      )}
      <Field label="Platform(s) to test on" dataTestid="test-platforms-to-test-on">
        <DecoratedText text={advancedTestRequest.platformsToTestOn} />
      </Field>
      {advancedTestRequest.deviceRequirements && (
        <Field label="Specific device(s) requirements" dataTestid="test-device-requirements">
          <DecoratedText text={advancedTestRequest.deviceRequirements} />
        </Field>
      )}
      {advancedTestRequest.testLocalisation && (
        <Field label="Localization requirements" dataTestid="test-test-localisation">
          <DecoratedText text={advancedTestRequest.testLocalisation} />
        </Field>
      )}
      {advancedTestRequest.testCasesUrl && (
        <Field label="Test cases" dataTestid="test-cases-url">
          <a href={advancedTestRequest.testCasesUrl} target="_blank">
            {advancedTestRequest.testCasesUrl}
          </a>
        </Field>
      )}
      {advancedTestRequest.knownIssuesUrl && (
        <Field label="Known issues list" dataTestid="test-known-issues-url">
          <a href={advancedTestRequest.knownIssuesUrl} target="_blank">
            {advancedTestRequest.knownIssuesUrl}
          </a>
        </Field>
      )}
      <CloneTestButton
        testTrackingId={advancedTestRequest._id}
        testId={advancedTestRequest.id}
        pathForCloning="/launch?tab=advanced_test"
        trackingEventName={Events.ADVANCED_TEST_CLONED}
      />
    </>
  );
};
