import { ParticipantModerationStatusEnum, TestParticipant } from "types/graphql";
import { TestParticipationStatus } from "types/app";

export type ChatChannel = {
  id: string;
  chatType: string;
  externalId: string;
};

export type Participant = {
  id: string;
  testerId: string;
  status: TestParticipationStatus;
  fullName: string;
  email: string;
  country: {
    code?: string;
    name?: string;
  };
  approvedTestCasesCount: number;
  joinedAt?: string | null;
  invitedAt?: string | null;
  lastCaseResultCompletedAt?: string | null;
  isActiveInTestCasesExecution: boolean;
  hasReportedIssue: boolean | undefined;
  numberOfPendingIssueModerations: number;
  moderationStatus: `${ParticipantModerationStatusEnum}` | undefined;
  resultsPublishable: boolean;
  chatChannels?: ChatChannel[];
};

export function mapParticipant(participant: TestParticipant): Participant {
  return {
    id: participant.id,
    testerId: participant.testerId || "",
    status: participant.status || "",
    fullName: participant.fullName || "",
    email: participant.email || "",
    country: {
      name: participant.country?.name || "",
      code: participant.country?.code || "",
    },
    approvedTestCasesCount: participant.approvedTestCasesCount,
    isActiveInTestCasesExecution: participant.isActiveInTestCasesExecution || false,
    lastCaseResultCompletedAt: participant.lastCaseResultCompletedAt || undefined,
    hasReportedIssue: participant.hasReportedIssue,
    numberOfPendingIssueModerations: participant.numberOfPendingIssueModerations,
    moderationStatus: participant.moderationStatus || undefined,
    joinedAt: participant.joinedAt || undefined,
    invitedAt: participant.invitedAt || undefined,
    resultsPublishable: participant.resultsPublishable,
    chatChannels: participant.chatChannels.edges.map(({ node: chatChannel }) => ({
      id: chatChannel.id,
      chatType: chatChannel.chatType,
      externalId: chatChannel.externalId,
    })),
  };
}
