import { gql, useMutation } from "@apollo/client";
import { Maybe, ExportTestCaseResultsToCsvPayload } from "types/graphql";

export const CSV_MUTATION = gql`
  mutation ExportTestCaseResultsToCSV($input: ExportTestCaseResultsToCSVInput!) {
    exportTestCaseResultsToCsv(input: $input) {
      successful
      result
    }
  }
`;

type MutationData = {
  exportTestCaseResultsToCsv?: Maybe<ExportTestCaseResultsToCsvPayload>;
};
type DownloadCsvFileType = {
  (testId: string, participantsIds: Array<string>): Promise<void>;
};

export function useCsvExport(): { downloadCsvFiles: DownloadCsvFileType; isLoading: boolean } {
  const [exportTestCaseResultsToCSV, { loading }] = useMutation<MutationData>(CSV_MUTATION);

  return {
    isLoading: loading,
    downloadCsvFiles: async (testId: string, participantsIds: Array<string>) => {
      const result = await exportTestCaseResultsToCSV({
        variables: {
          input: {
            testId,
            participantsIds,
          },
        },
      });
      const mutation_results = result.data?.exportTestCaseResultsToCsv?.result;
      if (mutation_results) {
        mutation_results.forEach((csv_file, index) => {
          /**
           * This timeout is a workaround for a limit of max 10 files downloaded at one time in Chrome.
           */
          setTimeout(() => {
            downloadCsv(csv_file.body, csv_file.filename);
          }, 100 * index);
        });
      }
    },
  };
}

function downloadCsv(body: BlobPart, fileName: string) {
  const blob = new Blob([body], { type: "text/csv" });
  const reader = new FileReader();
  reader.onload = e => {
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = e?.target?.result as string;
    anchor.download = fileName;
    anchor.click();
  };
  reader.readAsDataURL(blob);
}
