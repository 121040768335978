import { Events, isInternalEmail } from "../configuration";
import { useAuth0 } from "@auth0/auth0-react";
import { useTracking } from "react-tracking";

function UserSignInEventSender({ children }) {
  const { user } = useAuth0();
  const tracking = useTracking();

  if (localStorage.getItem("trackUserSignIn") === "true") {
    if (user && shouldTrackUser(user.email)) {
      tracking.trackEvent({
        action: "track",
        name: Events.SIGNED_IN,
        eventProperties: { auth_connection: authConnection(user.sub), email: user.email },
      });
      localStorage.removeItem("trackUserSignIn");
    }
  }

  return children;
}

function shouldTrackUser(email) {
  return email && !isInternalEmail(email);
}

function authConnection(authUserIdentifier) {
  const connectionProvider = authUserIdentifier.split("|")[0];
  const availableAzureConnectionProviders = ["azure-ad-nonprod", "azure-ad-prod"];

  if (connectionProvider === "google-oauth2") {
    return "google";
  }

  if (connectionProvider === "auth0") {
    return "username-password";
  }

  if (availableAzureConnectionProviders.includes(connectionProvider)) {
    return "microsoft";
  }

  return connectionProvider;
}

export default UserSignInEventSender;
