import React from "react";
import pluralize from "pluralize";
import { ArcElement, Chart as ChartJS } from "chart.js";
import { Pie } from "react-chartjs-2";
// @ts-ignore
import { preset } from "@hopper/styles";

ChartJS.register(ArcElement);

type IssuesStats = {
  issuesTotalCount: number;
  issuesCriticalCount: number;
  issuesHighCount: number;
  issuesMediumCount: number;
  issuesLowCount: number;
};

type IssuesSectionProps = {
  issuesStats: IssuesStats;
};

const IssuesSummary = ({ issuesStats }: IssuesSectionProps) => {
  const { issuesTotalCount, issuesCriticalCount, issuesHighCount, issuesMediumCount, issuesLowCount } = issuesStats;
  const { danger, warning, secondary, muted } = preset.theme.colors;
  const chartData = {
    datasets: [
      {
        data: issuesTotalCount ? [issuesCriticalCount, issuesHighCount, issuesMediumCount, issuesLowCount] : [1],
        backgroundColor: issuesTotalCount ? [danger, warning, secondary, muted] : [muted],
        borderWidth: 0,
      },
    ],
  };
  const issues = pluralize("issue", issuesTotalCount);
  const have = pluralize("have", issuesTotalCount);

  const getPercentage = (issuesCount: number) =>
    issuesTotalCount === 0 ? 0 : Math.round((issuesCount / issuesTotalCount) * 100);

  return (
    <div className="mt-3xl flex">
      <div className="w-[80px]" data-testid="issues-chart">
        <Pie data={chartData} />
      </div>

      <div className="ml-lg" data-testid="issues-summary">
        <p className="text-xl font-black text-darkest">{`${issuesTotalCount} ${issues} ${have} been reported`}</p>

        <div className="grid grid-cols-2 grid-rows-2 gap-x-lg">
          <span className="flex items-center" data-testid="critical-severity-issues-percentage">
            <i className="fa fa-circle mr-xs text-2xs text-danger" />
            <span>{`${getPercentage(issuesCriticalCount)}% critical severity`}</span>
          </span>

          <span className="flex items-center" data-testid="high-severity-issues-percentage">
            <i className="fa fa-circle mr-xs text-2xs text-warning" />
            <span>{`${getPercentage(issuesHighCount)}% high severity`}</span>
          </span>

          <span className="flex items-center" data-testid="medium-severity-issues-percentage">
            <i className="fa fa-circle mr-xs text-2xs text-secondary" />
            <span>{`${getPercentage(issuesMediumCount)}% medium severity`}</span>
          </span>

          <span className="flex items-center" data-testid="low-severity-issues-percentage">
            <i className="fa fa-circle mr-xs text-2xs text-muted" />
            <span>{`${getPercentage(issuesLowCount)}% low severity`}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default IssuesSummary;
