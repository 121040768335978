import type { Locale } from "date-fns";

type FormatDistanceTokenValue =
  | string
  | {
      one: string;
      other: string;
    };

type FormatDistanceToken =
  | "lessThanXSeconds"
  | "xSeconds"
  | "halfAMinute"
  | "lessThanXMinutes"
  | "xMinutes"
  | "aboutXHours"
  | "xHours"
  | "xDays"
  | "aboutXWeeks"
  | "xWeeks"
  | "aboutXMonths"
  | "xMonths"
  | "aboutXYears"
  | "xYears"
  | "overXYears"
  | "almostXYears";

const formatDistanceLocale: Record<FormatDistanceToken, FormatDistanceTokenValue> = {
  lessThanXSeconds: {
    one: "<1s",
    other: "<{{count}}s",
  },

  xSeconds: {
    one: "1s",
    other: "{{count}}s",
  },

  halfAMinute: "30s",

  lessThanXMinutes: {
    one: "<1m",
    other: "<{{count}}m",
  },

  xMinutes: {
    one: "1min",
    other: "{{count}}min",
  },

  aboutXHours: {
    one: "~1h",
    other: "~{{count}}h",
  },

  xHours: {
    one: "1h",
    other: "{{count}}h",
  },

  xDays: {
    one: "1d",
    other: "{{count}}d",
  },

  aboutXWeeks: {
    one: "~1w",
    other: "~{{count}}w",
  },

  xWeeks: {
    one: "1w",
    other: "{{count}}w",
  },

  aboutXMonths: {
    one: "~1mo",
    other: "~{{count}}mo",
  },

  xMonths: {
    one: "1mo",
    other: "{{count}}mo",
  },

  aboutXYears: {
    one: "~1y",
    other: "~{{count}}y",
  },

  xYears: {
    one: "1y",
    other: "{{count}}y",
  },

  overXYears: {
    one: ">1y",
    other: ">{{count}}y",
  },

  almostXYears: {
    one: "~1y",
    other: "~{{count}}y",
  },
};

// This is custom locale for formatDistance* functions from date-fns library.
// (Do not use it for other date-fns functions.)
// It displays the time difference in a short format, e.g. "1d" instead of "1 day".
// to use it pass it as `locale` option to a formatDistance function, e.g.
// formatDistanceToNowStrict(date, { locale: formatDistanceShortLocale })
// This is based on the en-US locale from date-fns library.
// https://github.com/date-fns/date-fns/blob/main/src/locale/en-US/_lib/formatDistance/index.ts
const formatDistanceShortLocale: Locale = {
  formatDistance: (token: FormatDistanceToken, count: number, options) => {
    let result;

    const tokenValue = formatDistanceLocale[token];
    if (typeof tokenValue === "string") {
      result = tokenValue;
    } else if (count === 1) {
      result = tokenValue.one;
    } else {
      result = tokenValue.other.replace("{{count}}", count.toString());
    }

    if (options?.addSuffix) {
      if (options.comparison && options.comparison > 0) {
        return "in " + result;
      } else {
        return result + " ago";
      }
    }

    return result;
  },
};

export default formatDistanceShortLocale;
