import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import classnames from "classnames";

type HeaderProps = {
  title: React.ReactNode;
  subTitle?: string;
  orderNumber?: number;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  className?: string;
  fullWidthTitle?: boolean;
};

function Section({
  children,
  title,
  orderNumber,
  subTitle,
  tooltip,
  className,
  fullWidthTitle,
}: HeaderProps): JSX.Element {
  return (
    <div className={className}>
      <div className="flex items-center font-bold pb-md">
        {orderNumber && (
          <span className="bg-info text-lightest w-2xl h-2xl rounded-full inline-flex items-center justify-center mr-lg">
            {orderNumber}
          </span>
        )}
        <span className={classnames(`inline-flex items-center`, { "flex-grow": fullWidthTitle })}>{title}</span>
        {tooltip ? (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id={`test-request-form-tooltip-${orderNumber}`}>{tooltip}</Tooltip>}
          >
            <i className="fa fa-question-circle ml-sm" aria-hidden="true" />
          </OverlayTrigger>
        ) : null}
        {subTitle ? <div className="text-dark font-normal ml-4xl text-sm mb-md">{subTitle}</div> : null}
      </div>
      {children}
    </div>
  );
}

export default Section;
