import React from "react";
import { PageSection } from "components/flexible_testing/shared/page_section";
import NewApiKeyButton from "./new_api_key_button";
import NewApiKeyInput from "./new_api_key_input";
import useNewKey from "./use_new_key";

type NewApiKeyProps = {
  onLoadingStarted: () => void;
  onLoadingFinished: () => void;
};

function NewKey({ onLoadingStarted, onLoadingFinished }: NewApiKeyProps): JSX.Element {
  const { generateNewKey, data } = useNewKey({ onLoadingFinished });

  const title = (
    <div className="block mt-xl">
      <span>Generate a new API Key</span>
      <div className="text-dark font-normal text-sm">
        {"For more information, please see our "}
        <a
          className="pl-0 text-link underline"
          target="_blank"
          href="https://app.globalapptesting.com/api/v2/api_docs"
          data-testid="api-docs-link"
        >
          API documentation
        </a>
      </div>
    </div>
  );

  return (
    <PageSection className="md:mr-4xl" title={title}>
      <NewApiKeyButton
        isKeyAlreadyGenerated={!!data.apiKey}
        onClick={() => {
          onLoadingStarted();
          generateNewKey();
        }}
      />
      {data.apiKey && <NewApiKeyInput apiKey={data.apiKey} />}
    </PageSection>
  );
}

export default NewKey;
