import classNames from "classnames";
import React from "react";

type FlagIconProps = {
  code?: string | null;
  className?: string;
  title?: string;
};

const FlagIcon = ({ code = "", className, title }: FlagIconProps): JSX.Element => (
  <span
    // Height and width have to be changed together to match the svg aspect ratio.
    // If one of them is changed without the other the flag won't match the border.
    style={{ width: "27px", height: "21px" }}
    className={classNames("bg-no-repeat border border-light rounded-base inline-block", `fi fi-${code}`, className)}
    data-testid="flag-icon"
    title={title}
  />
);

export default FlagIcon;
