import { gql, useQuery } from "@apollo/client";

type QueryData = {
  currentOrganizationUser: StreamCurrentUserProps;
};

export type StreamCurrentUserProps = {
  id: string;
  publicId: string;
  fullName: string;
  streamChatToken: string;
};

export const GET_STREAM_CURRENT_USER = gql`
  query getCurrentOrganizationUser {
    currentOrganizationUser {
      id
      fullName
      publicId
      streamChatToken
    }
  }
`;

function useStreamCurrentUser(): StreamCurrentUserProps | undefined {
  const { data } = useQuery<QueryData>(GET_STREAM_CURRENT_USER);

  return data?.currentOrganizationUser;
}

export { useStreamCurrentUser };
