import React from "react";
import { Heading } from "@hopper/heading";
import { useLocation } from "react-router-dom";

import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { WizardStepName } from "./stepper";
import RequestedTestWizard from "./requested_test_wizard";
import { RequestedTest } from "components/flexible_testing/test_request_page/requested_test_page/requested_test_form";

const PageHeader = () => (
  <div className="flex justify-between items-center">
    <Heading size={1}>Launch</Heading>
  </div>
);

const RequestedTestPage = () => {
  const { state } = useLocation<{ currentStep: WizardStepName; currentTest: RequestedTest }>();
  const currentTest = state?.currentTest;
  const stepName = state?.currentStep || "activity";

  return (
    <PageTemplate header={<PageHeader />}>
      <div className="p-md text-sm bg-light border-l-2 rounded border-primary w-full lg:w-[55%] md:w-[75%] mb-3xl -mt-2xl">
        There’s a new way to make requests and launch tests! You can configure multiple tests with specific parameters
        in a single request. As always, if you need a custom test, select “Create your own” and we’ll partner with you
        to design the tests and carry them out so that you get the results you need
      </div>

      <RequestedTestWizard stepName={stepName} initialValues={currentTest} isEditing={!!currentTest} />
    </PageTemplate>
  );
};

export default RequestedTestPage;
