import React from "react";
import { DecoratedText } from "@hopper/decorated_text";
import { Label } from "@hopper/label";

import { DetailsField } from "components/flexible_testing/shared/details_field";
import { InfoPopup } from "components/flexible_testing/shared/info_popup";
import { CaseResultStatus } from "components/flexible_testing/tester_overview/test_case_result";
import { EditButton } from "components/flexible_testing/shared/case_result/edit_button";

type OverallResultProps = {
  status: CaseResultStatus;
  actualResult?: string | null;
  originalActualResult?: string | null;
  additionalInformation?: string | null;
  originalAdditionalInformation?: string | null;
  caseResultResponseId?: string | null;
  testCaseResultId?: string;
  isCaseResultEditingDisabled?: boolean;
};

export function OverallResult({
  status,
  actualResult,
  originalActualResult,
  additionalInformation,
  originalAdditionalInformation,
  caseResultResponseId,
  testCaseResultId,
  isCaseResultEditingDisabled,
}: OverallResultProps) {
  const overallStatusLabel =
    {
      fail: "Overall result",
      blocked: "Selected reason for blocked result",
      pass: null,
      live: null,
      not_tested: null,
      skipped: "Reason for skipping",
    }[status] || "";
  const isCaseResultFailed = testCaseResultId && status === "fail";
  const isCaseResultBlocked = testCaseResultId && status === "blocked";

  const infoPopup = (content: string) => (
    <InfoPopup label="Original text" content={content}>
      <div className="cursor-pointer" style={{ marginTop: "-6px" }}>
        <Label>Edited automatically</Label>
      </div>
    </InfoPopup>
  );

  return (
    <div data-testid="overall-result-section">
      {!!actualResult && (
        <>
          <DetailsField
            label={overallStatusLabel}
            postfixLabel={originalActualResult && infoPopup(originalActualResult)}
            testId="overall-result"
            paddingClass="pt-md"
          >
            <DecoratedText text={actualResult} />
          </DetailsField>
          {isCaseResultFailed && (
            <EditButton
              fieldType="Status"
              fieldName="actualResult"
              fieldLabel="Overall result"
              caseResultResponseId={caseResultResponseId}
              initialValue={actualResult}
              testCaseResultId={testCaseResultId}
              isEditButtonDisabled={isCaseResultEditingDisabled}
              isOverallResult={true}
            />
          )}
        </>
      )}
      {!!additionalInformation && (
        <>
          <DetailsField
            label="Tester comments"
            postfixLabel={originalActualResult && infoPopup(originalActualResult)}
            testId="tester-comments"
            paddingClass="pt-md"
          >
            <DecoratedText text={additionalInformation} />
          </DetailsField>
          {isCaseResultBlocked && (
            <EditButton
              fieldType="Status"
              fieldName="additionalInformation"
              fieldLabel="Tester comments"
              caseResultResponseId={caseResultResponseId}
              initialValue={additionalInformation}
              testCaseResultId={testCaseResultId}
              isEditButtonDisabled={isCaseResultEditingDisabled}
              isOverallResult={true}
            />
          )}
        </>
      )}
    </div>
  );
}
