import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { VendorTestRequestResultPage } from "./vendor_test_request_result_page";

function FlexibleVendorTestRequestResultSection({ match: { path } }: RouteComponentProps): JSX.Element {
  return (
    <Switch>
      <Route path={path} component={VendorTestRequestResultPage} />
    </Switch>
  );
}

export default FlexibleVendorTestRequestResultSection;
