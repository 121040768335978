import React, { useEffect, useRef } from "react";
import { object, string } from "yup";
import { useFormikContext } from "formik";

import { RadioGroup, TextArea, TextInput } from "components/flexible_testing/shared/form";
import { RequestedTest } from "./requested_test_form";
import RequestedTestWizardNavigation from "./requested_test_wizard_navigation";

export type ApplicationType = {
  label: string;
  value: RequestedTest["applicationType"];
};

export const APPLICATION_TYPES: ApplicationType[] = [
  { label: "Android", value: "android" },
  { label: "iOS", value: "ios" },
  { label: "Web", value: "web" },
];

export const validations = object().shape({
  applicationType: string().required("Please provide the type of application."),
  accessToApplicationUrl: string().required("Please provide a link to the application."),
  requiresAccessToSecureEnvironment: string().required(
    "Please specify whether testers need access to a secure environment.",
  ),
  accessToSecureEnvironmentInstructions: string().when("requiresAccessToSecureEnvironment", {
    is: "true",
    then: string().required("Please provide instructions for accessing the secure environment."),
  }),
});

const AppStepContent = ({ nextStep }: { nextStep: () => unknown }) => {
  const { isValid, values, validateForm, setFieldValue } = useFormikContext<RequestedTest>();
  const initialRender = useRef<boolean>(true);

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    if (initialRender.current) initialRender.current = false;
    else setFieldValue("targetingGroups", []);
  }, [values.applicationType]);

  return (
    <div data-testid="app-step-content">
      <div className="flex gap-4xl">
        <div className="flex flex-col flex-1 gap-lg">
          <RadioGroup<RequestedTest["applicationType"]>
            name="applicationType"
            label="What type of application is it? "
            options={APPLICATION_TYPES}
            horizontal
            required
          />
          <div>
            <TextInput
              name="applicationName"
              placeholder="e.g., Instagram"
              label="What is the name of your application?"
              labelClass="font-normal"
            />
          </div>
          <div>
            <TextInput
              name="applicationVersion"
              placeholder="e.g., 1.1"
              label="What version of your application is this?"
              labelClass="font-normal"
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 gap-lg">
          <div>
            <TextInput
              name="accessToApplicationUrl"
              placeholder="e.g., https://testflight.apple.com/join/abcd1234"
              label="Link to where testers can access your application"
              labelClass="font-normal"
              required
            />
          </div>
          <RadioGroup
            name="requiresAccessToSecureEnvironment"
            label="Do testers need to access a secure environment?"
            options={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ]}
            required
          />
          {values.requiresAccessToSecureEnvironment === "true" && (
            <div className="w-[60%]">
              <TextArea
                name="accessToSecureEnvironmentInstructions"
                placeholder="e.g., username : password"
                label="How do the testers access the secure environment? If access credentials are needed, provide them here"
                useHopperInput={true}
                description="This information will be shown directly to testers"
                required
              />
            </div>
          )}
        </div>
      </div>
      <RequestedTestWizardNavigation onSubmit={nextStep} submitDisabled={!isValid} />
    </div>
  );
};

export default AppStepContent;
