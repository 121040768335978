import React from "react";
import { gql, useMutation } from "@apollo/client";
import { API_KEYS_QUERY } from "../use_api_keys";
import { Modal } from "@hopper/legacy_modal";
import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";

type ConfirmationModalProps = {
  apiKeyId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const REVOKE_API_KEY = gql`
  mutation CustomerApiKeyUpdate($input: CustomerApiKeyUpdateInput!) {
    customerApiKeyUpdate(input: $input) {
      successful
    }
  }
`;

function ConfirmationModal({ apiKeyId, isOpen, onClose }: ConfirmationModalProps): JSX.Element {
  const [customerApiKeyUpdate] = useMutation(REVOKE_API_KEY);

  const onConfirm = async () => {
    await customerApiKeyUpdate({
      variables: {
        input: { customerApiKeyId: apiKeyId, active: false },
      },
      refetchQueries: [{ query: API_KEYS_QUERY }],
    });
    onClose();
  };

  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={isOpen}
      position="center"
      size="lg"
      onOverlayClicked={onClose}
    >
      <div className="flex flex-col items-center justify-center max-w-xs sm:max-w-full text-center ml-xl">
        <div
          className="rounded-full border-solid border-danger border-4 flex items-center justify-center mb-2xl mt-2xl"
          style={{ height: "4.5rem", width: "4.5rem" }}
        >
          <i className="fa fa-times text-danger opacity-100 text-3xl" aria-hidden="true" />
        </div>
        <Heading size={2}>Are you sure?</Heading>
        <Heading size={3}>This API key will be revoked. This action cannot be undone.</Heading>
        <div className="flex mt-lg">
          <Button className="mr-xl" variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="danger" type="submit" onClick={onConfirm}>
            Yes, revoke this key!
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
