import React from "react";
import PropTypes from "prop-types";
import track from "react-tracking";
import injectSegment from "./segment_snippet";
import { GET_CURRENT_ORGANIZATION_USER } from "../../components/shared/queries/organization_users_query";
import { ApolloClientProvider } from "app/apollo_client_provider";
import { analyticsConfig, isInternalEmail } from "components/configuration";
import CookieConsent from "components/organization/cookie_consent";

// In guard to avoid publishing events without User identity
let userIdentified = false;

class Analytics {
  constructor(analyticsProvider) {
    this.analyticsProvider = analyticsProvider;
  }

  static eventsQueue = [];

  processEvent = event => {
    const allowedActions = ["track", "page"];
    if (allowedActions.includes(event.action)) {
      this.analyticsProvider[event.action](event.name, { ...event.eventProperties });
    } else {
      throw new Error("Unknown event action: " + event.action);
    }
  };

  identify = ({
    publicId,
    fullName,
    email,
    createdAt,
    organizationName,
    organizationPublicId,
    flexibleTestingAccess,
    isTestManager,
    intercomUserHash,
  }) => {
    const traits = {
      email,
      created_at: Date.parse(createdAt) / 1000,
      organization_user_id: publicId,
      organization_user_name: fullName,
      organization_public_id: organizationPublicId,
      organization_name: organizationName,
      flexible_testing_access: flexibleTestingAccess,
      is_test_manager: isTestManager,
    };

    this.analyticsProvider.identify(publicId, traits, this.analyticsOptions(flexibleTestingAccess, intercomUserHash));
  };

  analyticsOptions = (flexibleTestingAccess, intercomUserHash) => ({
    integrations: {
      Intercom: flexibleTestingAccess && { user_hash: intercomUserHash },
    },
  });

  page = () => {
    this.analyticsProvider.page("", "");
  };

  static addEvent(event) {
    Analytics.eventsQueue.push(event);
  }

  reset = () => {
    this.analyticsProvider.reset();
  };

  processEvents = () => {
    const event = Analytics.eventsQueue.shift();

    if (event) {
      this.processEvent(event);
      this.processEvents();
    }
  };
}

class NullAnalyticsProvider {
  track = () => {};
  identify = () => {};
  page = () => {};
  reset = () => {};
}

class AnalyticsTracker extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.analytics = new Analytics(new NullAnalyticsProvider());

    this.state = {
      identifiedPublicId: null,
    };
  }

  componentDidMount() {
    if (analyticsConfig.segmentWriteKey) {
      injectSegment(analyticsConfig.segmentWriteKey, this.onSegmentSnippetLoad);
    }
  }

  onSegmentSnippetLoad = () => {
    this.initializeAnalytics();

    this.identifyUser(this.props).then(
      () => {
        this.trackPage(this.props);
        userIdentified = true;
        this.analytics.processEvents();
      },
      error => {
        if (userIdentified) {
          userIdentified = false;
          this.analytics.reset();
        }

        return error;
      },
    );
  };

  initializeAnalytics = () => {
    if (window.analytics && this.analytics.analyticsProvider !== window.analytics) {
      this.analytics = new Analytics(window.analytics);
    }
  };

  componentWillUnmount() {
    userIdentified = false;
    this.analytics.reset();
  }

  shouldTrackUser(email, isTestManager) {
    return isTestManager || !isInternalEmail(email);
  }

  identifyUser(prevProps) {
    return new Promise((resolve, reject) => {
      const { publicId, fullName, createdAt, organization, email, testManager, intercomUserHash } =
        this.getCurrentOrganizationUser() || {};
      const {
        name: organizationName,
        publicId: organizationPublicId,
        flexibleTestingAccess,
      } = organization || {};

      const shouldTrackUser = this.shouldTrackUser(email, testManager);

      if (publicId && publicId !== this.state.identifiedPublicId && shouldTrackUser) {
        const args = {
          publicId,
          fullName,
          email,
          createdAt,
          organizationName,
          organizationPublicId,
          flexibleTestingAccess,
          isTestManager: testManager,
          intercomUserHash,
        };
        this.analytics.identify(args);
        this.trackPage(prevProps, true);
        this.setState({ identifiedPublicId: publicId });
      }

      if (publicId && shouldTrackUser) {
        resolve();
      } else {
        reject(new Error("User should not be tracked"));
      }
    });
  }

  shouldTrackPage(prevProps, initialUserLoad) {
    const { pathname: oldPathname } = prevProps.location;
    const { pathname } = this.props.location;
    const pathChanged = oldPathname !== pathname;
    const shouldTrackPage = userIdentified && pathChanged;

    return initialUserLoad || shouldTrackPage;
  }

  getCurrentOrganizationUser() {
    let currentOrganizationUser;

    try {
      currentOrganizationUser = ApolloClientProvider.apolloClient.readQuery({
        query: GET_CURRENT_ORGANIZATION_USER,
      }).currentOrganizationUser;
    } catch (e) {
      currentOrganizationUser = null;
    }

    return currentOrganizationUser;
  }

  trackPage(prevProps, initialUserLoad = false) {
    const currentOrganizationUser = this.getCurrentOrganizationUser();

    if (this.shouldTrackPage(prevProps, initialUserLoad) && currentOrganizationUser) {
      this.analytics.page();
    }
  }

  shouldEnableTracking = () => {
    const currentOrganizationUser = this.getCurrentOrganizationUser();

    if (!currentOrganizationUser) return false;

    const { email, testManager } = currentOrganizationUser;

    return this.shouldTrackUser(email, testManager);
  };

  render() {
    return (
      <>
        {analyticsConfig.segmentWriteKey && this.shouldEnableTracking() && <CookieConsent />}
        {this.props.children}
      </>
    );
  }
}

export default track(
  {},
  {
    dispatch: data => {
      Analytics.addEvent(data);

      if (userIdentified) {
        new Analytics(window.analytics ? window.analytics : new NullAnalyticsProvider()).processEvents();
      }
    },
  },
)(AnalyticsTracker);
