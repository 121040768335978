import * as yup from "yup";
import {
  launchTestFormCommonValidations,
  testerGroupTesterEmailsValidations,
} from "components/flexible_testing/shared/validations";

// the regex is based on: https://uibakery.io/regex-library/url
const urlRegex =
  /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const validationSchema = yup.object({
  ...launchTestFormCommonValidations,
  credentialsAndAccessInstructions: yup.string().trim().max(5000, "Cannot be longer than 5000 characters."),
  testerSpecificationDocument: yup
    .string()
    .trim()
    .matches(urlRegex, "Please enter a valid and secure URL")
    .required("A link to tester specification document is required"),
  automaticTesterManagementEnabled: yup.boolean(),
  automaticTesterManagementDisabledReason: yup.string().when("automaticTesterManagementEnabled", {
    is: true,
    then: schema => schema.optional(),
    otherwise: schema => schema.required().trim(),
  }),
  testerGroups: yup.array().of(
    yup.object().shape({
      name: yup.string().max(100, "Cannot be longer than 100 characters"),
      other: yup.string().max(10_000, "Cannot be longer than 10000 characters"),
      numberOfResultsNeeded: yup
        .number()
        .required("A number is required")
        .min(1, "At least one result is required")
        .max(1000, "Cannot require more than 1000 results"),
      operatingSystemVersions: yup.array(),
      selectedTesterEmails: testerGroupTesterEmailsValidations,
      backupTesterEmails: testerGroupTesterEmailsValidations.test({
        name: "no-duplicated-emails",
        test: async (value = [], testContext) => {
          const duplicates = value.filter(el => testContext.parent.selectedTesterEmails?.includes(el));

          return (
            duplicates.length === 0 ||
            testContext.createError({
              message: `The following emails have already been selected: ${duplicates.join(", ")}`,
            })
          );
        },
      }),
      automaticTesterSelectionEnabled: yup.boolean(),
      automaticTesterSelectionDisabledReason: yup.string().when("automaticTesterSelectionEnabled", {
        is: true,
        then: schema => schema.optional(),
        otherwise: schema =>
          schema.required("A reason is required").trim().max(1000, "Cannot be longer than 1000 characters"),
      }),
    }),
  ),
});
