import { useField } from "formik";
import React, { forwardRef, useEffect } from "react";
import ReactSelect from "react-select";
import { noop } from "lodash";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import FormLabel from "../form_label";
import customOptionWrapper from "./custom_option_wrapper";

const Select = forwardRef(
  (
    {
      id,
      name,
      label,
      required,
      onChange = noop,
      labelClassName = "",
      validate,
      isAsync,
      optionComponent,
      searchProp,
      ...props
    },
    ref,
  ) => {
    const ReactSelectComponent = isAsync ? AsyncSelect : ReactSelect;
    const [field, meta, helpers] = useField({ name, validate });

    useEffect(() => {
      // Setting field value from value from the props allows to make this component controllable
      if ("value" in props) {
        helpers.setValue(props.value?.value ?? "");
      }
    }, [props.value]);

    const { className, labelClass, ...otherProps } = props;

    return (
      <>
        <FormLabel
          label={label}
          required={required}
          labelProps={{ htmlFor: props.id || name, className: labelClass }}
        />
        <div
          className="input-group w-full items-stretch flex-col"
          data-test-role={props.isMulti ? "react-select-multi" : "react-select-single"}
        >
          <ReactSelectComponent
            inputId={id || name}
            name={name}
            className={`${className} flex-grow`}
            {...field}
            {...otherProps}
            styles={{
              menuList: provided => ({
                ...provided,
                maxHeight: "225px",
              }),
              option: provided => ({
                ...provided,
                height: "40px",
              }),
            }}
            filterOption={
              searchProp && ((option, input) => option[searchProp]?.toLowerCase().includes(input.toLowerCase()))
            }
            components={optionComponent && { Option: customOptionWrapper(optionComponent) }}
            onChange={options => {
              const value = props.isMulti ? options?.map(option => option.value) : options?.value;
              helpers.setValue(value);
              onChange(value);
            }}
            onBlur={() => {
              helpers.setTouched(true);
            }}
            ref={ref}
            value={props.value}
          />
          <div>{meta.touched && meta.error ? <div className="text-danger mb-l">{meta.error}</div> : null}</div>
        </div>
      </>
    );
  },
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  labelClassName: PropTypes.string,
  id: PropTypes.string,
  validate: PropTypes.func,
  isAsync: PropTypes.bool,
  optionComponent: PropTypes.func,
  searchProp: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.array,
  ]),
};

export default Select;
