import React from "react";
import classnames from "classnames";
import { FlexibleTestingIssue, FlexibleTestingIssueTag } from "types/graphql";
import pluralize from "pluralize";

type DisplayButtonProps = {
  tags: FlexibleTestingIssueTag[];
  isOpen: boolean;
  onClick: () => void;
  taggedIssues?: Array<FlexibleTestingIssue>;
  filterMode?: string;
};

const FilterDisplayButton = ({ tags, isOpen, onClick, taggedIssues, filterMode }: DisplayButtonProps) => {
  const isFilterAll = filterMode === "all";

  return (
    <>
      <div
        style={{ width: "180px" }}
        className={classnames(
          "flex justify-between cursor-pointer hover:text-body",
          isOpen ? "text-body" : "text-secondary",
        )}
        onClick={onClick}
        data-testid="add-issue-tag-filter"
      >
        <span className={tags.length ? (isFilterAll ? "text-danger" : "text-link") : "opacity-50"}>
          <i className={`fa ${tags.length < 2 ? "fa-tag" : "fa-tags"}`} />
        </span>
        <span className="px-sm flex-grow text-left">
          With <b>{tags.length !== 1 && (isFilterAll ? "all" : "any")}</b> {tags.length || ""}{" "}
          {pluralize("Tag", tags.length)}
          ...
        </span>
        <span className="text-secondary ml-md">{taggedIssues?.length || 0}</span>
      </div>
    </>
  );
};

export default FilterDisplayButton;
