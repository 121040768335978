import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import queryString from "query-string";
import { Card } from "@hopper/card";
import { Button } from "@hopper/button";

import { AZURE_DEVOPS_CREDENTIALS_CREATE } from "components/shared/integration/integration_queries";
// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import AzureDevopsForm from "./azure_devops_form";
import { AzureDevopsClientAuthentication } from "types/graphql";
import { useIntegrationUpdate } from "../use_integration_update";
import { ClientAuthenticationProps } from "../integration_page";

export const AzureDevops = ({ integration }: ClientAuthenticationProps) => {
  const [credentialsKey, setCredentialsKey] = useState();
  const [credentialsCreateError, setCredentialsCreateError] = useState();
  const [isEditable, setIsEditable] = useState(!integration.clientAuthenticationValid);
  const [credentialsCreate] = useMutation(AZURE_DEVOPS_CREDENTIALS_CREATE);
  const history = useHistory();
  const { updateIntegration } = useIntegrationUpdate();

  useEffect(() => {
    const parsedQuery = queryString.parse(window.location.search);

    if (parsedQuery.state) {
      const wasEditable = JSON.parse(Base64.decode(parsedQuery.state)).isEditable;

      setIsEditable(wasEditable);
    }

    if (parsedQuery.error) {
      setCredentialsCreateError(parsedQuery.error);
    } else if (parsedQuery.code) {
      credentialsCreate({
        variables: {
          input: {
            authorizationCode: parsedQuery.code,
          },
        },
      })
        .then(({ data }) => {
          setCredentialsKey(data.azureDevopsCredentialsCreate.result);
        })
        .catch(error => setCredentialsCreateError(error));
    }
  }, []);

  async function handleSubmit(values: { [field: string]: unknown }) {
    values.credentialsKey = credentialsKey;

    const params = {
      code: "azure_devops",
      azureDevopsAuthenticationParams: {
        site: values.site as string,
        username: values.username as string,
        organization: values.organization as string,
        accessToken: values.accessToken as string,
        contextPath: values.contextPath as string,
        credentialsKey: values.credentialsKey as string,
      },
    };

    await updateIntegration(params);

    returnToIntegrationsPage();
  }

  function handleCancel() {
    returnToIntegrationsPage();
  }

  function returnToIntegrationsPage() {
    history.push(createNavigationUrl("integrationSettings"));
  }

  function handleEditButtonClick() {
    setIsEditable(prevValue => !prevValue);
  }

  return (
    <Card>
      <div className="m-3xl">
        {!isEditable ? (
          <div className="border-b border-muted mb-4xl">
            <div>
              <Button className="text-sm px-lg" variant="primary" size="sm" onClick={handleEditButtonClick}>
                Edit this integration
              </Button>
              <div className="text-secondary text-xs mt-sm mb-4xl">
                To edit this integration, you will need to re-authenticate
              </div>
            </div>
          </div>
        ) : null}
        <AzureDevopsForm
          clientAuthentication={integration.clientAuthentication as AzureDevopsClientAuthentication}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          credentialsCreateError={credentialsCreateError}
          credentialsKey={credentialsKey}
          isEditable={isEditable}
        />
      </div>
    </Card>
  );
};
