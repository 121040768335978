import React from "react";
import { Status } from "@hopper/status";
import { StatusColor } from "components/flexible_testing/result_details_page/test_cases/test_cases_list_grouped_by_section/test_cases_group_status/test_cases_group_status";

type CasesStatsStatusProps = {
  color: StatusColor | undefined;
  fontStyle: string;
  message: string | undefined;
};

function CasesStatsStatus({ color, message, fontStyle }: CasesStatsStatusProps): JSX.Element {
  return (
    <Status color={color}>
      <span className={fontStyle} data-testid="cases-stats-status-message">
        {message}
      </span>
    </Status>
  );
}

export default CasesStatsStatus;
