import React from "react";

type WarningProps = {
  children: React.ReactNode;
};

export const Warning = ({ children }: WarningProps) => (
  <div className="rounded-base bg-tw-warning-100 px-lg py-sm" data-testid="warning">
    ⚠️ {children}
  </div>
);
