import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { Button } from "@hopper/button";

import { websiteContentPaths } from "components/configuration";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { TestRequestErrorModal } from "components/flexible_testing/shared/test_request_error_modal";
import { PreviewPageAdvancedTestRequest } from "../../preview_page";
import { PreviewField } from "components/flexible_testing/shared/preview_field";
import { SuccessModal } from "../success_modal";
import useLaunchAdvancedTest from "../use_launch_advanced_test";
import { usePreviousTestAvailable } from "components/flexible_testing/shared/use_previous_test_available";
import { useIsOrganizationNameChosen } from "components/flexible_testing/shared/use_is_organization_name_chosen";
import { useFeatureFlag } from "app/feature_flags";
import { PreviewTestCases } from "components/flexible_testing/shared/preview_test_cases";

type AdvancedTestContentProps = {
  testRequest: PreviewPageAdvancedTestRequest;
  handleEditTest: () => void;
  formattedTestLaunchDateTime: string;
};

function AdvancedTestContent({
  testRequest,
  handleEditTest,
  formattedTestLaunchDateTime,
}: AdvancedTestContentProps): JSX.Element {
  const {
    accessToApplication,
    testObjective,
    notes,
    name,
    platformsToTestOn,
    deviceRequirements,
    testLocalisation,
    testCasesUrl,
    knownIssuesUrl,
    scheduleAt,
    testCases,
  } = testRequest;

  const { launchTest, testResultsUrl, isLoading, wasSuccessfullyRequested } = useLaunchAdvancedTest();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { isLoading: isLoadingPreviousTestInfo, hasNoPreviousTests } = usePreviousTestAvailable();
  const isOrganizationNameChosen = useIsOrganizationNameChosen();
  const testCaseUploaderEnabled = useFeatureFlag("customer-test-case-uploader", false);
  const testRailsTestCaseImporterEnabled = useFeatureFlag("customer-test-rails-test-case-importer", false);
  const SHORT_VIEW_CASES_LIMIT = 3;

  function handleLaunchTest() {
    launchTest(testRequest)
      .then(() => {
        setShowSuccessModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      });
  }

  return (
    <>
      <div className="mt-3xl">
        <div className="text-xl font-black">Advanced testing</div>
        <div className="text-sm mt-xs">
          Advanced tests support any custom or niche testing need and deliver results in 48 hours or less
        </div>
      </div>
      <div className="mt-5xl font-bold">{name}</div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <PageSection>
          <PreviewField label="application url">{accessToApplication}</PreviewField>
          <PreviewField label="test run objective">{testObjective}</PreviewField>
          <PreviewField label="notes / extra information">{notes}</PreviewField>
          <PreviewField label="test launch date/time">
            <div data-testid="test-launch-time-message">
              {isEmpty(scheduleAt) ? "Immediately" : formattedTestLaunchDateTime}
            </div>
          </PreviewField>
          <div className="mt-2xl">
            <Button
              disabled={wasSuccessfullyRequested}
              isLoading={isLoading || isLoadingPreviousTestInfo}
              variant="primary"
              onClick={handleLaunchTest}
              data-testid="launch-test"
            >
              {wasSuccessfullyRequested ? "Test launched" : "Launch this test"}
            </Button>
            <Button
              disabled={wasSuccessfullyRequested}
              variant="secondary"
              className="ml-lg"
              onClick={handleEditTest}
              data-testid="edit-test-and-re-upload"
            >
              Edit test
            </Button>
            {wasSuccessfullyRequested && (
              <div className="text-md font-bold">
                <div className="mt-xl">This test has been launched</div>
                <div className="mt-sm">
                  <Link className="text-link mr-xs" to={websiteContentPaths.flexcaseResults}>
                    View results
                  </Link>
                  or
                  <Link
                    className="text-link ml-xs"
                    to={{
                      pathname: websiteContentPaths.flexcaseLaunch,
                      search: `?tab=${testRequest.testType}`,
                    }}
                  >
                    launch another test
                  </Link>
                </div>
              </div>
            )}
          </div>
        </PageSection>
        <PageSection>
          <PreviewField label="platform(s) to test on">{platformsToTestOn}</PreviewField>
          <PreviewField label="specific device(s) requirements">
            {deviceRequirements ? deviceRequirements : "None provided"}
          </PreviewField>
          <PreviewField label="localisation requirements">
            {testLocalisation ? testLocalisation : "None provided"}
          </PreviewField>
          <PreviewField label="test cases">
            {testCaseUploaderEnabled || testRailsTestCaseImporterEnabled ? (
              <PreviewTestCases testCases={testCases || []} shortViewCasesLimit={SHORT_VIEW_CASES_LIMIT} />
            ) : testCasesUrl ? (
              <a href={testCasesUrl} className="text-link underline text-body">
                {testCasesUrl}
              </a>
            ) : (
              "None provided"
            )}
          </PreviewField>
          <PreviewField label="known issues list">
            {knownIssuesUrl ? (
              <a href={knownIssuesUrl} className="text-link underline text-body">
                {knownIssuesUrl}
              </a>
            ) : (
              "None provided"
            )}
          </PreviewField>
        </PageSection>
      </div>
      <div style={{ lineHeight: "normal" }}>
        <SuccessModal
          formattedTestLaunchDateTime={formattedTestLaunchDateTime}
          resultsUrl={testResultsUrl}
          isOpen={showSuccessModal}
          onCloseModalClicked={() => setShowSuccessModal(false)}
          shouldAskForOrganizationChosenName={hasNoPreviousTests && !isOrganizationNameChosen}
        />
      </div>
      <TestRequestErrorModal isOpen={showErrorModal} onCloseModalClicked={() => setShowErrorModal(false)} />
    </>
  );
}

export default AdvancedTestContent;
