import React, { useEffect } from "react";
import { useHistory, Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { RedirectLoginOptions } from "@auth0/auth0-spa-js";
import NotFoundPage from "components/not_found_page";
import { websiteContentPaths } from "components/configuration";
// @ts-ignore
import { Loader } from "@hopper/loading";

// Used to force logout user from Auth0 actions while forwarding some query params
// In the future, it might be our own page that's just using Auth0 SDK on it for login form.
// It doesn't call logout because this was already triggered by the Auth0 action
// Flow:
//
// 1. User logs in
// 2. Auth0 runs actions and wants to force logout given user
// 3. Auth0 action redirects user to Auth0 endpoint for logout
// 4. Auth0 redirects to another URL(this page) where we may clean rest of the app's state
// 5. User is redirects to login

type LoginWithRedirect = (options?: RedirectLoginOptions) => void;
const redirectLogin = (loginWithRedirect: LoginWithRedirect, forwardedQueryParams: string[]) => {
  const searchParams = new URLSearchParams(window.location.search || "");
  const loginParams: RedirectLoginOptions = {
    rootUrl: window.location.origin,
    appState: { returnTo: websiteContentPaths.root },
    authorizationParams: {},
  };

  forwardedQueryParams.forEach(name => {
    const value = searchParams.get(name);
    if (value) {
      loginParams[name] = value;
    }
  });

  loginWithRedirect(loginParams);
};

const LogoutRedirect = () => {
  const { loginWithRedirect } = useAuth0();
  const allowedQueryParams = ["error", "error_description"];

  // Good place to clean up any additional session/cookies on SPA side

  localStorage.setItem("isAuthenticated", "false");
  useEffect(() => {
    redirectLogin(loginWithRedirect, allowedQueryParams);
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};

const LoginRedirect = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();
  const allowedQueryParams = ["sso"];

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        history.push(websiteContentPaths.root);
      } else {
        redirectLogin(loginWithRedirect, allowedQueryParams);
      }
    }
  }, [isLoading, isAuthenticated]);

  return (
    <>
      <Loader />
    </>
  );
};

function AuthPage(): JSX.Element {
  return (
    <Switch>
      <Route path={websiteContentPaths.authLogout} component={LogoutRedirect} />
      <Route path={websiteContentPaths.authLogin} component={LoginRedirect} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default AuthPage;
