import React from "react";
import { StatusLabel, StatusLabelProps } from "@hopper/status_label";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type AutomaticTesterManagementLabelProps = {
  automaticTesterManagementEnabled: boolean;
};

const gelLabelData = (
  automaticTesterManagementEnabled: AutomaticTesterManagementLabelProps["automaticTesterManagementEnabled"],
) => {
  switch (automaticTesterManagementEnabled) {
    case true:
      return {
        label: "Automated",
        variant: "default",
        description: "Tester removals and backup invites are managed automatically",
        tooltip: (
          <div className="text-start">
            <p>Tester will be automatically removed for the cases when:</p>
            <ul className="list-disc list-inside">
              <li>Tester does not start testing</li>
            </ul>
          </div>
        ),
      };
    case false:
      return {
        label: "Manual",
        variant: "warning",
        description: "Testers need to be manually invited and removed",
        tooltip: null,
      };
    default:
      return null;
  }
};

export const AutomaticTesterManagementLabel = ({
  automaticTesterManagementEnabled,
}: AutomaticTesterManagementLabelProps) => {
  const labelData = gelLabelData(automaticTesterManagementEnabled);

  if (labelData === null) return null;

  return (
    <div className="flex gap-sm items-center" data-testid="automatic-selection-label">
      <StatusLabel variant={labelData.variant as StatusLabelProps["variant"]}>{labelData.label}</StatusLabel>
      <span className="font-bold text-sm">{labelData.description}</span>
      {labelData.tooltip && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="automatic-testers-selection-tooltip">{labelData.tooltip}</Tooltip>}
        >
          <i className="fa fa-info-circle text-dark" aria-hidden="true" />
        </OverlayTrigger>
      )}
    </div>
  );
};
