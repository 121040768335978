import React from "react";
import { Button } from "@hopper/button";

import { useCloseTestWindow } from "./use_close_test_window";

export type EndTestButtonProps = {
  testId: string;
  onClick: () => void;
};

export const EndTestButton = ({ testId, onClick }: EndTestButtonProps) => {
  const { closeTestWindow } = useCloseTestWindow();

  const handleClick = async () => {
    onClick();
    await closeTestWindow(testId);
  };

  return (
    <Button variant="danger" size="sm" data-testid="end-test-button" onClick={handleClick}>
      End this test run
    </Button>
  );
};
