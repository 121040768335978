import React, { useEffect } from "react";
import { useField } from "formik";
import classnames from "classnames";

import { TestCase } from "types/test_cases";
import { FileUpload } from "components/flexible_testing/shared/test_cases/file_upload";
import { Section } from "components/flexible_testing/shared/section";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { CSVBoxConfig } from "components/configuration";

type TestCasesProps = {
  initialTestCaseSectionNumber: number;
};

const TestCases = ({ initialTestCaseSectionNumber: number }: TestCasesProps): JSX.Element => {
  const [includeTestCases, _includeTestCasesMeta, { setValue: setIncludeTestCases }] =
    useField<boolean>("includeTestCases");
  const [testCasesFile, _testCasesFileMeta, { setValue: setTestCasesFile }] = useField<File | null>("testCasesFile");
  const [testCases, _testCasesMeta, { setValue: setTestCases }] = useField<Array<TestCase> | null>("testCases");

  useEffect(() => {
    if (!includeTestCases.value && testCasesFile.value !== null) {
      setTestCasesFile(null);
    }
    if (!includeTestCases.value && testCases.value !== null) {
      setTestCases(null);
    }
  }, [includeTestCases.value]);

  const radioButtonStyle = { marginTop: 0, marginRight: "0.5rem", accentColor: "#373A40" };
  const testCasesClassNames = classnames(["mt-4xl", { hidden: !includeTestCases.value }]);

  return (
    <>
      <PageSection title="Does this test run include test cases?" tooltip={createTooltip()}>
        <div data-test-role="radio" className="text-xs">
          <label className="inline-flex justify-center items-center mr-3xl cursor-pointer">
            {/* style is used instead of className to override Bootstrap*/}
            <input
              style={radioButtonStyle}
              checked={includeTestCases.value}
              type="radio"
              onChange={() => setIncludeTestCases(true)}
              name="include_test_cases"
              value="true"
              data-testid="include-test-cases"
            />
            Yes
          </label>

          <label className="ml-xs inline-flex justify-center items-center cursor-pointer">
            {/* style is used instead of className to override Bootstrap*/}
            <input
              style={radioButtonStyle}
              checked={!includeTestCases.value}
              type="radio"
              onChange={() => setIncludeTestCases(false)}
              name="include_test_cases"
              value="false"
            />
            <span>No</span>
          </label>
        </div>
      </PageSection>
      <Section title="Add your test cases" className={testCasesClassNames}>
        <p className="font-normal text-secondary text-sm max-w-sm mb-xl">
          If you’re importing test cases from a file, please use our test case
          <br />
          template format. You can also manually enter your test cases
        </p>
        <FileUpload
          licenseKey={CSVBoxConfig.expressTestsLicenseKey}
          uploadedTestCases={testCases.value}
          onTestCasesUpload={setTestCases}
          isAdditionalInformationColumnEnabled={false}
        />
      </Section>
    </>
  );
};

function createTooltip() {
  return (
    <>
      <p className="mb-sm text-left">
        Our testers will look for issues in your application through exploratory testing.
      </p>
      <p className="text-left">You can also add test cases for them to execute.</p>
    </>
  );
}

export default TestCases;
