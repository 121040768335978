import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";

type FailedTestCasesProps = {
  failedTestCasesCount: number;
  totalTestCasesCount: number;
};

function FailedTestCases({ failedTestCasesCount, totalTestCasesCount }: FailedTestCasesProps): JSX.Element {
  const tooltip = (
    <Tooltip id="failedTestCasesTooltip" className="text-xs leading-sm">
      <span className="w-sm h-sm inline-block rounded-full mr-xs bg-danger" /> {failedTestCasesCount} failed test cases
    </Tooltip>
  );

  return (
    <OverlayTrigger overlay={tooltip} placement="right">
      <span>{totalTestCasesCount}</span>
    </OverlayTrigger>
  );
}

export default FailedTestCases;
