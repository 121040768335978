import React from "react";

const NoIssuesAcceptedYet = () => (
  <div className="mt-xl">
    <div className="font-bold max-w-md pb-md border-b border-light">No issues have been accepted yet</div>
    <div className="mt-xl">
      To accept an issue, open the issue from the <span className="font-bold">New issues</span> section and select{" "}
      <span className="font-bold">Accept</span>
    </div>
  </div>
);

export default NoIssuesAcceptedYet;
