import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import classnames from "classnames";

export const severities = ["critical", "high", "medium", "low", "unknown"] as const;

type SeverityLabelProps = {
  severity: typeof severities[number];
};

const SeverityLabel = ({ severity }: SeverityLabelProps) => {
  const severityColors = {
    critical: "danger",
    high: "warning",
    medium: "secondary",
    low: "muted",
    unknown: "light",
  };
  const severityColor = severityColors[severity];

  const spanClassNames = classnames(
    "bg-light py-xs px-sm rounded-base whitespace-nowrap",
    "text-body leading-lg font-bold text-xs uppercase tracking-extra-wide",
  );

  return severityColor ? (
    <span className={spanClassNames} data-testid={`severity-indicator-${severity}`}>
      <i className={classnames("fa fa-circle mr-xs text-2xs", `text-${severityColor}`)} data-testid="severity-icon" />
      <span>{capitalize(severity)}</span>
    </span>
  ) : null;
};

SeverityLabel.propTypes = {
  severity: PropTypes.string.isRequired,
};

export default SeverityLabel;
