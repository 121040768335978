import { TrackingHook } from "react-tracking";

export default function trackCloning(
  tracking: TrackingHook,
  testId: string,
  organizationId: string | undefined,
  eventName: string,
) {
  tracking.trackEvent({
    action: "track",
    name: eventName,
    eventProperties: {
      organization_id: organizationId,
      test_id: testId,
    },
  });
}
