import React from "react";

type RejectionReasonsProps = {
  rejectIssue: (rejectionReason: string) => void;
};

const RejectionReasons = ({ rejectIssue }: RejectionReasonsProps): JSX.Element => {
  const rejectionReasons = [
    "As designed",
    "Duplicate",
    "Known issue",
    "Not reproducible",
    "Out of scope",
    "Tester error",
    "Poor bug report",
    "Outdated instructions",
    "Other",
  ];

  return (
    <div className="pt-md pb-sm">
      <div className="text-dark mx-xl font-bold font-sm border-b border-light mt-sm pb-sm">Select reason</div>
      {rejectionReasons.map(rejectionReason => (
        <div
          className="hover:bg-light my-sm px-xl py-xs cursor-pointer font-bold"
          data-testid="rejection-reason"
          onClick={() => rejectIssue(rejectionReason)}
        >
          {rejectionReason}
        </div>
      ))}
    </div>
  );
};

export default RejectionReasons;
