import React, { useState } from "react";
import { Button } from "@hopper/button";

import { Test } from "types/graphql";
import { Participant } from "components/flexible_testing/shared/chat";
import { PublishTesterResultsModal } from "components/flexible_testing/shared/publish_tester_results_modal";

type PublishTesterResultsButtonProps = {
  participant: Participant;
  testId: Test["id"];
};

export const PublishTesterResultsButton = ({ participant, testId }: PublishTesterResultsButtonProps) => {
  const [showTesterResultsPublishModal, setShowTesterResultsPublishModal] = useState(false);
  const resultsApproved = participant.status === "results_approved";
  const buttonVariant = resultsApproved ? "primary" : "secondary";
  const buttonAppearance = resultsApproved ? "default" : "ghost";

  return (
    <>
      <Button
        className="m-xs"
        variant={buttonVariant}
        appearance={buttonAppearance}
        data-testid="publish-tester-results-button"
        onClick={() => setShowTesterResultsPublishModal(true)}
      >
        Publish
      </Button>
      <PublishTesterResultsModal
        participant={participant}
        testId={testId}
        isOpen={showTesterResultsPublishModal}
        onClose={() => setShowTesterResultsPublishModal(false)}
      />
    </>
  );
};
