import React, { useState } from "react";
import { FormikErrors, useField } from "formik";
import { FormInlineError } from "@hopper/form_inline_error";
import { Textarea } from "@hopper/textarea";

import { TesterGroupParams } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import FormLabel from "components/flexible_testing/shared/form/form_label";

type ManualSelectionFeedbackFormProps = {
  fieldName: string;
  groupName: string;
  errors: FormikErrors<TesterGroupParams>;
};

const ManualSelectionReasons = [
  "Cannot input the targeting requirements in the UI",
  "This customer requires manual tester selection",
];

const ManualSelectionFeedbackForm = ({ fieldName, groupName, errors }: ManualSelectionFeedbackFormProps) => {
  const [_reasonField, { value: reasonValue, error, touched }, { setValue: setReasonValue }] = useField({
    name: `${fieldName}.automaticTesterSelectionDisabledReason`,
  });
  const isOtherReason = (reason: string) => !ManualSelectionReasons.includes(reason);
  const [otherReasonFieldVisible, setOtherReasonFieldVisible] = useState(
    isOtherReason(reasonValue) && reasonValue !== "",
  );
  const handleReasonChange = (reason: string) => {
    if (isOtherReason(reason)) {
      setReasonValue(reason);
      setOtherReasonFieldVisible(true);
    } else {
      setOtherReasonFieldVisible(false);
      setReasonValue(reason);
    }
  };

  return (
    <div data-testid={`feedback-${groupName}`}>
      <div className="mt-md">
        <FormLabel
          label="What is the reason that you need to use manual selection?"
          required={true}
          labelProps={{ htmlFor: `${fieldName}.automaticTesterSelectionDisabledReason` }}
        />

        {error && touched && (
          <div className="mb-sm">
            <FormInlineError>{error}</FormInlineError>
          </div>
        )}

        {ManualSelectionReasons.map(reason => (
          <div key={reason}>
            <label className="ml-xs inline-flex justify-center items-center cursor-pointer">
              <input checked={reasonValue === reason} type="radio" onChange={() => handleReasonChange(reason)} />
              <span className="ml-sm">{reason}</span>
            </label>
          </div>
        ))}
      </div>
      <div>
        <div>
          <label className="ml-xs inline-flex justify-center items-center cursor-pointer">
            <input checked={otherReasonFieldVisible} type="radio" onChange={() => handleReasonChange("")} />
            <span className="ml-sm">Other</span>
          </label>
        </div>

        {otherReasonFieldVisible && (
          <div className="text-xs mb-xl">
            <Textarea
              placeholder="Provide the other reason"
              value={isOtherReason(reasonValue) ? reasonValue : ""}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setReasonValue(e.target.value);
              }}
            />
            {errors?.automaticTesterSelectionDisabledReason && (
              <div className="text-danger text-xs">{errors?.automaticTesterSelectionDisabledReason}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualSelectionFeedbackForm;
