import { StreamChat } from "stream-chat";
import { useEffect, useState } from "react";
import { StreamChatConfig } from "components/configuration";
import { useStreamCurrentUser } from "./use_stream_current_user";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { useChatContext } from "./chat_client_context";

function displayError(errorMessage: string) {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: errorMessage,
  });
}

function useChat() {
  const streamCurrentUser = useStreamCurrentUser();
  const { setClient, registerChannels } = useChatContext();
  const [isLoading, setIsLoading] = useState(true);

  const connectUser = async (client: StreamChat) => {
    if (!streamCurrentUser) return client;
    if (client?.userID === streamCurrentUser.publicId) return client;

    try {
      await client.connectUser(
        {
          id: streamCurrentUser.publicId,
          name: streamCurrentUser.fullName,
        },
        streamCurrentUser.streamChatToken,
      );

      return client;
    } catch {
      throw new Error("An error occurred while connecting to the chat");
    }
  };

  useEffect(() => {
    if (!streamCurrentUser) return;

    const client = StreamChat.getInstance(StreamChatConfig.apiKey);

    connectUser(client)
      .then(returnedClient => {
        setClient(returnedClient);
      })
      .catch(err => {
        displayError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [streamCurrentUser?.id]);

  return { isLoading, registerChannels };
}

export { useChat };
