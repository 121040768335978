import React from "react";
import { differenceInDays, parseISO, startOfDay } from "date-fns";
import pluralize from "pluralize";
import logo from "assets/images/favicon.png";
import SideMenu from "./components/side_menu";
import MainMenu from "./components/main_menu";
import { OrganizationUser, Maybe } from "types/graphql";
import CollapsedMenu from "./components/collapsed_menu";
import { StatusLabel } from "@hopper/status_label";
import CreditAccountBalance from "./components/credit_account_balance";
import { useCurrentUser } from "app/current_user/use_current_user";

// Declaring it here as it is not exported from hopper component
type Variant = "default" | "transparent" | "neutral" | "danger";

function HeaderNavigation(): JSX.Element {
  const currentUser = useCurrentUser();
  if (!currentUser) return <></>;

  const { variant, text } = trialLabelProps(currentUser);

  const isInTrial = currentUser.trial && !currentUser.trial?.finishedAt;
  const creditAccountBalance = currentUser.organization.creditAccount?.balance;
  const hasCreditModelAccess = currentUser.organization.creditModelAccess || false;

  return (
    <nav
      className="z-100 w-full top-0 left-0 fixed rounded-none
        mb-0 bg-lightest xl:px-4xl xl:py-lg"
    >
      <div className="flex ml-lg xl:ml-0 h-6xl justify-between xl:justify-around">
        <div className="flex items-center">
          <img src={logo} className="w-5xl h-5xl" />
          <div>
            <div className="flex items-center">
              <span className="ml-md mr-xs font-bold text-xl text-body uppercase tracking-widest">
                {currentUser.organization.chosenName}
              </span>
              {isInTrial && (
                <div className="leading-100">
                  <StatusLabel variant={variant}>{text}</StatusLabel>
                </div>
              )}
            </div>
            {hasCreditModelAccess && (
              <div className="ml-xs pl-xs">
                <CreditAccountBalance balance={creditAccountBalance} />
              </div>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="hidden xl:block">
            <MainMenu />
          </div>
        </div>
        <div className="flex justify-end">
          <div className="hidden xl:block">
            <SideMenu shouldShowCreditModelMenuOption={hasCreditModelAccess} />
          </div>
          <div className="xl:hidden flex items-center">
            <CollapsedMenu shouldShowCreditModelMenuOption={hasCreditModelAccess} />
          </div>
        </div>
      </div>
    </nav>
  );
}

function trialLabelProps(currentUser?: Maybe<OrganizationUser>) {
  if (!currentUser) {
    return {};
  }

  const finishesAtDate = startOfDay(parseISO(currentUser?.trial?.finishesAt));
  const today = startOfDay(new Date());
  const daysUntilTrialEnds = differenceInDays(finishesAtDate, today);

  if (daysUntilTrialEnds <= 0) {
    const message = "Trial expired";

    return { variant: "danger" as Variant, text: message };
  } else if (daysUntilTrialEnds <= 2) {
    const message = `${daysUntilTrialEnds} ${pluralize("day", daysUntilTrialEnds)} left`;

    return { variant: "danger" as Variant, text: message };
  } else {
    const message = `${daysUntilTrialEnds} days left`;

    return { variant: "neutral" as Variant, text: message };
  }
}

export default HeaderNavigation;
