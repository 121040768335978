import React, { useState, useEffect } from "react";

import { Select, TextInput } from "components/shared/form";
import { withJiraComponents } from "./with_jira_components";

const JiraExportFields = ({
  projects,
  integrationId,
  loadJiraComponents,
  namespace,
  labelClass,
  projectKey,
  issuetypeName,
  componentId,
}) => {
  const getProject = projectKey => projects?.find(({ key }) => key === projectKey);

  const getProjectIssueTypes = projectKey => {
    const project = getProject(projectKey);

    if (project) {
      return project.issuetypes.map(({ name: value, error }) => ({
        value,
        label: error ? `${value} (${error})` : value,
        isDisabled: !!error,
      }));
    } else {
      return [];
    }
  };

  const renderIssuetypesError = () => (
    <p className="bg-warning mb-lg p-lg">
      Some issue types are disabled because they require fields that are not sent while exporting. Please modify your
      JIRA settings if you'd like to use this issue type.
    </p>
  );

  const updateComponentOptions = projectKey => {
    if (projectKey) {
      setIsLoadingComponentsInProgress(true);

      loadJiraComponents(projectKey, integrationId).then(result => {
        setComponents(result.map(({ id: value, name: label }) => ({ value, label })));

        setIsLoadingComponentsInProgress(false);
      });
    }
  };

  const getIssuetypeOption = issuetypeName => issuetypeOptions.find(({ value }) => value === issuetypeName);
  const handleIssueTypeChange = value => setSelectedIssueType(getIssuetypeOption(value));

  const getProjectOption = projectKey => projectOptions?.find(({ value }) => value === projectKey);

  const getComponentOption = componentId => components && components.find(({ value }) => value === componentId);
  const handleComponentChange = value => setSelectedComponent(getComponentOption(value));

  const projectOptions = projects?.map(({ key: value, name: label }) => ({ value, label }));
  const [selectedProject, setSelectedProject] = useState(getProjectOption(projectKey));

  const [issuetypeOptions, setIssuetypeOptions] = useState(getProjectIssueTypes(projectKey));
  const [selectedIssueType, setSelectedIssueType] = useState(getIssuetypeOption(issuetypeName));
  const [showIssuetypesError, setShowIssuetypesError] = useState(false);

  const [isLoadingComponentsInProgress, setIsLoadingComponentsInProgress] = useState();
  const [components, setComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState();

  const projectDependentFieldsProps = selectedProject
    ? {}
    : {
        placeholder: "Select project first",
        isDisabled: true,
      };

  function onProjectChange(projectKey) {
    const issuetypes = getProjectIssueTypes(projectKey);
    const showIssuetypesError = !!issuetypes.find(({ isDisabled }) => isDisabled);

    updateComponentOptions(projectKey);
    setSelectedProject(getProjectOption(projectKey));
    setIssuetypeOptions(issuetypes);
    setShowIssuetypesError(showIssuetypesError);
    setSelectedIssueType(null);
  }

  useEffect(() => updateComponentOptions(projectKey), projectKey);
  useEffect(() => handleComponentChange(componentId), components);

  return (
    <>
      <div className="mb-lg" data-test-role="form-group">
        <Select
          required
          name={namespace ? `${namespace}.project_key` : "project_key"}
          label="Project"
          options={projectOptions}
          onChange={onProjectChange}
          clearable={false}
          labelClass={labelClass}
          data-testid="project-key-input"
          value={selectedProject}
        />
      </div>
      <div className="mb-lg" data-test-role="form-group">
        <Select
          required
          name={namespace ? `${namespace}.issuetype_name` : "issuetype_name"}
          label="Issue Type"
          options={issuetypeOptions}
          {...projectDependentFieldsProps}
          value={selectedIssueType}
          onChange={handleIssueTypeChange}
          labelClass={labelClass}
          data-testid="issuetype-name-input"
        />
      </div>
      {showIssuetypesError && renderIssuetypesError()}
      <div className="mb-lg" data-test-role="form-group">
        <Select
          isAsync
          isLoading={isLoadingComponentsInProgress}
          defaultOptions={components}
          name={namespace ? `${namespace}.componentId` : "componentId"}
          label="Component"
          {...projectDependentFieldsProps}
          value={selectedComponent}
          onChange={handleComponentChange}
          labelClass={labelClass}
          data-testid="component-id-input"
        />
      </div>
      <div className="mb-lg" data-test-role="form-group">
        <TextInput
          id="labels"
          name={namespace ? `${namespace}.labels` : "labels"}
          label="Labels (comma separated values)"
          placeholder="bug, critical"
          className="form-control"
          labelClass={labelClass}
          data-testid="labels-input"
        />
      </div>
    </>
  );
};

export default withJiraComponents(JiraExportFields);
