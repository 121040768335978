import React from "react";
// @ts-ignore
import { websiteContentPaths } from "components/configuration";
import { usePreviousTestAvailable } from "components/flexible_testing/shared/use_previous_test_available";
import { CloneTestRunButton } from "components/flexible_testing/shared/clone_test_run_button";

export default function StandardTestInfoSection(): JSX.Element {
  const { isLoading, hasNoPreviousTests } = usePreviousTestAvailable();

  return (
    <div className="mt-3xl mb-3xl grid grid-cols-1 md-grid-cols-2">
      <div>
        <div className="text-xl font-black">Test your build quickly and find issues before you release</div>
        <div className="text-sm mt-xs">Express tests deliver on demand test execution in 6 hours or less</div>
      </div>
      <CloneTestRunButton
        testsPath={websiteContentPaths.flexcaseTestsForCloning}
        isLoading={isLoading}
        hasNoPreviousTests={hasNoPreviousTests}
      />
    </div>
  );
}
