import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingIssueAcceptInput, FlexibleTestingIssueAcceptPayload, IssueSeverity } from "types/graphql";

export const ACCEPT_ISSUE = gql`
  mutation FlexibleTestingIssueAccept($input: FlexibleTestingIssueAcceptInput!) {
    flexibleTestingIssueAccept(input: $input) {
      successful
      result {
        id
        triageStatus
        test {
          triagedIssuesCount
        }
      }
    }
  }
`;

type useIssueAcceptResult = {
  acceptIssue: (issueId: string, newSeverity?: IssueSeverity) => Promise<void>;
};

export const useIssueAccept = (issueId: string): useIssueAcceptResult => {
  const [acceptIssueMutation] = useMutation<
    { flexibleTestingIssueAccept: FlexibleTestingIssueAcceptPayload },
    { input: FlexibleTestingIssueAcceptInput }
  >(ACCEPT_ISSUE);

  return {
    acceptIssue: async (issueId: string, newSeverity?: IssueSeverity): Promise<void> => {
      await acceptIssueMutation({
        variables: {
          input: {
            issueId,
            newSeverity,
          },
        },
      });
    },
  };
};
