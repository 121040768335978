import React from "react";
import { Body, BodyCell, Header, HeaderCell, Row, Table } from "components/flexible_testing/shared/table";
import { format, parseISO } from "date-fns";
import useApiKeys from "./use_api_keys";
import { RevokeApiKeyButton } from "./revoke_api_key_button";

type ApiKeysTableProps = {
  onLoadingFinished?: () => void;
};

function ExistingApiKeysTable({ onLoadingFinished }: ApiKeysTableProps): JSX.Element {
  const { data, isLoading } = useApiKeys({ onLoadingFinished });

  return (
    <Table>
      <Header>
        <HeaderCell className="text-dark pb-sm pl-xs">KEY</HeaderCell>
        <HeaderCell className="text-dark pb-sm">DATE CREATED</HeaderCell>
        <HeaderCell className="text-dark pb-sm">STATUS</HeaderCell>
        <HeaderCell className="text-dark pb-sm" />
      </Header>
      <Body>
        {!data.length && !isLoading ? (
          <Row className="text-sm">
            <BodyCell className="text-center text-dark" colSpan={3}>
              You don't have an API key
            </BodyCell>
          </Row>
        ) : (
          data.map(apiKey => (
            <Row key={apiKey.id} className="border-muted border-b text-sm" testId="api-key-list-row">
              <BodyCell>{apiKey.apiKeyDigest}...</BodyCell>
              <BodyCell>{apiKey.createdAt ? format(parseISO(apiKey.createdAt), "d MMM yyyy") : ""}</BodyCell>
              <BodyCell>
                <i className={`inline ${apiKey.active ? "fa fa-circle text-success" : "fa fa-circle text-danger"}`} />
                <span data-testid="api-key-status" className="pl-sm">
                  {apiKey.active ? "Active" : "Inactive"}
                </span>
              </BodyCell>
              <BodyCell>
                <RevokeApiKeyButton apiKeyId={apiKey.id} isActive={apiKey.active} />
              </BodyCell>
            </Row>
          ))
        )}
      </Body>
    </Table>
  );
}

export default ExistingApiKeysTable;
