import React from "react";
// @ts-ignore
import { TestCase } from "types/test_cases";
import CSVBoxUploader from "./csvbox_uploader";

type FileUploadProps = {
  uploadedTestCases: Array<TestCase> | null;
  className?: string;
  onTestCasesUpload: (testCases: Array<TestCase> | null) => void;
  isAdditionalInformationColumnEnabled: boolean;
  downloadCsvFile?: () => Promise<void>;
  licenseKey: string;
};

const FileUpload = ({
  uploadedTestCases,
  onTestCasesUpload,
  className,
  isAdditionalInformationColumnEnabled,
  downloadCsvFile,
  licenseKey,
}: FileUploadProps) => (
  <div className={className}>
    <CSVBoxUploader
      licenseKey={licenseKey}
      uploadedTestCases={uploadedTestCases}
      onTestCasesUpload={onTestCasesUpload}
      isAdditionalInformationColumnEnabled={isAdditionalInformationColumnEnabled}
      downloadCsvFile={downloadCsvFile}
    />
  </div>
);

export default FileUpload;
