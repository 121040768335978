import React from "react";
import { SubmitButton } from "components/flexible_testing/shared/form";
import { useField, useFormikContext } from "formik";
import { Loader } from "@hopper/loading";
import { FormField } from "@hopper/form_field";

type TagFormFieldsProps = {
  usageCount: number | null | undefined;
  isLoading: boolean;
  displayDeleteConfirmation: boolean;
  tagName: string;
};

const TagFormFields = ({ usageCount, isLoading, displayDeleteConfirmation, tagName }: TagFormFieldsProps) => {
  const { dirty, isSubmitting } = useFormikContext();
  const [field, meta] = useField("issueTagName");

  if (isLoading) return <Loader spinnerSize={32} />;

  return (
    <div>
      <div className="flex">
        {displayDeleteConfirmation && (
          <div className="font-bold text-sm text-body">{`Are you sure you want to delete "${tagName}"?`}</div>
        )}
        {!displayDeleteConfirmation && (
          <FormField errorMessage={meta.touched && meta.error}>
            <div className="flex flex-grow justify-items-stretch">
              <div data-test-role="form-group" className="text-xs">
                <input
                  data-testid="tag-name-input"
                  className="border border-light pl-sm font-normal
              text-xs rounded-base focus:outline-none focus:border-light py-sm
              leading-sm rounded-l-base rounded-r-none w-full"
                  placeholder="Edit tag name..."
                  {...field}
                />
              </div>
              <SubmitButton
                className="py-sm leading-sm rounded-r-base rounded-l-none flex-grow-0 break-normal"
                disabled={!dirty}
                size="sm"
                useHopperButton={true}
                data-testid="submit-tag-form"
              >
                {isSubmitting ? (
                  <i data-testid="spinning-circle-submit-button" className="fa fa-circle-o-notch fa-spin mr-xs" />
                ) : (
                  "Save"
                )}
              </SubmitButton>
            </div>
          </FormField>
        )}
      </div>
      <div className="mt-md text-secondary text-xs">
        <span className="font-bold">{usageCount} issues</span> currently use this tag
      </div>
      {usageCount && usageCount > 0 ? (
        <div className="mt-md text-secondary text-xs">
          Changes made to this tag will apply to all instances where it is used
        </div>
      ) : null}
    </div>
  );
};

export default TagFormFields;
