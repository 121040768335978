import React from "react";
import { Button } from "@hopper/button";

import { useToggleAutoExportSettings } from "./use_toggle_auto_export_settings";

type AutoExportToggleButtonProps = {
  autoExportEnabled: boolean;
};

const AutoExportToggleButton = ({ autoExportEnabled }: AutoExportToggleButtonProps): JSX.Element => {
  const { toggleAutoExportSettings } = useToggleAutoExportSettings();

  return (
    <Button appearance="minimal" variant="secondary" size="sm" onClick={toggleAutoExportSettings}>
      {autoExportEnabled ? "Turn off auto export" : "Turn on auto export"}
    </Button>
  );
};

export default AutoExportToggleButton;
