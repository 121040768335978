import { gql, useMutation } from "@apollo/client";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import {
  CreateCustomerRequestInput,
  CreateCustomerRequestPayload,
  ActivityEnum,
  TestEnvironmentPlatform,
  Scalars,
} from "types/graphql";
import { TargetingGroup } from "../requested_test_page/targeting_step_content";
import { useCustomerRequestContext } from "../customer_request_context";
import { RequestedTest as Test } from "../requested_test_page/requested_test_form";
import { websiteContentPaths } from "components/configuration";

export const CREATE_CUSTOMER_REQUEST_MUTATION = gql`
  mutation ($input: CreateCustomerRequestInput!) {
    createCustomerRequest(input: $input) {
      result {
        id
        name
      }
      errors {
        message
        code
        path
      }
      successful
    }
  }
`;

function prepareTargetingGroups(targetingGroups: TargetingGroup[], hasCustomTargetingRequirements: boolean) {
  return hasCustomTargetingRequirements
    ? []
    : targetingGroups.map(targetingGroup => ({
        mobileDeviceIds: targetingGroup.devices.map(device => device.id),
        operatingSystemVersionIds: targetingGroup.operatingSystemVersions.map(os => os.id),
        countryIds: targetingGroup.countries.map(country => country.id),
        languageIds: targetingGroup.languages.map(language => language.id),
        operatingSystemDeviceTypeIds: targetingGroup.operatingSystemDeviceTypes.map(deviceType => deviceType.id),
        internetBrowserIds: targetingGroup.internetBrowsers.map(browser => browser.id),
      }));
}

export function prepareRequestedTests(tests: Test[]) {
  return tests.map(test => ({
    name: test.name,
    activity: test.activity as unknown as ActivityEnum,
    applicationType: test.applicationType as TestEnvironmentPlatform,
    applicationName: test.applicationName,
    applicationVersion: test.applicationVersion,
    accessToApplicationUrl: test.accessToApplicationUrl,
    requiresAccessToSecureEnvironment: test.requiresAccessToSecureEnvironment === "true",
    accessToSecureEnvironmentInstructions: test.accessToSecureEnvironmentInstructions,
    requiresPredefinedUserAccounts: test.requiresPredefinedUserAccounts === "true",
    predefinedUserAccountsInstructions: test.predefinedUserAccountsInstructions,
    requiresFinancialTransactions: test.requiresFinancialTransactions === "true",
    financialTransactionsInstructions: test.financialTransactionsInstructions,
    requiresKycIdentityCheck: test.requiresKycIdentityCheck === "true",
    kycIdentityCheckInstructions: test.kycIdentityCheckInstructions,
    requiresPersonalIdentifyingInformation: test.requiresPersonalIdentifyingInformation === "true",
    personalIdentifyingInformationInstructions: test.personalIdentifyingInformationInstructions,
    requiresMovingToDifferentLocation: test.requiresMovingToDifferentLocation === "true",
    movingToDifferentLocationInstructions: test.movingToDifferentLocationInstructions,
    knownIssuesListUrl: test.knownIssuesListUrl,
    testCases: test.testCases,
    numberOfResultsPerTestCaseNeeded: Number(test.numberOfResultsPerTestCaseNeeded),
    inScope: test.inScope,
    outOfScope: test.outOfScope,
    targetingGroups: prepareTargetingGroups(test.targetingGroups, !!test.customTargetingRequirements),
    customTargetingRequirements: test.customTargetingRequirements,
  }));
}

function displayError(errorMessage: string) {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: errorMessage || "There was an issue with launching test.",
  });
}

function useCreateCustomerRequest() {
  const history = useHistory();
  const { clearContext } = useCustomerRequestContext();

  const [createCustomerRequest, { loading }] = useMutation<
    { createCustomerRequest: CreateCustomerRequestPayload },
    { input: CreateCustomerRequestInput }
  >(CREATE_CUSTOMER_REQUEST_MUTATION, {
    onCompleted: ({ createCustomerRequest: { result, successful, errors } }) => {
      if (successful && result?.name) {
        history.push(websiteContentPaths.successfulCustomerRequest, result.name);
        clearContext();
      } else {
        const errorMessage = errors.map(({ message }) => message).join(", ");

        displayError(errorMessage);
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = graphQLErrors.map(({ message }) => message).join(", ");

      displayError(errorMessage);
    },
  });

  return {
    createCustomerRequest: async (name: string, tests: Test[], scheduleAt?: Scalars["ISO8601DateTime"]) => {
      const convertedScheduleAt = scheduleAt ? format(new Date(scheduleAt), "yyyy'-'LL'-'dd'T'HH':'mm':'ssxx") : null;
      const requestedTests = prepareRequestedTests(tests);

      await createCustomerRequest({
        variables: {
          input: {
            name,
            scheduleAt: convertedScheduleAt,
            requestedTests,
          },
        },
      });
    },
    isLoading: loading,
  };
}

export default useCreateCustomerRequest;
