import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@hopper/button";
import { Form, GeneralError, SubmitButton } from "components/shared/form";
import styles from "./styles.module.css";

const ExportIntegrationForm = ({ onReset, onSubmit, initialValues, validations, children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = values => {
    setIsLoading(true);

    return onSubmit(values).catch(error => {
      setIsLoading(false);
      throw error;
    });
  };

  return (
    <Form
      className={`mt-2xl mx-auto ${styles.exportForm}`}
      onSubmit={handleSubmit}
      onReset={onReset}
      initialValues={initialValues}
      validations={validations}
    >
      <GeneralError />

      {children}

      <div className="form-group float-right mt-lg mb-3xl text-sm">
        <Button size="sm" appearance="minimal" variant="secondary" type="reset" className="mr-lg">
          Cancel
        </Button>
        <SubmitButton useHopperButton={true} loading={isLoading} variant="success" size="sm" className="px-lg">
          Export
        </SubmitButton>
      </div>
    </Form>
  );
};

ExportIntegrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  validations: PropTypes.object,
};

export default ExportIntegrationForm;
