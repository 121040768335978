import React, { useEffect, useState } from "react";
import { useMutation, FetchResult, MutationFunctionOptions } from "@apollo/client";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { LoadingOverlay } from "@hopper/loading";
import { History } from "history";

import { JiraConnectForm } from "./jira_connect_form";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
// @ts-ignore
import { createNavigationUrl } from "app/helpers/url_helper";
import { ClientAuthenticationProps } from "../integration_page";
import { INTEGRATION_UPDATE } from "components/shared/integration/integration_queries";

export const JiraConnect = ({ integration }: ClientAuthenticationProps) => {
  const [isCompletingOauthFlow, setIsCompletingOauthFlow] = useState(true);
  const history = useHistory();
  const [updateIntegration] = useMutation(INTEGRATION_UPDATE, {
    onCompleted: ({ integrationUpdate: { successful } }) => {
      if (successful) {
        createSuccessNotification();
      }
    },
    onError: () => {
      createErrorNotification();
    },
  });

  useEffect(() => {
    checkForParams(setIsCompletingOauthFlow, updateIntegration, history);
  }, []);

  return (
    <LoadingOverlay isLoaded={!isCompletingOauthFlow}>
      <JiraConnectForm integration={integration} />
    </LoadingOverlay>
  );
};

async function checkForParams(
  setIsCompletingOauthFlow: (newValue: boolean) => void,
  updateIntegration: (options: MutationFunctionOptions) => Promise<FetchResult>,
  history: History,
) {
  const queryParams = queryString.parse(window.location.search);

  if (
    queryParams.client_key &&
    queryParams.issuer &&
    queryParams.site_url &&
    queryParams.shared_secret &&
    queryParams.success_redirect_url &&
    queryParams.failure_redirect_url
  ) {
    // remove query params, to avoid reusing them in case of getting back to the page via history
    history.replace(createNavigationUrl("integrationSetting", { code: "jira_connect" }));

    try {
      const params = {
        variables: {
          input: {
            code: "jira_connect",
            jiraConnectAuthenticationParams: {
              credentials: {
                client_key: queryParams.client_key,
                issuer: queryParams.issuer,
                site_url: queryParams.site_url,
                shared_secret: queryParams.shared_secret,
              },
            },
          },
        },
      };

      await updateIntegration(params)
        .then(result => {
          if (result.data?.integrationUpdate?.successful) {
            window.location.href = queryParams.success_redirect_url;
          } else {
            window.location.href = queryParams.failure_redirect_url;
          }
        })
        .catch(() => {
          window.location.href = queryParams.failure_redirect_url;
        });
    } finally {
      setIsCompletingOauthFlow(false);
    }
  } else {
    setIsCompletingOauthFlow(false);
  }
}

function createSuccessNotification() {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Congratulations! 🎉 You've just integrated your GlobalAppTesting account with Jira successfully.",
  });
}

function createErrorNotification() {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "😢 We are sorry, authentication with Jira can't be completed. Please try again or contact our support.",
  });
}
