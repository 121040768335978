import { useQuery } from "@apollo/client";
import { Test } from "types/graphql";
import { GET_SUMMARY_TAB_DATA } from "./summary_query";

function useSummaryTabData(testId?: string) {
  const { loading, data } = useQuery<{
    platformTest: Test;
  }>(GET_SUMMARY_TAB_DATA, {
    variables: { testId },
    skip: !testId,
  });

  return {
    isLoading: loading,
    platformTest: data?.platformTest,
  };
}

export { useSummaryTabData };
