import React from "react";

import { usePreviousTestAvailable } from "components/flexible_testing/shared/use_previous_test_available";
import CloneTestRunButton from "components/flexible_testing/shared/clone_test_run_button/clone_test_run_button";
import { websiteContentPaths } from "components/configuration";

export function StandardTestCloneButton(): JSX.Element {
  const { isLoading, hasNoPreviousTests } = usePreviousTestAvailable();

  return (
    <div className="flex items-center">
      <CloneTestRunButton
        testsPath={websiteContentPaths.flexcaseTestsForCloning}
        isLoading={isLoading}
        hasNoPreviousTests={hasNoPreviousTests}
      />
    </div>
  );
}
