import React, { useState } from "react";
// @ts-ignore
import MediaAttachmentLightbox from "components/organization/results/components/media_attachments_lightbox/media_attachment_lightbox";
import AttachmentThumbnail from "./attachment_thumbnail";
import ImageAttachmentThumbnail from "./image_attachment_thumbnail";
import VideoAttachmentThumbnail from "./video_attachment_thumbnail";
import LogAttachmentThumbnail from "./log_attachment_thumbnail";
import MainImageAttachment from "./main_image_attachment";
import MainVideoAttachment from "./main_video_attachment";

type AttachmentProp = {
  type: string;
  url: string;
};
type MediaAttachmentProp = {
  mainComponent: React.FunctionComponent<MainComponentProps> | undefined;
  thumbnailComponent: React.FunctionComponent<ThumbnailComponentProps> | undefined;
  thumbnailClassNames: string | null | undefined;
  index?: number;
  totalNumber?: number;
} & AttachmentProp;
type AttachmentsProps = {
  attachments: Array<AttachmentProp>;
};

export type MediaForLightboxType = {
  videoSources?: Array<AttachmentProp>;
  image?: string;
};

type MainComponentProps = {
  attachmentUrl: string;
  onClick: () => void;
};
type ThumbnailComponentProps = {
  attachmentUrl: string;
  index?: number;
  totalNumber?: number;
};
type AttachmentVariants = {
  [key in string]: {
    mainComponent: React.FunctionComponent<MainComponentProps>;
    thumbnailComponent: React.FunctionComponent<ThumbnailComponentProps>;
    thumbnailClassNames: string | null;
  };
};

const attachmentVariants: AttachmentVariants = {
  image: {
    mainComponent: MainImageAttachment,
    thumbnailComponent: ImageAttachmentThumbnail,
    thumbnailClassNames: null,
  },
  video: {
    mainComponent: MainVideoAttachment,
    thumbnailComponent: VideoAttachmentThumbnail,
    thumbnailClassNames: "bg-light",
  },
  log: {
    mainComponent: LogAttachmentThumbnail,
    thumbnailComponent: LogAttachmentThumbnail,
    thumbnailClassNames: null,
  },
};

function getAttachmentWeight(attachment: AttachmentProp): number {
  if (attachment.type === "video") {
    return 2;
  } else if (attachment.type === "image") {
    return 1;
  } else {
    return 0;
  }
}

function sortAttachments(attachments: AttachmentProp[]): AttachmentProp[] {
  return [...attachments].sort((firstAttachment, secondAttachment) => {
    const firstVideo = getAttachmentWeight(firstAttachment);
    const secondVideo = getAttachmentWeight(secondAttachment);

    if (firstVideo > secondVideo) return -1;
    if (firstVideo < secondVideo) return 1;

    return 0;
  });
}

function mapAttachmentsToMediaAttachments(attachments: AttachmentProp[]): MediaAttachmentProp[] {
  const sortedAttachments = sortAttachments(attachments);
  const totalLogAttachmentCount = attachments.filter(attachment => attachment.type === "log").length;
  let logAttachmentIndex = 0;

  return sortedAttachments.map(attachment => {
    const attachmentVariant = attachmentVariants[attachment.type];

    if (attachment.type === "log") {
      ++logAttachmentIndex;
    }

    return {
      ...attachment,
      mainComponent: attachmentVariant?.mainComponent,
      thumbnailComponent: attachmentVariant?.thumbnailComponent,
      thumbnailClassNames: attachmentVariant?.thumbnailClassNames,
      index: attachment.type === "log" ? logAttachmentIndex : 0,
      totalNumber: attachment.type === "log" ? totalLogAttachmentCount : 0,
    };
  });
}

function Attachments({ attachments }: AttachmentsProps): JSX.Element {
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setlightboxIsOpen] = useState(false);
  const [attachmentsExpanded, setAttachmentsExpanded] = useState(false);
  const [attachmentsToShow, setAttachmentsToShow] = useState(3);
  const mediaAttachments: MediaAttachmentProp[] = mapAttachmentsToMediaAttachments(attachments);

  const closeLightbox = (): void => {
    setlightboxIsOpen(false);
    setCurrentImage(0);
  };

  const openLightbox = (imageNumber: number): void => {
    setCurrentImage(imageNumber);
    setlightboxIsOpen(true);
  };

  const prepareMediaForLightbox = (mediaAttachments: Array<AttachmentProp>): Array<MediaForLightboxType> =>
    mediaAttachments.map(attachment => {
      if (attachment.type === "video") {
        return {
          videoSources: [{ url: attachment.url, type: "video/mp4" }],
        };
      } else if (attachment.type === "image") {
        return { image: attachment.url };
      } else {
        return { log: attachment.url };
      }
    });

  const displayAttachmentThumbnail = (attachment: MediaAttachmentProp, index: number): JSX.Element | null => {
    const ThumbnailComponent = attachment.thumbnailComponent;
    const onClick = (imageNumber: number) => (attachment.type !== "log" ? openLightbox(imageNumber) : null);

    return ThumbnailComponent ? (
      <AttachmentThumbnail
        key={attachment.url}
        onClick={onClick}
        index={index}
        additionalClassNames={attachment.thumbnailClassNames}
      >
        <ThumbnailComponent
          attachmentUrl={attachment.url}
          index={attachment.index}
          totalNumber={attachment.totalNumber}
        />
      </AttachmentThumbnail>
    ) : null;
  };

  function toggleMoreAttachmentsLink(
    attachments: Array<MediaAttachmentProp>,
    showMore: () => void,
    attachmentsExpanded: boolean,
  ): JSX.Element | null {
    if (attachments.length > 3) {
      return (
        <div onClick={showMore} className="flex items-center text-primary cursor-pointer font-bold">
          {attachmentsExpanded ? <span>Hide</span> : <span>Show {attachments.length - 3} more</span>}
        </div>
      );
    }

    return null;
  }

  const showMore = (): void => {
    if (attachmentsToShow === 3) {
      setAttachmentsToShow(mediaAttachments.length);
      setAttachmentsExpanded(true);
    } else {
      setAttachmentsToShow(3);
      setAttachmentsExpanded(false);
    }
  };

  const attachmentThumbnails = (attachments: Array<MediaAttachmentProp>): JSX.Element | null => {
    if (attachments.length > 0) {
      return (
        <div className="grid grid-cols-4 mt-xl mb-sm gap-xl" data-testid="attachment-thumbnails">
          {attachments
            .slice(0, attachmentsToShow)
            .map((attachment, index) => displayAttachmentThumbnail(attachment, index + 1))}
          {toggleMoreAttachmentsLink(attachments, showMore, attachmentsExpanded)}
        </div>
      );
    }

    return null;
  };

  const displayMainAttachment = (attachments: Array<MediaAttachmentProp>): JSX.Element | null => {
    if (attachments.length > 0 && attachments[0] && attachments[0].type !== "log") {
      const MainAttachment = attachments[0].mainComponent;

      return MainAttachment ? (
        <div>
          <MainAttachment attachmentUrl={attachments[0].url} onClick={() => openLightbox(0)} />
        </div>
      ) : null;
    }

    return null;
  };

  const displayAttachments = () => (
    <div className="mb-xl" data-testid="attachments">
      {displayMainAttachment(mediaAttachments)}
      {attachmentThumbnails(mediaAttachments[0]?.type === "log" ? mediaAttachments : mediaAttachments.slice(1))}

      <MediaAttachmentLightbox
        media={prepareMediaForLightbox(mediaAttachments)}
        currentSource={currentImage}
        isOpen={lightboxIsOpen}
        onClose={closeLightbox}
      />
    </div>
  );

  return <div>{attachments.length > 0 && displayAttachments()}</div>;
}

export default Attachments;
