import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingIssueNoteCreateInput, FlexibleTestingIssueNoteCreatePayload } from "types/graphql";

export const CREATE_ISSUE_NOTE = gql`
  mutation FlexibleTestingIssueNoteCreate($input: FlexibleTestingIssueNoteCreateInput!) {
    flexibleTestingIssueNoteCreate(input: $input) {
      successful
      result {
        id
        issueNote {
          note
          authorName
          createdAt
          updatedAt
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

type useIssueNoteCreateResult = {
  createIssueNote: (issueId: string, note: string) => Promise<void>;
};

export const useIssueNoteCreate = (): useIssueNoteCreateResult => {
  const [createIssueNoteMutation] = useMutation<
    { flexibleTestingIssueNoteCreate: FlexibleTestingIssueNoteCreatePayload },
    { input: FlexibleTestingIssueNoteCreateInput }
  >(CREATE_ISSUE_NOTE);

  return {
    createIssueNote: async (issueId: string, note: string): Promise<void> => {
      await createIssueNoteMutation({
        variables: {
          input: { issueId, note },
        },
      });
    },
  };
};
