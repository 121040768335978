import React from "react";
import { FlexibleTestingIssue } from "types/graphql";
import { StatusBar } from "@hopper/status_bar";
import { Attachments } from "components/flexible_testing/shared/attachments";

type IssueAttachmentsProps = {
  issue: FlexibleTestingIssue;
};

const IssueAttachments = ({ issue }: IssueAttachmentsProps): JSX.Element => {
  const attachments = issue?.attachments?.edges?.map(edge => edge?.node?.url).filter(Boolean) || [];
  const logAttachments = attachments.filter(attachment => attachment.type === "log");
  const isOnlyLogAttachmentsPresent = logAttachments.length > 0 && logAttachments.length === attachments.length;

  const infoCard = (text: string): JSX.Element => (
    <div className="m-xl ml-0">
      <div className="relative border border-light my-xl p-lg leading-150">
        <StatusBar color="info" position="left" size="md" />
        <span className="fa fa-info-circle mr-sm" aria-hidden="true" />
        {text}
      </div>
    </div>
  );

  return (
    <div>
      {isOnlyLogAttachmentsPresent && <div>{infoCard("No videos or screenshots were uploaded for that issue")}</div>}
      {attachments.length === 0 && <div>{infoCard("There are no attachments for this issue")}</div>}
      {attachments.length > 0 && (
        <div className="md:mt-0 mr-4xl">
          <Attachments attachments={attachments} />
        </div>
      )}
    </div>
  );
};

export default IssueAttachments;
