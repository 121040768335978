import { FlexibleTestingTestRequestConnection } from "types/graphql";
import { gql, useQuery } from "@apollo/client";

type FlexibleTestingTestRequestList = {
  flexibleTestingTestRequests: FlexibleTestingTestRequestConnection;
};

type useExternalTestInProgressResult = {
  isTestRequestInProgress: boolean;
  isLoading: boolean;
};

type FlexibleTestingTestRequestFilter = {
  source: string;
  externalTestId: string;
};

export const TEST_REQUESTS_QUERY = gql`
  query FlexibleTestingTestRequests($externalTestId: String, $source: String, $testInProgress: Boolean) {
    flexibleTestingTestRequests(
      filters: { external_test_id: $externalTestId, source: $source, test_in_progress: $testInProgress }
    ) {
      totalCount
    }
  }
`;

export const useTestRequestsInProgress = (
  variables: FlexibleTestingTestRequestFilter,
): useExternalTestInProgressResult => {
  const { data, loading } = useQuery<FlexibleTestingTestRequestList>(TEST_REQUESTS_QUERY, {
    variables: { ...variables, testInProgress: true },
  });
  const totalCount = data?.flexibleTestingTestRequests?.totalCount || 0;
  const isTestRequestInProgress = totalCount > 0;

  return {
    isTestRequestInProgress,
    isLoading: loading,
  };
};

export default useTestRequestsInProgress;
