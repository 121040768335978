import { object, string, array } from "yup";
import { Activity } from "../activity_step_content";
import { ACTIVITIES_REQUIRING_SCOPE, ACTIVITIES_REQUIRING_TEST_CASE_FIELDS } from "./scope_step_content";

export const validations = object().shape({
  testCases: array().when("activity", {
    is: (value: Activity) => ACTIVITIES_REQUIRING_TEST_CASE_FIELDS.includes(value),
    then: array().required().min(1, "Please provide at least one test case."),
  }),
  numberOfResultsPerTestCaseNeeded: string().when("activity", {
    is: (value: Activity) => ACTIVITIES_REQUIRING_TEST_CASE_FIELDS.includes(value),
    then: string().required("Please provide the number of results you need for each test case."),
  }),
  requiresPredefinedUserAccounts: string().required(
    "Please specify whether testers need to use pre-defined user accounts created by you.",
  ),
  predefinedUserAccountsInstructions: string().when("requiresPredefinedUserAccounts", {
    is: "true",
    then: string().required("Please provide instructions for using pre-defined user accounts."),
  }),
  requiresFinancialTransactions: string().when("activity", {
    is: (value: Activity) => value === "test_case_run",
    then: string().required("Please specify whether test cases require financial transactions."),
    otherwise: string().required("Please specify whether testers need to conduct financial transactions."),
  }),
  financialTransactionsInstructions: string().when("requiresFinancialTransactions", {
    is: "true",
    then: string().required("Please provide instructions for payment method(s) that testers need to use."),
  }),
  requiresKycIdentityCheck: string().when("activity", {
    is: (value: Activity) => value === "test_case_run",
    then: string().required("Please specify whether test cases involve a KYC/identity check."),
    otherwise: string().required("Please specify whether testers need to go through a KYC/identity check."),
  }),
  kycIdentityCheckInstructions: string().when("requiresKycIdentityCheck", {
    is: "true",
    then: string().required("Please provide instructions for KYC/Identity documents and data that are needed."),
  }),
  requiresPersonalIdentifyingInformation: string().when("activity", {
    is: (value: Activity) => value === "test_case_run",
    then: string().required("Please specify whether test cases require personal identifying information."),
    otherwise: string().required("Please specify whether testers need to provide personal identifying information."),
  }),
  personalIdentifyingInformationInstructions: string().when("requiresPersonalIdentifyingInformation", {
    is: "true",
    then: string().required("Please provide instructions for information that testers need to provide."),
  }),
  requiresMovingToDifferentLocation: string().when("activity", {
    is: (value: Activity) => value === "test_case_run",
    then: string().required("Please specify whether test cases require moving to a different location."),
    otherwise: string().required(
      "Please specify whether testers need to move to a different location during the test.",
    ),
  }),
  hasKnownIssuesList: string().required("Please specify whether known issues list should be included."),
  knownIssuesListUrl: string().when("hasKnownIssuesList", {
    is: "true",
    then: string().required("Please provide an URL where we can find the known issues list."),
  }),
  movingToDifferentLocationInstructions: string().when("requiresMovingToDifferentLocation", {
    is: "true",
    then: string().required("Please provide instructions explaining what testers need to do."),
  }),
  inScope: string().when("activity", {
    is: (value: Activity) => ACTIVITIES_REQUIRING_SCOPE.includes(value),
    then: string().required("Please provide the scope"),
  }),
  outOfScope: string().when("activity", {
    is: (value: Activity) => ACTIVITIES_REQUIRING_SCOPE.includes(value),
    then: string().required("Please provide what is out of scope"),
  }),
});
