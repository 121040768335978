import React from "react";
import { FormikErrors, useFormikContext } from "formik";
import classnames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { Button } from "@hopper/button";

import { TesterGroupParams } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import styles from "./style.module.css";
import { TesterGroup } from "./tester_group";
import {
  Country,
  Device,
  InternetBrowser,
  Language,
  MatchingTester,
  MobileDevice,
  MobileDeviceBrand,
  OperatingSystem,
  OperatingSystemVersion,
} from "types/graphql";
import AutomaticTesterManagementSection from "./automatic_tester_management_section";
import { TestCase } from "types/test_cases";

export type TesterGroupData = {
  id: number;
  name: string;
  operatingSystemVersions: OperatingSystemVersion[];
  selectedTesterEmails: string;
  backupTesterEmails: string;
  deviceTypes: Device[];
  internetBrowsers: InternetBrowser[];
  manufacturers: MobileDeviceBrand[];
  mobileDevices: MobileDevice[];
  operatingSystems: OperatingSystem[];
  countries: Country[];
  languages: Language[];
  numberOfResultsNeeded: number | undefined;
  other: "";
  matchingTesters: MatchingTester[];
  excludedTesters: MatchingTester[];
  matchingTestersTotalNumber: number;
  automaticTesterSelectionInitiated: boolean;
  automaticTesterSelectionEnabled: boolean;
  automaticTesterSelectionDisabledReason: string;
  automaticTesterSelectionNotEnoughTesters: boolean;
  tags: string[];
};

export const TesterGroupList = () => {
  const {
    values: { testerGroups, testCases },
    errors: { testerGroups: testerGroupErrors = [] },
    setFieldValue,
  } = useFormikContext<{ testerGroups: TesterGroupData[]; testCases: TestCase[] }>();

  const availableTags = () => {
    if (!testCases || testCases.length === 0) {
      return [];
    }

    const allTags = testCases.flatMap(testCase => testCase.tags);

    return [...new Set(allTags)];
  };

  const addGroup = async (event: React.MouseEvent, testerGroup?: TesterGroupData) => {
    event.preventDefault();

    const maxId = testerGroups.length ? Math.max(...testerGroups.map(group => group.id)) : 0;
    const {
      numberOfResultsNeeded,
      operatingSystemVersions = [],
      deviceTypes = [],
      internetBrowsers = [],
      manufacturers = [],
      mobileDevices = [],
      operatingSystems = [],
      countries = [],
      languages = [],
      other = "",
      automaticTesterSelectionEnabled = true,
      automaticTesterSelectionDisabledReason = "",
      tags = [],
    } = testerGroup ?? {};

    const newTesterGroups: TesterGroupData[] = [
      ...testerGroups,
      {
        id: maxId + 1,
        name: `Group ${testerGroups.length + 1}`,
        numberOfResultsNeeded,
        operatingSystemVersions,
        selectedTesterEmails: "",
        backupTesterEmails: "",
        deviceTypes,
        internetBrowsers,
        manufacturers,
        mobileDevices,
        operatingSystems,
        countries,
        languages,
        other,
        matchingTesters: [],
        excludedTesters: [],
        matchingTestersTotalNumber: 0,
        automaticTesterSelectionInitiated: false,
        automaticTesterSelectionEnabled,
        automaticTesterSelectionDisabledReason,
        automaticTesterSelectionNotEnoughTesters: false,
        tags,
      },
    ];

    setFieldValue("testerGroups", newTesterGroups);
  };

  const duplicateGroup = async (event: React.MouseEvent, testerGroup: TesterGroupData) => {
    event.stopPropagation();

    const originalName = testerGroup.name;
    await addGroup(event, testerGroup);

    displaySuccessfulDuplicateNotification(originalName);
  };

  const displaySuccessfulDuplicateNotification = (groupName: string) => {
    notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
      type: "success",
      message: `${groupName} has been duplicated`,
    });
  };

  const removeGroup = (groupIndex: number) => {
    const newTesterGroups = [...testerGroups];
    newTesterGroups.splice(groupIndex, 1);

    setFieldValue("testerGroups", newTesterGroups);
  };

  const groupTransitionClassNames = {
    enter: styles.fadeEnter,
    enterActive: styles.fadeEnterActive,
    exit: styles.fadeExit,
    exitActive: styles.fadeExitActive,
  };

  const groupClassNames = (index: number) => classnames({ "mt-2xs": index !== 0 });

  return (
    <div style={{ maxWidth: "24rem" }}>
      <div className="flex flex-col border-bottom">
        <AutomaticTesterManagementSection />
        <TransitionGroup>
          {testerGroups.map((testerGroup: TesterGroupData, index: number) => (
            <CSSTransition key={testerGroup.id} timeout={200} classNames={groupTransitionClassNames}>
              <div className={groupClassNames(index)}>
                <TesterGroup
                  index={index}
                  testerGroup={testerGroup}
                  fieldName={`testerGroups.${index}`}
                  onDuplicateGroupClick={duplicateGroup}
                  onRemoveGroupClick={() => removeGroup(index)}
                  errors={(testerGroupErrors[index] || {}) as FormikErrors<TesterGroupParams>}
                  possibleTags={availableTags()}
                />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
      <Button
        className="mt-2"
        variant="primary"
        appearance="minimal"
        onClick={addGroup}
        style={{ position: "relative", left: "-16px" }}
      >
        Add new group
      </Button>
    </div>
  );
};
