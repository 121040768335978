import pluralize from "pluralize";
import React from "react";

import { Activity } from "../activity_step_content";
import { getActivityTitle } from "components/flexible_testing/test_request_page/requested_test_page/helpers";

const InfoCard = ({
  activity,
  numberOfResultsPerTestCaseNeeded,
}: {
  activity: Activity;
  numberOfResultsPerTestCaseNeeded?: number;
}) => {
  const testType = getActivityTitle(activity);

  return (
    <div className="inline-block rounded-sm bg-light p-md px-lg pr-6xl text-sm mb-lg border-l-4 border-primary ">
      {activity === Activity.TestCaseRun ? (
        <>
          You have chosen to receive {numberOfResultsPerTestCaseNeeded}{" "}
          {pluralize("result", numberOfResultsPerTestCaseNeeded)} per test case. Each group will return{" "}
          {numberOfResultsPerTestCaseNeeded} {pluralize("result", numberOfResultsPerTestCaseNeeded)} per test case
        </>
      ) : (
        <>
          We invite 15 testers to a {testType.toLowerCase()}. If you want more testers to participate, you can duplicate
          this test activity after it has been added to your request
        </>
      )}
    </div>
  );
};

export default InfoCard;
