import React from "react";

import { ControlledMultiselect } from "./controlled_multiselect";
import { useField } from "formik";
import { FormField } from "@hopper/form_field";

export type Options = Array<{ label: string; value: string; isSelected: boolean }>;

type MultiSelectProps = {
  label: string;
  options: Options;
  errorMessage: string | undefined;
  placeholder: string;
  name: string;
  onChange?: (selectedValues: Array<string>) => void;
  "data-testid"?: string;
  displayPrimaryButton?: boolean;
};

const MultiSelect = ({
  label,
  options,
  placeholder,
  errorMessage,
  onChange = () => {},
  "data-testid": testId,
  displayPrimaryButton = false,
  ...props
}: MultiSelectProps) => {
  const [_props, _meta, helpers] = useField({ ...props });

  return (
    // INFO: By default clicking on label focuses input, in this case selecting option
    // will do the same and thus scroll list to the top. This is workaround.
    // Jira: https://gatteam.atlassian.net/browse/TEAMX-3288
    <FormField isOptional errorMessage={errorMessage}>
      <label onClick={e => e.preventDefault()}>
        <div className="text-sm mb-sm">{label}</div>
        <div className="text-base" data-testid={testId}>
          <div style={{ minWidth: "24rem" }}>
            <ControlledMultiselect
              options={options}
              onChange={selectedValues => {
                helpers.setValue(selectedValues);
                onChange(selectedValues);
              }}
              placeholder={placeholder}
              displayPrimaryButton={displayPrimaryButton}
            />
          </div>
        </div>
      </label>
    </FormField>
  );
};

export default MultiSelect;
