import React from "react";
import { useHistory } from "react-router-dom";
import { websiteContentPaths } from "components/configuration";
import { Tab } from "@hopper/tab";
import { TabBar } from "@hopper/tab_bar";

import { advancedTest, standardTest, TestTypes } from "../../preview_page/preview_page";

type TestRequestTabsProps = {
  selectedTab: TestTypes;
};

export function TestRequestTabs({ selectedTab }: TestRequestTabsProps): JSX.Element {
  const history = useHistory();

  const changeTab = (tabName: TestTypes) => {
    history.push(`${websiteContentPaths.flexcaseLaunch}?tab=${tabName}`);
  };

  return (
    <div className="border-b border-light mt-lg">
      <TabBar>
        <Tab
          data-testid="standard-test-tab"
          active={selectedTab === standardTest}
          onClick={() => changeTab(standardTest)}
        >
          Express test
        </Tab>
        <Tab
          data-testid="advanced-test-tab"
          active={selectedTab === advancedTest}
          onClick={() => changeTab(advancedTest)}
        >
          Advanced test
        </Tab>
      </TabBar>
    </div>
  );
}
