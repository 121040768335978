import { useTrackingModeration } from "./use_tracking_moderation";

export function useTrackingEditStepResponseResult() {
  const { tracking, organizationUserEmail, trackingCaseResultId, trackingTestId } = useTrackingModeration();

  const trackEditStepResponseResult = (
    previousValue: string,
    currentValue: string,
    caseResultFieldResponseId: string,
    eventName: string,
  ) => {
    tracking.trackEvent({
      action: "track",
      name: eventName,
      eventProperties: {
        previous_value: previousValue,
        current_value: currentValue,
        case_result_field_response_id: caseResultFieldResponseId,
        test_id: trackingTestId,
        case_result_id: trackingCaseResultId,
        organization_user_email: organizationUserEmail,
      },
    });
  };

  return trackEditStepResponseResult;
}
