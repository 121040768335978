import React from "react";
import { FlexibleTestingTestCaseResult, FlexibleTestingTestWindow } from "types/graphql";
import LiveCaseOverview from "./live_case_overview";
import FinishedCaseOverview from "./finished_case_overview";
import { statuses } from "components/flexible_testing/shared/status_indicator";

type OverviewProps = {
  isTestCaseLive: boolean;
  caseResults: FlexibleTestingTestCaseResult[];
  caseStatus: typeof statuses[number];
  testWindow: FlexibleTestingTestWindow;
};

const Overview = ({ isTestCaseLive, caseStatus, caseResults, testWindow }: OverviewProps) => {
  if (isTestCaseLive) {
    return <LiveCaseOverview caseResults={caseResults} testWindow={testWindow} />;
  } else {
    return <FinishedCaseOverview caseResults={caseResults} caseStatus={caseStatus} testWindow={testWindow} />;
  }
};

export default Overview;
