import React, { useEffect } from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import capitalize from "lodash/capitalize";
import isString from "lodash/isString";
import { captureException } from "@sentry/browser";
import { Notification } from "@hopper/notification";
import { get } from "lodash";

const DEFAULT_DURATION = 4000;
const WARNING_DURATION = 3000;
const ERROR_DURATION = 5000;

const slideRight = cssTransition({
  enter: "slide-in-right",
  exit: "slide-out-right",
});

const bounceInSlideOutRight = cssTransition({
  enter: "bounce-in-right",
  exit: "slide-out-right",
});

const Toasts = ({ notifications, onClearToasts }) => {
  useEffect(() => {
    notifications.forEach(notification => {
      if (!get(notification, "options.internalError")) {
        notify(notification);
      } else {
        try {
          throw notification.message;
        } catch (error) {
          captureException(error, { extra: get(notification, "options.requestParams") });
        }
      }
    });
  }, [notifications]);

  if (notifications.length > 0) {
    onClearToasts();
  }

  return (
    <ToastContainer
      autoClose={DEFAULT_DURATION}
      draggable={false}
      className="fixed top-2xl right-3xl ml-lg mt-6xl"
      style={{ zIndex: 200, lineHeight: 1.15 }}
      transition={slideRight}
      closeButton={false}
      pauseOnHover
      pauseOnFocusLoss
    />
  );
};

function notify(notification) {
  const title = isString(notification.message) ? capitalize(notification.message) : notification.message;
  const toastId = title;
  const variant = mapTypeToVariant(notification.type);
  const duration = mapTypeToDuration(notification.type);
  const transition = mapTypeToTransition(notification.type);
  const toastOptions = { toastId, transition, autoClose: duration };

  return toast(
    <div className="bg-lightest mb-1" data-testid="notification-toast">
      <Notification onClose={() => toast.dismiss(toastId)} title={title} variant={variant} icon={notification.icon} />
    </div>,
    toastOptions,
  );
}

function mapTypeToVariant(type) {
  return {
    success: "success",
    danger: "danger",
    info: "warning",
    error: "danger",
  }[type];
}

function mapTypeToDuration(type) {
  return (
    {
      info: WARNING_DURATION,
      error: ERROR_DURATION,
    }[type] || DEFAULT_DURATION
  );
}

function mapTypeToTransition(type) {
  return (
    {
      danger: bounceInSlideOutRight,
      error: bounceInSlideOutRight,
    }[type] || slideRight
  );
}

export default Toasts;
