import React, { useRef, useState } from "react";
import Popup from "components/shared/popup";
import classnames from "classnames";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";

type RejectedStatusProps = {
  acceptIssue: () => void;
  triageReason: string | undefined | null;
};

const RejectedStatus = ({ acceptIssue, triageReason }: RejectedStatusProps) => {
  const [isAcceptPopupOpen, setIsAcceptPopupOpen] = useState(false);
  const wrapperRef = useRef(null);
  const handleOutsideClick = () => setIsAcceptPopupOpen(false);

  useOutsideHandler(wrapperRef, handleOutsideClick);

  const labelClassNames = classnames(
    "bg-light py-xs px-sm rounded-base cursor-pointer",
    "text-body leading-lg font-bold text-xs uppercase tracking-wider ",
  );

  const onAcceptButtonClick = () => {
    acceptIssue();
    setIsAcceptPopupOpen(false);
  };

  const issueAcceptElement = () => (
    <div
      onClick={onAcceptButtonClick}
      className="mt-sm border border-muted shadow rounded-base bg-lightest z-10"
      style={{ width: "170px" }}
      data-testid="options-box"
    >
      <div className="hover:bg-light my-sm p-xs pl-xl cursor-pointer">Accept</div>
    </div>
  );

  return (
    <div ref={wrapperRef}>
      <Popup popupElement={issueAcceptElement()} isOpen={isAcceptPopupOpen}>
        <div
          data-testid="rejected-status"
          onClick={() => {
            setIsAcceptPopupOpen(!isAcceptPopupOpen);
          }}
          className={labelClassNames}
        >{`Rejected: ${triageReason}`}</div>
      </Popup>
    </div>
  );
};

export default RejectedStatus;
