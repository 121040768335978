import { gql } from "@apollo/client";

export const TESTS = gql`
  query getTests($status: TestStatus) {
    platformTests(filters: { status: $status }) {
      edges {
        node {
          ... on AdvancedTestRequest {
            id
            name
            code
          }
          ... on FlexibleTestingTest {
            id
            name
            code
          }
        }
      }
    }
  }
`;
