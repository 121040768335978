import React from "react";
import DefaultLayoutHelmet from "../shared/default_layout_helmet";
// @ts-ignore
import { Notifications } from "../shared/notifications";
import gatLogo from "assets/images/gat-logo.svg";

type FlexibleTestingLayoutProps = {
  children: JSX.Element;
};

const NoHeaderLayout = ({ children }: FlexibleTestingLayoutProps): JSX.Element => (
  <div>
    <DefaultLayoutHelmet />

    <div id="wrapper" className="bg-lightest">
      <nav
        className="z-100 w-full top-0 left-0 fixed rounded-none
            mb-0 bg-lightest lg:px-6xl lg:py-lg"
      >
        <div className="flex ml-lg lg:ml-0 h-6xl">
          <div className="flex flex-1 items-center mr-auto">
            <img src={gatLogo} alt="Global App Testing" style={{ height: "40px" }} />
          </div>
        </div>
      </nav>

      <div className="px-lg overflow-auto mt-6xl mx-auto mb-0">
        {children}

        <div className="max-w-3xl mx-auto mb-6xl">
          <div className="pb-xl text-dark text-xs">
            {`Copyright ${new Date().getFullYear()} Global App Testing - All rights reserved`}
          </div>
        </div>
      </div>
    </div>

    <Notifications />
  </div>
);

export default NoHeaderLayout;
