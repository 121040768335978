import React from "react";
import { Button } from "@hopper/button";
import { Modal, ModalBody, ModalHeader } from "@hopper/legacy_modal";

import { Test } from "types/graphql";
import { Participant } from "components/flexible_testing/shared/chat";
import { usePublishIssuesAndCaseResults } from "components/flexible_testing/shared/use_publish_issues_and_case_results";

type PublishAllResultsModalProps = {
  onClose: () => void;
  isOpen: boolean;
  participantIds: Participant["id"][];
  testId: Test["id"];
};

export const PublishAllResultsModal = ({ onClose, isOpen, participantIds, testId }: PublishAllResultsModalProps) => {
  const { publishIssuesAndCaseResults, isLoading } = usePublishIssuesAndCaseResults();
  const handlePublish = () => publishIssuesAndCaseResults(testId, participantIds).finally(onClose);

  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={isOpen}
      position="center"
      size="md"
      onOverlayClicked={() => {}}
    >
      <div data-testid="publish-all-tester-results-modal">
        <ModalHeader>
          Publish the results and issues from <span className="text-primary">all approved testers</span>
        </ModalHeader>
        <ModalBody className="flex flex-col h-full overflow-hidden gap-lg">
          <p>Are you sure? This action cannot be undone</p>
          <div className="flex gap-sm">
            <p>✅</p>
            <p>
              All testers who have a complete set of approved results and all of their issues have been moderated will
              be published. Only accepted issues will be published
            </p>
          </div>
        </ModalBody>
        <div className="flex justify-end mt-2xl gap-md">
          <Button onClick={handlePublish} isLoading={isLoading}>
            Publish
          </Button>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
