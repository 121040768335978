import React, { useState } from "react";
import { Button } from "@hopper/button";
import { useCancelCaseResultEditRequest } from "./use_cancel_case_result_edit_request";
import { EditRequestCancelConfirmation } from "./edit_request_cancel_confirmation";

type CancelEditRequestButtonProps = {
  caseResultId: string;
};

export function CancelEditRequestButton({ caseResultId }: CancelEditRequestButtonProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { flexibleTestingCaseResultEditRequestCancel, isLoading: isButtonLoading } = useCancelCaseResultEditRequest(
    () => setIsConfirmationModalOpen(false),
  );

  const handleCancelEditRequest = (caseResultId: string) => {
    flexibleTestingCaseResultEditRequestCancel(caseResultId);
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <Button
        variant="secondary"
        appearance="ghost"
        onClick={() => setIsConfirmationModalOpen(true)}
        isLoading={isButtonLoading}
      >
        Cancel edit request
      </Button>

      {isConfirmationModalOpen && (
        <EditRequestCancelConfirmation
          onConfirm={() => handleCancelEditRequest(caseResultId)}
          onClose={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </>
  );
}
