import React from "react";
import { Button } from "@hopper/button";
import TemplateFileNoMergedCells from "./test_cases_example.xlsx";
import TemplateFileWithAdditionalInformationNoMergedCells from "./test_cases_with_additional_information_example.xlsx";

type TemplateProps = {
  className?: string;
  additionalInformation: boolean;
};
function Template({ className, additionalInformation }: TemplateProps): JSX.Element {
  const templateFile = additionalInformation
    ? TemplateFileWithAdditionalInformationNoMergedCells
    : TemplateFileNoMergedCells;

  return (
    <div className={className}>
      <Button variant="secondary" className="my-md text-sm" href={templateFile}>
        <i className="fa fa-download mr-sm" aria-hidden="true" />
        Download template format
      </Button>
    </div>
  );
}

export default Template;
