import { TabBar } from "@hopper/tab_bar";
import React, { useState } from "react";
import { Tab } from "@hopper/tab";
import ReproTestResult from "./repro_test_result";
import { FlexibleTestingIssue, FlexibleTestingTestCaseResult, Maybe } from "types/graphql";
import classNames from "classnames";
import { CaseResultStatus } from "./case_result_status";
import statusColorMap from "./shared/statusColorMap";
import { useTracking } from "react-tracking";
import { useCurrentUser } from "app/current_user/use_current_user";
import { Events } from "components/configuration";

function tabTitle(title: string, status: CaseResultStatus): JSX.Element {
  const statusColor = `text-${statusColorMap[status || "none"]}`;

  return (
    <div className="flex items-center " data-testid="repro-tab">
      <i className={classNames("fa fa-circle mr-xs text-2xs", statusColor)}></i>
      <span className="mr-xs">{title}</span>
    </div>
  );
}

function ReproTestsContainer({
  completedReproCaseResults,
  issue,
}: {
  completedReproCaseResults?: Maybe<FlexibleTestingTestCaseResult[]>;
  issue: FlexibleTestingIssue;
}) {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const changeTab = (tabName: number) => {
    setSelectedTab(tabName);
  };

  const tracking = useTracking();
  const currentUser = useCurrentUser();

  const trackReproTabClick = () =>
    tracking.trackEvent({
      action: "track",
      name: Events.REPRO_TEST_TAB_CLICKED,
      eventProperties: {
        test_id: issue.test.id,
        issue_id: issue.id,
        user_id: currentUser?.publicId || "",
        organization_id: currentUser?.organization.publicId || "",
      },
    });

  const handleTabClick = (index: number) => {
    trackReproTabClick();
    changeTab(index);
  };

  return (
    <>
      <div className="border-b border-light mt-lg mb-md">
        <TabBar>
          {completedReproCaseResults?.map((caseResult, i) => (
            <Tab active={selectedTab === i} onClick={() => handleTabClick(i)}>
              {tabTitle(`Repro Test ${i + 1}`, (caseResult?.status || "none") as CaseResultStatus)}
            </Tab>
          ))}
        </TabBar>
      </div>

      {completedReproCaseResults?.map((caseResult, i) => selectedTab === i && <ReproTestResult {...{ caseResult }} />)}
    </>
  );
}

export default ReproTestsContainer;
