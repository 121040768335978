import React, { useState } from "react";
import { Modal } from "@hopper/legacy_modal";
import { Checkbox } from "@hopper/checkbox";
import { Button } from "@hopper/button";

import { useChangeAssignmentStatus } from "./use_change_assignment_status";
import { AssignedCommunityTestManagerStatusEnum, AssignedCommunityTestManager } from "types/graphql";
import { TesterWorkRoot } from "components/configuration";

const guidanceDocUrl =
  "https://docs.google.com/document/d/1mGYz6ywvcq4LG4iyAehlpYMdZdpcJ_bzPzQRj-RPSks/edit#heading=h.40vwvei6h2rs";

type AcceptAssignmentModalProps = {
  assignedCommunityTestManager: AssignedCommunityTestManager;
};

const AcceptAssignmentModal = ({ assignedCommunityTestManager }: AcceptAssignmentModalProps) => {
  const { changeAssignmentStatus, loading: processing } = useChangeAssignmentStatus();
  const [understandsExpectation, setUnderstandsExpectation] = useState(false);
  const [understandsPayment, setUnderstandsPayment] = useState(false);
  const [commitsToModerating, setCommitsToModerating] = useState(false);
  const canStart = understandsExpectation && understandsPayment && commitsToModerating;

  const onAccept = () => {
    changeAssignmentStatus({
      assignedCommunityTestManagerId: assignedCommunityTestManager?.id || "",
      status: "accepted" as AssignedCommunityTestManagerStatusEnum,
    });
  };

  const onReject = () => {
    changeAssignmentStatus({
      assignedCommunityTestManagerId: assignedCommunityTestManager?.id || "",
      status: "rejected" as AssignedCommunityTestManagerStatusEnum,
    }).then(successful => {
      if (successful) {
        window.location.href = TesterWorkRoot;
      }
    });
  };

  return (
    <Modal
      isShowingCloseButton={false}
      onCloseButtonClicked={() => {}}
      position="center"
      size="lg"
      isOpen={true}
      onOverlayClicked={() => {}}
    >
      <div className="mx-lg max-w-sm">
        <p className="mt-lg font-bold">Welcome to test case moderation 👋</p>
        <p className="mt-md text-secondary">You have been assigned to this test as a moderator</p>

        <div className="mt-lg bg-secondary bg-opacity-25 py-sm pl-lg rounded">
          Read the{" "}
          <a href={guidanceDocUrl} target="_blank" className="text-primary cursor-pointer">
            guidance and instructions
          </a>
        </div>

        <label className="mt-md flex">
          <span className="mt-[2px]">
            <Checkbox
              onChange={() => setUnderstandsExpectation(!understandsExpectation)}
              isChecked={understandsExpectation}
            />
          </span>
          <span className="text-secondary">I have read the guidance and instructions</span>
        </label>
        <label className="mt-xs flex">
          <span className="mt-[2px]">
            <Checkbox onChange={() => setUnderstandsPayment(!understandsPayment)} isChecked={understandsPayment} />
          </span>
          <span className="text-secondary">
            I understand that I may not be paid if I do not moderate all of the test case results
          </span>
        </label>
        <label className="mt-xs flex">
          <span className="mt-[2px]">
            <Checkbox onChange={() => setCommitsToModerating(!commitsToModerating)} isChecked={commitsToModerating} />
          </span>
          <span className="text-secondary">I commit to moderating this test fully</span>
        </label>
      </div>
      <div className="flex justify-end mt-lg mr-lg">
        <Button
          onClick={onAccept}
          disabled={!canStart}
          isLoading={processing}
          className="mt-md"
          variant="primary"
          size="md"
        >
          I'm ready to start
        </Button>
        <Button onClick={onReject} isLoading={processing} className="mt-md ml-sm" variant="secondary" size="md">
          Reject
        </Button>
      </div>
    </Modal>
  );
};

export default AcceptAssignmentModal;
