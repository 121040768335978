import React from "react";
import { gql, useApolloClient } from "@apollo/client";

export const AZURE_DEVOPS_PROJECT_FIELDS_QUERY = gql`
  query AzureDevopsProjectFields($projectId: ID!, $integrationId: ID!) {
    azureDevopsProjectFields(projectId: $projectId, integrationId: $integrationId) {
      workItemTypes {
        name
      }
      areaPaths {
        name
      }
      tags {
        name
      }
    }
  }
`;

function withProjectFields(WrappedComponent) {
  return function ({ ...props }) {
    const client = useApolloClient();

    return (
      <WrappedComponent
        {...props}
        loadProjectFields={async (projectId, integrationId) => {
          const { data } = await client.query({
            query: AZURE_DEVOPS_PROJECT_FIELDS_QUERY,
            variables: { projectId, integrationId },
          });

          return data?.azureDevopsProjectFields;
        }}
      />
    );
  };
}

export { withProjectFields };
