import React from "react";

type HeaderProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLTableRowElement>;

function Header({ children, ...otherProps }: HeaderProps) {
  return (
    <thead>
      <tr {...otherProps}>{children}</tr>
    </thead>
  );
}

export default Header;
