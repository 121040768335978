import React, { useState, useEffect } from "react";

type ListProps = {
  items: Array<Item>;
};

type Item = {
  name?: string;
};

const DEFAULT_DISPLAYED_ITEMS_LENGTH = 3;

export function List({ items }: ListProps): JSX.Element {
  const [displayedItems, setDisplayedItems] = useState(items.slice(0, DEFAULT_DISPLAYED_ITEMS_LENGTH));
  const [displayShowButton, setDisplayShowButton] = useState(items.length > displayedItems.length);

  useEffect(() => {
    if (displayShowButton) {
      setDisplayedItems(items.slice(0, DEFAULT_DISPLAYED_ITEMS_LENGTH));
    } else {
      setDisplayedItems(items);
    }
  }, [displayShowButton, items]);

  return (
    <div className="border-t border-light">
      {displayedItems.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="pt-sm py-md border-b border-light text-sm" key={index}>
          {item.name}
        </div>
      ))}
      {displayShowButton && (
        <div
          className="text-link mt-sm cursor-pointer text-xs font-bold"
          onClick={() => setDisplayShowButton(!displayShowButton)}
        >
          <span>Show all</span>
          <i className="fa fa-chevron-down pl-1" />
        </div>
      )}
    </div>
  );
}
