import { gql } from "@apollo/client";

export const RESOLVE_TEST_BLOCKER_MUTATION = gql`
  mutation resolveTestBlocker($input: ResolveTestBlockerInput!) {
    resolveTestBlocker(input: $input) {
      result {
        id
        status
        name
      }
      errors {
        message
        code
        path
      }
      successful
    }
  }
`;
