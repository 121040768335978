import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import NotFoundPage from "components/not_found_page";
import { AllIssuesPage } from "./all_issues_page";
import { IssuePage } from "components/flexible_testing/issue_page";
import { websiteContentPaths } from "components/configuration";

const ISSUES_LIST_ROOT_PATH = websiteContentPaths.allIssues;

const IssuePageWrapper = (props: RouteComponentProps) => (
  <IssuePage
    {...props}
    navigationOrigin="all_issues"
    returnButton={{
      label: "Back to All Issues",
      url: ISSUES_LIST_ROOT_PATH,
    }}
  />
);

function AllIssuesListSection(): JSX.Element {
  return (
    <Switch>
      <Route exact path={ISSUES_LIST_ROOT_PATH} component={AllIssuesPage} />
      <Route path={websiteContentPaths.flexcaseIssue} component={IssuePageWrapper} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default AllIssuesListSection;
