import React from "react";

const NoIssuesAccepted = () => (
  <div className="mt-xl">
    <div className="font-bold max-w-md pb-md border-b border-light">No issues have been accepted</div>
    <div className="mt-xl">There were no accepted issues for this test run</div>
  </div>
);

export default NoIssuesAccepted;
