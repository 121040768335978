import React, { useState } from "react";

import { Modal, ModalBody, ModalHeader } from "@hopper/legacy_modal";
import Truncate from "react-truncate";
import { ModalFooter } from "react-bootstrap";
import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";
import { Textarea } from "@hopper/textarea";
import ModalSuccessMessage from "./modal_success_message";
import ModalErrorMessage from "./modal_error_message";
import { ModalStatus } from "./remove_tester_button";
import { Participant } from "components/flexible_testing/shared/chat";

type CustomTesterRemovalModalProps = {
  participants: Participant[];
  onClose: () => void;
  onSubmit: (reasons: string[]) => void;
  isOpen: boolean;
  status: ModalStatus;
};

const CustomTesterRemovalModal = ({
  participants,
  onClose,
  onSubmit,
  isOpen,
  status,
}: CustomTesterRemovalModalProps) => {
  const [reason, setReason] = useState("");

  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={isOpen}
      position="center"
      size="lg"
      onOverlayClicked={() => {}}
    >
      {status === "success" && <ModalSuccessMessage numberOfTesters={participants.length} />}
      {status === "error" && <ModalErrorMessage numberOfTesters={participants.length} />}
      {status === "init" && (
        <div data-testid="custom-tester-removal-modal">
          <ModalHeader>Remove testers</ModalHeader>
          <ModalBody className="flex flex-col h-full overflow-hidden">
            <div className="overflow-y-auto overflow-x-hidden mb-2xl p-sm" style={{ maxHeight: "400px" }}>
              {participants.map(participant => (
                <div className="mb-sm mr-2xl">
                  <div className="text-bold" data-testid="participant-full-name">
                    {participant.fullName}
                  </div>
                  <div className="text-dark text-sm">
                    <Truncate ellipsis="..." lines={1} width={500}>
                      {participant.email}
                    </Truncate>
                  </div>
                </div>
              ))}
            </div>

            <Heading size={5}>Why are you removing these testers?*</Heading>

            <Textarea
              data-testid="modal-reason-textbox"
              value={reason}
              onChange={e => setReason(e.target.value)}
            ></Textarea>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => onSubmit([reason])} disabled={!reason.length}>
              Remove testers
            </Button>
            <Button onClick={() => onClose()} variant="secondary">
              Cancel
            </Button>
          </ModalFooter>
        </div>
      )}
    </Modal>
  );
};

export default CustomTesterRemovalModal;
