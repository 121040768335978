import gql from "graphql-tag";
import { useApolloClient, useSubscription } from "@apollo/client";
import { GET_ISSUES_TAB_DATA } from "components/flexible_testing/result_details_page/issues";
import { TESTS } from "../results_page/use_test_list";

export const GET_TEST_ID = gql`
  subscription issues {
    issueWasCreated {
      testId
    }
  }
`;

const IssuesSubscription = () => {
  const client = useApolloClient();

  useSubscription(GET_TEST_ID, {
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData["data"]) {
        const variables = {
          testId: subscriptionData["data"]["issueWasCreated"]["testId"],
        };
        client.query({ query: TESTS, variables: { status: "in_progress" }, fetchPolicy: "network-only" });
        client.query({ query: GET_ISSUES_TAB_DATA, variables, fetchPolicy: "network-only" });
      } else {
        console.error("Missing data field from subscription");
      }
    },
  });

  return null;
};

export default IssuesSubscription;
