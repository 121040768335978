import { gql, useApolloClient, useMutation } from "@apollo/client";
import { OrganizationUpdateInput, OrganizationUpdatePayload } from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { useCurrentUser } from "app/current_user/use_current_user";
// @ts-ignore
import { GET_CURRENT_ORGANIZATION_USER } from "components/shared/queries/organization_users_query";

export const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation OrganizationUpdate($input: OrganizationUpdateInput!) {
    organizationUpdate(input: $input) {
      successful
      result {
        chosenName
      }
    }
  }
`;

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with saving new organization name",
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Organization name have been successfully updated.",
  });
};

function useSaveOrganizationChosenName() {
  const currentUser = useCurrentUser();
  const apolloClient = useApolloClient();
  const [updateOrganization, { loading }] = useMutation<
    { organizationUpdate: OrganizationUpdatePayload },
    { input: OrganizationUpdateInput }
  >(UPDATE_ORGANIZATION_MUTATION, {
    onCompleted: displaySuccess,
    onError: displayError,
  });

  const updateCurrentUser = (chosenName?: string | null) => {
    const updatedUser = { ...currentUser, organization: { ...currentUser?.organization, chosenName } };
    apolloClient.writeQuery({ query: GET_CURRENT_ORGANIZATION_USER, data: { currentOrganizationUser: updatedUser } });
  };

  return {
    isLoading: loading,
    saveOrganizationChosenName: async (chosenName: string): Promise<void> => {
      const response = await updateOrganization({
        variables: { input: { chosenName } },
      });
      updateCurrentUser(response.data?.organizationUpdate.result?.chosenName);
    },
  };
}

export default useSaveOrganizationChosenName;
