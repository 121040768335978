import React from "react";

import NoResultMessage from "./no_result_message";
import { StatusLabel } from "@hopper/status_label";
import rocketIcon from "assets/images/rocket.svg";

type NoTesterFeedbackMessageProps = {
  isTestOpen: boolean;
  isTestClosed: boolean;
};

function NoTesterFeedbackMessage({ isTestOpen, isTestClosed }: NoTesterFeedbackMessageProps): JSX.Element {
  if (isTestClosed) {
    return (
      <div className="mt-6xl">
        <div className="font-bold max-w-md pb-md border-b border-light">No feedback was provided</div>
        <div className="mt-xl">Our testers did not provide any additional feedback for this test</div>
      </div>
    );
  } else if (isTestOpen) {
    return (
      <div className="mt-6xl">
        <div className="flex font-bold max-w-md pb-md border-b border-light">
          <StatusLabel>Live</StatusLabel>
          <div className="ml-sm">Testing in progress</div>
        </div>
        <div className="mt-xl flex">
          <img src={rocketIcon} className="mr-xs" />
          <span>Tester feedback will be shown here as soon it is submitted</span>
        </div>
      </div>
    );
  } else {
    return <NoResultMessage message="No additional feedback were included in this test" />;
  }
}

export default NoTesterFeedbackMessage;
