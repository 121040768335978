import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { VendorTestRequestConfirmationPage } from "./vendor_test_request_confirmation_page";

function FlexibleVendorTestRequestSection({ match: { path } }: RouteComponentProps): JSX.Element {
  return (
    <Switch>
      <Route path={path} component={VendorTestRequestConfirmationPage} />
    </Switch>
  );
}

export default FlexibleVendorTestRequestSection;
