import React from "react";
import { compact } from "lodash";
import { DecoratedText } from "@hopper/decorated_text";

import { FlexibleTestingTesterEnvironment, Maybe } from "types/graphql";

import { StatusSection } from "components/flexible_testing/test_case_result_page/status_section";
import { DetailsField } from "components/flexible_testing/shared/details_field";
import { CaseResultStatus } from "components/flexible_testing/tester_overview/test_case_result";

type CaseResultStatusSectionProps = {
  statusLabel: string;
  testerEnvironment?: Maybe<FlexibleTestingTesterEnvironment>;
  statusCode: CaseResultStatus;
};

export function CaseResultStatusSection({ statusCode, statusLabel, testerEnvironment }: CaseResultStatusSectionProps) {
  const statusColor = {
    pass: "success",
    fail: "danger",
    blocked: "warning",
    not_tested: "muted",
    live: "muted",
    missing: "muted",
    skipped: "muted",
  }[statusCode];

  const testerEnvironmentText = testerEnvironment?.operatingSystemAndVersion
    ? compact([testerEnvironment.operatingSystemAndVersion, testerEnvironment.browserOrDevice]).join(" | ")
    : "N/A";

  return (
    <StatusSection status={statusColor}>
      <DetailsField label={`${statusLabel}`} testId="status-and-tester-environment">
        <span className="font-bold">
          <DecoratedText text={testerEnvironmentText} />
        </span>
      </DetailsField>
    </StatusSection>
  );
}
