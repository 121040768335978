import React, { ReactNode } from "react";
import { Tab as HopperTab } from "@hopper/tab";
import { TabBar as HopperTabBar } from "@hopper/tab_bar";

type TabIndex = number;

const TabsContext = React.createContext<[TabIndex, React.Dispatch<React.SetStateAction<TabIndex>>] | null>(null);

const useTabs = () => {
  const context = React.useContext(TabsContext);

  if (!context) {
    throw new Error("useTabs must be used within the TabsContext");
  }

  return context;
};

type TabsProps = {
  defaultActive?: TabIndex;
  children: ReactNode;
};

export const Tabs = ({ defaultActive = 0, children }: TabsProps) => {
  const [activeTab, setActiveTab] = React.useState<TabIndex>(defaultActive);

  return <TabsContext.Provider value={[activeTab, setActiveTab]}>{children}</TabsContext.Provider>;
};

type TabBarProps = {
  children: ReactNode;
};

export const TabBar = ({ children }: TabBarProps) => (
  <div className="mb-lg">
    <HopperTabBar borderBottom={true}>{children}</HopperTabBar>
  </div>
);
type TabProps = {
  index: TabIndex;
  disabled?: boolean;
  hidden?: boolean;
  children: ReactNode;
};

export const Tab = ({ index, disabled = false, hidden = false, children }: TabProps) => {
  const [active, setActive] = useTabs();
  const isActive = active === index;

  return (
    <HopperTab
      role="tab"
      aria-selected={isActive}
      disabled={disabled}
      active={isActive}
      onClick={() => setActive(index)}
      hidden={hidden}
    >
      {children}
    </HopperTab>
  );
};

type TabPanelProps = {
  index: TabIndex;
  children: ReactNode;
};

export const TabPanel = ({ index, children }: TabPanelProps) => {
  const [active] = useTabs();

  return index === active ? <>{children}</> : null;
};

export default Tabs;
