import React from "react";

type FieldProps = {
  dataTestid: string;
  label: string;
  children: JSX.Element;
};

export const Field = ({ dataTestid, label, children }: FieldProps) => (
  <div className="mb-3xl">
    <span className="text-dark font-bold uppercase tracking-wide text-xs">{label}</span>
    <div data-testid={dataTestid}>{children}</div>
  </div>
);
