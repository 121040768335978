import React from "react";
import { StandardTestContent } from "./standard_test_content";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import HeaderSection from "./header_section";
import { useCurrentUser } from "app/current_user/use_current_user";
import { Card } from "@hopper/card";
import { Loader } from "@hopper/loading";
import TestRequestContent from "./test_request_content/test_request_content";
import {
  PreviewPage,
  PreviewPageAdvancedTestRequest,
  PreviewPageStandardTestRequest,
} from "../preview_page/preview_page";
import { StandardTestCloneButton } from "components/flexible_testing/test_request_page/standard_test_clone_button";
import { useTest } from "./use_test";
import { Route, Switch, useRouteMatch } from "react-router";
import { RequestedTestPage } from "./requested_test_page";
import {
  CustomerRequestContent,
  CustomTestRequestContent,
  CustomerRequestSuccessContent,
} from "./customer_request_content";
import { CustomerRequestContextProvider } from "./customer_request_context";
import { websiteContentPaths } from "components/configuration";

type TestRequestPageProps = {
  testIdForCloning?: string;
  previewTestRequest?: PreviewPageAdvancedTestRequest | PreviewPageStandardTestRequest;
};

type AdvancedTestRequestCreatorProps = Pick<TestRequestPageProps, "previewTestRequest"> & {
  testRequest: PreviewPageStandardTestRequest | PreviewPageAdvancedTestRequest | undefined;
  currentUser: ReturnType<typeof useCurrentUser>;
};

const AdvancedTestRequestCreator = ({
  testRequest,
  previewTestRequest,
  currentUser,
}: AdvancedTestRequestCreatorProps) => {
  const { path } = useRouteMatch();
  const isServiceTypeSelectionEnabled = currentUser?.organization?.isServiceTypeSelectionEnabled || false;
  const isCustomerRequestEnabled = currentUser?.organization?.newLaunchUxEnabled || false;

  return (
    <CustomerRequestContextProvider>
      <Switch>
        <Route
          path={path + "/custom/preview"}
          render={routeProps => (
            <PreviewPage
              testRequest={routeProps.location.state as PreviewPageStandardTestRequest | PreviewPageAdvancedTestRequest}
              editPath={websiteContentPaths.advancedTestRequestCustomTest}
            />
          )}
        />
        <Route
          path={path + "/custom"}
          render={() => <CustomTestRequestContent testRequest={previewTestRequest as PreviewPageAdvancedTestRequest} />}
        />
        <Route path={path + "/test"} render={() => <RequestedTestPage />} />
        <Route path={path + "/success"} render={() => <CustomerRequestSuccessContent />} />
        <Route
          path={path}
          render={() => (
            <>
              {isCustomerRequestEnabled ? (
                <CustomerRequestContent />
              ) : (
                <TestRequestContent
                  testRequest={testRequest || previewTestRequest}
                  isServiceTypeSelectionEnabled={isServiceTypeSelectionEnabled}
                />
              )}
            </>
          )}
        />
      </Switch>
    </CustomerRequestContextProvider>
  );
};

export function TestRequestPage({ testIdForCloning, previewTestRequest }: TestRequestPageProps): JSX.Element {
  const currentUser = useCurrentUser();
  const hasAdvancedTestsAccess = currentUser?.organization?.advancedTestsAccess || false;
  const { getTest, testRequest, isLoading } = useTest();
  const isTestRequestLoading = testIdForCloning && isLoading;

  if (testIdForCloning && !isLoading && !testRequest) {
    getTest(testIdForCloning);
  }

  return (
    <section data-testid="test-request-page">
      {isTestRequestLoading ? (
        <Loader />
      ) : (
        <div data-testid="launch-test-page">
          {hasAdvancedTestsAccess ? (
            <AdvancedTestRequestCreator
              testRequest={testRequest}
              previewTestRequest={previewTestRequest}
              currentUser={currentUser}
            />
          ) : (
            <PageTemplate
              header={<HeaderSection shouldShowCloneButton={true} cloneButtonComponent={<StandardTestCloneButton />} />}
            >
              <div data-testid="launch-test-page">
                <Card>
                  <div className="p-3xl">
                    <StandardTestContent
                      testRequest={
                        (testRequest as PreviewPageStandardTestRequest) ||
                        (previewTestRequest as PreviewPageStandardTestRequest)
                      }
                    />
                  </div>
                </Card>
              </div>
            </PageTemplate>
          )}
        </div>
      )}
    </section>
  );
}
