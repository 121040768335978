import React, { useState } from "react";
import { Heading } from "@hopper/heading";
import { useField } from "formik";

import RequestedTestForm, { RequestedTest } from "./requested_test_form";
import Stepper, { WIZARD_STEPS, WizardStepName, getStepByName } from "./stepper";

type RequestedTestWizardProps = {
  stepName?: WizardStepName;
  initialValues?: RequestedTest;
  isEditing?: boolean;
};

const TestName = () => {
  const [{ value }] = useField("name");

  return (
    <div className="mb-xl">
      <Heading size={5}>{value}</Heading>
    </div>
  );
};

const RequestedTestWizard = ({ stepName = "activity", initialValues, isEditing = false }: RequestedTestWizardProps) => {
  const [activeStep, setActiveStep] = useState<WizardStepName>(stepName);
  const step = getStepByName(activeStep);

  const { component: StepComponent, validations } = step;

  const nextStep = () => {
    const activeStepIndex = WIZARD_STEPS.findIndex(({ name }) => name === activeStep);
    const nextStep = WIZARD_STEPS[activeStepIndex + 1];

    if (nextStep) setActiveStep(nextStep.name);
  };

  return (
    <RequestedTestForm initialValues={initialValues} validations={validations}>
      <TestName />
      <Stepper activeStep={activeStep} setActiveStep={setActiveStep} />
      <div className="mt-4xl">
        <StepComponent nextStep={nextStep} isEditing={isEditing} />
      </div>
    </RequestedTestForm>
  );
};

export default RequestedTestWizard;
