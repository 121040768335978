import { history } from "app/history/history";

// A function that routes the user to the right place
// after login. We must use here the same history object
// as we use in our Router.
const auth0RedirectCallback = appState => {
  localStorage.setItem("trackUserSignIn", "true");

  history.replace(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

export default auth0RedirectCallback;
