import React from "react";
import { isEmpty } from "lodash";
import { Heading } from "@hopper/heading";
import { Loader } from "@hopper/loading";
import { Card } from "@hopper/card";

import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { useProfessionalServices } from "./use_professional_services";
import { ProfessionalServiceCard } from "./professional_service_card";

const ProfessionalServicesPage = () => {
  const header = (
    <div data-testid="services-header">
      <Heading size={1}>Services</Heading>
    </div>
  );
  const { professionalServices, isLoading } = useProfessionalServices();

  const loadProfessionalServices = () => {
    if (isLoading) {
      return <Loader />;
    } else if (!isEmpty(professionalServices)) {
      return (
        <div className="grid grid-cols-3 gap-3xl" data-testid="services">
          {professionalServices.map(professionalService => (
            <Card key={professionalService.id} data-test-role="professional-service-card">
              <ProfessionalServiceCard professionalService={professionalService} />
            </Card>
          ))}
        </div>
      );
    } else {
      return <span className="text-secondary text-xl">Professional services coming soon. Stay tuned.</span>;
    }
  };

  return <PageTemplate header={header}>{loadProfessionalServices()}</PageTemplate>;
};

export default ProfessionalServicesPage;
