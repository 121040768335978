import React, { useState, ClipboardEvent } from "react";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import * as yup from "yup";

import { SubmitButton, TextArea } from "components/flexible_testing/shared/form";
import { testerGroupTesterEmailsValidations } from "components/flexible_testing/shared/validations";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { useAddParticipantsToTest } from "./use_add_participants_to_test";
import { useTestersWithInvalidDevices } from "./use_testers_with_invalid_devices";
import { AddTestersValidationModal } from "../add_testers_validation_modal";

const validationSchema = yup.object({ testerEmails: testerGroupTesterEmailsValidations });

const TesterEmailsField = ({ value }: { value?: string }) => {
  const { setFieldValue } = useFormikContext<FormValues>();
  const testerEmails = value?.split(",")?.filter(e => e.trim().length > 0);

  const processPastedEmails = (event: ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const textarea = event.currentTarget;
    const content = event.clipboardData.getData("text/plain");
    const text = content.split("\n").join(", ");

    const [originalStart, originalEnd] = [
      textarea.value.slice(0, textarea.selectionStart),
      textarea.value.slice(textarea.selectionEnd),
    ];

    setFieldValue("testerEmails", originalStart + text + originalEnd);
  };

  return (
    <div data-test-role="form-group" className="text-xs mb-lg">
      <TextArea
        name="testerEmails"
        label="Add backup tester(s)"
        placeholder="Enter tester emails..."
        useHopperInput={true}
        required={false}
        onPaste={processPastedEmails}
      />
      <div>Number of testers added: {testerEmails?.length || 0}</div>
    </div>
  );
};

type FormValues = {
  id: string;
  testerEmails: string;
  groupName: string;
  numberOfResultsNeeded: number;
};

type RequirementsGroupFormProps = {
  testId: string;
  groupId: string;
  groupName: string;
  numberOfResultsNeeded: number;
  selectedOperatingSystemVersionIds?: string[];
};

const ParticipantsForm = ({
  testId,
  groupId,
  groupName,
  numberOfResultsNeeded,
  selectedOperatingSystemVersionIds = [],
}: RequirementsGroupFormProps) => {
  const { addParticipantsToTest } = useAddParticipantsToTest();
  const { testersWithInvalidDevices } = useTestersWithInvalidDevices();
  const [isValidationModalOpen, setValidationModalOpen] = useState<boolean>(false);
  const [invalidTesterEmails, setInvalidTesterEmails] = useState<string[]>([]);
  const [ignoreValidation, setIgnoreValidation] = useState<boolean>(false);

  const formInitialValues = { id: groupId, testerEmails: "", groupName, numberOfResultsNeeded };

  const onSubmit = async (
    { id, testerEmails }: FormValues,
    { setFieldError, setFieldValue }: FormikHelpers<FormValues>,
  ) => {
    const value = testerEmails
      ?.split(",")
      .map(e => e.trim())
      .filter(e => e.length > 0);

    try {
      const { invalidEmails } = await testersWithInvalidDevices({
        requiredOsVersionIds: selectedOperatingSystemVersionIds,
        testerEmails: value,
      });

      if (!ignoreValidation && invalidEmails.length) {
        setValidationModalOpen(true);
        setInvalidTesterEmails(invalidEmails);

        return;
      }
      setIgnoreValidation(false);

      const { successful, errors } = await addParticipantsToTest(id, value, testId);

      if (successful) {
        notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
          type: "success",
          message: "Testers have been added.",
        });
        setFieldValue("testerEmails", "");
      } else {
        setFieldError("testerEmails", errors.map(({ message }: { message: string }) => message).join(" "));
      }
    } catch (_error) {
      notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
        type: "danger",
        message: "There was an unexpected issue while adding testers",
      });
    }
  };

  return (
    <>
      <Formik initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, isValid, submitForm }) => (
          <>
            <Form data-testid="edit-tester-panel-form">
              <TesterEmailsField value={values.testerEmails} />
              <SubmitButton
                size="sm"
                useHopperButton={true}
                style={{ pointerEvents: "auto" }}
                disabled={values.testerEmails?.length < 1 || !isValid}
              >
                Add testers
              </SubmitButton>
            </Form>
            <AddTestersValidationModal
              onClose={function (status?: string | undefined): void {
                setValidationModalOpen(false);
              }}
              onConfirm={function (status?: string | undefined): void {
                setIgnoreValidation(true);
                setValidationModalOpen(false);
                submitForm();
              }}
              isOpen={isValidationModalOpen}
              invalidTesters={invalidTesterEmails}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default ParticipantsForm;
