import format from "date-fns/format";
import { formatDistanceToNowStrict } from "date-fns";

function datetimeFormat(datetime, options = {}) {
  options.format = options.format || "dd' 'LLL' 'yyyy' - 'h':'mma' (UTC)";

  return convertDate(datetime, options);
}

function dateFormat(date, options = {}) {
  options.format = options.format || "dd' 'LLL' 'yyyy";

  return convertDate(date, options);
}

function datetimeDistance(datetime, suffix) {
  return `${formatDistanceToNowStrict(Date.parse(datetime))} ${suffix}`;
}

function convertDate(date, options = {}) {
  const placeholder = options.placeholder || "-";

  return date ? format(dateToUTC(date), options.format) : placeholder;
}

function dateToUTC(dateString) {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export { datetimeFormat, dateFormat, datetimeDistance };
