import React, { forwardRef, useEffect, useState } from "react";
import { Button } from "@hopper/button";

import { CaseResultPopup } from "components/flexible_testing/shared/case_result_popup";
import { useCreateCaseResultEditRequest } from "./use_create_case_result_edit_request";

type CreateEditRequestButtonProps = {
  caseResultId: string;
  disabled: boolean;
  disabledReason?: string;
};

export const CreateEditRequestButton = forwardRef<HTMLSpanElement, CreateEditRequestButtonProps>(
  ({ caseResultId, disabled, disabledReason }, buttonRef) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const caseResultRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (isPopupOpen && caseResultRef.current) {
        caseResultRef.current.scrollIntoView({ block: "nearest" });
      }
    }, [isPopupOpen]);

    const { flexibleTestingCaseResultEditRequestCreate, isLoading: isButtonLoading } = useCreateCaseResultEditRequest(
      () => setIsPopupOpen(false),
    );

    const onSaveButtonClick = (content: string) => {
      flexibleTestingCaseResultEditRequestCreate(caseResultId, content);
    };

    const onCancel = () => {
      setIsPopupOpen(false);
    };

    return (
      <div ref={caseResultRef}>
        <CaseResultPopup
          isOpen={isPopupOpen}
          onCancelButtonClick={onCancel}
          onSaveButtonClick={onSaveButtonClick}
          isButtonLoading={isButtonLoading}
          label="Edit you would like the tester to make"
          placeholder="E.g. Add attachment, replace attachment, redo steps etc."
        >
          <Button
            disabled={disabled}
            variant="secondary"
            appearance="ghost"
            onClick={() => setIsPopupOpen(!isPopupOpen)}
          >
            <span ref={buttonRef} className="text-lg">
              Request tester edit
            </span>
          </Button>
          {disabled && (
            <div data-testid="edit-request-blocked-message" className="text-secondary">
              {disabledReason}
            </div>
          )}
        </CaseResultPopup>
      </div>
    );
  },
);
