import { gql } from "@apollo/client";

const FLEXIBLE_TESTING_TEST_CASES_FRAGMENT = gql`
  fragment flexibleTestingTestCase on FlexibleTestingTestCase {
    id
    name
    code
    section
    status
    resultsCount
    isTestCaseLive
    caseResultsLimit
    caseFields: stepCaseFields {
      expectedResult
      instruction
    }
    completedCaseResults {
      id
      status
      isRejected
      wasRead
    }
  }
`;

export const GET_TEST_CASES_TAB_DATA = gql`
  query getPlatformTestTestCasesTab($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        _id
        cases {
          totalCount
          edges {
            node {
              ...flexibleTestingTestCase
            }
          }
        }
      }
      ... on FlexibleTestingTest {
        id
        _id
        cases {
          totalCount
          edges {
            node {
              ...flexibleTestingTestCase
            }
          }
        }
      }
    }
  }

  ${FLEXIBLE_TESTING_TEST_CASES_FRAGMENT}
`;
