import React from "react";

import { Heading } from "@hopper/heading";
import pluralize from "pluralize";

const ModalSuccessMessage = ({ numberOfTesters }: { numberOfTesters: number }) => (
  <div className="flex flex-col items-center justify-center max-w-xs sm:max-w-full text-center">
    <div
      className="rounded-full flex items-center justify-center mb-2xl mt-xl"
      style={{ backgroundColor: "rgba(64, 189, 166, 0.12)", height: "4.5rem", width: "4.5rem" }}
      data-testid="success-icon"
    >
      <i className="fa fa-check text-success opacity-100 text-3xl" aria-hidden="true" />
    </div>
    <Heading size={3}>Success</Heading>
    <Heading size={4}>
      {`The selected ${pluralize("tester", numberOfTesters)} ${pluralize(
        "was",
        numberOfTesters,
      )} removed from this test.`}
    </Heading>
  </div>
);

export default ModalSuccessMessage;
