import { ApolloClientProvider } from "../apollo_client_provider";
import { AUTHENTICATION_TOKEN_QUERY } from "./authentication_token_query";

function getAuthenticationToken() {
  const client = ApolloClientProvider.apolloClient;

  try {
    const { authenticationToken } = client.readQuery({ query: AUTHENTICATION_TOKEN_QUERY });

    return authenticationToken;
  } catch (e) {
    return null;
  }
}

export { getAuthenticationToken };
