import { useQuery } from "@apollo/client";
import {
  Country,
  Device,
  Language,
  OperatingSystemVersion,
  OrganizationUser,
  ParticipantModerationStatusEnum,
  SelectionRequirementsGroupConnection,
  TestParticipantConnection,
} from "types/graphql";
import { mapParticipant, Participant } from "components/flexible_testing/shared/chat";
import { SELECTION_REQUIREMENTS_GROUPS_QUERY } from "./selection_requirements_groups_query";

export type SelectionRequirementsGroup = {
  id: string;
  name: string;
  platforms: string[];
  numberOfApprovedResults: number;
  numberOfPublishedResults: number;
  numberOfResultsNeeded: number;
  numberOfPendingIssueModerations: number;
  numberOfAssignedCases: number;
  moderationStatus: `${ParticipantModerationStatusEnum}` | undefined;
  hasReportedIssues: boolean;
  operatingSystemVersions: Pick<OperatingSystemVersion, "id" | "fullName">[];
  deviceTypes: Pick<Device, "id" | "name">[];
  manufacturers: Pick<Device, "id" | "name">[];
  mobileDevices: Pick<Device, "id" | "name">[];
  operatingSystems: Pick<Device, "id" | "name">[];
  internetBrowsers: Pick<Device, "id" | "name">[];
  countries: Pick<Country, "id" | "name">[];
  languages: Pick<Language, "id" | "name">[];
  participants: Participant[];
  other: string;
  status: string;
  isFull: boolean;
  tags: Array<string>;
};

type SelectionRequirementsGroupsData = {
  selectionRequirementsGroups: SelectionRequirementsGroup[];
  loading: boolean;
};

function useSelectionRequirementsGroups(testId?: string, user?: OrganizationUser): SelectionRequirementsGroupsData {
  const { data, loading } = useQuery<{
    selectionRequirementsGroups: SelectionRequirementsGroupConnection;
  }>(SELECTION_REQUIREMENTS_GROUPS_QUERY, {
    variables: { testId },
    skip: !testId || (!user?.testManager && !user?.communityTestManager),
  });

  return {
    selectionRequirementsGroups: sortParticipantGroups(
      extractSelectionRequirementsGroups(data?.selectionRequirementsGroups),
    ),
    loading,
  };
}

function sortParticipantGroups(selectionRequirementsGroup: SelectionRequirementsGroup[]): SelectionRequirementsGroup[] {
  const publishedGroups = selectionRequirementsGroup.filter(group => group.status === "published");
  const notPublishedGroups = selectionRequirementsGroup.filter(group => group.status !== "published");

  return [...notPublishedGroups, ...publishedGroups];
}

function extractSelectionRequirementsGroups(
  selectionRequirementGroups?: SelectionRequirementsGroupConnection,
): SelectionRequirementsGroup[] {
  return (
    selectionRequirementGroups?.edges.map(({ node: selectionGroup }) => ({
      id: selectionGroup.id,
      platforms: selectionGroup.platforms || [],
      tags: selectionGroup.tags || [],
      operatingSystemVersions: selectionGroup.operatingSystemVersions.edges.map(({ node: osVersion }) => ({
        id: osVersion.id,
        fullName: osVersion.fullName,
      })),
      deviceTypes: selectionGroup.deviceTypes.edges.map(({ node: deviceType }) => ({
        id: deviceType.id,
        name: deviceType.name,
      })),
      manufacturers: selectionGroup.manufacturers.edges.map(({ node: deviceType }) => ({
        id: deviceType.id,
        name: deviceType.name,
      })),
      mobileDevices: selectionGroup.mobileDevices.edges.map(({ node: deviceType }) => ({
        id: deviceType.id,
        name: deviceType.name,
      })),
      operatingSystems: selectionGroup.operatingSystems.edges.map(({ node: deviceType }) => ({
        id: deviceType.id,
        name: deviceType.name,
      })),
      internetBrowsers: selectionGroup.internetBrowsers.edges.map(({ node: deviceType }) => ({
        id: deviceType.id,
        name: deviceType.name,
      })),
      countries: selectionGroup.countries.edges.map(({ node: country }) => ({
        id: country.id,
        name: country.name,
      })),
      languages: selectionGroup.languages.edges.map(({ node: language }) => ({
        id: language.id,
        name: language.name,
      })),
      numberOfApprovedResults: selectionGroup.numberOfApprovedResults,
      numberOfPublishedResults: selectionGroup.numberOfPublishedResults,
      numberOfResultsNeeded: selectionGroup.numberOfResultsNeeded,
      numberOfAssignedCases: selectionGroup.numberOfAssignedCases,
      numberOfPendingIssueModerations: countPendingIssueModerations(selectionGroup.participants),
      moderationStatus: specifyGroupIssuesModerationStatus(selectionGroup.participants),
      hasReportedIssues: hasReportedIssues(selectionGroup.participants),
      name: selectionGroup.name || "",
      other: selectionGroup.other || "",
      status: selectionGroup.status || "",
      isFull: selectionGroup.isFull,
      participants: selectionGroup.participants.edges.map(({ node: participant }) => mapParticipant(participant)),
    })) || []
  );
}

function hasReportedIssues(participantConnection: TestParticipantConnection) {
  return participantConnection.edges.some(({ node: participant }) => participant.hasReportedIssue);
}

function countPendingIssueModerations(participantConnection: TestParticipantConnection) {
  const participants = participantConnection.edges.map(({ node }) => node);

  return participants.reduce((count: number, participant) => count + participant.numberOfPendingIssueModerations, 0);
}

function specifyGroupIssuesModerationStatus(participantConnection: TestParticipantConnection) {
  const participants = participantConnection.edges.map(({ node }) => node.moderationStatus);

  // NOTICE: It's not possible to import and enum as a value from the d.ts file.
  // To make it work we would need to convert graphql.d.ts to a .ts file.
  // @ts-ignore
  if (participants.includes("invalid_issue")) {
    return "invalid_issue";
    // @ts-ignore
  } else if (participants.includes("pending")) {
    return "pending";
    // @ts-ignore
  } else if (participants.includes("known_issue")) {
    return "known_issue";
    // @ts-ignore
  } else if (participants.includes("moderated")) {
    return "moderated";
  }
}

export { useSelectionRequirementsGroups };
