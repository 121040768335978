import { gql, useMutation } from "@apollo/client";

import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { ChangeAssignmentStatusInput } from "types/graphql";

export const CHANGE_ASSIGNMENT_STATUS = gql`
  mutation ChangeAssignmentStatus($input: ChangeAssignmentStatusInput!) {
    changeAssignmentStatus(input: $input) {
      successful
      result {
        id
        status
      }
      errors {
        message
        code
      }
    }
  }
`;

const displayError = (errorMessage: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: errorMessage || "There was an issue with changing assignment status.",
  });
};

export const useChangeAssignmentStatus = () => {
  const [changeAssignmentStatus, { loading }] = useMutation(CHANGE_ASSIGNMENT_STATUS, {
    onCompleted: ({ changeAssignmentStatus: { successful, errors } }) => {
      if (!successful) {
        const errorMessage = errors.map(({ message }: { message: string }) => message).join(", ");

        displayError(errorMessage);
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = graphQLErrors.map(({ message }) => message).join(", ");

      displayError(errorMessage);
    },
  });

  return {
    changeAssignmentStatus: async ({
      assignedCommunityTestManagerId,
      status,
    }: ChangeAssignmentStatusInput): Promise<boolean> => {
      const { data } = await changeAssignmentStatus({
        variables: {
          input: {
            assignedCommunityTestManagerId,
            status,
          },
        },
      });

      return data.changeAssignmentStatus.successful;
    },
    loading,
  };
};
