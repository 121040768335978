import React, { useEffect, useRef } from "react";
import { Button } from "@hopper/button";
import classNames from "classnames";

type IssueFiltersToggleProps = {
  currentIssuesFilter: string;
  newIssues: number;
  acceptedIssues: number;
  rejectedIssues: number;
  setFilter: (argument: string) => void;
};

function IssueFiltersToggle({
  currentIssuesFilter,
  newIssues,
  acceptedIssues,
  rejectedIssues,
  setFilter,
}: IssueFiltersToggleProps): JSX.Element {
  const FILTER_OPTIONS = [
    {
      type: "untriaged",
      testid: "new-issues",
      content: (
        <>
          {newIssues > 0 && <i className="fa fa-circle mr-sm text-2xs text-link" />}
          New <span className="text-secondary ml-md">{newIssues}</span>
        </>
      ),
    },
    {
      type: "accepted",
      testid: "accepted-issues",
      content: (
        <>
          Accepted <span className="text-secondary ml-md">{acceptedIssues}</span>
        </>
      ),
    },
    {
      type: "rejected",
      testid: "rejected-issues",
      content: (
        <>
          Rejected <span className="text-secondary ml-md">{rejectedIssues}</span>
        </>
      ),
    },
  ];

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      const currentSelection = FILTER_OPTIONS.find(({ type }) => type === currentIssuesFilter);
      const selectedButton = divRef.current.querySelector(
        `[data-testid="${currentSelection?.testid}"]`,
      ) as HTMLElement | null;
      if (selectedButton) {
        const elementRect = selectedButton.getBoundingClientRect();
        const parentRect = divRef.current.getBoundingClientRect();
        const selectionIndicatorWidth = selectedButton.offsetWidth;
        const selectionIndicatorOffset = elementRect.left - parentRect.left;
        divRef.current.style.setProperty("--selection-indicator-width", `${selectionIndicatorWidth}px`);
        divRef.current.style.setProperty("--selection-indicator-offset", `${selectionIndicatorOffset}px`);
      }
    }
    // newIssues included to prevent a visual bug on page load
  }, [currentIssuesFilter, newIssues]);

  const filterButtons = FILTER_OPTIONS.map(option => (
    <Button
      key={option.type}
      className={classNames(
        "active:bg-opacity-0 hover:bg-opacity-50",
        "text-sm shadow-none",
        currentIssuesFilter === option.type ? "" : "bg-opacity-0 hover:bg-muted",
      )}
      size="sm"
      variant="secondary"
      data-testid={option.testid}
      appearance={currentIssuesFilter === option.type ? "minimal" : "default"}
      onClick={() => setFilter(option.type)}
    >
      {option.content}
    </Button>
  ));

  return (
    <div className="bg-light rounded-base relative flex" ref={divRef} style={{ padding: "2px", height: "fit-content" }}>
      <div
        className="block rounded-base absolute bg-white top-2px left-0 shadow-sm"
        style={{
          width: "var(--selection-indicator-width)",
          height: "calc(100% - 4px)",
          transition: "transform cubic-bezier(.2,.6,.3,1) 300ms, width ease-out 300ms",
          transform: "translateX(var(--selection-indicator-offset))",
        }}
      ></div>
      {filterButtons}
    </div>
  );
}

export default IssueFiltersToggle;
