import { FieldHookConfig, useField } from "formik";
import React from "react";
import FormLabel from "../form_label";

type TextInputProps = {
  label?: string;
  labelClass?: string;
  errorClass?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  "data-testid"?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

function TextInput({
  label,
  required,
  onChange = () => {},
  validate,
  labelClass,
  errorClass,
  ...props
}: TextInputProps & FieldHookConfig<string>): JSX.Element {
  const [field, meta] = useField({ validate, ...props });
  const id = props.id ?? props.name;

  return (
    <>
      <FormLabel label={label} required={required} labelProps={{ htmlFor: id, className: labelClass }} />
      <input
        type="text"
        id={id}
        {...field}
        {...(props as React.InputHTMLAttributes<HTMLInputElement>)}
        onChange={event => {
          field.onChange(event);
          onChange(event);
        }}
      />
      {meta.touched && meta.error ? (
        <div className={`text-danger mb-l ${errorClass}`} data-testid={`field-error-${props.name}`}>
          {meta.error}
        </div>
      ) : null}
    </>
  );
}

export default TextInput;
