import React from "react";
import { Heading } from "@hopper/heading";

type TestCaseHeaderProps = {
  testId: string;
  children: React.ReactNode;
};

function TestCaseHeader({ testId, children }: TestCaseHeaderProps): JSX.Element {
  return (
    <section className="mt-3xl max-w-lg">
      <Heading size={2}>{children}</Heading>
    </section>
  );
}

export default TestCaseHeader;
