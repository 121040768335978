import React, { useRef } from "react";

import { useIssueUpdateTags } from "./use_issue_update_tags";
import { FlexibleTestingIssueTag } from "types/graphql";

import { sortBy } from "lodash";
import TagSelect from "./tag_select";

type TagCellProps = {
  tags: FlexibleTestingIssueTag[];
  issueId: string;
  isActiveTagsListLimited: boolean;
};

const TagCell = ({ tags, issueId, isActiveTagsListLimited }: TagCellProps) => {
  const { updateIssueTags } = useIssueUpdateTags();
  const wrapperRef = useRef(null);

  tags = sortBy(tags, [tag => tag.name.toLowerCase()]);

  const handleTagsUpdate = (issueTags: Array<FlexibleTestingIssueTag>) => updateIssueTags(issueId, issueTags);

  return (
    <div
      className="cursor-default flex items-center"
      onClick={event => event.stopPropagation()}
      ref={wrapperRef}
      data-testid="tag-cell"
    >
      <TagSelect isReadOnly={false} {...{ tags, isActiveTagsListLimited, handleTagsUpdate, wrapperRef }} />
    </div>
  );
};

export default TagCell;
