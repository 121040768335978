import React from "react";

type TestingServiceCardPricingItemProps = {
  itemTitle: string;
  price: React.ReactNode;
  note?: string;
};

export function TestingServiceCardPricingItem({
  itemTitle,
  price,
  note,
}: TestingServiceCardPricingItemProps): JSX.Element {
  return (
    <div className="flex text-darkest pt-lg text-sm leading-sm">
      <div className="flex-2">
        {itemTitle}
        <div className="text-secondary text-xs leading-lg">{note}</div>
      </div>
      <div className="flex-1 text-right font-bold">{price}</div>
    </div>
  );
}
