import React, { useState } from "react";
import { Button } from "@hopper/button";
import PublicChatPanel from "./public_chat_panel";

type OpenPublicChatButtonProps = {
  testName: string;
  testId: string;
  testCode: string;
  ctmsAccess: boolean;
};

function OpenPublicChatButton({ testName, testId, testCode, ctmsAccess }: OpenPublicChatButtonProps) {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  return (
    <>
      <Button variant="secondary" className="m-xs" onClick={() => setIsChatOpen(!isChatOpen)}>
        <i className="fa fa-comment-o mr-sm" aria-hidden="true" />
        Chat
        {unreadMessagesCount > 0 && (
          <span className="h-[10px] w-[10px] absolute top-[-3px] right-[-3px] rounded-full bg-danger" />
        )}
      </Button>
      <PublicChatPanel
        testId={testId}
        isChatOpen={isChatOpen}
        testName={testName}
        testCode={testCode}
        onChatOpen={() => setIsChatOpen(true)}
        onChatClose={() => setIsChatOpen(false)}
        ctmsAccess={ctmsAccess}
        setUnreadMessagesCount={setUnreadMessagesCount}
      />
    </>
  );
}

export default OpenPublicChatButton;
