import React from "react";

type ImageAttachmentThumbnailProps = {
  attachmentUrl: string;
};

function ImageAttachmentThumbnail({ attachmentUrl }: ImageAttachmentThumbnailProps): JSX.Element {
  return (
    <img
      alt="Image attachment thumbnail"
      src={attachmentUrl}
      className="max-h-full max-w-full"
      style={{ objectFit: "contain" }}
      data-testid="image-attachment-thumbnail"
    />
  );
}

export default ImageAttachmentThumbnail;
