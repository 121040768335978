import { useLocation } from "react-router-dom";

function usePreviousState() {
  const location = useLocation();
  let previousState = {};

  if (typeof location.state === "object") {
    previousState = { ...location.state };
  }

  return previousState;
}

export default usePreviousState;
