import React, { useLayoutEffect, useState } from "react";
import classnames from "classnames";
import queryString from "query-string";
import { useHistory } from "react-router";
import { TabBar } from "@hopper/tab_bar";
import { Tab } from "@hopper/tab";
import { FlexibleTestingTestCaseResult } from "types/graphql";
import CaseResult from "components/flexible_testing/test_case_result_page/tester_tabs/case_result";
import { useMarkCaseResultAsRead } from "./use_mark_case_result_as_read";
import { TestCaseFields } from "components/flexible_testing/test_case_result_page/use_test_case_result";

type TesterTabsParams = {
  caseResults: FlexibleTestingTestCaseResult[];
  caseFields: TestCaseFields;
  prerequisites?: string | null;
};

type statusToColorMappingProps = {
  [type: string]: string;
};

const TesterTabs = ({ caseResults, caseFields, prerequisites }: TesterTabsParams) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [alreadyCheckedCaseResultIds, setAlreadyCheckedCaseResultIds] = useState<Array<string>>([]);
  const { markCaseResultAsRead } = useMarkCaseResultAsRead();
  const history = useHistory();
  const paramsTabCaseResultId = queryString.parse(location.search).tab;

  const statusToColorMapping: statusToColorMappingProps = {
    pass: "success",
    fail: "danger",
    blocked: "warning",
    notTested: "secondary",
    unknown: "secondary",
    rejected: "muted",
    skipped: "muted",
    missing: "muted",
  };

  function resolveCaseResultReadStatus(testerTabIndex: number) {
    if (caseResults) {
      const selectedCaseResult = caseResults[testerTabIndex];
      if (selectedCaseResult && shouldCaseResultBeMarkedAsRead(selectedCaseResult)) {
        setAlreadyCheckedCaseResultIds([...alreadyCheckedCaseResultIds, selectedCaseResult.id]);
        markCaseResultAsRead(selectedCaseResult.id);
      }
    }
  }

  useLayoutEffect(() => {
    if (caseResults) {
      let index = caseResults.findIndex(caseResult => caseResult.id === paramsTabCaseResultId);
      if (index < 0) index = 0;
      setSelectedTab(index);
      resolveCaseResultReadStatus(index);
    }
  }, []);

  function shouldCaseResultBeMarkedAsRead(selectedCaseResult: FlexibleTestingTestCaseResult): boolean {
    return !selectedCaseResult.wasRead && !alreadyCheckedCaseResultIds.includes(selectedCaseResult.id);
  }

  function handleTabClick(caseResult: FlexibleTestingTestCaseResult, index: number) {
    setSelectedTab(index);
    resolveCaseResultReadStatus(index);
    history.push({
      search: `?tab=${caseResult.id}`,
    });
  }

  return (
    <div>
      <div className="border-b border-muted mt-lg">
        <TabBar>
          {caseResults?.map((caseResult, index) => {
            const status = caseResult.isRejected ? "rejected" : caseResult.status || "unknown";
            const color = statusToColorMapping[status];

            return (
              <Tab
                active={selectedTab === index}
                onClick={() => {
                  handleTabClick(caseResult, index);
                }}
                data-testid={`tester-tab-${index + 1}`}
              >
                <i className={classnames("fa fa-circle mr-sm text-2xs", `text-${color}`)} data-testid="status-icon" />
                <span className={caseResult.wasRead ? "" : "text-darkest font-bold"}>
                  {caseResult?.tester?.fullName || `Tester ${index + 1}`}
                </span>
              </Tab>
            );
          })}
        </TabBar>
      </div>

      <div>
        {caseResults?.map((caseResult, index) => (
          <CaseResult
            caseResult={caseResult}
            tabIndex={index}
            selectedTab={selectedTab}
            caseFields={caseFields}
            prerequisites={prerequisites}
          />
        ))}
      </div>
    </div>
  );
};

export default TesterTabs;
