import React from "react";
import { StatusLabel } from "@hopper/status_label";

type IntegrationStateLabelProps = {
  isActive: boolean;
};

const IntegrationStateLabel = ({ isActive }: IntegrationStateLabelProps) => {
  const text = isActive ? "ACTIVE" : "INACTIVE";
  const color = isActive ? "default" : "transparent";
  const className = isActive ? "ml-sm" : "ml-0";

  return (
    <span className={className}>
      <StatusLabel variant={color}>{text}</StatusLabel>
    </span>
  );
};

export default IntegrationStateLabel;
