import React, { useState } from "react";

import AccountNavigation from "../account_navigation/index";
import Dropdown from "components/shared/dropdown/index";
import MenuItemBorder from "../menu_item_border/index";

type SideMenuProps = {
  shouldShowCreditModelMenuOption: boolean;
};

const SideMenu = ({ shouldShowCreditModelMenuOption }: SideMenuProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div data-testid="flexible-testing-side-nav" className="flex items-center h-6xl border-none">
      <div
        className="flex items-center text-body uppercase pt-xs"
        data-testid="account-settings"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div>
          <Dropdown title="Account" alignment="right">
            <AccountNavigation showCreditModel={shouldShowCreditModelMenuOption} />
          </Dropdown>
          <MenuItemBorder isVisible={isHovered} />
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
