import React, { useState } from "react";
import { FlexibleTestingTestCaseResult } from "types/graphql";
import groupTestCasesBySection from "../../utils/group_test_cases_by_section";
import TestCasesGroupHeader from "./test_cases_group_header";
import TestCaseRow from "./test_case_row";
import { AssignedCaseWithOwnResult } from "../tester_overview";

type TestCasesListProps = {
  testCases: Array<AssignedCaseWithOwnResult>;
  testerId?: string;
  onTestCaseResultRowClick: (testCaseResult: FlexibleTestingTestCaseResult) => void;
  selectedTestCaseResultId?: string;
};

const TestCasesListGroupedBySection = ({
  testCases,
  testerId,
  onTestCaseResultRowClick,
  selectedTestCaseResultId,
}: TestCasesListProps): JSX.Element => {
  const groupedTestCasesBySection = groupTestCasesBySection(testCases);
  const [collapsedGroupSections, setCollapsedGroupSections] = useState<string[]>([]);

  const toggleGroup = (groupSection: string) => {
    if (collapsedGroupSections.includes(groupSection)) {
      setCollapsedGroupSections(collapsedGroupSections.filter(section => section !== groupSection));
    } else {
      setCollapsedGroupSections([...collapsedGroupSections, groupSection]);
    }
  };

  return (
    <>
      {groupedTestCasesBySection.map(group => {
        const isGroupCollapsed = collapsedGroupSections.includes(group.section);

        return (
          <>
            <TestCasesGroupHeader
              isCollapsed={isGroupCollapsed}
              group={group}
              onClick={() => toggleGroup(group.section)}
            />
            {!isGroupCollapsed &&
              group.testCases.map(testCase => (
                <TestCaseRow
                  testCase={testCase as AssignedCaseWithOwnResult}
                  testerId={testerId}
                  onTestCaseResultRowClick={onTestCaseResultRowClick}
                  selectedTestCaseResultId={selectedTestCaseResultId}
                />
              ))}
            <div className="mb-xl" />
          </>
        );
      })}
    </>
  );
};

export default TestCasesListGroupedBySection;
