import React from "react";
import { Button } from "@hopper/button";
import { useHistory } from "react-router-dom";

import { websiteContentPaths } from "components/configuration";
import { useCustomerRequestContext } from "../customer_request_context";

type RequestedTestWizardNavigationProps = {
  submitCaption?: string;
  submitType?: "button" | "submit";
  onSubmit?: () => void;
  submitDisabled: boolean;
};

const CancelButton = () => {
  const history = useHistory();
  const { tests } = useCustomerRequestContext();

  if (tests.length === 0) return null;

  return (
    <Button
      type="button"
      variant="secondary"
      appearance="ghost"
      onClick={() => history.push(websiteContentPaths.flexcaseLaunch)}
      className="mr-3xl"
    >
      Cancel
    </Button>
  );
};

const RequestedTestWizardNavigation = ({
  submitCaption = "Next",
  submitType = "button",
  onSubmit,
  submitDisabled,
}: RequestedTestWizardNavigationProps) => (
  <div className="flex gap-xl mt-3xl">
    <Button type={submitType} onClick={onSubmit} disabled={submitDisabled}>
      {submitCaption}
    </Button>

    <CancelButton />
  </div>
);

export default RequestedTestWizardNavigation;
