import { gql } from "@apollo/client";

export const ORGANIZATION_STATS = gql`
  query getOrganizationStats {
    organizationStats {
      testsCount
      issuesTotalCount
      issuesLowCount
      issuesMediumCount
      issuesHighCount
      issuesCriticalCount
      testCasesTotalCount
      testCasesFailedCount
      participantsTotalCount
    }
  }
`;
