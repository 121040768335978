import React from "react";
import { Button, ButtonProps } from "@hopper/button";

import { newSupervisedTestTestCycleUrl } from "components/configuration";
import arrowUpRightWhite from "assets/images/arrow-up-right-white.svg";
import arrowUpRightBlack from "assets/images/arrow-up-right-black.svg";

type NewTestCycleButtonProps = {
  advancedTestRequestId: string;
  testId: string;
  variant?: ButtonProps["variant"];
  appearance?: ButtonProps["appearance"];
  size?: ButtonProps["size"];
};

function NewTestCycleButton({
  advancedTestRequestId,
  testId,
  variant = "primary",
  appearance = "default",
  size = "md",
}: NewTestCycleButtonProps): JSX.Element {
  const arrowUpRight = variant === "secondary" ? arrowUpRightBlack : arrowUpRightWhite;
  const newTestCycleUrl = newSupervisedTestTestCycleUrl
    .replace(":id", advancedTestRequestId)
    .replace(":testId", testId);

  return (
    <Button href={newTestCycleUrl} target="_blank" variant={variant} appearance={appearance} size={size}>
      Create a test cycle
      <img src={arrowUpRight} className="ml-xs text-black" />
    </Button>
  );
}

export default NewTestCycleButton;
