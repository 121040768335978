import { gql, useMutation } from "@apollo/client";

type MarkIssueAsRead = {
  markIssueAsRead: (issueId: string) => Promise<void>;
  wasSuccessfullyRequested: boolean;
};

export const MARK_ISSUE_AS_READ_MUTATION = gql`
  mutation MarkIssueAsRead($input: FlexibleTestingMarkIssueAsReadInput!) {
    flexibleTestingMarkIssueAsRead(input: $input) {
      successful
      result {
        id
        wasRead
      }
      errors {
        code
        message
      }
    }
  }
`;

export const useMarkIssueAsRead = (): MarkIssueAsRead => {
  const [markIssueAsRead, { error }] = useMutation(MARK_ISSUE_AS_READ_MUTATION, {
    onCompleted: () => {},
    onError: () => {},
  });

  return {
    markIssueAsRead: async (issueId): Promise<void> => {
      await markIssueAsRead({
        variables: {
          input: { issueId },
        },
      });
    },
    wasSuccessfullyRequested: !error,
  };
};
