import React, { useState } from "react";

// @ts-ignore
import Loader from "react-loader";
import { Heading } from "@hopper/heading";
import { Tab } from "@hopper/tab";
import { TabBar } from "@hopper/tab_bar";

import OrganizationUsersForm from "components/flexible_testing/shared/organization_users_form";
import OrganizationForm from "components/flexible_testing/shared/organization_form";
import useOrganizationUserList from "./use_organization_user_list";
import { List } from "components/flexible_testing/shared/list";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import { useCurrentUser } from "app/current_user/use_current_user";
import { useOrganizationChosenNameSuggestion } from "components/flexible_testing/shared/use_organization_chosen_name_suggestion";

function OrganizationUsersPage(): JSX.Element {
  const currentUser = useCurrentUser();
  const organizationUsersList = useOrganizationUserList();
  const nameSuggestion = useOrganizationChosenNameSuggestion();
  const organizationName = currentUser?.organization.chosenName || nameSuggestion;
  const [selectedTab, setSelectedTab] = useState("organizationSettings");
  const [chosenName, setChosenName] = useState(organizationName);
  const mappedOrganizationUsers = organizationUsersList.data.map(user => {
    const userName = user.email.includes("globalapptesting.com") ? user.fullName + " (GAT)" : user.email;

    return { name: userName };
  });

  const header = (
    <div data-testid="organisation-header">
      <Heading size={1}>Organization</Heading>
    </div>
  );

  const changeTab = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const TeamsTab = (): JSX.Element => (
    <div data-testid="teams-form" className="grid grid-cols-1 md:grid-cols-2">
      <PageSection orderNumber={1} title="Add new team members">
        <OrganizationUsersForm />
      </PageSection>
      <PageSection orderNumber={2} title="Your current team members">
        <Loader loaded={!organizationUsersList.isLoading}>
          <div className="pr-6xl">
            <List items={mappedOrganizationUsers} />
          </div>
        </Loader>
      </PageSection>
    </div>
  );

  return (
    <div data-testid="organisation-settings-page">
      <PageTemplate header={header}>
        <div className="border-b border-light">
          <TabBar>
            <Tab active={selectedTab === "organizationSettings"} onClick={() => changeTab("organizationSettings")}>
              Organization settings
            </Tab>
            <Tab active={selectedTab === "team"} onClick={() => changeTab("team")}>
              Team
            </Tab>
          </TabBar>
        </div>
        <div className="py-3xl">
          {selectedTab === "organizationSettings" && (
            <OrganizationForm chosenName={chosenName} setChosenName={setChosenName} />
          )}
          {selectedTab === "team" && <TeamsTab />}
        </div>
      </PageTemplate>
    </div>
  );
}

export default OrganizationUsersPage;
