import { gql, useMutation } from "@apollo/client";

import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

export const CLOSE_TEST_WINDOW = gql`
  mutation ($input: FlexibleTestingCloseTestWindowInput!) {
    flexibleTestingCloseTestWindow(input: $input) {
      successful
      result {
        id
        testWindow {
          closedAt
          status
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Test closed successfully",
  });
};

const displayError = (message: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message,
  });
};

export const useCloseTestWindow = () => {
  const [closeTestWindow] = useMutation(CLOSE_TEST_WINDOW, {
    onCompleted: ({ flexibleTestingCloseTestWindow }) => {
      if (flexibleTestingCloseTestWindow?.successful) {
        displaySuccess();
      } else {
        const errorMessage = flexibleTestingCloseTestWindow.errors
          .map(({ message }: { message: string }) => message)
          .join(",");

        displayError(errorMessage);
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = graphQLErrors.map(({ message }) => message).join(",");

      displayError(errorMessage);
    },
  });

  return {
    closeTestWindow: async (testId: string) => await closeTestWindow({ variables: { input: { testId } } }),
  };
};
