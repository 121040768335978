import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { websiteContentPaths } from "components/configuration";
import FlexibleTestingLayout from "components/layouts/flexible_testing_layout";
// @ts-ignore
import OrganizationLayout from "components/layouts/organization_layout";
import NotFoundPage from "components/not_found_page";
import { SupervisedTestRequestPageSection } from "components/flexible_testing/test_manager";
import { useCurrentUser } from "app/current_user/use_current_user";

// @ts-ignore
const withLayout = (Component, Layout) => routeProps =>
  (
    <Layout {...routeProps}>
      <Component {...routeProps} />
    </Layout>
  );

export const TestManagerSection = () => {
  const currentUser = useCurrentUser();
  const isTestManager = currentUser?.testManager || false;

  if (!isTestManager) return <Redirect to={{ pathname: websiteContentPaths.flexcaseLaunch }} />;

  return (
    <Switch>
      <Route
        path={websiteContentPaths.supervisedTestLaunchChild}
        render={withLayout(SupervisedTestRequestPageSection, FlexibleTestingLayout)}
      />
      <Route render={withLayout(NotFoundPage, OrganizationLayout)} />
    </Switch>
  );
};
