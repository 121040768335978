import { gql, useMutation } from "@apollo/client";
import { FlexibleTestingIssueNoteUpdateInput, FlexibleTestingIssueNoteUpdatePayload } from "types/graphql";

export const UPDATE_ISSUE_NOTE = gql`
  mutation FlexibleTestingIssueNoteUpdate($input: FlexibleTestingIssueNoteUpdateInput!) {
    flexibleTestingIssueNoteUpdate(input: $input) {
      successful
      result {
        id
        issueNote {
          note
          lastEditorName
          updatedAt
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

type useIssueNoteUpdateResult = {
  updateIssueNote: (issueId: string, note: string) => Promise<void>;
};

export const useIssueNoteUpdate = (): useIssueNoteUpdateResult => {
  const [updateIssueNoteMutation] = useMutation<
    { flexibleTestingIssueNoteUpdate: FlexibleTestingIssueNoteUpdatePayload },
    { input: FlexibleTestingIssueNoteUpdateInput }
  >(UPDATE_ISSUE_NOTE);

  return {
    updateIssueNote: async (issueId: string, note: string): Promise<void> => {
      await updateIssueNoteMutation({
        variables: {
          input: { issueId, note },
        },
      });
    },
  };
};
