import { gql, useMutation } from "@apollo/client";
import { SELECTION_REQUIREMENTS_GROUPS_QUERY } from "components/flexible_testing/shared/use_selection_requirements_groups";

export const ADD_PARTICIPANTS_TO_TEST = gql`
  mutation ($input: AddParticipantsToTestInput!) {
    addParticipantsToTest(input: $input) {
      successful
      result
      errors {
        code
        message
      }
    }
  }
`;

export const useAddParticipantsToTest = () => {
  const [addParticipantsToTest] = useMutation(ADD_PARTICIPANTS_TO_TEST);

  return {
    addParticipantsToTest: async (requirementsGroupId: string, testerEmails: string[], testId: string) => {
      const { data } = await addParticipantsToTest({
        variables: { input: { requirementsGroupId, testerEmails } },
        refetchQueries: [{ query: SELECTION_REQUIREMENTS_GROUPS_QUERY, variables: { testId } }],
      });

      return data.addParticipantsToTest;
    },
  };
};
