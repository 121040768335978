import { FieldHookConfig, useField } from "formik";
import React from "react";
import { Switch as SwitchInput } from "@hopper/switch";
import { FormField } from "@hopper/legacy_form_field";

type SwitchProps = {
  offLabel: string;
  onLabel: string;
  errorClass?: string;
  onToggle?: (value: boolean) => void;
  "data-testid"?: string;
};

function Switch({
  offLabel,
  onLabel,
  onToggle = () => {},
  validate,
  errorClass,
  ...props
}: FieldHookConfig<string> & SwitchProps): JSX.Element {
  const [field, meta, helpers] = useField({ validate, ...props });
  const { setValue } = helpers;
  const { value } = meta;

  return (
    <FormField errorMessage={meta.error} data-testid={`field-error-${props.name}`}>
      <SwitchInput
        isOn={Boolean(field.value)}
        onLabel={onLabel}
        offLabel={offLabel}
        {...field}
        onChange={event => {
          const newValue = !value;
          // @ts-ignore
          setValue(newValue);
          onToggle(newValue);
        }}
      />
    </FormField>
  );
}

export default Switch;
