import { ProfessionalService } from "types/graphql";
import { gql, useQuery } from "@apollo/client";

export const PROFESSIONAL_SERVICES_QUERY = gql`
  query getProfessionalServices {
    professionalServices {
      edges {
        node {
          id
          title
          description
          cost
          serviceLevelAgreement
          requestFormInstructions
        }
      }
    }
  }
`;

type ProfessionalServicesResult = {
  professionalServices: Array<ProfessionalService>;
  isLoading: boolean;
};

export const useProfessionalServices = (): ProfessionalServicesResult => {
  const { data, loading } = useQuery(PROFESSIONAL_SERVICES_QUERY);
  const professionalServices =
    data?.professionalServices?.edges.map(({ node }: { node: ProfessionalService }) => node) || [];

  return {
    professionalServices,
    isLoading: loading,
  };
};
