import { Test } from "components/flexible_testing/tests_for_cloning_page/tests_list/use_tests";
import React from "react";
import { Loader } from "@hopper/loading";
import { TestsListItem } from "components/flexible_testing/tests_for_cloning_page/tests_list/tests_list_item";
import { Button } from "@hopper/button";

type TestsListProps = {
  isLoading: boolean;
  tests: Array<Test>;
  hasNextPage?: boolean;
  loadNextPage: () => void;
  isLoadingNextPage: boolean;
  trackingEventName: string;
};

function TestsList({
  isLoading,
  tests,
  hasNextPage,
  loadNextPage,
  isLoadingNextPage,
  trackingEventName,
}: TestsListProps): JSX.Element {
  if (isLoading) return <Loader />;

  return (
    <>
      <div className="grid grid-cols-12 w-full border-b border-light gap-xl">
        <span className="text-xs col-span-4 text-secondary tracking-wide py-sm pr-lg uppercase">
          test name and date
        </span>
        <span className="text-xs col-span-2 text-secondary tracking-wide py-sm uppercase">application url</span>
        <span className="text-xs col-span-2 text-secondary tracking-wide py-sm uppercase">test cases</span>
        <span className="text-xs col-span-4 text-secondary tracking-wide py-sm uppercase">objective</span>
        <span />
      </div>
      {tests.map(test => (
        <TestsListItem key={test.id} test={test} trackingEventName={trackingEventName} />
      ))}
      <div className="flex justify-center mt-4xl">
        <Button
          variant="secondary"
          appearance="ghost"
          isLoading={isLoadingNextPage}
          disabled={!hasNextPage}
          onClick={loadNextPage}
        >
          View more test runs
        </Button>
      </div>
    </>
  );
}

export default TestsList;
