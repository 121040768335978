import { StreamMessage, useChannelStateContext } from "stream-chat-react";

type TestRole = "tester" | "test_manager" | "community_test_manager" | undefined;

type AuthorInformation = {
  testRole: TestRole;
};

export function useAuthorInformation(message: StreamMessage): AuthorInformation {
  const { channel } = useChannelStateContext();

  return {
    testRole: Object.values(channel.state.members).find(user => user.user_id === message.user?.id)?.user
      ?.test_role as TestRole,
  };
}
