import { ApolloClient, ApolloLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import fragmentTypes from "../../../fragmentTypes.json";
import { websiteContentPaths, websocketServerUrl } from "../../components/configuration";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "../notificationsEventBus";
import { GET_CURRENT_ORGANIZATION_USER } from "components/shared/queries/organization_users_query";
import { customFetcher } from "./custom_fetcher";
import { createConsumer } from "@rails/actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { getAuthenticationToken } from "../authentication_token/get_authentication_token";
import { captureMessage } from "@sentry/browser";

class ApolloClientProvider {
  #logoutFunction = null;
  #apolloClient = null;
  #redirectTo = null;

  get apolloClient() {
    if (this.#apolloClient === null) {
      throw new Error("Trying to access Apollo client before initializing it");
    }

    return this.#apolloClient;
  }

  initialize = (logout, redirectTo) => {
    const cache = new InMemoryCache({
      possibleTypes: fragmentTypes.possibleTypes,
      currentOrganizationUser: null,
      // typePolicy is required for merging results of paginated collections:
      // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
      typePolicies: {
        Query: {
          fields: {
            flexibleTestingTests: {
              keyArgs: ["filters"],
              merge(existing = [], incoming, { args }) {
                let flexibleTestingTests;

                if (existing.length === 0) {
                  return incoming;
                }

                // We merge existing and incoming tests only for "fetchMore" queries that use "after" argument
                // In other cases we use a default procedure, which means replacing old cache with incoming objects.
                if (args?.after) {
                  flexibleTestingTests = {
                    ...incoming,
                    edges: [...existing.edges, ...incoming.edges],
                  };
                } else {
                  flexibleTestingTests = {
                    ...incoming,
                    edges: incoming.edges,
                  };
                }

                return flexibleTestingTests;
              },
            },
          },
        },
        Webhook: {
          fields: {
            isExpanded: {
              read: (existing = false) => existing,
            },
            secretKey: {
              read: (existing = "") => existing,
            },
          },
        },
      },
    });

    this.#logoutFunction = logout;
    this.#redirectTo = redirectTo;
    this.#apolloClient = new ApolloClient({
      cache,
      link: ApolloLink.split(
        this.#hasSubscriptionOperation,
        new ActionCableLink({
          cable: this.#getCable(),
          channelName: "CustomerPortal::GraphqlChannel",
          connectionParams: _operation => ({
            auth_token: getAuthenticationToken(),
          }),
        }),

        ApolloLink.from([
          // If `account-override-auth-token` cookie is set, send it in the header to GAT GraphQL endpoint.
          // It's used to override logged in user in gat-e2e tests. It's required because of the limit on monthly
          // active users in Auth0. With automatically generated users we can easily exceed the limit. To avoid that,
          // we use a single account to log in and gat-e2e passes an override access token that changes the user
          // for requests to GAT.
          new ApolloLink((operation, forward) => {
            const overrideToken = document.cookie
              .split("; ")
              .find(cookie => cookie.startsWith("account-override-auth-token"))
              ?.split("=")[1];
            if (overrideToken) {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  "x-account-override-auth-token": overrideToken,
                },
              }));
            }

            return forward(operation);
          }),
          onError(error => {
            this.#handleNotFoundError(error);
            this.#handleAuthorizationErrors(error);
          }),
          createUploadLink({
            fetch: customFetcher,
          }),
        ]),
      ),
    });
  };

  #hasSubscriptionOperation = ({ query: { definitions } }) =>
    definitions.some(({ kind, operation }) => kind === "OperationDefinition" && operation === "subscription");

  #getCable = () => createConsumer(websocketServerUrl);

  #handleNotFoundError = ({ graphQLErrors, operation }) => {
    // We want to do a redirect only in the case of a query
    if (!operation.query.definitions.some(this.#hasQueryOperation)) return;

    const errorsInGraphQL = graphQLErrors && graphQLErrors.some(error => error.extensions?.code === "NOT_FOUND");

    if (errorsInGraphQL) this.#redirectToDefaultPage({ message: "The requested resource was not found." });
  };

  #hasQueryOperation = definition => definition.kind === "OperationDefinition" && definition.operation === "query";

  #handleAuthorizationErrors = ({ graphQLErrors, networkError }) => {
    const errorsInGraphQL = graphQLErrors && graphQLErrors.some(error => error.extensions?.code === "FORBIDDEN");
    const hasUnauthorizedError = networkError && (networkError.statusCode === 401 || networkError.statusCode === 403);

    if (errorsInGraphQL) {
      captureMessage(graphQLErrors.map(error => error.message).join(", "), { level: "error" });

      this.#redirectToDefaultPage({ message: "You are not authorized to access the page." });
    }

    if (hasUnauthorizedError) {
      let pathParam = "";
      const errorCode = networkError.result.error_code;

      if (errorCode === "user_not_found") {
        pathParam = "?error=403";
      } else if (errorCode === "trial_expired") {
        pathParam = "?trial=expired";
      }

      this.#logoutFunction({
        returnTo: `${window.location.origin}${pathParam}`,
      });
    }

    if (networkError && networkError.statusCode === 500) {
      notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
        type: "danger",
        message: "Something went wrong, please try again.",
      });
    }
  };

  #redirectToDefaultPage = ({ message }) => {
    const currentUser = this.apolloClient.readQuery({ query: GET_CURRENT_ORGANIZATION_USER }).currentOrganizationUser;

    if (currentUser.communityTestManager) this.#redirectTo(websiteContentPaths.flexcaseResults);
    else this.#redirectTo(websiteContentPaths.overview);

    notificationEventBus.dispatch(DISPLAY_NOTIFICATION, { type: "danger", message });
  };
}

export default new ApolloClientProvider();
