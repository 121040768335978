import React from "react";
import { Form as FormikForm, Formik, FormikHelpers } from "formik";
import { removePrefixFromGraphqlErrorMessage } from "tools/remove_prefix_from_graphql_error_message";

type ExtractedErrors = {
  [key: string]: string[] | string;
};

type FormProps<T extends { [key: string]: unknown }> = {
  initialValues: T;
  onSubmit: (values: T, form: FormikHelpers<T>) => Promise<unknown>;
  children: React.ReactNode;
  onReset?: () => void;
  validations?: unknown;
  enableReinitialize?: boolean;
  id?: string;
  className?: string;
};

function extractErrors(errors: string) {
  let extractedErrors: ExtractedErrors;

  try {
    extractedErrors = JSON.parse(removePrefixFromGraphqlErrorMessage(errors));
  } catch (e) {
    extractedErrors = { generalError: [removePrefixFromGraphqlErrorMessage(errors)] };
  }

  // Base error parsing taken from frontend-common-modules
  const generalErrors = Object.keys(extractedErrors)
    .filter(key => !!key.match(/base$/))
    .map(key => {
      const errorScope = key.split(".")[0]?.split("_").join(" ");
      const label = errorScope === "base" ? "" : errorScope + ": ";

      return `${label}${(extractedErrors[key] as string[])?.join(", ")}`;
    });
  if (generalErrors.length > 0) {
    extractedErrors.generalError = generalErrors;
  }

  return extractedErrors;
}

function Form<T extends Record<string, unknown>>({
  initialValues,
  onSubmit,
  onReset,
  validations,
  children,
  className,
  enableReinitialize = false,
  ...props
}: FormProps<T>): JSX.Element {
  return (
    <Formik<T>
      initialValues={initialValues}
      onSubmit={(values, form) =>
        onSubmit(values, form).catch(err => {
          Object.entries(extractErrors(err.message)).forEach(([field, errors]) => {
            form.setFieldError(field, errors as string);
          });
        })
      }
      onReset={onReset}
      validationSchema={validations}
      enableReinitialize={enableReinitialize}
    >
      <FormikForm {...props} className={className} data-test-role="form">
        {children}
      </FormikForm>
    </Formik>
  );
}

export default Form;
