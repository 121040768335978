import React, { useEffect, useState, ClipboardEvent } from "react";
import { FormikErrors, useFormikContext } from "formik";
import { Spinner } from "@hopper/loading";
import Truncate from "react-truncate";

import { TextArea, TextInput } from "components/flexible_testing/shared/form";
import { TesterGroupParams } from "components/flexible_testing/test_manager/preview_page/supervised_test_preview_page";
import { TesterGroupData } from "components/flexible_testing/test_manager/test_request_page/supervised_test_content/tester_group_list/tester_group_list";
import EnvironmentSelectionSection from "components/flexible_testing/test_manager/test_request_page/supervised_test_content/tester_group_list/environment_selection_section";
import { CollapsibleSection } from "components/flexible_testing/shared/collapsible_section";
import { Button } from "@hopper/button";
import AutomaticTesterSelectionSection from "./automatic_tester_selection_section";
import AutomaticSelectionSwitchSection from "./automatic_selection_switch_section";
import AutomaticSelectionSwitch from "./automatic_selection_switch";
import useMatchingTesters from "./use_matching_testers";
import { MultiSelect, Options } from "components/shared/form/multiselect/multiselect";
import { StatusBar } from "@hopper/status_bar";
import { useFeatureFlag } from "app/feature_flags";

type TesterGroupProps = {
  fieldName: string;
  testerGroup: TesterGroupData;
  errors: FormikErrors<TesterGroupParams>;
  onDuplicateGroupClick: (event: React.MouseEvent, testerGroup: TesterGroupData) => void;
  onRemoveGroupClick: () => void;
  index: number;
  possibleTags: string[];
};

export type TestRequestParams = {
  testEnvironments: Array<"android" | "ios" | "web">;
  testerGroups: TesterGroupParams[];
};

const splitStringToEmails = (str: string) => str?.split(",")?.filter(e => e.trim().length > 0);

export const TesterGroup = ({
  fieldName,
  testerGroup,
  errors,
  onDuplicateGroupClick,
  onRemoveGroupClick,
  index,
  possibleTags,
}: TesterGroupProps) => {
  const defaultName = `Group ${index + 1}`;
  const [initialName, setInitialName] = useState(defaultName);
  const { setFieldValue } = useFormikContext<TestRequestParams>();
  const {
    loading: testerMatchingLoading,
    testers,
    totalNumber,
    excludeTester,
  } = useMatchingTesters(fieldName, testerGroup);

  const testCaseTagsEnabled = useFeatureFlag("test-case-tags", false);

  useEffect(() => {
    if (testerGroup.name === initialName) {
      setFieldValue(`${fieldName}.name`, defaultName);
      setInitialName(defaultName);
    }
  }, [index]);

  const processPastedEmails = (event: ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const textarea = event.currentTarget;
    const content = event.clipboardData.getData("text/plain");
    const text = content.split("\n").join(", ");

    const [originalStart, originalEnd] = [
      textarea.value.slice(0, textarea.selectionStart),
      textarea.value.slice(textarea.selectionEnd),
    ];

    setFieldValue(textarea.name, originalStart + text + originalEnd);
  };

  const selectedTesterEmails = splitStringToEmails(testerGroup?.selectedTesterEmails);
  const backupTesterEmails = splitStringToEmails(testerGroup?.backupTesterEmails);

  const tagsOptions: Options = possibleTags.map(tag => ({
    value: tag,
    label: tag,
    isSelected: testerGroup.tags.includes(tag),
  }));

  return (
    <div className="mb-md mt-xs" data-testid={`requirements-group-${index}`}>
      <CollapsibleSection
        initiallyOpen={true}
        chevronPosition="left"
        title={
          <div className="flex justify-between w-full ml-lg">
            <span data-testid="group-name" className="text-body text-lg font-bold inline-flex items-center break-words">
              <Truncate ellipsis="..." lines={1} width={300}>
                {testerGroup.name}
              </Truncate>
              {testerMatchingLoading && <Spinner size={40} />}
            </span>
            <div>
              <button
                onClick={event => onDuplicateGroupClick(event, testerGroup)}
                className="cursor-pointer text-lg m-2"
                data-testid="duplicate-tester-group-icon"
                type="button"
              >
                <i className="fa fa-clone" />
              </button>
              <button
                onClick={onRemoveGroupClick}
                className="cursor-pointer text-danger text-lg m-2"
                data-testid="remove-tester-group-icon"
                type="button"
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          </div>
        }
      >
        <>
          <div className="mb-xl font-bold">
            <AutomaticSelectionSwitch fieldName={fieldName} testerGroup={testerGroup} />
          </div>
          <div className="mb-xl">
            <TextInput
              name="modifiedGroupName"
              placeholder={defaultName}
              label="Group name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`${fieldName}.name`, e.target.value.length ? e.target.value : defaultName);
              }}
            />
            {errors?.name && <div className="text-danger text-xs">{errors?.name}</div>}
          </div>

          <div data-test-role="form-group" className="text-xs mb-xl">
            <TextInput
              name={`${fieldName}.numberOfResultsNeeded`}
              label="Number of results needed"
              required={true}
              type="number"
              min="1"
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const number = Number(value);
                const newValue = value.length < 1 || isNaN(number) ? value : number > 0 ? number : 1;

                setFieldValue(`${fieldName}.numberOfResultsNeeded`, newValue);
              }}
            />
          </div>

          {testCaseTagsEnabled && (
            <div data-test-role="form-group" className="text-xs mb-xl">
              <div className="ml-0">
                <div className="relative border border-light my-xl p-lg leading-150">
                  <StatusBar color="primary" position="left" size="sm" />
                  This group will complete any test case that has ALL of the tags selected below
                </div>
              </div>
              <MultiSelect
                label="Test case tags"
                errorMessage={errors.tags as string}
                options={tagsOptions}
                onChange={selectedValues => {
                  setFieldValue(`${fieldName}.tags`, selectedValues);
                }}
                placeholder="Any"
              />
            </div>
          )}

          <EnvironmentSelectionSection fieldName={fieldName} testerGroup={testerGroup} errors={errors} />

          <div data-test-role="form-group" className="text-xs mb-xl">
            <TextArea
              name={`${fieldName}.other`}
              label="Other"
              placeholder="Enter any additional requirements for testers "
            />
          </div>

          <AutomaticSelectionSwitchSection fieldName={fieldName} testerGroup={testerGroup} errors={errors} />

          {(testerGroup.automaticTesterSelectionEnabled || testerGroup.automaticTesterSelectionNotEnoughTesters) && (
            <AutomaticTesterSelectionSection
              selectedTestersLimit={testerGroup.numberOfResultsNeeded}
              testers={testers}
              excludeTester={excludeTester}
              totalNumber={totalNumber}
            />
          )}

          {!testerGroup.automaticTesterSelectionEnabled && (
            <>
              <div data-test-role="form-group" className="text-xs mb-xl">
                <TextArea
                  name={`${fieldName}.selectedTesterEmails`}
                  label="Testers to invite at launch"
                  useHopperInput={true}
                  required={false}
                  onPaste={processPastedEmails}
                  placeholder="Add tester(s) email"
                />

                <span>Number of testers added: {selectedTesterEmails?.length || 0}</span>
              </div>
              <div data-test-role="form-group" className="text-xs mb-xl">
                <TextArea
                  name={`${fieldName}.backupTesterEmails`}
                  label="Backup testers"
                  useHopperInput={true}
                  required={false}
                  onPaste={processPastedEmails}
                  placeholder="Add tester(s) email"
                />

                <span>Number of testers added: {backupTesterEmails?.length || 0}</span>
              </div>
            </>
          )}

          <Button variant="secondary" appearance="ghost" onClick={event => onDuplicateGroupClick(event, testerGroup)}>
            Duplicate group
          </Button>
        </>
      </CollapsibleSection>
    </div>
  );
};
