import React from "react";
import * as yup from "yup";

import { Scalars } from "types/graphql";
import { Form, TextInput, SubmitButton } from "components/flexible_testing/shared/form";
import { ScheduleTestSection } from "../../shared/schedule_test_section";
import useCreateCustomerRequest from "./use_create_customer_request";
import { useCustomerRequestContext } from "../customer_request_context";

const initialValues = { name: "", isTestScheduled: false };

const validations = yup.object({
  name: yup.string().required("A test run name is required").max(100, "Cannot be longer than 100 characters"),
});

export type CustomerRequest = {
  name: string;
  scheduleAt?: Scalars["ISO8601DateTime"];
};

export default function CustomerRequestForm(): JSX.Element {
  const { createCustomerRequest, isLoading } = useCreateCustomerRequest();
  const { tests } = useCustomerRequestContext();

  const handleSubmit = (values: CustomerRequest): Promise<void> =>
    createCustomerRequest(values.name, tests, values.scheduleAt);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <Form<CustomerRequest> initialValues={initialValues} validations={validations} onSubmit={handleSubmit}>
        <div data-test-role="form-group" className="mb-xl">
          <TextInput
            name="name"
            placeholder="e.g., ACME iOS app release test #123"
            label="Test request name"
            required={true}
            labelClass="font-normal"
          />
        </div>

        <ScheduleTestSection title="When should testing begin?" titleClass="font-normal" />

        <SubmitButton
          className="mt-3xl mr-xl"
          loading={isLoading}
          useHopperButton={true}
          style={{ pointerEvents: "auto" }}
        >
          Launch test request
        </SubmitButton>
      </Form>
    </div>
  );
}
