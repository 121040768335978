import React, { useRef, useState } from "react";
import classnames from "classnames";

import Popup from "components/shared/popup";
import { RejectionReasons } from "components/flexible_testing/shared/rejection_reasons";
import { useOutsideHandler } from "components/shared/hooks/use_outside_handler";

type AcceptedStatusProps = {
  isRejectPopupOpen: boolean;
  setIsRejectPopupOpen: (value: boolean) => void;
  rejectIssue: (rejectionReason: string) => void;
};

const AcceptedStatus = ({ isRejectPopupOpen, setIsRejectPopupOpen, rejectIssue }: AcceptedStatusProps) => {
  const labelClassNames = classnames(
    "bg-info py-xs px-sm rounded-base cursor-pointer",
    "text-lightest leading-lg font-bold text-xs uppercase tracking-wider",
  );
  const [displayRejectionReasons, setDisplayRejectionReasons] = useState(false);
  const wrapperRef = useRef(null);
  const handleOutsideClick = () => {
    setIsRejectPopupOpen(false);
    setDisplayRejectionReasons(false);
  };

  useOutsideHandler(wrapperRef, handleOutsideClick);

  const rejectOption = () => (
    <div className="hover:bg-light my-sm p-xs pl-xl cursor-pointer" onClick={() => setDisplayRejectionReasons(true)}>
      Reject
    </div>
  );

  const issueRejectElement = () => (
    <div
      className="mt-sm border border-muted shadow rounded-base bg-lightest z-10"
      style={{ width: "170px" }}
      data-testid="options-box"
    >
      {displayRejectionReasons ? <RejectionReasons rejectIssue={rejectIssue} /> : rejectOption()}
    </div>
  );

  return (
    <div ref={wrapperRef}>
      <Popup popupElement={issueRejectElement()} isOpen={isRejectPopupOpen}>
        <div
          className={labelClassNames}
          data-testid="accepted-status"
          onClick={() => {
            setIsRejectPopupOpen(!isRejectPopupOpen);
          }}
        >
          Accepted
        </div>
      </Popup>
    </div>
  );
};

export default AcceptedStatus;
