import React from "react";

import { RequestedTest } from "components/flexible_testing/test_request_page/requested_test_page/requested_test_form";
import { getApplicationTypeLabel } from "components/flexible_testing/test_request_page/requested_test_page/helpers";
import { StepSummaryHeading } from "./step_summary_heading";

type ApplicationStepSummaryProps = {
  applicationType: RequestedTest["applicationType"];
  accessToApplicationUrl: string;
  requiresAccessToSecureEnvironment: string;
};

export const ApplicationStepSummary = ({
  applicationType,
  accessToApplicationUrl,
  requiresAccessToSecureEnvironment,
}: ApplicationStepSummaryProps) => {
  const applicationTypeLabel = getApplicationTypeLabel(applicationType);

  return (
    <div data-testid="application-step-summary">
      <StepSummaryHeading heading="Application" subheading={applicationTypeLabel} />
      <a target="_blank" href={accessToApplicationUrl} className="text-sm text-link">
        {accessToApplicationUrl}
      </a>
      <div className="text-sm text-secondary">
        {requiresAccessToSecureEnvironment === "true" ? (
          <>Testers need access to a secure environment</>
        ) : (
          <>Testers don't need access to a secure environment</>
        )}
      </div>
    </div>
  );
};
