import { gql, useMutation } from "@apollo/client";

import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";
import { RemoveAssignedCommunityTestManagerInput, RemoveAssignedCommunityTestManagerPayload } from "types/graphql";

export const REMOVE_ASSIGNED_COMMUNITY_TEST_MANAGER = gql`
  mutation ($input: RemoveAssignedCommunityTestManagerInput!) {
    removeAssignedCommunityTestManager(input: $input) {
      successful
      result {
        id
        availableCommunityTestManagers {
          edges {
            node {
              id
              fullName
            }
          }
        }
        assignedCommunityTestManagers {
          edges {
            node {
              id
              fullName
              status
            }
          }
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "The moderator has been successfully removed from the test.",
  });
};

const displayError = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: "There was an issue with removing the moderator from the test.",
  });
};

export const useRemoveAssignedCommunityTestManager = () => {
  const [removeAssignedCommunityTestManager, { loading }] = useMutation<{
    removeAssignedCommunityTestManager: RemoveAssignedCommunityTestManagerPayload;
  }>(REMOVE_ASSIGNED_COMMUNITY_TEST_MANAGER, {
    onCompleted: ({ removeAssignedCommunityTestManager: { successful } }) =>
      successful ? displaySuccess() : displayError(),
    onError: displayError,
  });

  return {
    removeAssignedCommunityTestManager: async ({
      assignedCommunityTestManagerId,
    }: RemoveAssignedCommunityTestManagerInput): Promise<void> => {
      await removeAssignedCommunityTestManager({
        variables: {
          input: {
            assignedCommunityTestManagerId,
          },
        },
      });
    },
    loading,
  };
};
