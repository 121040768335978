import React, { useEffect, useState } from "react";
import { Panel } from "@hopper/panel";
import { Heading } from "@hopper/heading";

import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import ParticipantsForm from "./participants_form";
import RequirementsGroupForm from "components/flexible_testing/result_details_page/testers/edit_requirements_group_panel/requirements_group_form";
import { Tab } from "@hopper/tab";
import { TabBar } from "@hopper/tab_bar";
import queryString from "query-string";
import { websiteContentPaths } from "components/configuration";
import { useHistory } from "react-router-dom";
import BackupTestersList from "./backup_testers_list";

type EditRequirementsGroupPanelProps = {
  testId: string;
  selectionRequirementsGroup?: SelectionRequirementsGroup;
  close: () => void;
  isOpen: boolean;
  automaticTesterManagementEnabled: boolean;
  canModifyNumberOfResultsNeeded: boolean;
};

export const Tabs = {
  ADD_TESTERS: "addTesters",
  EDIT_GROUP_REQUIREMENTS: "editGroupRequirements",
} as const;

const EditRequirementsGroupPanel = ({
  testId,
  selectionRequirementsGroup,
  close,
  isOpen,
  automaticTesterManagementEnabled,
  canModifyNumberOfResultsNeeded,
}: EditRequirementsGroupPanelProps) => {
  const [selectedOperatingSystemVersionIds, setSelectedOperatingSystemVersionIds] = useState<Array<string>>([]);
  const history = useHistory();

  const onTabClicked = (tabName: string) => {
    history.push(`${websiteContentPaths.flexcaseResult.replace(":id", testId)}?tab=testers&testSettingsTab=${tabName}`);
  };

  const testSettingsTab = queryString.parse(history.location.search).testSettingsTab || Tabs.ADD_TESTERS;

  useEffect(() => {
    setSelectedOperatingSystemVersionIds(selectionRequirementsGroup?.operatingSystemVersions.map(({ id }) => id) || []);
  }, [selectionRequirementsGroup]);

  if (!selectionRequirementsGroup) return null;

  return (
    <Panel isOpen={isOpen} hasOverlay={true} onOverlayClick={close} onCloseButtonClick={close} maxWidth="52rem">
      <div
        className="mt-0 ml-3xl mr-3xl overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']"
        style={{ height: "90vh" }}
      >
        <div className="mb-2xl">
          <Heading>Edit {selectionRequirementsGroup.name}</Heading>
        </div>

        <TabBar>
          <Tab active={testSettingsTab === Tabs.ADD_TESTERS} onClick={() => onTabClicked(Tabs.ADD_TESTERS)}>
            Add testers
          </Tab>
          <Tab
            active={testSettingsTab === Tabs.EDIT_GROUP_REQUIREMENTS}
            onClick={() => onTabClicked(Tabs.EDIT_GROUP_REQUIREMENTS)}
          >
            Edit test requirements
          </Tab>
        </TabBar>

        {testSettingsTab === Tabs.ADD_TESTERS && (
          <div className="mt-2xl" data-testid="add-testers-panel">
            <ParticipantsForm
              testId={testId}
              groupId={selectionRequirementsGroup.id}
              groupName={selectionRequirementsGroup.name}
              numberOfResultsNeeded={selectionRequirementsGroup.numberOfResultsNeeded}
              selectedOperatingSystemVersionIds={selectedOperatingSystemVersionIds}
            />
            <BackupTestersList
              testId={testId}
              selectionRequirementsGroup={selectionRequirementsGroup}
              automaticTesterManagementEnabled={automaticTesterManagementEnabled}
            />
          </div>
        )}
        {testSettingsTab === Tabs.EDIT_GROUP_REQUIREMENTS && (
          <div className="mt-2xl" data-testid="edit-group-requirements-panel">
            <RequirementsGroupForm
              testId={testId}
              groupId={selectionRequirementsGroup.id}
              groupName={selectionRequirementsGroup.name}
              numberOfResultsNeeded={selectionRequirementsGroup.numberOfResultsNeeded}
              platformsToTestOn={selectionRequirementsGroup.platforms}
              selectedOperatingSystemVersions={selectionRequirementsGroup.operatingSystemVersions}
              onOperatingSystemVersionIdsChange={setSelectedOperatingSystemVersionIds}
              selectedDeviceTypes={selectionRequirementsGroup.deviceTypes}
              selectedManufacturers={selectionRequirementsGroup.manufacturers}
              selectedMobileDevices={selectionRequirementsGroup.mobileDevices}
              selectedOperatingSystems={selectionRequirementsGroup.operatingSystems}
              selectedInternetBrowsers={selectionRequirementsGroup.internetBrowsers}
              selectedCountries={selectionRequirementsGroup.countries}
              selectedLanguages={selectionRequirementsGroup.languages}
              other={selectionRequirementsGroup.other}
              canModifyNumberOfResultsNeeded={canModifyNumberOfResultsNeeded}
              testCaseTags={selectionRequirementsGroup.tags}
            />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default EditRequirementsGroupPanel;
