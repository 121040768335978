import React from "react";
import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";
import { UseCaseCard } from "./use_case_card";
import developmentIcon from "./development_icon.svg";

const USE_CASE_REASONS = [
  "Confirm the specification and acceptance criteria are met",
  "Identify failing test cases",
  "Identify unexpected bugs",
  "Identify usability issues",
  "Confirm the feature matches the design",
];

type DevelopmentCardProps = {
  onButtonClick: (sdlcStageCode: SoftwareDevelopmentLifeCycleStageName) => void;
};

export function DevelopmentCard({ onButtonClick }: DevelopmentCardProps): JSX.Element {
  return (
    <UseCaseCard
      icon={developmentIcon}
      title="Development"
      stageCode={"development" as SoftwareDevelopmentLifeCycleStageName}
      description="You are making changes to your app and you want to test them as you go"
      useCaseReasons={USE_CASE_REASONS}
      onButtonClick={onButtonClick}
    />
  );
}
