import React from "react";
import { Loader } from "@hopper/loading";

import { AdvancedTestDescription } from "./advanced_test_description";
import StandardTestDescription from "./standard_test_description";
import { AdvancedTestRequest, FlexibleTestingTest, Test } from "types/graphql";

type TestDetailsProps = {
  platformTest?: Test;
  testWindow?: Test["testWindow"];
  isLoading: boolean;
};

const TestDetails = ({ platformTest, testWindow, isLoading }: TestDetailsProps) => {
  if (isLoading) {
    return <Loader />;
  }

  if (platformTest?.type === "Standard Test" || platformTest?.type === "Supervised Test")
    return (
      <StandardTestDescription flexibleTestingTest={platformTest as FlexibleTestingTest} testWindow={testWindow} />
    );
  else if (platformTest?.type === "Advanced Test")
    return (
      <AdvancedTestDescription advancedTestRequest={platformTest as AdvancedTestRequest} testWindow={testWindow} />
    );
  else return null;
};

export default TestDetails;
