import React from "react";

import { ProgressBar } from "./progress_bar";

type TesterProgressBarProps = {
  total: number;
  completed: number;
  isPublished?: boolean;
};

function TesterProgressBar({ total, completed, isPublished }: TesterProgressBarProps): JSX.Element {
  const completion = Math.min(~~((completed * 100) / total), 100);
  const isCompleted = completed && isFinite(completion) && completion === 100;
  const barColor = total && isPublished ? "success" : isCompleted ? "primary" : "dark";

  return <ProgressBar total={total} completed={completed} color={barColor} />;
}

export default TesterProgressBar;
