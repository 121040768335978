import React from "react";
import classNames from "classnames";

type TableProps = {
  children: React.ReactNode;
  noBorder?: boolean;
  borderColor?: string;
} & React.TableHTMLAttributes<HTMLTableElement>;

function Table({ children, noBorder, borderColor = "light", className, ...otherProps }: TableProps) {
  const classes = classNames({ [`border-b border-${borderColor}`]: !noBorder }, "w-full", className);

  return (
    <table className={classes} {...otherProps}>
      {children}
    </table>
  );
}

export default Table;
