import React, { useEffect, useState } from "react";
import { Switch } from "@hopper/switch";
import { useField, useFormikContext } from "formik";
import useMatchingTesters from "./use_matching_testers";
import { TesterGroupData } from "./tester_group_list";

type AutomaticSelectionSwitchProps = {
  fieldName: string;
  testerGroup: TesterGroupData;
};

const AutomaticSelectionSwitch = ({ fieldName, testerGroup }: AutomaticSelectionSwitchProps) => {
  const [disabled, setDisabled] = useState(false);
  const { initiated, filtersChanged, notEnoughTesters } = useMatchingTesters(fieldName, testerGroup);

  const { setFieldValue } = useFormikContext();
  const [_enabledField, { value: enabledValue }, { setValue: setEnabledValue }] = useField({
    name: `${fieldName}.automaticTesterSelectionEnabled`,
  });

  const onToggle = (enabled: boolean) => {
    if (enabled) {
      setFieldValue(`${fieldName}.automaticTesterSelectionDisabledReason`, "");
    }

    setFieldValue(`${fieldName}.selectedTesterEmails`, "");
    setFieldValue(`${fieldName}.backupTesterEmails`, "");
    setEnabledValue(!enabled);
  };

  useEffect(() => {
    setDisabled(initiated && !filtersChanged && notEnoughTesters);
  }, [initiated, notEnoughTesters, filtersChanged]);

  return (
    <div className="font-bold" data-testid={`automatic-selection-switch-${testerGroup.name}`}>
      <Switch
        isOn={enabledValue}
        onLabel="Automatic tester selection"
        offLabel="Manual tester selection"
        onChange={() => onToggle(enabledValue)}
        disabled={disabled}
      />
    </div>
  );
};

export default AutomaticSelectionSwitch;
