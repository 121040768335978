import React from "react";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "../../../../app/notificationsEventBus";
import Toasts from "./toasts/toasts";

class Notifications extends React.PureComponent {
  state = {
    alerts: [],
  };

  componentDidMount() {
    notificationEventBus.on(DISPLAY_NOTIFICATION, this.handleNotificationReceived);
  }

  componentWillUnmount() {
    notificationEventBus.off(DISPLAY_NOTIFICATION, this.handleNotificationReceived);
  }

  handleNotificationReceived = notification => {
    const alerts = [notification, ...this.state.alerts];

    this.setState({ alerts });
  };

  render() {
    return <Toasts notifications={this.state.alerts} onClearToasts={this.clearAlerts} />;
  }

  clearAlerts = () => this.setState({ alerts: [] });
}

export default Notifications;
