import React from "react";
import pluralize from "pluralize";
import { Loader } from "@hopper/loading";

import { TestCasesSection } from "./test_cases_section";
import IssuesSection from "components/flexible_testing/result_details_page/summary/issues_section";
import { Test } from "types/graphql";

type SummaryProps = {
  platformTest?: Test;
  isLoading: boolean;
  onAllIssuesClick: () => void;
  onAllResultsClicked: () => void;
};

function Summary({ platformTest, isLoading, onAllResultsClicked, onAllIssuesClick }: SummaryProps): JSX.Element {
  if (isLoading) {
    return <Loader />;
  }

  const testCasesTotalCount = platformTest?.testStats?.testCasesTotalCount || 0;
  const participantsTotalCount = platformTest?.testStats?.participantsTotalCount;
  const testers = pluralize("tester", participantsTotalCount);
  const testersHave = pluralize("has", participantsTotalCount);

  return (
    <>
      <div>
        <div className="mt-xl" data-testid="test-participants-summary">
          <p>{`${participantsTotalCount} ${testers} ${testersHave} participated in this test.`}</p>
        </div>
        <div className="grid grid-cols-2">
          {testCasesTotalCount > 0 && (
            <TestCasesSection testStats={platformTest?.testStats} onAllResultsClicked={onAllResultsClicked} />
          )}
          <div>
            <IssuesSection test={platformTest} onAllIssuesClick={onAllIssuesClick} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Summary;
