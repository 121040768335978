import { gql, useMutation } from "@apollo/client";
import { SELECTION_REQUIREMENTS_GROUPS_QUERY } from "components/flexible_testing/shared/use_selection_requirements_groups";
import { ChangeBackupParticipantStatusPayload } from "types/graphql";

export const CHANGE_BACKUP_PARTICIPANT_STATUS = gql`
  mutation ($input: ChangeBackupParticipantStatusInput!) {
    changeBackupParticipantStatus(input: $input) {
      successful
      result {
        id
        status
      }
      errors {
        code
        message
      }
    }
  }
`;

export const useChangeBackupParticipantStatus = () => {
  const [changeBackupParticipantStatus, { loading }] = useMutation<{
    changeBackupParticipantStatus: ChangeBackupParticipantStatusPayload;
  }>(CHANGE_BACKUP_PARTICIPANT_STATUS);

  return {
    changeBackupParticipantStatus: async (status: "selected" | "removed", participantId: string, testId: string) => {
      const { data } = await changeBackupParticipantStatus({
        variables: { input: { testId, participantId, status } },
        refetchQueries: [{ query: SELECTION_REQUIREMENTS_GROUPS_QUERY, variables: { testId } }],
      });

      return data?.changeBackupParticipantStatus;
    },
    loading,
  };
};
