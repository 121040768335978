import { useMutation, gql } from "@apollo/client";

import { FlexibleTestingTestBlocker } from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

export const REQUEST_TEST_BLOCKER_ASSISTANCE_MUTATION = gql`
  mutation requestTestBlockerAssistance($input: RequestTestBlockerAssistanceInput!) {
    requestTestBlockerAssistance(input: $input) {
      result {
        id
        status
        name
        assistanceRequested
      }
      errors {
        message
        code
        path
      }
      successful
    }
  }
`;

export const useRequestTestBlockerAssistance = () => {
  const [requestTestBlockerAssistance, { loading }] = useMutation(REQUEST_TEST_BLOCKER_ASSISTANCE_MUTATION, {
    onCompleted: ({ requestTestBlockerAssistance: { successful, errors } }) => {
      if (!successful) {
        const errorMessage = errors.map(({ message }: { message: string }) => message).join(", ");

        displayError(errorMessage);
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = graphQLErrors.map(({ message }) => message).join(", ");

      displayError(errorMessage);
    },
  });

  return {
    requestTestBlockerAssistance: async (testBlockerId: FlexibleTestingTestBlocker["id"]) => {
      await requestTestBlockerAssistance({
        variables: { input: { testBlockerId } },
      });
    },
    isLoading: loading,
  };
};

function displayError(errorMessage: string) {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message: errorMessage || "Test blocker assistance request failed. Please try again.",
  });
}
