import { LoadingOverlay } from "@hopper/loading";
import { NewKey } from "components/api_settings/api_keys/new_key";
import { CliDownload } from "components/api_settings/api_keys/cli_download";
import { ExistingApiKeys } from "components/api_settings/api_keys/existing_api_keys";
import { Card } from "@hopper/card";
import React, { useState } from "react";

function ApiKeys(): JSX.Element {
  const [isPageReady, setIsPageReady] = useState(false);

  return (
    <Card>
      <LoadingOverlay isLoaded={isPageReady}>
        <div data-testid="api-page" className="py-3xl px-md md:px-3xl grid grid-cols-1 md:grid-cols-2">
          <section>
            <NewKey
              onLoadingStarted={() => {
                setIsPageReady(false);
              }}
              onLoadingFinished={() => {
                setIsPageReady(true);
              }}
            />
            <CliDownload />
          </section>
          <ExistingApiKeys
            onLoadingFinished={() => {
              setIsPageReady(true);
            }}
          />
        </div>
      </LoadingOverlay>
    </Card>
  );
}

export default ApiKeys;
