import { useEffect } from "react";

function drawEmptyFavicon() {
  const canvas = document.createElement("canvas");
  canvas.width = 1;
  canvas.height = 1;

  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  ctx.fillStyle = "#0000";
  ctx.fillRect(0, 0, 1, 1);

  return canvas.toDataURL();
}

function drawFaviconCounter(count: number) {
  const canvas = document.createElement("canvas");
  canvas.width = 16;
  canvas.height = 16;

  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;
  const radius = 8.2;

  ctx.beginPath();
  ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
  ctx.fillStyle = "#ff5630";
  ctx.fill();

  ctx.fillStyle = "white";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.font = "500 .70rem Helvetica, Arial";

  ctx.fillText(count.toString(), centerX - 0.5, centerY + 1);

  return canvas.toDataURL();
}
export function removeFavicon() {
  const linkFinder: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
  if (linkFinder) {
    linkFinder.href = drawEmptyFavicon();
  }
}
export function updateFavicon(count: number) {
  const iconNode: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
  const link: HTMLLinkElement = iconNode || document.createElement("link");
  if (!iconNode) {
    link.rel = "icon";
    document.getElementsByTagName("head")[0]?.appendChild(link);
  }

  link.href = count === 0 ? drawEmptyFavicon() : drawFaviconCounter(count);
}

function useFaviconCounter(count: number, dependencies: Array<unknown> = [], callback?: () => void): void {
  useEffect(() => {
    updateFavicon(count);

    return removeFavicon;
  }, [count, ...dependencies]);
}

export default useFaviconCounter;
