import React from "react";
import { BodyCell, Row } from "../../shared/table";
import { FlexibleTestingCaseResultRelatedIssueModerationStatus, FlexibleTestingTestCaseResult } from "types/graphql";
import TruncateLS from "../../result_details_page/truncatels";
import classnames from "classnames";
import { StatusIndicator, statuses } from "../../shared/status_indicator";
import { StatusLabel } from "@hopper/status_label";
import ModerationStatusIndicator from "components/flexible_testing/tester_overview/moderation_status";
import warningIcon from "assets/images/icon--warning.svg";
import { AssignedCaseWithOwnResult } from "components/flexible_testing/tester_overview/tester_overview";

type TestCaseRowProps = {
  testCase: AssignedCaseWithOwnResult;
  testerId?: string;
  onTestCaseResultRowClick: (testCaseResult: FlexibleTestingTestCaseResult) => void;
  selectedTestCaseResultId?: string;
};

const WarningIcon = () => (
  <img src={warningIcon} className="inline-block" alt="Warning icon" style={{ height: "24px", width: "24px" }} />
);

const TestCaseRow = ({
  testCase,
  testerId,
  onTestCaseResultRowClick,
  selectedTestCaseResultId,
}: TestCaseRowProps): JSX.Element => {
  const testerCaseResult = testCase.caseResult;
  const resultStatus = (testerCaseResult?.status as typeof statuses[number]) || "not_tested";
  const editRequest = testerCaseResult?.latestCaseResultEditRequest;
  const relatedIssues = testerCaseResult?.relatedIssues;
  const isApproved = testerCaseResult?.moderationStatus === "approved";
  const pendingIssues = relatedIssues?.filter(relatedIssue => relatedIssue.moderationStatus === "not_moderated") || [];

  const isAnyRelatedIssueWithStatus = (status: `${FlexibleTestingCaseResultRelatedIssueModerationStatus}`) =>
    relatedIssues?.some(relatedIssue => relatedIssue.moderationStatus === status);

  const relatedIssuesModerationStatus = () => {
    if (relatedIssues?.some(({ existsInSystem }) => !existsInSystem))
      return (
        <>
          <WarningIcon /> Fake issue
        </>
      );

    if (relatedIssues?.some(({ known }) => known))
      return (
        <>
          <WarningIcon /> Known issue
        </>
      );

    if (pendingIssues.length > 0) {
      return `${pendingIssues.length} pending`;
    } else if (isAnyRelatedIssueWithStatus("rejected")) {
      return "❌ Rejected";
    } else if (isAnyRelatedIssueWithStatus("accepted")) {
      return "✅ Accepted";
    } else {
      return "";
    }
  };

  return (
    <Row
      className={classnames({
        "cursor-pointer": !!testerCaseResult,
        "bg-muted": selectedTestCaseResultId && selectedTestCaseResultId === testerCaseResult?.id,
      })}
      onClick={() => testerCaseResult && onTestCaseResultRowClick(testerCaseResult)}
      testId="test-case-row"
    >
      <BodyCell data-testid="test-case-status">
        <StatusLabel variant="neutral">
          <StatusIndicator status={resultStatus} />
        </StatusLabel>
      </BodyCell>
      <BodyCell>{testCase.code}</BodyCell>
      <BodyCell className="font-bold">
        <p title={testCase?.name}>
          <TruncateLS ellipsis="..." lines={1} width={650}>
            {testCase?.name}
          </TruncateLS>
        </p>
      </BodyCell>
      <BodyCell className="text-secondary">{relatedIssuesModerationStatus()}</BodyCell>
      <BodyCell>
        <div className="flex flex-col justify-center items-start gap-y-xs">
          {isApproved ? (
            "✅ Approved"
          ) : (
            <>
              {editRequest && (
                <ModerationStatusIndicator editRequest={editRequest} caseResultId={testerCaseResult.id} />
              )}
              {isAnyRelatedIssueWithStatus("rejected" as FlexibleTestingCaseResultRelatedIssueModerationStatus) && (
                <StatusLabel>Rejected Issue</StatusLabel>
              )}
            </>
          )}
        </div>
      </BodyCell>
    </Row>
  );
};

export default TestCaseRow;
