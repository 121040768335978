import React from "react";
import { Body, BodyCell, Header, HeaderCell, Row, Table } from "components/flexible_testing/shared/table";
import { OrderNumber } from "components/flexible_testing/shared/order_number";
import { DecoratedText } from "@hopper/decorated_text";
import { TestCaseFields } from "components/flexible_testing/test_case_result_page/use_test_case_result";

type FieldsProps = {
  fields: TestCaseFields;
};

const FieldsList = ({ fields }: FieldsProps) => (
  <Table noBorder={true}>
    <Header>
      <HeaderCell>TEST CASE STEPS</HeaderCell>
    </Header>
    <Body>
      {fields.stepCaseFields?.map(field => (
        <Row noBorder={true} key={field.id} testId="instruction-result">
          <BodyCell className="grid grid-cols-12">
            <div className="col-span-1">
              <OrderNumber background="bg-light" fontColor="text-body">
                {field.ordinalNumber}
              </OrderNumber>
            </div>
            <div className="col-span-11" data-testid="instruction-title">
              <DecoratedText text={field.instruction || ""} />
            </div>
          </BodyCell>
        </Row>
      ))}
    </Body>
  </Table>
);

export default FieldsList;
