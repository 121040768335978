import React from "react";
import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";
import { useHistory } from "react-router-dom";

import { websiteContentPaths } from "components/configuration";
import { PageTemplate } from "components/flexible_testing/shared/page_template";
import CustomerRequestForm from "./customer_request_form";
import { useCustomerRequestContext } from "components/flexible_testing/test_request_page/customer_request_context";
import { RequestedTestCard } from "./requested_test_card";
import { MaxRequestedTestsInfo } from "./max_requested_tests_info";

export default function CustomerRequestContent() {
  const { tests } = useCustomerRequestContext();
  const history = useHistory();
  const hasNoTests = tests.length === 0;
  const maxRequestedTests = 10;
  const hasMaxTests = tests.length === maxRequestedTests;

  const handleAddTest = () => {
    history.push(websiteContentPaths.advancedTestRequestTest);
  };

  if (hasNoTests) {
    handleAddTest();

    return null;
  }

  return (
    <PageTemplate header={<Heading size={1}>Launch</Heading>}>
      {hasMaxTests && <MaxRequestedTestsInfo maxRequestedTests={maxRequestedTests} />}
      <div data-testid="customer-request-content">
        <div className="flex flex-col gap-4xl">
          {tests.length > 0 && (
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4xl">
              {tests.map((test, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <RequestedTestCard key={index} test={test} canDuplicate={!hasMaxTests} />
              ))}
            </div>
          )}
          <div>
            <Button variant="secondary" appearance="ghost" onClick={handleAddTest} type="button" disabled={hasMaxTests}>
              Add a new test from scratch
            </Button>
          </div>
        </div>
        <div className="w-5xl my-4xl">
          <hr />
        </div>
        <CustomerRequestForm />
      </div>
    </PageTemplate>
  );
}
