import React, { useState } from "react";

import { CaseResultPopup } from "components/flexible_testing/shared/case_result_popup/index";
import { useUpdateCaseResultContent } from "./use_update_case_result_content";
import { ButtonLink } from "components/flexible_testing/shared/button_link/index";
import { useTrackingEditOverallResult } from "components/flexible_testing/tester_overview/track_moderation_events/use_tracking_edit_overall_result";
import { useTrackingEditStepResponseResult } from "components/flexible_testing/tester_overview/track_moderation_events/use_tracking_edit_step_response_result";
import { Optional } from "types/utility";

type EditButtonProps = StepEditButtonProps | StringEditButtonProps;
type BaseEditButtonProps = {
  fieldName: string;
  fieldLabel: string;
  caseResultResponseId: string | undefined | null;
  initialValue: string;
  testCaseResultId: string;
  isEditButtonDisabled?: boolean;
  isOverallResult?: boolean;
  trackingCaseResultFieldResponseId?: string;
  trackingEventName?: string;
};
type StepEditButtonProps = { fieldType: "Status" | "Step" } & BaseEditButtonProps;
type StringEditButtonProps = { fieldType: "String" } & Optional<BaseEditButtonProps, "fieldName">;

export function EditButton({
  fieldType,
  fieldName,
  fieldLabel,
  caseResultResponseId,
  initialValue,
  testCaseResultId,
  isEditButtonDisabled,
  isOverallResult,
  trackingCaseResultFieldResponseId,
  trackingEventName,
}: EditButtonProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const trackEditStepResponseResult = useTrackingEditStepResponseResult();
  const trackEditOverallResult = useTrackingEditOverallResult();

  const onSuccess = (newContent: string) => {
    if (isOverallResult) {
      trackEditOverallResult(initialValue, newContent);
    } else if (trackingCaseResultFieldResponseId && trackingEventName) {
      trackEditStepResponseResult(initialValue, newContent, trackingCaseResultFieldResponseId, trackingEventName);
    }

    setIsPopupOpen(false);
  };

  const { flexibleTestingUpdateCaseResultResponse, isLoading } = useUpdateCaseResultContent(
    onSuccess,
    fieldName,
    fieldLabel,
    fieldType,
  );

  return caseResultResponseId ? (
    <CaseResultPopup
      isOpen={isPopupOpen}
      onCancelButtonClick={() => setIsPopupOpen(false)}
      onSaveButtonClick={content =>
        flexibleTestingUpdateCaseResultResponse(caseResultResponseId, testCaseResultId, content)
      }
      isButtonLoading={isLoading}
      label={fieldLabel}
      initialValue={initialValue}
    >
      <ButtonLink variant="primary" size="sm" onClick={() => setIsPopupOpen(true)} disabled={isEditButtonDisabled}>
        Edit
      </ButtonLink>
    </CaseResultPopup>
  ) : (
    <></>
  );
}
