import React, { useState } from "react";
import { Button } from "@hopper/button";
import pluralize from "pluralize";
import { Table, Body, BodyCell, Row } from "components/flexible_testing/shared/table";
import { SelectionRequirementsGroup } from "components/flexible_testing/shared/use_selection_requirements_groups";
import { FlagIcon } from "components/flexible_testing/shared/flag";
import { ConfirmParticipantInvitationModal } from "./confirm_participant_invitation_modal";
import { ConfirmParticipantRemovalModal } from "./confirm_participant_removal_modal";
import { TestParticipant } from "types/graphql";
import warningIcon from "assets/images/icon--warning.svg";
import classNames from "classnames";

type BackupTestersListProps = {
  testId: string;
  selectionRequirementsGroup: SelectionRequirementsGroup;
  automaticTesterManagementEnabled: boolean;
};

export type Participant = Pick<TestParticipant, "email" | "fullName" | "id">;

const BackupTestersList = ({
  testId,
  selectionRequirementsGroup,
  automaticTesterManagementEnabled,
}: BackupTestersListProps) => {
  const [showInvitationConfirmationModal, setShowInvitationConfirmationModal] = useState(false);
  const [showRemovalConfirmationModal, setShowRemovalConfirmationModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | undefined>();
  const backupParticipants = selectionRequirementsGroup.participants.filter(({ status }) => status === "backup");
  const participantsCount = backupParticipants.length;
  const isFull = automaticTesterManagementEnabled && selectionRequirementsGroup.isFull;

  return (
    <div className="mt-2xl">
      <div className="font-bold" data-testid="backup-testers-note">
        There {pluralize("is", participantsCount)} {participantsCount} backup {pluralize("testers", participantsCount)}
      </div>
      {isFull && (
        <div className="text-sm my-2xl">
          <img
            src={warningIcon}
            className="mr-xs inline-block"
            alt="Warning icon"
            style={{ height: "16px", width: "16px" }}
          />
          This group is currently full and no more testers can join. If you need more testers to join, disable the
          automatic tester management system on "Test details".
        </div>
      )}
      <Table className="table-fixed">
        <Body>
          {backupParticipants.map(participant => (
            <Row testId="participant-row" className="min-w-full break-words">
              <BodyCell style={{ width: "50%" }}>
                <div className="font-bold text-sm">{participant.fullName}</div>
                <div className="text-sm" data-testid="email">
                  {participant.email}
                </div>
              </BodyCell>
              <BodyCell style={{ width: "30%" }}>
                <div>
                  <FlagIcon className="mr-xs" code={participant.country.code?.toLowerCase()} />
                  <span className="text-xs">{participant.country.name}</span>
                </div>
              </BodyCell>
              <BodyCell style={{ width: "20%" }}>
                <Button
                  className={classNames("text-sm my-sm px-0 font-normal inline-block underline underline-offset-2", {
                    "line-through": isFull,
                    "text-secondary": isFull,
                  })}
                  variant="primary"
                  appearance="minimal"
                  onClick={() => {
                    setShowInvitationConfirmationModal(true);
                    setSelectedParticipant(participant);
                  }}
                  type="button"
                  disabled={isFull}
                >
                  Invite
                </Button>

                <Button
                  className="text-sm my-sm px-0 font-normal text-danger inline-block ml-sm underline underline-offset-2"
                  variant="secondary"
                  appearance="minimal"
                  onClick={() => {
                    setShowRemovalConfirmationModal(true);
                    setSelectedParticipant(participant);
                  }}
                  type="button"
                >
                  Remove
                </Button>
              </BodyCell>
            </Row>
          ))}
        </Body>
      </Table>
      <ConfirmParticipantInvitationModal
        onClose={() => {
          setShowInvitationConfirmationModal(false);
        }}
        isOpen={showInvitationConfirmationModal}
        selectedParticipant={selectedParticipant}
        testId={testId}
        setShowInvitationConfirmationModal={setShowInvitationConfirmationModal}
      />
      <ConfirmParticipantRemovalModal
        onClose={() => {
          setShowRemovalConfirmationModal(false);
        }}
        isOpen={showRemovalConfirmationModal}
        selectedParticipant={selectedParticipant}
        testId={testId}
        setShowRemovalConfirmationModal={setShowRemovalConfirmationModal}
      />
    </div>
  );
};

export default BackupTestersList;
