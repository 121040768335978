import { gql, useMutation } from "@apollo/client";
import { Maybe, ExportIssuesToCsvPayload } from "types/graphql";

export const CSV_MUTATION = gql`
  mutation ExportIssuesToCsv($input: ExportIssuesToCSVInput!) {
    exportIssuesToCsv(input: $input) {
      successful
      result
    }
  }
`;

type MutationData = {
  exportIssuesToCsv?: Maybe<ExportIssuesToCsvPayload>;
};
type DownloadCsvFileType = {
  (issueIds: Array<string>): Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCsvExport(refetchQuery: any): { downloadCsvFile: DownloadCsvFileType; isLoading: boolean } {
  const [performExportIssuesToCsv, { loading }] = useMutation<MutationData>(CSV_MUTATION);

  return {
    isLoading: loading,
    downloadCsvFile: async (issueIds: Array<string>) => {
      const result = await performExportIssuesToCsv({
        variables: { input: { issueIds } },
        refetchQueries: [refetchQuery],
      });
      const mutation_result = result.data?.exportIssuesToCsv?.result;
      if (mutation_result) {
        downloadCsv(mutation_result.body, mutation_result.filename);
      }
    },
  };
}

function downloadCsv(body: BlobPart, fileName: string) {
  const blob = new Blob([body], { type: "text/csv" });
  const reader = new FileReader();
  reader.onload = e => {
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = e?.target?.result as string;
    anchor.download = fileName;
    anchor.click();
  };
  reader.readAsDataURL(blob);
}
