import React, { Component } from "react";
import { compose } from "lodash/fp";
import DefaultLayoutHelmet from "../shared/default_layout_helmet";

import { Helmet } from "react-helmet";
import FlexibleTestingHeaderNavigation from "../flexible_testing_layout/components/header_navigation/index";
import { StickyContainer } from "react-sticky";

import styles from "./style.module.css";

import "assets/stylesheets/animations.css";
import "assets/stylesheets/vendor/sweetalert/sweetalert.css";
import "assets/stylesheets/vendor/sweetalert/sweetalert_custom.css";
import { Notifications } from "../shared/notifications";
import { withRouter } from "react-router";
import { withCurrentUser } from "app/current_user/with_current_user";

class OrganizationLayout extends Component {
  render() {
    const { currentUser, children } = this.props;

    return (
      <div id="organization">
        <StickyContainer>
          <DefaultLayoutHelmet />
          <Helmet title="Global App Testing" />
          <div id="wrapper">
            <div className="bg-lightest">
              <FlexibleTestingHeaderNavigation currentUser={currentUser} />

              <Notifications />

              <div className={`container-fluid ${styles.content}`}>{children}</div>
            </div>
          </div>
        </StickyContainer>
      </div>
    );
  }
}

export default compose(withRouter, withCurrentUser)(OrganizationLayout);
