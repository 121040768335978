import { ApolloError, gql, useLazyQuery } from "@apollo/client";

import { FlexibleTestingIssue, Maybe } from "types/graphql";

export const NEXT_ISSUE_QUERY = gql`
  query FlexibleTestingNextIssue($issueId: ID!, $triageStatus: FlexibleTestingIssueTriageStatus!) {
    flexibleTestingNextIssue(issueId: $issueId, triageStatus: $triageStatus) {
      id
    }
  }
`;

export type FlexibleTestingNextIssueResult = {
  flexibleTestingNextIssue: Maybe<FlexibleTestingIssue>;
};

export function useLazyNextIssue(
  onCompleted: (data: FlexibleTestingNextIssueResult) => void,
  onError: (error: ApolloError) => void,
) {
  const [loadNextIssue] = useLazyQuery(NEXT_ISSUE_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: data => onCompleted(data),
    onError: error => onError(error),
  });

  return {
    loadNextIssue,
  };
}
