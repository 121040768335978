import React from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

// @ts-ignore
import { ClearButton, Form, SubmitButton, TextArea, TextInput } from "components/flexible_testing/shared/form";
import { ScheduleTestSection } from "../../shared/schedule_test_section";
import { PageSection } from "components/flexible_testing/shared/page_section";
import { OptionalSection } from "./optional_section";
import { advancedTest, PreviewPageAdvancedTestRequest } from "../../preview_page/preview_page";
import { DesiredOutcomeCode, SoftwareDevelopmentLifeCycleStageName } from "types/graphql";
import { TestCases } from "./test_cases";
import { TestCasesFromTestrail } from "././test_cases_from_testrail";
import { useFeatureFlag } from "app/feature_flags";
import { TestCasesFileError } from "components/flexible_testing/shared/test_cases_file_error";
import { websiteContentPaths } from "components/configuration";

type AdvancedTestFormProps = {
  testRequest?: PreviewPageAdvancedTestRequest;
  capabilities?: Array<string>;
  sdlcStage?: SoftwareDevelopmentLifeCycleStageName;
  desiredOutcomes?: Array<DesiredOutcomeCode>;
  customDesiredOutcomes?: string;
  infoSection?: JSX.Element;
};

function AdvancedTestForm({
  testRequest,
  capabilities,
  sdlcStage,
  desiredOutcomes,
  customDesiredOutcomes,
  infoSection,
}: AdvancedTestFormProps): JSX.Element {
  const history = useHistory();
  const testCaseUploaderEnabled = useFeatureFlag("customer-test-case-uploader", false);
  const testRailsTestCaseImporterEnabled = useFeatureFlag("customer-test-rails-test-case-importer", false);

  const handleSubmit = (values: { [field: string]: unknown }): Promise<void> => {
    const {
      accessToApplication,
      testObjective,
      notes,
      name,
      platformsToTestOn,
      deviceRequirements,
      testLocalisation,
      isTestLocalised,
      testCasesUrl,
      isTestCasesIncluded,
      knownIssuesUrl,
      isKnownIssuesIncluded,
      scheduleAt,
      isTestScheduled,
      capabilities,
      sdlcStage,
      desiredOutcomes,
      customDesiredOutcomes,
      testCases,
    } = values;
    const testRequest = {
      accessToApplication,
      testObjective,
      notes,
      name,
      platformsToTestOn,
      deviceRequirements,
      testLocalisation,
      isTestLocalised,
      testCasesUrl,
      isTestCasesIncluded,
      knownIssuesUrl,
      isKnownIssuesIncluded,
      scheduleAt,
      isTestScheduled,
      capabilities,
      sdlcStage,
      desiredOutcomes,
      customDesiredOutcomes,
      testCases,
    };

    history.push(websiteContentPaths.testPreview, {
      ...testRequest,
      testType: advancedTest,
      capabilities,
    } as PreviewPageAdvancedTestRequest);

    return new Promise(resolve => resolve());
  };

  return (
    <div data-testid="advanced-test-content">
      {infoSection}
      <Form
        initialValues={{
          accessToApplication: testRequest?.accessToApplication || "",
          testObjective: testRequest?.testObjective || "",
          notes: testRequest?.notes || "",
          name: testRequest?.name || "",
          platformsToTestOn: testRequest?.platformsToTestOn || "",
          deviceRequirements: testRequest?.deviceRequirements || "",
          testLocalisation: testRequest?.testLocalisation || "",
          testCasesUrl: testRequest?.testCasesUrl || "",
          knownIssuesUrl: testRequest?.knownIssuesUrl || "",
          isTestLocalised: !!testRequest?.testLocalisation,
          isTestCasesIncluded: !!testRequest?.testCasesUrl,
          isKnownIssuesIncluded: !!testRequest?.knownIssuesUrl,
          isTestScheduled: testRequest?.isTestScheduled === true,
          capabilities: testRequest?.capabilities || capabilities,
          sdlcStage: testRequest?.sdlcStage || sdlcStage,
          desiredOutcomes: testRequest?.desiredOutcomes || desiredOutcomes,
          customDesiredOutcomes: testRequest?.customDesiredOutcomes || customDesiredOutcomes,
          testCases: [],
        }}
        validations={yup.object({
          accessToApplication: yup
            .string()
            .required("A link to application is required")
            .max(1000, "Cannot be longer than 1000 characters"),
          testObjective: yup
            .string()
            .required("A test run objective is required")
            .max(10000, "Cannot be longer than 10000 characters"),
          notes: yup.string().required("A notes is required").max(10000, "Cannot be longer than 10000 characters"),
          name: yup.string().required("A test run name is required").max(100, "Cannot be longer than 100 characters"),
          platformsToTestOn: yup
            .string()
            .required("A platforms to test on is required")
            .max(10000, "Cannot be longer than 10000 characters"),
          deviceRequirements: yup.string().max(10000, "Cannot be longer than 10000 characters"),
          testLocalisation: yup
            .string()
            .when("isTestLocalised", {
              is: true,
              then: yup.string().required("A localisation is required"),
            })
            .max(10000, "Cannot be longer than 10000 characters"),
          testCasesUrl: yup.string().when("isTestCasesIncluded", {
            is: true,
            then: yup
              .string()
              .url("Please enter a valid URL")
              .trim()
              .required("A link to your test cases sheet is required"),
          }),
          knownIssuesUrl: yup.string().when("isKnownIssuesIncluded", {
            is: true,
            then: yup
              .string()
              .url("Please enter a valid URL")
              .trim()
              .required("A link to your known issues is required"),
          }),
        })}
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <PageSection title="What would you like to test?" className="mb-2xl">
              <div data-test-role="form-group" className="text-xs mb-xl">
                <TextArea
                  name="accessToApplication"
                  placeholder="http://www.your-app.com"
                  label="Link to where testers can access your application or build"
                  required={true}
                  useHopperInput
                />
              </div>
              <div data-test-role="form-group" className="text-xs mb-xl">
                <TextArea
                  name="testObjective"
                  placeholder="What is the scope of this test? What would you like the testers to focus on?"
                  label="Test run objective"
                  required={true}
                  useHopperInput
                  autoSize={true}
                />
              </div>
              <div data-test-role="form-group" className="text-xs">
                <TextArea
                  name="notes"
                  placeholder="Is there any additional information you want to provide for this test run?"
                  label="Notes / Extra information"
                  required={true}
                  useHopperInput
                  autoSize={true}
                />
              </div>
            </PageSection>
            <PageSection title="Give your test run a name" className="mb-2xl">
              <div data-test-role="form-group" className="text-xs">
                <TextInput
                  name="name"
                  placeholder={`eg "Account sign-up flow"`}
                  label="Test run name"
                  required={true}
                  labelClass="font-normal"
                />
              </div>
            </PageSection>
            <ScheduleTestSection initScheduleAt={testRequest?.scheduleAt} />
            <SubmitButton className="mt-3xl mr-xl" useHopperButton={true} style={{ pointerEvents: "auto" }}>
              Preview and launch test
            </SubmitButton>
            <ClearButton>Clear All</ClearButton>
          </div>
          <div>
            <PageSection title="Which environments would you like to test on?" className="mb-2xl">
              <div data-test-role="form-group" className="text-xs mb-xl">
                <TextArea
                  name="platformsToTestOn"
                  placeholder="eg Android, iOS, and Web"
                  label="Platform(s) to test on"
                  required={true}
                  useHopperInput
                />
              </div>
              <div data-test-role="form-group" className="text-xs mb-xl">
                <TextArea
                  name="deviceRequirements"
                  placeholder="eg Samsung phones, iOS 15, and Chrome + Safari"
                  label="Specific device requirements"
                  required={false}
                  useHopperInput
                />
              </div>
            </PageSection>
            <OptionalSection
              title="Is this a localised test?"
              toggleFieldName="isTestLocalised"
              contentFieldName="testLocalisation"
              label="Which country/countries would you like to localise this test in?"
              tooltip="If you have localisation testing in your plan, use this input to tell us which country or countries you’d like to target for this test"
              placeholder="eg France and Spain"
              dataTestId="test-localisation"
            />
            {testRailsTestCaseImporterEnabled ? (
              <TestCasesFromTestrail />
            ) : testCaseUploaderEnabled ? (
              <>
                <TestCases />
                <TestCasesFileError />
              </>
            ) : (
              <OptionalSection
                title="Does this test run include test cases?"
                toggleFieldName="isTestCasesIncluded"
                contentFieldName="testCasesUrl"
                label="Link to test cases sheet"
                tooltip="Our testers will look for issues in your application through exploratory testing. You can also add test cases for them to execute."
                placeholder="https://www.your-test-cases.com"
                dataTestId="test-cases-url"
              />
            )}
            <OptionalSection
              title="Are there known issues?"
              toggleFieldName="isKnownIssuesIncluded"
              contentFieldName="knownIssuesUrl"
              label="Link to known issues"
              tooltip="If you have any known issues that you’d like us to be aware of, please link to them here (eg. a spreadsheet or URL to Jira)"
              placeholder="https://www.your-known-issues.com"
              dataTestId="known-issues-url"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default AdvancedTestForm;
