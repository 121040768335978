import React from "react";
import StatusFilter from "./status_filter";
import TagsFilter from "./tags_filter";
import { Button } from "@hopper/button";
import { FlexibleTestingIssue, FlexibleTestingIssueTag } from "types/graphql";

type IssueFiltersProps = {
  currentStatusFilter: string;
  setCurrentStatusFilter: (argument: string) => void;
  currentTags: Array<FlexibleTestingIssueTag>;
  updateSelectedTags: (tags: Array<FlexibleTestingIssueTag>, mode?: string) => void;
  tagsFilterMode: string;
  issues: Array<FlexibleTestingIssue>;
  newIssuesCount: number;
  acceptedIssuesCount: number;
  rejectedIssuesCount: number;
};

type totalIssuesCountByStatusMappingProps = {
  [type: string]: number;
};

function IssueFilters({
  currentStatusFilter,
  setCurrentStatusFilter,
  currentTags,
  updateSelectedTags,
  issues,
  tagsFilterMode,
  newIssuesCount,
  acceptedIssuesCount,
  rejectedIssuesCount,
}: IssueFiltersProps): JSX.Element {
  const totalIssuesCountByStatusMapping: totalIssuesCountByStatusMappingProps = {
    untriaged: newIssuesCount,
    accepted: acceptedIssuesCount,
    rejected: rejectedIssuesCount,
  };

  return (
    <div className="flex items-center">
      <StatusFilter
        currentStatusFilter={currentStatusFilter}
        setCurrentStatusFilter={setCurrentStatusFilter}
        newIssuesCount={newIssuesCount}
        acceptedIssuesCount={acceptedIssuesCount}
        rejectedIssuesCount={rejectedIssuesCount}
      ></StatusFilter>
      <Button className="ml-md text-sm" size="sm" variant="secondary" data-testid="filter-by-tag" appearance="ghost">
        <TagsFilter
          currentTags={currentTags}
          updateSelectedTags={updateSelectedTags}
          issues={issues}
          issuesCount={totalIssuesCountByStatusMapping[currentStatusFilter] || 0}
          tagsFilterMode={tagsFilterMode}
        />
      </Button>
    </div>
  );
}

export default IssueFilters;
