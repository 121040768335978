import React from "react";
import { SoftwareDevelopmentLifeCycleStageName } from "types/graphql";
import { LiveCard, ReleaseCard, DevelopmentCard, DesignCard } from "./use_case_cards";

type SdlcSelectionWizardStepProps = {
  onButtonClick: (sdlcStageCode: SoftwareDevelopmentLifeCycleStageName) => void;
};

export function SdlcSelectionWizardStep({ onButtonClick }: SdlcSelectionWizardStepProps): JSX.Element {
  return (
    <div className="grid grid-cols-12" data-testid="sdlc-selection-wizard-step">
      <div className="col-span-3">
        <div className="pr-xl h-full">
          <DesignCard onCardClick={onButtonClick} />
        </div>
      </div>
      <div className="col-span-3">
        <div className="pr-md pl-xs h-full">
          <DevelopmentCard onButtonClick={onButtonClick} />
        </div>
      </div>
      <div className="col-span-3">
        <div className="pl-md pr-xs h-full">
          <ReleaseCard onButtonClick={onButtonClick} />
        </div>
      </div>
      <div className="col-span-3">
        <div className="pr-sm pl-xl h-full">
          <LiveCard onButtonClick={onButtonClick} />
        </div>
      </div>
    </div>
  );
}
