import React from "react";
import { Modal } from "@hopper/legacy_modal";
import { Button } from "@hopper/button";
import { Heading } from "@hopper/heading";

type AttachmentRemovalConfirmationModalProps = {
  onClose: () => void;
  onRemove: () => void;
  isButtonLoading: boolean;
};

export function AttachmentRemovalConfirmationModal({
  onClose,
  onRemove,
  isButtonLoading,
}: AttachmentRemovalConfirmationModalProps) {
  const onConfirm = async () => {
    await onRemove();
    onClose();
  };

  return (
    <Modal
      isShowingCloseButton={true}
      onCloseButtonClicked={onClose}
      isOpen={true}
      position="center"
      size="md"
      onOverlayClicked={onClose}
    >
      <div className="flex flex-col max-w-xs sm:max-w-full ml-xl" data-testid="attachment-removal-confirmation">
        <Heading size={2}>Remove attachment?</Heading>
        <div>
          Are you sure you want to remove the attachment? If you remove the attachment, you will be able to revert
          changes back to original.
        </div>
        <div className="mt-lg">
          If you want the tester to add a new attachment, let them know by requesting a tester edit.
        </div>
        <div className="flex mt-4xl items-center justify-center">
          <Button variant="secondary" className="mr-xl" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={onConfirm} isLoading={isButtonLoading}>
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
}
