import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import BlockerChatPanel from "./blocker_chat_panel";
import { useChat } from "./use_chat";
import useChannel from "./use_channel";
import { useOpenChatFromQueryParams } from "./use_open_chat_from_query_params";
import { InlineButton } from "./inline_button";
import { ActionButton } from "./action_button";
import { ChatType } from "types/app";
import { Tester } from "types/graphql";
import { useChatContext } from "./chat_client_context";
import { Channel } from "stream-chat";

export type OpenPrivateTestBlockerChatButtonHandle = {
  openChatPanel: () => void;
};

export enum ButtonType {
  Inline = "inline",
  Action = "action",
}

type OpenPrivateTestBlockerChatButtonProps = {
  type: ChatType;
  chatTitle: string;
  testBlockerId: string;
  testCode: string;
  tester: Tester;
  variant: ButtonType;
};

export const OpenPrivateTestBlockerChatButton = forwardRef<
  OpenPrivateTestBlockerChatButtonHandle,
  OpenPrivateTestBlockerChatButtonProps
>(({ type, chatTitle, testBlockerId, testCode, tester, variant = ButtonType.Inline }, ref) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { isLoading: isClientLoading } = useChat();
  const { channels } = useChatContext();
  const [testBlockerChannel, setTestBlockerChannel] = useState<Channel>();

  useEffect(() => {
    const testBlockerWithChannel = channels.get(`test_blocker_private_tms_tester:${testBlockerId}`);

    if (testBlockerWithChannel) {
      setTestBlockerChannel(testBlockerWithChannel);
      setShouldInitializeChat(true);
    }
  }, [channels]);

  // We want to be careful with initializing private chat, because it can cause a lot of requests
  // to the server. We want to lazy initialize chat only when the user opens the chat panel or
  // the private channel already exists for a given participant.
  const [shouldInitializeChat, setShouldInitializeChat] = useState(false);

  const {
    unreadMessagesCount,
    channel,
    isLoading: isPrivateChatLoading,
  } = useChannel({
    targetId: testBlockerId,
    chatType: type,
    testerId: tester.id,
    providedChannel: testBlockerChannel,
    shouldInitialize: shouldInitializeChat,
  });
  const { shouldOpenChannel, messageToJumpIntoExternalId } = useOpenChatFromQueryParams(channel?.id);

  useEffect(() => {
    if (shouldOpenChannel) {
      setIsChatOpen(true);
    }
  }, [shouldOpenChannel]);

  useImperativeHandle(ref, () => ({
    openChatPanel() {
      onChatButtonClick();
    },
  }));

  const onChatButtonClick = () => {
    setIsChatOpen(!isChatOpen);
    setShouldInitializeChat(true);
  };

  return (
    <>
      {variant === ButtonType.Inline && (
        <InlineButton onChatButtonClick={onChatButtonClick} unreadMessagesCount={unreadMessagesCount} />
      )}

      {variant === ButtonType.Action && (
        <ActionButton onChatButtonClick={onChatButtonClick} unreadMessagesCount={unreadMessagesCount} />
      )}

      {isChatOpen && (
        <BlockerChatPanel
          isChatOpen={isChatOpen}
          chatTitle={chatTitle}
          chatSubtitle={testCode}
          userFullName={tester.fullName || ""}
          onChatClose={() => setIsChatOpen(false)}
          channel={channel}
          isLoading={isClientLoading || isPrivateChatLoading}
          messageToJumpIntoExternalId={messageToJumpIntoExternalId}
        />
      )}
    </>
  );
});
