import React from "react";
import warning from "./warning.svg";
import { Button } from "@hopper/button";
import { Card } from "@hopper/card";
import { useConfirmNoMassPassing } from "./use_confirm_no_mass_passing";

type MassPassingWarningProps = {
  hourlyCaseFieldCompletionRate: number;
  testId: string;
  participantId: string;
};

const MassPassingWarning = ({ testId, participantId, hourlyCaseFieldCompletionRate }: MassPassingWarningProps) => {
  const { flexibleTestingConfirmNoMassPassing, isLoading } = useConfirmNoMassPassing();

  const handleDismiss = () => {
    flexibleTestingConfirmNoMassPassing(testId, participantId);
  };

  return (
    <Card className="p-lg">
      <div className="font-bold text-md text-darkest">
        <img src={warning} width="17.5" height="17.5" className="inline mr-sm mb-xs" />
        Possible mass passing
      </div>
      <div className="font-normal text-md text-darkest mt-sm">
        The tester completed steps at a pace of <strong>{hourlyCaseFieldCompletionRate}</strong> per hour.
      </div>
      <div className="mt-lg">
        <Button variant="secondary" className="mr-lg" isLoading={isLoading} onClick={handleDismiss}>
          Dismiss
        </Button>
      </div>
    </Card>
  );
};

export default MassPassingWarning;
