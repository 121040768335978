import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
// @ts-ignore
import { AuthTokenProvider } from "../../components/auth_token_provider";
import { Loader } from "@hopper/loading";
import { websiteContentPaths } from "components/configuration";

import useSignUpWithTrial from "./use_sign_up_with_trial";

export const SignUp = () => {
  const [error, setError] = useState<string>();
  const { signUpWithTrial } = useSignUpWithTrial();
  const history = useHistory();
  const { isLoading, user, logout } = useAuth0();
  const emailTakenMessage = "Email has already been taken";

  useEffect(() => {
    if (!isLoading && user) {
      signUpWithTrial(user.email as string, user.given_name as string, user.family_name as string)
        .then(response => {
          if (response.error) {
            logout({
              returnTo: `${window.location.origin}${websiteContentPaths.signUpError}?errorCode=${response.code}`,
            });
          } else {
            history.push("/");
          }
        })
        .catch(error => {
          if (error && error?.error === emailTakenMessage) {
            history.push("/");
          } else if (error && error?.error) {
            setError(error.error);
          } else {
            console.error(error);
            setError("There was an error while handling your request. Please try again later or contact support.");
          }
        });
    }
  }, [isLoading]);

  return (
    <>
      {error ? (
        <p className="text-danger" data-testid="signup-error">
          {error}
        </p>
      ) : (
        <Loader />
      )}
    </>
  );
};

const SignUpWithToken = () => (
  <AuthTokenProvider>
    <SignUp />
  </AuthTokenProvider>
);

export default withAuthenticationRequired(SignUpWithToken, {
  loginOptions: { signUp: true, rootUrl: window.location.origin },
});
