import React from "react";
import { Button } from "@hopper/button";
import { CloneLink, CloneLinkProps } from "components/flexible_testing/shared/clone_link/clone_link";

const CloneTestButton = (props: Omit<CloneLinkProps, "children">): JSX.Element => (
  <div>
    <CloneLink {...props}>
      <Button variant="primary" size="sm" data-testid="clone-test-button">
        Clone this test run
      </Button>
    </CloneLink>
  </div>
);

export default CloneTestButton;
