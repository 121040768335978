import { gql, useMutation } from "@apollo/client";
import {
  FlexibleTestingCaseResultEditRequestCancelInput,
  FlexibleTestingCaseResultEditRequestCancelPayload,
} from "types/graphql";
import { DISPLAY_NOTIFICATION, notificationEventBus } from "app/notificationsEventBus";

const CANCEL_CASE_RESULT_EDIT_REQUEST_MUTATION = gql`
  mutation ($input: FlexibleTestingCaseResultEditRequestCancelInput!) {
    flexibleTestingCaseResultEditRequestCancel(input: $input) {
      successful
      result {
        id
        hasOpenCaseResultEditRequest
        canRemoveAttachment
        latestCaseResultEditRequest {
          status
          createdAt
        }
        caseResultEditLogs {
          description
          timestamp
        }
      }
    }
  }
`;

const displayError = (message: string) => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "danger",
    message,
  });
};

const displaySuccess = () => {
  notificationEventBus.dispatch(DISPLAY_NOTIFICATION, {
    type: "success",
    message: "Your edit request has been successfully cancelled.",
  });
};

type useCancelCaseResultEditRequestResult = {
  flexibleTestingCaseResultEditRequestCancel: (caseResultId: string) => Promise<void>;
  isLoading: boolean;
};

export const useCancelCaseResultEditRequest = (onSuccess: () => void): useCancelCaseResultEditRequestResult => {
  const [flexibleTestingCaseResultEditRequestCancelMutation, { loading }] = useMutation<
    { flexibleTestingCaseResultEditRequestCancel: FlexibleTestingCaseResultEditRequestCancelPayload },
    { input: FlexibleTestingCaseResultEditRequestCancelInput }
  >(CANCEL_CASE_RESULT_EDIT_REQUEST_MUTATION, {
    onCompleted: ({ flexibleTestingCaseResultEditRequestCancel }) => {
      if (flexibleTestingCaseResultEditRequestCancel?.successful) {
        displaySuccess();
        onSuccess();
      } else {
        const errorMessage = flexibleTestingCaseResultEditRequestCancel.errors.map(({ message }) => message).join(",");
        displayError(errorMessage);
      }
    },
    onError: () => displayError("There was an issue with cancelling your edit request. Please try again later."),
  });

  return {
    flexibleTestingCaseResultEditRequestCancel: async (caseResultId: string): Promise<void> => {
      await flexibleTestingCaseResultEditRequestCancelMutation({ variables: { input: { caseResultId } } });
    },
    isLoading: loading,
  };
};
