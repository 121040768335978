import React, { useEffect } from "react";
import { subDays, startOfDay } from "date-fns";
import { TestStatus } from "types/graphql";
import { useLocation } from "react-router-dom";

import { CollapsibleSection } from "../shared/collapsible_section";
import { HeadingNumber } from "components/flexible_testing/shared/heading_number";
import { useCurrentUser } from "app/current_user/use_current_user";
import { useLazyTestList, useTestList } from "./use_test_list";
import Authorization from "app/authorization/authorization";

import ResultsSection from "./results_section";

type Sections = {
  recentTests: boolean;
  upcomingTests: boolean;
  inProgressTests: boolean;
  pastTests: boolean;
  cancelledTests: boolean;
};

const ResultSectionsList = (): JSX.Element => {
  const date = subDays(startOfDay(new Date()), 7);
  const {
    tests: inProgressTests,
    loading: inProgressTestsLoading,
    totalCount: inProgressTestsTotalCount,
    hasNextPage: hasInProgressNextPage,
    loadNextPage: loadInProgressNextPage,
    isLoadingNextPage: isLoadingInProgressNextPage,
  } = useTestList({
    status: "in_progress" as TestStatus.InProgress,
  });
  const {
    tests: upcomingTests,
    loading: upcomingTestsLoading,
    totalCount: upcomingTestsTotalCount,
    hasNextPage: hasUpcomingNextPage,
    loadNextPage: loadUpcomingNextPage,
    isLoadingNextPage: isLoadingUpcomingNextPage,
  } = useTestList({
    status: "upcoming" as TestStatus.Upcoming,
  });
  const {
    tests: recentTests,
    loading: recentTestsLoading,
    totalCount: recentTestsTotalCount,
    hasNextPage: hasRecentNextPage,
    loadNextPage: loadRecentNextPage,
    isLoadingNextPage: isLoadingRecentNextPage,
  } = useTestList({ closedAtGt: date });
  const {
    tests: pastTests,
    loading: isLoadingPastTests,
    totalCount: pastTestsTotalCount,
    loadTests: loadPastTests,
    testsLoadingCalled: isPastTestsLoadingCalled,
    hasNextPage: hasPastNextPage,
    loadNextPage: loadPastNextPage,
    isLoadingNextPage: isLoadingPastNextPage,
  } = useLazyTestList({ closedAtLt: date });
  const {
    tests: cancelledTests,
    loading: isLoadingCancelledTests,
    totalCount: cancelledTestsTotalCount,
    loadTests: loadCancelledTests,
    testsLoadingCalled: isCancelledTestsLoadingCalled,
    hasNextPage: hasCanceledNextPage,
    loadNextPage: loadCanceledNextPage,
    isLoadingNextPage: isLoadingCanceledNextPage,
  } = useLazyTestList({
    status: "cancelled" as TestStatus.Cancelled,
  });

  const location = useLocation<Sections>();
  if (!location.state) {
    location.state = {
      recentTests: true,
      pastTests: false,
      cancelledTests: false,
      upcomingTests: true,
      inProgressTests: true,
    };
  }

  useEffect(() => {
    if (location.state.pastTests) {
      loadPastTests();
    }
  }, [location.state.pastTests]);

  useEffect(() => {
    if (location.state.cancelledTests) {
      loadCancelledTests();
    }
  }, [location.state.cancelledTests]);

  const currentUser = useCurrentUser();
  if (!currentUser) return <></>;
  const hasAdvancedTestsAccess = currentUser.organization.advancedTestsAccess;

  const onCancelledSectionChange = (open: boolean) => {
    location.state.cancelledTests = open;
    loadCancelledTests();
  };
  const onPastSectionChange = (open: boolean) => {
    location.state.pastTests = open;
    loadPastTests();
  };

  const sectionTitle = (title: string, headingNumber: number, displayHeadingNumber = true): JSX.Element => (
    <>
      <span className="mr-sm">{title}</span>
      {displayHeadingNumber && <HeadingNumber className="text-sm text-dark leading-sm">{headingNumber}</HeadingNumber>}
    </>
  );

  return (
    <>
      <div className="mt-4xl">
        <CollapsibleSection
          title={sectionTitle("Live tests", inProgressTestsTotalCount || 0)}
          initiallyOpen={location.state.inProgressTests}
          onChange={open => (location.state.inProgressTests = open)}
        >
          <ResultsSection
            tests={inProgressTests}
            isLoading={inProgressTestsLoading}
            isLoadingNextPage={isLoadingInProgressNextPage}
            hasNextPage={hasInProgressNextPage}
            loadNextPage={loadInProgressNextPage}
            emptyListMessage="There are no live test results"
            dataTestid="live-tests"
            isInProgressSection={true}
          />
        </CollapsibleSection>
      </div>
      <Authorization roles={["testManager", "customerUser"]}>
        <>
          <div className="mt-4xl">
            <CollapsibleSection
              title={sectionTitle("Upcoming tests", upcomingTestsTotalCount || 0)}
              initiallyOpen={location.state.upcomingTests}
              onChange={open => (location.state.upcomingTests = open)}
            >
              <ResultsSection
                tests={upcomingTests}
                isLoading={upcomingTestsLoading}
                isLoadingNextPage={isLoadingUpcomingNextPage}
                hasNextPage={hasUpcomingNextPage}
                loadNextPage={loadUpcomingNextPage}
                emptyListMessage="There are no upcoming test results"
                dataTestid="upcoming-tests"
              />
            </CollapsibleSection>
          </div>
          <div className="mt-4xl">
            <CollapsibleSection
              title={sectionTitle("Tests finished in the last 7 days", recentTestsTotalCount || 0)}
              initiallyOpen={location.state?.recentTests}
              onChange={open => {
                location.state.recentTests = open;
              }}
            >
              <ResultsSection
                tests={recentTests}
                isLoading={recentTestsLoading}
                isLoadingNextPage={isLoadingRecentNextPage}
                hasNextPage={hasRecentNextPage}
                loadNextPage={loadRecentNextPage}
                emptyListMessage="No tests were finished in the last 7 days"
                dataTestid="recent-tests"
              />
            </CollapsibleSection>
          </div>
          <div className="mt-4xl">
            <CollapsibleSection
              title={sectionTitle(
                "Older test results",
                pastTestsTotalCount || 0,
                isPastTestsLoadingCalled && !isLoadingPastTests,
              )}
              initiallyOpen={location.state.pastTests}
              onChange={onPastSectionChange}
            >
              <ResultsSection
                tests={pastTests}
                isLoading={isPastTestsLoadingCalled && isLoadingPastTests}
                isLoadingNextPage={isLoadingPastNextPage}
                hasNextPage={hasPastNextPage}
                loadNextPage={loadPastNextPage}
                emptyListMessage="There are no test results older than 7 days"
                dataTestid="past-tests"
              />
            </CollapsibleSection>
          </div>
          {hasAdvancedTestsAccess && (
            <div className="mt-4xl">
              <CollapsibleSection
                title={sectionTitle(
                  "Cancelled tests",
                  cancelledTestsTotalCount || 0,
                  isCancelledTestsLoadingCalled && !isLoadingCancelledTests,
                )}
                initiallyOpen={location.state.cancelledTests}
                onChange={onCancelledSectionChange}
              >
                <ResultsSection
                  tests={cancelledTests}
                  isLoading={isCancelledTestsLoadingCalled && isLoadingCancelledTests}
                  isLoadingNextPage={isLoadingCanceledNextPage}
                  hasNextPage={hasCanceledNextPage}
                  loadNextPage={loadCanceledNextPage}
                  emptyListMessage="There are no cancelled test results"
                  dataTestid="cancelled-tests"
                />
              </CollapsibleSection>
            </div>
          )}
        </>
      </Authorization>
      <div className="text-dark text-left mb-5xl mt-6xl">
        In the interest of security & customer privacy, we do not store results older than 18 months
      </div>
    </>
  );
};

export { ResultSectionsList };
