import React from "react";
import { Status } from "@hopper/status";
import pluralize from "pluralize";
import { Test } from "types/graphql";

type IssuesStatsProps = {
  test: Test;
};

const IssuesStats = ({ test }: IssuesStatsProps) => {
  const issuesCount = test.issuesCount;
  const color = issuesCount > 0 ? "danger" : "primary";

  return (
    <Status color={color}>
      <span className="text-md text-body">{`${issuesCount} ${pluralize("issue", issuesCount)}`}</span>
    </Status>
  );
};

export default IssuesStats;
