import React from "react";
import { Heading } from "@hopper/heading";
import { Panel } from "@hopper/panel";

import { FlexibleTestingTest } from "types/graphql";
import { AssignedCommunityTestManagersTable } from "./assigned_community_test_managers_table";
import { AvailableCommunityTestManagersTable } from "./available_community_test_managers_table";

type ModeratorsPanelProps = {
  isPanelOpen: boolean;
  onPanelClose: () => void;
  platformTest: FlexibleTestingTest;
};

export const ModeratorsPanel = ({ isPanelOpen, onPanelClose, platformTest }: ModeratorsPanelProps) => {
  const availableCommunityTestManagers = platformTest.availableCommunityTestManagers.edges.map(({ node }) => node);
  const assignedCommunityTestManagers = platformTest.assignedCommunityTestManagers.edges.map(({ node }) => node);

  return (
    <Panel
      isOpen={isPanelOpen}
      position="right"
      hasOverlay={true}
      onOverlayClick={onPanelClose}
      onCloseButtonClick={onPanelClose}
      maxWidth="52rem"
    >
      <div className="p-xl pt-0 flex flex-col ml-auto mr-0" style={{ height: "95dvh" }}>
        <Heading size={2}>Test case result moderators</Heading>
        <p className="py-lg">Test case result moderators assigned to this test</p>
        {assignedCommunityTestManagers.length > 0 ? (
          <AssignedCommunityTestManagersTable
            flexibleTestingTestId={platformTest.id}
            assignedCommunityTestManagers={assignedCommunityTestManagers}
          />
        ) : (
          <p>No moderators assigned to this test</p>
        )}
        <hr className="bg-muted mt-[5rem]" />
        <p className="py-lg">Assign test case result moderators to this test</p>
        {availableCommunityTestManagers.length > 0 ? (
          <AvailableCommunityTestManagersTable
            flexibleTestingTestId={platformTest.id}
            availableCommunityTestManagers={availableCommunityTestManagers}
          />
        ) : (
          <p>No moderators available for this test</p>
        )}
      </div>
    </Panel>
  );
};
