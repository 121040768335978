import React from "react";
import clsx from "clsx";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import {
  areMessageUIPropsEqual,
  EditMessageForm as DefaultEditMessageForm,
  MessageContextValue,
  MessageDeleted as DefaultMessageDeleted,
  MessageErrorIcon,
  messageHasAttachments,
  messageHasReactions,
  MessageInput,
  MessageOptions as DefaultMessageOptions,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageStatus as DefaultMessageStatus,
  MessageText,
  MessageTimestamp as DefaultMessageTimestamp,
  MessageUIComponentProps,
  MML,
  Modal,
  ReactionSelector as DefaultReactionSelector,
  ReactionsList as DefaultReactionList,
  useComponentContext,
  useMessageContext,
} from "stream-chat-react";
import { startCase } from "lodash";
import { useAuthorInformation } from "./use_author_information";
import logo from "assets/images/tw-logo-icon.png";

const CUSTOM_MESSAGE_TYPE = {
  date: "message.date",
  intro: "channel.intro",
} as const;

const customReactions = [
  { id: "+1" },
  { id: "heart" },
  { id: "joy" },
  { id: "white_check_mark" },
  { id: "question" },
  { id: "eyes" },
];

type MessageSimpleWithContextProps<StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics> =
  MessageContextValue<StreamChatGenerics>;

const MessageSimpleWithContext = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>(
  props: MessageSimpleWithContextProps<StreamChatGenerics>,
) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleOpenThread,
    handleRetry,
    highlighted,
    isMyMessage,
    isReactionEnabled,
    message,
    reactionSelectorRef,
    renderText,
    showDetailedReactions,
    threadList,
  } = props;

  const {
    Attachment,
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    MessageOptions = DefaultMessageOptions,
    MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageStatus = DefaultMessageStatus,
    MessageTimestamp = DefaultMessageTimestamp,
    ReactionSelector = DefaultReactionSelector,
    ReactionsList = DefaultReactionList,
  } = useComponentContext<StreamChatGenerics>("CustomMessage");
  const { testRole } = useAuthorInformation(message);

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);

  if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
    return null;
  }

  if (message.deleted_at || message.type === "deleted") {
    return <MessageDeleted message={message} />;
  }

  const showMetadata = !groupedByUser || endOfGroup;
  const showReplyCountButton = !threadList && !!message.reply_count;
  const allowRetry = message.status === "failed" && message.errorStatusCode !== 403;
  const userName = ["tester", undefined].includes(testRole)
    ? message.user?.name || message.user?.id
    : `${message.user?.name || message.user?.id} - ${startCase(testRole)}`;

  const rootClassName = clsx(
    "str-chat__message str-chat__message-simple",
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage() ? "str-chat__message--me str-chat__message-simple--me" : "str-chat__message--other",
    message.text ? "str-chat__message--has-text" : "has-no-text",
    {
      "pinned-message": message.pinned,
      "str-chat__message--has-attachment": hasAttachment,
      "str-chat__message--highlighted": highlighted,
      "str-chat__message--with-reactions str-chat__message-with-thread-link": hasReactions && isReactionEnabled,
      "str-chat__message-send-can-be-retried": message?.status === "failed" && message?.errorStatusCode !== 403,
      "str-chat__virtual-message__wrapper--end": endOfGroup,
      "str-chat__virtual-message__wrapper--first": firstOfGroup,
      "str-chat__virtual-message__wrapper--group": groupedByUser,
    },
  );

  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      <div className={rootClassName} key={message.id}>
        <div
          className={clsx("str-chat__message-inner", {
            "str-chat__simple-message--error-failed": allowRetry,
          })}
          data-testid="message-inner"
          onClick={allowRetry ? () => handleRetry(message) : undefined}
          onKeyUp={allowRetry ? () => handleRetry(message) : undefined}
        >
          <MessageOptions />
          <div className="str-chat__message-reactions-host">
            {hasReactions && isReactionEnabled && <ReactionsList reverse reactionOptions={customReactions} />}
            {showDetailedReactions && isReactionEnabled && (
              <ReactionSelector ref={reactionSelectorRef} reactionOptions={customReactions} />
            )}
          </div>
          <div className="str-chat__message-bubble">
            {message.attachments?.length && !message.quoted_message ? (
              <Attachment actionHandler={handleAction} attachments={message.attachments} />
            ) : null}
            <MessageText message={message} renderText={renderText} />
            {message.mml && (
              <MML actionHandler={handleAction} align={isMyMessage() ? "right" : "left"} source={message.mml} />
            )}
            <MessageErrorIcon />
          </div>
        </div>
        {showReplyCountButton && (
          <MessageRepliesCountButton onClick={handleOpenThread} reply_count={message.reply_count} />
        )}
        {showMetadata && (
          <div className="str-chat__message-metadata mb-sm">
            {!!message.user && (
              <div className="str-chat__message-simple-name text-sm leading-2xl">
                {message.system_message && <img src={logo} className="inline h-lg m-xs ml-0 align-bottom" />}
                {isMyMessage() ? "You" : userName}
              </div>
            )}
            <div className="str-chat__message-data str-chat__message-simple-data flex">
              <MessageTimestamp calendar customClass="str-chat__message-simple-timestamp" />
              <MessageStatus />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual,
) as typeof MessageSimpleWithContext;

export const CustomMessage = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>(
  props: MessageUIComponentProps<StreamChatGenerics>,
) => {
  const messageContext = useMessageContext<StreamChatGenerics>("CustomMessage");

  return <MemoizedMessageSimple {...messageContext} {...props} />;
};
