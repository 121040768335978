// browser setup
import "core-js/stable";
import "regenerator-runtime/runtime";
import Sentry from "./app/sentry";

Sentry.install();
import React from "react";
import { ApolloProvider } from "@apollo/client";

import $ from "jquery";
window.jQuery = $;
window.$ = $;

import "assets/stylesheets/colors.css";
import "assets/stylesheets/font_families.css";
import "assets/stylesheets/vendor/bootstrap.css";
import "assets/stylesheets/vendor/hamburgers.css";
import "font-awesome/css/font-awesome.css";
import "@hopper/styles/dist/index.css";
import "stream-chat-react/dist/css/v2/index.css";
import "assets/stylesheets/main.css";
import "assets/stylesheets/stream_chat_overrides.css";
import "react-toastify/dist/ReactToastify.minimal.css";
import "../node_modules/flag-icons/css/flag-icons.min.css";

import { createRoot } from "react-dom/client";
import Root from "./root";

import { Auth0Config } from "components/configuration";
import { featureFlags } from "app/feature_flags";

import { auth0RedirectCallback } from "./app/auth0_redirect_callback";
import { ApolloClientProvider } from "./app/apollo_client_provider";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import { Router } from "react-router-dom";
import { history } from "app/history/history";
import { useLogout } from "components/shared/hooks/use_logout";

function ApolloProviderInitializer({ children, ...props }) {
  const history = useHistory();
  const { isLoading } = useAuth0();
  const { logout } = useLogout();
  let apolloClient;

  if (!isLoading) {
    ApolloClientProvider.initialize(logout, history.push);

    apolloClient = ApolloClientProvider.apolloClient;
  }

  return (
    <>
      {isLoading ? null : (
        <ApolloProvider {...props} client={apolloClient}>
          {children}
        </ApolloProvider>
      )}
    </>
  );
}

const render = Component => {
  let redirectUri = window.location.origin;

  if (window.location.href.match("organization-settings/integrations/azure_devops")) {
    redirectUri = window.location.href.replace("error=", "azure_devops_error=");
  }

  return (
    <Auth0Provider
      redirectUri={redirectUri}
      clientId={Auth0Config.clientId}
      domain={Auth0Config.domain}
      audience={Auth0Config.audience}
      onRedirectCallback={auth0RedirectCallback}
      beforeLogoutCallback={() => featureFlags.shutdown()}
    >
      <Router history={history}>
        <ApolloProviderInitializer>
          <Component />
        </ApolloProviderInitializer>
      </Router>
    </Auth0Provider>
  );
};

const renderAndMount = Component => {
  const container = document.getElementById("customer-portal-app");
  const root = createRoot(container);
  root.render(render(Component));
};

$(() => renderAndMount(Root));

export { render, renderAndMount, Root };
