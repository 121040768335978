import React from "react";
import { OrganizationStats } from "types/graphql";
import { ArcElement, Chart as ChartJS } from "chart.js";
import { Pie } from "react-chartjs-2";
import pluralize from "pluralize";

// @ts-ignore
import { preset } from "@hopper/styles";

ChartJS.register(ArcElement);

type TestCasesSectionProps = {
  organizationStats: OrganizationStats;
};

const TestCasesSection = ({ organizationStats }: TestCasesSectionProps) => {
  const totalTestCases = organizationStats.testCasesTotalCount;
  const failedTestCases = organizationStats.testCasesFailedCount;
  const notFailedTestCases = totalTestCases - failedTestCases;
  const { danger, muted } = preset.theme.colors;
  const chartData = {
    datasets: [
      {
        data: [failedTestCases, notFailedTestCases],
        backgroundColor: [danger, muted],
        borderWidth: 0,
      },
    ],
  };
  const cases = pluralize("case", totalTestCases);
  const have = pluralize("have", totalTestCases);

  return (
    <div className="mt-3xl flex">
      <div className="w-[80px]" data-testid="test-cases-chart">
        <Pie data={chartData} />
      </div>

      <div className="ml-lg">
        <p className="text-xl font-black text-darkest">{`${failedTestCases} of ${totalTestCases} test ${cases} ${have} failed`}</p>

        <p>{`${Math.round((failedTestCases / totalTestCases) * 100)}% failure rate`}</p>
      </div>
    </div>
  );
};

export default TestCasesSection;
