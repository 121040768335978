import {
  FlexibleTestingCaseResultFieldResponse,
  FlexibleTestingStringCaseResultFieldResponse,
  FlexibleTestingStringCaseField,
  Maybe,
} from "types/graphql";
import { AdditionalInformationTextResponse } from "./additional_information_text_responses";

const isStringCaseResultFieldResponse = (
  response: FlexibleTestingCaseResultFieldResponse,
): response is FlexibleTestingStringCaseResultFieldResponse => "value" in response;

export const generateAdditionalInformationWithTextResponses = (
  caseResultResponses: Maybe<FlexibleTestingCaseResultFieldResponse[]> | undefined,
  caseFields: FlexibleTestingStringCaseField[],
): AdditionalInformationTextResponse[] => {
  const fieldsResponsesMap = new Map();

  caseFields.forEach(caseField => {
    fieldsResponsesMap.set(caseField.id, { label: caseField.value, value: undefined });
  });

  caseResultResponses
    ?.filter(isStringCaseResultFieldResponse)
    ?.forEach(({ caseField: { id: caseFieldId }, _id, id, value }) => {
      if (fieldsResponsesMap.has(caseFieldId)) {
        fieldsResponsesMap.set(caseFieldId, {
          ...fieldsResponsesMap.get(caseFieldId),
          _id,
          id,
          value,
        });
      }
    });

  return Array.from(fieldsResponsesMap.values());
};
