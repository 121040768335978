import { gql, useLazyQuery } from "@apollo/client";
import {
  AdvancedTestRequest,
  FlexibleTestingStringCaseField,
  FlexibleTestingTest as FlexibleTestingTestGQL,
  FlexibleTestingTestCase as FlexibleTestingTestCaseGQL,
} from "types/graphql";
import { PreviewPageAdvancedTestRequest, PreviewPageStandardTestRequest } from "../preview_page/preview_page";
import { partition } from "lodash";

type FlexibleTestingTest = Omit<FlexibleTestingTestGQL, "cases"> & {
  cases: {
    edges: {
      node: FlexibleTestingTestCase;
    }[];
  };
};

type FlexibleTestingTestCase = Omit<FlexibleTestingTestCaseGQL, "caseFields"> & {
  stepCaseFields: FlexibleTestingTestCaseGQL["caseFields"];
  additionalInformationCaseFields: FlexibleTestingTestCaseGQL["caseFields"];
};

export const GET_TEST = gql`
  query getPlatformTest($testId: ID!) {
    platformTest(id: $testId) {
      ... on AdvancedTestRequest {
        id
        code
        name
        accessToApplication
        testObjective
        notes
        platformsToTestOn
        deviceRequirements
        testLocalisation
        testCasesUrl
        knownIssuesUrl
        capabilities
        sdlcStage
        desiredOutcomes {
          code
        }
        customDesiredOutcomes
      }
      ... on FlexibleTestingTest {
        id
        code
        name
        applicationUrl
        description
        credentialsAndAccessInstructions
        additionalUrlsAndResources
        testerSpecificationLink
        requiredAttachmentForPass
        environments {
          platform
        }
        sdlcStage
        desiredOutcomes {
          code
        }
        cases {
          edges {
            node {
              name
              code
              section
              status
              prerequisites
              stepCaseFields {
                instruction
                expectedResult
              }
              additionalInformationCaseFields: caseFields(
                filters: { group_type: additional_information, display_type: string }
              ) {
                edges {
                  node {
                    ... on FlexibleTestingStringCaseField {
                      ordinalNumber
                      value
                      hasResultFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function useTest() {
  const [getTest, { data, loading }] = useLazyQuery(GET_TEST, { fetchPolicy: "cache-and-network" });
  let testRequest: PreviewPageAdvancedTestRequest | PreviewPageStandardTestRequest | undefined;

  if (data?.platformTest) {
    if (data.platformTest.__typename === "AdvancedTestRequest") {
      testRequest = mapAdvancedTestRequest(data.platformTest as AdvancedTestRequest);
    } else if (data.platformTest.__typename === "FlexibleTestingTest") {
      testRequest = mapFlexibleTestingTest(data.platformTest as FlexibleTestingTest);
    }
  }

  return {
    getTest: (testId: string) => getTest({ variables: { testId } }),
    testRequest,
    isLoading: loading,
  };
}

function mapAdvancedTestRequest(advancedTestRequest: AdvancedTestRequest): PreviewPageAdvancedTestRequest {
  return {
    accessToApplication: advancedTestRequest.accessToApplication,
    testObjective: advancedTestRequest.testObjective,
    name: advancedTestRequest.name,
    notes: advancedTestRequest.notes,
    platformsToTestOn: advancedTestRequest.platformsToTestOn || "",
    deviceRequirements: advancedTestRequest.deviceRequirements || "",
    testLocalisation: advancedTestRequest.testLocalisation || "",
    testCasesUrl: advancedTestRequest.testCasesUrl || "",
    knownIssuesUrl: advancedTestRequest.knownIssuesUrl || "",
    capabilities: advancedTestRequest.capabilities,
    sdlcStage: advancedTestRequest.sdlcStage || undefined,
    desiredOutcomes: advancedTestRequest.desiredOutcomes.map(desiredOutcome => desiredOutcome.code),
    customDesiredOutcomes: advancedTestRequest.customDesiredOutcomes || undefined,
  } as PreviewPageAdvancedTestRequest;
}

function mapFlexibleTestingTest(flexibleTestingTest: FlexibleTestingTest): PreviewPageStandardTestRequest {
  const testCases = flexibleTestingTest.cases.edges.map(testCase => {
    const stepCaseFields = testCase.node.stepCaseFields || [];

    const [textBasedAdditionalInformationCaseFields, attachmentBasedAdditionalInformationCaseFields] = partition(
      testCase.node.additionalInformationCaseFields.edges?.map(edge => edge?.node) || [],
      "hasResultFields",
    );

    return {
      name: testCase.node.name,
      code: testCase.node.code,
      section: testCase.node.section,
      prerequisites: testCase.node.prerequisites,
      fields:
        stepCaseFields.map(stepCaseField => ({
          instruction: stepCaseField.instruction || "",
          expectedResult: stepCaseField.expectedResult || "",
        })) || [],
      additionalInformation: {
        textRequirements: textBasedAdditionalInformationCaseFields.map(
          caseField => (caseField as FlexibleTestingStringCaseField)?.value,
        ),
        attachmentRequirements: attachmentBasedAdditionalInformationCaseFields.map(
          caseField => (caseField as FlexibleTestingStringCaseField)?.value,
        ),
      },
    };
  });
  const testEnvironments = flexibleTestingTest.environments?.map(environment => environment.platform as string) || [];

  return {
    applicationUrl: flexibleTestingTest.applicationUrl || "",
    name: flexibleTestingTest.name,
    description: flexibleTestingTest.description,
    credentialsAndAccessInstructions: flexibleTestingTest.credentialsAndAccessInstructions || "",
    additionalUrlsAndResources: flexibleTestingTest.additionalUrlsAndResources || "",
    testerSpecificationDocument: flexibleTestingTest.testerSpecificationLink || "",
    testCases,
    testEnvironments,
    sdlcStage: flexibleTestingTest.sdlcStage || undefined,
    desiredOutcomes: flexibleTestingTest.desiredOutcomes.map(desiredOutcome => desiredOutcome.code) || [],
    requiredAttachmentForPass: flexibleTestingTest.requiredAttachmentForPass,
  } as PreviewPageStandardTestRequest;
}
