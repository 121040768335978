import React from "react";
import { Button as HopperButton } from "@hopper/button";

export type ButtonProps = {
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function Button({ children, disabled, loading, ...props }: ButtonProps): JSX.Element {
  return (
    <HopperButton type="reset" variant="secondary" disabled={disabled} {...props}>
      {children}
    </HopperButton>
  );
}

export default Button;
