import React from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import { TestRequestPage } from "./test_request_page/test_request_page";
import { PreviewPage } from "./preview_page";
import { PreviewPageAdvancedTestRequest, PreviewPageStandardTestRequest } from "./preview_page/preview_page";
import { TestForCloningPage, AdvancedTestForCloningPage } from "components/flexible_testing/tests_for_cloning_page";
import { websiteContentPaths } from "../configuration";

type CloneTestRequest = {
  testId: string;
};

function FlexibleTestRequestPageSection({ match: { path } }: RouteComponentProps): JSX.Element {
  return (
    <Switch>
      <Route
        exact
        path={path + "/preview"}
        render={routeProps => (
          <PreviewPage
            testRequest={routeProps.location.state as PreviewPageStandardTestRequest | PreviewPageAdvancedTestRequest}
            editPath={websiteContentPaths.flexcaseLaunch}
          />
        )}
      />
      <Route exact path={path + "/tests-for-cloning"} component={TestForCloningPage} />
      <Route exact path={path + "/advanced-tests-for-cloning"} component={AdvancedTestForCloningPage} />
      <Route
        path={path}
        render={routeProps => {
          // Depending on the referrer of redirection the state value might have a different type.
          // For redirection from the cloning page the state stores CloneTestRequest type data
          // For preview page back action the state stores the PreviewPageTestRequest type
          // This is a tech debt that needs to be paid by refactoring the preview page to be part of the launch wizard
          const state = routeProps.location.state as CloneTestRequest;

          return (
            <TestRequestPage
              testIdForCloning={state?.testId}
              previewTestRequest={
                routeProps.location.state as PreviewPageStandardTestRequest | PreviewPageAdvancedTestRequest
              }
            />
          );
        }}
      />
    </Switch>
  );
}

export default FlexibleTestRequestPageSection;
