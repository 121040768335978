import { useQuery } from "@apollo/client";
import { OrganizationStats } from "types/graphql";
import { ORGANIZATION_STATS } from "./organization_stats_query";

type OrganizationStatsData = {
  isLoading: boolean;
  organizationStats: OrganizationStats | undefined;
};

const useOrganizationStats = (): OrganizationStatsData => {
  const { loading, data } = useQuery(ORGANIZATION_STATS);

  return {
    isLoading: loading,
    organizationStats: data?.organizationStats,
  };
};

export { useOrganizationStats };
