import { FlexibleTestingIssue } from "types/graphql";
import React from "react";
import { IssueSeverity } from "components/flexible_testing/shared/issue_severity";
import { history } from "app/history/history";
import { createNavigationUrl } from "app/helpers/url_helper";

type IssuesListProps = {
  issues: FlexibleTestingIssue[];
  testId?: string;
};

function IssuesList({ issues, testId }: IssuesListProps) {
  const formatTitle = (issue: FlexibleTestingIssue) => {
    let code = issue.code;

    if (code) code = `[${code}]`;

    return code ? `${code} ${issue.title}` : issue.title;
  };

  const onClick = (issue: FlexibleTestingIssue) => {
    if (testId) {
      history.push(createNavigationUrl("flexcaseIssue", { id: issue.id, testId }));
    }
  };

  return (
    <div className="border-t border-light">
      {issues.map(issue => (
        <div
          key={issue.id}
          onClick={() => {
            onClick(issue);
          }}
          className="cursor-pointer space-x-lg flex p-lg border-b border-light text-sm"
          data-testid="issue-summary"
        >
          <div style={{ width: 120 }}>
            <IssueSeverity issue={issue} />
          </div>
          <div className="font-weight-bold truncate w-full">{formatTitle(issue)}</div>
          <div style={{ width: 38 }}>
            <div className="mx-md">
              <i className="fa fa-chevron-right text-dark" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default IssuesList;
