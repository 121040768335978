import classNames from "classnames";
import React from "react";

type OpenRequirementsGroupPanelButtonProps = {
  text: string;
  testId: string;
  className?: string;
  onClick: (e: React.MouseEvent) => void;
};

const OpenRequirementsGroupPanelButton = ({
  text,
  testId,
  className,
  onClick,
}: OpenRequirementsGroupPanelButtonProps) => (
  <a
    href="#"
    data-testid={testId}
    className={classNames("text-link text-sm font-bold", className)}
    onClick={e => onClick(e)}
  >
    {text}
  </a>
);

export default OpenRequirementsGroupPanelButton;
