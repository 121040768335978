import React, { useState } from "react";

import { useDeleteTestCaseRelatedIssue } from "./use_delete_related_issue";
import { RelatedIssueDeleteConfirmation } from "./related_issue_delete_confirmation";
import { ButtonLink } from "components/flexible_testing/shared/button_link/index";
import { useTrackingDeleteRelatedIssue } from "../track_moderation_events/use_tracking_delete_related_issue";
import { useTrackingMarkAsPassed } from "../track_moderation_events/use_tracking_mark_as_passed";

type DeleteRelatedIssueButtonProps = {
  caseResultStatusFieldResponseId: string;
  onRequestEditButtonClicked: () => void;
  relatedIssueId: string;
  caseResultId: string;
  isDeleteButtonDisabled: boolean;
};

export function DeleteRelatedIssueButton({
  caseResultStatusFieldResponseId,
  onRequestEditButtonClicked,
  relatedIssueId,
  caseResultId,
  isDeleteButtonDisabled,
}: DeleteRelatedIssueButtonProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const trackDeleteRelatedIssue = useTrackingDeleteRelatedIssue();
  const trackMarkAsPassed = useTrackingMarkAsPassed();

  const onSuccess = (isStatusPass: boolean) => {
    trackDeleteRelatedIssue(relatedIssueId);
    setIsConfirmationModalOpen(false);

    if (isStatusPass) {
      trackMarkAsPassed();
    } else {
      onRequestEditButtonClicked();
    }
  };

  const { flexibleTestingRelatedIssueDelete, isLoading: isDeletingRelatedIssueInProgress } =
    useDeleteTestCaseRelatedIssue(onSuccess);

  const handleMarkAsPassedRelatedIssue = (caseResultResponseId: string) => {
    const changeStatusToPass = true;

    flexibleTestingRelatedIssueDelete(caseResultResponseId, caseResultId, changeStatusToPass);
    setIsConfirmationModalOpen(false);
  };

  const handleCreateEditRequest = () => {
    flexibleTestingRelatedIssueDelete(caseResultStatusFieldResponseId, caseResultId);
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <ButtonLink
        variant="danger"
        size="md"
        onClick={() => setIsConfirmationModalOpen(true)}
        disabled={isDeleteButtonDisabled}
        isLoading={isDeletingRelatedIssueInProgress}
      >
        Delete
      </ButtonLink>
      {isConfirmationModalOpen && (
        <RelatedIssueDeleteConfirmation
          onMarkAsPassed={() => handleMarkAsPassedRelatedIssue(caseResultStatusFieldResponseId)}
          onRequestEdit={() => handleCreateEditRequest()}
          onClose={() => setIsConfirmationModalOpen(false)}
          isButtonLoading={isDeletingRelatedIssueInProgress}
        />
      )}
    </>
  );
}
