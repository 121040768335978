import React from "react";
import { useCurrentUser } from "app/current_user/use_current_user";

import ResultDetailsContent from "./result_details_content";
import CommunityTestManagerContent from "./community_test_manager_content";

function ResultDetailsPage(): JSX.Element {
  const currentUser = useCurrentUser();

  return <>{currentUser?.communityTestManager ? <CommunityTestManagerContent /> : <ResultDetailsContent />}</>;
}

export default ResultDetailsPage;
