import { gql, useMutation } from "@apollo/client";
import { SELECTION_REQUIREMENTS_GROUPS_QUERY } from "../use_selection_requirements_groups";

export const REMOVE_TESTER_FROM_TEST = gql`
  mutation ($input: RemoveTestersFromTestInput!) {
    removeTestersFromTest(input: $input) {
      successful
      result {
        id
        status
        isRemovable
      }
      errors {
        code
        message
      }
    }
  }
`;

export const useRemoveTester = () => {
  const [removeTester] = useMutation(REMOVE_TESTER_FROM_TEST);

  return {
    removeTester: async (testId: string, participantIds: string[], removalReasons: string[]) => {
      const { data } = await removeTester({
        variables: { input: { testId, participantIds, removalReasons } },
        refetchQueries: [{ query: SELECTION_REQUIREMENTS_GROUPS_QUERY, variables: { testId } }],
      });

      return data.removeTestersFromTest.successful;
    },
  };
};
