import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import ExportIntegrationForm from "../export_integration_form";
import { Select } from "components/shared/form";
import { withProjectFields } from "./with_project_fields";

const validations = yup.object({
  project_id: yup.string().required("Project can't be blank"),
  work_item_type: yup.string().required("Work item type can't be blank"),
});

const initialValues = {
  project_id: "",
  work_item_type: "",
  area_path: "",
  tags: [],
};

function AzureDevopsExportForm(props) {
  const { integration, loadProjectFields } = props;
  const [projectId, setProjectId] = useState(null);
  const [workItemTypes, setWorkItemTypes] = useState([]);
  const [areaPaths, setAreaPaths] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedWorkItemType, setSelectedWorkItemType] = useState(null);
  const [selectedAreaPath, setSelectedAreaPath] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [isLoadingProjectFields, setIsLoadingProjectFields] = useState(false);

  const projects =
    integration.runs_export_attributes.projects?.map(({ id: value, name: label }) => ({
      value,
      label,
    })) || [];
  const projectDependentFieldsProps = projectId
    ? {}
    : {
        placeholder: "Select project first",
        isDisabled: true,
      };

  function onProjectChange(projectId) {
    resetProjectFields();
    setProjectId(projectId);
    setIsLoadingProjectFields(true);

    loadProjectFields(projectId, integration.id).then(result => {
      setWorkItemTypes(result.workItemTypes.map(mapNamesToSelect));
      setAreaPaths(result.areaPaths.map(mapNamesToSelect));
      setTags(result.tags.map(mapNamesToSelect));
      setIsLoadingProjectFields(false);
    });
  }

  function handleWorkItemTypeChange(value) {
    const selectedWorkItemType = workItemTypes.find(workItemType => workItemType.value === value);

    setSelectedWorkItemType(selectedWorkItemType);
  }

  function handleAreaPathChange(value) {
    const selectedAreaPath = areaPaths.find(areaPath => areaPath.value === value);

    setSelectedAreaPath(selectedAreaPath);
  }

  function handleTagsChange(value) {
    const selectedTags = tags.filter(tag => value?.includes(tag.value));

    setSelectedTags(selectedTags);
  }

  function resetProjectFields() {
    selectedWorkItemType && setSelectedWorkItemType(null);
    selectedAreaPath && setSelectedAreaPath(null);
    selectedTags && setSelectedTags(null);
  }

  return (
    <ExportIntegrationForm initialValues={initialValues} validations={validations} {...props}>
      <div className="mb-lg" data-test-role="form-group">
        <Select required name="project_id" label="Project" options={projects} onChange={onProjectChange} />
      </div>
      <div className="mb-lg" data-test-role="form-group">
        <Select
          required
          isAsync
          name="work_item_type"
          label="Work item type"
          isLoading={isLoadingProjectFields}
          defaultOptions={workItemTypes}
          {...projectDependentFieldsProps}
          value={selectedWorkItemType}
          onChange={handleWorkItemTypeChange}
        />
      </div>
      <div className="mb-lg" data-test-role="form-group">
        <Select
          isAsync
          name="area_path"
          label="Area path"
          isLoading={isLoadingProjectFields}
          defaultOptions={areaPaths}
          isClearable={true}
          {...projectDependentFieldsProps}
          value={selectedAreaPath}
          onChange={handleAreaPathChange}
        />
      </div>
      <div className="mb-lg" data-test-role="form-group">
        <Select
          isAsync
          isMulti
          name="tags"
          label="Tags"
          isLoading={isLoadingProjectFields}
          defaultOptions={tags}
          {...projectDependentFieldsProps}
          value={selectedTags}
          onChange={handleTagsChange}
        />
      </div>
    </ExportIntegrationForm>
  );
}

function mapNamesToSelect({ name }) {
  return {
    value: name,
    label: name,
  };
}

AzureDevopsExportForm.propTypes = {
  integration: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  loadProjectFields: PropTypes.func,
};

export default withProjectFields(AzureDevopsExportForm);
