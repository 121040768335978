import React from "react";
import { DecoratedText } from "@hopper/decorated_text";
import pluralize from "pluralize";

import { RequestedTest, TestEnvironmentPlatform } from "types/graphql";
import { Field } from "./field";
import {
  getActivityTitle,
  getApplicationTypeLabel,
} from "components/flexible_testing/test_request_page/requested_test_page/helpers";
import { Activity } from "components/flexible_testing/test_request_page/requested_test_page/activity_step_content";

type RequestedTestDetailsProps = {
  requestedTest: RequestedTest;
};

export const RequestedTestDetails = ({ requestedTest }: RequestedTestDetailsProps) => {
  const testType = getActivityTitle(requestedTest.activity as Activity);
  const applicationTypeLabel =
    getApplicationTypeLabel(requestedTest.applicationType as `${TestEnvironmentPlatform}`) || "";

  return (
    <Field label={requestedTest.name} dataTestid="requested-test">
      <div className="flex flex-col">
        <DecoratedText text={testType} />
        <DecoratedText text={applicationTypeLabel + " app"} />
        {requestedTest.activity === "test_case_run" ? (
          <DecoratedText
            text={`${requestedTest.testCasesCount} ${pluralize("test case", requestedTest.testCasesCount || 0)}`}
          />
        ) : (
          <DecoratedText text={requestedTest.inScope || ""} />
        )}
      </div>
    </Field>
  );
};
